import React from 'react'
import { Link } from 'react-router-dom';
import './SetupChecklist.css'
import TournamentStatus from '../RefDesk/modals/TournamentStatus'

const SetupChecklist = () => {
    return (
        <div className='container'>
            <div >
                <h2>Setup Guide</h2>
                <h5>Click on the task to go to the page</h5>
                <p>If this is your first tournament we recommend you view the how-to videos.  They can be found in the admin section in the how-to section.</p>
            </div>
            <div className='checklist'>
                <div className='checklist-number'>1</div>
                <Link to={`/admin/tournamentSetup`} >
                    <div>
                        Add tournament dates, about page, and description page.
                </div>
                </Link>
            </div >

            <div className='checklist'>
                <div className='checklist-number'>2</div>
                <Link to={`/desk/editProcedures`} >
                    <div>
                        Referee Procedures Page
                </div>


                </Link>
            </div >
            <div className='checklist'>
                <div className='checklist-number'>3</div>
                <Link to={`/desk/scheduleSetup`} >
                    <div>
                        Scheduling Setup
                </div>
                </Link>
            </div >
            <div className='checklist'>
                <div className='checklist-number'>4</div>
                <Link to={`/admin`} >
                    <div>
                        Ready for volunteers to signup?  Change tournament status to active- bottom of dashboard.
                        
                    </div>
                </Link>
            </div>
            <div className='checklist'>
                <div className='checklist-number'>5</div>
                <Link to={`/admin`} >
                    <div>
                        Share the tournament URL in your tournament listing, facebook or anywhere you want to let people know where to signup as a volunteer.  Link can be found on the bottom of the menu page.
                </div>
                </Link>
            </div >
            <h4>Remember that the menu has links to all features and how to videos.</h4>
        </div >
    )
}
export default SetupChecklist