import React from 'react'
import Moment from 'react-moment';
import 'moment-timezone';

const InvoiceLineItem = props => {

    return (
        <div className='invoiceItem'>
            <div style={{ width: '50px' }}>
                {props.past.court_num}</div>
            <div style={{ width: '50px' }}>
                <Moment format="MM/DD/YYYY" >
                    {props.past.start_time}
                </Moment>
            </div>
            <div style={{ width: '100px' }}>
                <Moment format="h:mm a" >
                    {props.past.start_time}
                </Moment>
            </div>
            <div style={{ width: '100px' }}> <Moment format="h:mm a" >
                {props.past.end_time}
            </Moment></div>
            <div style={{ width: '50px' }}>
                $ {props.amount}
            </div>

        </div>
    )

}
export default InvoiceLineItem