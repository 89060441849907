import React, {useState} from 'react'

export default function DisplayNotificationsListDetail(props){
    const [message, setMessage] = useState('')
    let {note} = props

    const completeNotification = () => props.completeNotification(note.nid, message)

    return(
        <div className='col-sm-12 col-md-6'>
        <div className={note.type === 'medical' ? 'notification medical'
                    : note.type === '2nd' ? 'notification second'
                        : note.type === 'td' ? 'tournament-director notification'
                            : 'notification'} key={'nn' + note.nid}
                    >
                    <div className='col-4 notification-court'>
                        <div className='court-name'>
                            <div className='court-title'>
                            {note.time}
                            </div>
                            <div>{note.court_num}</div>
                        </div>

                    </div>
                    <div className='col-8'>
                        {note.type === '2nd' && 'Second Call Needed'}
                        {note.type === 'desk' && 'Message to Desk' }
                        {note.type === 'medical' && 'Medical Attention Needed'}
                        {note.type === 'td' && 'Tournament Director Needed'}
                        {note.type === 'head ref' && 'Head Referee Needed'}
                        <div>
                            <div style={{fontSize:'1.5em'}}> {note.type === '2nd' ? 'Players:' : ''}
                                {note.message}</div>
                            <p>{note.department}: {note.first} {note.last}</p>

                        </div>
                        
                        <div className='row'>
<div className='col-12'>
                        <div>
                            <h4>Want to include a message?</h4>
                            <textarea type="text" 
                                maxLength='250'
                                value={message} 
                                    onChange={e=>setMessage(e.target.value)}  />
                        </div>
                        <div>
                            <button onClick={completeNotification}>Confirm Receipt of Notification</button>
                        </div>
                        <p>By clicking 'Confirm Receipt of Notification' referee will be send a text confirmation that request was received and is being handled.  </p>
                    </div>

</div>
                    </div>



                </div >
                </div>

    )
}