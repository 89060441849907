import React, { Component } from 'react'
import axios from 'axios'
import { connect } from "react-redux"
import { updateTournament } from '../../../ducks/reducer'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import Modal from 'react-responsive-modal';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import '../AddRefToSchedule.css'
import ScheduleRecap from '../ScheduleModal/ScheduleRecap'
import SignupsPublic from '../ScheduleModal/SignupsPublic'
import Login from '../../Login/Login'
import IndividualStatusUpdate from '../../DayOfComponents/IndividualStatusUpdate'
import './Signup.css'
import WithdrawFromAllShifts from './WithdrawFromAllShifts';
import ShiftDescriptions from '../ShiftDescriptions';


class RefereeSignupApproval extends Component {
    constructor() {
        super()
        this.state = {
            tournament: {},
            shifts: [],
            schedule: [],
            searchName: '',
            showLogin: false,
            categoryFilter: '',
            showMore: false,
            showRefereeRequest: false,
            note: '',
            approvedStatus: { approved: false, requested: false },
            loadingTournament: true
        }
    }

    componentDidMount() {
        this.getShiftSpots()
        this.getMySchedule()
        this.isUserApprovedForTournament()

    }

    componentDidUpdate(prevProps, prevState) {
        const { ref_id } = this.props;

        if (prevProps.ref_id !== ref_id) {
            this.getMySchedule()
        }
    }



    getTournament = () => {
        if (this.props.match.params.tid) {
            axios.post(`/api/t/getTournament/${this.props.tid}`, { ref_id: this.props.ref_id }).then(
                res => {
                    this.setState({ tournament: res.data, loadingTournament: false })
                }
            )
        }

    }
    getShiftSpots = () => {
        axios.get(`/api/getRefereeSignup/${this.props.tid}`).then(
            res => {
                this.setState({ shifts: res.data })
            }
        )
    }

    getMySchedule = () => {
        if(!this.props.ref_id)return
        axios.post(`/api/getRefScheduleForTournament/${this.props.ref_id}`, { tid: this.props.tid }).then(
            res => {
                this.setState({ schedule: res.data })
            }
        )
    }

    isUserApprovedForTournament = () => {
        if (this.props.ref_id > 0) {
            axios.post(`/api/t/isUserApprovedForTournament/${this.props.tid}`, { ref_id: this.props.ref_id }).then(
                res => {
                    if (res.data.ref_id > 0) {
                        this.setState({ approvedStatus: res.data })
                    }
                }
            )
        }
    }

    assignRef = async (shift_id) => {
        if (!this.props.ref_id) {
            this.setState({ showLogin: true })
        } else {

            axios.put(`/api/putTimeSpots/${shift_id}`, { tid: this.props.tid, ref_id: this.props.ref_id }).then(
                res => {
                    this.getShiftSpots()
                    this.getMySchedule()
                }
            ).catch(error => {
                if (error.response) {
                    toast.warning(error.response.data)
                    this.setState({ referee: {}, shift: {}, waitlist: false })

                }
            })
        }
    }

    addWaitList = async (shift_id) => {
        if (!this.props.ref_id) {
            this.setState({ showLogin: true })
        } else {
            axios.put(`/api/putWaitlist/${shift_id}`, { tid: this.props.tid, ref_id: this.props.ref_id }).then(
                res => {
                    this.getShiftSpots()
                    this.getMySchedule()
                }
            ).catch(error => {
                if (error.response) {
                    toast.warning(error.response.data)
                    this.setState({ referee: {}, shift: {}, waitlist: false })
                }
            })
        }
    }

    removeRef = (shift_id) => {
        axios.put(`/api/removeRefTime/${shift_id}`, { tid: this.props.tid }).then(
            res => {
                this.getShiftSpots()
                this.getMySchedule()
            }
        )
    }

    requestRefereeApproval = () => {
        if(!this.state.showRefereeRequest || 
            (this.state.approvedStatus.requested && !this.state.approvedStatus.approved)
            ){
            return toast.warning('Request was already sent.  Only one request is required per tournament.')
        }
        axios.post(`/api/requestRefereeApproval/${this.props.tid}`, { ref_id: this.props.ref_id, note: this.state.note }).then(
            res => {
                if(res.data === 'duplicate'){
                    return toast.warning('Request was already sent.  Only one request is required per tournament.')
                }else {
                    toast.success('Tournament Approval Request Sent')
                    this.isUserApprovedForTournament()
                    this.setState({ showRefereeRequest: false })
                }
            }
        )
    }

    updateUser = () => {
        this.getMySchedule()
        this.isUserApprovedForTournament()
    }

    showLogin = () => this.setState({ showLogin: !this.state.showLogin })

    showRefereeRequest = () => {
        if (!this.props.ref_id || this.props.ref_id === 0) {
            this.setState({ showLogin: !this.state.showLogin })
        } else {
            this.setState({ showRefereeRequest: !this.state.showRefereeRequest })
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }
    handleWithdraw = () => {
        this.getShiftSpots()
        this.getMySchedule()
    }


    render() {
        const mappedSchedule = this.state.schedule
            .map((shift, i) => {
                return (
                    <div key={'a' + i}>

                        <ScheduleRecap
                            shift={shift} />


                    </div>
                )
            })

        const mappedShifts = this.state.shifts
            .filter(obj => obj.name.toUpperCase().includes(this.state.searchName.toUpperCase()))
            .map((shift, i) => {

                return (
                    <div key={'b' + i}>

                        <div style={{ background: 'white', margin: '10px 0' }}>
                            <div className='shift-title'
                                style={{ background: shift.color || '#009CAE' }}>
                                <h4 >{shift.category}: {shift.time} {shift.name}</h4>

                            </div>
                            {shift.description ?
                                < div style={{ padding: '0 15px 15px 15px' }}>
                                    {shift.description}
                                </div> : ''}
                            <ShiftDescriptions shift={shift}/>
                            <div className='row'>


                                <div className='col-12 col-md-6 shift-box' >
                                    {shift.pre_reg ? '' :
                                        <div className='col-12 criteria'>

                                            <h5>Spots: {shift.count}/{shift.spots}</h5>
                                            {shift.certified > 0 || shift.level_two > 0 || shift.level_one > 0 ?
                                                <div style={{ fontSize: '.7em' }}>criteria:</div> : ''}
                                            <div className='row'>
                                                {shift.certified > 0 ?
                                                    <div className='col-12 col-lg-6'>
                                                        Certified: {shift.certified}
                                                    </div> : ''}
                                                {shift.level_two > 0 ?
                                                    <div className='col-12 col-lg-6'>
                                                        Level Two: {shift.level_two}
                                                    </div> : ''}
                                                {shift.level_one > 0 ?
                                                    <div className='col-12 col-lg-6'>
                                                        Level One: {shift.level_one}
                                                    </div> : ''}
                                                {shift.certified > 0 && shift.level_two > 0 && shift.level_one > 0 ?
                                                    <div className='col-12 col-lg-6'>
                                                        Not Specified: {shift.none}
                                                    </div> : ''}
                                            </div>

                                        </div>}
                                    <div className='registered-spots'
                                        key={shift.shift_id}>
                                        <SignupsPublic
                                            spots={shift.signup}
                                            ref_id={this.props.ref_id}
                                            removeRef={this.removeRef}
                                        />


                                        {
                                            ((shift.spots - shift.count) > 0 || shift.pre_reg)
                                                ?
                                                shift.category === 'Referee' &&
                                                    this.state.approvedStatus.requested &&
                                                    !this.state.approvedStatus.approved ?
                                                    <button>Approval Request Sent</button> :
                                                    !this.state.approvedStatus.approved ?
                                                        <button onClick={this.showRefereeRequest}>Request Signup Approval</button> :

                                                        <button onClick={() => this.assignRef(shift.sid)}>Sign Up</button>
                                                : ''
                                        }
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 shift-box' >
                                    <div className='col-12 criteria'>
                                        {shift.wait_spots > 0 ?
                                            <div>

                                                <h5>Waitlist: {shift.wait_count}/{shift.wait_spots}</h5>
                                                {shift.wait_certified > 0 || shift.wait_level_two > 0 || shift.wait_level_one > 0 ?
                                                    <div style={{ fontSize: '.7em' }}>criteria:</div> : ''}
                                                <div className='row'>
                                                    {shift.wait_certified > 0 ?
                                                        <div className='col-12 col-lg-6'>
                                                            Certified: {shift.wait_certified}
                                                        </div> : ''}
                                                    {shift.wait_level_two > 0 ?
                                                        <div className='col-12 col-lg-6'>
                                                            Level Two: {shift.wait_level_two}
                                                        </div> : ''}
                                                    {shift.wait_level_one > 0 ?
                                                        <div className='col-12 col-lg-6'>
                                                            Level One: {shift.wait_level_one}
                                                        </div> : ''}
                                                    {shift.wait_certified > 0 && shift.wait_level_two > 0 && shift.wait_level_one > 0 ?
                                                        <div className='col-12 col-lg-6'>
                                                            Not Specified: {shift.wait_none}
                                                        </div> : ''}

                                                </div>
                                                <div className='registered-spots'
                                                    key={`w${shift.shift_id}`}>
                                                    <SignupsPublic
                                                        spots={shift.waitlist}
                                                        ref_id={this.props.ref_id}
                                                        removeRef={this.removeRef} />


                                                    {(shift.wait_spots - shift.wait_count) > 0 &&
                                                        shift.category === 'Referee' &&
                                                        shift.category === 'Referee' &&
                                                        this.state.approvedStatus.requested &&
                                                        !this.state.approvedStatus.approved ?
                                                        <button>Approval Request Sent</button> :
                                                        !this.state.approvedStatus.approved ?
                                                            <button onClick={this.showRefereeRequest}>Request Signup Approval</button> :
    
                                                            <button onClick={() => this.addWaitList(shift.sid)}>Sign Up</button>
                                                    }
                                                            
                                                </div>
                                            </div> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                )
            })
        return (
            <div>

                {this.props.tid && this.props.status === 'active' ?

                    <div className='AddRefToSchedule container' >
                        <div className='row'
                        >

                            <div className='col-12'>
                                {(this.state.approvedStatus.requested && !this.state.approvedStatus.approved)?
                                    <div className='request-submitted'>Your referee request has been submitted. Waiting on approval.</div> :
                                    ''}
                                {this.state.approvedStatus.approved
                                    ? <div className='request-submitted'>You are an approved referee for this tournament </div> : ''}
                            </div>
                        </div>
                        <div className='row'>


                            <div className='col-12 col-md-4 '
                                style={{ background: 'white' }}>
                                {this.state.tournament.tournament_started ?
                                    <Link to={`/public/board/${this.props.tid}`} target='_blank' rel="noopener noreferrer" >
                                        <button className='register-button'>
                                            Referee Court Assignments/ Queue</button>

                                    </Link> : ''}
                                {this.state.tournament.tournament_started && this.state.tournament.registered_referee > 0 ?
                                    <IndividualStatusUpdate
                                        tid={this.props.match.params.tid} />
                                    : ''}

                                {this.props.ref_id ?
                                    <div>
                                        <div className='my-schedule-title'>
                                            My Schedule
                                            </div>
                                        <div className='my-schedule'>
                                            {mappedSchedule}
                                        </div>
                                        {this.state.schedule.length > 0 &&
                                    <WithdrawFromAllShifts get={this.handleWithdraw}/>}
                                    </div> : 'Login to see your schedule'}
                            </div>

                            <div className='col-12 col-md-8' >
                                <div className='dashboard-title'>Referee Signup</div>
                                {this.props.referee_coordinator_name ?
                                    <div className='coordinator'>Referee Coordinator: {this.props.referee_coordinator_name}
                                        {this.props.ref_id ?
                                            <Link to={{
                                                pathname: `/signup/contactcoordinator`,
                                                type: 'referee'
                                            }}>
                                                <div className='contact-button'>
                                                    <i class="fas fa-envelope"></i>Contact</div>
                                            </Link> :
                                            <p style={{ marginLeft: '20px' }}>Login to Contact</p>}
                                    </div> : ''}
                                <div style={{
                                    background: 'white', padding: '10px'
                                }}>
                                    <div className={this.state.showMore ? 'tournament-description-long' : 'tournament-description-short'
                                    } >
                                        <ReactQuill
                                            className='description'
                                            value={this.props.description}
                                            readOnly={true}
                                            theme={"bubble"}
                                        />
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <button
                                            className='login-button'
                                            onClick={() => this.setState({ showMore: !this.state.showMore })}>{this.state.showMore ? 'Show Less' : 'Show More'}</button>
                                    </div>
                                </div>


                                <div>
                                <div>
                            <Link to={`/signup/refereemessageboard/${this.props.tid}`} >
                                <button className='login-button'
                                    style={{ margin: '10px 0 0 0', fontSize: '2em', background: '#EF746C' }}>
                                    Past Emails Sent to Referees</button>
                            </Link>
                            </div>
                            <div>
                            <Link to={`/signup/referee/messageboard/${this.props.tid}`} >
                                <button className='login-button'
                                    style={{ margin: '10px 0 0 0', fontSize: '2em', background: '#4974a5' }}>
                                    Referee Message Board</button>
                            </Link>
                            </div>
                            </div>
                            <div>
                            <Link to={`/procedures/${this.props.tid}`} >
                                <button className='register-button'
                                    style={{ margin: '10px 0 0 0' }}>
                                    View Referee Procedures</button>
                            </Link>
                            </div>

                                <div style={{ margin: '10px 0 0 0' }}>
                                    <div className='search-menu'>
                                        <legend> Search by date or title
                            <input
                                                type="text"
                                                placeholder='Search date/ Shift Name'
                                                name="searchName"
                                                value={this.state.searchName}
                                                onChange={this.handleChange} />
                                            <i className="fas fa-ban" onClick={() => this.setState({ searchName: '' })}></i>
                                        </legend>
                                    </div>

                                    {mappedShifts}

                                </div>
                            </div>


                        </div>
                        <Modal open={this.state.showLogin} center showCloseIcon={true} onClose={this.showLogin} closeIconSize={20} closeOnEsc={true} >
                            <Login
                                showLogin={this.showLogin} />

                        </Modal>
                        <Modal open={this.state.showRefereeRequest} center showCloseIcon={true} onClose={this.showRefereeRequest} closeIconSize={20} closeOnEsc={true} >
                            <div>
                                <h4>Request to be an approved referee for the tournament</h4>
                                <p>Your request will be submitted.  If you want you can include a note to the coordinator to introduce yourself.</p>
                                <p>Your email and phone number will be shared with the coordinator as they may contact you.</p>
                                <h4>Only one request per tournament is needed as you are requesting for the tournament and not per shift.</h4>
                                <textarea
                                    type="text"
                                    placeholder='Message to coordinator'
                                    name="note"
                                    value={this.state.note}
                                    onChange={this.handleChange}
                                    cols="30" rows="10">
                                </textarea>
                                <button onClick={this.requestRefereeApproval}>Send Request</button>
                            </div>

                        </Modal>
                    </div >
                    :
                    this.state.loadingTournament ?
                        <div>
                            <h4>Gathering tournament information...  </h4>
                        </div> :
                        <div>
                            <h4>Sorry this tournament is not open for registration.  </h4>
                        </div>}
            </div>
        )
    }
}

const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        ref_id: reduxState.ref_id,
        name: reduxState.name,
        referee_approval_required: reduxState.referee_approval_required,
        start: reduxState.start,
        end_date: reduxState.end_date,
        location: reduxState.location,
        description: reduxState.description,
        status: reduxState.status,
        referee_coordinator_name: reduxState.referee_coordinator_name
    }
}
export default connect(
    mapStateToProps, { updateTournament }
)(RefereeSignupApproval)
