import React, { Component } from 'react';
import axios from 'axios'
import { connect } from "react-redux"
import Modal from 'react-responsive-modal';
import 'moment-timezone';
import './TournamentSetup.css'
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter'
import LookupRef from '../LookupRef'
import AddRef from '../AddRef'
import RefDetails from './RefDetails'

class RefereeSetup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            edit: false,
            verifyDelete: false,
            ref_id: '',
            first: '',
            last: '',
            phone: '',
            email: '',
            barcode: '',
            credentials: 'Not Rated',
            ppaRating: '',
            allRefs: [],
            refStatus: [],
            showLookupButton: true,
            firstSearch: '',
            lastSearch: '',
            search: '',
            editRefModal: false
        }

    }

    componentDidMount() {
        this.getRegisteredRefs()
        this.getAllRefs()
    }


    getAllRefs = () => {
        this.setState({ showLookupButton: true })
        axios.get(`/api/getAllRefList/${this.props.tid}`).then(
            res => {
                this.setState({ allRefs: res.data })
            }
        )
    }

    getRegisteredRefs = async () => {
        await axios.get(`/api/getRegisteredRefs/${this.props.tid}`).then(
            res => {
                this.setState({ refStatus: res.data })
            }
        )
    }

    addRefToTournament = async (ref_id) => {
        await axios.post(`/api/addRefToTournamentSetup/${this.props.tid}`, { ref_id: ref_id }).then(

            res => {
                this.setState({
                    refStatus: res.data
                })
            }
        ).then(this.getAllRefs)
    }

    removeRef = async (ref_id) => {
        await axios.put(`/api/removeRefFromTournament/${this.props.tid}`, { ref_id: ref_id, removed_by: this.props.first || this.props.last }).then(

            res => {
                this.setState({
                    refStatus: res.data,
                    verifyDelete: false
                })
            }
        ).then(this.getAllRefs)
    }

    verifyDelete = () => {
        this.setState({ edit: false, verifyDelete: !this.state.verifyDelete })
    }

    makeRefAdmin = async (ref_id) => {
        await axios.put(`/api/makeRefAdmin/${this.props.tid}`, { ref_id: ref_id }).then(

            res => {
                this.setState({
                    refStatus: res.data
                })
            }
        ).then(this.getAllRefs)
    }

    edit = (r) => {
        this.setState({
            edit: true,
            ref_id: r.ref_id,
            first: r.first,
            last: r.last,
            phone: r.phone,
            email: r.email,
            barcode: r.barcode,
            credentials: r.credentials,
            ppaRating: r.ppa_rating

        })
    }

    handleEditRef = async (ref_id, first, last, credentials, phone, email, barcode, ppaRating) => {
        axios.put(`/api/editRef`, { tid: this.props.tid, ref_id, first, last, credentials, phone, email, barcode, ppaRating, editPPA: true }).then(
            res => {
                this.setState({ refStatus: res.data })
                this.getRegisteredRefs()
            }
        )
    }

    // editRef = async (obj) => {
    //     axios.put(`/api/editRef`, { tid: this.props.tid, ref_id: obj.ref_id, first: obj.first, last: obj.last, credentials: obj.credentials, phone: obj.phone, email: obj.email, barcode: obj.barcode, ppaRating, editPPA: true }).then(
    //         res => {
    //             this.setState({ refStatus: res.data })
    //         }
    //     )
    // }

    save = () => {
        this.handleEditRef(this.state.ref_id, this.state.first, this.state.last, this.state.credentials, this.state.phone, this.state.email, this.state.barcode, this.state.ppaRating)
        this.setState({ edit: false })
    }

    cancel = () => {
        this.setState({
            edit: false,
            ref_id: '',
            first: '',
            last: '',
            phone: '',
            email: '',
            barcode: '',
            credentials: 'Not Rated'
        })
    }

    clearSearch = () => this.setState({ firstSearch: '', lastSearch: '' })

    openEditRefModal = () => this.setState({ editRefModal: !this.state.editRefModal })
    closeEditRefModal = () => this.setState({ editRefModal: false })



    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }



    render() {
        const columns = [

            {
                Header: 'First',
                accessor: 'first',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["first"] }),
                filterAll: true
            },
            {
                Header: 'Last',
                accessor: 'last',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["last"] }),
                filterAll: true
            },
            {
                Header: 'credentials',
                accessor: this.props.organization === 'ppa' ?
                'ppa_rating':
                'credentials',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["credentials", "ppa_rating"] }),
                filterAll: true

            },
            {
                Header: 'Edit',
                accessor: 'ref_id',
                width: 60,
                Cell: ({ value, row }) =>
                    <button onClick={() => this.edit(row._original)}>Edit</button>

            },
        ]
        const mappedRef = this.state.refStatus
            .filter(obj => obj.first.toUpperCase().includes(this.state.firstSearch.toUpperCase()))
            .filter(obj => obj.last.toUpperCase().includes(this.state.lastSearch.toUpperCase()))
            .map(ref => {
                return (
                    <RefDetails
                        key={ref.ref_id}
                        referee={ref}
                        editRef={this.editRef} />
                )
            })

        return (
            <div className="" style={{ background: 'white' }}>
                <h2>Referee Tournament Setup- Edit or Add Referees</h2>

                <div className='ref-status-container'>
                    <div>
                        <h4 className='tips'>Add</h4>
                        <LookupRef
                            allRefs={this.state.allRefs}
                            getAllRefs={this.getAllRefs}
                            addRefToTournament={this.addRefToTournament}
                            removeRef={this.removeRef} />

                        <p>If ref is not in system add below</p>
                        <AddRef
                            tid={this.props.tid}
                            refereeAdded={this.getRegisteredRefs}
                            getRegisteredRefs={this.getRegisteredRefs} />

                    </div>
                    <div className='tips'>
                        <h3>Registered</h3>
                    </div>
                    <button onClick={this.openEditRefModal}><h4>Edit Referee Credential or Add Barcode</h4></button>
                    {this.state.editRefModal ?
                        <div>
                            <h4>Edit</h4>
                            <p>All fields are editable.  Just update the info and it will auto save</p>

                            <input
                                type="text"
                                placeholder='Search by First Name'
                                name="firstSearch"
                                value={this.state.firstSearch}
                                onChange={this.handleChange} />
                            <input
                                type="text"
                                placeholder='Search by Last Name'
                                name="lastSearch"
                                value={this.state.lastSearch}
                                onChange={this.handleChange} />
                            <i className="fas fa-ban" onClick={this.clearSearch}></i>

                            <div className='TournamentSetup-ref-list'>

                                {mappedRef}

                            </div>
                            <button onClick={this.closeEditRefModal}>Close Edit</button>
                        </div> :
                        <ReactTable
                            data={this.state.refStatus}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            columns={columns}
                        />}



                    <Modal open={this.state.edit} center showCloseIcon={true} onClose={this.cancel} closeOnEsc={true} closeIconSize={2}>
                        <h6>Edit Referee Info</h6>
                        <h2>{this.state.first} {this.state.last}</h2>
                        <p>
                            <label> First
                                <input type="text"
                                    placeholder='first'
                                    name='first'
                                    value={this.state.first}
                                    onChange={this.handleChange} />
                            </label>
                        </p>
                        <p>
                            <label> last
                                <input type="text"
                                    placeholder='last'
                                    name='last'
                                    value={this.state.last}
                                    onChange={this.handleChange} />
                            </label>
                        </p>

                        <p><label> Phone
                                <input type="text"
                                placeholder='Phone Num- no spaces'
                                name='phone'
                                value={this.state.phone}
                                maxLength='10'
                                onChange={this.handleChange} />
                        </label>
                        </p>
                        <p>
                            <label> Email
                                <input type="text"
                                    placeholder='email'
                                    name='email'
                                    value={this.state.email}
                                    onChange={this.handleChange} />
                            </label>
                        </p>
                        <p>
                            <label> Barcode
                                <input type="text"
                                    placeholder='Barcode'
                                    name='barcode'
                                    value={this.state.barcode}
                                    onChange={this.handleChange} />
                            </label>
                        </p>

                        <legend>Credentials: {this.state.credentials}</legend>
                        <label>
                            <input type='radio'
                                checked={this.state.credentials === 'Not Rated'}
                                name='credentials'
                                value='Not Rated'
                                onClick={this.handleChange}
                            /> Not Rated </label>
                        <label>
                            <input type='radio'
                                checked={this.state.credentials === 'Level 1'}
                                name='credentials'
                                value='Level 1'
                                onClick={this.handleChange}
                            /> Level 1 </label>
                        <label>
                            <input type='radio'
                                value='Level 2'
                                name='credentials'
                                checked={this.state.credentials === 'Level 2'}
                                onClick={this.handleChange}
                            /> Level 2 </label>
                        <label>
                            <input type='radio'
                                checked={this.state.credentials === 'Certified'}
                                name='credentials'
                                value='Certified'
                                onClick={this.handleChange}
                            /> Certified </label>
                        <div>
                            {this.props.organization === 'ppa' &&
                            <div>
                                 <legend>PPA Credentials: {this.state.ppaRating}</legend>
                        <label>
                            <input type='radio'
                                checked={this.state.ppaRating === 'NR'}
                                name='ppaRating'
                                value='NR'
                                onClick={this.handleChange}
                            /> NR </label>
                            <label>
                            <input type='radio'
                                checked={this.state.ppaRating === 'PR'}
                                name='ppaRating'
                                value='PR'
                                onClick={this.handleChange}
                            /> PR </label>
                            {/* <label>
                            <input type='radio'
                                checked={this.state.ppaRating === 'PR2'}
                                name='ppaRating'
                                value='PR2'
                                onClick={this.handleChange}
                            /> PR2 </label> */}
                            </div>
                            }
                            <button onClick={this.save}><i className="far fa-save"></i>Save</button>
                            <button onClick={this.cancel}><i className="fas fa-ban" ></i>Cancel</button>
                            <button onClick={this.verifyDelete}>Remove Ref From Tournament</button>


                        </div>

                    </Modal>
                    <Modal open={this.state.verifyDelete} center showCloseIcon={true} onClose={this.verifyDelete} closeIconSize={20} closeOnEsc={true} >
                        <div>
                            <h4>Are you sure you want to remove {this.state.first} {this.state.last} from the tournament?</h4>
                            <p>Referee will be removed from all scheduled shifts.  This cannot be reversed.</p>
                            <p>Please make sure to first notify the referee before removing.</p>

                            <p>If a referee is shown on court but removed from the board click the reset button in referee management section.  </p>
                            <button onClick={() => this.removeRef(this.state.ref_id)}>Verify Remove From Shifts and Tournament</button>

                        </div>
                    </Modal>










                </div>


            </div>


        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        first: reduxState.first,
        last: reduxState.last,
        organization: reduxState.organization
    }
}


export default connect(
    mapStateToProps,
)(RefereeSetup);