import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import io from 'socket.io-client'


const ReportScore = props => {
    const [match, setMatch] = useState([])
    const [score1T1, setScore1T1] = useState('')
    const [score1T2, setScore1T2] = useState('')
    const [score2T1, setScore2T1] = useState('')
    const [score2T2, setScore2T2] = useState('')
    const [score3T1, setScore3T1] = useState('')
    const [score3T2, setScore3T2] = useState('')
    const [score4T1, setScore4T1] = useState('')
    const [score4T2, setScore4T2] = useState('')
    const [winner, setWinner] = useState(undefined)
    const [signature, setSignature] = useState('')
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const admin = useSelector(state => state.tid)


    let { tid, t1p1, t1p1_name, t1p2, t1p2_name, t2p1, t2p1_name, t2p2, t2p2_name, t1_g1_score, t2_g1_score, t1_g2_score, t2_g2_score, t1_g3_score, t2_g3_score, t1_g4_score, t2_g4_score, match_type, gold } = match

    const socket = io()

    useEffect(
        () => { getMatch() }, [])

    const getMatch = () => {
        setLoading(true)
        axios.get(`/api/score/getScoreCard/${props.match.params.sid}`).then(
            res => {
                setMatch(res.data)
                if (res.data) {
                    let { t1_g1_score, t2_g1_score, t1_g2_score, t2_g2_score, t1_g3_score, t2_g3_score, t1_g4_score, t2_g4_score, submitted } = res.data
                    setLoading(false)
                    setSubmitted(submitted)
                    setScore1T1(t1_g1_score || '')
                    setScore1T2(t2_g1_score || '')
                    setScore2T1(t1_g2_score || '')
                    setScore2T2(t2_g2_score || '')
                    setScore3T1(t1_g3_score || '')
                    setScore3T2(t2_g3_score || '')
                    setScore4T1(t1_g4_score || '')
                    setScore4T2(t2_g4_score || '')

                }
                else {
                    setSubmitted(true)
                    setLoading(false)
                }
            }
        )
    }

    const recordScore = (type, score) => {
        if (submitted) return
        axios.put(`/api/score/reportScore`, { sid: props.match.params.sid, type, score }).then(
            res => {
            }
        )
    }
    const submit = () => {
        axios.put(`/api/score/submitMatch`, { sid: props.match.params.sid, score1T1, score1T2, score2T1, score2T2, score3T1, score3T2, score4T1, score4T2, winner, signature }).then(
            res => {
                toast.success('Match results have been submitted. ')
                setSubmitted(true)
                socket.emit('score', { room: `score:${tid}` })


            }
        )
    }

    if (loading && !admin) {
        return (
            <div>
                Gathering match info
            </div>
        )
    }

    else {

        return (
            <div className='container ReportScore'>
                <Paper>
                    <div>
                        {submitted ? 'Match Already Submitted' : ''}
                        <h2>Report Score</h2>
                        <p className='tips'>After the match input the scores and hit submit.</p>
                        <p>Match Type: {match_type}</p>

                        <div className='team-names team-one'>
                            <h5>Team 1</h5>
                            <div>{t1p1_name}</div>
                            <div>{t1p2_name}</div>
                        </div>
                        <div className='report-score'>
                            <div className='score-box'>
                                <input type="number"
                                    className='team-one'
                                    value={score1T1}
                                    onChange={e => setScore1T1(e.target.value)}
                                    onBlur={() => recordScore('g1t1', score1T1)}
                                />
                                <div className='game'>Game</div>
                                <div className='game-num'>1</div>
                                <input type="number"
                                    value={score1T2}
                                    className='team-two'
                                    onChange={e => setScore1T2(e.target.value)}
                                    onBlur={() => recordScore('g1t2', score1T2)} />

                            </div>
                            {match_type === '2/3 to 11' ?
                                <div className='score-box'>

                                    <input type="number"
                                        value={score2T1}
                                        className='team-one'
                                        onChange={e => setScore2T1(e.target.value)}
                                        onBlur={() => recordScore('g2t1', score2T1)}
                                    />
                                    <div className='game'>Game</div>
                                    <div className='game-num'>2</div>
                                    <input type="number"
                                        value={score2T2}
                                        className='team-two'
                                        onChange={e => setScore2T2(e.target.value)}
                                        onBlur={() => recordScore('g2t2', score2T2)} />

                                </div> : ''}
                            {match_type === '2/3 to 11' ?
                                <div className='score-box'>
                                    <input type="number"
                                        value={score3T1}
                                        className='team-one'
                                        onChange={e => setScore3T1(e.target.value)}
                                        onBlur={() => recordScore('g3t1', score3T1)}
                                    />
                                    <div className='game'>Game</div>
                                    <div className='game-num'>3</div>
                                    <input type="number"
                                        value={score3T2}
                                        className='team-two'
                                        onChange={e => setScore3T2(e.target.value)}
                                        onBlur={() => recordScore('g3t2', score3T2)} />

                                </div> : ''}
                            {gold ?
                                <div className='score-box'>

                                    <input type="number"
                                        value={score4T1}
                                        className='team-one'
                                        onChange={e => setScore4T1(e.target.value)}
                                        onBlur={() => recordScore('g4t1', score4T1)}
                                    />
                                    <div className='game'>Tie-Break</div>
                                    <input type="number"
                                        value={score4T2}
                                        className='team-two'
                                        onChange={e => setScore4T2(e.target.value)}
                                        onBlur={() => recordScore('g4t2', score4T2)} />

                                </div> : ''}
                        </div>
                        <div className='team-names team-two'>
                            <h5>Team 2</h5>
                            <div>{t2p1_name}</div>
                            <div>{t2p2_name}</div>
                        </div>
                        <div>
                            <legend>
                                Who Won The Match?
                            </legend>
                            <label style={{ background: '#DBFAB6', padding: '0 10px', marginRight: '10px' }}>
                                <input type="radio"
                                    checked={winner === true}
                                    value={winner}
                                    onChange={() => setWinner(true)}
                                />
                            Team 1
                        </label>
                            <label style={{ background: '#A4DFE9', padding: '0 10px' }}>
                                <input type="radio"
                                    checked={winner === false}
                                    value={winner}
                                    onChange={() => setWinner(false)}
                                />
                            Team 2
                        </label>

                        </div>
                        <div>
                            Type Signature:
                        <input type='text'
                                value={signature}
                                onChange={e => setSignature(e.target.value)} />
                        </div>
                        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                            {error ?
                                <h4 style={{ color: 'red' }}>Missing Information</h4> : ''}
                            {submitted ? 'Match Already Submitted' : <button onClick={submit}>Submit</button>}
                        </div>
                    </div>

                </Paper>
            </div>
        )
    }
}
export default ReportScore