import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTournament, clearTournament, setAdmin } from '../../ducks/reducer'
import { withRouter } from 'react-router-dom'
import Moment from 'react-moment';
import 'moment-timezone';
import './TournamentInfo.css'



const TournamentInfo = props => {
    const [showDesc, setShowDesc] = useState(false)
    const [showProcedures, setShowProcedures] = useState(false)

    const { t } = props


    const dispatch = useDispatch();

    const selectTournament = (tourney) => {
        dispatch(updateTournament(tourney))
        dispatch(setAdmin())
        props.history.push('/admin')
    }

    const mappedAdmin = t.admins.map((a, i) => {
        return (
            <div key={'a' + i}>
                {a.first} {a.last}
            </div>
        )
    })

    return (
        <div
            className='tournament-info'
            style={{ background: 'white', textAlign: 'left', padding: '20px' }}>
            <h4>
                {t.name}
                <button
                    onClick={() => selectTournament(t)}>
                    GO</button>
            </h4>
            <h5>Status: {t.status}
            </h5>
            <div>Admins:
            {mappedAdmin}
            </div>


            <legend>Location: {t.location}
            </legend>


            <legend>
                Start Date:
                {t.start ?
                    <Moment format="MM/DD/YYYY" >
                        {t.start}
                    </Moment>
                    :
                    <div style={{ color: 'red' }}>DATE NEEDED</div>
                }
            </legend>
            <legend>
                End Date:
                {t.end_date ?
                    <Moment format="MM/DD/YYYY" >
                        {t.end_date}
                    </Moment> :
                    <div style={{ color: 'red' }}>DATE NEEDED</div>
                }
            </legend>





            <legend>About - Shown on Listing Page (140 character limit)
                <p className='full-description'>{t.about}</p>
            </legend>
            <div>Description for Signup Page
                <div className={showDesc ? 'full-description' : 'short-description'}>


                    <div dangerouslySetInnerHTML={{ __html: t.description }}>

                    </div>

                </div>
                <button onClick={() => setShowDesc(showDesc => !showDesc)}>Show More</button>


            </div>
            <div>
                Referee Procedures
                <div className={showDesc ? 'full-description' : 'short-description'}>
                    <div dangerouslySetInnerHTML={{ __html: t.procedures }}>

                    </div>

                </div>
                <button onClick={() => setShowProcedures(showProcedures => !showProcedures)}>Show More</button>

            </div>

            <div>
                {t.referee_approval_required ?
                    <div>
                        Tournament Requires Referee Approval
                        <legend>Email for referee coordinator where approval request notification should be sent
                <input type="text"
                                style={{ width: '300px' }}
                                value={t.referee_coordinator_email}
                            />
                        </legend>
                    </div>
                    : 'No Referee Approval Required'}
                <legend>
                    <h5>Match Payout Amounts</h5>
                    <label >
                        Certified
                <input type="number"
                            name='certified'
                            value={t.certified}
                        />
                    </label>
                    <label >
                        Level 2
                <input type="number"
                            name='level2'
                            value={t.level2}
                        />
                    </label>
                    <label >
                        Level 1
                <input type="number"
                            name='level1'
                            value={t.level1}
                        />
                    </label>
                    <label >
                        Not Rated
                <input type="number"
                            name='notRated'
                            value={t.notRated}
                        />
                    </label>
                </legend>

            </div>
        </div>
    )
}
export default withRouter((TournamentInfo))