import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper';
import io from 'socket.io-client'
import './NotifyDesk.css'
import MonitorNotificationButtons from './MonitorNotificationButtons'
import ActiveMatchListCourtMonitor from '../ScoreReporting/ActivematchListCourtMonitor'


const MonitorNotifyDesk = props => {
    const [verified, setVerified] = useState('checking')
    const [scoreReporting, setScoreReporting] = useState(false)

    const ref_id = useSelector(state => state.ref_id)

    const socket = io()

    useEffect(
        () => { verifyCourtMonitor() }, [props.match.params.tid])

    const verifyCourtMonitor = async () => {
        if (props.match.params.tid > 0) {
            await axios.post(`/api/verifyCourtMonitorStatus/${props.match.params.tid}`, { ref_id }).then(

                res => {
                    if (res.data.count > 0) {
                        setVerified('yes')
                        setScoreReporting(res.data.score_reporting)
                    }
                    else {
                        setVerified('no')
                    }
                }
            )
        }
    }


    return (
        <div className='container-fluid'>
            {verified === 'checking' ?
                <h3>Verifying Court Monitor Status</h3> : ''}

            {verified === 'no' ?
                <h3>Sorry but you are not assigned to a court as a court monitor.  Once assigned to a court, please re-fresh your browser</h3> : ''}

            {verified === 'yes' ?
                <Paper>
                    < MonitorNotificationButtons
                        verified={verified}
                        tid={props.match.params.tid} />

                </Paper> : ''}
            {scoreReporting ?
                <Paper>
                    <ActiveMatchListCourtMonitor
                        tid={props.match.params.tid}
                        verified={verified} />
                </Paper> : ''}
        </div>
    )
}
export default MonitorNotifyDesk