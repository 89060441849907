import React from 'react'

const TermsOfService = () => {

    return (
        <div className='container' style={{ background: 'white', textAlign:'left' }}>
            <h4>TERMS of USE & PRIVACY POLICY</h4>
            <h5>Pickleball Desk</h5>

By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content. Pickleball Desk (“Company”, “We”, “Us”) is committed to protecting the privacy of your information. The following policies and procedures describe how we collect and use information we gather through our Web site and mobile application. 
<ol>
<li>Acceptance.</li>
By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site. You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction.
 
<li>Property</li>
This site and any related desktop or mobile application is the exclusive property of Pickleball Desk. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.

<li>No Warranty or Guarantee of Accuracy</li>
We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk. 
<br/>
In no case shall Pickleball Desk, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.

<li>Products or Services</li>
Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.
We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.
We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.

<li>Prohibited Uses</li>
You are prohibited from using the site or its content: 
(a) for any unlawful purpose; 
(b) to solicit others to perform or participate in any unlawful acts; 
(c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; 
(d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; 
(e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; 
(f) to submit false or misleading information; 
(g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; 
(h) to collect or track the personal information of others; 
(i) to spam, phish, pharm, pretext, spider, crawl, or scrape; 
(j) for any obscene or immoral purpose; or 
(k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. 

<li>Information Collected</li>
Pickleball Desk offers a variety of services delivered through the Web site that are collectively referred to as the “Service”.  Pickleball Desk collects information from individuals who visit the Company's Web sites (“Visitors”) and individuals who register to use the Service (“Customers”).
<br/>
Pickleball Desk requires you to provide the Company with certain information, such as name, address, phone number, and e-mail address (“Required Contact Information”) when registering to use the Service.  Pickleball Desk requires you to provide the Company with certain billing information, such as billing name and address and credit card number (“Billing Information”) when purchasing the Service.  Pickleball Desk may also ask you to provide additional information in the future in order to provide other features or services. 
<br/>
As you navigate the Company's Web site, Pickleball Desk may also collect information through the use of commonly used information-gathering tools, such as cookies and web beacons (“Web Site Navigational Information”). Web Site Navigational Information includes standard information from your web browser, your Internet Protocol (“IP”) address, and the actions you take on the Company's Web site.

<li>Use of Information</li>
Information may be used in the following manner depending on the type of information collected. The Company uses information about Customers to provide the Services, including regular maintenance and improvements. The Company may also use Customer information to market other Pickleball Desk services you may be interested in, as well as promotions. Pickleball Desk uses Billing Information solely to collect payment for the Service.
Pickleball Desk uses Web Site Navigational Information to operate and improve the Company's Web site. The Company may also use Web Site Navigational Information in combination with Data About Pickleball Desk Customers to provide personalized information about the Company.
<li>Web Site Navigational Information</li>
Pickleball Desk uses commonly used information-gathering tools, such as cookies and web beacons, to collect information as you navigate the Company's Web sites (“Web Site Navigational Information”). This information is not linked to any personal information such as Contact Information or Billing Information about Visitors or Customers.
<br/>
Pickleball Desk uses cookies to make interactions with the Company's Web sites easy and meaningful. When you visit one of the Company's Web sites, Pickleball Desk's servers send a cookie to your computer. Standing alone, cookies do not personally identify you. They merely recognize your web browser. Unless you choose to identify yourself to Pickleball Desk, either by responding to a promotional offer, opening an account, or filling out a web form, you remain anonymous to the Company.
<br/>
The Company uses session cookies containing encrypted information to allow the Company to uniquely identify you if you have chosen to identify yourself to us. Each time you log into the Service, a session cookie containing an encrypted, unique identifier that is tied to your account is placed in your browser. These session cookies allow the Company to uniquely identify you when you are logged into the Service and to process your online transactions and requests. Session cookies are required to use the Service and provide a layer of security to our Service delivery.
<br/>
Pickleball Desk uses persistent cookies that only the Company can read and use to identify browsers that have previously visited the Company's Web sites. When you purchase the Service or provide the Company with personal information, a unique identifier is assigned to you. This unique identifier is associated with a persistent cookie that the Company places on your web browser. The Company does not store account numbers or passwords in persistent cookies. If you disable your web browser's ability to accept cookies, you will be able to navigate the Company's Web sites, but you may not be able to successfully use the Service.
<li>IP Addresses</li>
When you visit Pickleball Desk's Web sites, the Company collects your Internet Protocol (“IP”) addresses to track and aggregate non-personally identifiable information.  Pickleball Desk also collects IP addresses from Customers whey they log into the Service as part of the Company's “Identity Confirmation” and “IP Range Restrictions” security features.
<li>Public Forums</li>
Pickleball Desk may provide bulletin boards, blogs, or chat rooms on the Company's Web sites. Any personally identifiable information you choose to submit in such a forum may be read, collected, or used by others who visit these forums, and may be used to send you unsolicited messages.  Pickleball Desk is not responsible for the personally identifiable information you choose to submit in these forums.
<li>Sharing of Information Collected</li>
Pickleball Desk does not share, sell, rent, or trade personally identifiable information with third parties for their promotional purposes.  Pickleball Desk may use a third-party intermediary to manage credit card processing. This intermediary is not permitted to store, retain, or use Billing Information except for the sole purpose of credit card processing on the Company's behalf.
<br/>
Pickleball Desk reserves the right to disclose personally identifiable information of the Company's Customers or Visitors if required by law or if the Company reasonably believes that disclosure is necessary to protect the Company's rights and/or to comply with a judicial proceeding, court order, or legal process.
Governing Law
<br/>
These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of the State of Utah.

<li>Changes to Terms of Use or Privacy Policy</li>	
Pickleball Desk reserves the right to change this Privacy Statement.  Pickleball Desk will provide notification of the material changes to this Privacy Statement through the Company's Web sites at least thirty (30) business days prior to the change taking effect.
</ol>


        </div>
    )
}
export default TermsOfService