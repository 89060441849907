import React, { Component } from 'react'
import axios from 'axios'
import { connect } from "react-redux"
import Modal from 'react-responsive-modal';
import Datetime from 'react-datetime'
import moment from 'moment'
import 'moment-timezone'

import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CirclePicker } from 'react-color';
import './Schedule.css'
import ColorPicker from '../utlities/ColorPicker';

class ScheduleSetup extends Component {
    constructor() {
        super()
        this.state = {
            shifts: [],
            name: '',
            preReg: false,
            description: '',
            date: '',
            start: null,
            end: null,
            spots: 0,
            certified: 0,
            levelOne: 0,
            levelTwo: 0,
            none: 0,
            edit: false,
            sid: '',
            waitSpots: 0,
            waitCertified: 0,
            waitLevelOne: 0,
            waitLevelTwo: 0,
            waitNone: 0,
            message: '',
            category: 'Referee',
            color: '',
            searchName: '',
            searchCategory: '',
            verifyDelete: false,
            deleteSid: {},
            oldTitle: '',
            repeat: 0
        }
    }

    componentDidMount() {
        this.getShifts()
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    handleNumber = async (e) => {
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex

        if (e.target.value === '' || re.test(e.target.value)) {

            await this.setState({
                [e.target.name]: +e.target.value

            })
            this.spotTotal()
        }
    }

    handleDate = date => {
        this.setState({
            date: date
        });
    };

    handleStart = e => {this.setState({ start: e })
    if(this.state.end === null || this.state.end < e ){
        this.setState({ end: e })
    }
}

    handleEnd = end => this.setState({ end })

    handleColor = (color, event) => {
        this.setState({ color: color })
    }

    handlePreReg = () => {
        if (this.state.preReg) {
            this.setState({
                preReg: false,
                description: ''
            })
        } else {
            this.setState({
                preReg: true,
                description: 'This is pre-registration signup to identify who plans to referee. By registering, you will be on the list to receive an email invitation once time slots and specific referee needs are available.'
            })
        }
    }

    getShifts = () => {
        axios.get(`/api/getRefereeShifts/${this.props.tid}`).then(
            res => {
                this.setState({ shifts: res.data })
            }
        )
    }

    addShift = () => {
        // if (this.state.name.length === 0 && this.state.category.length === 0) { this.setState({ message: 'Volunteer Category and Title Required' }) }
        // else if (this.state.oldName === this.state.name) {
        //     this.setState({ message: 'Unique Title Required' })
        // }
        if (this.state.spots === 0 && this.state.waitSpots === 0 && !this.state.preReg) {
            this.setState({ message: 'Total spot count is 0, please add spots.' })
        }
        // else if (this.state.name.length === 0) { this.setState({ message: 'Title is required' }) }
        // else if (this.state.category.length === 0) { this.setState({ message: 'Volunteer Category Required' }) }
        else {
            const { name, preReg, description, start, end, spots, certified, levelTwo, levelOne, none, waitSpots, waitCertified, waitLevelOne, waitLevelTwo, waitNone, category, color } = this.state
            axios.post(`/api/addShift/${this.props.tid}`, { name, preReg, description, start, end, spots, certified, levelTwo, levelOne, none, waitSpots, waitCertified, waitLevelOne, waitLevelTwo, waitNone, category, color, editor: this.props.editor, repeat: this.state.repeat }).then(
                res => {
                    toast.success(`Saved`)
                    this.getShifts()
                    this.clearState()
                }
            )
        }
    }

    spotTotal = () => {

        let certified = this.state.certified
        let levelTwo = this.state.levelTwo
        let levelOne = this.state.levelOne
        let none = this.state.none
        let total = +certified + +levelTwo + +levelOne + +none

        let waitCertified = this.state.waitCertified
        let waitLevelTwo = this.state.waitLevelTwo
        let waitLevelOne = this.state.waitLevelOne
        let waitNone = this.state.waitNone
        let waitTotal = +waitCertified + +waitLevelTwo + +waitLevelOne + +waitNone

        this.setState({ spots: total, waitSpots: waitTotal })

    }

    handleEdit = (shift) => {
        window.scrollTo(0, 450)
        this.setState({
            edit: true,
            name: shift.name,
            preReg: shift.pre_reg,
            description: shift.description,
            date: shift.date,
            start: moment(shift.start_time).tz(this.props.zone),
            end: moment(shift.end_time).tz(this.props.zone),
            sid: shift.sid,
            spots: shift.spots,
            certified: shift.certified,
            levelOne: shift.level_one,
            levelTwo: shift.level_two,
            none: shift.none,
            waitSpots: shift.wait_spots,
            waitCertified: shift.wait_certified,
            waitLevelOne: shift.wait_level_one,
            waitLevelTwo: shift.wait_level_two,
            waitNone: shift.wait_none,
            category: shift.category,
            color: shift.color

        })
    }

    handleDuplicate = (shift) => {
        window.scrollTo(0, 450)

        this.setState({
            date: shift.date,
            start: moment(shift.start_time).tz(this.props.zone),
            end: moment(shift.end_time).tz(this.props.zone),
            name: shift.name,
            preReg: shift.pre_reg,
            description: shift.description,
            oldName: shift.name,
            spots: shift.spots,
            certified: shift.certified,
            levelOne: shift.level_one,
            levelTwo: shift.level_two,
            none: shift.none,
            waitSpots: shift.wait_spots,
            waitCertified: shift.wait_certified,
            waitLevelOne: shift.wait_level_one,
            waitLevelTwo: shift.wait_level_two,
            waitNone: shift.wait_none,
            category: shift.category,
            color: shift.color

        })
    }

    editShift = () => {
        // if (this.state.name.length === 0 && this.state.category.length === 0) { this.setState({ message: 'Volunteer Category and Title Required' }) }
        // else if (this.state.name.length === 0) { this.setState({ message: 'Title is required' }) }
        // else if (this.state.category.length === 0) { this.setState({ message: 'Volunteer Category Required' }) }
        if (this.state.spots === 0 && this.state.waitSpots === 0 && !this.state.preReg) {
            this.setState({ message: 'Total spot count is 0, please add spots.' })
        }
        else {
            const { sid, name, preReg, description, start, end, spots, certified, levelTwo, levelOne, none, waitSpots, waitCertified, waitLevelOne, waitLevelTwo, waitNone, category, color } = this.state
            axios.put(`/api/editShift`, { sid, name, preReg, start, description, end, spots, certified, levelTwo, levelOne, none, waitSpots, waitCertified, waitLevelOne, waitLevelTwo, waitNone, category, color, editor: this.props.editor }).then(
                res => {
                    toast.success(`Saved`)
                    this.getShifts()
                    this.clearState()
                }
            )
        }
    }

    verifyDelete = (sid) => this.setState({ verifyDelete: true, deleteSid: sid })


    cancelDelete = () => this.setState({ verifyDelete: false, deleteSid: '' })

    deleteShift = () => {
        axios.put(`/api/deleteShift/${this.state.deleteSid.sid}`, {shift: this.state.deleteSid, tid: this.props.tid}).then(
            res => {
                this.getShifts()
                this.setState({ shifts: res.data, verifyDelete: false, deleteSid: '' })
            }
        )
    }

    clearState = () => this.setState({
        name: '',
        preReg: false,
        date: null,
        start: null,
        end: '',
        description: '',
        spots: 0,
        certified: 0,
        levelOne: 0,
        levelTwo: 0,
        none: 0,
        edit: false,
        sid: '',
        waitSpots: 0,
        waitCertified: 0,
        waitLevelOne: 0,
        waitLevelTwo: 0,
        waitNone: 0,
        message: '',
        oldName: '',
        color: ''
    })





    render() {

        const mappedShifts = this.state.shifts
            .filter(obj => obj.name.toUpperCase().includes(this.state.searchName.toUpperCase()))
            .filter(obj => obj.category.includes(this.state.searchCategory))
            .map(shift => {
                return (
                    <div key={shift.sid} className='col-12'>
                        <div style={{ background: 'white', margin: '10px' }}>
                            <div className='shift-title'
                                style={{ background: shift.color || '#009CAE' }}
                            >
                                <h4 >{shift.category}: {shift.start} {shift.name}</h4>

                            </div>
                            <p>{shift.description}</p>
                            <div className='row'>
                                <div className='col-12 col-md-6 shift-box' >
                                    <div className='col-12 criteria'>

                                        <h4>Spots: {shift.spots}</h4>
                                        <div className='row'>
                                            <div className='col-12 col-lg-6'>
                                                <div>
                                                    Certified: {shift.certified}
                                                </div>
                                                <div>
                                                    Level Two: {shift.level_two}
                                                </div>
                                                <div>
                                                    Level One: {shift.level_one}
                                                </div>
                                                <div>
                                                    Not Specified: {shift.none}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <div className='shift-box' >
                                        {shift.wait_spots > 0 ?
                                            <div className='col-12 criteria'>
                                                <h4>Waitlist: {shift.wait_spots}</h4>
                                                <div className='row'>
                                                    <div className='col-12 col-lg-6'>
                                                        <div>
                                                            Certified: {shift.wait_certified}
                                                        </div>
                                                        <div>
                                                            Level Two: {shift.wait_level_two}
                                                        </div>
                                                        <div>
                                                            Level One: {shift.wait_level_one}
                                                        </div>
                                                        <div>
                                                            Not Specified: {shift.wait_none}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button onClick={() => this.handleEdit(shift)}>Edit</button>
                        <button onClick={() => this.handleDuplicate(shift)}>Duplicate</button>
                        <button onClick={() => this.verifyDelete(shift)}>Delete</button>
                    </div >
                )
            }
            )

        return (
            <div className='container '>
                <div className='ScheduleSetup'>
                    <div className='row justify-content-center'>

                        <div className='col-12 '>
                            <div className='tips-shiftSetup'>
                                <p ><bold className='how-to'>How-To set up shifts:</bold>  </p>
                                <p>You can set the minimum credential requirements for any volunteer category.  Volunteers can sign up for spots with lower credential requirements but not higher.  For example, if you need a total of 10 referees, of which 4 must be Certified, enter 4 for Certified and 6 for Not Specified.  This will restrict 4 spots to Certified only and the other 6 can be any credential from Not Rated to Certified (e.g., 1-Not Rated, 1--Level 1, 3-Level 2, and 1 Certified).

</p>
                                <h5>If your tournament has no credential requirements, enter the total number needed as Not Specified and leave the other fields blank.</h5>
                            </div>
                            <h4 className='tips'>Referee Shift Setup</h4>
                            
                            <legend>
                                Title *optional if using start and end times
                                <div style={{color: 'red'}}>
                                    *Order is by start time then alphabetical by title name.  
                                    {/* Recommended title format: Date, Day of Week, Letter, Start time, End time. */}
                                    </div>
                                <input type="text"
                                    name='name'
                                    style={{ width: '200px' }}
                                    value={this.state.name}
                                    onChange={this.handleChange} />
                                    
                                    
                                    
                                </legend>
                                <div className='form-label'>
                                    **You can now add start and end time.  If you do this it will check if the referee has signed up for another tournament/ shift during the same time.  If they are the system will not allow them to double book.  If you put in times but another tournament does not put in times then it will not catch the conflict.  As an effort to help all tournaments it is recommended to add the start and end time.  In the description you can state they are estimated.  
                                </div>
                                <div className='form-label'>
                                Start Date/ Time
                                <small>Time Zone: {this.props.zone}</small>
                                <Datetime 
                                displayTimeZone={this.props.zone}
                                timeConstraints={
                                    { minutes: { step: 15 }}
                                }
                                value={this.state.start}
                                onChange={e=>this.handleStart(e)} />
                                
                            </div>
                            <div className='form-label'>
                                End Date/ Time
                                <small>Time Zone: {this.props.zone}</small>
                                <Datetime 
                                displayTimeZone={this.props.zone}
                                timeConstraints={
                                    { minutes: { step: 15 }}
                                }
                                value={this.state.end}
                                onChange={e=>this.setState({end:e})} />
                                
                            </div>
                            <label>
                                <input type='checkbox'
                                    checked={this.state.preReg}
                                    name='preRed'
                                    onChange={this.handlePreReg}
                                /> Pre-Registration Shift?  Select if time slots and referee needs are not yet available.
                                </label>
                            <legend>
                                Description
                                <textarea cols="20" rows="2"
                                    style={{ margin: '0 20px', width: '90%' }}
                                    name='description'
                                    placeholder='Shift Description'
                                    value={this.state.description}
                                    onChange={this.handleChange}></textarea>
                            </legend>
                        </div>
                    </div>
                    {this.state.preReg ? <> </> :
                        <div className='row'>
                            <div className='col-12 col-md-6 shift-requirements' >
                                <h5>Shift Requirements</h5>
                                <p>{`shift total: ${this.state.spots}`}</p>
                                <legend>Certified
                                <input type="number"
                                        name='certified'
                                        value={this.state.certified}
                                        onChange={this.handleNumber} />
                                </legend>
                                <legend>Level 2
                                <input type="number"
                                        name='levelTwo'
                                        value={this.state.levelTwo}
                                        onChange={this.handleNumber} />
                                </legend>
                                <legend>Level 1
                                <input type="number"
                                        name='levelOne'
                                        value={this.state.levelOne}
                                        onChange={this.handleNumber} />
                                </legend>
                                <legend> Not Specified
                                <input type="number"
                                        name='none'
                                        value={this.state.none}
                                        onChange={this.handleNumber} />
                                </legend>
                            Place total number as Not Specified if there is no certification requirements.
                        </div>
                            <div className='col-12 col-md-6'>
                                <h5>Waitlist Requirements</h5>
                                <p>{`Waitlist total: ${this.state.waitSpots}`}</p>
                                <legend>Certified
                                <input type="number"
                                        name='waitCertified'
                                        value={this.state.waitCertified}
                                        onChange={this.handleNumber} />
                                </legend>
                                <legend>Level 2
                                <input type="number"
                                        name='waitLevelTwo'
                                        value={this.state.waitLevelTwo}
                                        onChange={this.handleNumber} />
                                </legend>
                                <legend>Level 1
                                <input type="number"
                                        name='waitLevelOne'
                                        value={this.state.waitLevelOne}
                                        onChange={this.handleNumber} />
                                </legend>
                                <legend>Not Specified
                                <input type="number"
                                        name='waitNone'
                                        value={this.state.waitNone}
                                        onChange={this.handleNumber} />
                                </legend>
                            </div>

                        </div>}
                    <div className='col-12 '>
                            <h5>Want to color code the shifts?</h5>
                            <ColorPicker 
                                setColor={this.handleColor}
                                color={this.state.color}/>
                       
                    </div>
                    {!this.props.edit &&
                    <div className='form-label'>
                        How many days do you want this shift to be duplicated for?
                        <input type="number"
                            value={this.state.repeat}
                            onChange={e=>this.setState({repeat:e.target.value})}/>
                    </div>}
                    <p style={{ color: 'red' }}>
                    {moment(this.state.end).isSame(this.state.start) ? '':'Shifts must have both the start and end times on the same day.  Please correct.'}
                        {this.state.message}</p>
                    {this.state.edit ?
                        <button onClick={this.editShift}>Save</button> :
                        <button onClick={this.addShift}>Add</button>}
                    <button onClick={this.clearState}>Clear</button>
                </div>
                <div>
                    <div className='tips'>
                        
                        <legend> Search by date or title
                        <input
                                type="text"
                                placeholder='Search date/ Shift Name'
                                name="searchName"
                                value={this.state.searchName}
                                onChange={this.handleChange} />
                            <i className="fas fa-ban" onClick={() => this.setState({ searchName: '', searchCategory: '' })}></i>
                        </legend>
                    </div>
                    <p style={{color: 'red'}}>*Order is alphabetical by title name.  Recommended title format: Date, Day of Week, Letter, Start time, End time. Example: 10/23 Friday A 9am to 11 am or 10/23 Friday B 11 am to 2pm</p>
                    {mappedShifts}
                </div>
                <Modal open={this.state.verifyDelete} center showCloseIcon={true} onClose={this.cancelDelete} closeIconSize={20} closeOnEsc={true} >
                    <h5>{this.state.deleteSid.category}: {this.state.deleteSid.name}</h5>
                    Are you sure you want to delete this shift?  Once deleted shift and registered volunteers for the shift  CAN NOT be recovered.
            <h5>Please make sure to email all registered volunteers before deleting</h5>
                    <div>
                        <Link to={`/desk/scheduleByRef`} >
                            <button>Go To Email</button>
                        </Link>
                    </div>

                        
                    <button onClick={this.deleteShift}>Confirm</button>
                    <button onClick={this.cancelDelete}>Cancel</button>
                </Modal>
            </div >
        )
    }
}

const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name,
        zone: reduxState.time_zone,
        editor: reduxState.ref_id
    }
}
export default connect(
    mapStateToProps,
)(ScheduleSetup)
