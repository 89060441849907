import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

const MergeToParentTournament = props => {
    const [ptid, setPtid] = useState(null)
    const [name, setName] = useState('')
    const [message, setMessage] = useState('')
    const [verify, setVerify] = useState(false)
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)
    
    useEffect(
        () => { getParentTournament() }, [tid])

    const getParentTournament = () => {
        setLoader(true)
        axios.post(`/api/getParentTournament/`, { tid }).then(res => {
            setLoader(false)
            setPtid(res.data.parent_tournament)
            setName(res.data.name)
        })
    }
    const mergeData = () => {
        if(tid === null) return 
        if(!tid) return
        setMessage('Merging Matches')
        axios.put(`/api/mergeToParentTournament/`, { tid, ptid }).then(res => {
            setMessage('merged')
            setVerify(false)
        })
    }
    return(
        <div>
            {ptid ?
            <>
            <h3>Parent Tournament: {name}</h3>
            <button onClick={()=>setVerify(!verify)}> Merge All Referee and Match Starter Data to {name}</button>
            {verify ? <button className='register-button' onClick={mergeData}>Yes, merge data</button>:null}
            <h3>{message}</h3>
            </>:
            loader ? 'Looking for parent tournament...':
            'Sorry no parent tournament was found.'
            }
        </div>
    )
}
export default MergeToParentTournament