import React, {useState} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'


const MappedNames = (props)  => {
    const [verifyDelete, setVerifyDelete] = useState(false)

    let {spot, i} = props

    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)


    const removeVolunteer = (shift_id) => {
        axios.put(`/api/removeRefTime/${shift_id}`, { tid }).then(
            res => {
                props.get()
            }
        )
    }

    const handleVerifyDelete = () => setVerifyDelete(verifyDelete => !verifyDelete)
    return(
    <div style={{ background: 'white', display: 'flex', alignItems: 'center' }} >
        {!props.waitlist && <i className="fas fa-arrow-right small-button" onClick={()=>props.moveToWaitlist(spot.shift_id)}></i>}
        {props.waitlist && <i className="fas fa-arrow-left small-button" onClick={()=>props.moveOffWaitlist(spot.shift_id)}></i>}
    <div className=''>{++i}.</div>
    <div className=''>
        {spot.volunteer_name}
    </div>
    {verifyDelete ? 
    <>
    <button onClick={() => removeVolunteer(spot.shift_id)}><small>Verify Remove</small></button>
    <button onClick={handleVerifyDelete}><small>cancel</small></button>
    </>:
    <i className="fas fa-user-minus pointer" onClick={handleVerifyDelete}></i>}

    </div>
    )
}
export default MappedNames