import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Link } from 'react-router-dom'

const SelectVolunteersToApprove = props => {
    const [approved, setApproved] = useState([])
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)


    const searchForName = async (name) => {

        await setSearch(name)
        if (search.length > 2) {
            getName()
            setLoader(true)
        }
        else {
            setSearchResults([])
        }
    }

    const getName = () => {
        axios.post(`/api/getRefereeName?search=${search}`, { tid }).then(res => {
            setSearchResults(res.data)
            setLoader(false)
        })

    }

    const handleAddApproval = ref_id => {
        props.addApproval(ref_id)
        setSearchResults([])
        setSearch('')
    }

    const mappedSearchResults = searchResults.map(name => {
        return (
            <div key={name.ref_id} className='search-results'>
                <div style={{ width: '20%' }}>
                    <div style={{ cursor: 'pointer' }}
                        onClick={() => handleAddApproval(name.ref_id)}>
                        <button>Add</button>
                    </div>
                </div>
                <div style={{ cursor: 'pointer', width: '60%' }}
                    onClick={() => handleAddApproval(name.ref_id)}>
                    {name.first} {name.last} - {name.credentials}
                </div>

            </div>
        )
    })


    return (
        <div>
            <div>
                <h4>Have referees you want to pre-approve for the tournament?</h4>
                <p>If your tournament is active an email will be send to the referee notifying them of their pre-approval.  If in draft mode you can email them once the tournament is active.</p>
                <label>
                    Search for names to add to approved list
                    <input
                        type="text"
                        placeholder='Start typing name...'
                        value={search}
                        onChange={e => searchForName(e.target.value)} />
                </label>
                <div>
                <Link to={`/desk/emailapproved`} >
                        <button
                        >Email Referees</button>
                    </Link>
                </div>
                {loader ? <img width="100" src={require('../../../img/15.gif')} alt='players' /> : ''}
                {mappedSearchResults}
            </div>
        </div>
    )
}
export default SelectVolunteersToApprove