import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'


const AnswerQuestion = props => {
    const [question, setQuestion] = useState({})
    const [answer, setAnswer] = useState('')
    const [submitted, setShowSubmitted] = useState(false)
    const [noQuestion, setNoQuestion] = useState(false)


    const ref_id = useSelector(state => state.ref_id);

    useEffect(
        () => { getQuestion() }, [props.match.params.url])

    const getQuestion = () => {
        axios.get(`/api/getQuestion/${props.match.params.url}`).then(
            res => {
                if (res.data.question) {
                    setQuestion(res.data)
                } else {
                    setNoQuestion(true)
                }

            }
        )
    }

    const submitAnswer = () => {
        axios.put(`/api/answerQuestion/`, { ref_id, qid: question.qid, answer }).then(
            res => {
                setAnswer('')
                setShowSubmitted(true)
            }
        )
    }

    return (
        <div className='container'>
            <h4>Question</h4>
            <p>{question.question}</p>
            <div>
                {noQuestion ? 'Sorry the question can not be found.  This can happen if it has already been answered or if the question was deleted.' :
                    <div>
                        <h4>Answer</h4>
                        <textarea type="text"
                            value={answer}
                            style={{ padding: '0 20px' }}
                            onChange={e => setAnswer(e.target.value)} />
                        <button onClick={submitAnswer}>Submit</button>
                        <div>
                            {submitted ? 'Your answer has been submitted.  Your help is greatly appreciated.' : ''}
                        </div>
                    </div>}
            </div>
        </div>
    )
}
export default AnswerQuestion