import React from 'react'
import BarcodeAllReferees from './BarcodesAllReferees'
import PastBarcodes from './PastBarcodes'

const BarcodeListDeskView = () => {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <PastBarcodes />
                </div>
                <div className='col-12 col-md-6'>
                    <BarcodeAllReferees />
                </div>
            </div>
        </div>
    )
}

export default BarcodeListDeskView