import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setRankTournament } from '../../../ducks/reducer'
import Moment from 'react-moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom'


const TournamentList = props => {
    const [list, setList] = useState([])

    const dispatch = useDispatch();


    useEffect(
        () => { getTournament() }, [])

    const getTournament = () => {
        axios.get(`/api/ranking/getTournaments`).then(
            res => {
                setList(res.data)
            }
        )
    }

    const selectTournament = t => {
        dispatch(setRankTournament(t))
        props.history.push('/ranking/eventsetup')

    }

    return (
        <div>
            <h3>Tournament List</h3>
            <TableContainer component={Paper}>
                <Table aria-label="tournament list">
                    <TableHead>
                        <TableRow>
                            <TableCell>Select</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell align="left">Location</TableCell>
                            <TableCell align="left">Sanctioned</TableCell>
                            <TableCell align="left">Tier</TableCell>
                            <TableCell align="left">Start</TableCell>
                            <TableCell align="left">End</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((row, i) => (
                            <TableRow key={i} >
                                <TableCell component="th" scope="row">
                                    <button onClick={() => selectTournament(row)}>select</button>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="left">{row.location}</TableCell>
                                <TableCell align="left">{row.sanctioned ? 'yes' : 'no'}</TableCell>
                                <TableCell align="left">{row.tier}</TableCell>
                                <TableCell align="left">
                                    <Moment format="MM/DD/YYYY" >
                                        {row.start_date}
                                    </Moment>
                                </TableCell>
                                <TableCell align="left"><Moment format="MM/DD/YYYY" >
                                    {row.end_date}
                                </Moment></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


        </div>
    )
}
export default withRouter(TournamentList)