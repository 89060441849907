import React from 'react'
import SignupsPublicDetail from './SignupsPublicDetail'


const SignupsPublic = props =>{
    
        const mappedSpot = props.spots.map((spot, i) => (
            <SignupsPublicDetail 
                key={'pp:' + i}
                i={i}
                spot={spot}
                removeRef={props.removeRef}/>
        ))


        return (
            <>
                {mappedSpot}
            </>
        )

    }
export default SignupsPublic