import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import VolunteerNameList from './VolunteerNameList'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import AddVolunteerNew from '../AddVolunteerNew'


const VolunteerList = () => {
    const [names, setNames] = useState([])
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)

    useEffect(
        () => { getVolunteers() }, [tid, ref_id])

    const getVolunteers = () => {
        setLoader(true)
        axios.post(`/api/volunteer/getVolunteerListWithShiftCount`, {tid, ref_id}).then(
            res => {
                setLoader(false)
                setNames(res.data)
            }
        )
    }

        return (
            <div className='container-fluid' >
                <h3>Add, Edit or Remove Volunteers</h3>
                <AddVolunteerNew get={getVolunteers}/>
                {loader ?
                    <LinearProgress color="secondary"/>:''}


<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
        {names.map((name, i)=>(
                <VolunteerNameList key={i} name={name} tid={tid} get={getVolunteers}/>
                ))}
        </TableBody>
        </Table>
        </TableContainer>
                    
            </div>
        )
    }

export default VolunteerList

