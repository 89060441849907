import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';


const RemoveAllTags = props => {
    const [verify, setVerify] = useState(false)
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)


    const removeTags = () => {
        setLoader(true)
        axios.put(`/api/board/removeAllTags`, {tid}).then(
            res => {
                setLoader(false)
                setVerify(false)
                toast.success('Tags Removed')
                if(props.emit){props.emit()}
            }
        )
    }

    return (
        <div >
            <button onClick={()=>setVerify(!verify)}>Clear All Tags</button>
            {verify && !loader ? <button className='register-button' onClick={removeTags}> Yes, Clear all tags</button>:null}
            {loader ? <CircularProgress/>:''}
        </div>
    )
}
export default RemoveAllTags