import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Moment from 'react-moment';
import './ScheduleLog.css'


const ScheduleLog = (props) => {
    const [signupLog, setSignupLog] = useState([])
    const [removedLog, setRemovedLog] = useState([])
    let [firstSearch, setFirstSearch] = useState('')
    let [lastSearch, setLastSearch] = useState('')
    let [titleSearch, setTitleSearch] = useState('')
    let [searchCategory, setSearchCategory] = useState('')
    const [showSignups, setShowSignups] = useState(true)
    const [showDeleted, setShowDeleted] = useState(true)


    const tid = useSelector(state => state.tid)
    const zone = useSelector(state => state.zone)

    useEffect(() => {
        getSchedulingLog()
        getRemovedLog()
    }, [])

    const getSchedulingLog = () => {

        axios.post(`/api/getSchedulingLog/${tid}`, {category:props.category}).then(res => {
            setSignupLog(res.data)
        })
    }

    const getRemovedLog = () => {
        axios.post(`/api/getSchedulingRemovedLog/${tid}`, {category:props.category}).then(res => {
            setRemovedLog(res.data)
        })
    }

    const clearSearch = () => {
        setLastSearch('')
        setFirstSearch('')
        setTitleSearch('')
        setSearchCategory('')
    }


    const mappedSignup = signupLog
        .filter(obj => obj.first.toUpperCase().includes(firstSearch.toUpperCase()))
        .filter(obj => obj.last.toUpperCase().includes(lastSearch.toUpperCase()))
        .filter(obj => obj.name.toUpperCase().includes(titleSearch.toUpperCase()))
        .filter(obj => obj.category.includes(searchCategory))

        .map((log, i) =>  (
                <div key={'s'+i} className='log-list'>
                    <div style={{ display: 'flex', background: '#009CAE' }}>
                        <div className='signup-credential-box'>

                            <div style={{ margin: '3px', width: '1em' }} className={log.credentials === 'Certified' ? 'ref-list certified' : log.credentials === 'Level 2' ? 'ref-list level2' : log.credentials === 'Level 1' ? 'ref-list level1' :

                                'ref-list Volunteer'}>
                                {log.credentials === 'Certified' ? 'C' :
                                    log.credentials === 'Level 2' ? '2' : log.credentials === 'Level 1' ? '1' : '-'}
                            </div></div>
                        <div className='log-title'>
                            {log.last}, {log.first}
                        </div>
                        
                    </div>
                    <div className='log-title'>
                            {log.waitlist ? 'WAITLIST':''} {log.category} {log.name}
                            <br />
                            {` ${log.start} - ${log.end}`}
                        </div>
                    <div className='log-times'>
                        Signup:
                    {log.signup_time ?
                            <Moment format="MM/DD/YYYY h:mm a" >
                                {log.signup_time}
                            </Moment> : 'not recorded'}
                    </div>
                    

                </div>
            )
        )

    const mappedRemoved = removedLog
        .filter(obj => obj.first_last.toUpperCase().includes(firstSearch.toUpperCase()))
        .filter(obj => obj.name.toUpperCase().includes(titleSearch.toUpperCase()))
        .filter(obj => obj.category.includes(searchCategory))

        .map((log, i) => (
                <div key={'r'+i} className='log-list'>
                    <div style={{ display: 'flex', background: '#51BB85' }}>
                        <div className='signup-credential-box'>

                            <div style={{ margin: '3px', width: '1em' }} className={log.credentials === 'Certified' ? 'ref-list certified' : log.credentials === 'Level 2' ? 'ref-list level2' : log.credentials === 'Level 1' ? 'ref-list level1' :

                                'ref-list Volunteer'}>
                                {log.credentials === 'Certified' ? 'C' :
                                    log.credentials === 'Level 2' ? '2' : log.credentials === 'Level 1' ? '1' : '-'}
                            </div></div>
                        <div className='log-title'>
                            {log.last}, {log.first}
                        </div>
                        
                    </div>
                    <div className='log-title'>
                    {log.waitlist ? 'WAITLIST':''} {log.category || log.notes} {log.name}
                            {` ${log.start} - ${log.end}`}
                        </div>
                    <div className='log-times'>
                        Signup:
                    {log.signup_time ?
                            <Moment format="MM/DD/YYYY h:mm a" >
                                {log.signup_time}
                            </Moment> : 'not recorded'}
                    </div>
                    <div className='log-times'>
                        Removed:
                <Moment format="MM/DD/YYYY h:mm a" >
                            {log.removal_time}
                        </Moment>
                    </div>
                    <div>
                        {log.note} {log.removed_by ? 'Removed By:' + log.removed_by:''}
                    </div>
                </div>
            )
        )


    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12'>
                    <h4>Scheduling Log {props.category === 'other' ? 'Volunteer':props.category}</h4>
                    <p>This includes all shift types, use filter(s) if needed.</p>
                </div>
                <div className='col-12 search'>
                    <input
                        type="text"
                        placeholder='Search by Name'
                        name="firstSearch"
                        value={firstSearch}
                        onChange={e => setFirstSearch(e.target.value)} />

                    <input
                        type="text"
                        placeholder='Search by Title'
                        value={titleSearch}
                        onChange={e => setTitleSearch(e.target.value)} />
                    <button onClick={clearSearch}>Clear Search</button>
                        
                </div>
            </div>
            <div className='row'>
                <div className='col-6 col-md-6'>
                    <h4 className='dashboard-title'>Signups</h4>
                    <button onClick={() => setShowSignups(showSignups => !showSignups)}>{showSignups ? 'hide' : 'show'}</button>
                    {showSignups ?
                        <div>
                            {mappedSignup}
                        </div> : ''}
                </div>
                <div className='col-6 col-md-6'>
                    <h4 className='dashboard-title-operations'>Deleted</h4>
                    <button onClick={() => setShowDeleted(showDeleted => !showDeleted)}>{showDeleted ? 'hide' : 'show'}</button>
                    {showDeleted ?
                        <div>
                            {mappedRemoved}
                        </div> : ''}
                </div>
            </div>

        </div>
    )
}

export default ScheduleLog