import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import NotificationNameRow from './NotificationNameRow'
import AddNotificationContact from './AddNotificationContact';

const NotificationNameList = props => {
    const [list, setList] = useState([])
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getList() }, [tid])

    const getList = () => {
        axios.get(`/api/getNotificationContactList/${tid}`).then(
            res => {
                setList(res.data)
            }
        )
    }
    const updateContact = (id, type, value) => {
        axios.put(`/api/updateNotificationContact/`, {tid, id, type, value}).then(
            res => {
                setList(res.data)
            }
        )
    }

    return(
        <div style={{textAlign: 'left', border: '2px solid black', background: 'white', color: 'black', padding: '15px'}}>
            <h3>Notification Contact List</h3>
            <p>Select what text messages the contact should receive.  Contact must be on duty to receive messages.</p>
            <h5>*Phone number must include country code (USA = 1)</h5>
            <p>Click save button to update name or phone.  Checkboxes with auto save</p>
            <TableContainer component={Paper}>
      <Table aria-label="Contact List">
        <TableHead>
          <TableRow>
          <TableCell>On Duty</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="left">Phone</TableCell>
            <TableCell align="left">2nd Call</TableCell>
            <TableCell align="left">Medical</TableCell>
            <TableCell align="left">TD</TableCell>
            <TableCell align="left">Desk</TableCell>
            <TableCell align="left">Head Ref</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row, i) => (
            <NotificationNameRow key={'l'+i} row={row} getList={getList} update={updateContact} loader={loader}/>
          ))}
          <AddNotificationContact getList={getList} tid={tid}/>
        </TableBody>
      </Table>
    </TableContainer>

        </div>
    )
}
export default NotificationNameList