import React, { Component } from 'react'
import axios from 'axios'
import { connect } from "react-redux"
import './RefereeSchedule.css'
import '../RefList/RefList.css'

class PrintSchedule extends Component {
    constructor() {
        super()
        this.state = {
            schedule: [],
            firstSearch: '',
            lastSearch: '',
            categoryFilter: '',
            nameFilter: ''
        }
    }

    componentDidMount() {
        this.getSchedule()
    }

    getSchedule = () => {
        axios.get(`/api/getShiftSpots/${this.props.tid}`).then(
            res => {
                this.setState({ schedule: res.data })
            }
        )
    }

    clearSearch = () => this.setState({ firstSearch: '', lastSearch: '', categoryFilter: '', nameFilter: '' })


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    render() {
        const mappedShifts = this.state.schedule
            .filter(obj => obj.category.includes(this.state.categoryFilter))
            .filter(obj => obj.name.toUpperCase().includes(this.state.nameFilter.toUpperCase()))

            .map((shift, i) => {
                let waitLength = shift.waitlist.length
                const mappedSpot = shift.signup
                    .filter(obj => obj.first.toUpperCase().includes(this.state.firstSearch.toUpperCase()))
                    .filter(obj => obj.last.toUpperCase().includes(this.state.lastSearch.toUpperCase()))
                    .map((spot, i) => {

                        return (
                            <div key={spot.spot_id} style={{ background: 'white', display: 'flex' }} >
                                <div className=''>{++i}.</div>
                                <div className='signup-credential-box'>

                                    <div style={{ margin: '3px', width: '1em' }} className={spot.credentials_verified === 'Certified' ? 'ref-list certified' : spot.credentials_verified === 'Level 2' ? 'ref-list level2' : spot.credentials_verified === 'Level 1' ? 'ref-list level1' : 'ref-list none'}>
                                        {spot.credentials === 'Certified' ? 'C' :
                                            spot.credentials === 'Level 2' ? '2' : spot.credentials === 'Level 1' ? '1' : 'V'}
                                    </div></div>
                                <div className=''>
                                    {spot.last}, {spot.first}
                                    {spot.captain ? ' - CAPTAIN' : ''}
                                </div>

                            </div>
                        )
                    })
                const mappedWaitlist = shift.waitlist
                    .filter(obj => obj.first.toUpperCase().includes(this.state.firstSearch.toUpperCase()))
                    .filter(obj => obj.last.toUpperCase().includes(this.state.lastSearch.toUpperCase()))
                    .map((waitList, i) => {

                        return (
                            <div key={waitList.spot_id} style={{ background: 'white', display: 'flex' }} >
                                <div className=''>{++i}.</div>
                                <div className='signup-credential-box'>

                                    <div style={{ margin: '3px', width: '1em' }} className={waitList.credentials_verified === 'Certified' ? 'ref-list certified' : waitList.credentials_verified === 'Level 2' ? 'ref-list level2' : waitList.credentials_verified === 'Level 1' ? 'ref-list level1' : 'ref-list none'}>
                                        {waitList.credentials === 'Certified' ? 'C' :
                                            waitList.credentials === 'Level 2' ? '2' : waitList.credentials === 'Level 1' ? '1' : 'V'}
                                    </div></div>
                                <div className=''>
                                    {waitList.first} {waitList.last}
                                </div>

                            </div>
                        )
                    })
                return (
                    <div key={shift.sid} style={{ background: 'white', margin: '10px' }} >
                        <div className='col-12'>
                            <div className='shift-title'
                                style={{ background: shift.color || '#009CAE' }}>
                                <h6 >{shift.category}: {shift.name}</h6>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>

                                    <div >
                                        <div className=''>
                                            {mappedSpot}

                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6'>



                                    <div >
                                        {waitLength > 0 ?
                                            <div className=''>

                                                <h6>Waitlist</h6>
                                                {mappedWaitlist}

                                            </div> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )
            })

        return (
            <div className='AddRefToSchedule container' >
                <div className='row'>
                    <div className='col-12 tips'>

                        <input
                            type="text"
                            placeholder='Search by Last Name'
                            name="lastSearch"
                            value={this.state.lastSearch}
                            onChange={this.handleChange} />
                        <input
                            type="text"
                            placeholder='Search by First Name'
                            name="firstSearch"
                            value={this.state.firstSearch}
                            onChange={this.handleChange} />
                        <input
                            type="text"
                            placeholder='Search by Shift Name'
                            name="nameFilter"
                            value={this.state.nameFilter}
                            onChange={this.handleChange} />

                        <form>
                            <legend></legend>
                            <label>
                                Filter By Volunteer Category:
                                    <input type='radio'
                                    checked={this.state.categoryFilter === 'Referee'}
                                    name='categoryFilter'
                                    value='Referee'
                                    onChange={this.handleChange}
                                /> Referee </label>
                            <label>
                                <input type='radio'
                                    checked={this.state.categoryFilter === 'Line Judge'}
                                    name='categoryFilter'
                                    value='Line Judge'
                                    onChange={this.handleChange}
                                /> Line Judge </label>
                            <label>
                                <input type='radio'
                                    checked={this.state.categoryFilter === 'other'}
                                    name='categoryFilter'
                                    value='other'
                                    onChange={this.handleChange}
                                /> Other </label>
                            <label>
                                <input type='radio'
                                    checked={this.state.categoryFilter === ''}
                                    name='categoryFilter'
                                    value=''
                                    onChange={this.handleChange}
                                /> Show All </label>

                        </form>


                        <span> <i className="fas fa-ban" onClick={this.clearSearch}></i> Clear Search</span>
                    </div>

                    <div className='col-12'>
                        <div className='col-12'>

                            {mappedShifts}
                        </div>
                    </div>
                </div>
            </div>




        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name,
        email: reduxState.email
    }
}
export default connect(
    mapStateToProps,
)(PrintSchedule)