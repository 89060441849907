import React from 'react'
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'

const scheduleDashboard = () => {

    return (
        <div>
            <Link to={`/desk/scheduleSetup`} >
                <p>Setup</p>
            </Link>
            <Link to={`/signup`} >
                <p>Individual Signup</p>
            </Link>

            <Link to={`/desk/editSchedule`} >
                <p>Schedule Referees</p>
            </Link>
            <Link to={'/desk/scheduleByRef'}>Schedule by Referee</Link>
        </div>
    )

}
export default withRouter(scheduleDashboard)