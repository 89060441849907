import React from 'react'
import ListOfTournaments from '../TournamentListing/ListOfTournaments'
import './HomePage.css'


const HomePage = () => {
    return (
        <div className='container-fluid HomePage' >
            <div className='row' >
                <div className='col-12 col-md-4' >
                    <div style={{ background: 'white' }}>
                        <img className='img-volunteer'
                            src={'https://res.cloudinary.com/shonda/image/upload/v1576768567/PickleballDesk/22986_ihhjfq.jpg'} alt='volunteer' />
                        <div className='volunteer-opportunities'>Volunteer Opportunities</div>
                        <div className='tips'>View the list below to sign up to referee or volunteer at a tournament.  </div>
                        <div className='tournament-list'>
                            <ListOfTournaments />
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-8' >
                    <div style={{ background: 'white' }}>
                        <img className=''

                            src={'https://res.cloudinary.com/shonda/image/upload/v1575480155/PickleballDesk/Volunteer_jmccv6.png'} alt='Volunteer' />
                        <h2>Welcome to pickleballdesk.com</h2>
                        <h5>Your source for Pickleball Volunteer Management</h5>
                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <p>PickleballDesk.com was designed by pickleball players, referee coordinators, and volunteer coordinators with the intention of streamlining volunteer management.
                    </p>
                                <p>
                                    This tool provides everything needed before, during and after the tournament.  You set up your volunteer needs and volunteers can sign up.   Communicate with volunteers using email and text messaging.  Great for Referee, Line Judge, and Volunteer management.
                    </p>
                                <p>Successful tournaments have happy volunteers.  Use pickleballdesk.com and keep your volunteers happy!</p>


                            </div>
                            <div className='col-12 col-md-6'>
                                <img className='img-coordinator'
                                    src={'https://res.cloudinary.com/shonda/image/upload/v1583252919/PickleballDesk/survey_rufjds.jpg'} alt='Survey Results' />
                            </div>
                        </div>

                        <div className='col-12 '>
                            <h4 className='homepage-title'>Referee, Line Judge, and Volunteer Signup</h4>
                            <div className='row'>
                                <div className='col-12 col-md-4 '>
                                    <img className='img-coordinator'
                                        src={'https://res.cloudinary.com/shonda/image/upload/v1576727380/PickleballDesk/referee-volunteer_xlxe8y.png'} alt='Volunteers' />
                                </div>
                                <div className='col-12 col-md-8'>
                                    Set up shifts to show your volunteers what volunteer opportunities are available.  The platform was made specifically for pickleball so it includes credential based signup options for referees.  For example if you want some spots to be saved for certified referees you can set this as a parameter.
                                <ul className='home-list'>
                                        <li>Credential Based Options</li>
                                        <li>Easily Communicate with Volunteers Using Email and Text Messaging</li>

                                    </ul>
                                </div>

                            </div>
                            <div className='col-12'>
                                <h4 className='homepage-title'>Referee Coordinating</h4>
                                <div className='row'>
                                    <div className='col-12 col-md-8'>
                                        <h5>Digital Referee Board</h5>
                                        No standing all day and no losing magnets.  Assign referees to courts using your computer.  The system will text the referee to notify them of their match.  Referees can view ref court assignments from the comfort of their referee lounge.
                                <ul className='home-list'>
                                            <li>Text Notification</li>
                                            <li>Track all court assignments and time</li>
                                            <li>Referees can update their status</li>
                                            <li>Time based queue rotates the referees through the queue</li>
                                            <li>Advanced reporting including match count by day/ tournament, medal matches, and more</li>
                                        </ul>
                                    </div>

                                    <div className='col-12 col-md-4'>
                                        <img className='img-coordinator'
                                            src={'https://res.cloudinary.com/shonda/image/upload/v1576727380/PickleballDesk/ref-desk_ww05q3.png'} alt='Referee Coordinator' />
                                    </div>
                                    <div className='col-12'>
                                        <h5>Hardware Requirements</h5>
                                        To use the tool at your tournament you will need a laptop, tablet or iPad with internet access.   To increase the experience you can have an additional tablet or laptop for referees to update their status and a screen to show the referee board and queue in the referee lounge.

                                </div>
                                </div>


                            </div>
                            <div className='col-12'>
                                <h4 className='homepage-title'>Line Judge Coordinating</h4>
                                <div className='row'>
                                    <div className='col-12 col-md-12'>
                                        <h5>Digital Line Judge Organizer</h5>
                                        Track the status of your line judges including if they are availabel, what group they are in or what court they are judging.
                                <ul className='home-list'>
                                            <li>Text Notification</li>
                                            <li>Track all court assignments and time</li>
                                            <li>Line Judge Checkin</li>
                                            <li>Time based queue rotates the groups through the queue</li>
                                            <li>Advanced reporting including match count by day/ tournament</li>
                                        </ul>
                                    </div>


                                </div>


                            </div>
                            <div className='col-12'>
                                <h4 className='homepage-title'>Volunteer Manager</h4>
                                <div className='row'>
                                    <div className='col-12 col-md-12'>
                                        <h5>Know the status of your volunteers</h5>
                                        Have volunteers who are helping at the food table?  Or posting scores? Or any other volunteer position?  Easliy know when they are on duty and who is signed up for shifts
                                        
                                <ul className='home-list'>
                                            <li>Volunteer Checkin/ Checkout</li>
                                            <li>Text/ Email Volunteers</li>

                                        </ul>
                                    </div>


                                </div>


                            </div>
                            <div className='col-12'>
                                <h4 className='homepage-title'>Do Referees like the tool??</h4>
                                <div className='row'>
                                    <div className='col-12'>
                                        <h5>
                                            YES... It's safe to say they love the tool!
                                    </h5>
                                        <p>"I received a text message on my way to the courts.  It was awesome knowing what court I would be on."
                                    </p>

                                        <p>"I was skeptical at first but I love this system."</p>
                                        <p>"Having a tv monitor in the referee lounge was nice so I knew my queue position."</p>
                                        <p> "Knowing I would receive a text message notifying me of court assignments allowed me to watch matches when I was not refereeing."</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <h4 className='homepage-title'>Want more information?</h4>
                                <div className='row'>
                                    <div className='col-12'>
                                        <p>
                                            To use the platform,  request a demo, or for pricing information please contact Shonda Davidson at pickleballdesk@gmail.com.
                                    </p>
                                        <p>The platform is easy to learn and includes several how to tutorial videos.  In less then 25 minutes you can learn everything you need to run pickleballdesk at a tournament.  </p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12'>
                                <h4 className='homepage-title'>About</h4>
                                <div className='row'>
                                    <div className='col-12'>
                                        <p>This tool was created for referee coordinators and pickleball tournaments.  All code was specifically written with pickleball in mind.  Special thanks to Terry and Sandy Brown, USAPA Nationals Referee Coordinators and USAPA certified referees,  Marsha Freso, USAPA Certified Referee and Referee Coordinator, along with many other referees and tournament directors who had input in creating a tool that would improve referee and volunteer management.</p>

                                        <p>If you have an idea or suggestion for improvement please contact developer (and pickleball player) Shonda Davidson at pickleballdesk@gmail.com</p>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>


    )
}
export default HomePage

