import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Moment from 'react-moment';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import { toast } from 'react-toastify';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const AddPlayer = props => {
    const [first, setFirst] = useState('')
    const [last, setLast] = useState('')
    const [gender, setGender] = useState('')
    const [birth, setBirth] = useState(null)



    const addPlayer = () => {
        axios.post(`/api/ranking/addPlayer`, {
            first, last, gender, birth
        }).then(res => {
            clearState()
        })
    }
    const handleBirth = (date) => {
        setBirth(date)
    }

    const clearState = () => {
        setFirst('')
        setLast('')
        setGender('')
        setBirth(null)
    }

    return (
        <div>
            <h3>Add Player</h3>
            <>
                <label>
                    First
                <input type="text"
                        value={first}
                        onChange={e => setFirst(e.target.value)} />
                </label>
                <label>
                    Last
                <input type="text"
                        value={last}
                        onChange={e => setLast(e.target.value)} />
                </label>
                <label>
                    Gender:
                    <select
                        value={gender}
                        className='dropdown'
                        onChange={e => setGender(e.target.value)}
                    >
                        <option key={'a6'}>
                            Select...
                         </option>
                        <option key={'a1'}>
                            F
                         </option>
                        <option key={'a2'}>
                            M
                         </option>
                    </select>

                </label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        label="birthday"
                        format="MM/dd/yyyy"
                        value={birth}
                        onChange={handleBirth}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </>
            <button onClick={addPlayer}>Save</button>

        </div>
    )
}
export default AddPlayer