import React, { Component } from 'react';
import axios from 'axios'
import Modal from 'react-responsive-modal';
import './TextPlayers.css'





class TextPlayers extends Component {
    constructor() {
        super()
        this.state = {
            edit: false,
            playerList: [],
            id: '',
            name: '',
            club: '',
            phone: '',
            search: '',
            data: [],
        }

    }

    componentDidMount() {
        this.getPlayers()
    }

    getPlayers = () => {
        axios.get(`/api/getAllPlayers`).then(
            res => {
                this.setState({ playerList: res.data })
            }
        )
    }

    edit = (obj) => {
        this.setState({
            edit: true,
            id: obj.id,
            name: obj.name,
            club: obj.club,
            phone: obj.phone
        })

    }

    save = async () => {
        axios.put(`/api/editPlayer`, { id: this.state.id, club: this.state.club, phone: this.state.phone }).then(
            res => {
                this.setState({
                    playerList: res.data,
                    edit: false,
                    id: '',
                    name: '',
                    club: '',
                    phone: ''
                })
            }
        )
    }

    cancel = () => {
        this.setState({
            edit: false,
            id: '',
            name: '',
            club: '',
            phone: ''
        })
    }

    sendToTxtList = () => console.log('text')

    sendText = () => console.log('send text')

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }



    render() {

        const mappedPlayers = this.state.playerList

            .filter(obj => obj.name.toUpperCase().includes(this.state.search.toUpperCase()))
            .map(player => {
                return (
                    <div className='col-12 player-table' key={player.id} >

                        <div className='col-1'>
                            <i className="fas fa-plus-circle" onClick={() => this.sendToTxtList(player)}></i>
                        </div>
                        <div className='col-5' onClick={() => this.sendToTxtList(player)}>
                            <p>{player.name} </p>
                        </div>

                        <div className='col-4'>
                            {player.club}
                        </div>

                        <div className='col-1'>
                            {player.phone ? 'yes' : 'no'}
                        </div>
                        <div className='col-1'>
                            <i className="fas fa-user-edit" onClick={() => this.edit(player)}> </i>
                        </div>
                    </div>



                )
            })


        return (
            <div className="">
                <div className='ref-status-container'>
                    Search
                    <input
                        type="text"
                        name="search"
                        value={this.state.search}
                        onChange={this.handleChange} />

                    {mappedPlayers}

                    {this.state.edit ?
                        <Modal open={this.edit} center showCloseIcon={false} closeIconSize={2}>
                            <h6>Edit Player Info</h6>
                            <h2>{this.state.name}</h2>
                            <label>  Club
                                <input type="text"
                                    placeholder='Club'
                                    name='Club'
                                    value={this.state.club}
                                    onChange={this.handleChange} />
                            </label>
                            <p><label> Phone
                                <input type="integer"
                                    placeholder='Phone Num- no spaces'
                                    name='phone'
                                    value={this.state.phone}
                                    maxLength='10'
                                    onChange={this.handleChange} />
                            </label>
                            </p>

                            <div>
                                <button onClick={this.save}><i className="far fa-save"></i>Save</button>
                                <button onClick={this.cancel}><i className="fas fa-ban" ></i>Cancel</button>
                            </div>


                        </Modal>
                        : ''}



                </div>


            </div>


        )
    }
}


export default TextPlayers;