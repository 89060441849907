import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import ColorPicker from '../../utlities/ColorPicker'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';


const AddVenueItem = props => {
    const [title, setTitle] = useState('')
    const [details, setDetails] = useState('')
    const [link, setLink] = useState('')
    const [image, setImage] = useState('')
    const [color, setColor] = useState('')
    const [order, setOrder] = useState(1)
    const [show, setShow] = useState(true)
    const [showDialog, setShowDialog] = useState(false)
    const [verifyDelete, setVerifyDelete] = useState(false)

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { setProps() }, [props.id, showDialog])

    const save = () => {
        if(props.id){
            saveEdit()
        }else {
            saveNew()
        }
    }

    const saveNew = () => {
        axios.post(`/api/eventGuide/addVenueItem`, { vid: props.vid, title, details, link, image, color, order,show }).then(res => {
            clearState()
            setShowDialog(false)
            if(props){props.get()}
        })
    }
    const saveEdit = () => {
        axios.put(`/api/eventGuide/editVenueItem`, { id: props.item.id, title, details, link, image, color, order,show }).then(res => {
            clearState()
            setShowDialog(false)
            if(props){props.get()}
        })
    }
    const handleDelete = () => {
        axios.post(`/api/eventGuide/deleteVenueItem`, { id: props.item.id }).then(res => {
            clearState()
            setShowDialog(false)
            if(props.get){props.get()}
        })
    }

    const setProps = () => {
        if(props.id && showDialog){
            let {title, details, link, image, color, order,show} = props.item
            setTitle(title)
            setDetails(details)
            setLink(link)
            setImage(image)
            setColor(color)
            setOrder(order)
            setShow(show)
        }
    }

    const clearState = () => {
        setTitle('')
        setDetails('')
        setLink('')
        setImage('')
        setColor('')
        setOrder(1)
        setShow(true)
    }

    const handleClose = () => setShowDialog(!showDialog)

    return(
        <div>
            <button onClick={handleClose} className='button-none'>{props.id ? 'Edit':'+ Add New Button'}</button>
            <Dialog onClose={handleClose}  open={showDialog}>
            <div style={{margin: '20px'}}>
            <div>
                Title
                <input type="text"
                    value={title}
                    onChange={e=>setTitle(e.target.value)} />
            </div>
            <div>
                Details
                <textarea type="text"
                    value={details}
                    onChange={e=>setDetails(e.target.value)} />
            </div>
            <div>
                Link
                <input type="text"
                    value={link}
                    onChange={e=>setLink(e.target.value)} />
            </div>
            <div>
                Image
                <input type="text"
                    value={image}
                    onChange={e=>setImage(e.target.value)} />
            </div>
            <div>
                Venue Color
                <ColorPicker color={color} setColor={setColor}/>
            </div>
            {/* <div>
            <input type="checkbox"
                    value={show}
                    onChange={e=>setShow(!show)} />
                    Show Detail
            </div> */}
            <div>
            <input type="number"
                    value={order}
                    onChange={e=>setOrder(e.target.value)} />
                    order
            </div>
            <div>
                <button onClick={save}>Save</button>
            </div>
            <div style={{textAlign: 'right'}}>
            {props.id && <button 
            className='button-none'
            onClick={()=>setVerifyDelete(!verifyDelete)}>{verifyDelete ? 'Cancel Delete': 'Delete'}</button>}
            {verifyDelete && <button 
            className='button-none'
            onClick={handleDelete}>Yes Delete Venue and All Buttons</button>}
            </div>
            </div>
            </Dialog>
        </div>
    )


}
export default AddVenueItem