import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Moment from 'react-moment';


const MatchReportUSAPickleball = () => {
    const [list, setList] = useState([])

    const tid = useSelector(state => state.tid)
    const name = useSelector(state => state.name)

    const timeZone = useSelector(state => state.time_zone)

    const classes = useStyles()

    useEffect(() => {
        getPastResults()
    }, [tid])


    const getPastResults = () => {
        axios.get(`/api/getPastMatchUSAPickleball/${tid}`).then(res => {
            setList(res.data)
        })
    }
    return (
        <div className='container-fluid'>
            <h3>Referee History Report </h3>
            <p>{name}</p>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Court</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Medal?</TableCell>
                            <TableCell align="left">SYSID</TableCell>
                            <TableCell align="left">Start Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((row, i) => (

                            <TableRow key={i}>
                                <TableCell>{++i}.</TableCell>
                                <TableCell component="th" scope="row">
                                    {row.court_num}
                                </TableCell>
                                <TableCell align="left">{row.last}, {row.first}</TableCell>
                                <TableCell align="left">{row.medal}</TableCell>
                                <TableCell align="left">{row.sysid}</TableCell>
                                <TableCell align="left"><Moment format="MM/DD h:mm a" tz={timeZone}>
                                    {row.start_time}
                                </Moment></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    )
}
export default MatchReportUSAPickleball

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});