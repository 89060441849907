import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';



const ActiveMatchListCourtMonitor = props => {
    const [matches, setMatches] = useState([])

    let { tid } = props
    const classes = useStyles()

    useEffect(
        () => { getMatches() }, [props.verified])

    const getMatches = () => {
        if (props.verified == 'yes') {
            axios.get(`/api/score/getActiveMatches/${tid}`).then(
                res => {
                    setMatches(res.data)
                }
            )
        }
    }

    return (
        <div>
            <h3>Score Reporting</h3>
            <button
                onClick={getMatches}>
                <i className="fas fa-sync-alt"></i>
                            refresh</button>

            <TableContainer component={Paper}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell >Report Scores</TableCell>
                            <TableCell align="left" >Court</TableCell>

                            <TableCell align="left">Team 1</TableCell>
                            <TableCell align="left">Team 2</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {matches.map((row, i) => (
                            <TableRow key={i}>

                                <TableCell component="th" scope="row" className={classes.button}>
                                    <Link to={`/score/rpsr/${row.sid}`} target='_blank' rel="noopener noreferrer">
                                        <button>Report Scores</button>
                                    </Link>
                                </TableCell>

                                <TableCell className={classes.court}>
                                    {row.court_num}
                                </TableCell>
                                <TableCell align="left"><div>
                                    {row.t1p1_name} {row.t1p2_name}
                                </div></TableCell>
                                <TableCell align="left"><div>
                                    {row.t2p1_name} {row.t2p2_name}
                                </div></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
export default ActiveMatchListCourtMonitor

const useStyles = makeStyles({
    button: {
        width: 200,
    },
    court: {
        width: 150,
        fontSize: '2em'
    },
});