import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'

const localizer = momentLocalizer(moment)

const CalendarOfTournaments = (props) => {
    const [tournaments, setTournaments] = useState([])
    const [locationSearch, setLocationSearch] = useState('')
    const [nameSearch, setNameSearch] = useState('')
    const [organization, setOrganization] = useState('')
    const [usapa, setUsapa] = useState(null)

    useEffect(
        () => { getTournaments() }, [])

    const getTournaments = () => {
        axios.get(`/api/t/getTournaments`).then(
            res => {
                let x = (res.data || []).map(item => {
                    item['start']  = item['start'] && moment(item['start']).toDate()
                    item['end_date'] = item['end_date'] && moment(item['end_date']).toDate()
                    item['allDay?'] = true
                    item['title']  = item['name']
                    return item
                  })
                setTournaments(x)
            }
        )
    }

    const clearSearch = () => {
        setNameSearch('')
        setLocationSearch('')
        setOrganization('')
        setUsapa(null)
    }

    const handleOpen = tid => {
        props.history.push(`/signup/${tid}`)
    }

    const events = tournaments.filter(obj => obj.name.toUpperCase().includes(nameSearch.toUpperCase()))
    .filter(obj => obj.location.toUpperCase().includes(locationSearch.toUpperCase()))
    .filter(obj => obj.organization.includes(organization))
    .filter(obj => obj.usapa != usapa)

    return (
        <div className='col-12'>
            <h2>Tournament Calendar</h2>
            <div className='col-12'>
                <label>
                    Name:
                <input type="text"
                        value={nameSearch}
                        onChange={e => setNameSearch(e.target.value)} />
                </label>
                <label>
                    Location:
                <input type="text"
                        value={locationSearch}
                        onChange={e => setLocationSearch(e.target.value)} />
                </label>
                <legend>
                    Filter by organization:
                    </legend>
                <div>
                <img className='search-by-organization pointer' 
                    onClick={()=>setUsapa(false)}
                    src={require('../../img/usapickleball-logo.png')} alt='USA Pickleball' />

                    <img className='search-by-organization pointer'
                        onClick={() => setOrganization('app')}
                        src={'https://res.cloudinary.com/shonda/image/upload/v1585598171/app_fgbs8a.png'} alt='APP' />
                    <img className='search-by-organization pointer'
                        onClick={() => setOrganization('np')}
                        src={'https://res.cloudinary.com/shonda/image/upload/v1612829816/NationalPickleball-stacked_n08msp.png'} alt='National Pickleball' />
                    <img className='search-by-organization pointer'
                        onClick={() => setOrganization('npl')}
                        src={'https://res.cloudinary.com/shonda/image/upload/v1713838655/LivePickleball/image001_wxplgl.png'} alt='NPL' />
                    {/* <img className='search-by-organization pointer'
                        onClick={() => setOrganization('legacy')}

                        src={'https://res.cloudinary.com/shonda/image/upload/v1641486085/PickleballDesk/Legacy-Sports_Logo-01_fqrke9.png'} alt='Legacy' /> */}
                    <img className='search-by-organization pointer'
                        onClick={() => setOrganization('pig')}

                        src={'https://res.cloudinary.com/shonda/image/upload/v1613689855/pig_v3k4d8.png'} alt='PIG' />
                    <img className='search-by-organization pointer'
                        onClick={() => setOrganization('ppa')}

                        src={'https://res.cloudinary.com/shonda/image/upload/v1585598171/ppa_dpolxq.png'} alt='PPA' />

                </div>

                <button
                    className='pointer'
                    onClick={clearSearch}>
                    <i className="fas fa-minus-circle"></i>

                    Clear Search</button>
            </div>

            <Calendar
                localizer={localizer}
                events={events}
                startAccessor='start'
                endAccessor='end_date'
                views={['month']}
                style={{ minHeight: 900, background: 'white' }}
                onSelectEvent={event => handleOpen(event.tid)}
                
            />
        </div>
    )
}

export default withRouter(CalendarOfTournaments)