import React, {useState} from 'react'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import VolunteerCheckInListDetail from './VolunteerCheckinListDetail'
import { toast } from 'react-toastify';
import SendEmail from '../../Communication/SendEmail'
import SendText from '../../Communication/SendText'





const VolunteerCheckInList = props => {
    const [name, setName] = useState('')
    const [category, setCategory] = useState('')
    const [shift, setShift] = useState('')
    const [time, setTime] = useState('')
    const [selected, setSelected] = useState([])


    const [subject, setSubject] = useState('')
    const [body, setBody] = useState('')
    const [loader, setLoader] = useState(false)
    const [email, setEmail] = useState(false)
    const [text, setText] = useState(false)

    const tid = useSelector(state => state.tid)
    const reply = useSelector(state => state.email)
    const author = useSelector(state => state.ref_id)
    const tournamentName = useSelector(state => state.name)
    const classes = useStyles()

    const select = (row) => {
      if (selected.some(item => row.ref_id === item.ref_id)) {
          let index = selected.map(function (e) { return e.ref_id; }).indexOf(row.ref_id);
          remove(index)
      } else {
          add(row)
      }
  }

  const handleSelectAll = () => {
      let all = props.volunteers
      .filter(obj => obj ? obj.name.toUpperCase().includes(name.toUpperCase()):'')
      .filter(obj => obj ? obj.category.toUpperCase().includes(category.toUpperCase()):'')
      .filter(obj => obj ? obj.shift.toUpperCase().includes(shift.toUpperCase()):'')
      .filter(obj => obj ? obj.start_time.toUpperCase().includes(time.toUpperCase()):'')
      setSelected(all)
  }
  const add = (row) => {
      let list = selected.concat(row)
      setSelected(list)
  }

  const remove = (i) => {
      let list = [...selected]
      list.splice(i, 1)
      setSelected(list)
  }

  const clearState = () => {
    setName('')
    setCategory('')
    setShift('')
    setSelected([])
    setEmail(false)
    setText(false)
  }

    return(
      <div>
        <button
                            className='select-button'
                            onClick={handleSelectAll}>Select All</button>
                        <button
                            className='select-button'
                            onClick={() => setSelected([])}>Clear Selection</button>
                            <button onClick={()=>setEmail(email => !email)}><i className="far fa-envelope"></i> Email Selected</button>

                            <button onClick={()=>setText(text => !text)}>
                              <i className="fas fa-sms"></i> Text Selected</button>
          {email ?
          <SendEmail 
            selected={selected}
            category={'volunteer'}
            clearState={clearState}/>:''}
            {text ?
          <SendText 
            selected={selected}
            category={'volunteer'}
            clearState={clearState}/>:''}
      
        <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>
                Checkin
              </TableCell>
              <TableCell>
                T-Shirt
              </TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="left">Category</TableCell>
            <TableCell align="left">Shift</TableCell>
            <TableCell align="left">Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow>
        <TableCell component="th" scope="row">
                
              </TableCell>
              <TableCell>
                
              </TableCell>
            <TableCell>
                <input type="text"
                    value={name}
                    onChange={e=>setName(e.target.value)}/>
            </TableCell>
            <TableCell align="left">
            <input type="text"
                    value={category}
                    onChange={e=>setCategory(e.target.value)}/>
            </TableCell>
            <TableCell align="left">
            <input type="text"
                    value={shift}
                    onChange={e=>setShift(e.target.value)}/>
            </TableCell>
            <TableCell align="left">
            <input type="text"
                    value={time}
                    onChange={e=>setTime(e.target.value)}/>
            </TableCell>
          </TableRow>
          {props.volunteers
            .filter(obj => obj.name.toUpperCase().includes(name.toUpperCase()))
            .filter(obj => obj.category.toUpperCase().includes(category.toUpperCase()))
            .filter(obj => obj.shift.toUpperCase().includes(shift.toUpperCase()))
            .filter(obj => obj.start_time.toUpperCase().includes(time.toUpperCase()))

            .map((row, i) => (
            <VolunteerCheckInListDetail 
                key={i}
                row={row}
                selected={selected}
                select={select}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>

    )
}
export default VolunteerCheckInList

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });