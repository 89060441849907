import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import io from 'socket.io-client'
import Switch from "@material-ui/core/Switch";


const LineCheckin = (props) => {
    const [shifts, setShifts] = useState([])
    const [showLoader, setShowLoader] = useState(false)

    const tid = useSelector(state => state.tid)


    useEffect(
        () => { getShifts() }, [tid])



    // useEffect(
    //     () => {
    //         socket.on('board', results => {
    //             console.log('hit board')

    //         })
    //     }, 0);

    // const emitSocket = () => {
    //     socket.emit('board', { room: `board:${tid}` })
    // }

    const getShifts = () => {
        setShowLoader(true)

        axios.get(`/api/getRegisteredLineJudges/${tid}`).then(res => {
            setShifts(res.data)
            setShowLoader(false)
        })
    }
    const checkinLineJudge = (ref_id) => {
        axios.put(`/api/checkinLineJudge/${tid}`, { ref_id }).then(res => {
            if (props.handleCheckInModal) {
                props.handleCheckInModal()
            }
            else {
                getShifts()
                let socket = io()
                socket.emit('line', { room: `line:${tid}` })
            }
        })
    }

        const mappedNames =shifts.map((name, i) => {
            return (
                <div key={'g' + name.ref_id + i} className='line-checkin-list '>
                    <div>
                        < Switch
                            color="primary"
                            checked={name.status}
                            value={name.status ? "Active" : 'Inactive'}
                            onClick={() => checkinLineJudge(name.ref_id)}
                        />
                        {name.last}, {name.first} </div>

                </div>
            )
        })
        



    return (
        <div className='container'>
            <h4>Line Judge Check-In</h4>
            {showLoader ?
                <div>
                    <p>generating list...</p>
                    <img id='loader' width="100" src={require('../../img/15.gif')} alt='players' />
                </div> : ''}
            {mappedNames}
        </div>
    )
}
export default LineCheckin