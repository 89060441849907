import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper';
import MappedNames from './MappedNames'
import MappedCaptains from './MappedCaptains'


const MappedShift = props => {
    const [show, setShow] = useState(true)
    let {shift, searchFirst, searchLast, searchName, searchDate} = props
    const timeZone = useSelector(state => state.time_zone)

    const handleShow = () => setShow(show => !show)

    const mappedCaptains = shift.captains
                    .filter(obj => obj.volunteer_name.toUpperCase().includes(searchFirst.toUpperCase()))
                    .map(c => {
                        return (
                            <MappedCaptains 
                            c={c}
                            get={props.get}/>
                        )
                    })
                const mappedSpot = shift.signup
                .filter(obj => obj.volunteer_name.toUpperCase().includes(searchFirst.toUpperCase()))
                .map((spot, i) => {

                    return (
                        <MappedNames 
                        key={'x'+i}
                        i={i}
                        spot={spot}
                        get={props.get}
                        waitlist={false}
                        moveToWaitlist={props.moveToWaitlist}
                        moveOffWaitlist={props.moveOffWaitlist}/>
                    )
                })
                const mappedWaitlist = shift.waitlist
                    .filter(obj => obj.volunteer_name.toUpperCase().includes(searchFirst.toUpperCase()))
                    .map((waitList, i) => {

                        return (
                            <MappedNames 
                        key={'w'+i}
                        i={i}
                        spot={waitList}
                        get={props.get}
                        waitlist={true}
                        moveOffWaitlist={props.moveOffWaitlist}/>
                        )
                    })

return(
    <Paper>
    <div style={{ background: 'white', margin: '10px 0' }} className={props.selected.sid === shift.sid ? '  selected-slot' : ''}>
    
        <div className='shift-title'
            style={{ background: shift.color || '#009CAE'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                <div>
                {show ?
                <i className="fas fa-chevron-up" onClick={handleShow}></i>:
                <i className="fas fa-chevron-down" onClick={handleShow}></i>    
                }
                </div>
                <div>
            <h4>
                <small>{shift.category}{`: `}</small>
                {shift.name}</h4>
            <div>
            {shift.start_time ?
                    shift.start_time
                :''}
                {shift.end_time ?
                <>
                {` to `}
                    {shift.end_time}
                </>:''}
            </div>
            </div>
            
            </div>

<div>
    
            
            </div>
            <div style={{display: 'flex', flexDirection:'column'}}>
    <div>Shift: {shift.count}/{shift.spots}</div>
     <div>       Wait: {shift.wait_count}/ {shift.wait_spots}</div>
    </div>
        </div>
        {show ?
        <div style={{padding: '10px'}}>
        <div >
            {shift.captains.length > 0 ?
                < div className='tips'>
                    <div className='captains'>
                        {shift.captains.length > 1 ?
                            `Shift Captains:` : 'Shift Captain:'}
                    </div>
                    {mappedCaptains}
                </div>
                : ''}

        </div>
        <div className='row'>
            <div className='col-12 col-md-6'>

                <button
                    className='register-button'
                    onClick={()=>props.handleAddCaptain(shift)}>
                    Add Captain</button>
                <div className='row'>
                    <div className='col-12'>
                        <h5>Spots: {shift.count}/{shift.spots}</h5>
                    </div>


                </div>
                <div >

                    <div className='registered-spots'>
                        {mappedSpot}


                        {(shift.spots - shift.count) > 0 ?
                            <button onClick={() => props.setShift(shift, false)}>add</button> : ''}
                    </div>
                </div>
            </div>
            {shift.wait_spots || shift.waitlist.length > 0 ?
                <div className='col-12 col-md-6'>

                    <div className='row'>
                        <div className='col-12'>
                            <h5>Waitlist: {shift.wait_count}/ {shift.wait_spots}</h5>
                        </div>

                    </div>
                    <div style={{ background: 'white' }}>
                        <div className='registered-spots'>
                            {mappedWaitlist}

                            {(shift.wait_spots - shift.wait_count) > 0 ?
                                <button onClick={() => props.setShift(shift, true)}>add</button> : ''}
                        </div>
                    </div>
                </div> : ''}

        </div>
    </div>:''}
    </div>
    </Paper>

)
}
export default MappedShift