import React, { useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import Modal from 'react-responsive-modal';
import AddVolunteerFromSystem from './AddVolunteerFromSystem'
import AddVolunteer from './AddVolunteer'

const AddVolunteerNew = props => {
    const [showAddVolunteer, setShowAddVolunteer] = useState(false)

    const tid = useSelector(state => state.tid)

    const addVolunteerToDBandTournament = (refInfo) => {
        if(refInfo.email.length < 4){return toast.warning('email required')}
        axios.post(`/api/addVolunteerToDBandTournament/${tid}`, { refInfo }).then(
            res => {
                if(props.get){
                    props.get()
                }
                setShowAddVolunteer(false)
            }
        )
    }

    const addVolunteerToTournament = (ref_id) => {
        axios.post(`/api/addVolunteerToTournament/${tid}`, { ref_id }).then(
            res => {
                if(props.get){
                    props.get()
                }
                setShowAddVolunteer(false)
            }
        )
    }

    const handleShowAddVolunteer = () => setShowAddVolunteer(showAddVolunteer => !showAddVolunteer )


        return (
            <div className='container' >
                <button onClick={handleShowAddVolunteer}>Add Volunteer</button>
                <Modal open={showAddVolunteer} center showCloseIcon={true}
                    onClose={handleShowAddVolunteer} closeOnEsc={true} closeIconSize={2}>
                    <div>
                        <h4>Click on name to add volunteer.  If volunteer is not in the system add them at the bottom.</h4>
                        <AddVolunteerFromSystem
                            addRefToTournament={addVolunteerToTournament}
                        />
                        <div className='addLineJudge'>
                            <AddVolunteer
                                tid={tid}
                                addVolunteer={addVolunteerToDBandTournament} />
                        </div>
                        <button onClick={handleShowAddVolunteer}>Cancel</button>
                    </div>
                </Modal>
            </div>

        )
    }

export default AddVolunteerNew
