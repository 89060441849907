import React, {useState} from 'react'
import RefScheduleModal from './RefScheduleModal'
import DisplayRefSchedule from './DisplayRefSchedule'
import Modal from 'react-responsive-modal';


export default function(props){
    const [show, setShow] = useState(false)
    return(
        <>
        {show ?
        <Modal open={show} center showCloseIcon={true} onClose={()=>setShow(false)} closeOnEsc={true} closeIconSize={2}>
                        <div className='col-12'>
                            <h4>Schedule:</h4>
                            <div className='registered-spots'>
                                <DisplayRefSchedule
                                    referee={props.row} />
                            </div>
                            <h6>Notes:</h6>
                            <RefScheduleModal 
                                referee={props.row}
                            />

                            <button onClick={()=>setShow(false)}> Close</button>
                        </div>
                    </Modal>:
                    <i className="far fa-calendar" onClick={() => setShow(true)}></i>}
        </>
    )
}