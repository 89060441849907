import React, { Component } from 'react';
import axios from 'axios'
import { connect } from "react-redux"
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter'
import { Link } from 'react-router-dom';



class MatchOverview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pastMatches: []
        }
    }

    componentDidMount() {
        this.getPastMatches()
    }

    getPastMatches = async () => {
        await axios.get(`/api/getPastMatchesForRef/${this.props.ref_id}`).then(

            res => {
                this.setState({ pastMatches: res.data })
            }
        )
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }


    render() {
        const columns = [

            {
                Header: 'Court',
                accessor: 'court_num',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["court_num"] }),
                filterAll: true
            },
            {
                Header: 'Medal',
                accessor: 'medal',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["medal"] }),
                filterAll: true
            },
            
            {
                Header: 'Tournament',
                accessor: 'name',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true,
            },
            {
                Header: 'Credentials',
                accessor: 'credentials',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["credentials"] }),
                filterAll: true

            },
            {
                Header: 'Date',
                accessor: 'date',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["date"] }),
                filterAll: true
            },
            {
                Header: 'Start',
                accessor: 'start_time',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["start_time"] }),
                filterAll: true
            },
            {
                Header: 'End',
                accessor: 'end_time',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["end_time"] }),
                filterAll: true
            },
            {
                Header: 'Match Time',
                accessor: 'match_time',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["match_time"] }),
                filterAll: true
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["amount"] }),
                filterAll: true
            },
            {
                Header: 'Paid',
                accessor: 'paid',
                Cell: ({ value, row }) =>
                    value ? 'Yes':'No'
            },
            {
                Header: 'Note',
                accessor: 'note',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["note"] }),
                filterAll: true
            },
            

        ]


        return (
            <div className="container-fluid">
                <h4>Match Count {this.state.pastMatches.length}</h4>
                <div className='RefCourtAssignments'>
                    <h2>Past Matches for {this.props.first} {this.props.last}</h2>
                    <p>
                        Want to filter the data?  Use the white boxes under the column titles to filter.  Click the column title to sort.  
                    </p>
                    <Link to={`/profile/matchesByTournament`} >
                    <button>Matches By Tournament</button>
                    </Link>
                    <div  >
                        <ReactTable
                            data={this.state.pastMatches}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            className='ReactTable'

                            columns={columns}
                        />
                    </div>
                </div>
            </div>

        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name,
        zone: reduxState.zone,
        ref_id: reduxState.ref_id,
        first: reduxState.first,
        last: reduxState.last,
    }
}


export default connect(
    mapStateToProps,
)(MatchOverview)