import React, {useState} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';


const AddMessage = props => {
    const [message, setMessage] = useState('')

    const ref_id = useSelector(state => state.ref_id)
    const first = useSelector(state => state.first)
    const last = useSelector(state => state.last)
    const name = useSelector(state => state.name)


    const save = () => {
        axios.post(`/api/messageBoard/addMessage`, { tid: props.tid, ref_id, message, category:'referee', first, last, name }).then(
            res => {
                // setMessage(res.data)
                toast.success('saved')
                if(props.get){ props.get()}
            }
        ).catch(err => {
            toast.error('Issue Occurred- please try again')
        })
    }

    return(
        <div style={{background: 'white', display: 'flex', flexDirection: 'column'}}>
            <h3>New Message Board Message</h3>
            
            <form action="">
                <label htmlFor="">
                    Message
                    <textarea name="" id="" cols="30" rows="10" value={message}
                        onChange={e=>setMessage(e.target.value)}>

                    </textarea>

                </label>
                <div>
                An email link will be provided for people to contact you.  Include your phone number in your post if you wish.
                </div>
                
                <button onClick={save}>Save</button>
            </form>
        </div>
    )

}
export default AddMessage