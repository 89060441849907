import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import './ScheduleOverview.css'



const ScheduleOverview = props => {
    const [dates, setDates] = useState([])
    const [categories, setCategories] = useState([])
    const [showLoader, setShowLoader] = useState(false)

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getCategories() }, [tid])

        useEffect(
            () => { getVolunteerDates() }, [tid])

    const getCategories = () => {
        setShowLoader(true)
        axios.get(`/api/volunteer/getVolunteerCategories/${tid}`).then(
            res => {
                setCategories(res.data)
            }
        )
    }
    const getVolunteerDates = () => {
        setShowLoader(true)
        axios.get(`/api/getVolunteerDates/${tid}`).then(
            res => {
                setDates(res.data)
                setShowLoader(false)
            }
        )
    }



    return(
        <div className='container-fluid'>
          <h3>Volunteer Schedule Recap</h3>
          {showLoader ? <LinearProgress />:''}
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className='overview-category'>
                    Category
                    {categories.map((cat, i) => (
                    <div key={i} className='overview-category'>
                        {cat.name}
                    </div>
                ))}
                </div>
                {dates.map((day, i) => (
                    <div key={i} className='overview-day'>
                        <div>{day.day}</div>
                        <div>{day.date}</div>
                    </div>
                ))}
            </div>
    </div>
  )
}
export default ScheduleOverview