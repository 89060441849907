import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Switch from "@material-ui/core/Switch";
import './LineJudge.css'
import LineSocket from './LineSocket'

const LinePublicDisplay = (props) => {
    const [right, setRight] = useState([])
    const [left, setLeft] = useState([])
    const [checkedInLineJudges, setCheckedInLineJudges] = useState([])
    const [groups, setGroups] = useState([])
    const [tournament, setTournament] = useState({})

    const tid = props.match.params.tid

    useEffect(
        () => { getTournament() }, [])
    useEffect(
        () => { getGroups() }, [])
    useEffect(
        () => { getCheckedInLineJudges() }, [])
    useEffect(
        () => { getCourts() }, [])


    const getTournament = () => {
        if (props.match.params.tid && props.match.params.tid > 0) {
            axios.post(`/api/t/getTournament/${props.match.params.tid}`).then(
                res => {
                    setTournament(res.data)
                }
            )
        }
    }

    const handleGetAllGroups = () => {
        getGroups()
        getCheckedInLineJudges()
    }

    const getCourts = () => {
        axios.get(`/api/getLineCourts/${props.match.params.tid}`).then(res => {
            setRight(res.data.right)
            setLeft(res.data.left)
        })
    }

    const getGroups = () => {
        if (tid) {
            axios.post(`/api/getLineGroups/${props.match.params.tid}`).then(res => {
                setGroups(res.data)
            })
        }
    }

    const getCheckedInLineJudges = () => {
        axios.get(`/api/getCheckedInLineJudges/${props.match.params.tid}`).then(res => {
            setCheckedInLineJudges(res.data)
        })
    }
    const mappedRight = right.map(c => {

        return (
            <div key={c.court_num} className='col-6 col-md-3 col-lg-2 court-box-boardView'>
                <div className='col-4 court-num'>
                    <div
                        className='court-name'
                    >
                        <div className={c.court_num.length > 2 ? 'shrink-court-num' : ''}>{c.court_num}</div>
                        {c.medal === 'Bronze' ?
                            <div className='board-medal'>
                                <i className="fas fa-medal"></i>
                                B
                                </div> :
                            c.medal === 'Gold' ?
                                <div className='board-medal'>
                                    <i className="fas fa-medal"></i>
                                    G
                                </div> : ''}
                    </div>
                </div>
                <div className='ref-name'
                    style={c.gid ? { background: c.color || '#ABB8C3', paddingLeft: '5px', width: '100%' } : { background: 'white' }}>

                    {c.group_name ?
                        <div>{c.group_name}
                        </div> : ''}

                </div>
            </div>
        )
    })
    const mappedLeft = left.map(c => {

        return (
            <div key={c.court_num} className='col-6 col-md-3 col-lg-2 court-box-boardView'>
                <div className='col-4 court-num'>
                    <div
                        className='court-name'
                    >
                        <div className={c.court_num.length > 2 ? 'shrink-court-num' : ''}>{c.court_num}</div>
                        {c.medal === 'Bronze' ?
                            <div className='board-medal'>
                                <i className="fas fa-medal"></i>
                                B
                                </div> :
                            c.medal === 'Gold' ?
                                <div className='board-medal'>
                                    <i className="fas fa-medal"></i>
                                    G
                                </div> : ''}
                    </div>
                </div>
                <div className='ref-name'
                    style={c.gid ? { background: c.color || '#ABB8C3', paddingLeft: '5px', width: '100%' } : { background: 'white' }}>

                    {c.group_name ?
                        <div>{c.group_name}
                        </div> : ''}

                </div>
            </div>
        )
    })

    const mappedGroups = groups.map((g, i) => {
        const mappedNames = g.signup.map(name => {
            return (
                <div key={'g' + name.ref_id} className='line-name-list'>

                    < Switch
                        color="primary"
                        checked={name.status}
                        value={name.status ? "Active" : 'Inactive'}
                    />
                    <div>{name.last}, {name.first}</div>
                </div>
            )
        })
        return (
            <div key={'n' + i}
                className='col-12'>
                <div className='line-group' >
                    <div className='line-group-header '
                        style={{ background: g.color || '#abb8c3' }} >
                        <div className='line-group-court-assignment'>
                            {g.court_num ? <div id='court'>COURT</div> : ''}
                            <div >{g.court_num}</div>
                        </div>
                        <div className='line-group-title '>
                            {g.group_name}
                        </div>
                    </div>
                    <div className='row'>
                        {mappedNames}
                    </div>
                </div>
            </div>
        )
    })

    const mappedCheckedIn = checkedInLineJudges.map((n, i) => {
        return (
            <div key={'s' + i}
                className='line-no-group-list'
            >
                <div className='line-name-list-name'>
                    {n.last}, {n.first}
                </div>
            </div>
        )
    })


    return (
        <div className='container'>
            <h2>Line Judge Monitor - {tournament.name}</h2>
            <LineSocket
                getCheckedInLineJudges={getCheckedInLineJudges}
                getGroups={getGroups}
                getCourts={getCourts}
                tid={tid} />
            <div className='row'>
                <div className='line-mappedCourts col-12'>
                    
                    {mappedLeft}
                    {mappedRight}
                </div>
            </div>

            <div>
            </div>

            <div className='row' >
                <div className='col-12 col-sm-6 col-md-8'>
                    {mappedGroups}
                </div>
                <div className='col-12 col-sm-6 col-md-4'
                    style={{ background: 'white' }}>
                    <h5>Checked In</h5>
                    {mappedCheckedIn}
                </div>
            </div>
        </div>
    )
}

export default LinePublicDisplay