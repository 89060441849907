import React from 'react'

const VolunteerList = props => {
    let {volunteer, selected} = props
    return(
        <div 
        className='pointer'
        onClick={() => props.setVolunteer(volunteer)}>
        <div className={selected.ref_id === volunteer.ref_id ? 'selected-ref' : ''}>
            {volunteer.last}, {volunteer.first}
        </div>
    </div >
    )
}
export default VolunteerList