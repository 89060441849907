import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import './RefereeScheduleTable.css'

const RefereeScheduleTable = props => {
    const [loader, setLoader] = useState(false)
    const [schedule, setSchedule] = useState([])
    const [shiftNames, setShiftNames] = useState([])
    const [search, setSearch] = useState('')

    const tid = useSelector(state => state.tid);

    useEffect(
        () => { getSchedule() }, [])

    const getSchedule = () => {
        setLoader(true)
        axios.get(`/api/getRefereeShiftTable/${tid}`).then(res => {
            setSchedule(res.data)
            setShiftNames(res.data[0].shift)
            setLoader(false)
        })
    }

    const lastMatch = (id) => {
        axios.put(`/api/lastMatch/${tid}`, { ref_id: id }).then(
            res => {
                getSchedule()
            }
        )
    }

    const mappedReferees = schedule.map(referee => {
        const mappedShifts = referee.shift
            .filter(obj => obj.name.toUpperCase().includes(search.toUpperCase()))
            .map((s, i) => {
                return (
                    <div key={i}
                        className='shifts'>
                        {s.registered ?
                            <div className='yes'></div> : ''}
                    </div>
                )
            })
        return (
            <div key={referee.ref_id}
                style={{ display: 'flex' }}>
                <div className='names'
                    style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        {referee.last_match ? <span className='last_match_icon pointer'
                            onClick={() => lastMatch(referee.ref_id)}>LM</span> : <span
                                className='last_match_icon_inactive pointer'
                                onClick={() => lastMatch(referee.ref_id)}>LM</span>}
                    </div>
                    {referee.last}, {referee.first}

                </div>
                {mappedShifts}
            </div >
        )
    })


    const mappedShiftNames = shiftNames
        .filter(obj => obj.name.toUpperCase().includes(search.toUpperCase()))
        .map((s, i) => {
            return (
                <div key={'r' + i}
                    className='shifts header'>
                    {s.name}
                </div>
            )
        })


    return (
        <div className='container-fluid'>
            {loader ?
                <img id='loader' width="100" src={require('../../img/15.gif')} alt='players' /> : ''}
            <h4>View Referee Shifts</h4>
            <div className='tips'>All shifts that the referee is scheduled for will be green.  To filter just type the date.  To change that referee to last match just click on the LM icon.  It will be yellow if the referee is on their last match.  </div>
            <legend> Filter Shifts
            <input type="text"
                    value={search}
                    onChange={e => setSearch(e.target.value)} />
            </legend>
            <div className='shift-table'>
                <div className='shift-titles'>
                    <div className='names header'>
                        Name:
                    </div>
                    <div style={{ display: 'flex' }}>
                        {mappedShiftNames}
                    </div>
                </div>
                <div>
                    {mappedReferees}
                </div>

            </div>
        </div>
    )
}
export default RefereeScheduleTable