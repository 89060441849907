import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { updateTournament } from '../../ducks/reducer'

const VolunteerCaptainList = props => {
    const [list, setList] = useState([])

    const dispatch = useDispatch()


    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)

    useEffect(
        () => { getTournamentCaptain() }, [tid, ref_id])

    const getTournamentCaptain = () => {
        if(!ref_id || !ref_id) return
        axios.get(`/api/getTournamentCaptain/${ref_id}`).then(
            res => {
                setList(res.data)
            }
        )
    }

    const handleSelect = (tournament) => {
        dispatch(updateTournament(tournament))
        props.history.push('/volunteercaptain')
    }
    return(
        <div>
            {list.length > 0 ?
                <div>
                    <div className='dashboard-title-operations'>
                        <>Volunteer Captain</>
                        </div>
                        <div>
                        {list.map((tourney, i)=>(
                             <div
                             key={'c'+i}
                             className='tournament-listing-operations shadow mb-1 bg-white rounded'
                             style={{ cursor: 'pointer' }}
                             onClick={() => handleSelect(tourney)}
                         >
                             <div className={tourney.status === 'active' ? 'tournament-status-active' : 'tournament-status-draft'}>
                                 {tourney.start ?
                                     <span>
                                             {tourney.start} - 
                                             {tourney.end_date}
                                     </span> : 'Dates Needed'}
                             </div>
                             <h4>{tourney.name}</h4>
                             <p>{tourney.location}</p>
 
                         </div>
                        ))}
                    </div>
                </div>:null}
        </div>
    )
}
export default withRouter(VolunteerCaptainList)
