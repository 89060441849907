import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Link } from 'react-router-dom';
import Listing from '../Listing'

const PPAListings = props => {
    const [tournaments, setTournaments] = useState([])

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getTournaments() }, [])


    const getTournaments = () => {
        axios.get(`/api/t/getTournamentsByOrganization/ppa`).then(res => {
            setTournaments(res.data)
        })
    }
    const mappedTournaments = tournaments.map(t => {
        return (
            <div key={t.tid}
                className='col-12 col-md-6 col-lg-4'
                style={{ color: 'black' }}>
                    <div style={{ color: 'black', marginBottom: '20px'}}>
                <Link to={`/signup/${t.tid}`}
                >
                    <Listing
                        t={t} />
                </Link>
                </div>
            </div>
        )
    })

    return (
        <div style={{ background: 'white', padding: '20px' }}>
            <div>
                <img className=''
                    style={{ width: '200px' }}
                    src={'https://www.propickleballassociation.com/wp-content/uploads/2019/07/ppa-dark-header-630.png'} alt='ppa' />
                <h4>Volunteer Opportunities for the PPA- Pro Pickleball Association</h4>

            </div>
            <div className='row justify-content-center' >
                {mappedTournaments}
            </div>
        </div>
    )
}
export default PPAListings

