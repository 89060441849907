import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress'

const RefereeTimeAndAmount = () => {
    const [list, setList] = useState([])
    const [masterList, setMasterList] = useState([])
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)
    const name = useSelector(state => state.name)

    const classes = useStyles()

    useEffect(() => {
        getInfo()
    }, [tid])


    const getInfo = () => {
        setLoader(true)
        axios.get(`/api/getRefereeTimeAndAmount/${tid}`).then(res => {
            let x = res.data.filter(function(item){
                if(item.ms_count > 0 || item.lead_count > 0 || item.second_count > 0) {
                  return item;
                }
              });
            setList(x)
            setMasterList(x)
            setLoader(false)
        })
    }

    const sortName = async () => {
        setList([])
        let x = await masterList.sort((a, b) => a.name.localeCompare(b.name))
        setList(x)
    }

    const sortTime = async () => {
        setList([])
        let x = await masterList.sort(function(a, b) {
            return a.time - b.time})
        setList(x)
    }

    const sortCount = async () => {
        setList([])
        let x = await masterList.sort(function(a, b) {
            return a.total_count - b.total_count})
        setList(x)
    }
    const sortLeadCount = async () => {
        setList([])
        let x = await masterList.sort(function(a, b) {
            return a.lead_count - b.lead_count})
        setList(x)
    }
    const sortSupportingCount = async () => {
        setList([])
        let x = await masterList.sort(function(a, b) {
            return a.second_count - b.second_count})
        setList(x)
    }

    const sortAmount = async () => {
        setList([])
        let x = await masterList.sort(function(a, b) {
            return a.total_amount - b.total_amount})
        setList(x)
    }
    const sortLeadAmount = async () => {
        setList([])
        let x = await masterList.sort(function(a, b) {
            return a.lead_amount - b.lead_amount})
        setList(x)
    }
    const sortSupportingAmount = async () => {
        setList([])
        let x = await masterList.sort(function(a, b) {
            return a.second_amount - b.second_amount})
        setList(x)
    }
    const sortMatchStarterCount = async () => {
        setList([])
        let x = await masterList.sort(function(a, b) {
            return a.ms_count - b.ms_count})
        setList(x)
    }
    const sortMatchStarterMin = async () => {
        setList([])
        let x = await masterList.sort(function(a, b) {
            return a.ms_minutes - b.ms_minutes})
        setList(x)
    }

    return (
        <div className='container-fluid'>
            <h3>Referee Tournament Totals</h3>
            <p>{name}</p>
            {loader ?
            <LinearProgress color="secondary"/>:''}
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.name} onClick={sortName}>
                            <i className="fas fa-sort pointer"></i>
                                Name</TableCell>
                                <TableCell className={classes.name} >
                                Credentials</TableCell>
                            <TableCell align="center" onClick={sortTime}>
                            <i className="fas fa-sort pointer"></i>
                                Total hr:min</TableCell>
                            <TableCell align="center" onClick={sortLeadCount}>
                            <i className="fas fa-sort pointer"></i>
                                Lead Count</TableCell>
                            <TableCell align="center" onClick={sortSupportingCount}>
                            <i className="fas fa-sort pointer"></i>
                                Supporting Count</TableCell>
                            <TableCell align="center" onClick={sortCount}>
                            <i className="fas fa-sort pointer" ></i>
                                Total Count</TableCell>
                            <TableCell align="center" onClick={sortLeadAmount}>
                            <i className="fas fa-sort pointer"></i>
                                Lead Amount</TableCell>
                            <TableCell align="center" onClick={sortSupportingAmount}>
                            <i className="fas fa-sort pointer"></i>
                                Second Amount</TableCell>
                            <TableCell align="center" onClick={sortAmount}>
                            <i className="fas fa-sort pointer" ></i>
                                Total Amount</TableCell>
                                <TableCell align="center" onClick={sortMatchStarterCount}>
                                <i className="fas fa-sort pointer" ></i>
                                Match Starter Count</TableCell>
                                <TableCell align="center" onClick={sortMatchStarterMin}>
                                <i className="fas fa-sort pointer" ></i>
                                Match Starter Minutes</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list
                        .map((row, i) => (

                            <TableRow key={i}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.credentials}</TableCell>
                            <TableCell align="left">
                            <div>
                                {Math.floor(+row.time / 60)}
                                :{+row.time % 60}
                            </div>
                            </TableCell>
                            <TableCell align="right">{row.lead_count}</TableCell>
                            <TableCell align="right">{row.second_count}</TableCell>
                            <TableCell align="right" className={classes.total}>{row.total_count}</TableCell>
                            <TableCell align="right">$ {row.lead_amount}</TableCell>
                            <TableCell align="right">$ {row.second_amount}</TableCell>
                            <TableCell align="right" className={classes.total}>$ {+row.lead_amount + +row.second_amount}</TableCell>
                            <TableCell align="right" >
                                {row.ms_count}</TableCell>
                                <TableCell align="right" >
                                {row.ms_minutes}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    )
}
export default RefereeTimeAndAmount

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    name: {
        minWidth: '200px'
    },
    total: {
        fontWeight: '720'
    }
});