import React, { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux'
import QRCodeStyling from "qr-code-styling";

const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  image:
    "https://res.cloudinary.com/shonda/image/upload/v1623645333/PickleballDesk/PickleballDesk_Logo_-_530x530_jujsoi.png",
  dotsOptions: {
    color: "#4267b6",
    type: "square"
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 10
  }
});

export default function QRCodeGuide() {
  const [url, setUrl] = useState("https://www.pickleballdesk.com");
  const [fileExt, setFileExt] = useState("png");
  const ref = useRef(null);

  const tid = useSelector(state => state.tid)

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: `www.pickleballdesk.com/tournament/${tid}`
    });
  }, [tid]);

  const onUrlChange = (event) => {
    event.preventDefault();
    setUrl(event.target.value);
  };

  const onExtensionChange = (event) => {
    setFileExt(event.target.value);
  };

  const onDownloadClick = () => {
    qrCode.download({
      extension: fileExt
    });
  };

  return (
    <div style={{background: 'white'}}>
      <div style={styles.inputWrapper}>
        {/* <input value={url} onChange={onUrlChange} style={styles.inputBox} /> */}
        <select onChange={onExtensionChange} value={fileExt}>
          <option value="png">PNG</option>
          <option value="jpeg">JPEG</option>
          <option value="webp">WEBP</option>
        </select>
        <button onClick={onDownloadClick}>Download QR Image</button>
      </div>
      <div ref={ref} />
      <div>
          This is the QR Code to your tournament guide.   Place this code around the venue so players can access all the tournament information.
      </div>
      <div>
          Scan now to see what the players will see.
      </div>
    </div>
  );
}

const styles = {
  inputWrapper: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "200px"
  },
  inputBox: {
    flexGrow: 1,
    marginRight: 20
  }
};
