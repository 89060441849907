import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import LinearProgress from '@material-ui/core/LinearProgress';
import MatchTableList from './RefereeMatchList';
import MatchStarterList from './MatchStarterList';

const RefereeMatchListByTournament = props => {
    const [lead, setLead] = useState([])
    const [supporting, setSupporting] = useState([])
    const [matchStarter, setMatchStarter] = useState([])
    const [aux, setAux] = useState([])
    const [loader, setLoader] = useState(false)
    const [filter, setFilter] = useState('')
    const [leadTotal, setLeadTotal] = useState(0)
    const [supportingTotal, setSupportingTotal] = useState(0)
    const [auxTotal, setAuxTotal] = useState(0)
 

    let {ref_id, tid} = props

    useEffect(
        () => { getMatchList() }, [ref_id, tid])
    useEffect(
        () => { getSupportingMatchList() }, [ref_id, tid])
    useEffect(
        () => { getMatchStarters() }, [ref_id, tid])
    useEffect(
        () => { getAuxList() }, [ref_id, tid])

    const getMatchList = () => {
        if (ref_id && tid) {
            setLoader(true)
            axios.post(`/api/getRefereeMatchList/${tid}`,{ref_id}).then(res => {
                setLead(res.data)
                setLoader(false)
                let total = 0
                for(let i = 0; i < res.data.length; i++){
                    total = total + res.data[i].amount
                }
                setLeadTotal(total)
                
            })
        }
    }
    const getSupportingMatchList = () => {
        if (ref_id && tid) {
            axios.post(`/api/getSupportingRefereeMatchList/${tid}`,{ref_id}).then(res => {
                setSupporting(res.data)
                let total = 0
                for(let i = 0; i < res.data.length; i++){
                    total = total + res.data[i].amount
                }
                setSupportingTotal(total)
            })
        }
    }
    const getMatchStarters = () => {
        if (ref_id && tid) {
            axios.post(`/api/getMatchStartersMatchList/${tid}`,{ref_id}).then(res => {
                setMatchStarter(res.data)
            })
        }
    }
   const getAuxList = () => {
        if (ref_id) {
            axios.post(`/api/getAuxMatchListForReferee/${tid}`,{ref_id}).then(res => {
                setAux(res.data)
                let total = 0
                for(let i = 0; i < res.data.length; i++){
                    total = total + res.data[i].amount
                }
                setAuxTotal(total)
            })
        }
    }

    return(
        <div style={{marginTop: '20px'}}>
            <label htmlFor="">Filter By Date
                <input type="text"
                    placeholder='MM/DD'
                    value={filter}
                    onChange={e=>setFilter(e.target.value)} />
            </label>
            <br/>
            
            {loader ? <LinearProgress /> : ''}
            Total : {lead.length + supporting.length + aux.length + matchStarter.length}
            <p>${leadTotal +supportingTotal +auxTotal}</p>
            {lead.length > 0 ?
            <>
            <div className='text-align-left'>Lead Referee</div>
            <MatchTableList
                matches={lead}
                type='Lead Referee'
                filter={filter}/>
            </>
               :null}
               {supporting.length > 0 ?
               <>
                 <div className='text-align-left'>Supporting Referee</div>
               <MatchTableList 
                type='Supporting Referee'
                matches={supporting}
                filter={filter}
                />
                </>:null}
                {aux.length > 0 ?
               <>
                 <div className='text-align-left'>Auxillary Assignments</div>
               <MatchTableList 
                type='Aux Assignment'
                matches={aux}
                filter={filter}
                />
                </>:null}
                {aux.length > 0 ?
               <>
                 <div className='text-align-left'>Match Starter</div>
               <MatchStarterList
                type='Match Starter'
                matches={matchStarter}
                filter={filter}
                />
                </>:null}
                {!loader && supporting.length === 0 && lead.length === 0 ?
                <h4>Sorry no matches were found</h4>
                :null    
            }

        </div>
    )
}
export default RefereeMatchListByTournament