import React, { useState, useEffect } from 'react';
import axios from 'axios'
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter'



const LookupVolunteer = (props) => {
    const [volunteers, setVolunteers] = useState([])

    useEffect(
        () => { getAllVolunteers() })

    const getAllVolunteers = () => {
        axios.get(`/api/getAllRefList/${props.tid}`).then(
            res => {
                setVolunteers(res.data)
            }
        )
    }

    const columns = [

        // {
        //     Header: 'Registered',
        //     accessor: 'line_judge',
        //     filterMethod: (filter, rows) =>
        //         matchSorter(rows, filter.value, { keys: ["last"] }),
        //     filterAll: true


        // },
        {
            Header: 'First',
            accessor: 'first',
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["first"] }),
            filterAll: true

        },
        {
            Header: 'Last',
            accessor: 'last',
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["last"] }),
            filterAll: true


        },



    ]
    return (
        <div className="">

            <ReactTable
                data={volunteers}
                filterable
                defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value}
                getTrProps={(state, rowInfo, column, instance) => ({
                    onClick: e => props.addVolunteerToShift(rowInfo.original.ref_id)
                })}
                className='ReactTable'

                columns={columns}
            />
        </div>


    )
}
export default LookupVolunteer;