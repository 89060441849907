import React, {useState} from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';



const ShondaDashboardRow = props => {
    const [lat, setLat] = useState(0)
    const [long, setLong] = useState(0)
    const [edit, setEdit] = useState(false)
    let {row} = props

    const handleLatLong = () => {
        setLat(row.lat)
        setLong(row.long)
        setEdit(true)
    }

    const saveLatLong = () => {
        axios.put(`/api/t/saveLatLong`, {tid: row.tid, lat, long}).then(
            res => {
                setEdit(false)
            }
        )
    }
    const handleInvoiced = () => {
        axios.put(`/api/t/handleInvoiced`, {tid: row.tid}).then(
            res => {
                props.get()
            }
        )
    }

    return(
        <TableRow key={row.name}>
              <TableCell component="th" scope="row">
              <button
                            onClick={() => props.selectTournament(row)}>
                            GO</button>
              </TableCell>
              <TableCell align="left">{row.status}
              </TableCell>
              <TableCell align="left">
              <input type="checkbox" checked={row.invoiced} onClick={handleInvoiced} />
              invoiced
              </TableCell>
              <TableCell align="left">{`${row.tid} ${row.name}`}</TableCell>
              <TableCell align="left">{row.start }</TableCell>
              <TableCell align="left">{row.end}</TableCell>
              <TableCell align="left">{row.organization}</TableCell>
              <TableCell align="left">{row.court_count}<Link to={`/admin/addCourts/${row.tid}/${row.name}`} >
                  
              

<button>Edit Courts</button>
</Link>
</TableCell>
<TableCell onClick={handleLatLong} align="left">{lat || row.lat}/ {long || row.long}</TableCell>
<Dialog  aria-labelledby="simple-dialog-title" open={edit}>
      <DialogTitle >Edit Lat/ Long</DialogTitle>
      <div>
        <label htmlFor="">
            Lat 
            <input type="number"
                value={lat}
                onChange={e=>setLat(e.target.value)} />
        </label>
        <label htmlFor="">
            Long
            <input type="number"
                value={long}
                onChange={e=>setLong(e.target.value)} />
        </label>
        <button onClick={saveLatLong}>Save</button>
        <button onClick={()=>setEdit(false)}>Cancel</button>
      </div>
      </Dialog>
            </TableRow>
    )
}
export default ShondaDashboardRow