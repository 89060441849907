import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { updateTournament, clearTournament, tournamentAdmin } from '../../ducks/reducer'
import './RefDeskDashboard.css'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import MyProfile from '../Profile/MyProfile'


import ListOfTournaments from '../TournamentListing/ListOfTournaments';
import VolunteerCaptainList from './VolunteerCaptainList'
import Intro from '../utlities/Intro'
import HomePageDescription from './HomePageDescription'
// import CourtMonitorDayOf from '../DayOfComponents/CourtMonitorDayOf'


class RefDeskDashboard extends Component {
    constructor() {
        super()
        this.state = {
            tournaments: [],
            archivedTournaments: [],
            nameSearch: '',
            noTournaments: false,
            admin: false
        }

    }

    componentDidMount() {
        this.getTournaments()
        this.props.clearTournament()
    }

    getTournaments = async () => {
        await axios.get(`/api/getTournaments/${this.props.ref_id}`).then(
            res => {
                this.setState({ tournaments: res.data })
                if(res.data.length === 0){
                    this.setState({ noTournaments: true })
                    this.checkIfAdmin()
                }
            }
        )
    }
    
    getArchivedTournaments = async () => {
        await axios.get(`/api/getArchivedTournaments/${this.props.ref_id}`).then(
            res => {
                this.setState({ archivedTournaments: res.data })
            }
        )
    }

    checkIfAdmin = async () => {
        await axios.get(`/api/checkIfAdmin/${this.props.ref_id}`).then(
            res => {
                this.setState({ admin: res.data.admin })
            }
        )
    }
   
    selectTournament = async (tourney) => {
        await this.props.tournamentAdmin()
        await this.props.updateTournament(tourney)
        this.props.history.push('/admin')
    }



    fieldView = (tourney) => {

        this.props.updateTournament(tourney)
        this.props.history.push('/field')

    }

    render() {
        const mappedTournaments = this.state.tournaments
            .filter(obj => obj.name.toUpperCase().includes(this.state.nameSearch.toUpperCase()))
            .map((tourney, i) => {
                return (
                    <div key={'a'+i} >
                        <div
                            className='tournament-listing-operations shadow mb-1 bg-white rounded'
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.selectTournament(tourney)}
                        >
                            <div className='tournament-status'>
                                {tourney.status}
                            </div>
                            <div className={tourney.status === 'active' ? 'tournament-status-active' : 'tournament-status-draft'}>
                                {tourney.start ?
                                    <span>
                                            {tourney.start} - 
                                            {tourney.end_date}
                                    </span> : 'Dates Needed'}
                            </div>
                            <h4>{tourney.name}</h4>
                            <p>{tourney.location}</p>

                        </div>
                    </div>
                )
            })
        const mappedArchivedTournaments = this.state.archivedTournaments
            .filter(obj => obj.name.toUpperCase().includes(this.state.nameSearch.toUpperCase()))
            .map((tourney, i) => {
                return (
                    <div key={'b'+i} >
                        <div
                            className='tournament-listing-operations shadow mb-1 bg-white rounded'
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.selectTournament(tourney)}
                        >
                            <div className='tournament-status'>
                                archived
                            </div>
                            <div className='tournament-status-archived'>
                                {tourney.start ?
                                    <span>
                                            {tourney.start} -
                                            {tourney.end_date}
                                    </span> : 'Dates Needed'}
                            </div>
                            <h4>{tourney.name}</h4>
                            <p>{tourney.location}</p>

                        </div>
                    </div>
                )
            })

        return (
            <div className='container'>
                <div className='row'>

                    {(this.state.tournaments.length > 0 || this.state.admin) ?
                        <div className='col-12 col-md-6 '>

                            <div className='dashboard-title-operations'>
                                <>Admin/ Operations Desk</>
                                <div><small>These are the tournaments you have administrative privileges for.</small></div>
                            </div>
                            <div>
                                <legend>
                                    Search by name:
                <input type="text"
                                        value={this.state.nameSearch}
                                        onChange={e => this.setState({ nameSearch: e.target.value })} />
                                </legend>
                            </div>


                            {mappedTournaments}
                            <button onClick={this.getArchivedTournaments}>
                                View Archived Tournaments
                            </button>
                            {mappedArchivedTournaments}


                        </div> : ''}
                        <VolunteerCaptainList/>
                    <div className='col-12 col-md-6' >
                        

                        <div className='dashboard-title'>
                            Referee/ Volunteer Opportunities
                        </div>


                        <ListOfTournaments />

                    </div>
                    {this.state.tournaments.length < 0 || this.state.archivedTournaments.length < 0 ? 
                    <HomePageDescription/>:null}
                </div>
                <Dialog  aria-labelledby="simple-dialog-title" open={!this.props.profile_updated}>
      <DialogTitle >Lets make sure your profile is up to date:</DialogTitle>
      <div>
     <MyProfile 
        show={true}/>
      </div>
      </Dialog>
            </div>
        )
    }
}

function mapStateToProps({ tid, name, url,
    location,
    num_of_courts, zone, ref_id, profile_updated }) {
    return {
        tid, name, url,
        location,
        num_of_courts, zone, ref_id, profile_updated
    }
}

export default connect(mapStateToProps, { updateTournament, clearTournament, tournamentAdmin })(RefDeskDashboard)