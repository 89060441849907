import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './RefereeNav/RefereeNav.css';

class VolunteerNav extends Component {
    constructor() {
        super()
        this.state = {
            toggle: false,
            navHeight: 0
        }
    }


    toggleNav = () => {
        this.setState({
            navHeight: !this.state.navHeight ? 240 : 0
        })
    }



    render() {
        return (
            <div className="App">
                <nav className="navbar" >
                    <div>
                        <span className='menuBurger'
                            onClick={this.toggleNav}
                            onMouseEnter={this.toggleNav}>Volunteer Captain: &#9776;</span>
                        <div className={this.state.toggle ? "bigList nav-height" : "bigList"}>
                            <ul>
                            <li><Link to={`/volunteercaptain/scheduling`} >
                            Shifts</Link></li>
                        <li><Link to={`/volunteercaptain/managevolunteers`} >
                            Scheduling</Link></li>
                            <li><Link to={`volunteercaptain/shifsbyday`} >
                            Check-in</Link></li>
                            <li><Link to={`/volunteercaptain/schedulebyvolunteer`} >
                            Volunteer List</Link></li>

                            
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="menuList"
                    onMouseLeave={() => this.setState({ navHeight: 0 })}
                    style={{ height: this.state.navHeight }}
                >
                    <ul onClick={this.toggleNav}>
                    
                    <li><Link to={`/volunteercaptain/scheduling`} >
                            Shifts</Link></li>
                        <li><Link to={`/volunteercaptain/managevolunteers`} >
                            Scheduling</Link></li>
                            <li><Link to={`volunteercaptain/shifsbyday`} >
                            Check-in</Link></li>
                            <li><Link to={`/volunteercaptain/schedulebyvolunteer`} >
                            Volunteer List</Link></li>
                            
                       
                    </ul>
                </div>

            </div>
        );
    }
}

export default VolunteerNav;