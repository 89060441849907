import React, { useState } from 'react';
import axios from 'axios'
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import '../RefDesk/RefDesk.css'

const AddVolunteerFromSystem = (props) => {

    const [verifyDelete, setVerifyDelete] = useState(false)
    const [referee, setReferee] = useState({})
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)

    const searchForName = async (name) => {

        await setSearch(name)
        if (search.length > 2) {
            getName()
            setLoader(true)
        } else {
            setSearchResults([])
        }
    }

    const getName = () => {
        axios.post(`/api/getVolunteerName?search=${search}`, { tid }).then(res => {
            setSearchResults(res.data)
            setLoader(false)
        })

    }

    const handleVerifyDelete = (referee) => {
        if (referee) {
            setReferee(referee)
            setVerifyDelete(!verifyDelete)
        } else {
            setReferee({})
            setVerifyDelete(!verifyDelete)
        }
    }

    const deleteVerified = () => {
        props.removeRef(referee[0])
        setReferee({})
        setVerifyDelete(!verifyDelete)
    }

    const mappedSearchResults = searchResults.map(name => {
        return (
            <div key={name.ref_id} className='search-results'>
                <div style={{ width: '20%' }}>
                    {name.status ? <span style={{ color: 'green' }}><i className="fas fa-user-check" ></i>Registered </span> :
                        <div style={{ cursor: 'pointer' }}
                            onClick={() => props.addRefToTournament(name.ref_id)}>
                            <button>Add</button>
                        </div>}
                </div>
                <div style={{ cursor: 'pointer', width: '60%' }}
                    onClick={() => props.addRefToTournament(name.ref_id)}>
                    {name.first} {name.last} - {name.credentials}
                </div>
                <div>

                    {name.status ? <span
                        onClick={() => handleVerifyDelete(name)}
                        style={{ color: 'red', cursor: 'pointer' }}><i className="fas fa-user-slash" ></i>Remove </span> : ''}
                </div>

            </div>
        )
    })


    return (
        <div className="">
            <legend>
                <h4>Start typing name... </h4>
                <input
                    type="text"
                    placeholder='Name'
                    value={search}
                    onChange={e => searchForName(e.target.value)} />
            </legend>
            {loader ? <img width="100" src={require('../../img/15.gif')} alt='players' /> : ''}
            {mappedSearchResults}

            <Modal open={verifyDelete} center showCloseIcon={true} onClose={handleVerifyDelete} closeIconSize={20} closeOnEsc={true} >
                <div>
                    <h4>Are you sure you want to remove {referee[2]} {referee[3]} from the tournament?</h4>
                    <p>Volunteer will be removed from all scheduled shifts.  This can not be reversed.</p>
                    <p>Please make sure to first notify the volunteer before removing.</p>
                    <button onClick={deleteVerified}>Verify Remove</button>

                </div>
            </Modal>



        </div>


    )
}
export default AddVolunteerFromSystem;