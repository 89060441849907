import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import CircularProgress from '@material-ui/core/CircularProgress';



const ApprovedRefereeEmail = props => {
    const [people, setPeople] = useState([])
    const [selected, setSelected] = useState([])
    const [searchName, setSearchName] = useState('')
    const [searchCred, setSearchCred] = useState('')
    const [searchCount, setSearchCount] = useState('')
    const [searchStatus, setSearchStatus] = useState('')

    const [subject, setSubject] = useState('')
    const [body, setBody] = useState('')
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)
    const reply = useSelector(state => state.email)
    const author = useSelector(state => state.ref_id)
    const tournamentName = useSelector(state => state.name)

    const classes = useStyles();

    useEffect(
        () => { getReferees() }, [tid])
    useEffect(
        () => { clearState() }, [tid])

    const getReferees = () => {
        axios.get(`/api/email/getApprovedEmailsWithScheduleCount/${tid}`).then(
            res => {
                setPeople(res.data)
            }
        )
    }

    const sendEmail = async () => {

        if (selected.length === 0) { return toast.warning('Select Email Recipients') } else {
            setLoader(true)
            await selected.forEach(async x => {
                let s = selected.splice(0, 25)
                await handleSendEmail(s)
            })
            await getReferees()
            await clearState()
            await setLoader(false)
            await toast.success('email sent')
        }
    }

    const handleSendEmail = async (s) => {
        axios.post(`/api/email`, { email: s, tid, body, reply, subject, tournamentName, category:'referee', author  }).then(
            res => {
                if (res.data === 'error') {
                    toast.error('Error Sending Email')
                }
                
            }
        )
    }

    const selectRecipients = () => toast.warning('Select Email Recipients')


    const select = (row) => {
        if (selected.some(item => row.ref_id === item.ref_id)) {
            let index = selected.map(function (e) { return e.ref_id; }).indexOf(row.ref_id);
            remove(index)
        } else {
            add(row)
        }
    }

    const handleSelectAll = () => {
        let all = people
        .filter(obj => obj.name ? obj.name.toUpperCase().includes(searchName.toUpperCase()) : '')
        .filter(obj => obj.credentials ? obj.credentials.toUpperCase().includes(searchCred.toUpperCase()) : '')
        .filter(obj => obj.count ? obj.count.toUpperCase().includes(searchCount.toUpperCase()) : '')
        .filter(obj => obj.status ? obj.status.toUpperCase().includes(searchStatus.toUpperCase()) : '')
        setSelected(all)
    }
    const add = (row) => {
        let list = selected.concat(row)
        setSelected(list)
    }

    const remove = (i) => {
        let list = [...selected]
        list.splice(i, 1)
        setSelected(list)
    }

    const clearState = () => {
        setSelected([])
        setSearchCred('')
        setSearchCount('')
        setSearchStatus('')
        setSubject('')
        setBody('')
    }

    const handleBody = (value) => {
        setBody(value)
    }

    return (
        <div className='container-fluid'>
            <h3>Email Referees</h3>
            <div className='row'>
                <div className='col-12 col-md-7'>
                    <h5>Referees who have requested approval or have been approved</h5>
                    <div className='tips'>
                      List shows all referees who have sent a request.  Use filters to select the referees you want to contact.
                      Click on name to select.  
                    </div>

                    <TableContainer component={Paper} className={classes.table}>
                        <button
                            className='select-button'
                            onClick={handleSelectAll}>Select All</button>
                        <button
                            className='select-button'
                            onClick={() => setSelected([])}>Clear Selection</button>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="left" className={classes.credentials}>Credentials</TableCell>
                                    <TableCell align="left" className={classes.status}>Status</TableCell>
                                    <TableCell align="left" className={classes.count}>Shift Count</TableCell>
                                    <TableCell align="left">Request Time</TableCell>
                                    <TableCell align="left">Approved Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <input type="text"
                                            onChange={e => setSearchName(e.target.value)}
                                            value={searchName} />
                                    </TableCell>
                                    <TableCell align="left">
                                    <input type="text"
                                        className={classes.credentials}
                                            onChange={e => setSearchCred(e.target.value)}
                                            value={searchCred} />
                                    </TableCell>
                                    <TableCell align="left">
                                    <input type="text"
                                        className={classes.status}
                                            onChange={e => setSearchStatus(e.target.value)}
                                            value={searchStatus} />
                                    </TableCell>
                                    <TableCell align="left" className={classes.count}>
                                         <input type="text"
                                            className={classes.count}
                                            onChange={e => setSearchCount(e.target.value)}
                                            value={searchCount} />
                                    </TableCell>
                                    <TableCell align="left">
                                        
                                    </TableCell>
                                    <TableCell align="left">
                                        
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                {people
                                    .filter(obj => obj.name ? obj.name.toUpperCase().includes(searchName.toUpperCase()) : '')
                                    .filter(obj => obj.credentials ? obj.credentials.toUpperCase().includes(searchCred.toUpperCase()) : '')
                                    .filter(obj => obj.count ? obj.count.toUpperCase().includes(searchCount.toUpperCase()) : '')
                                    .filter(obj => obj.status ? obj.status.toUpperCase().includes(searchStatus.toUpperCase()) : '')

                                    .map((row, i) => (
                                        <TableRow key={'m' + i}
                                            onClick={() => select(row)}
                                            className={selected.some(item => row.ref_id === item.ref_id) ? classes.selected : ''}>
                                            <TableCell component="th" scope="row">
                                                {selected.some(item => row.ref_id === item.ref_id) ? <i className="fas fa-check"></i> : ''}
                                                {++i}. {row.name}
                                            </TableCell>
                                            <TableCell align="left" className={classes.credentials}>{row.credentials}</TableCell>
                                            <TableCell align="left" className={classes.status}>{row.status}</TableCell>
                                            <TableCell align="left" className={classes.count}>{row.count}</TableCell>
                                            <TableCell align="left">{row.request_time}</TableCell>
                                            <TableCell align="left">{row.approved_time}</TableCell>

                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <div className='col-12 col-md-5'>
                    <div className={classes.email}>
                        New Message:
                        <p style={{ color: 'red' }}>{selected.length < 1 ? 'Select Email Recipients' : ''}</p>

                        <div>
                            <input type="text"
                                className={classes.emailBody}
                                placeholder='Subject'
                                value={subject}
                                onChange={e => setSubject(e.target.value)} />
                        </div>
                        <div>

                            <ReactQuill
                                style={{ background: 'white' }}
                                value={body}
                                onChange={handleBody} />
                                <p>Reply-to email will be your email address.</p>

                            {/* <textarea name="body" id="" rows="10"
                                placeholder='message'
                                className={classes.emailBody}
                                value={body}
                                onChange={e => setBody(e.target.value)}>
                            </textarea> */}
                            {selected.length > 0 ?
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={loader}
                                    onClick={sendEmail}
                                >
                                    {loader ?
                                        <CircularProgress />
                                        : 'Send Email'}</Button> :
                                <button onClick={selectRecipients} className='inactive-button'>Send Email</button>}

                            {loader ? <h4>Email sending... please wait and do not refresh or leave page</h4> : ''}
                            <p>To send attachments (photos, videos) upload them to dropbox, google docs or your choice in file storage.  You can then include a link in the email.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ApprovedRefereeEmail

const useStyles = makeStyles({
    table: {
        textAlign: 'left',
        maxHeight: '600px',
        overflow: 'scroll'
    },
    tableBody: {
        textAlign: 'left',
        height: '500px',
        overflow: 'scroll',
    },
    selected: {
        background: "#D2D4F0"
    },
    pointer: {
        cursor: 'pointer'
    },
    error: {
        color: 'red'
    },
    credentials: {
        maxWidth: '40px'
    },
    count: {
        maxWidth: '50px'
        },
    status: {
        maxWidth: '60px'
        },
    email: {
        width: '98%'
    },
    emailBody: {
        width: '100%',
        margin: '10px'
    }
});
