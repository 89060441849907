import React, { Component } from 'react';
import axios from 'axios'
import { connect } from "react-redux"
import io from 'socket.io-client'
import Moment from 'react-moment';
import Modal from 'react-responsive-modal';


const Barcode = require('react-barcode');


class BarcodesAllReferees extends Component {
    constructor(props) {
        super(props)
        this.state = {
            referees: [],
            firstSearch: '',
            lastSearch: '',
            showModal: false,
            referee: {}
        }
    }

    componentDidMount() {
        this.getRegisteredRefs()
    }

    getRegisteredRefs = async () => {
        await axios.get(`/api/getRegisteredRefsBarcodes/${this.props.tid}`).then(
            res => {
                this.setState({ referees: res.data })
            }
        )
    }

    showModal = (referee) => {
        if (this.state.showModal) {
            this.setState({ showModal: false, referee: {} })
        }
        else { this.setState({ showModal: true, referee: referee }) }
    }


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    render() {
        const mappedMatches = this.state.referees
            .filter(obj => obj.first.toUpperCase().includes(this.state.firstSearch.toUpperCase()))
            .filter(obj => obj.last.toUpperCase().includes(this.state.lastSearch.toUpperCase()))
            .map(court => {

                return (
                    <div key={court.ref_id} className='col-12 col-lg-6' >
                        <div className='court-box-Pastbarcode'>
                            <div className='past-barcode-name'>
                                <div className='barcode-pastMatches' onClick={() => this.showModal(court)}>
                                    {court.barcode ?
                                        <Barcode
                                            format='CODE39'
                                            height={'30vh'}
                                            width={'1px'}
                                            value={court.barcode} /> : ''}
                                </div>
                                <div>{court.first} {court.last}</div>

                            </div>
                        </div>
                    </div>
                )
            })


        return (
            <div className="container">
                <div className='col-12' >
                    <h3>Referee Barcodes</h3>
                    <div className='tips'>*Click on barcode to enlarge</div>
                    <input
                        type="text"
                        placeholder='Search by First Name'
                        name="firstSearch"
                        value={this.state.firstSearch}
                        onChange={this.handleChange} />
                    <input
                        type="text"
                        placeholder='Search by Last Name'
                        name="lastSearch"
                        value={this.state.lastSearch}
                        onChange={this.handleChange} />
                    <button onClick={() => this.setState({ firstSearch: '', lastSearch: '' })}>clear</button>

                    <div style={{ display: 'flex', flexWrap: 'wrap', margin: '10px' }}>

                        {mappedMatches}
                    </div>
                </div>
                <Modal open={this.state.showModal} center showCloseIcon={true} onClose={this.showModal} closeIconSize={20} closeOnEsc={true} >
                    <div className='modal-full-screen'>
                        <div>
                            {this.state.referee.barcode ?
                                <Barcode
                                    format='CODE39'
                                    height={'30vh'}
                                    width={'1.5px'}
                                    value={this.state.referee.barcode} /> : ''}
                        </div>
                        <div>{this.state.referee.first} {this.state.referee.last}</div>
                    </div>
                </Modal>
            </div>

        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name,
        zone: reduxState.zone
    }
}


export default connect(
    mapStateToProps,
)(BarcodesAllReferees)