import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import BingoCard from './BingoCard'

const MakeBingoCard = props => {
    const [tournament, setTournament] = useState('')
    const [bingoCard, setBingoCard] = useState([]) 
    const ref_id = useSelector(state => state.ref_id)
    let {tid} = props.match.params

    useEffect(
        () => { getTournamentName() }, [props.match.params.tid])


const getTournamentName = () => {
    if(!props.match.params.tid)return
    axios.get(`/api/getTournamentName/${props.match.params.tid}`).then(
        res => {
            setTournament(res.data.name)
        }
    )
}

const makeBingoCard = () => {
    if(!props.match.params.tid)return
    if(!ref_id)return
    axios.post(`/api/bingo/makeBingoCard`, {tid, ref_id}).then(
        res => {
            // getBingoCard()
        }
    )
}

    return(
        <div className='container'>
            <h2>{tournament}</h2>
            <div>Want a chance to win fun prizes?</div>
            <div onClick={makeBingoCard}>
                <button>Start Bingo Game</button>
            </div>
            <div style={{textAlign: '-webkit-center'}}>
            <BingoCard ref_id={ref_id} tid={props.match.params.tid}/>
            </div>
            

        </div>
    )

}
export default MakeBingoCard