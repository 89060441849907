import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { toast } from 'react-toastify';

const InviteOne = props => {
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [type, setType] = useState('referee')
    const [error, setError] = useState('')

    const tid = useSelector(state => state.tid)
    const tournament_name = useSelector(state => state.name)
    const ref_id = useSelector(state => state.tid)

    const sendInvite = () => {
        if (isValidEmailAddress()) {
            axios.post(`/api/emailInvite/`, { name, email, tournament_name, tid, author: ref_id, type }).then(
                res => {
                    clearState()
                    toast.success('Email Sent')
                }
            )
        } else {
            setError('email address not valid')
        }
    }

    const clearState = () => {
        setEmail('')
        setName('')
        setError('')
    }

    const isValidEmailAddress = () => {
        return !!email.match(/.+@.+/)
    }


    return (
        <div className='row justify-content-center'>
            <div
                className='col-12'
                style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                    <h5>Email Invitation</h5>
                    <RadioGroup aria-label="type" name="type" value={type} onChange={e=>setType(e.target.value)}
                        style={{display: 'flex'}}>
                        <FormControlLabel value="referee" control={<Radio />} label="referee"/>
                        <FormControlLabel value="line judge" control={<Radio />} label="line judge" />
                        <FormControlLabel value="volunteer" control={<Radio />} label="volunteer" />
                    </RadioGroup>
                    <input type="text"
                        placeholder='name'
                        value={name}
                        onChange={e => setName(e.target.value)} />
                    <input type="text"
                        placeholder='email'
                        value={email}
                        onChange={e => setEmail(e.target.value)} />
                    <div>
                    
                        
                    </div>
                    <button onClick={sendInvite}>Send</button>
                    <div style={{ color: 'red' }}>{error}</div>

                    <Link to={`/admin/sendInvitecsv`} >
                        <div className='pointer'>Have an invite list to upload?</div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default InviteOne 