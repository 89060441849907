import React from 'react'

const CredentialGuide = () => {
    return (
        <div className='credentialGuide'>
            <div>Referee Credential Color Guide:</div>
            <div className='certified'>
                Certified
            </div>
            <div className='level2'>
                Level Two
            </div>
            <div className='level1'>
                Level One
            </div>
            <div className='volunteer' style={{ border: '1px solid grey' }}>
                Not Rated
        </div>
        </div>
    )
}
export default CredentialGuide