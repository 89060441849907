import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import MatchTable from './MatchTable'
import LinearProgress from '@material-ui/core/LinearProgress';
import AuxTable from './AuxTable';

const RefereeMatchList = props => {
    const [lead, setLead] = useState([])
    const [supporting, setSupporting] = useState([])
    const [aux, setAux] = useState([])
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)
    const first = useSelector(state => state.first)

    let {ref_id} = props


    useEffect(
        () => { getMatchList() }, [ref_id])
    useEffect(
        () => { getSupportingMatchList() }, [ref_id])
    useEffect(
        () => { getAuxList() }, [ref_id])

    const getMatchList = () => {
        if (ref_id) {
            axios.post(`/api/getRefereeMatchList/${tid}`,{ref_id}).then(res => {
                setLead(res.data)
            })
        }
    }
    const getSupportingMatchList = () => {
        if (ref_id) {
            axios.post(`/api/getSupportingRefereeMatchList/${tid}`,{ref_id}).then(res => {
                setSupporting(res.data)
            })
        }
    }

    const getAuxList = () => {
        if (ref_id) {
            axios.post(`/api/getAuxMatchListForReferee/${tid}`,{ref_id}).then(res => {
                setAux(res.data)
            })
        }
    }

    const handlePayAll = () => {
        setLoader(true)
        if (ref_id) {
            axios.put(`/api/refereePayAllMatches/${tid}`,{ref_id, first}).then(res => {
                setLoader(false)
                getMatchList()
                getSupportingMatchList()
                getAuxList()
                props.handleUpdate()
            })
        }
    }

    const getBoth = () => {
        getMatchList()
        getSupportingMatchList()
        getAuxList()
    }

    return(
        <div>
            <button onClick={handlePayAll}>Mark all as paid</button>
            <div className='text-align-left'>Lead Referee</div>
            {loader ? <LinearProgress /> : ''}
               <MatchTable 
                matches={lead}
                lead={true}
                handleUpdate={props.handleUpdate}
                getLists={getBoth}/>
                 <div className='text-align-left'>Supporting Referee</div>
               <MatchTable 
                lead={false}
                matches={supporting}
                getLists={getBoth}
                handleUpdate={props.handleUpdate}/>
                <AuxTable
                matches={aux}
                getLists={getBoth}
                handleUpdate={props.handleUpdate}/>

        </div>
    )
}
export default RefereeMatchList