import React, {useState} from 'react'
import axios from 'axios'

const AuxAssignmentList = (props) => {
    const [showDelete, setShowDelete] = useState(false)

    const ref = props.referee

    const deleteAssignment = () => {
        axios.post(`/api/deleteAuxAssignment`, {id: ref.id, tid: ref.tid, ref_id: ref.ref_id}).then(

            res => {
                if(props.get){
                    props.get()
                }
            }
        )
    }
    return (
        <div style={{display:'flex'}}>
            <div style={{background: 'black', color: 'white', fontSize:'2em', height: '60px', padding: '10px'}}>
                {ref.court_num}
            </div>







            
            <div 
            className={
                ref.quick_break ? 'break ref-list' :
                    ref.credentials_verified === 'Certified' ? 'ref-list certified' :
                        ref.credentials_verified === 'Level 2' ? 'ref-list level2' :
                            ref.credentials_verified === 'Level 1' ? 'ref-list level1' : 'ref-list'}
            >
                {ref.note ? <span className='ref-note'>{ref.note}</span> : ''}
                {ref.tag ? <span className='ref-tag'>{ref.tag}</span> : ''}
                {ref.ref_note ? <span className='ref-note'>{ref.ref_note}</span> : ''}

                <div
                    style={{ cursor: 'pointer' }}
                    >
                        
                    <span> {ref.type}</span> 

                    <div className='magnet-name-small col-11'>{ref.name}</div>
                    <button className='small-button'
                        onClick={()=>setShowDelete(!showDelete)}>{showDelete ? 'close':'edit'}</button>
                    {showDelete && 
                    <>
                    <br/>
                    <button onClick={deleteAssignment}>Delete Assignment</button>
                    
                    </>
                    }
                    
                </div>

            </div>
           
        </div >
    )
}
export default AuxAssignmentList