import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Link } from 'react-router-dom';


const CourtMonitorDayOf = props => {
    const [tournaments, setTournaments] = useState([])

    const ref_id = useSelector(state => state.ref_id)
    const tid = useSelector(state => state.tid)

    useEffect(
        () => { verifyCourtMonitor() }, [props.started])
    const verifyCourtMonitor = async () => {
        if(!props.started && ref_id && tid) return
        await axios.post(`/api/checkIfCourtMonitor/`, { ref_id, tid }).then(

            res => {
                setTournaments(res.data)
            }
        )
    }

    const mappedTournaments = tournaments.map((t, i) => {
        return (
            <div key={'cm' + i}>
                <Link to={`/volunteer/courtMonitor/${t.tid}`} >
                    <button>Open</button>
                </Link>
            </div>
        )
    }
    )

    return (
        <div>
            {tournaments.length > 0 ?
                <div className='court-monitor'>
                    <h3>Court Monitor</h3>
                    {mappedTournaments}
                </div> : ''}

        </div>
    )
}
export default CourtMonitorDayOf