import React, { useState } from 'react'
import AddPlayer from './RankingSetup/AddPlayer'
import AddTournament from './RankingSetup/AddTournament'
import TournamentList from './RankingSetup/TournamentList'
import './Ranking.css'

const RankingMaster = props => {

    return (
        <div className='container'>
            <AddPlayer />
            <AddTournament />
            <TournamentList />
        </div>
    )
}
export default RankingMaster