import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setLineShift } from '../../ducks/reducer'
import Switch from "@material-ui/core/Switch";
import Modal from 'react-responsive-modal';
import LineJudgeCommunication from '../Communication/LineJudgeCommunication'
import { CirclePicker } from 'react-color';
import MappedShift from './MappedShift'
import './LineJudge.css'
import { toast } from 'react-toastify';
import AddLineJudge from './AddLineJudge'
import LookupVolunteer from './LookupVolunteer'
import LineCheckin from './LineCheckin'
import EditLineJudgeContact from './EditLineJudgeContact'
import LineCourt from './LineCourt'
import LineSocket from './LineSocket'

const io = require('socket.io-client');
const socket = io();



const LineJudgeManager = (props) => {
    const [leftCourts, setLeftCourts] = useState([])
    const [rightCourts, setRightCourts] = useState([])
    const [checkedInLineJudges, setCheckedInLineJudges] = useState([])
    const [deleteGid, setDeleteGid] = useState(null)
    const [emailList, setEmailList] = useState([])
    const [showSendText, setShowSendText] = useState(false)
    const [shifts, setShifts] = useState([])
    const [groups, setGroups] = useState([])
    const [group, setGroup] = useState('')
    const [showEditGroup, setShowEditGroup] = useState(false)
    const [showGroupModal, setShowGroupModal] = useState(false)
    const [editGroup, setEditGroup] = useState({})
    const [newGroup, setNewGroup] = useState('')
    const [selectedName, setSelectedName] = useState({})
    const [selectedGroup, setSelectedGroup] = useState({})
    const [selectedCourt, setSelectedCourt] = useState({})
    const [showFinishMatch, setShowFinishMatch] = useState(false)
    const [showCheckInModal, setShowCheckInModal] = useState(false)
    const [textList, setTextList] = useState([])
    const [groupText, setGroupText] = useState(false)
    const [color, setColor] = useState('')
    const [loader, setLoader] = useState(true)
    const [showAddLineJudge, setShowAddLineJudge] = useState(false)
    const [verifyCheckoutAll, setVerifyCheckoutAll] = useState(false)
    const [showEditLineJudge, setShowEditLineJudge] = useState(false)

    const tid = useSelector(state => state.tid)
    const shift = useSelector(state => state.lineShift)
    const dispatch = useDispatch();


    useEffect(
        () => { getGroups() }, [shift])
    useEffect(
        () => { getCheckedInLineJudges() }, [])
    useEffect(
        () => { getCheckedInLineJudges() }, [showCheckInModal])
    useEffect(
        () => { getCourts() }, [])
    useEffect(
        () => { saveGroupAssignment() }, [selectedGroup])
    useEffect(
        () => { saveGroupAssignment() }, [selectedName])
    useEffect(
        () => { assignCourt() }, [selectedCourt])
    useEffect(
        () => { assignCourt() }, [selectedGroup])


    const handleGetAllGroups = () => {
        getGroups()
        getCheckedInLineJudges()
    }

    const getCourts = () => {
        axios.get(`/api/getLineCourts/${tid}`).then(res => {
            setRightCourts(res.data.right)
            setLeftCourts(res.data.left)
            setLoader(false)
        })
    }

    const getGroups = () => {
        if (shift) {
            axios.post(`/api/getLineGroups/${tid}`).then(res => {
                setGroups(res.data)
                setLoader(false)
            })
        }
    }

    const getCheckedInLineJudges = () => {
        axios.get(`/api/getCheckedInLineJudges/${tid}`).then(res => {
            setCheckedInLineJudges(res.data)
            setLoader(false)
        })
    }


    const saveGroupName = () => {
        setLoader(true)
        axios.post(`/api/saveGroupName/${tid}`, { group_name: newGroup, sid: shift.sid, color: color }).then(res => {
            handleGetAllGroups()
            setGroup(newGroup => '')
            setLoader(false)
            setShowGroupModal(false)
            socket.emit('line', { room: `board:${tid}`, msg: 'group' })
        })
    }

    const handleEditGroupName = (g) => {
        window.scrollTo(0, document.body.scrollHeight)
        setShowGroupModal(showGroupModal => true)
        setShowEditGroup(showEditGroup => true)
        setNewGroup(g.group_name)
        setColor(g.color)
        setEditGroup(g)
    }

    const handleCancelEditGroupName = () => {
        setShowGroupModal(showGroupModal => false)
        setShowEditGroup(showEditGroup => false)
        setNewGroup(newGroup => '')
        setColor('')
        setEditGroup({})

    }

    const editGroupName = () => {
        setLoader(true)
        axios.put(`/api/editGroupName/${tid}`, { gid: editGroup.gid, group_name: newGroup, sid: shift.sid, color: color }).then(res => {
            handleGetAllGroups()
            setGroup(newGroup => '')
            setColor('')
            setEditGroup({})
            setShowGroupModal(showGroupModal => false)
            setLoader(false)
            socket.emit('line', { room: `board:${tid}`, msg: 'group' })
        })
    }

    const deleteGroup = () => {
        setLoader(true)
        axios.put(`/api/deleteGroup/${tid}`, { gid: deleteGid }).then(res => {
            handleGetAllGroups()
            setDeleteGid(null)
            setLoader(false)
            socket.emit('line', { room: `board:${tid}`, msg: 'group' })
        })
    }

    const removeFromLineGroup = (ref_id) => {
        setLoader(true)
        axios.put(`/api/removeFromLineGroup/${tid}`, { ref_id }).then(res => {
            handleGetAllGroups()
            setLoader(false)
            socket.emit('line', { room: `board:${tid}`, msg: 'group' })
        })
    }


    const saveGroupAssignment = () => {
        if (selectedName.first && selectedGroup.group_name) {
            setLoader(true)
            axios.put(`/api/saveGroupAssignment/${tid}`, { gid: selectedGroup.gid, ref_id: selectedName.ref_id }).then(res => {
                handleGetAllGroups()
                setSelectedGroup(selectedGroup => '')
                setSelectedName(setSelectedName => '')
                setLoader(false)
                socket.emit('line', { room: `board:${tid}`, msg: 'group' })
            })
        }
    }

    const assignCourt = () => {
        if (selectedGroup.court_num && selectedCourt) {
            toast.warning(`Group Already Assigned to a Court`)
            setSelectedGroup(selectedGroup => '')
            setSelectedCourt(selectedCourt => '')
        }
        if (!selectedCourt.gid && selectedGroup.gid && selectedCourt.court_num && !selectedGroup.court_num) {
            setLoader(true)
            axios.put(`/api/assignLineCourt/${tid}`, { gid: selectedGroup.gid, court_num: selectedCourt.court_num }).then(res => {
                getCourts()
                handleGetAllGroups()
                setSelectedGroup(selectedGroup => '')
                setSelectedCourt(selectedCourt => '')
                socket.emit('line', { room: `board:${tid}`, msg: 'line get all' })
            })
        }
    }

    const checkOutLineJudge = (ref_id) => {
        setLoader(true)
        axios.put(`/api/checkOutLineJudge/${tid}`, { ref_id }).then(res => {
            handleGetAllGroups()
            socket.emit('line', { room: `board:${tid}`, msg: 'group' })
        })
    }

    const checkinLineJudge = (ref_id) => {
        axios.put(`/api/checkinLineJudge/${tid}`, { ref_id }).then(res => {
            getGroups()
            socket.emit('line', { room: `board:${tid}`, msg: 'group' })
        })
    }


    const handleFinishMatch = (court) => {
        setSelectedCourt(court)
        setShowFinishMatch(finishMatch => !finishMatch)

    }

    const finishMatch = () => {
        setLoader(true)
        axios.put(`/api/finishLineMatch/${tid}`, { court: selectedCourt }).then(res => {
            getCourts()
            handleGetAllGroups()
            setSelectedCourt(selectedCourt => '')
            setShowFinishMatch(showFinishMatch => !showFinishMatch)
            socket.emit('line', { room: `board:${tid}`, msg: 'line get all' })
        })
    }
    const finishMatchAndGroup = () => {
        setLoader(true)
        axios.put(`/api/finishLineMatchAndGroup/${tid}`, { court: selectedCourt }).then(res => {
            getCourts()
            handleGetAllGroups()
            setSelectedCourt(selectedCourt => '')
            setShowFinishMatch(showFinishMatch => !showFinishMatch)
            socket.emit('line', { room: `board:${tid}`, msg: 'line get all' })
        })
    }


    const addVolunteerToShift = async (ref_id) => {
        setLoader(true)
        setShowAddLineJudge(false)
        await axios.post(`/api/addVolunteerAndCheckin/${tid}`, { ref_id: ref_id, sid: shift.sid }).then(
            res => {
                handleGetAllGroups()
                socket.emit('line', { room: `board:${tid}`, msg: 'group' })
            }
        )
    }

    const addLineJudge = (refInfo) => {
        setLoader(true)
        setShowAddLineJudge(false)
        axios.post(`/api/addLineJudgeAndCheckin`, { tid, sid: shift.sid, refInfo }).then(res => {
            handleGetAllGroups()
            socket.emit('line', { room: `board:${tid}`, msg: 'group' })
        })
    }

    const checkoutAllLineJudges = () => {
        setVerifyCheckoutAll(false)
        axios.put(`/api/checkoutAllLineJudges/${tid}`).then(res => {
            setCheckedInLineJudges([])
            getGroups()
            socket.emit('line', { room: `board:${tid}`, msg: 'group' })
        })
    }


    const sendTextToShift = (list) => {
        setTextList(list)
        setShowSendText(true)
        setGroupText(false)
    }

    const sendTextToGroup = (list) => {
        setTextList(list)
        setShowSendText(true)
        setGroupText(true)
    }

    const handleColor = (color, event) => {
        setColor(color.hex)
    }

    const handleCancelFinishMatch = () => {
        setShowFinishMatch(finishMatch => !finishMatch)
        setSelectedCourt('')
    }

    const handleHideCheckInModal = () => setShowCheckInModal(false)





    const mappedLeftCourts = leftCourts.map(c => {

        return (
            <div className='col-6' key={c.court_order}>
                <LineCourt
                    c={c}
                    handleFinishMatch={handleFinishMatch}
                    setSelectedCourt={setSelectedCourt}
                    selectedCourt={selectedCourt} />
            </div>
        )
    })
    const mappedRightCourts = rightCourts.map(c => {

        return (
            <div className='col-6 two-per-column' key={c.court_order}>
                <LineCourt
                    c={c}
                    handleFinishMatch={handleFinishMatch}
                    setSelectedCourt={setSelectedCourt}
                    selectedCourt={selectedCourt} />
            </div>
        )
    })

    const mappedGroups = groups.map((g, i) => {
        const mappedNames = g.signup.map(name => {
            return (
                <div key={'g' + name.ref_id} className='line-name-list'>

                    < Switch
                        color="primary"
                        checked={name.status}
                        value={name.status ? "Active" : 'Inactive'}
                        onClick={() => { name.status ? checkOutLineJudge(name.ref_id) : checkinLineJudge(name.ref_id) }}
                    />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div >

                            {name.referee_court ? <div style={{ background: 'red', borderRadius: '10px', color: 'white', padding: '1px 4px', margin: '0 4px' }}>{name.referee_court}</div> : ''}</div>
                        {name.last}, {name.first}   <i className="fas fa-minus-circle" onClick={() => removeFromLineGroup(name.ref_id)}></i></div>



                </div>
            )
        })
        return (
            <div key={'n' + i}
                className='col-12'
            >
                <div className={selectedGroup.gid === g.gid ? 'line-group selected-group' : 'line-group'} >
                    <div className='line-group-header '
                        style={{ background: g.color || '#abb8c3' }}

                        onClick={() => {
                            selectedGroup.gid === g.gid ? setSelectedGroup('')
                                : setSelectedGroup(g)
                        }} >
                        <div className='line-group-court-assignment'>
                            {g.court_num ? <div id='court'>COURT</div> : ''}
                            <div >{g.court_num}</div>
                        </div>
                        <div className='line-group-title '>
                            {g.group_name}



                        </div>

                        <div>
                            <div >
                                <i onClick={() => handleEditGroupName(g)}
                                    className="fas fa-edit line-group-icons"></i>
                                <i className="far fa-trash-alt line-group-icons"
                                    onClick={() => setDeleteGid(g.gid)}></i>
                                <i className="far fa-comment line-group-icons"
                                    onClick={() => sendTextToGroup(g)}></i>
                            </div>

                        </div>



                    </div>
                    <div className='row'>

                        {mappedNames}

                    </div>

                </div>
            </div>
        )
    })

    const mappedCheckedIn = checkedInLineJudges.map((n, i) => {
        return (
            <div key={'s' + i}
                className='line-no-group-list'
            >
                < Switch
                    color="primary"
                    checked={n.status}
                    value={n.status ? "Active" : 'Inactive'}
                    onClick={() => checkOutLineJudge(n.ref_id)}
                />
                <div className={selectedName.ref_id === n.ref_id ? 'line-name-list-name selected-name' : 'line-name-list-name'}
                    onClick={() => selectedName.ref_id === n.ref_id ? setSelectedName('') : setSelectedName(n)}>
                    <div>
                        {n.referee_court ? <div style={{ background: 'red', borderRadius: '10px', color: 'white', padding: '1px 4px', margin: '0 4px' }}>Referee-  {n.referee_court}</div> : ''}</div>
                    {n.last}, {n.first}

                </div>
                <div className='assignButton'
                    onClick={() => selectedName.ref_id === n.ref_id ? setSelectedName('') : setSelectedName(n)}>
                    {selectedName.ref_id === n.ref_id ? 'cancel assign' : 'assign'}</div>

            </div >
        )
    })


    return (
        <div className='container'>
            <LineSocket
                getCheckedInLineJudges={getCheckedInLineJudges}
                getGroups={getGroups}
                getCourts={getCourts}
                tid={tid} />
            <div className='row'>
                <div className='col-12'>
                    Line Coordinator- if you want to receive a text when bronze medal matches are called make sure your text is imputed in tournament setup area.
                    </div>
                <div className='tips col-12'>*To assign a group to a court click on court then group name.  To add a volunteer to a group click on their name then group name
                </div>

            </div>
            <div className='row' style={{ margin: '20px 0' }}>
                <div className='col-12 col-md-5 court-list'>
                    {mappedLeftCourts}

                </div>
                <div className='col-0 col-md-2'>
                </div>
                <div className='col-12 col-md-5 court-list'>
                    {mappedRightCourts}

                </div>
            </div>

            <div>
                {loader ?
                    <img id='loader' width="100" src={require('../../img/15.gif')} alt='players' /> : ''}
            </div>
            <div className='line-tip col-12'>

                {selectedCourt.court_num && !showEditGroup ?
                    <div>Click on group name to assign to court.  To cancel click on court number again <button onClick={() => setSelectedCourt('')}>Cancel Court Selection</button></div>
                    : ''}
                {selectedGroup.gid ? 'To assign volunteers to group click on volunteer name or to assign group to court click on court number.  To cancel click on group name again' : ''}
            </div>



            <div className='row' >
                <div className='col-12 col-sm-6 col-md-4'
                    style={{ background: 'white' }}>
                    <button onClick={() => setShowCheckInModal(true)}>Check In Volunteers</button>

                    <h5>Checked In</h5>
                    {mappedCheckedIn}
                    {/* <div>
                        <h5>Checked In for different shift</h5>
                        {mappedCheckedIn}
                    </div> */}
                    <div className='col-12'>
                        <button onClick={() => setShowAddLineJudge(showAddLineJudge => !showAddLineJudge)}>Add Line Judge</button>
                    </div>
                    <button
                        style={{ marginTop: '30px', background: '#EA607B' }}
                        onClick={() => setVerifyCheckoutAll(true)}>Check-Out All Line Judges</button>
                </div>
                <div className='col-12 col-sm-6 col-md-8'>

                    {mappedGroups}


                    {showGroupModal ?
                        <div className='add-group'>
                            {showEditGroup ?
                                <h5>Edit group and select a color (color is optional).</h5> :
                                <h5>To make a new group type the group name and select a color (color is optional).</h5>}
                            <div style={{ display: 'flex' }}>

                                <input type="text"
                                    value={newGroup}
                                    style={{ width: '200px', background: color }}
                                    placeholder='add group name'
                                    onChange={(e) => setNewGroup(e.target.value)} />

                                <CirclePicker
                                    onChange={handleColor}
                                    value={color}
                                    triangle='hide' />
                            </div>
                            {showEditGroup ?
                                <div>
                                    <button onClick={editGroupName}>Save Changes</button>
                                    <button onClick={handleCancelEditGroupName}>Cancel</button>
                                </div>
                                :
                                <div>
                                    <button onClick={saveGroupName}>Save Group</button>
                                    <button onClick={handleCancelEditGroupName}>Cancel</button>
                                </div>
                            }

                        </div> :
                        <button onClick={() => setShowGroupModal(showGroupModal => !showGroupModal)}>Add Group</button>}
                </div>
                <div className='col-12'>
                    <div className='tips'
                        style={{ marginTop: '30px' }}>
                        <button

                            onClick={() => setShowEditLineJudge(showEditLineJudge => !showEditLineJudge)}>{showEditLineJudge ? 'Hide Contact Info' : 'Show/ Edit Line Judge Contact Info'}</button>
                    </div>
                    {showEditLineJudge ?
                        <div>
                            <h3 className='tips'>Registered Line Judge List</h3>
                            <EditLineJudgeContact />
                        </div> : ''}
                </div>




            </div>

            <Modal open={showCheckInModal} center showCloseIcon={true}
                onClose={() => setShowCheckInModal(false)} closeOnEsc={true} >
                <LineCheckin
                    handleHideCheckInModal={handleHideCheckInModal}
                />
            </Modal>

            <Modal open={showAddLineJudge} center showCloseIcon={true}
                onClose={() => setShowAddLineJudge(showAddLineJudge => !showAddLineJudge)} closeOnEsc={true} closeIconSize={2}>
                <div>
                    <h4>Click on name to add volunteer.  If volunteer is not in the system add them at the bottom.</h4>
                    <LookupVolunteer
                        tid={tid}
                        addVolunteerToShift={addVolunteerToShift}
                    />
                    <div className='addLineJudge'>
                        <AddLineJudge
                            tid={tid}
                            addLineJudge={addLineJudge} />
                    </div>
                    <button onClick={() => setShowAddLineJudge(showAddLineJudge => !showAddLineJudge)}>Cancel</button>
                </div>
            </Modal>


            <Modal open={deleteGid} center showCloseIcon={true} onClose={() => setDeleteGid(0)} closeOnEsc={true} closeIconSize={2}>
                <div>
                    <h4>Are you sure you want to delete this group?</h4>
                    <p>Anyone assigned to this group will be listed without a group.</p>
                </div>
                <button onClick={deleteGroup}> Delete</button>
                <button onClick={() => setDeleteGid(null)}>cancel</button>
            </Modal>

            <Modal open={showFinishMatch} center showCloseIcon={true} onClose={() => handleCancelFinishMatch} closeOnEsc={true} closeIconSize={2}>
                <div>
                    <h4>Finish match on court {selectedCourt.court_num}</h4>
                </div>
                <button onClick={finishMatch}> Finish but keep judges in group</button>
                <button onClick={finishMatchAndGroup}> Finish and remove judges from group</button>
                <button onClick={() => handleCancelFinishMatch()}>cancel</button>

            </Modal>
            <Modal open={showSendText} center showCloseIcon={true} onClose={() => setShowSendText(showSendText => !showSendText)} closeOnEsc={true} closeIconSize={2}>
                <LineJudgeCommunication
                    textList={textList}
                    groupText={groupText}
                    setShowSendText={setShowSendText}
                />
                <button onClick={() => setShowSendText(showSendText => !showSendText)}>cancel</button>
            </Modal>
            <Modal open={verifyCheckoutAll} center showCloseIcon={true} onClose={() => setVerifyCheckoutAll(false)} closeOnEsc={true} closeIconSize={2}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'spaceBetween' }}>
                    <div>
                        <h4>Are you sure you want to check out all line judges?</h4>
                        <p>This will remove all line judged from assigned groups</p>
                    </div>
                    <div className='verify-modal-buttons'>
                        <button onClick={checkoutAllLineJudges}> Check-Out All</button>
                        <button onClick={() => setVerifyCheckoutAll(false)}>cancel</button>
                    </div>
                </div>
            </Modal>
            {/* <LineSocket getCourts={getCourts} getGroups={getGroups}
        getCheckedInLineJudges={getCheckedInLineJudges}/> */}
        </div>

    )
}

export default LineJudgeManager