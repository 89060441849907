import React, { Component } from 'react'
const Barcode = require('react-barcode');

class RefDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ref_id: '',
            first: '',
            last: '',
            phone: '',
            email: '',
            barcode: '',
            credentials: '',
            admin: false
        }
    }

    componentDidMount() {
        this.setState({
            ref_id: this.props.referee.ref_id,
            first: this.props.referee.first,
            last: this.props.referee.last,
            phone: this.props.referee.phone,
            email: this.props.referee.email,
            barcode: this.props.referee.barcode,
            credentials: this.props.referee.credentials,
            admin: this.props.referee.admin
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    handleChangeCredentials = async (e) => {
        await this.setState({
            [e.target.name]: e.target.value

        })
        this.save()
    }

    save = () => {
        this.props.editRef(this.state)
    }

    render() {

        return (
            <div style={{ margin: '10px', background: 'white', padding: '4px', display: 'flex' }}>


                <input type="text"
                    placeholder='first'
                    name='first'
                    value={this.state.first}
                    // onChange={this.handleChange}
                    onBlur={this.save} />


                <input type="text"
                    placeholder='last'
                    name='last'
                    value={this.state.last}
                    // onChange={this.handleChange}
                    onBlur={this.save} />


                {/* <input type="text"
                    placeholder='Phone Num- no spaces'
                    name='phone'
                    value={this.state.phone}
                    maxLength='10'
                    onChange={this.handleChange}
                    onBlur={this.save} />


                <input type="text"
                    placeholder='email'
                    name='email'
                    value={this.state.email}
                    onChange={this.handleChange}
                    onBlur={this.save} /> */}


                <input type="text"
                    placeholder='Barcode'
                    name='barcode'
                    value={this.state.barcode}
                    onChange={this.handleChange}
                    onBlur={this.save} />
                {this.state.barcode ?
                    <Barcode
                        format='CODE39'
                        height={30}
                        width={1}
                        value={this.state.barcode}
                    /> : ''}


                <div>
                    <legend>Credentials</legend>
                    <label>
                        <input type='checkbox'
                            name='credentials'
                            checked={this.state.credentials === 'Not Rated'}
                            value='Not Rated'
                            onClick={this.handleChangeCredentials}
                        /> Not Rated </label>
                    <label>
                        <input type='checkbox'
                            name='credentials'
                            checked={this.state.credentials === 'Level 1'}
                            value='Level 1'
                            onClick={this.handleChangeCredentials}
                        /> Level 1 </label>
                    <label>
                        <input type='checkbox'
                            name='credentials'
                            value='Level 2'
                            checked={this.state.credentials === 'Level 2'}
                            onClick={this.handleChangeCredentials}
                        /> Level 2 </label>
                    <label>
                        <input type='checkbox'
                            name='credentials'
                            checked={this.state.credentials === 'Certified'}
                            value='Certified'

                            onClick={this.handleChangeCredentials}
                        /> Certified </label>
                </div>

            </div >

        )
    }
}

export default RefDetails