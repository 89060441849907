import React, { useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'


const SubmitQuestion = props => {
    const [question, setQuestion] = useState('')
    const [submitted, setShowSubmitted] = useState(false)

    const ref_id = useSelector(state => state.ref_id);


    const submitQuestion = () => {
        axios.post(`/api/submitQuestion`, { ref_id, question }).then(
            res => {
                setQuestion('')
                setShowSubmitted(true)
            }
        )
    }

    return (
        <div className='container'>
            <h4>What is your question?</h4>
            <textarea type="text"
                value={question}
                style={{ padding: '0 20px' }}
                onChange={e => setQuestion(e.target.value)} />
            <button onClick={submitQuestion}>Submit</button>

            <div>
                {submitted ? 'Your question has been submitted.  You will receive an email with an answer.' : ''}
            </div>
        </div>
    )
}
export default SubmitQuestion