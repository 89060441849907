import React, { Component } from 'react';
import axios from 'axios'
import { connect } from "react-redux"
import io from 'socket.io-client'
import { Link } from 'react-router-dom';



import './DisplayNotification.css'
import DisplayNotificationsListDetail from './DisplayNotificationsListDetail';


class DisplayNotificationsList extends Component {
    constructor() {
        super()
        this.state = {
            list: [],
            response: ''
        }
    }

    componentDidMount() {
        this.getNotifications()
        this.setSocketListeners()
    }

    componentWillUnmount() {
        this.socket.disconnect()
    }

    setSocketListeners = () => {
        this.socket = io()

        this.socket.emit('joinRoom', `notification:${this.props.tid}`)

        this.socket.on(`notification`, results => {
            this.getNotifications()
        })
    }

    

    getNotifications = () => {
        axios.get(`/api/getNotifications/${this.props.tid}`)
            .then(res => {
            this.setState({ list: res.data })
        })
    }


    completeNotification = (nid, response) => {
        axios.put(`/api/confirmNotification`, { nid, response }).then(res => {
            this.getNotifications()

        })
    }


    render() {

        return (
            <div className='container'>
                <h2>Notification List</h2>
                <button onClick={this.getNotifications}>Refresh </button>
                <><small>Page should automatically refresh</small></>

                <Link to={`/desk/pastNotifications`} target='_blank' rel="noopener noreferrer" >
                                <button>Past Notifications</button>
                            </Link>

                <div className='row'>
                    <div className='col-12'>

                    
                { this.state.list.map((note, i)=>(
                    <DisplayNotificationsListDetail
                        key={i}
                        note={note}
                        completeNotification={this.completeNotification}/>
                ))}
                </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name
    }
}
export default connect(
    mapStateToProps,
)(DisplayNotificationsList)
