import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import AddVolunteerFromSystem from '../AddVolunteerFromSystem'
import AddVolunteer from '../AddVolunteer'
import VolunteerList from './VolunteerList'
import MappedShift from './MappedShift'
import VcidScheduleRecap from './VcidScheduleRecap';

const CategoryVolunteerScheduling = props => {
    const [shift, setShift] = useState([])
    const [volunteer, setVolunteer] = useState({})
    const [waitlist, setWaitlist] = useState(false)
    const [captain, setCaptain] = useState(false)
    const [searchFirst, setSearchFirst] = useState('')
    const [searchLast, setSearchLast] = useState('')
    const [searchName, setSearchName] = useState('')
    const [searchVolunteerFirst, setSearchVolunteerFirst] = useState('')
    const [searchVolunteerLast, setSearchVolunteerLast] = useState('')
    const [searchDate, setSearchDate] = useState('')
    const [categoryFilter, setCategoryFilter] = useState('')
    const [showAddVolunteer, setShowAddVolunteer] = useState(false)
    const [showByName, setShowByName] = useState(false)
    const [waitFilter, setWaitFilter] = useState(0)
    const [openSpotsFilter, setOpenSpotsFilter] = useState(0)

    const tid = useSelector(state => state.tid)
    const admin = useSelector(state => state.ref_id)

    let {vcid, data, volunteers, getShiftSpots, getRegisteredVolunteers} = props

    useEffect(
        () => { checkForShiftAndVolunteer() }, [shift])
    useEffect(
        () => { checkForShiftAndVolunteer() }, [volunteer])

    const assignCaptain = async () => {
        if(volunteer.ref_id && shift.sid){
        axios.post(`/api/putCaptainInShift/${shift.sid}`, { tid: tid, ref_id: volunteer.ref_id }).then(
            res => {
                getShiftSpots()
                setVolunteer({})
                setShift({})
                setCaptain(false)
            }
        ).catch(error => {
            if (error.response) {
                toast.warning(error.response.data)
                setVolunteer({})
                setShift({})
                setCaptain(false)

            }
        })
    }
    }

    const assignVolunteer = async () => {
        axios.put(`/api/putVolunteerInShift/${shift.sid}`, { tid: tid, ref_id: volunteer.ref_id, waitlist: waitlist }).then(
            res => {

                if(res.data.conflict){
                    toast.error(`CONFLICT - ${res.data.conflict.name} ${res.data.conflict.start} - ${res.data.conflict.end}`)
                }
                else if (res.data.length > 0){
                    toast.error(res.data )
                }
                else {
                getShiftSpots()
                setVolunteer({})
                setShift({})
                toast.success('email sent')
                }
            }
        ).catch(error => {
            if (error.response) {
                toast.warning('System Error')
                setVolunteer({})
                setShift({})

            }
        })
    }

    const addWaitList = async () => {
        axios.put(`/api/putVolunteerInShift/${shift.sid}`, { tid, ref_id: volunteer.ref_id, waitlist: true }).then(
            res => {
                    if(res.data.conflict){
                        toast.error(`CONFLICT - ${res.data.conflict.name} ${res.data.conflict.start} - ${res.data.conflict.end}`)
                }else {
                getShiftSpots()
                setVolunteer({})
                setShift({})
                toast.success('email sent')
                }
            }
        ).catch(error => {
            if (error.response) {
                toast.warning('System Error')
                setVolunteer({})
                setShift({})
                setWaitlist(false)
            }
        });

    }

    const addVolunteerToDBandTournament = (refInfo) => {
        if(refInfo.email.length < 4){return toast.warning('email required')}
        axios.post(`/api/addVolunteerToDBandTournament/${tid}`, { refInfo }).then(
            res => {
                getRegisteredVolunteers()
                setShowAddVolunteer(false)
            }
        )
    }

    const addVolunteerToTournament = (ref_id) => {
        axios.post(`/api/addVolunteerToTournament/${tid}`, { ref_id }).then(
            res => {
                getRegisteredVolunteers()
                setShowAddVolunteer(false)
            }
        )
    }

    const moveOffWaitlist = (shift_id) => {
        axios.put(`/api/moveOffWaitlist/${shift_id}`, { tid }).then(
            res => {
                getShiftSpots()
                toast.success('email sent')
            }
        )
    }

    const moveToWaitlist = (shift_id) => {
        axios.put(`/api/moveToWaitlist/${shift_id}`, { tid }).then(
            res => {
                getShiftSpots()
                toast.success('email sent')
            }
        )
    }

    const handleSetShift = async (s, bol) => {
        if(shift.sid === s.sid){return setShift({})}
        setShift(s)
        setWaitlist(bol)
    }
    const checkForShiftAndVolunteer = () => {
        if (shift.sid && volunteer.ref_id && waitlist === false && !captain) {
            assignVolunteer()
        }
        else if (shift.sid && volunteer.ref_id && waitlist === true && !captain) {
            addWaitList()
        }
        else if (shift.sid && volunteer.ref_id && waitlist === false && captain) {
            assignCaptain()
        }
    }


    const handleSelectVolunteer = async (v) => {
        if(volunteer.ref_id){return setVolunteer({})}
        setVolunteer(v)
    }

    const clearSearch = () => {
        setSearchFirst('')
        setSearchLast('')
        setSearchName('')
        setSearchDate('')
        setWaitFilter(0)
    }
    const clearSearchVolunteer = () => {
        setSearchVolunteerFirst('')
        setSearchVolunteerLast('')
    }

    const handleShowAddVolunteer = () => setShowAddVolunteer(showAddVolunteer => !showAddVolunteer )

    const handleSearchName = (row) => {
        if(searchName === row.name && searchDate === row.start_time){
            setSearchDate('')
            setSearchName('')
        }else {
            setSearchDate(row.start_time)
            setSearchName(row.name)
        }
    }


    const handleAddCaptain = (shift) => {
        if(captain){
            setShift({})
            setCaptain(false)
        } else {
        setShift(shift)
        setCaptain(true)
        }
        }
        const mappedShifts = data
        .filter( obj => obj.name.toUpperCase().includes(searchName.toUpperCase()))
        .filter(obj => obj.start_time ? obj.start_time.toUpperCase().includes(searchDate.toUpperCase()):'')
        .filter(obj =>  Math.max(0, +obj.count -obj.spots)  >= openSpotsFilter)
        .filter(obj => Math.max(0, +obj.wait_count) >= waitFilter)

            .map((s, i) => 
            <MappedShift 
                key={'s'+i}
                shift={s}
                selected={shift}
                searchFirst={searchFirst}
                searchLast={searchLast}
                searchName={searchName}
                setDate={searchDate}
                get={getShiftSpots}
                handleAddCaptain={handleAddCaptain}
                setShift={handleSetShift}
                moveOffWaitlist={moveOffWaitlist}
                moveToWaitlist={moveToWaitlist}/>
            )

        const mappedVolunteers = volunteers
            .filter(obj => obj.first ? obj.first.toUpperCase().includes(searchVolunteerFirst.toUpperCase()):'')
            .filter(obj => obj.last ? obj.last.toUpperCase().includes(searchVolunteerLast.toUpperCase()):'')
            .map((v, i) => 
            <VolunteerList 
                key={'v'+i}
                volunteer={v}
                selected={volunteer}
                setVolunteer={handleSelectVolunteer}/>)
        return (
            <div className='AddRefToSchedule container' >
<VcidScheduleRecap vcid={props.vcid} select={handleSearchName} tid={tid}/>
                <Link to={`/volunteercaptain/scheduling`} >
                    <button className='login-button'>Schedule Setup</button>
                </Link>
                <Link to={`/volunteer/volunteerSchedule`} >
                    <button className='register-button'>Schedule By Name</button>
                </Link>
                <Link to={`/volunteer/schedulelog`} >
                    <button className='register-button'>Schedule Log (signup/ removed times)</button>
                </Link>



                <div className='row'>
                    <div className='col-12 col-md-8 '>
                        <div className='search-menu'>
                            <form>

                            <legend> Search by date/ start time
                            <input
                                        type="text"
                                        placeholder='Date'
                                        name="searchDate"
                                        value={searchDate}
                                        onChange={e=>setSearchDate(e.target.value)} />
                                </legend>
                                <legend> Search by shift
                            <input
                                        type="text"
                                        placeholder='Shift Name'
                                        name="searchName"
                                        value={searchName}
                                        onChange={e=>setSearchName(e.target.value)} />
                                </legend>
                                <legend>
                                    Search by volunteer name:

                                    <input
                                        type="text"
                                        placeholder='Search Name'
                                        name="searchFirst"
                                        value={searchFirst}
                                        onChange={e=>setSearchFirst(e.target.value)} />

                                </legend>
                                <legend>
                                    Open Spots >=

                                    <input
                                        type="text"
                                        placeholder='greater or equal than'
                                        name="openSpotsFilter"
                                        value={openSpotsFilter}
                                        onChange={e=>setOpenSpotsFilter(e.target.value)} />

                                </legend>
                                <legend>
                                    Waitlist >=

                                    <input
                                        type="text"
                                        placeholder='greater or equal than'
                                        name="waitFilter"
                                        value={waitFilter}
                                        onChange={e=>setWaitFilter(e.target.value)} />

                                </legend>
                                <i className="fas fa-ban" onClick={clearSearch}> clear</i>
                            </form>
                            
                        </div>
                        <i className="fas fa-arrow-right small-button"></i> = "move to waitlist"
                        <i className="fas fa-arrow-left small-button"></i> = "move to off waitlist"

                        <div className='mappedSection'>
                            {mappedShifts}
                        </div>
                    </div>
                    <div className='col-12 col-md-4 '>

                        <div className=''>
                            <input
                                type="text"
                                placeholder='Search by Last Name'
                                name="searchVolunteerLast"
                                value={searchVolunteerLast}
                                onChange={e=>setSearchVolunteerLast(e.target.value)} />
                            <input
                                type="text"
                                placeholder='Search by First Name'
                                name="searchVolunteerFirst"
                                value={searchVolunteerFirst}
                                onChange={e=>setSearchVolunteerFirst(e.target.value)} />
                            <i className="fas fa-ban" onClick={clearSearchVolunteer}></i>
                           
                        </div>
                        <h4>Volunteers Registered for Tournament</h4>
                        <button onClick={handleShowAddVolunteer}>Add Volunteer</button>

                        <div className='mappedSection'>
                            {mappedVolunteers}
                            <button onClick={handleShowAddVolunteer}>Add Volunteer</button>
                        </div>
                    </div>
                </div>
                <Modal open={showAddVolunteer} center showCloseIcon={true}
                    onClose={handleShowAddVolunteer} closeOnEsc={true} closeIconSize={2}>
                    <div>
                        <h4>Click on name to add volunteer.  If volunteer is not in the system add them at the bottom.</h4>
                        <AddVolunteerFromSystem
                            addRefToTournament={addVolunteerToTournament}
                        />
                        <div className='addLineJudge'>
                            <AddVolunteer
                                tid={tid}
                                addVolunteer={addVolunteerToDBandTournament} />
                        </div>
                        <button onClick={handleShowAddVolunteer}>Cancel</button>
                    </div>
                </Modal>
            </div>

        )
    }

export default CategoryVolunteerScheduling
