import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper'

const MatchTableList = props => {
    

    return(
              <TableContainer component={Paper}>
      <Table  size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
          <TableCell></TableCell>
            <TableCell>Court Num</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Credentials</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Match Type</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Paid</TableCell>            
          </TableRow>
        </TableHead>
        <TableBody>
        
          {props.matches
            .filter(obj => obj.start_time !== null && obj.start_time.includes(props.filter))
            .map((row, i) => (
                <TableRow key={row.id}>
                  <TableCell>{i+1}.</TableCell>
                  <TableCell>{row.court_num}</TableCell>
                  <TableCell>{row.type || props.type}</TableCell>
                  <TableCell>{row.ref_cred}</TableCell>
                  <TableCell>{row.start_time}</TableCell>
                  <TableCell>{row.match_time}</TableCell>
                  <TableCell>{row.medal}</TableCell>
                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.paid ? 'paid':'not paid'}</TableCell>            
                </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    )
}
export default MatchTableList