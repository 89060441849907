import React, { Component } from 'react'
import axios from 'axios'
import io from 'socket.io-client'
import { connect } from "react-redux"
import Moment from 'react-moment';
import 'moment-timezone';
import '../BoardView.css'
import ClipboardNeeded from '../ClipboardNeeded'
import ShiftCheckedIn from '../BoardView/ShiftCheckedIn'
import RefereeMagnet from '../BoardView/RefereeMagnet'
import BoardDisplayMagnet from '../BoardView/BoardDisplayMagnet'




class BoardDisplay extends Component {
    constructor() {
        super()
        this.state = {
            courts: [],
            refList: [],
            clipboardNeeded: [],
            sideQueue: [],
        }
    }

    componentDidMount() {
        this.getCourts()
        this.getAvailableRefs()
        this.getClipboardNeeded()
        this.getSideQueue()
        this.setSocketListeners()
        this.interval = setInterval(() => {
            this.getAll()
        }, 15 * 60 * 1000);
    }

    componentWillUnmount() {
        this.socket.disconnect()
        clearInterval(this.interval)
    }

    setSocketListeners = () => {
        this.socket = io()

        this.socket.emit('joinRoom', `board:${this.props.tid}`)

        this.socket.on('board', results => {
            if (results.msg === 'assign') {
                let c = [...this.state.courts]
                let index = c.map(e => e.court_num).indexOf(results.court.court_num)
                c[index] = results.court
                this.setState({courts: c})

                let q = [...this.state.refList]
                let qi = q.map(e => e.ref_id).indexOf(results.court.ref_id)
                if(qi >= 0){
                q.splice(qi, 1)
                this.setState({refList: q})
                }

                let s = [...this.state.sideQueue]
                let si = s.map(e => e.ref_id).indexOf(results.court.ref_id)
                if(si >= 0){
                s.splice(si, 1)
                this.setState({sideQueue: s})
                }
            }
            else if (results.msg === 'finish') {
                let c = [...this.state.courts]
                let index = c.map(e => e.court_num).indexOf(results.court.court_num)
                c[index] = results.court
                this.setState({courts: c})

                let q = [...this.state.refList]
                q = q.concat(results.referee)
                this.setState({refList: q})
            }
            else if (results.msg === 'match starter') {
                let q = [...this.state.refList]
                let qi = q.map(e => e.ref_id).indexOf(results.ref_id)
                if(qi >= 0){
                q[qi].note = results.note
                this.setState({refList: q})
                }else {
                    let aux = [...this.state.sideQueue]
                let auxI = aux.map(e => e.ref_id).indexOf(results.ref_id)
                if(auxI >= 0){
                aux[auxI].note = results.note
                this.setState({sideQueue: q})

                }

            }
        }
            else if (results.msg === 'swapQueue') {
                this.getAvailableRefs()
                this.getSideQueue()
            }
            else if (results.msg === 'getCourtsOnly') {
                this.getCourts()
            }
            else {
                this.getAll()
            }

        })
    }

    getAll = () => {
        this.getCourts()
        this.getAvailableRefs()
        this.getClipboardNeeded()
        this.getSideQueue()
    }

    getCourts = async () => {

        await axios.get(`/api/getCourtsForPublicDisplay/${this.props.match.params.tid}`).then(

            res => {
                this.setState({ courts: res.data })
            }
        )
    }

    getAvailableRefs = () => {
        axios.get(`/api/getAvailableRefs/${this.props.match.params.tid}`).then(

            res => {
                this.setState({ refList: res.data })
            }
        )
    }

    getClipboardNeeded = async () => {

        await axios.get(`/api/getClipboardNeeded/${this.props.match.params.tid}`).then(

            res => {
                this.setState({ clipboardNeeded: res.data })
            }
        )
    }

    getSideQueue = () => {
        axios.get(`/api/getSideQueue/${this.props.match.params.tid}`).then(

            res => {
                this.setState({ sideQueue: res.data })
            }
        )
    }

    render() {
        const mappedCourts = this.state.courts.map(court => {

            return (
                <div key={court.court_num} className='col-3 court-box-boardView'
                >
                    <div className='col-4 court-num'>
                        <p>{court.court_num}</p>
                        {court.medal === 'Bronze' ?
                            <div className='board-medal'>
                                <i className="fas fa-medal"></i>
                                B
                                </div> :
                            court.medal === 'Gold' ?
                                <div className='board-medal'>
                                    <i className="fas fa-medal"></i>
                                    G
                                </div> : ''}
                    </div>
                    <div className={
                        court.credentials_verified === 'Certified' ? 'certified-board col-8 ref-name' : court.credentials_verified === 'Level 2' ? 'level2-board col-8 ref-name' : court.credentials_verified === 'Level 1' ? 'level1-board col-8 ref-name' :
                            'col-8 ref-name'}>
                        <div className={court.last_match ? 'last_match' : ''}>
                            {court.last_match ? <span className='last_match_icon'>LM</span> : ''}
                            {court.last_match ? <p >{court.first} {court.last}</p> :
                                <span>{court.first} {court.last}
                                    {court.second ? ` / ${court.second}` : ''}
                                </span>}
                        </div>
                    </div>
                </div>
            )
        })

        const mappedRefs = this.state.refList.map((ref, i) => {

            return (
                <BoardDisplayMagnet
                    key={ref.ref_id}
                    referee={ref}
                    zone={this.props.zone} />
            )
        })

        const mappedSideQueue = this.state.sideQueue.map((ref, i) => {

            return (
                <BoardDisplayMagnet
                    key={ref.ref_id}
                    referee={ref}
                    zone={this.props.zone} />
            )
        })
        return (
            <div className='container-fluid'>
                <h3 className='tips'>{this.props.name}</h3>
                <div className='col-12' style={{ lineHeight: '.5' }}>
                    <p>Queue order is an estimate for match assignments.</p>
                    <p>Actual assignment is at the discretion of the referee coordinator or based on match type.</p>

                </div>

                <div className='row'>
                    <div className='col-12 board' >

                        <div className='col-8 court-list'>
                            {mappedCourts}
                        </div>
                        <div className='col-2 '>
                            <h5 className='queue-name'>Queue ({this.state.refList.length})</h5>
                            <div className=''>
                                {mappedRefs}

                            </div>
                        </div>
                        <div className='col-2'>
                            <h5 className='queue-name'>Aux Queue ({this.state.sideQueue.length})</h5>
                            <div className=''>
                                {mappedSideQueue}
                            </div>
                            {/* <div>
                                <div className='queue-name' style={{ background: 'white' }}>OnDeck/ Referees who have checked-In</div>
                                <ShiftCheckedIn
                                    updateBoard={this.updateBoard}
                                    hideButton={this.state.hideButton}
                                    turnOffOnClick={true}

                                />
                            </div> */}
                        </div>

                    </div>



                </div>
                {this.state.clipboardNeeded.length > 0 ?
                    <div className='row'>
                        <div className='col-6'>
                            {this.state.clipboardNeeded.length > 0 ?
                                <ClipboardNeeded
                                    clipboardNeeded={this.state.clipboardNeeded}
                                    clipboardReceived={this.clipboardReceived} />
                                : ''}
                        </div>
                    </div> : ''}
                    <div>
                                Guide 
                                <div><span className='last_match_icon'>LM</span> = Last Match For Referee</div>
                                <div>Wait = Wait to Assign (Quick Break)</div>
                                
                            </div>

            </div>
        )
    }
}
const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name,
        zone: reduxState.zone
    }
}


export default connect(mapStateToProps)(BoardDisplay)