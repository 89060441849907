import React, {useState} from 'react'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';

export default function BingoBox(props){
    const [loader, setLoader] = useState(false)
    let {name, item, completed, cid} = props

    const done = () => {
        setLoader(true)
        axios.put(`/api/bingo/completeItem/`, {cid, item, item_done: completed}).then(
            res => {
                props.get()
                setLoader(false)
            }
        )
    }
    return(
        <div className={completed ? 'bingo-square-done':'bingo-square'} onClick={done}>
            {loader ? <CircularProgress />:name}
            </div>
    )
}