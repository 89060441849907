import React from 'react'

const HowTo = () => {
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-12 col-md-4 shadow mb-10 bg-white rounded'>
                    <div className='admin-image-box'>
                        <img className='admin-images'
                            src={'https://res.cloudinary.com/shonda/image/upload/v1582498963/PickleballDesk/how-to_k1zl8t.png'} alt='how-to' />
                    </div>
                </div>
                <div className='col-12 col-md-4 shadow mb-10 bg-white rounded'>
                    <h4>How-to PDF</h4>
                    <div style={{ textAlign: 'left' }}>
                    <a target="_blank" rel="noopener noreferrer"
                            href="https://drive.google.com/file/d/1wt7y70MK1VRW4Rvy3rFDjnY6bWKpNEmP/view?usp=sharing">
                            <p>Referee Coordinator - Day Off</p>
                        </a>
                        <a target="_blank" rel="noopener noreferrer"
                            href="https://drive.google.com/file/d/1b-8nZCxAnr_RoXR9WLK00pMHwRDDEpok/view?usp=sharing">
                            <p>Volunteer Coordinator - Shift Management</p>
                        </a>
                    </div>
                    <h4>How-to videos</h4>
                    <span>Learn everything in 25 min</span>
                    <p>*We are working on updating how-to videos.  Please note we have updated some website design since making the videos.  Functionality is the same but the menus might look different.</p>
                    <div style={{ textAlign: 'left' }}>
                        <a target="_blank" rel="noopener noreferrer"
                            href="https://drive.google.com/open?id=1p_5ZugLBYX9FApF9kmFm9F4wHJ-WYrWk">
                            <p>Admin Overview</p>
                        </a>
                        <a target="_blank" rel="noopener noreferrer"
                            href="https://drive.google.com/open?id=1WICJaHMSh1zZZGSv2L2unxw_U2F0juzy">
                            <p>Scheduling and Setup</p></a>
                        <a target="_blank" rel="noopener noreferrer"
                            href="https://drive.google.com/open?id=1d_jIeaRfPoKwH9UjiN_2Gw5xN0KM1Y0v">
                            <p>Board View/ Day Of</p></a>
                        <a target="_blank" rel="noopener noreferrer"
                            href="https://drive.google.com/open?id=1w41UEMniXAQwTqXjqKtjG4z0Bk5Hm8dy">
                            <p>Self Checkin</p></a>

                        {/* <a target="_blank" rel="noopener noreferrer"
                            href="https://vimeo.com/394712295">
                            <p>Referee Payments/ Invoicing</p></a> */}
                        <a target="_blank" rel="noopener noreferrer"
                            href="https://vimeo.com/user109153135">
                            <p>Registration Guide</p></a>
                        <a target="_blank" rel="noopener noreferrer"
                            href="https://vimeo.com/user109153135">
                            <p>Finish Match - Referee</p></a>
                            <a target="_blank" rel="noopener noreferrer"
                            href="https://vimeo.com/user109153135/">
                            <p>Contactless Score Reporting</p></a>
                        {/* <a target="_blank" rel="noopener noreferrer"
                            href="https://vimeo.com/394712295">
                            <p>Referee Payments/ Invoicing</p></a> */}
                        <a target="_blank" rel="noopener noreferrer"
                            href="https://vimeo.com/user109153135">
                            <p>Contactless Score Reporting- Introduction</p></a>
                        <a target="_blank" rel="noopener noreferrer"
                            href="https://vimeo.com/user109153135">
                            <p>Contactless Score Reporting</p></a>

                            

                    </div>
                </div>
                <div className='col-12 col-md-4 shadow mb-10 bg-white rounded'>
                    <div className='setup-list'>
                        <h4>Downloads</h4>
                        <a target="_blank" rel="noopener noreferrer"
                            href="https://www.dropbox.com/s/v9wpi1dw3vt99cv/pickleball%20desk%20how%20to.pdf?dl=0">
                            <p>Day Of Guide for Referee- PDF</p>
                            <small>Print this and have available at desk.</small></a>
                        <span>TIPS: In case of internet failure: 1. use your phone to operate the board. If that also does not work have this spreadsheet on your computer as a temporary backup.</span>
                        <a target="_blank" rel="noopener noreferrer"
                            href="https://www.dropbox.com/s/sl03aokori85cxn/PickleballDesk%20Backup%20Spreadsheet.xlsx?dl=0">
                            <p>Internet failure?  Backup spreadsheet</p></a>
                    </div>
                </div>

            </div>


        </div>

    )
}
export default HowTo