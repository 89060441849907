import React, { Component } from 'react'
import { connect } from "react-redux"
import { updateTournament } from '../../ducks/reducer'
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import axios from 'axios'
import TournamentTimeZone from '../Admin/TournamentTimeZone'

class TournamentSetup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            location: '',
            num_of_courts: '',
            zone: '',
            time_zone:'',
            usapa: false,
            about: '',
            description: '',
            line_description: '',
            volunteer_description: '',
            start: null,
            end: null,
            approveReferee: false,
            referee_coordinator_email: '',
            certified: 0,
            level2: 0,
            level1: 0,
            notRated: 0,
            certified2: 0,
            level22: 0,
            level12: 0,
            notRated2: 0,
            pr1Amount:0, 
            pr2Amount:0,
            line_cord_text: '',
            referee_coordinator_number: '',
            referee_coordinator_name: '',
            head_referee: '',
            head_referee_phone: '',
            line_coordinator_number: '',
            line_coordinator_name: '',
            volunteer_coordinator_number: '',
            volunteer_coordinator_name: '',
            volunteer_waiver: '',
            volunteer_signature_required: false,
            medical_number: '',
            postPaid: false,
            zeroWarning: false,
            private_tournament: false,
            facility_map: '',
            pickleballCanada: false,
            saveTag: false,
            plannedOpen: null

        }
    }


    componentDidMount() {
        this.getTournament()
    }

    getTournament = () => {
        axios.post(`/api/t/getTournamentAdmin/${this.props.tid}`).then(
            res => {
                const { name, location, num_of_courts, zone, time_zone, usapa, start_text, end_date_text, about, description, line_description, volunteer_description, referee_approval_required, certified_amount, level_two_amount, level_one_amount, volunteer_amount, 
                    certified_amount2, level_two_amount2, level_one_amount2, volunteer_amount2,
                    line_cord_text, referee_coordinator_number, referee_coordinator_name, referee_coordinator_email, head_referee, head_referee_phone, line_coordinator_name, line_coordinator_email, volunteer_coordinator_name, volunteer_coordinator_email, volunteer_waiver, volunteer_signature_required, medical_number, referee_post_pay, zero_payout_warning, private_tournament, facility_map, pickleball_canada, pr1_amount, pr2_amount, save_tag, planned_opening_date } = res.data

                this.setState({
                    name, location, num_of_courts, zone, time_zone, usapa, about, start: start_text, end: end_date_text, description, line_description, volunteer_description, referee_approval_required, referee_coordinator_email: referee_coordinator_email || 0, certified: certified_amount || 0,
                    level2: level_two_amount || 0, level1: level_one_amount || 0, notRated: volunteer_amount || 0, 
                    certified2: certified_amount2 || 0,
                    level22: level_two_amount2 || 0, level12: level_one_amount2 || 0, notRated2: volunteer_amount2 || 0,line_cord_text, referee_coordinator_number, referee_coordinator_name, head_referee, head_referee_phone,line_coordinator_name, line_coordinator_email, volunteer_coordinator_name, volunteer_coordinator_email, volunteer_waiver, volunteer_signature_required, medical_number,
                    postPaid: referee_post_pay,
                    zeroWarning: zero_payout_warning,
                    private_tournament, facility_map, pickleballCanada: pickleball_canada, 
                    pr1Amount: pr1_amount, pr2Amount: pr2_amount,
                    saveTag: save_tag,
                    plannedOpen: planned_opening_date
                })
            }
        )
    }

    save = () => {
        if (this.state.name.length < 3) { return }
        if (!this.props.tid) { return }
        else {
            const { name, location, num_of_courts, zone, time_zone, usapa, start, end, about, description, referee_approval_required, certified, level1, level2, notRated, 
                certified2, level12, level22, notRated2,
                line_cord_text, line_description, volunteer_description, referee_coordinator_number, referee_coordinator_name, referee_coordinator_email, head_referee, head_referee_phone,line_coordinator_name, line_coordinator_email, volunteer_coordinator_name, volunteer_coordinator_email, volunteer_waiver, volunteer_signature_required,medical_number, postPaid, zeroWarning, private_tournament, facility_map, pickleballCanada, pr1Amount, pr2Amount, saveTag, plannedOpen } = this.state
            axios.put(`/api/t/editTournament/${this.props.tid}`, { tid:this.props.tid, name, location, num_of_courts, zone, time_zone, usapa, start, end, about, description, referee_approval_required, referee_coordinator_email: referee_coordinator_email || 0, certified, level1, level2, notRated, 
                certified2, level12, level22, notRated2,
                line_cord_text, line_description, volunteer_description, referee_coordinator_number, referee_coordinator_name, head_referee, head_referee_phone,line_coordinator_name, line_coordinator_email, volunteer_coordinator_name, volunteer_coordinator_email, volunteer_waiver, volunteer_signature_required,medical_number, postPaid, zeroWarning, private_tournament, facility_map, pickleballCanada, pr1Amount, pr2Amount, ref_id: this.props.ref_id, saveTag, plannedOpen }).then(
                res => {
                    toast.success(`Tournament Updated`)
                    if(this.props.ref_id > 1){
                        this.props.updateTournament(res.data)
                    }
                    
                }
            )
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleStartDate = (date) => {
        this.setState({ start: date });
    }

    handleEndDate = (date) => {
        this.setState({ end: date });
    }

    handleOpenDate = (date) => {
        this.setState({ plannedOpen: date });
    }

    handleEnd = end => this.setState({ end })

    handleDescription = (value) => {
        this.setState({ description: value })
    }
    handleLineDescription = (value) => {
        this.setState({ line_description: value })
    }
    handleVolunteerDescription = (value) => {
        this.setState({ volunteer_description: value })
    }
    handleVolunteerWaiverRequired = () => {
        this.setState({ volunteer_signature_required: !this.state.volunteer_signature_required })
    }
    handleVolunteerWaiver = (value) => {
        this.setState({ volunteer_waiver: value })
    }
    handlePrivate= () => {
        this.setState({ private_tournament: !this.state.private_tournament })
    }

    handlePhone = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            this.setState({ [e.target.name]: onlyNums });
        } else if (onlyNums.length === 10) { this.setState({ [e.target.name]: onlyNums }) }
    }
    handleUsapa = () => this.setState({usapa: !this.state.usapa})
    handlePickleballCanada = () => this.setState({pickleballCanada: !this.state.pickleballCanada})

    handlePostPaid = () => this.setState({postPaid: !this.state.postPaid})
    handleZeroWarning= () => this.setState({zeroWarning: !this.state.zeroWarning})

    handleSaveTag= () => this.setState({saveTag: !this.state.saveTag})

    render() {
        let {admin_type, referee, line, volunteer} = this.props

        return (
            <div className='container' style={{ textAlign: 'left' }}>
                {admin_type === 'master' &&
                <>
                <h3>Tournament Setup</h3>
                <legend>Tournament Name
                <input type="text"
                        name='name'
                        style={{ width: '300px' }}
                        value={this.state.name}
                        onChange={this.handleChange}
                    />
                </legend>

                <legend>Location
                <input type="text"
                        name='location'
                        style={{ width: '300px' }}
                        value={this.state.location}
                        onChange={this.handleChange}
                    />
                </legend>
                <TournamentTimeZone zone={this.state.time_zone}/>
                <legend>
                <input type="checkbox"
                        name='private_tournament'
                        checked={this.state.private_tournament}
                        onChange={this.handlePrivate}
                    />
                    Private tournament?  If checked, tournament will not show in tournament list. 
                </legend>
                <legend>
                <input type="checkbox"
                        name='usapa'
                        checked={this.state.usapa}
                        onChange={this.handleUsapa}
                    />
                    USA Pickleball Sanctioned?
                </legend>
                <legend>
                <input type="checkbox"
                        name='pickleballCanada'
                        checked={this.state.pickleballCanada}
                        onChange={this.handlePickleballCanada}
                    />
                    Pickleball Canada Sanctioned?
                </legend>
                
                

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="flex">
                        <KeyboardDatePicker
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            label="Start Date"
                            format="MM/dd/yyyy"
                            value={this.state.start}
                            onChange={this.handleStartDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        <KeyboardDatePicker
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            label="End Date"
                            format="MM/dd/yyyy"
                            value={this.state.end}
                            onChange={this.handleEndDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />

                    </Grid>
                </MuiPickersUtilsProvider>
                </>}

                <div>
                    Planned Opening Date:
                    

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="flex">
                        <KeyboardDatePicker
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            label="Planned Opening Date"
                            format="MM/dd/yyyy"
                            value={this.state.plannedOpen}
                            onChange={this.handleOpenDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        

                    </Grid>
                </MuiPickersUtilsProvider>
                <p>We send a weekly email to let people know tournaments that have recently opened or will be opening in the next 7 days.  By putting a planned opening date your tournament will be included in that email. Private tournaments will not be included on the list. </p>
                </div>




                {/* <legend>About - Shown on Listing Page (140 character limit)
                <textarea type="text"
                        name='about'
                        maxLength='140'
                        value={this.state.about}
                        onChange={this.handleChange}
                    /> */}
                {/* </legend> */}
                {admin_type === 'master' || referee ?
               <>
               <hr/>
                <h3>Referee Setup</h3>
                <legend>
                <input type="checkbox"
                        name='postPaid'
                        checked={this.state.postPaid}
                        onChange={this.handlePostPaid}
                    />
                    Referees are paid at the end of tournament.  (If not checked each match will default to paid when assigned.)
                </legend>
                <legend>
                <input type="checkbox"
                        name='zeroWarning'
                        checked={this.state.zeroWarning}
                        onChange={this.handleZeroWarning}
                    />
                    Add warning if referee payout is 0 when finishing a match.  If you are paying different amounts based on match type you can set all referee payments amount to 0 in the setup.  This will force you to put in the correct amount for each match.   
                </legend>
                <legend>
                <input type="checkbox"
                        name='saveTag'
                        checked={this.state.saveTag}
                        onChange={this.handleSaveTag}
                    />
                    Save tag(s) with match data.  Will be saved in SYSID/ note section.
                </legend>
                
                <legend>Description for Referee Signup Page
                <ReactQuill
                        className='description'
                        style={{ background: 'white' }}

                        value={this.state.description || ''}
                        onChange={this.handleDescription} />
                </legend>
                <div>
                    Does your tournament require referee approval before signing up?
                    <label>
                        <input type='radio'
                            checked={this.state.referee_approval_required === true}
                            name='referee_approval_required'
                            value='Line Judge'
                            onChange={() => this.setState({ referee_approval_required: true })}
                        /> Yes </label>
                    <label>
                        <input type='radio'
                            checked={this.state.referee_approval_required === false}
                            name='referee_approval_required'
                            value='Line Judge'
                            onChange={() => this.setState({ referee_approval_required: false })}
                        /> No </label>

                    <legend>
                        <strong>Referee coordinator name </strong>Name will be listed on the referee signup page.
                    <input type="text"
                            name='referee_coordinator_name'
                            placeholder='name'
                            maxLength={250}
                            value={this.state.referee_coordinator_name}
                            onChange={this.handleChange}
                        />
                    </legend>
                    <legend>
                        <strong>Referee coordinator email </strong>
                        <input type="email"
                            name='referee_coordinator_email'
                            placeholder='email'
                            maxLength={100}
                            value={this.state.referee_coordinator_email}
                            onChange={this.handleChange}
                        />
                    </legend>
                    <legend>
                        <strong>Referee coordinator phone number </strong>for notifications during the tournament.  This includes referee needs including: medical, second call, tournament director needed, comments to the desk.
                    <input type="number"
                            name='referee_coordinator_number'
                            placeholder='phone'
                            maxLength={10}
                            value={this.state.referee_coordinator_number}
                            onChange={this.handlePhone}
                        />
                    </legend>
                    <legend>
                        <strong>Head Referee </strong>
                    <input type="text"
                            name='head_referee'
                            placeholder='name'
                            maxLength={250}
                            value={this.state.head_referee}
                            onChange={this.handleChange}
                        />
                    </legend>

                    <legend>
                        <strong>Head Referee phone number </strong>for notifications during the tournament.  When head referee is requested a text message will be sent.
                    <input type="number"
                            name='head_referee_phone'
                            placeholder='phone'
                            maxLength={10}
                            value={this.state.head_referee_phone}
                            onChange={this.handlePhone}
                        />
                    </legend>
                    <legend>
                        <strong>Medical Attention Needed Phone Number: </strong>for notifications during the tournament.  Medical Assistance Notifications will be sent to this number
                    <input type="number"
                            name='medical_number'
                            placeholder='phone'
                            maxLength={10}
                            value={this.state.medical_number}
                            onChange={this.handlePhone}
                        />
                    </legend>

                    </div>
                    <legend>
                        <h5>Match Payout Amounts</h5>
                        {this.props.organization === 'ppa' &&
                        <>
                        
                        {/* <label >
                            PR2
                <input type="number"
                                name='pr2Amount'
                                value={this.state.pr2Amount}
                                onChange={this.handleChange}
                            />
                        </label> */}
                        <label >
                            PR
                <input type="number"
                                name='pr1Amount'
                                value={this.state.pr1Amount}
                                onChange={this.handleChange}
                            />
                        </label>
                        </>
                        
                        }
                        <label >
                            Certified
                <input type="number"
                                name='certified'
                                value={this.state.certified}
                                onChange={this.handleChange}
                            />
                        </label>
                        <label >
                            Level 2
                <input type="number"
                                name='level2'
                                value={this.state.level2}
                                onChange={this.handleChange}
                            />
                        </label>
                        <label >
                            Level 1
                <input type="number"
                                name='level1'
                                value={this.state.level1}
                                onChange={this.handleChange}
                            />
                        </label>
                        <label >
                            Not Rated
                <input type="number"
                                name='notRated'
                                value={this.state.notRated}
                                onChange={this.handleChange}
                            />
                        </label>
                    </legend>
                    <legend>
                        <h5>Alternative payment amounts</h5>
                        <p>If you are paying different amounts based on match type use these amount for the alternant payment amount.  You can then toggle the amount when assigning a match.</p>
                        <p>* For many tournaments this is not applicable so you can leave the amounts as 0.</p>

                        <label >
                            Certified
                <input type="number"
                                name='certified2'
                                value={this.state.certified2}
                                onChange={this.handleChange}
                            />
                        </label>
                        <label >
                            Level 2
                <input type="number"
                                name='level22'
                                value={this.state.level22}
                                onChange={this.handleChange}
                            />
                        </label>
                        <label >
                            Level 1
                <input type="number"
                                name='level12'
                                value={this.state.level12}
                                onChange={this.handleChange}
                            />
                        </label>
                        <label >
                            Not Rated
                <input type="number"
                                name='notRated2'
                                value={this.state.notRated2}
                                onChange={this.handleChange}
                            />
                        </label>
                    </legend>
                    <legend>
                        <strong>Facility Map Image URL </strong>
                        Include a url to a JPG or PNG image
                        <input type="text"
                            name='facility_map'
                            placeholder='facility map'
                            maxLength={100}
                            value={this.state.facility_map}
                            onChange={this.handleChange}
                        />
                        <hr/>
                        *If you need assistance email the facility map to pickleballdesk@gmail.com and we will add it for you.  Once you add the link to the image you can preview it at 
                        <a target="_blank" rel="noopener noreferrer"
                            href={`https://www.pickleballdesk.com/public/board/${this.props.tid}`}>
                            <p>https://www.pickleballdesk.com/public/board/{this.props.tid}</p></a>
                        
                    </legend>

                </>:null}


                {admin_type === 'master' || line ?
               <>
                  <hr/>

                <h3>Line Judge Setup</h3>
                <legend>Description for Line Judge Signup Page
                <ReactQuill
                        className='description'
                        style={{ background: 'white' }}

                        value={this.state.line_description || ''}
                        onChange={this.handleLineDescription} />
                </legend>
                
                <legend>
                        <strong>Line Judge coordinator name(s) </strong>Name will be listed on the referee signup page.
                    <input type="text"
                            name='line_coordinator_name'
                            placeholder='name'
                            maxLength={250}
                            value={this.state.line_coordinator_name}
                            onChange={this.handleChange}
                        />
                    </legend>
                    <legend>
                        <strong>Line Judge coordinator email </strong>
                        <input type="email"
                            name='line_coordinator_email'
                            placeholder='email'
                            maxLength={100}
                            value={this.state.line_coordinator_email}
                            onChange={this.handleChange}
                        />
                    </legend>

                    <legend>
                        <strong>Line coordinator phone number</strong> for notifications when bronze medal is called
                    <input type="number"
                            name='line_cord_text'
                            placeholder='phone'
                            maxLength={10}
                            value={this.state.line_cord_text}
                            onChange={this.handlePhone}
                        />
                    </legend>
                </>:null}

                {admin_type === 'master' || volunteer ?
               <>
                              <hr/>

                <h3>Volunteer Setup</h3>  
                <legend>Description for Volunteer Signup Page
                <ReactQuill
                        className='description'
                        style={{ background: 'white' }}

                        value={this.state.volunteer_description || ''}
                        onChange={this.handleVolunteerDescription} />
                </legend>
                <legend>
                <input type="checkbox"
                        name='volunteer_signature_required'
                        checked={this.state.volunteer_signature_required}
                        onChange={this.handleVolunteerWaiverRequired}
                    />
                    A signed waiver required before a volunteer can register?
                </legend>
                {this.state.volunteer_signature_required && 
                <>
                <legend>Volunteer Waiver
                <ReactQuill
                        className='description'
                        style={{ background: 'white' }}

                        value={this.state.volunteer_waiver || ''}
                        onChange={this.handleVolunteerWaiver} />
                </legend>  
                </>}        
                    <legend>
                        <strong>Volunteer coordinator name(s) </strong>Name will be listed on the referee signup page.
                    <input type="text"
                            name='volunteer_coordinator_name'
                            placeholder='name'
                            maxLength={250}
                            value={this.state.volunteer_coordinator_name}
                            onChange={this.handleChange}
                        />
                    </legend>
                    <legend>
                        <strong>Volunteer coordinator email </strong>
                        <input type="email"
                            name='volunteer_coordinator_email'
                            placeholder='email'
                            maxLength={100}
                            value={this.state.volunteer_coordinator_email}
                            onChange={this.handleChange}
                        />
                    </legend>
                    </>:null}
                    <hr/>
                    
     
                {
                    this.state.name.length > 3 ?
                        <button className='btn btn-primary' onClick={this.save}>Save</button> :
                        <div>
                            <p style={{ color: 'red' }}>** Name Required</p>
                            <button style={{ background: 'grey' }}>Save</button>
                        </div>
                }


            </div >
        )
    }
}
const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        admin_type: reduxState.admin_type,
        referee: reduxState.referee,
        line: reduxState.line,
        volunteer: reduxState.volunteer,
        organization: reduxState.organization,
        ref_id: reduxState.ref_id,
    }
}
export default connect(
    mapStateToProps, { updateTournament })(TournamentSetup)