import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { LinearProgress } from '@material-ui/core'

const CourtReset = () => {
    const [courts, setCourts] = useState([])
    const [message, setMessage] = useState('')
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getCourts() }, [tid])

    const getCourts = () => {
        axios.get(`/api/getCourtNames/${tid}`).then(res => {
            setCourts(res.data)
        })
    }

    const resetCourt = (court_num) => {
        setMessage('')
        axios.put(`/api/resetCourt/${tid}`, { court_num }).then(res => {
            setMessage('Court has been re-set')
        })
    }

    const mappedCourts = courts.map(court =>
        <div key={'c' + court.court_num}
            style={{ margin: '30px', padding: '10px' }}
        >


            <button onClick={() => { resetCourt(court.court_num) }}>Reset Court {court.court_num}</button>
        </div>
    )

    return (
        <div className='container'>
            <h4>This tool is to re-set a court</h4>
            <p>If you are not able to assign a court that is open click on the reset button then refresh your board.</p>
            {loader ? <LinearProgress/>:null}
            <h3>{message}</h3>
            <div
                style={{ display: 'flex', flexWrap: 'wrap' }}>
                {mappedCourts}
            </div>

        </div>
    )
}
export default CourtReset