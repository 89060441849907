import React, { Component } from 'react'
import Moment from 'react-moment';
import { connect } from 'react-redux'

import 'moment-timezone';
import './RefList.css'

class RefList extends Component {

    constructor() {
        super()
        this.state = {
            ref: {}


        }
    }


    render() {
        const mappedRefs = this.props.refList.map(ref => {
            return (
                <div key={ref.id} className={ref.quick_break ? 'ref-list break' : ref.credentials_verified === 'Certified' ? 'ref-list certified' : ref.credentials_verified === 'Level 2' ? 'ref-list level2' : ref.credentials_verified === 'Level 1' ? 'ref-list level1' :

                    'ref-list'}

                    onClick={((e) => this.props.handleAssignRef(e, ref))}
                >

                    {/* <Barcode
                        format='CODE39'
                        value={ref.ID} /> */}
                    <p style={{ color: 'yellow' }}> {ref.quick_break ? 'Wait to Assign' : ''}</p>
                    <p style={{ color: 'yellow' }}> {ref.last_match ? 'Last Match' : ''}</p>
                    <h3>{ref.first} {ref.last}</h3>
                    <div className='row'>
                        <div className='col-6'>





                            {ref.credentials_verified === 'certified' ?
                                <p className='certified-ref'><small>credentials:</small>{ref.credentials_verified}</p> :
                                <p className=''> {ref.credentials_verified}</p>}
                        </div>
                        <div className='col-6'>
                            {ref.end_time ?
                                <div>

                                    <Moment format="h:mm a" >
                                        {ref.end_time}
                                    </Moment>
                                </div> : ''}
                        </div>



                    </div >
                </div>
            )
        })
        return (


            <div className='refList' >
                <h4>Available Referees</h4>
                {mappedRefs}
            </div>


        )
    }

}
function mapStateToProps({ zone }) {
    return {
        zone
    }
}

export default connect(mapStateToProps, {})(RefList)

