import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import SubmitQuestion from '../components/AskReferee/SubmitQuestion';
import AnswerQuestion from '../components/AskReferee/AnswerQuestion';
import ListOfAnswers from '../components/AskReferee/ListOfAnswers';



const UserRoutes = (props) => {
    const user_id = useSelector(state => state.user_id)

    return (
        <div>

            <Switch>
                <Route path='/question/submit' component={SubmitQuestion} />
                <Route path='/question/answer/:url' component={AnswerQuestion} />
                <Route path='/question' component={ListOfAnswers} />

            </Switch>

        </div>
    )
}
export default UserRoutes