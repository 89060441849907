import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import axios from 'axios'
import Moment from 'react-moment';
import LinearProgress from '@material-ui/core/LinearProgress'
import TotalDisplay from './TotalDisplay';



const GetTournamentTotals = (props) => {
    const [totals, setTotals] = useState({lead_total: 0,
    second_total: 0,
    match_count: 0,
    second_count: 0})
    const [credentials, setCredentials] = useState([])
    const [headTotals, setHeadTotals] = useState([])
    const [secondCredentials, setSecondCredentials] = useState([])
    const [secondTotals, setSecondTotals] = useState([])
    const [auxTotal, setAuxTotal] = useState(0)
    const [auxTotalByCat, setAuxTotalByCat] = useState([])
    const [loader, setLoader] = useState(false)


     const tid = useSelector(state => state.tid)


    useEffect(() => {
        getRefereeTournamentTotal()
    }, [tid])


    const getRefereeTournamentTotal = () => {
        axios.post(`/api/getTotalRefereeTotals`, {tid}).then(res => {
            console.log(res.data)
            if(res.data.totals.length > 0){
                setTotals(res.data.totals[0])
            }
            
            setCredentials(res.data.credentials)
            setHeadTotals(res.data.headTotals)
            setSecondCredentials(res.data.secondCredentials)
            setSecondTotals(res.data.secondTotals)
            setAuxTotal(res.data.auxTotal[0].total)
            setAuxTotalByCat(res.data.auxTotals)
        })
    }

    return(
        <div className='col-12'>
            {loader ?
                    <LinearProgress />:''}
            <div className='DailyTotals'>
              <TotalDisplay 
                totals={totals} 
                headTotals={headTotals}
                secondTotals={secondTotals} 
                credentials={credentials}
                secondCredentials={secondCredentials}
                auxTotal={auxTotal}
                auxTotalByCat={auxTotalByCat}
              />
              </div>
        </div>
    )
}
export default GetTournamentTotals