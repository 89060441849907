import React, { Component } from 'react'
import axios from 'axios'
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RefScheduleModal from './RefScheduleModal'
import DisplayRefSchedule from './DisplayRefSchedule'


class RefInfoModal extends Component {
    constructor() {
        super()
        this.state = {
            checkOutStatus: false,
            status: '',
            statusTime: '',
            statusOther: '',
            referee: {},
            note: '',
            tag: '',
            ref_note:'',
            loader: false
        }
    }

    componentDidMount() {
        this.setRefToState()
    }


    setRefToState = () => this.setState({
        referee: this.props.refInfo,
        note: this.props.refInfo.note,
        refMessage: this.props.refMessage

    })

    checkInStatus = () => {
        if (this.props.queue <= this.props.queueNumber) { this.props.queueWarning() }
        else {
            this.setState({ checkOutStatus: true })
        }
    }



    checkOut =  () => {
        if (this.props.queue <= this.props.queueNumber) { this.props.queueWarning() }
        else {
            this.setState({ loader: true })
            let reason = `${this.state.status} ${this.state.statusTime.length > 0 ? 'back @' : ''} ${this.state.statusTime}`

             axios.put(`/api/checkin/${this.props.tid}`, { ref_id: this.props.refInfo.ref_id, checkout_note: reason }).then(
                res => {
                    this.setState({ loader: false })
                    if (this.props.statusHasBeenUpdated) { this.props.statusHasBeenUpdated(this.props.refInfo.ref_id) }
                    this.props.closeModal()
                }
            )
        }
    }

    quickBreak =  () => {
        this.setState({ loader: true })
         axios.put(`/api/quickBreak/${this.props.tid}`, { ref_id: this.props.refInfo.ref_id }).then(
            res => {
                this.setState({ loader: false })
                if (this.props.statusHasBeenUpdated) { this.props.statusHasBeenUpdated() }
                this.props.closeModal()

            }
        )
    }

    lastMatch =  () => {
        this.setState({ loader: true })
         axios.put(`/api/lastMatch/${this.props.tid}`, { ref_id: this.props.refInfo.ref_id }).then(
            res => {
                this.setState({ loader: false })
                if (this.props.statusHasBeenUpdated) { this.props.statusHasBeenUpdated() }
                this.props.closeModal()
            }
        )
    }

    updateNote = () => {
        this.setState({ loader: true })
        axios.put(`/api/updateNote/${this.props.tid}`, { ref_id: this.state.referee.ref_id, note: this.state.note, tag: this.state.tag, refMessage: this.state.refMessage }).then(
            res => {
                this.setState({ loader: false })
                if (this.props.statusHasBeenUpdated) { this.props.statusHasBeenUpdated() }
                this.props.closeModal()
            }
        )
    }

    lunchBreakStart = () => {
        this.setState({ loader: true })
         axios.put(`/api/lunchBreakStart/${this.props.tid}`, { ref_id: this.props.refInfo.ref_id, note: 'Lunch Break' }).then(
            res => {
                if (this.props.statusHasBeenUpdated) { this.props.statusHasBeenUpdated() }
                this.props.closeModal()
            }
        )
    }
    lunchBreakEnd =  () => {
        this.setState({ loader: true })
         axios.put(`/api/lunchBreakEnd/${this.props.tid}`, { ref_id: this.props.refInfo.ref_id }).then(
            res => {
                if (this.props.statusHasBeenUpdated) { this.props.statusHasBeenUpdated() }
                this.props.closeModal()
            }
        )
    }


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }
    render() {
        return (
            <div className='col-12 ref_info_modal'>
                {this.state.loader ?
                    <img id='loader' src={require('../../img/15.gif')} alt='players' /> : ''}
                <div className='row'>
                    <div className='col-6 RefInfoModal-status'>
                        <h4>{this.props.refInfo.first} {this.props.refInfo.last}</h4>
                        {!this.state.checkOutStatus ?
                            <div style={{ display: 'flex', flexDirection: 'column' }}>                                {this.state.referee.status ?
                                    <FormControlLabel
                                        label='Checked In'
                                        value={this.props.refInfo.status ? "Active" : 'Inactive'}
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={this.props.refInfo.status}
                                                value={this.props.refInfo.status ? "Active" : 'Inactive'}
                                            />
                                        }

                                        onClick={this.checkInStatus}

                                    /> : 'On Deck'}
                                <FormControlLabel
                                    label='Quick Break'
                                    value={this.props.refInfo.quick_break ? "Active" : 'Inactive'}
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={this.props.refInfo.quick_break}
                                            value={this.props.refInfo.quick_break ? "Active" : 'Inactive'}
                                        />
                                    }

                                    onClick={this.quickBreak}

                                />
                                <FormControlLabel
                                    label='Last Match '
                                    value={this.props.refInfo.last_match ? "Active" : 'Inactive'}
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={this.props.refInfo.last_match}
                                            value={this.props.refInfo.last_match ? "Active" : 'Inactive'}
                                        />
                                    }

                                    onClick={this.lastMatch}

                                />
                                <FormControlLabel
                                    label='Lunch Break '
                                    value={this.props.refInfo.last_match ? "Active" : 'Inactive'}
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={this.props.refInfo.note === 'Lunch Break'}
                                            value={this.props.refInfo.note === 'Lunch Break' ? "Active" : 'Inactive'}
                                        />
                                    }

                                    onClick={() => {
                                        this.props.refInfo.note === 'Lunch Break' ? this.lunchBreakEnd() :
                                            this.lunchBreakStart()
                                    }}

                                />
                                note: (will be removed once assigned)
                        <input type="text"
                                    placeholder=''
                                    maxLength='100'
                                    name='note'
                                    value={this.state.note}
                                    onChange={this.handleChange}
                                />
                                tag: (stays until you remove)
                        <input type="text"
                                    placeholder=''
                                    maxLength='100'
                                    name='tag'
                                    value={this.state.tag}
                                    onChange={this.handleChange}
                                />
                                Message from referee:
                            <input type="text"
                                    placeholder=''
                                    maxLength='100'
                                    name='refMessage'
                                    value={this.state.refMessage}
                                    onChange={this.handleChange}
                                />
                                <button onClick={this.updateNote}><i className="far fa-save" ></i>Save Notes</button>
                            </div>
                            : ''}

                        {this.state.checkOutStatus ?
                            <div className='check-out-modal checkout-style'>
                                <div className='col-12 check-out-questions '>


                                    <legend>Will you be returning to ref?</legend>
                                    <label>
                                        <input type='radio'
                                            checked={this.state.status === 'Lunch'}
                                            name='status'
                                            value='Lunch'
                                            onChange={this.handleChange}
                                        /> Lunch Break </label>
                                    <label>
                                        <input type='radio'
                                            value='Today'
                                            checked={this.state.status === 'Today'}
                                            name='status'
                                            onChange={this.handleChange}
                                        /> Today </label>
                                    <label>
                                        <input type='radio'
                                            checked={this.state.status === 'Today, When done playing'}
                                            name='status'
                                            value='Today, When done playing'
                                            onChange={this.handleChange}
                                        /> Today, when done playing </label>
                                    <label>
                                        <input type='radio'
                                            checked={this.state.status === 'Tomorrow'}
                                            name='status'
                                            value='Tomorrow'
                                            onChange={this.handleChange}
                                        /> Tomorrow </label>


                                    <label>
                                        <input type='radio'
                                            checked={this.state.status === 'Not Returning'}
                                            name='status'
                                            value='Not Returning'

                                            onChange={this.handleChange}
                                        /> Not Returning </label>
                                    <label>
                                        <input type='radio'
                                            checked={this.state.status === 'Contact if needed'}
                                            name='status'
                                            value='Contact if needed'
                                            onChange={this.handleChange}
                                        /> Contact if needed </label>
                                    <label>
                                        <input type='radio'
                                            checked={this.state.statusOther}
                                            name='statusOther'
                                            value='true'
                                            onChange={this.handleChange}
                                        /> Other </label>
                                    {this.state.statusOther ?
                                        <input type="text"
                                            placeholder=''
                                            maxLength='50'
                                            name='status'
                                            value={this.state.status}
                                            onChange={this.handleChange} />

                                        : ''}
                                </div>


                                <legend>What time will you be back?</legend>
                                <input type="text"
                                    placeholder='Time'
                                    name='statusTime'
                                    maxLength='10'
                                    value={this.state.statusTime}
                                    onChange={this.handleChange} />
                                <div>

                                    <button onClick={this.checkOut}><i className="far fa-save"></i>Save Checkout</button>



                                </div>
                            </div> : ''}
                    </div>
                    <div className='col-6'>
                        <h4>Schedule:</h4>
                        <div className='registered-spots'>
                            <DisplayRefSchedule
                                referee={this.props.refInfo} />
                        </div>
                        <h6>Notes:</h6>
                        <RefScheduleModal
                            referee={this.props.refInfo}
                        />
                    </div>

                    <button onClick={this.props.closeModal}> Close</button>
                </div>
            </div>
        )
    }
}
export default RefInfoModal
