import React, { Component } from 'react';
import axios from 'axios'
import { connect } from 'react-redux'
import Moment from 'react-moment';
import 'moment-timezone';

class MatchCountLine extends Component {
    constructor() {
        super()
        this.state = {
            count: [],
            days: [],
            date: ''
        }
    }

    componentDidMount() {
        this.getMatchCount()
        this.getTournamentDays()
    }



    getMatchCount = () => {
        axios.get(`/api/getMatchCountLine/${this.props.tid}`).then(res => {
            this.setState({ count: res.data, date: 'All' })
        })
    }

    getTournamentDays = () => {
        axios.get(`/api/getTournamentDaysLine/${this.props.tid}`).then(res => {
            this.setState({ days: res.data })
        })
    }

    getMatchCountByDay = (day) => {
        axios.post(`/api/getMatchCountLineByDay/${this.props.tid}`, { day }).then(res => {
            this.setState({ count: res.data, date: day })
        })
    }


    render() {

        const mappedDays = this.state.days.map(day => {
            return (
                <div>
                    <button
                        className='date-buttons'
                        style={day.start_time.includes(this.state.date) ? { background: '#009CAE' } : {}}
                        onClick={() => this.getMatchCountByDay(day.start_time)}>
                        <Moment format="MM/DD" >
                            {day.start_time}

                        </Moment>
                    </button>
                </div>
            )
        })


        const mappedResults = this.state.count.map((ref, i) => {

            return (
                <div key={i} className='col-4'>
                    <span> {ref.last}, {ref.first} - {ref.count} </span>
                </div>

            )
        })

        return (
            <div className="container">
                <div className='row'>

                    <div className='col-12' style={{ background: 'white', width: '100 %' }}>
                        <div className='col-12' style={{ display: 'flex' }}>
                            {mappedDays}
                            <button
                                className='date-buttons'
                                onClick={this.getMatchCount}
                                style={this.state.date === 'All' ? { background: '#009CAE' } : {}}
                            >All</button>
                        </div>
                        <h4>Match Count by Line Judge</h4>
                        {this.state.day ?
                            <Moment format="MM/DD" >
                                {this.state.date}

                            </Moment> : ''}
                        <div className='col-12 MatchCount'>

                            {mappedResults}
                        </div>
                        <p><small>Count includes matches currently in progress.</small></p>
                        <div>
                        </div>

                    </div>
                </div>

            </div>

        )
    }
}


function mapStateToProps({ tid, name, url,
    location,
    num_of_courts, zone }) {
    return {
        tid, name, url,
        location,
        num_of_courts, zone
    }
}

export default connect(mapStateToProps, {})(MatchCountLine);