import React from 'react'

const FinishMatch = (props) => {

    return (
        <div className='finishMatch col-12 ' align="center">
            <h3>Finish Match</h3>
            <div className='court-box-boardView col-10' align="center">
                <div className='court-num col-4'>
                    {props.court.court_num}
                </div>
                <div className={
                    props.court.credentials_verified === 'Certified' ? 'certified-board col-8 ref-name' : props.court.credentials_verified === 'Level 2' ? 'level2-board col-8 ref-name' : props.court.credentials_verified === 'Level 1' ? 'level1-board col-8 ref-name' :
                        'col-8 ref-name'}>

                    {props.court.last_match ? <span className='last_match_icon'>LM</span> : ''}
                    {props.court.first ?
                        <div>{props.court.first} {props.court.last}
                        </div> : ''}
                </div>
            </div>
            <input
                type="text"
                placeholder='SYSID'
                name="sysid"
                value={props.sysid}
                onChange={props.handleChange}
            />

            <legend>Medal Match?</legend>
            <label>
                <input type='radio'
                    checked={props.medal === ''}
                    name='medal'
                    value=''
                    onChange={props.handleChange}
                /> No
            </label>
            <label>
                <input type='radio'
                    value='Bronze'
                    checked={props.medal === 'Bronze'}
                    name='medal'
                    onChange={props.handleChange}
                /> Bronze
            </label>
            <label>
                <input type='radio'
                    checked={props.medal === 'Gold'}
                    name='medal'
                    value='Gold'
                    onChange={props.handleChange}
                /> Gold
            </label>
            {props.medal === 'Gold' ?
                <div className='tie-breaker'>
                    <label>
                        <p >Was there a tie-breaker match?</p>
                        <input type='radio'
                            checked={props.tiebreaker}
                            name='tiebreaker'
                            value={props.tiebreaker ? false : true}
                            onChange={props.handleChange}
                        /> Yes, Tie Breaker
                    </label>
                    <input
                        type="text"
                        placeholder='Tiebreaker SYSID'
                        name="tieSysid"
                        value={props.tieSysid}
                        onChange={props.handleChange}
                    />
                </div>
                : ''}
            {props.court.last_match ?
                <p className='last_match_finish'>Last Match *Verify and remember to checkout referee</p>
                : ''}
            {/* <legend></legend>
            <label>
                <input type='checkbox'
                    checked={props.forfeit}
                    name='forfeit'
                    onChange={props.handleChange}
                /> Forfeit
            </label> */}

            <div>

                <button id='finish-button'
                    type="submit"
                    onClick={() => { props.handleFinishMatch() }}>Finish</button>
                {/* <button onClick={() => props.showSecondReferee()}>
                    + Second Referee
                </button> */}
                <button onClick={() => props.clipboardNeeded()}>
                    Clipboard Needed
                </button>
                <button onClick={props.reAssign}>Assign New Referee/ Change Courts</button>
                <button onClick={props.saveChanges}>Save Changes</button>
            </div>

            <button onClick={props.clearState} > Cancel</button>
        </div>

    )
}
export default FinishMatch