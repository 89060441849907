import React, { Component } from 'react'
import axios from 'axios'
import { connect } from "react-redux"
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';




class BoardCommunication extends Component {
    constructor() {
        super()
        this.state = {
            referees: [],
            templates: [],
            showTemplateList: false,
            firstSearch: '',
            lastSearch: '',
            nameSearch: '',
            categoryFilter: '',
            email: [],
            emailSubject: '',
            replyEmail: '',
            emailBody: '',
            text: [],
            textBody: '',
            saveTemplate: false,
            templateName: '',
            loader: false
        }
    }

    componentDidMount() {
        this.getReferees()
        this.setEmail()
        this.getTemplates()
    }

    getReferees = () => {
        this.setState({ loader: true })
        axios.get(`/api/getActiveReferees/${this.props.tid}`).then(
            res => {
                this.setState({ referees: res.data, loader: false })
            }
        )
    }

    setEmail = () => this.setState({ replyEmail: this.props.email })

    getTemplates = () => {
        axios.get(`/api/getTemplates/${this.props.ref_id}`).then(
            res => {
                this.setState({ templates: res.data })

            }
        )
    }

    clearSearch = () => this.setState({ firstSearch: '', lastSearch: '', nameSearch: '', categoryFilter: '' })

    emailAll = () => {
        let email = [...this.state.referees]
        this.setState({ email: email })

    }

    textAll = () => {
        let text = [...this.state.referees]
        this.setState({ text: text })

    }


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    toggleShowTemplate = () => this.setState({ showTemplateList: !this.state.showTemplateList })


    selectTemplate = (t) => {
        if (t.type === 'text') {
            this.setState({
                showTemplateList: false,
                textBody: t.body

            })
        } else {
            this.setState({
                showTemplateList: false,
                emailSubject: t.subject,
                emailBody: t.body

            })
        }
    }

    addEmail = (ref) => {
        let email = this.state.email.concat(ref)
        this.setState({ email: email })
    }

    addText = (ref) => {
        let text = this.state.text.concat(ref)
        this.setState({ text: text })
    }

    removeEmail = (i) => {
        let email = [...this.state.email]
        email.splice(i, 1)
        this.setState({ email: email })
    }
    removeText = (i) => {
        let text = [...this.state.text]
        text.splice(i, 1)
        this.setState({ text: text })
    }


    sendEmail = () => {
        if (this.state.saveTemplate) { axios.post(`/api/saveTemplate`, { ref_id: this.props.ref_id, name: this.state.templateName, subject: this.state.emailSubject, body: this.state.emailBody, type: 'email' }).then(res => toast.success(`Template Saved`)) }
        axios.post(`/api/email`, { category: 'referee', tournamentName: this.props.name, tid: this.props.tid, email: this.state.email, subject: this.state.emailSubject, reply: this.state.replyEmail, body: this.state.emailBody, author: this.props.ref_id }).then(
            res => {
                toast.success(`Email Queued`)
                this.setState({ email: [], emailSubject: '', replyEmail: '', emailBody: '' })
            }
        )
    }

    sendText = () => {
        if (this.state.saveTemplate) { axios.post(`/api/saveTemplate`, { ref_id: this.props.ref_id, name: this.state.templateName, subject: '', body: this.state.textBody, type: 'text' }).then(res => toast.success(`Template Saved`)) }
        axios.post(`/api/customText`, { text: this.state.text, textBody: this.state.textBody, tid: this.props.tid, type: 'referee' }).then(
            res => {
                this.setState({ text: [], textBody: '' })
            }
        )
    }

    render() {

        const mappedReferees = this.state.referees
            .filter(obj => obj.first.toUpperCase().includes(this.state.firstSearch.toUpperCase()))
            .filter(obj => obj.last.toUpperCase().includes(this.state.lastSearch.toUpperCase()))


            .map(referee => {
                return (
                    <div key={referee.ref_id} style={{ display: 'flex' }}>
                        <div>
                            {referee.last}, {referee.first}
                        </div>

                        <div>
                            {referee.email ?
                                <i className="far fa-envelope icon"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.addEmail(referee)}></i> : <i className="far fa-envelope icon inactive" ></i>}
                            {referee.phone && referee.phone !== '0000000000' ?
                                <i className="fas fa-sms icon"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.addText(referee)}></i> :
                                <i className="fas fa-sms icon inactive" ></i>}
                        </div>
                    </div>
                )
            })

        const mappedEmail = this.state.email.map((ref, i) => {
            return (
                <div key={i} >
                    {ref.last}, {ref.first} <i className="fas fa-minus-circle" onClick={() => this.removeEmail(i)}></i>
                </div>
            )
        })
        const mappedText = this.state.text.map((ref, i) => {
            return (
                <div key={i} >
                    {ref.last}, {ref.first} <i className="fas fa-minus-circle" onClick={() => this.removeText(i)}></i>
                </div>
            )
        })

        const mappedTemplates = this.state.templates.map(t => {
            return (
                <div key={t.etid}
                    onClick={() => this.selectTemplate(t)}
                    className='mappedTemplates'>

                    {t.type}: {t.name}

                </div >
            )
        })
        return (
            <div className='container' >
                <div className='RefereeSchedule col-12'>

                    <div className='row'>

                        <div className='col-12 col-md-6'>

                            <h4>Active Referees</h4>
                            <div className='tips'>
                                <input
                                    type="text"
                                    placeholder='Search by Last Name'
                                    name="lastSearch"
                                    value={this.state.lastSearch}
                                    onChange={this.handleChange} />
                                <input
                                    type="text"
                                    placeholder='Search by First Name'
                                    name="firstSearch"
                                    value={this.state.firstSearch}
                                    onChange={this.handleChange} />

                                <form>

                                    <label>
                                        Filter:
                                    <input type='radio'
                                            checked={this.state.categoryFilter === 'Referee'}
                                            name='categoryFilter'
                                            value='Referee'
                                            onChange={this.handleChange}
                                        /> Referee </label>
                                    <label>
                                        <input type='radio'
                                            checked={this.state.categoryFilter === 'Line Judge'}
                                            name='categoryFilter'
                                            value='Line Judge'
                                            onChange={this.handleChange}
                                        /> Line Judge </label>
                                    <label>
                                        <input type='radio'
                                            checked={this.state.categoryFilter === 'other'}
                                            name='categoryFilter'
                                            value='other'
                                            onChange={this.handleChange}
                                        /> Other </label>
                                    <label>
                                        <input type='radio'
                                            checked={this.state.categoryFilter === ''}
                                            name='categoryFilter'
                                            value=''
                                            onChange={this.handleChange}
                                        /> All </label>
                                </form>


                                <input
                                    type="text"
                                    placeholder='Search by Shift Name'
                                    name="nameSearch"
                                    value={this.state.nameSearch}
                                    onChange={this.handleChange} />
                                <i className="fas fa-ban" onClick={this.clearSearch}></i>
                            </div>

                            <div className='RefSchedule-schedule'>
                                {this.state.loader ?
                                    <img id='loader' width="100" src={require('../../img/15.gif')} alt='players' /> : ''}
                                {mappedReferees}</div>
                        </div>

                        <div className='col-12 col-md-6 email'>
                            <h4>Send Text or Email</h4>
                            <button onClick={this.toggleShowTemplate}>{this.state.showTemplateList ? 'Close Template List' : 'Create from Saved Template'}</button>
                            {this.state.showTemplateList ?
                                <div className='mappedTemplatesDiv'>{mappedTemplates}</div>
                                : ''}

                            <div className='row'>
                                <div className='col-12 paper'>
                                    <h4>Text Referee</h4>
                                    <button onClick={this.textAll}>Add All Active Refs to text</button>
                                    <div className='emailRecipients'>
                                        {mappedText}
                                    </div>


                                    <p>Text Message</p>

                                    <textarea
                                        type="text"
                                        placeholder='Text Message, limit 160 cha'
                                        name="textBody"
                                        maxLength='159'
                                        value={this.state.textBody}
                                        onChange={this.handleChange} />
                                    <p><small>*Include your name or tournament name. Limit 160 characters</small></p>
                                    <legend> Save text as template
                                    <input
                                            type="checkbox"
                                            placeholder='Save as template'
                                            name="saveTemplate"
                                            value={this.state.saveTemplate}
                                            onChange={() => this.setState({ saveTemplate: !this.state.saveTemplate })} />
                                        {this.state.saveTemplate ?
                                            <input
                                                type="text"
                                                placeholder='Template Name'
                                                name="templateName"
                                                value={this.state.templateName}
                                                onChange={this.handleChange} /> : ''}
                                    </legend>
                                    <button onClick={this.sendText}>Send Text</button>
                                </div>
                                <div className='col-12 paper'>
                                    <h4>Email Referee</h4>
                                    <button onClick={this.emailAll}>Add All Active Refs to email</button>
                                    <legend>To:</legend>
                                    <div className='emailRecipients'>
                                        {mappedEmail}
                                    </div>
                                    <legend>
                                        Email Subject
                                <input
                                            type="text"
                                            placeholder='Email Subject'
                                            name="emailSubject"
                                            value={this.state.emailSubject}
                                            onChange={this.handleChange} />
                                        <small>*Tournament Name will be added to the end of the subject</small></legend>
                                    <legend>
                                        reply to:
                                    <input
                                            type="text"
                                            placeholder='Reply Email'
                                            name="replyEmail"
                                            value={this.state.replyEmail}
                                            onChange={this.handleChange} />
                                    </legend>
                                    <textarea
                                        type="text"
                                        placeholder='Email Body'
                                        name="emailBody"
                                        style={{ minHeight: '200px' }}
                                        value={this.state.emailBody}
                                        onChange={this.handleChange} />
                                    <legend> Save email as template
                                    <input
                                            type="checkbox"
                                            placeholder='Save as template'
                                            name="saveTemplate"
                                            value={this.state.saveTemplate}
                                            onChange={() => this.setState({ saveTemplate: !this.state.saveTemplate })} />
                                        {this.state.saveTemplate ?
                                            <input
                                                type="text"
                                                placeholder='Template Name'
                                                name="templateName"
                                                value={this.state.templateName}
                                                onChange={this.handleChange} /> : ''}
                                    </legend>
                                    {/* <legend>Schedule will be added to the bottom of the email</legend> */}

                                    <button onClick={this.sendEmail}>Send Email</button>
                                    <div>Email communication can be used for this tournament.  It is against site policies to use communication to solicit for other tournaments or products. </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name,
        email: reduxState.email,
        ref_id: reduxState.ref_id
    }
}
export default connect(
    mapStateToProps,
)(BoardCommunication)