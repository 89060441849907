import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Switch from "@material-ui/core/Switch";
import axios from 'axios'
import Modal from 'react-responsive-modal';
import './CourtMonitors.css'
import CourtMonitorBoard from './CourtMonitorBoard'
import VolunteerGroups from './VolunteerGroups'

const CourtMonitors = props => {
    const [leftCourts, setLeftCourts] = useState([])
    const [rightCourts, setRightCourts] = useState([])
    const [courtMonitors, setCourtMonitors] = useState([])
    const [volunteers, setVolunteers] = useState([])
    const [selectedVolunteer, setSelectedVolunteer] = useState(0)
    const [selectedCourt, setSelectedCourt] = useState({ court_num: null })
    const [searchName, setSearchName] = useState('')
    const [showRegistered, setShowRegistered] = useState(false)
    const [registeredVolunteers, setRegisteredVolunteers] = useState([])



    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getBoard() }, [])
    useEffect(
        () => { getVolunteers() }, [])
    useEffect(
        () => { assignMonitor() }, [selectedCourt])
    useEffect(
        () => { assignMonitor() }, [selectedVolunteer])
    useEffect(
        () => { getRegisteredVolunteers() }, [])

    const getBoard = async () => {
        await axios.get(`/api/getCourtMonitorBoard/${tid}`).then(

            res => {
                setLeftCourts(res.data[0].left)
                setRightCourts(res.data[0].right)
            }
        )
    }

    const getVolunteers = async () => {
        await axios.get(`/api/getCheckedInCourtMonitors/${tid}`).then(
            res => {
                setVolunteers(res.data)
            }
        )
    }
    const getRegisteredVolunteers = () => {
        axios.get(`/api/getVolunteers/${tid}`).then(res => {
            setRegisteredVolunteers(res.data)
        })
    }

    const getCourtMonitors = () => {
        axios.get(`/api/getCourtMonitors/${tid}`).then(res => {
            setCourtMonitors(res.data)
        })
    }

    const checkinVolunteer = (ref_id) => {
        axios.put(`/api/checkinVolunteer/${tid}`, { ref_id }).then(res => {
            if (props.handleCheckInModal) {
                props.handleCheckInModal()
            }
            else {
                getRegisteredVolunteers()
                setShowRegistered(false)
                // getCourtMonitors()
                getBoard()
                getVolunteers()

            }
        })
    }


    const assignMonitor = async () => {
        if (selectedCourt.court_num && selectedVolunteer > 0) {
            await axios.put(`/api/assignMonitorToCourt/${tid}`, { court: selectedCourt.court_num, ref_id: selectedVolunteer }).then(

                res => {
                    getBoard()
                    getVolunteers()
                    setSelectedVolunteer(0)
                    setSelectedCourt('')
                }
            )
        }
    }

    const finish = () => {
        axios.put(`/api/assignMonitorToCourt/${tid}`, { court: selectedCourt.court_num, ref_id: null }).then(

            res => {
                getBoard()
                getVolunteers()
                setSelectedVolunteer(0)
                setSelectedCourt('')
            }
        )

    }

    const clear = () => {
        setSelectedVolunteer('')
        setSelectedCourt('')
    }

    const mappedLeft = leftCourts.map(c => {

        return (
            <CourtMonitorBoard
                key={c.court_num}
                c={c}
                setSelectedCourt={setSelectedCourt}
                selectedCourt={selectedCourt} />
        )
    })
    const mappedRight = rightCourts.map(c => {

        return (
            <CourtMonitorBoard
                key={c.court_num}
                c={c}
                setSelectedCourt={setSelectedCourt}
                selectedCourt={selectedCourt} />
        )
    })

    const mappedVolunteers = volunteers.map(v => {
        return (
            <div
                className={v.ref_id === selectedVolunteer ? 'selected-volunteer' : 'pointer'}
                style={{ display: 'flex' }}
                key={v.ref_id}
                onClick={() => setSelectedVolunteer(v.ref_id)}>
                < Switch
                    color="primary"
                    checked={v.status}
                    value={v.status}
                    onClick={() => checkinVolunteer(v.ref_id)}
                />
                <div>
                    {v.last}, {v.first}
                </div>

            </div>
        )
    })

    const mappedRegistered = registeredVolunteers
        .filter(obj => obj.last.toUpperCase().includes(searchName.toUpperCase()))
        .map(v => {
            return (
                <div key={'r' + v.ref_id}>
                    < Switch
                        color="primary"
                        checked={v.status}
                        value={v.status ? "Active" : 'Inactive'}
                        onClick={() => checkinVolunteer(v.ref_id)}
                    />
                    {v.last}, {v.first}
                </div>
            )
        })

    const mappedCourtMonitors = courtMonitors
        .filter(obj => obj.last.toUpperCase().includes(searchName.toUpperCase()))
        .map(v => {
            return (
                <div key={'r' + v.ref_id}>
                    < Switch
                        color="primary"
                        checked={v.status}
                        value={v.status ? "Active" : 'Inactive'}
                        onClick={() => checkinVolunteer(v.ref_id)}
                    />
                    {v.last}, {v.first}
                </div>
            )
        })


    return (
        <div className='container'>
            <div className='row'>
                <div className='col-12 col-md-4'>
                    <div
                        style={{ background: '#DBFAB6', marginBottom: '15px' }}>
                        <div className='tips'>
                            <h4>Checked In Volunteers</h4>
                            Click their name to select then click the court they are assigned to. 
                        </div>
                        <div style={{ paddingLeft: '15px' }}>
                            {mappedVolunteers}
                        </div>
                    </div>

                    {/* <button onClick={() => setShowRegistered(true)}>Show Registered Volunteers</button> */}
                </div>
                <div className='col-12 col-md-8'
                    style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    <div className='col-6' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                        {mappedLeft}
                    </div>
                    <div className='col-6' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                        {mappedRight}
                    </div>



                </div>



                <div className='col-12'>
                    {selectedCourt.ref_id ?
                        <div className='tips'>
                            Remove {selectedCourt.first} {selectedCourt.last} from court {selectedCourt.court_num}?
                            <button onClick={finish}>Yes</button>
                            <p>If volunteer is done for the day make sure to check them out</p>                     </div>
                        : ''}
                </div>
                <div className='col-12'>
                    <button onClick={clear}>Clear Selection</button>
                </div>
                {/* <div className='col-12'>
                    <VolunteerGroups
                        selectedName={selectedVolunteer}
                        setSelectedName={setSelectedVolunteer}
                        getVolunteers={getVolunteers}
                        getBoard={getBoard}
                    />
                </div> */}


            </div>
            <Modal open={showRegistered} center showCloseIcon={true} onClose={() => setShowRegistered(false)} closeOnEsc={true} >
                <div
                    style={{ background: 'white', marginBottom: '15px' }}>

                    <h4 className='tips'>Registered Volunteers</h4>
                    <legend>
                        Search Last Name
                <input type="text"
                            value={searchName}
                            onChange={e => setSearchName(e.target.value)} />
                    </legend>
                    <div style={{ textAlign: 'left' }}>
                        {mappedRegistered}
                    </div>
                </div>

            </Modal>

        </div>
    )
}

export default CourtMonitors