import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Moment from 'react-moment';


const RefereeContactInfo = () => {
    const [list, setList] = useState([])

    const tid = useSelector(state => state.tid)
    const name = useSelector(state => state.name)

    const classes = useStyles()

    useEffect(() => {
        getRefereeContactInfo()
    }, [tid])


    const getRefereeContactInfo = () => {
        axios.get(`/api/getRefereeContactInfo/${tid}`).then(res => {
            setList(res.data)
        })
    }
    return (
        <div className='container-fluid'>
            <h3>Referee Contact Information </h3>
            <p>{name}</p>
            Referee Count: {list.length}
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.name}>Name</TableCell>
                            <TableCell align="left">Credentials</TableCell>
                            <TableCell align="left"
                                className={classes.phone}>Cell Phone</TableCell>
                            <TableCell align="left"
                                className={classes.phone}>Other Phone</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left" >Shirt Size</TableCell>
                            <TableCell align="left">Address</TableCell>
                            <TableCell align="left">Paypal</TableCell>
                            <TableCell align="left">Venmo</TableCell>
                            <TableCell align="left">Zelle</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((row, i) => (

                            <TableRow key={i}>
                                <TableCell>{row.name}</TableCell>
                            <TableCell align="left">{row.credentials}</TableCell>
                            <TableCell align="left">{row.cell_phone}</TableCell>
                            <TableCell align="left">{row.other_phone}</TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left" className={classes.shirt}>{`${row.shirt} / ${row.shirt2}`}</TableCell>
                            <TableCell align="left">
                                <div className={classes.address}>
                                <div>
                                    {row.address}
                                </div>
                                <div>
                                    {row.city} {row.state} {row.zip}
                                </div>
                            
                            </div></TableCell>
                            <TableCell align="left">{row.paypal}</TableCell>
                            <TableCell align="left">{row.venmo}</TableCell>
                            <TableCell align="left">{row.zelle}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    )
}
export default RefereeContactInfo

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    name: {
        minWidth: '200px'
    },
    phone: {
        minWidth: '150px'
    },
    address: {
        fontSize: '10px',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '200px'
    },
    email: {
        fontSize: '10px'
    },
    shirt: {
        fontSize: '10px',
        minWidth: '200px'
    }
});