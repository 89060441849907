import React from 'react'

export default function (){
    return(
        <div style={{textAlign: 'left', padding: '20px', display: 'flex', flexDirection: 'column', background: 'white'}} className='paper'>
            <p>
                The report is designed to compare the estimated amount vs actual and make sure all matches are paid.  If using supporting referees make sure to scroll to the right to see the supporting referee payments.  
            </p>
            <p>
                1. Click the auto post button.  This will compare the estimated amount and paid amount.  If these amount match and the match is showed as paid the match will be posted. 
                </p> 
                <p>  
                2. Look thru the list and find the matches that do not have a checkbox for paid or posted.  If the match was paid click the checkbox.  If you know the amounts are correct you can mark it as posted.  An example of this would be a pro-gold that was paid more.  
                </p> 
                <p>
                    3. If a payment amount is not correct click on the amount to correct.  
                </p>
                <p> 
                4. Once all matches are marked as paid and posted the total paid and verified paid amount will be equal.  
            </p>  
            <p>
                If any changes are made a note will be added in the notes section.  Note includes who made the change.
            </p>

        </div>
    )
}