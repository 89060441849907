import React from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'

const HandlePayment = props => {

    const name = useSelector(state => state.first)


    const payment = (type) => {
        axios.put(`/api/payment/handlePayment`, { tid: props.row.tid, ref_id: props.row.ref_id, type, name }).then(res => {
            props.close()
            props.get()
        })

    }

    return (
        <div>
            <h4>What payment type was used?</h4>
            <p>When you click on the type all unpaid items will be marked as paid.</p>
            <button onClick={()=>payment ('paypal')}>Paypal</button>
            <button onClick={()=>payment ('venmo')}>Venmo</button>
            <button onClick={()=>payment ('zelle')}>Zelle</button>
            <button onClick={()=>payment ('cast')}>Cash</button>
            <button onClick={()=>payment ('check')}>Check</button>
        </div>
    )
}
export default HandlePayment