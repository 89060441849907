import React from 'react'
import { Typography } from '@material-ui/core';
import QuizQuestion from './QuizQuestion'

const QuizHome = () => {
    return (
        <div className='container'>
            <Typography variant="h2" color="primary">
                Welcome to the rules challenge!!
                </Typography>
            <QuizQuestion />

        </div>
    )
}
export default QuizHome 