import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'


const ScoreCard = (props) => {

    let { sid, t1p1_name, t1p2_name, t2p1_name, t2p2_name, t1_g1_score, t2_g1_score, t1_g2_score, t2_g2_score, t1_g3_score, t2_g3_score, t1_g4_score, t2_g4_score, match_type, gold, court_num, submitted, signature, sysid, match_id } = props.match

    const finish = () => {
        axios.put(`/api/score/finish/${sid}`).then(
            res => {
                props.getScores()
            }
        )
    }

    return (
        <div className='ScoreCard'>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', textAlign: 'left' }} className={submitted ? 'submitted' : ''}>
                <div style={{ width: '200px' }}>
                    {submitted ? 'Submitted' : 'In Progress'}

                </div>

                <div>
                    <h3>Court: {court_num}</h3>
                    <p>{match_type}</p>
                    {gold ? 'GOLD' : ''}
                    <div>Match Id: {match_id}</div>
                    <div>SYSID: {sysid}</div>
                </div>
            </div>



            <div className='team-one score-row'>
                <div className='team-names-row team-one'>
                    <div>{t1p1_name}</div>
                    <div>{t1p2_name}</div>
                </div>
                <div className='scores-row'>{t1_g1_score}</div>
                {match_type === '2/3 to 11' ?
                    <div className='scores-row'>{t1_g2_score}</div> : ''}
                {match_type === '2/3 to 11' ?
                    <div className='scores-row'>{t1_g3_score}</div> : ''}
                {gold ?
                    <div className='scores-row'>{t1_g4_score}</div> : ''}

            </div>
            <div className='team-two score-row'>
                <div className='team-names-row team-two'>
                    <div>{t2p1_name}</div>
                    <div>{t2p2_name}</div>
                </div>

                <div className='scores-row'>{t2_g1_score}</div>
                {match_type === '2/3 to 11' ?
                    <div className='scores-row'>{t2_g2_score}</div> : ''}
                {match_type === '2/3 to 11' ?
                    <div className='scores-row'>{t2_g3_score}</div> : ''}
                {gold ?
                    <div className='scores-row'>{t2_g4_score}</div> : ''}

            </div>
            {submitted ?
                <div>Submitted By: {signature}
                </div> : ''}
            <div>{submitted ? <Moment format="hh:mm a   MM/DD/YYYY" >
                {submitted}
            </Moment> : ''}</div>
            {submitted ?
                <button onClick={finish}>Finish/ Scores Reported</button> :
                <Link to={`/score/rpsr/${sid}`} >
                    <button className='register'>Add Scores</button>
                </Link>
            }
            {submitted ?
                <Link to={`/score/rpsr/${sid}`} >
                    <button className='login'>Edit Scores</button>
                </Link> : ''
            }


        </div >
    )
}
export default ScoreCard