import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import axios from 'axios'
import Moment from 'react-moment';
import 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const NotificationsList = props => {
    const [list, setList] = useState([])

    const tid = useSelector(state => state.tid)

    const classes = useStyles();

    useEffect(
        () => { getList() }, [])


    const getList = () => {
        axios.get(`/api/getPastNotifications/${tid}`).then(
            res => {
                setList(res.data)
            }
        )
    }

    return (
        <div className='container-fluid'>
            <h2>Past Notifications</h2>
            <TableContainer component={Paper}>
                <Table aria-label="simple table" className={classes.body}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Time</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell align="left">Court</TableCell>
                            <TableCell align="left">Referee</TableCell>
                            <TableCell align="left">Message</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((row, i) => (
                            <TableRow key={i} className={row.type === 'medical' ? 'notification medical'
                                : row.type === '2nd' ? 'notification second'
                                    : row.type === 'td' ? 'tournament-director notification'
                                        : 'notification'}>
                                <TableCell className={classes.time} component="th" scope="row">
                                    <Moment format="MM/DD h:mm a" >
                                        {row.time}
                                    </Moment>
                                </TableCell>
                                <TableCell
                                    className={classes.type}
                                    align="left">{row.type === '2nd' ? 'Second Call Needed' :
                                        row.type === 'desk' ? 'Message to Desk' :
                                            row.type === 'medical' ? 'Medical Attention Needed' :
                                                row.type === 'td' ? 'Tournament Director Needed' : ''} {row.type}</TableCell>
                                <TableCell className={classes.court} align="left">{row.court_num}</TableCell>
                                <TableCell className={classes.name} align="left">{row.last}, {row.first}</TableCell>
                                <TableCell className={classes.message} align="left">{row.message}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div >


    )
}

export default NotificationsList;

const useStyles = makeStyles({
    body: { color: 'white' },
    time: { width: '60px', color: 'white' },
    type: { width: '50px', color: 'white' },
    court: { width: '30px', color: 'white' },
    name: { width: '50px', color: 'white' },
    message: { color: 'white' }
});