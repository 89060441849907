import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const TournamentAdmins = props => {
    const [admins, setAdmins] = useState([])
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [loader, setLoader] = useState(false)
    const [verify, setVerify] = useState(false)
    const [name, setName] = useState({})

    const tid = useSelector(state => state.tid)
    const admin_type = useSelector(state => state.admin_type)
    const editor = useSelector(state => state.ref_id)

    useEffect(
        () => { getTournamentAdmins() }, [])

    const searchForName = async (name) => {
        await setSearch(name)
        if (search.length > 2) {
            getName()
            setLoader(true)
        }
        else {
            setSearchResults([])
        }
    }

    const getTournamentAdmins = () =>{
        if(admin_type !== 'master')return
        axios.post(`/api/getTournamentAdmins/${tid}`, {editor}).then(res => {
            setAdmins(res.data)
        }).catch(err=> {})
    }

    const getName = () => {
        axios.post(`/api/getAdminName?search=${search}`).then(res => {
            setSearchResults(res.data)
            setLoader(false)
        })
    }

    const addAdmin = (ref_id) => {
        if(admin_type !== 'master')return
        axios.post(`/api/addTournamentAdmin/${tid}`, { ref_id, editor }).then(res => {
            getTournamentAdmins()
            setSearch('')
            setSearchResults([])
            toast.success('Email Sent. Remember to set privileges')
        }).catch(err=> toast.error('Not authorized to add admins'))
    }

    const removeAdmin = () => {
        axios.put(`/api/removeTournamentAdmin/${tid}`, { ref_id: name.ref_id, editor }).then(res => {
            getTournamentAdmins()
            setVerify(false)
            setSearch('')
            setSearchResults([])
            setName({})
        }).catch(err=> toast.error('Not authorized to edit admins'))
    }
    const changeType = (type, ref_id) => {
        axios.put(`/api/editTournamentAdminAccess`, { tid, type, ref_id, editor}).then(res => {
            getTournamentAdmins()
            toast.success('saved')
        }).catch(err=> toast.error('Not authorized to edit admins'))
    }
    const handleDelete = (ref) => {
        setVerify(true)
        setName(ref)
    }

    const closeDelete = () => {
        setVerify(false)
        setName({})
    }


    const mappedSearchResults = searchResults.map(name => {
        return (
            <div key={name.ref_id} className='search-results'>
                <button onClick={() => addAdmin(name.ref_id)}>
                    Add
            </button>
                {name.first} {name.last}
            </div>
        )
    })

    return (
        <div className='container' >
            <div className='row' style={{ background: 'white' }}>
                <div className='col-12'>

                    <h4>Tournament Admins</h4>
                    <p>These users have admin access to this tournament</p>
                    <TableContainer component={Paper}>
      <Table  aria-label="Bingo List">
      <TableHead>
          <TableRow>
          <TableCell align="left"></TableCell>
          <TableCell align="left">Name</TableCell>
             <TableCell align="left">Master</TableCell>
             <TableCell align="left">Referee</TableCell>
             <TableCell align="left">Line</TableCell>
             <TableCell align="left">Volunteer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {admins.map((row, i) => (
             <TableRow key={row.ref_id}>
             <TableCell>      <i className="fas fa-minus-circle icon pointer" onClick={() => handleDelete(row)}></i>
</TableCell>
             <TableCell align="left">{row.name}</TableCell>
             <TableCell align="left">
                 <input type='checkbox' checked={row.admin_type === 'master'} onChange={()=>row.admin_type === 'master' ? changeType('basic', row.ref_id):changeType('master', row.ref_id)}/>
             </TableCell>
             <TableCell align="left">
                 <input type='checkbox' checked={row.referee} onChange={()=>changeType('referee', row.ref_id)}/></TableCell>
             <TableCell align="left">
             <input type='checkbox' checked={row.line} onChange={()=>changeType('line', row.ref_id)}/>
             </TableCell>
             <TableCell align="left">
             <input type='checkbox' checked={row.volunteer} onChange={()=>changeType('volunteer', row.ref_id)}/>
             </TableCell>
           </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
                    
                </div>
                <div className='col-12'>
                    <legend>
                        <h5>Type name to search and add an admin </h5>
                        <p>Person must have a Desk account</p>
                        <input
                            type="text"
                            placeholder='search'
                            value={search}
                            onChange={e => searchForName(e.target.value)} />
                    </legend>
                    {loader ? <img width="100" src={require('../../../img/15.gif')} alt='players' /> : ''}
                    {mappedSearchResults}
                </div>
            </div>

            <Modal open={verify} center showCloseIcon={true} onClose={closeDelete} closeIconSize={20} closeOnEsc={true} >
                <div>
                    Are you sure you want to delete {name.first} {name.last} admin?
                                        <button onClick={removeAdmin}>Confirm Delete</button>
                    <button onClick={closeDelete}>Cancel</button>
                </div>
            </Modal>
        </div>
    )
}
export default TournamentAdmins