import React, { Component } from 'react'
import axios from 'axios'
import io from 'socket.io-client'
import { connect } from "react-redux"
import ScoreCard from './ScoreCard'



class ViewScores extends Component {
    constructor() {
        super()
        this.state = {
            scores: [],
            activeMatches: [],
            showActive: false
        }
    }

    componentDidMount() {
        this.getScores()
        this.setSocketListeners()
        this.interval = setInterval(() => {
            this.getScores()
        }, 1000 * 10 * 60);
    }

    componentWillUnmount() {
        this.socket.disconnect()
    }

    setSocketListeners = () => {
        this.socket = io()

        this.socket.emit('joinRoom', `score:${this.props.tid}`)

        this.socket.on('score', results => {
            this.getScores()

        })
    }

    getScores = () => {
        this.getCompletedMatches()
    }

    getCompletedMatches = () => {
        axios.get(`/api/score/getScores/${this.props.tid}`).then(
            res => {
                this.setState({ scores: res.data })
            }
        )
    }

    getActiveMatches = () => {
        axios.get(`/api/score/getActiveMatches/${this.props.tid}`).then(
            res => {
                this.setState({ activeMatches: res.data })
            }
        )
    }

    handleShowActive = () => {
        if (!this.state.showActive) { this.getActiveMatches() }
        this.setState({ showActive: !this.state.showActive })
    }

    render() {
        const mappedScores = this.state.scores.map(match => {
            return (
                <div className='col-12 col-sm-4' key={match.sid}>
                    <ScoreCard
                        getScores={this.getScores}
                        match={match} />
                </div>
            )
        })

        const mappedActiveMatches = this.state.activeMatches.map(match => {
            return (
                <div className='col-12 col-sm-4' key={match.sid}>
                    <ScoreCard
                        getScores={this.getScores}
                        match={match} />
                </div>
            )
        })


        return (
            <div className='container-fluid'>
                <div className='col-12'>
                    <button
                        onClick={this.getScores}>
                        <i className="fas fa-sync-alt"></i>
                            refresh</button>
                    <h3>View Scores</h3>
                    <div className='col-12' style={{ display: 'flex', flexWrap: 'wrap' }} >
                        {mappedScores}
                        {this.state.scores.length === 0 ? 'No Scores Reported' : ''}

                    </div>
                    <div className='col-12'>
                        <button onClick={this.handleShowActive}>{this.showActive ? 'Hide Matches In Progress' : 'Show Matches In Progress'}</button>
                    </div>
                    <div className='col-12' style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {this.state.showActive ?

                            mappedActiveMatches : ''}

                    </div>

                </div>

            </div>
        )
    }
}
const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
    }
}


export default connect(mapStateToProps)(ViewScores)