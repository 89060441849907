import React, {useState} from 'react'
import axios from 'axios'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';

const SendEmail = props => {
    const [body, setBody] = useState('')

    const from = useSelector(state => state.ref_id)
    const tid = useSelector(state => state.tid)

    const sendEmail = () => {
        if(!from){return}
        axios.post(`/api/messageboard/sendEmail`, { tid, from, id: props.row.id, body }).then(
            res => {
                setBody('')
                toast.success('email sent')
                props.close()
            }
        ).catch(err => {
            toast.error('Issue Occurred- please try again')
        })
    }
    return(
        <Dialog  aria-labelledby="simple-dialog-title" open={props.show} onClose={props.close}>
      <DialogTitle >Send Email to {props.row.name}</DialogTitle>
      <div style={{display: 'flex', flexDirection:'column', padding: '20px'}}>
     <label htmlFor="">
         <textarea name="" id="" cols="30" rows="10"
            value={body}
            onChange={e=>setBody(e.target.value)}>

         </textarea>
     </label>
     <button onClick={sendEmail}>Send Email</button>
     <p>Your name  and email will be included in the email.</p>
      </div>
      </Dialog>
    )
}
export default SendEmail