import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify';

const ContactCoordinator = props => {
    const [emailBody, setEmailBody] = useState('')

    const name = useSelector(state => state.name)
    const tid = useSelector(state => state.tid)
    const rcName = useSelector(state => state.referee_coordinator_name)
    const ref_id = useSelector(state => state.ref_id)
    const lineName = useSelector(state => state.line_coordinator_name)
    const volName = useSelector(state => state.volunteer_coordinator_name)

    const {type} = props.location

    useEffect(
        () => { checkForProps() }, [props.location])

    const checkForProps = () => {
        if(!props.location.type){props.history.goBack()}
    }

    const sendEmail = () => {
        if (!ref_id) return
        axios.post(`/api/emailCoordinator`, { tid, ref_id, emailBody, type: props.location.type }).then(
            res => {
                toast.success('email sent')
                props.history.goBack()
            }
        )
    }

    return (
        <div className='container'>
            <h3>{name}</h3>
            <h5>Send message to {` `}
                {type === 'referee' ? rcName:
                    type === 'line' ? lineName :
                    type === 'volunteer' ? volName : 'coordinator'}</h5>
            <textarea name="emailBody" id="" cols="30" rows="10"
                onChange={e => setEmailBody(e.target.value)}></textarea>
                {ref_id ?
            <button onClick={sendEmail}>Send</button>:
            <h3>Please login to send an email</h3>}

        </div>
    )
}
export default ContactCoordinator