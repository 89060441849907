import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'


import './AdminNav.css';

class AdminNav extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggle: false,
            navHeight: 0
        }
    }


    toggleNav = () => {
        this.setState({
            navHeight: !this.state.navHeight ? 240 : 0
        })
    }



    render() {
        return (
            <div className="App">
                <nav className="main-navbar" >
                    <div>
                        <span className='menuBurger' onClick={this.toggleNav} onMouseEnter={this.toggleNav}>MENU &#9776;</span>
                        <div className={this.state.toggle ? "bigList nav-height" : "bigList"}>

                            <ul>
                                <li className={this.props.location.pathname.includes('/desk/admin') ? 'main-navbar-active' : ''}>
                                    <Link to={`/admin`} >
                                        Menu</Link></li>
                                <li className={this.props.location.pathname.includes('/desk') ? 'main-navbar-active' : ''}>
                                    <Link to={`/desk`}>
                                        Referee
                                    </Link></li>
                                <li className={this.props.location.pathname.includes('/line/') ? 'main-navbar-active' : ''}>
                                    <Link to={`/line/linejudge`} >
                                        Line Judge
                                    </Link></li>
                                <li className={this.props.location.pathname.includes('/volunteer/') ? 'main-navbar-active' : ''}>
                                    <Link to={`/volunteer/`} >
                                        Volunteer</Link></li>

                                <li className={this.props.location.pathname.includes('/dashboard') ? 'main-navbar-active' : ''}>
                                    <Link to={`/deskDashboard`} >
                                        Tournament List</Link></li>

                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="menuList"
                    onMouseLeave={() => this.setState({ navHeight: 0 })}
                    style={{ height: this.state.navHeight }}
                >
                    <ul onClick={this.toggleNav}>
                        <li><Link to={`/admin`} >
                            Menu</Link></li>
                        <li><Link to={`/desk/boardView`} >
                            Referee</Link></li>
                        <li><Link to={`/line/linejudge`} >
                            Line Judge</Link></li>
                        <li><Link to={`/volunteer/`} >
                            Volunteer</Link></li>

                        <li><Link to={`/deskDashboard`} >
                            Tournament List</Link></li>
                    </ul>
                </div>

            </div >
        );
    }
}

export default withRouter(AdminNav);