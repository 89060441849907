import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import './ToDoList.css'



const AddToDo = props => {
    const [category, setCategory] = useState(0)
    const [error, setError] = useState('')
    const [budget, setBudget] = useState(false)
    const [budgetTitle, setBudgetTitle] = useState('')
    const [contact, setContact] = useState('')
    const [contactPhone, setContactPhone] = useState('')
    const [contactEmail, setContactEmail] = useState('')
    const [itemNotes, setItemNotes] = useState('')
    const [categoryError, setCategoryError] = useState(true)
    const [titleError, setTitleError] = useState(true)
    const [verifyDelete, setVerifyDelete] = useState(false)
    const [todo, setTodo] = useState(true)
    const [todoTitle, setToDoTitle] = useState('')
    const [todoCategory, setTodoCategory] = useState({})
    const [todoCategoryName, setTodoCategoryName] = useState('')
    const [errorTodoCategory, setErrorTodoCategory] = useState(true)
    const [dueDate, setDueDate] = useState(null)
    const [names, setNames] = useState([])
    const [addedNames, setAddedNames] = useState([])
    const [removedNames, setRemovedNames] = useState([])


    const tid = useSelector(state => state.tid)

    useEffect(
        () => { setPropsToState() }, [props.editItem])
    useEffect(
        () => { checkForCategory() }, [budget])
    useEffect(
        () => { checkForCategory() }, [category])
    useEffect(
        () => { checkForTitle() }, [todoTitle])
    useEffect(
        () => { duplicateTitle() }, [budget])
    useEffect(
        () => { editItem() }, [todo])

    const setPropsToState = () => {
        if(props.category.cid){
            setTodoCategory({cid: props.category.cid, category:props.category.category})
            setTodoCategoryName(props.category.category)
            setErrorTodoCategory(false)
        }
        if (props.editItem.pid) {
            
            setCategory(props.editItem.category_id)
            
            setBudget(props.editItem.budget)
            setBudgetTitle(props.editItem.budget_title)
            setContact(props.editItem.contact)
            setContactPhone(props.editItem.contactPhone)
            setContactEmail(props.editItem.contactEmail)
            setItemNotes(props.editItem.itemNotes)
            setToDoTitle(props.editItem.todo_title)
            setTodo(props.editItem.todo)
            setNames(props.editItem.names)

        }
    }

    const handleSave = () => {
        if (!categoryError && !titleError) {
            if (props.editItem.pid) {
                editItem()
            } else {
                addItem()
            }
        }
    }

    const addItem = () => {
        if (!categoryError && !titleError) {
            axios.post(`/api/addTodoItem/${tid}`, { category, budgetTitle, todoTitle, contact, contactPhone, contactEmail, itemNotes, budget, todo, todoCategory: todoCategory.cid, dueDate, addedNames }).then(res => {
                if (props.getToDoList) { props.getToDoList() }
                if (props.close) { props.close() }
            })
        }
    }

    const editItem = () => {
        if (!categoryError && !titleError) {
            axios.put(`/api/editTodoItem/${props.editItem.pid}`,
                { category, budgetTitle, todoTitle, contact, contactPhone, contactEmail, itemNotes, budget, todo, todoCategory:todoCategory.cid, dueDate, addedNames, removedNames, tid }).then(res => {
                    if (props.getToDoList) { props.getToDoList() }
                    if (props.close) { props.close() }
                })
        }
    }
    const deleteItem = () => {
        if (!categoryError && !titleError) {
            axios.put(`/api/deleteBudgetItem/${props.editItem.pid}`).then(res => {
                if (props.getToDoList) { props.getToDoList() }
                if (props.close) { props.close() }
            })
        }
    }

    const handleDeleteItem = () => {
        setVerifyDelete(true)
    }

    const handleTodoCategory = e =>{
        
        if(e.target.value === ''){setErrorTodoCategory(true)}
        else if(e.target.value){
            let index = props.categories.findIndex(x => x.category === e.target.value);
            setTodoCategory(props.categories[index])
            setTodoCategoryName(props.categories[index].category)
            setErrorTodoCategory(false)
            checkForCategory(false)
        }else{
            setErrorTodoCategory(true)
        }
    }

    const checkForCategory = () => {
        if (budget === 0 && category === 0) { setCategoryError(true) }
        else { 
            setCategoryError(false) }
    }


    const checkForTitle = () => {
        if (todoTitle.length === 0) { setTitleError(true) }
        else { setTitleError(false) }
    }

    const duplicateTitle = () => {
        setBudgetTitle(todoTitle)
    }

    const handleRemoveName = ref_id => {
        setAddedNames(addedNames.filter(addedNames => addedNames.ref_id !== ref_id))
    }

    const handleCategory = (num) => {
        setCategory(num)
        setCategoryError(false)
    }

    const mappedNames = names.map((n, i)=>(
       <div key={n.ref_id}
        onClick={() => setRemovedNames(addedNames => [...addedNames, n])}>
        {n.first} {n.last.charAt(0)}
       </div>
    ))

    const mappedCommittee = props.committee.map(c => {
        return (
            <div key={'c'+c.ref_id}
                className={c.cid === todoCategory ? 'selected-todo-category-item' : 'todo-category-item'}
                onClick={() => setAddedNames(addedNames => [...addedNames, c])}>
                {c.first}  {c.last.charAt(0)}
            </div>
        )
    })

    const mappedAdded = addedNames.map(c => {
        return (
            <div key={c.ref_id}
                className={c.cid === todoCategory ? 'selected-todo-category-item' : 'todo-category-item'}
                onClick={() => handleRemoveName(c.ref_id)}>
                {c.first}  {c.last.charAt(0)}
            </div>
        )
    })

    const mappedRemoved = removedNames.map(c => {
        return (
            <div key={c.ref_id}
                className={c.cid === todoCategory ? 'selected-todo-category-item' : 'todo-category-item'}>
                {c.first}  {c.last.charAt(0)}
            </div>
        )
    })

    

    return (
        <div className='AddBudgetItem'>

            <div className='row'>
                <div className='col-12'>

                    <legend
                        style={errorTodoCategory ? { color: 'red', paddingRight: '10px' } : null}>
                        Select Category
                        <select onChange={handleTodoCategory} value={todoCategoryName}>
                            <option key={'x1'} value={''}>Select Category</option>
                                {props.categories.map((row, i) =>(
                                    <option key={i+'c'} value={row.category}>{row.category}</option>
                                ))}

                        </select>
                    </legend>
                    <div className='addForm'>
                        <div className=''
                            style={titleError ? { color: 'red' } : null}>
                            Title
                        </div>
                        <div >
                            <input type="text"
                                style={{ width: '400px' }}
                                value={todoTitle}
                                onChange={e => setToDoTitle(e.target.value)} />
                        </div>
                        
                    </div>
                    <div className='addForm'>
                        Due Date: 
                        <input 
                            type='date'
                            value={dueDate}
                            onChange={e=>setDueDate(e.target.value)}/>
                       
                        </div>

                   
                    <div className='vendor-contact'>
                    Vendor/ Supplier Info
                    <div className='addForm'>
                        <div className='formTitle'>
                            Contact
                            </div>
                        <div >
                            <input type="text"
                                value={contact}
                                onChange={e => setContact(e.target.value)} />
                        </div>
                    </div>
                    <div className='addForm'>
                        <div className='formTitle'>
                            Contact Phone
                    </div>
                        <div >
                            <input type="text"
                                value={contactPhone || ''}
                                onChange={e => setContactPhone(e.target.value)} />
                        </div>
                    </div>
                    <div className='addForm'>
                        <div className='formTitle'>
                            Contact Email
                    </div>
                        <div >
                            <input type="text"
                                value={contactEmail}
                                onChange={e => setContactEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className='addForm'>
                        <div className='formTitle'>
                            Notes
                    </div>
                    </div>
                        <div >
                            <textarea name="" id="" cols="30" rows="3"
                                onChange={e => setItemNotes(e.target.value)}>

                            </textarea>


                        </div>

                    </div>
                    <div className='add-checkbox'>
                        <input type="checkbox"
                            checked={budget}
                            value={budget}
                            onChange={e => setBudget(budget => !budget)} />
                        Add To-Do Item to Budget

            </div>
                    {budget ?
                        <div>
                            <div className='addForm'>
                                <div className='formTitle'>
                                    Budget Title
                    </div>
                                <div >
                                    <input type="text"
                                        value={budgetTitle}
                                        onChange={e => setBudgetTitle(e.target.value)} />
                                </div>
                            </div>
                            <div className='addForm'>
                                <div className='formTitle'
                                    style={categoryError ? { color: 'red' } : null}
                                >
                                    Expense Type
                                </div>
                                <div >
                                    <label>
                                        <input type='radio'
                                            checked={category === 1}
                                            onChange={() => handleCategory(1)}
                                        /> Income </label>
                                    <label>
                                        <input type='radio'
                                            checked={category === 2}
                                            onChange={() => handleCategory(2)}
                                        /> Income Expense </label>

                                    <label>
                                        <input type='radio'
                                            checked={category === 3}
                                            onChange={() => handleCategory(3)}
                                        /> Expense </label>
                                </div>
                            </div>
                        </div>
                        : ''}

                    <div className='col-12'>

                        <div className='todo-categories'>
                            Task Assigned To:
<div className='todo-category-list'>
                            {names.length === 0 && 'not assigned'}
                                    { mappedNames }
                            </div>
                        </div>
                        <div className='todo-categories'>

                            Volunteer Staff (click name to add assign to task)
<div className='todo-category-list'>
                                {mappedCommittee}
                            </div>
                        </div>
                        {addedNames.length > 0 ?
                            <div className='todo-categories'>
                                Added:
<div className='todo-category-list'>
                                    {mappedAdded}
                                </div>
                            </div> : ''}
                        {removedNames.length > 0 ?
                            <div className='todo-categories'>
                                Removed:
<div className='todo-category-list'>
                                    {mappedRemoved}
                                </div>
                            </div> : ''}
                    </div>
                </div>
                <div className='col-12 tips'
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginTop: '20px' }}
                >


                    {verifyDelete ?
                        todo ?
                            <div>
                                <h3>To-Do Item has a budget item assigned.  </h3>
                                <div>Do you want to remove both budget item and to-do item?</div>
                                <button
                                    onClick={deleteItem}>Remove Both Budget and To-Do</button>
                                <button
                                    onClick={() => setTodo(false)
                                    }>Remove Just To-Do Item</button>
                            </div>
                            :
                            <button
                                style={{ background: 'red' }}
                                onClick={deleteItem}>Verify Delete Item</button> : ''}
                    <button onClick={props.close}>Close/ Cancel</button>
                    {!categoryError && !titleError && !verifyDelete && !errorTodoCategory ?
                        <button
                            onClick={handleSave}>Save</button> : ''}
                    {props.editItem.pid ?
                        <i className="far fa-trash-alt" style={{ textAlign: 'right' }}
                            onClick={handleDeleteItem}></i> : ''}
                </div>
            </div>
        </div >


    )
}
export default AddToDo