import React, { Component } from 'react'
import axios from 'axios'
import io from 'socket.io-client'
import { connect } from "react-redux"
import 'moment-timezone';
import '../BoardView.css'
import ClipboardNeeded from '../ClipboardNeeded'
import ShiftCheckedIn from '../BoardView/ShiftCheckedIn'
import BoardDisplayMagnet from '../BoardView/BoardDisplayMagnet'

class BoardListFromParams extends Component {
    constructor() {
        super()
        this.state = {
            courts: [],
            refList: [],
            court: '',
            clipboardNeeded: [],
            sideQueue: [],
            hideButton: true,
            tournament: '',
            map: ''
        }
    }

    componentDidMount() {
        this.getTournamentName()
        this.getCourts()
        this.getAvailableRefs()
        this.getClipboardNeeded()
        this.setSocketListeners()
        this.getSideQueue()
    }

    componentWillUnmount() {
        this.socket.disconnect()
    }

    setSocketListeners = () => {
        this.socket = io()

        this.socket.emit('joinRoom', `board:${this.props.tid}`)

        this.socket.on('board', results => {
            if (results.msg === 'assign') {
                let c = [...this.state.courts]
                let index = c.map(e => e.court_num).indexOf(results.court.court_num)
                c[index] = results.court
                this.setState({courts: c})

                let q = [...this.state.refList]
                let qi = q.map(e => e.ref_id).indexOf(results.court.ref_id)
                if(qi >= 0){
                q.splice(qi, 1)
                this.setState({refList: q})
                }

                let s = [...this.state.sideQueue]
                let si = s.map(e => e.ref_id).indexOf(results.court.ref_id)
                if(si >= 0){
                s.splice(si, 1)
                this.setState({sideQueue: s})
                }
            }
            else if (results.msg === 'finish') {
                let c = [...this.state.courts]
                let index = c.map(e => e.court_num).indexOf(results.court.court_num)
                c[index] = results.court
                this.setState({courts: c})

                let q = [...this.state.refList]
                q = q.concat(results.referee)
                this.setState({refList: q})


            }
            else if (results.msg === 'match starter') {
                let q = [...this.state.refList]
                let qi = q.map(e => e.ref_id).indexOf(results.ref_id)
                if(qi >= 0){
                q[qi].note = results.note
                this.setState({refList: q})
                }else {
                    let aux = [...this.state.sideQueue]
                let auxI = aux.map(e => e.ref_id).indexOf(results.ref_id)
                if(auxI >= 0){
                aux[auxI].note = results.note
                this.setState({sideQueue: q})

                }

            }
        }
            else if (results.msg === 'swapQueue') {
                this.getAvailableRefs()
                this.getSideQueue()
            }
            else if (results.msg === 'getCourtsOnly') {
                this.getCourts()
            }
            else {
                this.getAll()
            }
        })
    }

    getAll = () => {
        this.getCourts()
        this.getAvailableRefs()
        this.getClipboardNeeded()
    }

    getCourts = async () => {

        await axios.get(`/api/getCourtsForPublicDisplay/${this.props.match.params.tid}`).then(

            res => {
                this.setState({ courts: res.data })
            }
        )
    }
    getTournamentName = async () => {

        await axios.get(`/api/getTournamentName/${this.props.match.params.tid}`).then(

            res => {
                this.setState({ tournament: res.data.name, map: res.data.facility_map })
            }
        )
    }

    getAvailableRefs = () => {
        axios.get(`/api/getAvailableRefs/${this.props.match.params.tid}`).then(

            res => {
                this.setState({ refList: res.data })
            }
        )
    }

    getSideQueue = () => {
        axios.get(`/api/getSideQueue/${this.props.match.params.tid}`).then(
            res => {
                this.setState({ sideQueue: res.data })
            }
        )
    }

    getClipboardNeeded = async () => {
        await axios.get(`/api/getClipboardNeeded/${this.props.match.params.tid}`).then(
            res => {
                this.setState({ clipboardNeeded: res.data })
            }
        )
    }

    render() {
        const mappedCourts = this.state.courts.map(court => {
            return (
                <div key={court.court_num} className='col-12 court-box-boardList'>
                    <div className='col-3 court-num-boardList'>
                        <div className='court-num-board-list'>{court.court_num}</div>
                        {court.medal === 'Bronze' ?
                            <div className='board-medal-display'>
                                <i className="fas fa-medal"></i>
                                B
                                </div> :
                            court.medal === 'Gold' ?
                                <div className='board-medal-display'>
                                    <i className="fas fa-medal"></i>
                                    G
                                </div> : ''}
                    </div>
                    <div className='col-9 ref-name'>
                        <div className={court.last_match ? 'last_match' : ''}>
                            {court.last_match ? <span className='last_match_icon'>LM</span> : ''}
                            {court.last_match ? <p >{court.first} {court.last}</p> :
                                <div className='magnet-name-boardDisplay'>{court.first} {court.last}
                                    {court.second ? ` / ${court.second}` : ''}
                                </div>}
                        </div>
                    </div>
                </div>
            )
        })

        const mappedRefs = this.state.refList.map((ref, i) => {
            return (
                <BoardDisplayMagnet
                key={'r'+i}
                i={i}
                referee={ref}
            />
                
            )
        })

        const mappedSideQueue = this.state.sideQueue.map((ref, i) => {
            return (
                <BoardDisplayMagnet
                    referee={ref}
                />
            )
        })

        return (
            <div className='container-fluid'>
                <h2>{this.state.tournament}</h2>
                    <div className='col-12 board'
                        style={{ background: 'white' }}>
                            
                            
                        <div className='row'>
                            <div className='col-12 col-md-6'>
                            {this.state.map &&
                            <img className='admin-images'
                                src={this.state.map} alt='facility map' />}
                                <div className='court-list'>
                                <h5>Court Assignments</h5>
                                {mappedCourts}
                                </div>

                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='court-list'>
                                <h3>On Duty Queue</h3>
                                <p><small>Queue order is an estimate for match assignments.  Actual assignment is at the discretion of the referee coordinator or based on match type.</small></p>
                                <div className='col-12'>
                                   
                                    {mappedRefs}
                                </div>
                                <div className='col-12'>
                                    <h4>Aux Queue</h4>
                                    {mappedSideQueue}
                                </div>

                                <h5 style={{ background: 'white' }}>
                                    OnDeck/ Referees who have checked-In</h5>
                                <ShiftCheckedIn
                                    updateBoard={this.updateBoard}
                                    hideButton={this.state.hideButton}
                                    turnOffOnClick={true}
                                />
                                {this.state.clipboardNeeded.length > 0 ?
                                    <ClipboardNeeded
                                        clipboardNeeded={this.state.clipboardNeeded}
                                    />
                                    : ''}
                                    
                            </div>
                           
                        </div>
                    </div>
                    </div>

                <div>
                                Guide 
                                <div><span className='last_match_icon'>LM</span> = Last Match For Referee</div>
                                <div>Wait = Wait to Assign (Quick Break)</div>
                                
                            </div>
            </div>
        )
    }
}

const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name,
        zone: reduxState.zone
    }
}

export default connect(mapStateToProps)(BoardListFromParams)