import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import './VolunteerSchedule.css'
import Dialog from '@material-ui/core/Dialog';
import CategorySuggestions from './CategorySuggestions';


const AddEditVolunteerCategories = props => {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [courtMonitor, setCourtMonitor] = useState(false)
    const [show, setShow] = useState(true)
    const [editVcid, setEditVcid] = useState(undefined)
    const [verifyDelete, setVerifyDelete] = useState(false)
    const [deleteError, setDeleteError] = useState('')

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { handleEditCategory() }, [props.edit])

    const addCategory = () => {
        axios.post(`/api/volunteer/addVolunteerCategory/${tid}`, {name, description, courtMonitor, show}).then(
            res => {
                props.getCategories()
                clearState()
            }
        )
    }
    const editCategory = () => {
        axios.put(`/api/volunteer/editVolunteerCategory/${editVcid}`, {tid, name, description, courtMonitor, show}).then(
            res => {
                props.getCategories()
                clearState()
            }
        )
    }
    const deleteCategory = () => {
        setDeleteError('')
        axios.put(`/api/volunteer/deleteVolunteerCategory/${editVcid}`, {tid}).then(
            res => {
                if(res.data === 'registrations exists'){
                    setDeleteError('Can not delete category as there are volunteers registered for shifts in this category.  Please move the volunteers to new shifts.')
                }else{
                props.getCategories()
                clearState()
                }
            }
        ).catch(
            err => {
                console.log(err)
                if(err === 'registrations exists'){
                    setDeleteError('Can not delete category as there are volunteers registered for shifts in this category.')
                }
            }
        )
    }

    const handleEditCategory = () => {
        setName(props.row.name)
        setDescription(props.row.description)
        setCourtMonitor(props.row.court_monitor)
        setShow(props.row.show || false)
        setEditVcid(props.row.vcid)
    }

    const clearState = () => {
        setName('')
        setDescription('')
        setShow(true)
        setCourtMonitor(false)
        setEditVcid(undefined)
    }

    const handleClose = () => {
        clearState()
        props.close()
    }

    return(
        <Dialog  aria-labelledby="simple-dialog-title" open={props.show}>

      
            <div className='add-category'>
                <h3>
                {editVcid? 'Edit Category':'Add Category'}
 
                </h3>
                <h5>Select from options:</h5>
                <CategorySuggestions setName={setName} setDescription={setDescription} setCourtMonitor={setCourtMonitor}/>
                <h5>Or create your own categories:</h5>
                <label htmlFor="">
                    Name: 
                    <input type="text"
                        value={name}
                        onChange={e=>setName(e.target.value)}/>
                </label>
                <label htmlFor="">
                    Description
                    <textarea cols="30" rows="3"
                    value={description}
                    onChange={e=>setDescription(e.target.value)}/>
                    
                </label>
                <label htmlFor="">
                     
                    <input type="checkbox"
                        value={courtMonitor}
                        checked={courtMonitor}
                        onChange={e=>setCourtMonitor(!courtMonitor)}/>
                        Court Monitor - Will this category include court monitors?
                </label>
                <label htmlFor="">
                     
                    <input type="checkbox"
                        value={show}
                        checked={show}
                        onChange={e=>setShow(!show)}/>
                        Category Shifts Viewable to Public
                </label>
                {editVcid ?
                <button onClick={editCategory}>Save Changes</button>:
                <button onClick={addCategory}>Save</button>}
                <button className='button-none' onClick={handleClose}>cancel</button>
                <div>
                <button className='button-none' onClick={()=>setVerifyDelete(!verifyDelete)}>{verifyDelete? 'cancel delete':'delete'}</button>
                {verifyDelete ? 
                <button className='button-verify' onClick={deleteCategory}>Yes Delete Category and All Shifts in Category</button> :''}   
                {verifyDelete ? <p>Shifts can only be deleted if there are no volunteers registered for shifts within the category.</p>:''}
                <h3>{deleteError}</h3>
                </div>
                
            </div>
            </Dialog>
  )
}
export default AddEditVolunteerCategories