import React from 'react'

export default function NotificationButtons(props){
    console.log(props)
    return(
        <button
        className='notification-button'
        style={{background: props.color}}
        onClick={props.set}>{props.title}</button>
    )
}