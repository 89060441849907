import React, { useState } from 'react';
import axios from 'axios'
import { useSelector } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress';

const LookupReferee = (props) => {
    const [referee, setReferee] = useState({})
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)

    const searchForName = async (name) => {

        await setSearch(name)
        if (name.length > 2) {
            getName()
        } else {
            setSearchResults([])
        }
    }

    const getName = () => {
        setLoader(true)
        axios.post(`/api/getRegisteredRefereeName?search=${search}`, { tid }).then(res => {
            setSearchResults(res.data)
            setLoader(false)
        })
    }

    const selectReferee = r => {
        props.addReferee(r)
        setSearch(r.name)
        setSearchResults([])
    }

    const mappedSearchResults = searchResults.map(name => {
        return (
            <div key={name.ref_id} className='search-results'>
                
                <div style={{ cursor: 'pointer' }}
                    onClick={() => selectReferee(name)}>
                    {name.name} - {name.credentials}
                </div>

            </div>
        )
    })


    return (
        <div className="">

            <legend>
                <input
                    type="text"
                    placeholder='Name'
                    value={search}
                    onChange={e => searchForName(e.target.value)} />
            </legend>
            {loader ? <LinearProgress />:''}

            {mappedSearchResults}
        </div>


    )
}
export default LookupReferee;