import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './RefereeNav/RefereeNav.css';

class ProfileNav extends Component {
    constructor() {
        super()
        this.state = {
            toggle: false,
            navHeight: 0
        }
    }


    toggleNav = () => {
        this.setState({
            navHeight: !this.state.navHeight ? 240 : 0
        })
    }



    render() {
        return (
            <div className="App">
                <nav className="navbar-profile" >
                    <div>
                        <span className='menuBurger'
                            onClick={this.toggleNav}
                            onMouseEnter={this.toggleNav}>Menu: &#9776;</span>
                        <div className={this.state.toggle ? "bigList nav-height" : "bigList"}>
                            <ul>
                                <li><Link to={`/refDashboard`} >
                                    Tournament List</Link></li>
                                    <li><Link to={`/tournamentcalendar`} >
                                    Tournament Calendar</Link></li>
                                <li><Link to={`/profile/matchCount`} >
                                    Past Matches</Link></li>
                                <li><Link to={`/profile/signupReport`} >
                                    My Schedule</Link></li>
                                <li><Link to={`/profile/myProfile`} >
                                    Profile</Link></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="menuList"
                    onMouseLeave={() => this.setState({ navHeight: 0 })}
                    style={{ height: this.state.navHeight }}
                >
                    <ul onClick={this.toggleNav}>
                    <li><Link to={`/refDashboard`} >
                                    Tournament List</Link></li>
                                    <li><Link to={`/tournamentcalendar`} >
                                    Tournament Calendar</Link></li>
                                <li><Link to={`/profile/matchCount`} >
                                    Past Matches</Link></li>
                                <li><Link to={`/profile/signupReport`} >
                                    My Schedule</Link></li>
                                <li><Link to={`/profile/myProfile`} >
                                    Profile</Link></li>
                    </ul>
                </div>

            </div>
        );
    }
}

export default ProfileNav;