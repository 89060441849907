import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import RefInfoModal from './RefInfoModal'
import Moment from 'react-moment';


const AssignMagnet = props => {
    const [showRefInfo, setShowRefInfo] = useState(false)
    const [refInfo, setRefInfo] = useState({})

    const tid = useSelector(state => state.tid)

    const { referee, court, medal } = props

    return (
        <div className='court-box-boardView'
            style={{ cursor: 'pointer' }}
            onClick={court && court.ref_id ? () => props.finish(court) : () => props.selectCourt(court.court_num)} >
            <div className='col-4 court-num'>
                <div className='court-name'>
                    <div className={(court && court.court_num) || props.court ?
                        court.length > 2 ? 'shrink-court-num' : '' : ''}>{court || props.court}</div>
                </div>
                {medal === 'Bronze' ?
                    <div className='board-medal'>
                        <i className="fas fa-medal"></i>
                    B
                    </div> :
                    medal === 'Gold' ?
                        <div className='board-medal'>
                            <i className="fas fa-medal"></i>
                        G
                    </div> : ''}
            </div>
            <div className={
                court.credentials_verified === 'Certified' ? 'certified-board col-8 ref-name' : court.credentials_verified === 'Level 2' ? 'level2-board col-8 ref-name' : court.credentials_verified === 'Level 1' ? 'level1-board col-8 ref-name' :
                    court.ref_id ? 'col-8 ref-name volunteer-board' :
                    referee.credentials_verified === 'Certified' ? 'certified-board col-8 ref-name' : referee.credentials_verified === 'Level 2' ? 'level2-board col-8 ref-name' : referee.credentials_verified === 'Level 1' ? 'level1-board col-8 ref-name' :
                    referee.ref_id ? 'col-8 ref-name volunteer-board':
                        'col-8 ref-name'}>
                

                {referee.last_match ? <span className='last_match_icon'>LM</span> : ''}
                {referee.dress_code ? <i className="fas fa-user-check dress_code"></i>:''}
                {referee.first ?
                    <div>{referee.first} {referee.last}
                    </div> : ''}
                    <div>{props.matchStarter}</div>




                <div>
                    {court.first ?
                        <RefInfoModal
                            start_time={court.start_time}
                            ref_id={court.ref_id}
                            tid={tid}
                            board='board'
                            statusHasBeenUpdated={props.statusHasBeenUpdated} />
                        : ''}

                </div>


            </div>
        </div>
    )
}
export default AssignMagnet