import React from 'react'
export function NotifyButtons(props){
    let {handleType} = props
    return(
        <div>
            <button
                className='notification-button second'
                onClick={() => handleType('2nd')}><i className="fas fa-bullhorn"></i>Second Call</button>
            <button
                className='notification-button message-to-desk'
                onClick={() => handleType('desk')}><i className="far fa-comment"></i>Send Message To Referee Desk</button>

            <button
                className='notification-button tournament-director'
                onClick={()=>handleType('td')}>Tournament Director Requested</button>
            <button
                className='notification-button head-ref'
                onClick={()=>handleType('head ref')}>Head Referee Requested</button>
            <button
                className='notification-button medical'
                onClick={()=>handleType('medical')}>
                    <>
                    <i className="fas fa-briefcase-medical" style={{fontSize: '2em'}}> 
                
                </i>
                </>Medical Assistance Needed</button>
        </div>
    )
}