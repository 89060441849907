import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setLineShift } from '../../ducks/reducer'
import Switch from "@material-ui/core/Switch";
import Modal from 'react-responsive-modal';
import LineJudgeCommunication from '../Communication/LineJudgeCommunication'
import { CirclePicker } from 'react-color';
import { toast } from 'react-toastify';



const VolunteerGroups = (props) => {
    const [deleteVgid, setDeleteVgid] = useState('')
    const [emailList, setEmailList] = useState([])
    const [showSendText, setShowSendText] = useState(false)
    const [groups, setGroups] = useState([])
    const [group, setGroup] = useState('')
    const [showEditGroup, setShowEditGroup] = useState(false)
    const [showGroupModal, setShowGroupModal] = useState(false)
    const [editGroup, setEditGroup] = useState({})
    const [newGroup, setNewGroup] = useState('')
    const [selectedName, setSelectedName] = useState({})
    const [selectedGroup, setSelectedGroup] = useState({})
    const [selectedCourt, setSelectedCourt] = useState({})
    const [showCheckInModal, setShowCheckInModal] = useState(false)
    const [textList, setTextList] = useState([])
    const [groupText, setGroupText] = useState(false)
    const [color, setColor] = useState('')
    const [loader, setLoader] = useState(true)
    const [showAddLineJudge, setShowAddLineJudge] = useState(false)

    const tid = useSelector(state => state.tid)
    const shift = useSelector(state => state.lineShift)
    const dispatch = useDispatch();

    useEffect(
        () => { getGroups() }, [shift])
    useEffect(
        () => { saveGroupAssignment() }, [selectedGroup])
    useEffect(
        () => { saveGroupAssignment() }, [selectedName])
    useEffect(
        () => { getGroups() }, [props.volunteers])


    const handleGetAllGroups = () => {
        getGroups()
    }


    const getGroups = () => {
        axios.get(`/api/getVolunteerGroups/${tid}`).then(res => {
            setGroups(res.data)
            setLoader(false)
        })
    }


    const saveGroupName = () => {
        setLoader(true)
        axios.post(`/api/saveVolunteerGroupName/${tid}`, { group_name: newGroup, color: color }).then(res => {
            setGroups(res.data)
            setGroup(newGroup => '')
            setLoader(false)
            setShowGroupModal(false)
        })
    }

    const handleEditGroupName = (g) => {
        setShowGroupModal(showGroupModal => true)
        setShowEditGroup(showEditGroup => true)
        setNewGroup(g.group_name)
        setColor(g.color)
        setEditGroup(g)
    }

    const handleCancelEditGroupName = () => {
        setShowGroupModal(showGroupModal => false)
        setShowEditGroup(showEditGroup => false)
        setNewGroup(newGroup => '')
        setColor('')
        setEditGroup({})

    }

    const editGroupName = () => {
        setLoader(true)
        axios.put(`/api/editVolunteerGroupName/${tid}`, { vgid: editGroup.vgid, group_name: newGroup, sid: shift.sid, color: color }).then(res => {
            handleGetAllGroups()
            setGroup(newGroup => '')
            setColor('')
            setEditGroup({})
            setShowGroupModal(showGroupModal => false)
            setLoader(false)
        })
    }

    const deleteGroup = () => {
        setLoader(true)
        axios.post(`/api/deleteVolunteerGroup/${tid}`, { vgid: deleteVgid }).then(res => {
            handleGetAllGroups()
            setDeleteVgid('')
            setLoader(false)
            props.getVolunteers()
        })
    }

    const removeFromGroup = (ref_id) => {
        setLoader(true)
        axios.put(`/api/removeFromVolunteerGroup/${tid}`, { ref_id }).then(res => {
            if (props.getBoard) { props.getBoard() }
            props.getVolunteers()
            props.setSelectedName('')
            handleGetAllGroups()
            setLoader(false)

        })
    }

    const saveGroupAssignment = () => {
        if (props.selectedName > 0 && selectedGroup.group_name) {
            setLoader(true)
            axios.put(`/api/saveVolunteerGroupAssignment/${tid}`, { vgid: selectedGroup.vgid, ref_id: props.selectedName }).then(res => {
                handleGetAllGroups()
                setSelectedGroup({})
                props.setSelectedName('')
                setLoader(false)
                props.getVolunteers()
            })
        }
    }

    const sendTextToShift = (list) => {
        setTextList(list)
        setShowSendText(true)
        setGroupText(false)
    }

    const sendTextToGroup = (list) => {
        setTextList(list)
        setShowSendText(true)
        setGroupText(true)
    }

    const handleColor = (color, event) => {
        setColor(color.hex)
    }

    const mappedGroups = groups.map((g, i) => {
        const mappedNames = g.signup.map(name => {
            return (
                <div key={'g' + name.ref_id}
                    className='line-name-list'>
                    <div
                        className={name.ref_id === props.selectedName ? 'selected-volunteer' : ''}
                        onClick={() => props.setSelectedName(name.ref_id)}>{name.last}, {name.first}   <i className="fas fa-user-minus" onClick={() => removeFromGroup(name.ref_id)}></i></div>
                </div>
            )
        })
        return (
            <div key={'n' + i}
                className='col-12 col-sm-6 col-md-6'
            >
                <div className={selectedGroup.vgid === g.vgid ? 'line-group selected-group' : 'line-group'} >
                    <div className='line-group-header '
                        style={{ background: g.color || '#abb8c3' }}

                        onClick={() => {
                            selectedGroup.vgid === g.vgid ? setSelectedGroup('')
                                : setSelectedGroup(g)
                        }} >

                        <div className='line-group-title '>
                            {g.group_name}



                        </div>

                        <div>
                            <div >
                                <i onClick={() => handleEditGroupName(g)}
                                    className="fas fa-edit line-group-icons"></i>
                                <i className="far fa-trash-alt line-group-icons"
                                    onClick={() => setDeleteVgid(g.vgid)}></i>
                                <i className="far fa-comment line-group-icons"
                                    onClick={() => sendTextToGroup(g)}></i>
                            </div>

                        </div>



                    </div>
                    <div className='col-12'>

                        {mappedNames}

                    </div>

                </div>
            </div>
        )
    })

    return (
        <div className='container'>
            <div>
                {loader ?
                    <img id='loader' width="100" src={require('../../img/15.gif')} alt='players' /> : ''}
            </div>
            <div className='row' >
                <div className='col-12'
                    style={{ display: 'flex', flexWrap: 'wrap' }}>

                    {mappedGroups}
                </div>
                <div className='col-12'>

                    {showGroupModal ?
                        <div className='add-group'>
                            <h5>To make a new group type the group name and select a color (color is optional).</h5>
                            <div style={{ display: 'flex' }}>

                                <input type="text"
                                    value={newGroup}
                                    style={{ width: '200px', background: color }}
                                    placeholder='add group name'
                                    onChange={(e) => setNewGroup(e.target.value)} />

                                <CirclePicker
                                    onChange={handleColor}
                                    value={color}
                                    triangle='hide' />
                            </div>
                            {showEditGroup ?
                                <div>
                                    <button onClick={editGroupName}>Save Changes</button>
                                    <button onClick={handleCancelEditGroupName}>Cancel</button>
                                </div>
                                :
                                <div>
                                    <button onClick={saveGroupName}>Save Group</button>
                                    <button onClick={handleCancelEditGroupName}>Cancel</button>
                                </div>
                            }

                        </div> :
                        <button onClick={() => setShowGroupModal(showGroupModal => !showGroupModal)}>Add Group</button>}

                </div>
            </div>


            <Modal open={deleteVgid} center showCloseIcon={true} onClose={() => setDeleteVgid(0)} closeOnEsc={true} closeIconSize={2}>
                <div>
                    <h4>Are you sure you want to delete this group?</h4>
                    <p>Anyone assigned to this group will be listed without a group.</p>
                </div>
                <button onClick={deleteGroup}> Delete</button>
                <button onClick={() => setDeleteVgid('')}>cancel</button>
            </Modal>




        </div>

    )
}

export default VolunteerGroups