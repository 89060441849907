import React, { Component } from 'react'
import axios from 'axios'
import { connect } from "react-redux"
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CSVReader from 'react-csv-reader'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header =>
        header
            .toLowerCase()
            .replace(/\W/g, '_')
}

class InviteCSV extends Component {
    constructor() {
        super()
        this.state = {
            referees: [],
            templates: [],
            showTemplateList: false,
            firstSearch: '',
            lastSearch: '',
            nameSearch: '',
            categoryFilter: '',
            email: [],
            emailSubject: '',
            replyEmail: '',
            emailBody: '',
            saveTemplate: false,
            templateName: '',
            loader: false,
            type: ''
        }
    }

    componentDidMount() {
        this.setEmail()
        this.getTemplates()
    }

    handleUpload = data => this.setState({ email: data })

    setEmail = () => this.setState({ replyEmail: this.props.email })

    getTemplates = () => {
        axios.get(`/api/getTemplates/${this.props.ref_id}`).then(
            res => {
                this.setState({ templates: res.data })

            }
        )
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    toggleShowTemplate = () => this.setState({ showTemplateList: !this.state.showTemplateList })


    selectTemplate = (t) => {
        if (t.type === 'text') {
            this.setState({
                showTemplateList: false,
                textBody: t.body

            })
        } else {
            this.setState({
                showTemplateList: false,
                emailSubject: t.subject,
                emailBody: t.body

            })
        }
    }


    sendEmail = () => {
        if (this.state.saveTemplate) { axios.post(`/api/saveTemplate`, { ref_id: this.props.ref_id, name: this.state.templateName, subject: this.state.emailSubject, body: this.state.emailBody, type: 'email' }).then(res => toast.success(`Template Saved`)) }
        axios.post(`/api/emailInviteCSV`, { tournamentName: this.props.name, tid: this.props.tid, email: this.state.email, subject: this.state.emailSubject, reply: this.state.replyEmail, body: this.state.emailBody, author: this.props.ref_id }).then(
            res => {
                toast.success(`Email Queued`)
                this.setState({ email: [], emailSubject: '', replyEmail: '', emailBody: '' })
            }
        )
    }


    render() {

        const mappedEmail = this.state.email.map((ref, i) => {
            return (
                <div key={i} >
                    {ref.email} - {ref.name}
                    {/* <i className="fas fa-minus-circle" onClick={() => this.removeEmail(i)}></i> */}
                </div>
            )
        })

        return (
            <div className='container shadow mb-10 bg-white rounded' >

                <div className='row'>
                    <div className='col-12 email '>
                        <h4>Invite people to signup to Volunteer</h4>
                        <h6>Have a CSV file with emails?  Upload to send invitation</h6>
                        <h6>** Before uploading make sure to format your CSV file.  File needs to include two columns titled: name, email</h6>

                        <div className='row'>

                            <div className='col-12 '>
                                <h4>Email</h4>
                                <RadioGroup aria-label="type" name="type" value={this.state.type} onChange={this.handleChange}
                                    style={{display: 'flex'}}>
                                    <FormControlLabel value="referee" control={<Radio />} label="referee"/>
                                    <FormControlLabel value="line judge" control={<Radio />} label="line judge" />
                                    <FormControlLabel value="volunteer" control={<Radio />} label="volunteer" />
                                </RadioGroup>
                                <legend>To:</legend>
                                <CSVReader
                                    cssClass="react-csv-input"
                                    label="Select CSV to upload"
                                    onFileLoaded={this.handleUpload}
                                    parserOptions={papaparseOptions}
                                />
                                <div className='emailRecipients'>
                                    {mappedEmail}
                                </div>
                                
                                {/* <legend>
                                    Email Subject
                                <input
                                        type="text"
                                        placeholder='Email Subject'
                                        name="emailSubject"
                                        value={this.state.emailSubject}
                                        onChange={this.handleChange} />
                                    <small>*Tournament Name will be added to the end of the subject</small></legend>
                                <legend>
                                    reply to:
                                    <input
                                        type="text"
                                        placeholder='Reply Email'
                                        name="replyEmail"
                                        value={this.state.replyEmail}
                                        onChange={this.handleChange} />
                                </legend>
                                <textarea
                                    type="text"
                                    placeholder='Email Body'
                                    name="emailBody"
                                    style={{ minHeight: '200px' }}
                                    value={this.state.emailBody}
                                    onChange={this.handleChange} />
                                <legend> Save email as template
                                    <input
                                        type="checkbox"
                                        placeholder='Save as template'
                                        name="saveTemplate"
                                        value={this.state.saveTemplate}
                                        onChange={() => this.setState({ saveTemplate: !this.state.saveTemplate })} />
                                    {this.state.saveTemplate ?
                                        <input
                                            type="text"
                                            placeholder='Template Name'
                                            name="templateName"
                                            value={this.state.templateName}
                                            onChange={this.handleChange} /> : ''}
                                </legend> */}
                                <legend>A volunteer invitation including a link to the tournament signup page will be sent to all names on your list.</legend>

                                <button onClick={this.sendEmail}>Send Email</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name,
        email: reduxState.email,
        ref_id: reduxState.ref_id
    }
}
export default connect(
    mapStateToProps,
)(InviteCSV)