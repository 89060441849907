import React from 'react'
import { Link } from 'react-router-dom';

const VolunteerCaptainDashboard = props => {
    return (
        <div className='container'>
            <div className='col-12'>
                <img className='volunteer-dashboard-volunteer'
                    src={'https://res.cloudinary.com/shonda/image/upload/v1582498963/PickleballDesk/Volunteer_he87xg.png'} alt='volunteer' />
                <h2>Volunteer Captain</h2>
                <h5>Thank you for being a volunteer captain</h5>


            </div>
            <div className='row'>


                <div className='col-12'>
                <div className='checklist'>
                        <div className='checklist-number'>
                        </div>
                        <Link to={`/admin`} >
                            <div>
                            Captain Home
                        </div>
                        </Link>
                    </div >
                    <div className='checklist'>
                        <div className='checklist-number'>1
                        </div>
                        <Link to={`/volunteercaptain/scheduling`} >
                            <div>
                                Shift List
                        </div>
                        </Link>
                    </div >


                    <div className='checklist'>
                        <div className='checklist-number'>2
                        </div>
                        <Link to={`/volunteercaptain/managevolunteers`} >
                            <div>
                                Manage Schedule
                                <p><small>Assign volunteers to shifts.  See who is registered to volunteer</small></p>
                            </div>
                        </Link>
                    </div >
                    <div className='checklist'>
                        <div className='checklist-number'>3
                        </div>
                        <Link to={`/volunteercaptain/emailshifts`} >
                            <div>
                                Email Shifts
                                <p><small>Select shifts to email</small></p>
                            </div>
                        </Link>
                    </div >
                    <div className='checklist'>
                        <div className='checklist-number'>4
                        </div>
                        <Link to={`/volunteercaptain/shifsbyday`} >
                            <div>
                               Shifts by day/ Checkin
                                <p><small>Sent emails and text messages.  View daily schedule by volunteer.</small></p>
                            </div>
                        </Link>
                    </div >
                    <div className='checklist'>
                        <div className='checklist-number'>5
                        </div>
                        <Link to={`/volunteercaptain/schedulebyvolunteer`} >
                            <div>
                                Volunteer List By Name and Contact
                                <p><small>Sent emails and text messages.  View daily schedule by volunteer.</small></p>
                        </div>
                        </Link>
                    </div >

                    
                    
                </div >
            </div>
        </div >
    )
}
export default VolunteerCaptainDashboard