import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import CourtName from './CourtName'
import { toast } from 'react-toastify';


const NameCourts = props => {
    const [courts, setCourts] = useState([])

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getCourts() }, [])

    const getCourts = () => {
        axios.get(`/api/getCourtNames/${tid}`).then(res => {
            setCourts(res.data)
        })
    }

    const addCourts = () => {
        axios.post(`/api/t/addCourt`, { tid}).then(
            res => {
                toast.success('Court Added')
                getCourts()
            }
        )
    }

    const mappedCourts = courts.map(c => {
        return (
            <CourtName
                key={c.court_order}
                court={c} />
        )
    })

    return (
        <div className='container justify-content-center'>
            <h2>Court Naming, Description and Color Tool</h2>
            <p>This tool lets you custom name courts, up to 4 characters.  Add description to be included in court assignment text and color code the courts.</p>
            <p>Name with auto-save.  Look for green check mark to verify name has been saved.</p>
            <p>Courts will be listed on your board based on court order number.</p>
            {/* <div style={{color: 'red', padding: '10px'}}>
                Do not rename a court when a referee is assigned to the court
            </div> */}
            {mappedCourts}

            <button onClick={addCourts}>Add Court to Tournament</button>
        </div>
    )

}
export default NameCourts