import React, { Component } from 'react'
import axios from 'axios'
import { connect } from "react-redux"
import io from 'socket.io-client'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'


class RefScheduleModal extends Component {
    constructor() {
        super()
        this.state = {
            ref_id: '',
            schedule: '',
            showEditSchedule: false

        }
    }

    componentDidMount() {
        this.setSocketListeners()
        this.updateStateFromProps()
    }

    setSocketListeners = () => {
        this.socket = io()

        this.socket.emit('joinRoom', `board:${this.props.tid}`)

    }

    updateStateFromProps = () => {
        this.setState({
            ref_id: this.props.referee.ref_id,
            schedule: this.props.referee.schedule,
            first: this.props.referee.first,
            last: this.props.referee.last
        })
    }

    showEditSchedule = () => this.setState({ showEditSchedule: !this.state.showEditSchedule })

    editSchedule = () => {

    }

    saveSchedule = () => {
        axios.put(`/api/editRefSchedule/${this.props.tid}`, { ref_id: this.state.ref_id, schedule: this.state.schedule }).then(

            res => {
                this.setState({

                    schedule: res.data.schedule,
                    showEditSchedule: false
                })
            }
        )
    }

    handleChange = (value) => {
        this.setState({ schedule: value })
    }

    render() {
        return (
            <div className='col-12'>

                {this.state.showEditSchedule ?
                    <div>
                        <ReactQuill
                            className='description'
                            value={this.state.schedule}
                            onChange={this.handleChange} />
                        <button onClick={this.saveSchedule}>Save</button>
                        <button onClick={() => this.setState({ showEditSchedule: false })}>Cancel</button>

                    </div> :
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: this.state.schedule }}></div>
                        <button onClick={this.showEditSchedule}>Edit Notes</button>
                    </div>
                }
            </div>
        )
    }
}
const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid
    }
}


export default connect(
    mapStateToProps,
)(RefScheduleModal);