import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Modal from 'react-responsive-modal';
import NumberFormat from 'react-number-format';
import ItemDetail from './ItemDetail'
import AddBudgetItem from './AddBudgetItem'
import './Budget.css'

const Budget = props => {
    const [budget, setBudget] = useState([])
    const [budgetSubTotals, setBudgetSubTotals] = useState({})
    const [editItem, setEditItem] = useState({})
    const [showAddBudgetItem, setShowAddBudgetItem] = useState(false)
    const [gross, setGross] = useState(0)
    const [net, setNet] = useState(0)

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getBudget() }, [])
    useEffect(
        () => { getBudgetSubTotals() }, [budget])
    useEffect(
        () => { calcIncomeSubtotal() }, [budgetSubTotals])

    const getBudget = () => {
        axios.get(`/api/getBudget/${tid}`).then(res => {
            setBudget(res.data)
        })
    }
    const getBudgetSubTotals = () => {
        axios.get(`/api/getBudgetSubTotals/${tid}`).then(res => {
            setBudgetSubTotals(res.data)
        })
    }
    const calcIncomeSubtotal = () => {
        let g = (+budgetSubTotals.total_income + +budgetSubTotals.total_sponsor - +budgetSubTotals.total_income_deductions)
        let n = (g - +budgetSubTotals.total_expenses)
        setGross(g)
        setNet(n)
    }

    const handleEditBudgetItem = item => {
        setEditItem(item)
        setShowAddBudgetItem(true)
    }

    const handleCloseAddItem = () => {
        setEditItem({})
        setShowAddBudgetItem(false)
    }

    const mappedBudget = budget.map((b, i) => {
        const mappedItems = b.items.map(item => {
            return (

                <ItemDetail
                    key={item.pid}
                    item={item}
                    handleEditBudgetItem={handleEditBudgetItem} />

            )
        })
        return (
            <div key={i}>
                <div className='category'>
                    <div >
                        {b.title}
                    </div>

                </div>
                {mappedItems}
                <div className='categoryTotals'>
                    <div>
                        {b.title} Total:
                    </div>
                    <div className='categoryTotal-amounts'>
                        <NumberFormat value={
                            b.category_id === 1 ? budgetSubTotals.total_income :
                                b.category_id === 4 ?
                                    budgetSubTotals.total_sponsor :
                                    b.category_id === 2 ? budgetSubTotals.total_income_deductions :
                                        b.category_id === 3 ?
                                            budgetSubTotals.total_expenses : ''} displayType={'text'} thousandSeparator={true} prefix={'$'}
                            decimalScale={2}
                            fixedDecimalScale={true} />
                    </div>

                </div>
                {b.category_id === 2 ?
                    <div className='categoryTotals'>
                        <div>
                            Income After Deductions:
                    </div>
                        <div className='categoryTotal-amounts'>
                            <NumberFormat value={gross} displayType={'text'} thousandSeparator={true} prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale={true} />
                        </div>

                    </div> : ''}
            </div>
        )
    })
    return (
        <div className='container '>
            <div className='center-div'>


                <div className='budget col-12 col-md-10 col-lg-8'>
                    <h3>Estimated Budget</h3>
                    <div onClick={() => setShowAddBudgetItem(true)}
                        className='pointer'>
                        <i className="fas fa-plus"> </i>
                        Add Item
                </div>

                    <div className='column-titles'>
                        <div className='item-title-title'>Title</div>
                        <div className='item-rate-title'>Rate</div>
                        <div className='item-rate-title'>Quantity</div>
                        <div className='item-rate-title'>Total</div>
                    </div>

                    {mappedBudget}
                    <div className='categoryTotals'>
                        <div>
                            Estimated Income After Deductions:
                    </div>
                        <div className='categoryTotal-amounts'>
                            <NumberFormat value={net} displayType={'text'} thousandSeparator={true} prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale={true} />
                        </div>

                    </div>
                </div>
                <Modal open={showAddBudgetItem} center showCloseIcon={true} onClose={handleCloseAddItem} closeOnEsc={true} >
                    <h4>Item</h4>
                    <AddBudgetItem
                        getBudget={getBudget}
                        close={handleCloseAddItem}
                        editItem={editItem}
                    />
                </Modal>
            </div>
        </div>
    )
}
export default Budget