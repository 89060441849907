import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import Listing from '../Listing'

const FacilityListings = props => {
    const [tournaments, setTournaments] = useState([])
    const [loader, setLoader] = useState(true)

    useEffect(
        () => { getTournaments() }, [])


    const getTournaments = () => {
        setLoader(true)
        axios.post(`/api/t/getTournamentsByFacility`, {facility: 'legacy'}).then(res => {
            setTournaments(res.data)
            setLoader(false)
        })
    }
    const mappedTournaments = tournaments.map(t => {
        return (
            <div key={t.tid}
                className='col-12 col-md-6 col-lg-4'
                >
                    <div style={{ color: 'black', marginBottom: '20px'}}>
                <Link to={`/signup/${t.tid}`}
                >
                    <Listing
                        t={t} />
                </Link>
                </div>
            </div>
        )
    })

    return (
        <div style={{ background: 'white', padding: '20px' }}>
            <div>
                <img className=''
                    style={{ width: '200px' }}
                    src={'https://res.cloudinary.com/shonda/image/upload/v1641486085/PickleballDesk/Legacy-Sports_Logo-01_fqrke9.png'} alt='app' />
                <h5>Volunteer Opportunities</h5>
                <h3>Legacy Sports USA at Bell Bank Park</h3>

            </div>
            <div className='row justify-content-center' >
                {mappedTournaments}
                {tournaments.length === 0 && !loader ?
                <p>Sorry no tournaments were found</p>:''}
            </div>
        </div>
    )
}
export default FacilityListings

