import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditVolunteer from './EditVolunteer';



const VolunteerNameList = props => {
    const [verify, setVerify] = useState(false)
    const [edit, setEdit] = useState(false)

    const editor = useSelector(state => state.ref_id)

    const removeVolunteer = () => {
        axios.post(`/api/volunteer/removeVolunteerFromTournament`, {tid: props.tid, ref_id:props.name.ref_id}).then(
            res => {
                setVerify(false)
                props.get()
                toast.success('removed')
            }
        )
    }

    const handleEdit = () => setEdit(!edit)

        return (
<TableRow>
              <TableCell component="th" scope="row">
              <button onClick={handleEdit}>Edit</button>
              </TableCell>
              <TableCell align="left">{props.name.name}</TableCell>
              <TableCell align="left">shifts: {props.name.count}</TableCell>
              <TableCell align="left">
                    <button onClick={()=>setVerify(!verify)}>{verify ? 'Cancel':'Remove'}</button>
                    
              </TableCell>
              <TableCell align="left">
                    {verify &&
                    <button className='button-verify' onClick={removeVolunteer}>Yes Remove from all shifts/ tournament</button>}
              </TableCell>
              <EditVolunteer edit={edit} ref_id={props.name.ref_id} close={handleEdit}/>
            </TableRow>
        )
    }

export default VolunteerNameList

