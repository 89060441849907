import React, { Component } from 'react';
import axios from 'axios'
import Moment from 'react-moment';
import { connect } from "react-redux"
import io from 'socket.io-client'
import Modal from 'react-responsive-modal';
import ShiftCheckIn from '../BoardView/ShiftCheckedIn'


import 'moment-timezone';

import './Recap.css'


class Recap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            status: [],
            deleteAll: false
        }

    }

    componentDidMount() {
        this.getStatusUpdates()
        this.setSocketListeners()
    }

    componentWillUnmount() {
        this.socket.disconnect()
    }

    setSocketListeners = () => {
        this.socket = io()

        this.socket.emit('joinRoom', `board:${this.props.tid}`)

        this.socket.on('board', results => {
            this.getStatusUpdates()

        })
    }



    getStatusUpdates = () => {
        axios.get(`/api/getRecap/${this.props.tid}`).then(
            res => {
                this.setState({ status: res.data })
            }
        )
    }

    removeStatus = (id) => {
        axios.post(`/api/removeStatus/${id}`, { tid: this.props.tid }).then(
            res => {
                this.setState({ status: res.data })
            }
        )
    }

    closeDelete = () => this.setState({ deleteAll: false })

    deleteAll = () => {
        axios.post(`/api/removeAllStatus/${this.props.tid}`).then(
            res => {
                this.setState({ status: res.data, deleteAll: false })
            }
        )
    }
    render() {

        const mappedStatus = this.state.status.map((status, i) => {
            return (
                <div key={i} className='col-12 col-md-9 status-update-list'>

                    <div>
                        <i className="fas fa-trash-alt" onClick={() => this.removeStatus(status.id)}></i>
                    </div>
                    <div className='col-4'>
                        {status.last}, {status.first}
                    </div>
                    <div className='col-4'>
                        {status.status}
                        <p>{status.reason}</p>
                    </div>
                    <div>
                        <Moment format="hh:mm A" >
                            {status.date_time}
                        </Moment>
                    </div>

                    <Modal open={this.state.deleteAll} center showCloseIcon={true} onClose={this.closeDelete} closeIconSize={20} closeOnEsc={true} >
                        Are you sure you want to delete all status updates?  Once deleted they can not be recovered.
                        <button onClick={this.deleteAll}>Confirm</button>
                        <button onClick={this.closeDelete}>Cancel</button>
                    </Modal>

                </div>
            )
        }
        )


        return (
            <div className="container-fluid">
                <div className='row'>

                    <div className='col-4'>
                        <div className='Recap-containers'>
                            <h4>Shift Checkin</h4>
                            <ShiftCheckIn />
                        </div>
                    </div>

                    <div className='col-8 '>
                        <div className='Recap-containers'>
                            <h4>Status Updates</h4>
                            {mappedStatus}
                            <button onClick={() => this.setState({ deleteAll: true })}>Remove All</button>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        zone: reduxState.zone
    }
}


export default connect(
    mapStateToProps,
)(Recap);