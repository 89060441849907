import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress'

const RefereeTotalDayAndMatchCount = () => {
    const [list, setList] = useState([])
    const [masterList, setMasterList] = useState([])
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)
    const name = useSelector(state => state.name)

    const classes = useStyles()

    useEffect(() => {
        getInfo()
    }, [tid])


    const getInfo = () => {
        setLoader(true)
        axios.get(`/api/refereeDaysAndMatchTotal/${tid}`).then(res => {
            setList(res.data)
            setMasterList(res.data)
            setLoader(false)
        })
    }

    const sortName = async () => {
        setList([])
        let x = await masterList.sort((a, b) => a.name.localeCompare(b.name))
        setList(x)
    }

    const sortTime = async () => {
        setList([])
        let x = await masterList.sort(function(a, b) {
            return a.time - b.time})
        setList(x)
    }

    const sortCount = async () => {
        setList([])
        let x = await masterList.sort(function(a, b) {
            return a.total_count - b.total_count})
        setList(x)
    }
    const sortLeadCount = async () => {
        setList([])
        let x = await masterList.sort(function(a, b) {
            return a.lead_count - b.lead_count})
        setList(x)
    }
    const sortSupportingCount = async () => {
        setList([])
        let x = await masterList.sort(function(a, b) {
            return a.second_count - b.second_count})
        setList(x)
    }

    const sortAmount = async () => {
        setList([])
        let x = await masterList.sort(function(a, b) {
            return a.total_amount - b.total_amount})
        setList(x)
    }
    const sortLeadAmount = async () => {
        setList([])
        let x = await masterList.sort(function(a, b) {
            return a.lead_amount - b.lead_amount})
        setList(x)
    }
    const sortSupportingAmount = async () => {
        setList([])
        let x = await masterList.sort(function(a, b) {
            return a.second_amount - b.second_amount})
        setList(x)
    }

    return (
        <div className='container-fluid'>
            <h3>Referee Total Days and Match Counts</h3>
            <h5>This includes all matches that the referee was a lead or supporting referee.</h5>
            <p>{name}</p>
            {loader ?
            <LinearProgress color="secondary"/>:''}
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.name} onClick={sortName}>
                            <i className="fas fa-sort pointer"></i>
                                Name</TableCell>
                            <TableCell align="left">
                                email</TableCell>
                            
                            <TableCell align="left" onClick={sortAmount}>
                                Credentials</TableCell>
                                <TableCell align="left">
                                Days</TableCell>
                                <TableCell align="left">
                                Match Count</TableCell>
                                <TableCell align="left">
                                Total Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list
                        .map((row, i) => (

                            <TableRow key={i}>
                                <TableCell>{row.name}</TableCell>

                            <TableCell align="left">
                                {row.email}</TableCell>
                            <TableCell align="left" >{row.credentials}</TableCell>
                            <TableCell align="left">
                                {row.days.length}</TableCell>
                                <TableCell align="left">
                                {row.match_count}</TableCell>
                                <TableCell align="left" className={classes.total}>
                                ${+row.amount + +row.second_amount}</TableCell>
                               
                            </TableRow>
                            
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    )
}
export default RefereeTotalDayAndMatchCount

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    name: {
        minWidth: '200px'
    },
    total: {
        fontWeight: '720'
    }
});