import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Moment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';

const VolunteerContactInfoByDay = props => {
    const [dates, setDates] = useState([])
    const [day, setDay] = useState('')
    const [volunteers, setVolunteers] = useState([])
    const [loader, setLoader] = useState(false)
    const [noData, setNoData] = useState(false)
    const [search, setSearch] = useState('')


    const tid = useSelector(state => state.tid)
    const captain = useSelector(state => state.volunteer_captain)

    const classes = useStyles()

    useEffect(
        () => { getVolunteerDates() }, [tid])
    useEffect(
        () => { getVolunteers() }, [day])


    const getVolunteerDates = async () => {
        await axios.get(`/api/getVolunteerDates/${tid}`).then(
            res => {
                setDates(res.data)
                let today = new Date().toISOString().slice(0, 10)
                setDay(today)
            }
        )
    }
    const getVolunteers = async () => {
        if(!day){return}
        setLoader(true)
        await axios.post(`/api/volunteer/getVolunteerContactByDay/${tid}`, {day}).then(
            res => {
                setLoader(false)
                setVolunteers(res.data)
                if(res.data.length === 0){
                    setNoData(true)
                } else {
                    setNoData(false)
                }
                
            }
        )
    }

    return(
        <div className='container-fluid'>
            {day ?
                            <h3> 
                            <Moment format="ddd MM/DD/YYYY" >
                        {day}
                    </Moment> Volunteer Contact List By Day</h3>:''}
            <div className='tips' style={{textAlign: 'left'}}>
                                Select Date
                                
                            <select
                                name={day}
                                value={day}
                                className='dropdown'
                                onChange={e=>setDay(e.target.value)}
                            >
                                <option key={'s'} value={''}>Select...</option>
                                    {dates.map((day, i)=> (
                                        <option key={i+'o'} value={day.start_time} key={i}>
                                        {day.start_time}
                                        </option>
                                    ))}
                            </select>

                            {`  `}Volunteer Count: {volunteers.length}
                           
                            </div>
                            <label>
                Search by name
                <input value={search} onChange={e=>setSearch(e.target.value)}/>
                <i className="fas fa-ban icon" onClick={()=>setSearch('')}></i>
            </label>
                            
                            {day && loader && <LinearProgress />}
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.name}>First</TableCell>
                            <TableCell className={classes.name}>Last</TableCell>
                            <TableCell align="left"
                                className={classes.phone}>Cell Phone</TableCell>
                            <TableCell align="left"
                                className={classes.phone}>Other Phone</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Emergency Contact</TableCell>
                            <TableCell align="left">Emergency Number</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {volunteers
                        .filter(obj => obj.name.toUpperCase().includes(search.toUpperCase()))
                        .map((row, i) => (

                            <TableRow key={i}>
                            <TableCell align="left">{row.first}</TableCell>
                            <TableCell align="left">{row.last}</TableCell>
                            <TableCell align="left">{row.cell_phone}</TableCell>
                            <TableCell align="left">{row.other_phone}</TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left">
                                {row.emergency_name}
                            </TableCell>
                            <TableCell align="left">
                                    {row.emergency_number}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {noData ? 
            'No Volunteers found for this day':''}
                            

        </div>
    )
}
export default VolunteerContactInfoByDay

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    name: {
        minWidth: '100px'
    },
    phone: {
        minWidth: '150px'
    },
    address: {
        fontSize: '10px',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '200px'
    },
    email: {
        fontSize: '10px'
    },
    shirt: {
        fontSize: '10px',
        minWidth: '200px'
    }
});