import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import Paper from '@material-ui/core/Paper';



const QuillDisplay = props => {
    const [body, setBody] = useState('')

    const classes = useStyles();

    const handleBody = (value) => {
        setBody(value)
    }



    return (
        <Paper className='Paper'>
            <h3>Email</h3>
            
                    <div className={classes.email}>

                        <div>

                            <ReactQuill
                                style={{ background: 'white' }}
                                value={body}
                                onChange={handleBody} />

                        </div>
                        <div>
                            {body}
                        </div>
                    </div>
                </Paper>
    )
}
export default QuillDisplay

const useStyles = makeStyles({
   
    error: {
        color: 'red'
    },
    credentials: {
        maxWidth: '40px'
    },
    count: {
        maxWidth: '50px'
        },
    status: {
        maxWidth: '60px'
        },
    email: {
        width: '98%'
    },
    emailBody: {
        width: '100%',
        margin: '10px'
    }
});
