import React from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { updateTournamentStatus } from '../../ducks/reducer'
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import './Setup.css'
import AdminLandingPage from '../Dashboard/AdminLandingPage'
import TournamentStatus from '../RefDesk/modals/TournamentStatus'
import QRCode from '../Schedule/QRCode';
import TournamentAdmins from './TournamentSetup/TournamentAdmins';



const Setup = (props) => {
    const tid = useSelector(state => state.tid);
    const admin_type = useSelector(state => state.admin_type);
    const state = useSelector(state => state);

    return (
        <div className='container'>
            <div className='col-12'>

                <h2>Home Menu</h2>
                <div className='row'>
                    <div className='col-12'>

                <TournamentStatus />
   

                </div>
                </div>

                <AdminLandingPage />
                {admin_type === 'master' && 
                <>
                <div className='setup-list'>
                    <Link to={`/admin/tournamentSetup`} >

                        <h4>Tournament Listing Setup</h4>
                        <p>Edit tournament name, dates, about, description and Add admin to tournament</p>
                    </Link>

                </div>
                <TournamentAdmins />
                </>}
                <div className='setup-list'>
                    <h4>Tournament URL:</h4>
                    <span>Use this URL in your tournament listing so volunteers know where to sign up</span>
                    <p onClick={() => { navigator.clipboard.writeText(`www.pickleballdesk.com/signup/${tid}`) }}>
                        www.pickleballdesk.com/signup/{tid}
                        <button onClick={() => { navigator.clipboard.writeText(`www.pickleballdesk.com/signup/${tid}`) }}>
                            Copy URL
                        </button>
                    </p>

                    <QRCode />


                </div>
                
                {/* <div className='row'>
                    <div className='col-12 col-md-6 setupMenu' >


                        <div className='setup-list'>
                            <h4>Referee Scheduling
                            </h4>
                            <Link to={`/desk/scheduleSetup`} >
                                <p>Schedule setup and set signup criteria- <small>
                                    when you are ready for volunteers to sign up make sure to change tournament status to active</small></p>
                            </Link>
                            <Link to={`/desk/editSchedule`} >
                                <p>Assign and Remove Referees to Shifts</p>
                            </Link>
                            <Link to={`/desk/printSchedule`} >
                                <p>Print Schedule showing shift name and volunteer signup</p>
                            </Link>
                            <Link to={`/desk/schedulingLog`} >
                                <p>Schedule Log- <small>List of shift signup or removal times for all shifts</small></p>
                            </Link>
                            <Link to={`/admin/sendInvitecsv`} >
                                <p>Send email invitation to sign up using a csv file.</p>
                            </Link>


                        </div>

                        <div className='setup-list'>

                            <Link to={`/desk/refereeSetup`} >
                                <h4>Referee Setup</h4>
                                <p>Add Referees, Edit Referees, Add Barcodes</p>

                            </Link>
                        </div>
                        <div className='setup-list'>

                        </div>
                    </div>
                    <div className='col-12 col-md-6' >
                        <div className='setupMenu' >
                            <div className='setup-list'>
                                <Link to={`/desk/scheduleByRef`} >
                                    <h4>Referee Communication</h4>
                                    <p>Email, Text, View Schedule by Referee</p>
                                </Link>
                                <Link to={'/desk/templateManager'}>
                                    <p>Manage Templates</p>
                                </Link>
                            </div>

                            <div className='setup-list'>
                                <h4>Line Judge
                            </h4>
                                <Link to={`/desk/scheduleSetup`} >
                                    <p>Schedule setup and set signup criteria- <small>
                                        When you are ready for volunteers to sign up, make sure to change tournament status to active</small></p>
                                </Link>
                                <Link to={`/line/linejudgeschedule`} >
                                    <h5>Line Judge Schedule by Line Judge</h5>
                                    <p>Email, Text, View Schedule by Line</p>
                                </Link>
                                <Link to={`/line/editschedule`} >
                                    <h5>Schedule Manager</h5>
                                    <p>Assign and Remove Line Judges to Shifts</p>
                                </Link>
                                <Link to={`/line/linejudge`} >
                                    <h5>Day Of Line Judge Management</h5>
                                    <p>Make groups, assign groups, court assignments</p>
                                </Link>
                                <Link to={`/line/pastMatches`} >
                                    <h5>Past Matches</h5>
                                    <p>Total by line judge and detail of all matches</p>
                                </Link>
                                <Link to={`/line/linecheckin`} target='_blank' rel="noopener noreferrer" >
                                    <h5>Line Judge Checkin</h5>
                                    <p>Make groups, assign groups, court assignments</p>
                                </Link>

                                <Link to={`/desk/schedulingLog`} >
                                    <p>Schedule Log- <small>List of shift signup or removal times for all shifts (this includes referees, line judges,and other)</small></p>
                                </Link>
                                <Link to={`/line/linecontactreport`} >
                                    <h5>Line Judge Contact Report</h5>
                                    <p>Name, phone, emails.</p>
                                </Link>


                            </div>

                            <div className='setup-list'>
                                <h4>Day of Tournament</h4>
                                <h6>**Make sure to turn on text messaging (switch is top left corner of the board view)</h6>
                                <Link to={'/public/checkIn'} target='_blank' rel="noopener noreferrer">
                                    <p>Ref Status/ Check-In <small>- Opens in new tab</small></p>
                                </Link>
                                <Link to={`/public/boardDisplay/${tid}`} target='_blank' rel="noopener noreferrer">
                                    <p>Board Display <small>- For Display only - no functionality</small></p>
                                </Link>
                                <Link to={'/desk/barcodes'}>
                                    <p>Barcodes- Past Matches and Referee List <small>- Past matches on left with all referee's on right</small></p>
                                </Link>
                                <Link to={'/desk/PastBarcodes'}>
                                    <p>Barcodes- Past Matches <small>- 10 most recent matches, shows referee barcodes</small></p>
                                </Link>
                                <Link to={'/desk/RefereeBarcodes'}>
                                    <p>Barcodes- All Referees </p>
                                </Link>
                            </div>
                            <div className='setup-list'>
                                <h4>How-To and Downloads</h4>
                                <a target="_blank" rel="noopener noreferrer"
                                    href="https://www.dropbox.com/s/v9wpi1dw3vt99cv/pickleball%20desk%20how%20to.pdf?dl=0">
                                    <p>Day Of Guide for Referee- PDF</p></a>
                                <span>In case of internet failure: 1. use your phone to operate the board. If that also does not work have this spreadsheet on your computer as a temporary backup.</span>
                                <a target="_blank" rel="noopener noreferrer"
                                    href="https://www.dropbox.com/s/sl03aokori85cxn/PickleballDesk%20Backup%20Spreadsheet.xlsx?dl=0">
                                    <p>Internet failure?  Backup spreadsheet</p></a>
                                <h4>How to videos</h4>
                                <span>Learn everything in 25 min</span>
                                <a target="_blank" rel="noopener noreferrer"
                                    href="https://drive.google.com/open?id=1p_5ZugLBYX9FApF9kmFm9F4wHJ-WYrWk">
                                    <p>Admin Overview</p>
                                </a>
                                <a target="_blank" rel="noopener noreferrer"
                                    href="https://drive.google.com/open?id=1WICJaHMSh1zZZGSv2L2unxw_U2F0juzy">
                                    <p>Scheduling and Setup</p></a>
                                <a target="_blank" rel="noopener noreferrer"
                                    href="https://drive.google.com/open?id=1d_jIeaRfPoKwH9UjiN_2Gw5xN0KM1Y0v">
                                    <p>Board View/ Day Of</p></a>
                                <a target="_blank" rel="noopener noreferrer"
                                    href="https://drive.google.com/open?id=1w41UEMniXAQwTqXjqKtjG4z0Bk5Hm8dy">
                                    <p>Check-In and Past Matches</p></a>
                            </div>

                        </div>
                    </div>
                </div> */}
            </div>
        </div>


    )
}

export default withRouter(Setup);
