import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter'
import Modal from 'react-responsive-modal';


const EditLineJudgeContact = props => {
    const [names, setNames] = useState([])
    const [editLineJudge, setEditLineJudge] = useState(false)
    const [ref_id, setRef_id] = useState(null)
    const [first, setFirst] = useState('')
    const [last, setLast] = useState('')
    const [phone, setPhone] = useState(null)
    const [altPhone, setAltPhone] = useState(null)
    const [email, setEmail] = useState('')
    const [credentials, setCredentials] = useState('')
    const [verifyDelete, setVerifyDelete] = useState(false)

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getNames() }, [])


    const getNames = () => {
        axios.get(`/api/getLineEmailPhone/${tid}`).then(res => {
            setNames(res.data)
        })
    }

    const handleEdit = (info) => {
        setRef_id(info.ref_id)
        setFirst(info.first)
        setLast(info.last)
        setPhone(info.phone)
        setAltPhone(info.alt_phone)
        setEmail(info.email)
        setCredentials(info.credentials)
        setEditLineJudge(true)

    }

    const saveChanges = () => {
        axios.put(`/api/editLineJudge`, { tid, ref_id, first, last, credentials, phone, altPhone, email }).then(res => {
            getNames()
            cancelEdit()
            setEditLineJudge(false)
        })
    }

    const cancelEdit = () => {
        setRef_id(null)
        setFirst('')
        setLast('')
        setEmail('')
        setPhone('')
        setAltPhone('')
        setCredentials('')
        setEditLineJudge(false)
    }

    const deleteFromTournament = () => {
        axios.post(`/api/removeLineJudgeFromTournament/${tid}`, { ref_id }).then(res => {
            getNames()
            setVerifyDelete(false)
            cancelEdit()
            setEditLineJudge(false)
        })
    }

    const columns = [

        {
            Header: 'First',
            accessor: 'first',
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["first"] }),
            filterAll: true

        },
        {
            Header: 'Last',
            accessor: 'last',
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["last"] }),
            filterAll: true


        },
        {
            Header: 'phone',
            accessor: 'alt_phone',
        },
        {
            Header: 'Text Phone',
            accessor: 'phone',
        },
        {
            Header: 'email',
            accessor: 'email',
        },
        {
            Header: 'credentials',
            accessor: 'credentials',
        },
        {
            Header: 'edit',
            accessor: 'ref_id',
            filterAll: true,
            Cell: ({ value, row }) => {
                return (
                    <button onClick={() => handleEdit(row._original)}>Edit</button>
                )
            }

        },
    ]
    return (
        <div className='container-fluid'>
            <ReactTable
                data={names}
                filterable
                defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value}

                className='ReactTable'

                columns={columns}
            />
            <Modal open={editLineJudge} center showCloseIcon={true} onClose={() => setEditLineJudge(false)} closeIconSize={20} closeOnEsc={true} >
                <div>
                    <h6>Edit Info</h6>
                    <h2>{first} {last}</h2>
                    <p>
                        <label> First
                                <input type="text"
                                placeholder='first'
                                value={first}
                                onChange={e => setFirst(e.target.value)} />
                        </label>
                    </p>
                    <p>
                        <label> last
                                <input type="text"
                                placeholder='last'
                                value={last}
                                onChange={e => setLast(e.target.value)} />
                        </label>
                    </p>

                    <p><label> Text Phone
                                <input type="text"
                            placeholder='Phone Num- no spaces'
                            name='text phone'
                            value={phone}
                            maxLength='10'
                            onChange={e => setPhone(e.target.value)} />
                    </label>
                    </p>
                    <p><label> Phone
                                <input type="text"
                            placeholder='Phone Num- no spaces'
                            name='phone'
                            value={altPhone}
                            maxLength='10'
                            onChange={e => setAltPhone(e.target.value)} />
                    </label>
                    </p>
                    <p>
                        <label> Email
                                <input type="text"
                                placeholder='email'
                                value={email}
                                onChange={e => setEmail(e.target.value)} />
                        </label>
                    </p>

                    <legend>Credentials: {credentials}</legend>
                    <label>
                        <input type='radio'
                            checked={credentials === 'Not Rated'}
                            name='credentials'
                            value='Not Rated'
                            onClick={() => setCredentials('Not Rated')}
                        /> Not Rated </label>
                    <label>
                        <input type='radio'
                            checked={credentials === 'Level 1'}
                            name='credentials'
                            value='Level 1'
                            onClick={() => setCredentials('Level 1')}
                        /> Level 1 </label>
                    <label>
                        <input type='radio'
                            value='Level 2'
                            name='credentials'
                            checked={credentials === 'Level 2'}
                            onClick={() => setCredentials('Level 2')}
                        /> Level 2 </label>
                    <label>
                        <input type='radio'
                            checked={credentials === 'Certified'}
                            name='credentials'
                            value='Certified'
                            onClick={() => setCredentials('Certified')}
                        /> Certified </label>
                    <div>
                        <button onClick={saveChanges}><i className="far fa-save"></i>Save</button>
                        <button onClick={cancelEdit}><i className="fas fa-ban" ></i>Cancel</button>
                        <button onClick={setVerifyDelete}>Remove From Tournament</button>


                    </div>
                    {verifyDelete ?
                        <div>
                            <h5>Line judge will be removed from tournament and all shifts they are signed up for.</h5>
                            <button
                                style={{ background: '#EA607B', fontSize: '2em' }}
                                onClick={deleteFromTournament}>Verify Delete</button>
                        </div>
                        : ''}


                </div>
            </Modal>
        </div>
    )
}
export default EditLineJudgeContact