import React from 'react'
import { Link } from 'react-router-dom';
import './Footer.css'


const Footer = () => {

    return (

        <div className='container'>
            <div className='row footer-flex'>
                <div className='col-12 col-md-6'>
                    © Pickleball Desk. All rights reserved.
                 </div>
                <div className='col-12 col-md-6'>
                    
                    <div>
                        <Link to={`/tools/termsofservice`} >
                            Terms of Service & Privacy Policy
            </Link>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Footer