import React, { useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from "react-redux"
import MappedShiftsByTime from './MappedShiftsByTime'
import LinearProgress from '@material-ui/core/LinearProgress'

const ShiftsByTime = props => {
    const [shifts, setShifts] = useState([])
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)

    useEffect(
        () => { getShiftSpots() }, [tid, ref_id, props.view])

 
     const getShiftSpots = () => {
        if(props.view !== 'time'){return}

         setLoader(true)
         axios.post(`/api/getVolunteerSignupByTime/${tid}`, {ref_id}).then(
             res => {
                 setShifts(res.data)
                 setLoader(false)
                 props.getSchedule()
             }
         )
     }

     const getAll = () => {
        getShiftSpots()
        props.getMySchedule()
    }

     const mappedShifts = shifts
        .map((shift, i) => (
            <MappedShiftsByTime 
            key={i}
            shift={shift}
            get={getAll}
            showAll={props.showAll}
            collapse={props.collapse}
            />
        ))

    return(
        <div className='container-fluid'>
            {loader && <LinearProgress />}
             {mappedShifts}

        </div>
    )
}
export default ShiftsByTime