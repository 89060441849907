import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify';


const HeadRefereePhone = props => {
    const [name, setName] = useState('')
    const [number, setNumber] = useState(null)
    const [error, setError] = useState('')

    const tid = useSelector(state => state.tid)


    useEffect(
        () => {
            getInfo()
        }, [])

    const getInfo = () => {
        axios.get(`/api/board/getHeadRefPhone/${tid}`).then(
            res => {
                setNumber(res.data.number)
                setName(res.data.name)
            }
        )
    }

    const update = () => {
        axios.put(`/api/board/editHeadRefPhone/`, { tid, name, number }).then(
            res => {
                toast.success('saved')
            }
        )
    }

    const handlePhone = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setNumber(onlyNums)
            setError('US Phone - 10 numbers')
        } else if (onlyNums.length === 10) { 
            setNumber(onlyNums)
            setError('') }
    }

    return (
        <div >
            <label>
                Head Referee
                <input type="text"
                    value={name}
                    onChange={e => setName(e.target.value)} />
            </label>
            <input type="number"
                placeholder='phone number'
                value={number}
                onChange={handlePhone} />
            <button onClick={update}>Save</button>
            <div style={{ color: 'red' }}>{error}</div>
        </div>
    )
}
export default HeadRefereePhone