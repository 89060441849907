import React, { Component } from 'react'
import axios from 'axios'
import io from 'socket.io-client'
import { connect } from "react-redux"
import { updateTournament, updateQueue, toggleText } from '../../../ducks/reducer'
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper'
import 'moment-timezone';
import '../BoardView.css'
import Assign from './Assign'
import ClipboardNeeded from '../ClipboardNeeded'
import RefManager from '../RefManager'
import RefInfoModal from '../RefInfoModal'
import ShiftCheckedIn from '../BoardView/ShiftCheckedIn'
import FinishMatch from './FinishMatch'
import Reassign from './Reassign'
import RefereeMagnet from '../BoardView/RefereeMagnet'
import SmallMagnet from '../BoardView/SmallMagnet'
import BoardCommunication from '../../Communication/BoardCommunication'
import CheckinShowingShifts from '../CheckinCheckout/CheckinShowingShifts'
import LineJudgeManager from '../../LineJudge/LineJudgeManager'
import DisplayNotifications from '../../DayOfComponents/DisplayNotifications'
import BoardMagnet from './BoardMagnet'
import BoardMagnetDuel from './BoardMagnetDuel'
import SecondReferee from './SecondReferee'
import MedicInfo from './MedicInfo'
import RCPhone from './RCPhone'
import './MasterBoard.css'
import AddCompletedMatch from '../AddCompletedMatch/AddCompletedMatch'
import HeadRefereePhone from './HeadRefereePhone'
import NotificationNameDialog from '../../Communication/CourtNotifications/NotificationNamesDialog'
import { toast } from 'react-toastify'
import FinishMatchStarter from './FinishMatchStarter'
import Delay from './Delay'
import RefreshIcon from '@material-ui/icons/Refresh';


class MasterBoardNew extends Component {
    

    constructor() {
        super()
        this.state = {
            leftSide: [],
            rightSide: [],
            refList: [],
            sideQueue: [],
            court: '',
            assignedRef: { ref_id: '', first: '', last: '' },
            medal: '',
            forfeit: false,
            finishMatch: { ref_id: '', first: '', last: '', court_num: '', id: '', sysid: '', tiebreaker: false, medal: '' },
            clipboardNeeded: [],
            showReassign: false,
            showRefInfo: false,
            refInfo: {},
            resetStartTime: false,
            queueCount: 0,
            sysid: '',
            tiebreaker: false,
            tieSysid: '',
            matchId: null,
            pastCourt: null,
            pastRef: null,
            loader: true,
            showCommunicationModal: false,
            showSchedule: false,
            showRefManager: false,
            showLineJudge: false,
            addSecond: false,
            notifications: [],
            matchStarter: false,
            finishMatchStarterCourt: {}, 
            showFinishMatchStarter: false
        }
    }

    componentDidMount() {
        this.getRightSide()
        this.getLeftSide()
        this.getAvailableRefs()
        this.getSideQueue()
        this.getClipboardNeeded()
        this.setSocketListeners()
        this.updateState()
        this.getNotifications()
    }



    componentWillUnmount() {
        this.socket.disconnect()
    }

    setSocketListeners = () => {
        this.socket = io()

        this.socket.emit('joinRoom', `board:${this.props.tid}`)
        this.socket.emit('joinRoom', `notification:${this.props.tid}`)

        this.socket.on(`board`, results => {
            // console.log(101, results)
            if (results.msg === 'text') {
                this.props.toggleText({ toggleText: results.data })
            }
            else if (results.msg === 'second') {
                this.getAll()
            }
            else if (results.msg === 'assign') {
                let l = [...this.state.leftSide]
                let li = l.map(e => e.court_num).indexOf(results.court.court_num)
                if(li >= 0){
                l[li] = results.court
                this.setState({leftSide: l})
                }else {
                    let r = [...this.state.rightSide]
                    let ri = r.map(e => e.court_num).indexOf(results.court.court_num)
                    if(ri >= 0){
                        r[ri] = results.court
                        this.setState({rightSide: r})
                        }
                }

                let q = [...this.state.refList]
                let qi = q.map(e => e.ref_id).indexOf(results.court.ref_id)
                if(qi >= 0){
                q.splice(qi, 1)
                this.setState({refList: q})
                }else {

                let s = [...this.state.sideQueue]
                let si = s.map(e => e.ref_id).indexOf(results.court.ref_id)
                if(si >= 0){
                s.splice(si, 1)
                this.setState({sideQueue: s})
                }
            }
            }
            else if (results.msg === 'finish') {
                let l = [...this.state.leftSide]
                let li = l.map(e => e.court_num).indexOf(results.court.court_num)
                if(li >= 0){
                l[li] = results.court
                this.setState({leftSide: l})
                }else {
                    let r = [...this.state.rightSide]
                    let ri = r.map(e => e.court_num).indexOf(results.court.court_num)
                    if(ri >= 0){
                        r[ri] = results.court
                        this.setState({rightSide: r})
                        }
                }
                let index = this.state.refList.map(e => e.ref_id).indexOf(results.referee.ref_id)
                if(index > -1)return
                let q = [...this.state.refList]
                q = q.concat(results.referee)
                this.setState({refList: q})
            }
            else if (results.msg === 'match starter') {
                let q = [...this.state.refList]
                let qi = q.map(e => e.ref_id).indexOf(results.ref_id)
                if(qi >= 0){
                q[qi].note = results.note
                this.setState({refList: q})
                }else {
                    let aux = [...this.state.sideQueue]
                let auxI = aux.map(e => e.ref_id).indexOf(results.ref_id)
                if(auxI >= 0){
                aux[auxI].note = results.note
                this.setState({sideQueue: q})

                }

            }
        }
            else if (results.msg === 'swapQueue') {
                this.handleSwapQueue(results.referee)
            }
            else if (results.msg === 'getCourtsOnly') {
                this.getRightSide()
                this.getLeftSide()
            }
            else {
                this.getAll()
            }
        })
        this.socket.on(`notification`, results => {
            this.getNotifications()
        })
    }

    getNotifications = () => {
        axios.get(`/api/getNotifications/${this.props.tid}`).then(res => {
            this.setState({ notifications: res.data })
        })
    }

    completeNotification = () => {
            this.getNotifications()
            this.socket.emit('notification', { room: `notification:${this.props.tid}` })
    }

    updateState = () => this.setState({ queueCount: this.props.queue })

    updateBoard = () => {
        this.getAll()
        this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'all' })
    }

    getAll = () => {
        this.getRightSide()
        this.getLeftSide()
        this.getSideQueue()
        this.getAvailableRefs()
        this.getClipboardNeeded()
    }

    getAssign = () => {
        this.getRightSide()
        this.getLeftSide()
        this.getSideQueue()
        this.getAvailableRefs()
    }

    getFinish = () => {
        this.getRightSide()
        this.getLeftSide()
        this.getAvailableRefs()
        this.getClipboardNeeded()
    }

    socketFinish = (court, referee) => {
        this.getFinish()
        this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'finish', court, referee })
    }

    socketFinishSecond = (court, referee) => {
        this.getFinish()
        this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'second', court, referee })
    }

    getLeftSide = async () => {
        this.setState({ loader: true })

        await axios.get(`/api/board/getLeftSide/${this.props.tid}`).then(

            res => {
                this.setState({ leftSide: res.data, loader: false })
            }
        )
    }

    getRightSide = async () => {

        await axios.get(`/api/board/getRightSide/${this.props.tid}`).then(

            res => {
                this.setState({ rightSide: res.data })
            }
        )
    }
    getAvailableRefs = () => {
        axios.get(`/api/getAvailableRefs/${this.props.tid}`).then(

            res => {
                this.setState({ refList: res.data })
            }
        )
    }

    getSideQueue = () => {
        axios.get(`/api/getSideQueue/${this.props.tid}`).then(

            res => {
                this.setState({ sideQueue: res.data })
            }
        )
    }

    showSecondReferee = () => this.setState({ addSecond: !this.state.addSecond })



    getClipboardNeeded = async () => {

        await axios.get(`/api/getClipboardNeeded/${this.props.tid}`).then(

            res => {
                this.setState({ clipboardNeeded: res.data })
            }
        )
    }

    selectCourt = async (court, msId, msName) => {
        if(msId){
            this.setState({showFinishMatchStarter: true, finishMatchStarterCourt: {court, match_starter_id: msId, match_starter_name: msName}})
        }
        else if(this.state.matchStarter){
            this.assignMatchStarter(court)
        }else {
        await this.setState({ court: court, finishMatch: '' })
        }
    }

    selectRef = async (ref) => {
        if(ref.note === 'Starting Match'){
            this.setState({showFinishMatchStarter: true, finishMatchStarterCourt: {court: '-', match_starter_id: ref.ref_id, match_starter_name: ref.name, credentials_verified: ''}})
        }else {
        await this.setState({ assignedRef: ref })
        }

    }
    handleAssign = (court) => {
        this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'assign', court})
        this.getAssign()
        this.clearAssign()
    }
    handleReAssign = (court) => {
        this.socket.emit('board', { room: `board:${this.props.tid}`})
        this.getAssign()
        this.clearAssign()
    }

    queueCount = () => {
        axios.put(`/api/queueCount/${this.props.tid}`, { queue: this.state.queueCount }).then(
            res => {
                this.props.updateQueue({ queueCount: res.data.queue })
                this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'queue', data: res.data.queue })
            }
        )
    }

    reAssign = () => {
        // this.clearAssign()
        this.setState({
            showReassign: true,
            matchId: this.state.finishMatch.id,
            court: this.state.finishMatch.court_num,
            assignedRef: this.state.finishMatch,
            pastCourt: this.state.finishMatch.court_num,
            pastRef: this.state.finishMatch.ref_id
        })
    }

    clearAssign = () => this.setState({
        court: '',
        assignedRef: {},
        sysid: '',
        tiebreaker: false,
        tieSysid: '',
        matchId: null,
        pastCourt: null,
        loader: false,
        addSecond: false,
        finishMatch: { ref_id: '', first: '', last: '', court_num: '', id: '', sysid: '', tiebreaker: false, medal: '' },
        pastRef: null,
        reassign: false,
        showReassign: false,
        showFinishMatchStarter: false,
        finishMatchStarterCourt: {}
    })

    tiebreaker = (match) => {
        let { ref_id, start_time, court_num } = match
        axios.post(`/api/tiebreakermatch/${this.props.tid}`, {
            ref_id, start_time, court_num, sysid: this.state.tieSysid
        }).then(
            res => {

            }
        )
    }

    clipboardNeeded = async () => {
        axios.put(`/api/clipboardNeeded/${this.props.tid}`, { id: this.state.finishMatch.current_match }).then(
            res => {
                this.setState({
                    finishMatch: {}
                })
            }
        ).then(this.updateBoard)
    }

    clipboardReceived = async (id, ref_id) => {
        axios.put(`/api/clipboardReceived/${id}`, { ref_id: ref_id, tid: this.props.tid }).then(
            res => {
                this.updateBoard()
            }
        )
    }

    swapQueue = (ref_id) => {
        this.setState({ loader: true })
        axios.put(`/api/swapQueue/${ref_id}`, { tid: this.props.tid }).then(
            res => {
                // this.handleSwapQueue(res.data[0])
                //this is making it get twice...
                this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'swapQueue', referee: res.data[0] })
                
                this.setState({ loader: false })
            }
        )
    }

    handleSwapQueue = (referee) => {
        if(referee.side_queue){
            let sq = [...this.state.sideQueue]
            sq = sq.concat(referee)
            this.setState({sideQueue: sq})

            let rl = [...this.state.refList]
            let ri = rl.map(e => e.ref_id).indexOf(referee.ref_id)
            if(ri >= 0){
                rl.splice(ri, 1)
            this.setState({refList: rl})
            }


        }else {
            let rl = [...this.state.refList]
            rl = rl.concat(referee)
            this.setState({refList: rl})
            let sq = [...this.state.sideQueue]
            let si = sq.map(e => e.ref_id).indexOf(referee.ref_id)
            if(si >= 0){
                sq.splice(si, 1)
            this.setState({sideQueue: sq})
            }
        }
    }

    handleSysid = async () => {
        this.setState({ loader: true })
        axios.put(`/api/addSysid/${this.state.finishMatch.id}`, { sysid: this.state.sysid }).then(
            res => {
                this.getLeftSide()
                this.getRightSide()
                this.setState({ sysid: '', loader: false })
            }
        )
    }

    assignMatchStarter = (court) => {
        if(!this.state.assignedRef.ref_id){return toast.error('select referee before assigning as match starter')}
        axios.post(`/api/board/assignRefereeMatchStarter`, {tid: this.props.tid, ref_id: this.state.assignedRef.ref_id, court, name: this.state.assignedRef.name}).then(res => {
            let q = [...this.state.refList]
                let qi = q.map(e => e.ref_id).indexOf(this.state.assignedRef.ref_id)
                if(qi >= 0){
                q[qi].note = 'Starting Match'
                this.setState({refList: q})
                }else {
                    let aux = [...this.state.sideQueue]
                let auxI = aux.map(e => e.ref_id).indexOf(this.state.assignedRef.ref_id)
                if(auxI >= 0){
                aux[auxI].note = 'Starting Match'
                this.setState({sideQueue: q})

                }
            }
                this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'all', note: 'Starting Match', ref_id: this.state.assignedRef.ref_id })
                this.setState({ assignedRef: {}, matchStarter: false, finishMatchStarterCourt: {}, showFinishMatchStarter: false })
            
        })
    }
    finishMatchStarter = () => {
        axios.put(`/api/board/finishRefereeMatchStarter`, {tid: this.props.tid, ref_id: this.state.finishMatchStarterCourt.match_starter_id}).then(res => {
            let q = [...this.state.refList]
                let qi = q.map(e => e.ref_id).indexOf(this.state.assignedRef.ref_id)
                if(qi >= 0){
                q[qi].note = ''
                this.setState({refList: q})
                }else {
                    let aux = [...this.state.sideQueue]
                let auxI = aux.map(e => e.ref_id).indexOf(this.state.assignedRef.ref_id)
                if(auxI >= 0){
                aux[auxI].note = ''
                this.setState({sideQueue: q})

                }
            }
                this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'all', note: '', ref_id: this.state.assignedRef.ref_id })
                this.setState({ assignedRef: {}, matchStarter: false, finishMatchStarterCourt: {}, showFinishMatchStarter: false })
        })
    }


    saveMatchChange = () => {
        this.getLeftSide()
        this.getRightSide()
        this.setState({ finishMatch: {} })
        this.socket.broadcast.emit('board', { room: `board:${this.props.tid}`, msg: 'getCourtsOnly' })
    }



    closeRefInfo = () => {
        this.setState({
            showRefInfo: false,
            assignedRef: { ref_id: '', first: '', last: '' }
        })
    }

    closeSchedule = () => {
        this.getAll()
        this.setState({ showSchedule: false })
    }

    showRefManager = async () => {
        await this.setState({ showRefManager: !this.state.showRefManager })
        window.scrollTo(0, 1000)
    }

    refInfoModal = () => { }

    verifyResetStartTime = () => {
        this.setState({ resetStartTime: !this.state.resetStartTime })
    }

    resetStartTime = () => {
        this.setState({ loader: true })

        axios.put(`/api/resetStartTime/${this.props.tid}`).then(

            res => {
                this.setState({ resetStartTime: false, loader: false })
                this.socketFinish()
            }
        )
    }

    showCommunicationModal = () => this.setState({ showCommunicationModal: !this.state.showCommunicationModal })

    clearState = () => this.setState({
        court: '',
        assignedRef: { ref_id: '', first: '', last: '' },
        medal: '',
        finishMatch: {},
        showReassign: false,
        sysid: '',
        tiebreaker: false,
        tieSysid: '',
        matchId: null,
        pastCourt: null,
        medal: '',
        loader: false
    })

    statusHasBeenUpdated = () => {
        this.getRightSide()
        this.getLeftSide()
        this.getSideQueue()
        this.getAvailableRefs()
        this.socket.emit('board', { room: `board:${this.props.tid}` })
    }

    selectFinishMatch = court => {
        if(court.match_starter_id){
            this.setState({finishMatchStarterCourt: court, showFinishMatchStarter: true})
        }
        else {
        this.setState({ finishMatch: court, court: '', medal: court.medal, sysid: court.sysid, paid: court.paid, amount: court.amount })
        }
    }


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    render() {

        const mappedLeftSide = this.state.leftSide.map((court, i) => {
            return (
                <div className='col-12 col-md-6'  key={'l' + i}>
                    {court.second ?
                        <BoardMagnetDuel
                           
                            court={court}
                            finish={this.selectFinishMatch}
                            selectCourt={this.selectCourt}
                            stateCourt={this.state.court}
                            statusHasBeenUpdated={this.statusHasBeenUpdated} />
                        :
                        <BoardMagnet
                            court={court}
                            finish={this.selectFinishMatch}
                            selectCourt={this.selectCourt}
                            stateCourt={this.state.court}
                            statusHasBeenUpdated={this.statusHasBeenUpdated} />}
                </div>
            )
        })

        const mappedRightSide = this.state.rightSide.map((court, i) => {
            return (
                <div className='col-12 col-md-6' key={'r' + court.court_num}>
                    {court.second ?
                        <BoardMagnetDuel
                            court={court}
                            finish={this.selectFinishMatch}
                            selectCourt={this.selectCourt}
                            stateCourt={this.state.court}
                            statusHasBeenUpdated={this.statusHasBeenUpdated} />
                        :
                        <BoardMagnet
                            court={court}
                            finish={this.selectFinishMatch}
                            selectCourt={this.selectCourt}
                            stateCourt={this.state.court}
                            statusHasBeenUpdated={this.statusHasBeenUpdated} />}
                </div>
            )
        })

        const mappedRefs = this.state.refList.map((referee, i) => {

            return (
                <div key={referee.ref_id} className={this.state.assignedRef.ref_id === referee.ref_id ? 'selectedMagnet' : ''}>

                    <RefereeMagnet
                        key={'x'+i}
                        referee={referee}
                        selectRef={this.selectRef}
                        swapQueue={this.swapQueue}
                        zone={this.props.zone}
                        openRefInfo={this.openRefInfo}
                        statusHasBeenUpdated={this.statusHasBeenUpdated} />
                </div>

            )
        })

        const mappedSideQueue = this.state.sideQueue.map((ref, i) => {

            return (
                <div className={this.state.assignedRef.ref_id === ref.ref_id ? 'selectedMagnet' : ''}>
                    <SmallMagnet
                        key={'rrm' + i}
                        referee={ref}
                        selectRef={this.selectRef}
                        swapQueue={this.swapQueue}
                        zone={this.props.zone}
                        openRefInfo={this.openRefInfo}
                        statusHasBeenUpdated={this.statusHasBeenUpdated} />
                </div>
            )
        })

        return (
            <div className='container-fluid'>

                <div className='row'>
                    <div className='col-12 tips' style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                        <div>
                            <legend>
                                Self Checkout available if queue is greater than
                            <input
                                    type="integer"
                                    name="queueCount"
                                    value={this.state.queueCount}
                                    onChange={this.handleChange}
                                    style={{ width: '3em' }}
                                />
                                <button onClick={this.queueCount}>Save</button>
                            </legend>
                            {/* <Delay/> */}
                        </div>
                        <div >
                            <Link to={`/desk/pastNotifications`} target='_blank' rel="noopener noreferrer" >
                                <button className='register-button'>
                                <i className="fas fa-bell" style={{fontSize: '20px'}}></i>Notification Log
                                </button>
                            </Link>
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <RCPhone />
                            <MedicInfo />
                            <HeadRefereePhone/>
                            <NotificationNameDialog />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-2'>
                        <button onClick={() => this.setState({ showLineJudge: true })}>Assign Line Judge Group</button>
                    </div>
                    <div className='col-8'>
                        <p>Assign court - click select referee then court number. </p>
                        <p>Assign New Referee to match or Finish Match - click on court and Click Finish</p>
                    </div>
                    <div className='col-2'>
                        <button onClick={this.verifyResetStartTime}>Reset all court start times</button>

                    </div>
                    <div className='col-12'>
                    {this.state.matchStarter ? <h3>Select the match starter court</h3>:''}    
                    </div>

                    {/* <div className='col-12 col-sm-9 board' > */}


                    <div className='col-6 col-sm-3 order-1 order-sm-1 court-list'>
                        <div className='row no-gutters'>
                            {mappedLeftSide}
                        </div>
                        <AddCompletedMatch />
                    </div>
                    <div className='col-12 col-sm-3 order-3 order-sm-2'>
                        {this.state.loader ?
                            <img id='loader' width="100" src={require('../../../img/15.gif')} alt='players' /> : ''}
                        <div style={{ paddingBottom: '10px' }}>
                            <Paper elevation={3}>

                            <DisplayNotifications 
                                    notifications={this.state.notifications}
                                    getNotifications={this.getNotifications}
                                    completeNotification={this.completeNotification}/>

                            </Paper>
                        </div>
                        <div className='assign-box'>
                            {this.state.matchStarter ? 
                            <div className='match-starter-header'>
                                <h3>Select court for match starter</h3>
                                
                            </div>  :null  
                        }
                            {this.state.addSecond ?
                                <SecondReferee
                                    referee={this.state.assignedRef}
                                    clear={this.clearAssign}
                                    court={this.state.finishMatch}
                                    getAll={this.getAll}
                                    hide={this.showSecondReferee}
                                    socket={this.handleReAssign}
                                /> : ''}

                            {this.state.showFinishMatchStarter?
                            <FinishMatchStarter 
                            court={this.state.finishMatchStarterCourt.court}
                            assignedRef={this.state.finishMatchStarterCourt}
                            matchStarter={this.state.finishMatchStarterCourt.match_starter_name}
                            finishMatchStarter={this.finishMatchStarter}
                            clear={this.clearAssign}/>
                            :null
                            }
                            
                            {(this.state.court || this.state.assignedRef.ref_id > 0) && !this.state.showReassign && !this.state.addSecond && this.state.assignedRef.note !== 'Starting Match' && !this.state.matchStarter ?
                                <Assign
                                    assign={this.assign}
                                    reassign={this.state.showReassign}
                                    get={this.handleAssign}
                                    assignedRef={this.state.assignedRef}
                                    clearAssign={this.clearAssign}
                                    court={this.state.court}
                                    handleChange={this.handleChange}
                                    medal={this.state.medal}
                                    sysid={this.state.sysid}
                                /> : ''}
                            {this.state.finishMatch.first && !this.state.showReassign && !this.state.addSecond ?
                                < FinishMatch
                                    clearState={this.clearState}
                                    saveMatchChange={this.saveMatchChange}
                                    clipboardNeeded={this.clipboardNeeded}
                                    court={this.state.finishMatch}
                                    socketFinish={this.socketFinish}
                                    socketFinishSecond={this.socketFinishSecond}
                                    // handleChange={this.handleChange}
                                    // handleFinishMatch={this.handleFinishMatch}
                                    // handleSysid={this.handleSysid}
                                    // forfeit={this.state.forfeit}
                                    // medal={this.state.medal}
                                    reAssign={this.reAssign}
                                    // saveChanges={this.saveChanges}
                                    // sysid={this.state.sysid}
                                    // tiebreaker={this.state.tiebreaker}
                                    // tieSysid={this.state.tieSysid}
                                    showSecondReferee={this.showSecondReferee}
                                />
                                : ''}
                            {this.state.showReassign ?
                                <Reassign
                                    matchId={this.state.matchId}
                                    assignedRef={this.state.assignedRef}
                                    clearState={this.clearState}
                                    court={this.state.court}
                                    get={this.handleReAssign}
                                    pastCourt={this.state.pastCourt}
                                    pastRef={this.state.pastRef}
                                />
                                : ''}

                        </div>
                        <button onClick={this.getAll}>                                
                                <RefreshIcon />
                                Refresh Board
                            </button>
                        <Paper elevation={3}>
                            
                            <div className='board-available-refs'>
                                <h3 className='queue-name'>
                                    Queue ({this.state.refList.length})</h3>
                                <div className='board-ref-list' style={{ padding: '10px ' }}>
                                    {mappedRefs}

                                </div>

                            </div>
                        </Paper>
                    </div>

                    <div className='col-6 col-sm-3 order-2 order-sm-3 court-list'>
                        <div className='row no-gutters'>
                            {mappedRightSide}
                        </div>
                        <button style={{border: '2px solid #009CAD', background: 'white', padding: '5px', marginTop:'10px'}} className='button-none' onClick={()=>this.setState({matchStarter: !this.state.matchStarter})}>{this.state.matchStarter ? 'Cancel Match Starter':'Match Starter'}</button>
                        <p><small>
                        Match Starter how to: select referee, then click 'Match Starter' button then select court
                        </small></p>
                        

                    </div>
                    {/* </div> */}
                    <div className='col-12 col-sm-3 order-4' >

                        <Paper elevation={3}>
                            <div>
                                <div className='queue-name' style={{ marginBottom: '10px' }}>
                                    <h3 >Aux Queue ({this.state.sideQueue.length})</h3>
                                    <span ><small>Example: waiting for a match or available if needed.</small></span>
                                </div>
                                <div className='' style={{ paddingBottom: '10px' }}>

                                    {mappedSideQueue}

                                </div>
                            </div>
                        </Paper>
                        <Paper elevation={3}>
                            <div className='' style={{ paddingBottom: '10px', marginTop: '20px' }}>
                                <div className='queue-name'>
                                    <h4 >OnDeck </h4>
                                    <div>Referees who have checked-In</div>
                                </div>
                                <ShiftCheckedIn
                                    updateBoard={this.updateBoard} />
                            </div>
                        </Paper>
                    </div>


                    <Modal open={this.state.resetStartTime} center showCloseIcon={true} onClose={this.verifyResetStartTime} closeIconSize={20} closeOnEsc={true} >
                        <div>
                            <p>Are you sure you want to re-set all match start times to now?

                            This will update all matches that are currently on court.  The queue will not be affected.
                                        </p>
                            <button onClick={this.resetStartTime}>Confirm Re-set Start Times</button>
                        </div>
                    </Modal>

                    <Modal open={this.state.showRefInfo} center showCloseIcon={true} onClose={this.closeRefInfo} closeIconSize={20} closeOnEsc={true} >
                        <div>

                            <RefInfoModal
                                refInfo={this.state.refInfo}
                                closeModal={this.closeRefInfo}
                                tid={this.props.tid}
                                statusHasBeenUpdated={this.statusHasBeenUpdated} />
                        </div>
                    </Modal>
                    <Modal open={this.state.showLineJudge} center showCloseIcon={true} onClose={() => this.setState({ showLineJudge: false })} closeOnEsc={true} >
                        <div>

                            <LineJudgeManager />
                        </div>
                    </Modal>


                </div>
                <div className='row'>
                    <div className='col-6'>
                        {this.state.clipboardNeeded.length > 0 ?
                            <ClipboardNeeded
                                clipboardNeeded={this.state.clipboardNeeded}
                                clipboardReceived={this.clipboardReceived} /> : ''}
                    </div>


                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='lineBreak'>
                            <h4>Referee Manager</h4>
                        </div>
                        <button className='refManager-button' onClick={this.showRefManager}>

                            {this.state.showRefManager ? 'Close Referee Manager' : 'Referee Manager'}</button>
                        <button className='refManager-button' onClick={() => this.setState({ showSchedule: true })}>Show Shifts- List</button>
                        <Link to={`/desk/scheduleTable`} >
                            <button className='refManager-button'>Show Shifts- Table </button>
                        </Link>
                        {this.state.showRefManager ?
                            <RefManager
                                getAvailableRefs={this.getAvailableRefs} /> : ''}
                        
                    </div>

                </div>

                <Modal open={this.state.showCommunicationModal} center showCloseIcon={true} onClose={this.showCommunicationModal} closeIconSize={20} closeOnEsc={true} >
                    <div>

                        <BoardCommunication />
                    </div>
                </Modal>
                <Modal open={this.state.showSchedule} center showCloseIcon={true} onClose={this.closeSchedule} closeIconSize={20} closeOnEsc={true} >
                    <div>
                        <CheckinShowingShifts
                            closeSchedule={this.closeSchedule} />
                    </div>
                </Modal>


            </div >
        )
    }
}
const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name,
        zone: reduxState.zone,
        text: reduxState.text,
        queue: reduxState.queue
    }
}


export default connect(mapStateToProps, { updateTournament, updateQueue, toggleText })(MasterBoardNew)