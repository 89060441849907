import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import io from 'socket.io-client'
import './NotifyDesk.css'
import CourtMonitorBoard from '../Volunteers/CourtMonitorBoard'
import NotificationButtons from './NotificationButtons';
import CircularProgress from '@material-ui/core/CircularProgress';


const MonitorNotifyDesk = props => {
    const [type, setType] = useState('')
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [secondCall, setSecondCall] = useState(false)
    const [sendMessage, setSendMessage] = useState(false)
    const [selectedCourt, setSelectedCourt] = useState({})
    const [leftCourts, setLeftCourts] = useState([])
    const [rightCourts, setRightCourts] = useState([])
    const [loader, setLoader] = useState(false)

    const ref_id = useSelector(state => state.ref_id)

    const socket = io()

    let { tid } = props

    useEffect(
        () => { getBoard() }, [props.verified === 'yes'])


    const getBoard = async () => {
        if (props.verified === 'yes') {
            await axios.get(`/api/getCourtMonitorBoard/${tid}`).then(

                res => {
                    setLeftCourts(res.data[0].left)
                    setRightCourts(res.data[0].right)
                }
            )
        }
    }

    const notifyDesk = async () => {
        if (!selectedCourt.court_num) { return toast.error('Select Court Number') }
        setLoader(true)
        axios.post(`/api/courtMonitorNotifyDesk`, { type, tid, ref_id, court_num: selectedCourt.court_num, message }).then(
            res => {
                setLoader(false)
                if (res.data === 'Court Number Needed') {
                    toast.error('Select Court Number and re-submit')
                } else {
                    setType('')
                    setTitle('')
                    setSelectedCourt({})
                    setMessage('')
                    setSendMessage(false)
                    setSecondCall(false)
                    toast.success('Message Sent, Your Phone Number has been provided')
                    socket.emit('notification', { room: `notification:${tid}` })
                }
            }).catch(err=> console.log(err))
    }

    const handleType = (type, title) => {
        setType(type)
        setTitle(title)
    }

    const handleCancel = () => {
        setType('')
        setTitle('')
        setMessage('')
    }


    const mappedLeft = leftCourts.map(c => {

        return (
            <CourtMonitorBoard
                key={c.court_num}
                c={c}
                setSelectedCourt={setSelectedCourt}
                selectedCourt={selectedCourt} />
        )
    })
    const mappedRight = rightCourts.map(c => {

        return (
            <CourtMonitorBoard
                key={c.court_num}
                c={c}
                setSelectedCourt={setSelectedCourt}
                selectedCourt={selectedCourt} />
        )
    })


    return (
        <div className='row'>
            <div className='col-12'>
                <div style={{ background: '#009CAE', padding: '5px', marginBottom: '10px' }}>
                    <h4>Court Monitor Notify Desk</h4>
                    <p>If you need to notify the desk select the court number then click the notification button.</p>
                </div>

            </div>
            <div className='col-12 col-md-6'
                style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                <div className='col-6' style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {mappedLeft}
                </div>
                <div className='col-6' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    {mappedRight}
                </div>



            </div>
            <div className='col-12 col-md-6'>
                
                {!selectedCourt.court_num ?
                    <div style={{ fontSize: '1.3em', color: 'red' }}>
                        Click on court to select what court number message is for
            </div> :
                    <div style={{ fontSize: '1.3em', color: 'red' }}>
                        Court Number : {selectedCourt.court_num}
                    </div>}
                    {title && <button className='notification-button'>{title}</button>}

                {!type &&
                    <div>
                        <NotificationButtons set={()=>handleType('2nd', 'Second Call')} title='Second Call' color='second'/>
                        <NotificationButtons set={()=>handleType('desk', 'Send Message To Referee Desk')} title='Send Message To Referee Desk'/>
                        <NotificationButtons set={()=>handleType('td', 'Tournament Director Requested')} title='Tournament Director Requested' color='tournament-director'/>
                        <NotificationButtons set={()=>handleType('head ref', 'Head Referee Needed')} title='Head Referee Needed'/>
                        <NotificationButtons set={()=>handleType('medical', 'Medical Assistance Needed')} title='Medical Assistance Needed'/>
                        
                    </div>}
                    {type && 
                    <>
                    <label>
                            {type === '2nd' ?'Player Name(s)':'Message'}
                    <textarea type="text"
                                value={message}
                                onChange={e => setMessage(e.target.value)} />
                        </label>
                        <br />
                        {loader ? <CircularProgress />:
                        <button onClick={notifyDesk}>Send</button>}
                        <button onClick={handleCancel}>Cancel</button>
                        </>}
            </div>
        </div>
    )
}
export default MonitorNotifyDesk

