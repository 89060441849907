import React from 'react'
import { useSelector } from 'react-redux'

export default function ShiftDescriptions({shift}){
    const organization = useSelector(state => state.organization)

    return(
        <div style={{ padding: '0 15px' }}>
                                {
                                organization === 'ppa' ?
                                <p>
                                Referees that are PR can sign up for any available spot.  
                                Referees who are Certified can sign up for Certified, Level Two, Level One and Volunteer Spots.
                                Referees who are Level Two can sign up for Level Two, Level One and Volunteer Spots.  
                                Level One can sign up for Level One or Volunteer.
                                </p>
                                :
                                shift.category === 'Referee' &&
                                    (shift.certified > 0 ||
                                        shift.level_two > 0 ||
                                        shift.level_one > 0) ?
                                    <p>
                                        Referees that are certified can sign up for any available spot.  Referees who are Level Two can sign up for Level Two, Level One and Volunteer Spots.  Level One can sign up for Level One or Volunteer.
                            </p> : ''}
                                {shift.category === 'Line Judge'
                                    &&
                                    (shift.certified > 0 ||
                                        shift.level_two > 0 ||
                                        shift.level_one > 0) ?
                                    <p>
                                        Line judges that are certified referee's can sign up for any available spot.  Line judges who are Level Two can sign up for Level Two, Level One and Volunteer Spots.  Level One can sign up for Level One or Volunteer.
                            </p> : ''}

                            </div>
    )
}