import React from 'react'

export default function SetupTips() {
    function createData(title, description) {
        return { title, description };
      }
      
      const rows = [
        createData('Venue', 'Include information specific to the venue'),
        createData('Restrooms', 'Link restroom locations'),
        createData('Parking', 'Include a map and/or written instructions'),
        createData('Shuttle', 'Shuttle pickup spots and how to get a shuttle'),
        createData('Food', 'List food available'),
        createData('Player List', 'Link to player list within your tournament software'),
        createData('Schedule', 'Link to schedule within your tournament software'),
        createData('Match Assignments', 'Link to your match assignment list within your tournament software'),
        createData('Partner Replacement/ Registration', 'Type email information and instructions for registration changes'),
        createData('Volunteer Registration', 'Link to volunteer registration page in Pickleball Desk'),
        createData('Referee Registration', 'Link to referee registration page in Pickleball Desk'),







      ];
    return(
        <div style={{textAlign: 'left', background: 'white', padding: '15px'}}>
            <h4>Button Suggestions</h4>
            {rows.map((row, i)=> (
                <div key={'s'+i} style={{borderBottom: '1px solid grey'}}>
                    <div>
                        {row.title}
                    </div>
                    <div style={{fontSize: '.8em'}}>
                        {row.description}
                    </div>
                </div>
            ))}
        </div>
    )
}