import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import ScoreCard from './ScoreCard'

const PastScores = () => {
    const [scores, setScores] = useState([])

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getMatches() }, [])

    const getMatches = () => {
        axios.get(`/api/score/getPastScores/${tid}`).then(
            res => {
                setScores(res.data)
            }
        )
    }

    const mappedScores = scores.map(match => {
        return (
            <div className='col-12 col-sm-4' key={match.sid}>
                <ScoreCard
                    getScores={getMatches}
                    match={match} />
            </div>
        )
    })

    return (
        <div className='container-fluid'>
            <div className='col-12'>
                <button
                    onClick={getMatches}>
                    <i className="fas fa-sync-alt"></i>
                            refresh</button>
                <h3>Score History- All Matches</h3>
                <div className='col-12' style={{ display: 'flex', flexWrap: 'wrap' }} >
                    {mappedScores}
                    {scores.length === 0 ? 'No Scores Reported' : ''}

                </div>


            </div>

        </div>
    )
}



export default PastScores