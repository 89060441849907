import React, { Component } from 'react'
import axios from 'axios'
import { connect } from "react-redux"
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';




class LineJudgeCommunication extends Component {
    constructor(props) {
        super(props)
        this.state = {
            referees: [],
            templates: [],
            showTemplateList: false,
            email: [],
            emailSubject: '',
            replyEmail: '',
            emailBody: '',
            text: [],
            textBody: '',
            saveTemplate: false,
            templateName: '',
            loader: false,
            textBodyError: ''
        }
    }

    componentDidMount() {
        this.setEmail()
        this.getTemplates()
    }

    setEmail = () => this.setState({ replyEmail: this.props.email })

    getTemplates = () => {
        axios.get(`/api/getTextTemplates/${this.props.ref_id}`).then(
            res => {
                this.setState({ templates: res.data })

            }
        )
    }

    clearSearch = () => this.setState({ firstSearch: '', lastSearch: '', nameSearch: '', categoryFilter: '' })

    emailAll = () => {
        let email = [...this.state.referees]
        this.setState({ email: email })

    }

    textAll = () => {
        let text = [...this.state.referees]
        this.setState({ text: text })

    }


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    toggleShowTemplate = () => this.setState({ showTemplateList: !this.state.showTemplateList })


    selectTemplate = (t) => {
        if (t.type === 'text') {
            this.setState({
                showTemplateList: false,
                textBody: t.body

            })
        } else {
            this.setState({
                showTemplateList: false,
                emailSubject: t.subject,
                emailBody: t.body

            })
        }
    }

    addEmail = (ref) => {
        let email = this.state.email.concat(ref)
        this.setState({ email: email })
    }


    sendEmail = () => {
        if (this.state.saveTemplate) { axios.post(`/api/saveTemplate`, { ref_id: this.props.ref_id, name: this.state.templateName, subject: this.state.emailSubject, body: this.state.emailBody, type: 'email' }).then(res => toast.success(`Template Saved`)) }
        axios.post(`/api/email`, { category: 'referee', tournamentName: this.props.name, tid: this.props.tid, email: this.state.email, subject: this.state.emailSubject, reply: this.state.replyEmail, body: this.state.emailBody, author: this.props.ref_id }).then(
            res => {
                toast.success(`Email Queued`)
                this.setState({ email: [], emailSubject: '', replyEmail: '', emailBody: '' })
            }
        )
    }

    sendText = () => {
        if (this.state.emailBody.length < 0) { return }
        if (this.state.saveTemplate) { axios.post(`/api/saveTemplate`, { ref_id: this.props.ref_id, name: this.state.templateName, subject: '', body: this.state.textBody, type: 'text' }).then(res => toast.success(`Template Saved`)) }
        axios.post(`/api/customLineJudgeText`, { tid: this.props.tid, sid: this.props.textList.sid, sendToGroup: this.props.gid, gid: this.props.textList.gid || 0, textBody: this.state.textBody }).then(
            this.props.setShowSendText(false)
        )
    }

    render() {

        const mappedTemplates = this.state.templates.map(t => {
            return (
                <div key={t.etid}
                    onClick={() => this.selectTemplate(t)}
                    className='mappedTemplates'>

                    {t.type}: {t.name}

                </div >
            )
        })
        return (
            <div className='container' >
                <div className='RefereeSchedule col-12'>

                    <div className='email'>
                        <h4>Send Text</h4>
                        <button onClick={this.toggleShowTemplate}>{this.state.showTemplateList ? 'Close Template List' : 'Create from Saved Template'}</button>
                        {this.state.showTemplateList ?
                            <div className='mappedTemplatesDiv'>{mappedTemplates}</div>
                            : ''}

                        <div className='row'>
                            <div className='paper'>
                                <h4>Text: {this.props.textList.name}
                                    {this.props.textList.group_name}</h4>


                                <p>Text Message</p>

                                <textarea
                                    type="text"
                                    placeholder='Text Message, limit 160 cha'
                                    name="textBody"
                                    maxLength='159'
                                    value={this.state.textBody}
                                    onChange={this.handleChange} />
                                <p><small>*Include your name or tournament name. Limit 160 characters</small></p>
                                <legend> Save text as template
                                    <input
                                        type="checkbox"
                                        placeholder='Save as template'
                                        name="saveTemplate"
                                        value={this.state.saveTemplate}
                                        onChange={() => this.setState({ saveTemplate: !this.state.saveTemplate })} />
                                    {this.state.saveTemplate ?
                                        <input
                                            type="text"
                                            placeholder='Template Name'
                                            name="templateName"
                                            value={this.state.templateName}
                                            onChange={this.handleChange} /> : ''}
                                </legend>
                                <button onClick={this.sendText}>Send Text</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name,
        email: reduxState.email,
        ref_id: reduxState.ref_id
    }
}
export default connect(
    mapStateToProps,
)(LineJudgeCommunication)