import React, { Component } from 'react';
import 'moment-timezone';
import MUIDataTable from 'mui-datatables'
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import './RefDesk.css'
import Modal from 'react-responsive-modal';





class RefStatus extends Component {
    constructor() {
        super()
        this.state = {
            edit: false,
            ref_id: '',
            first: '',
            last: '',
            phone: '',
            email: '',
            barcode: '',
            credentials: 'Not Rated'
        }

    }

    edit = (value, tableMeta) => {
        let t = tableMeta.rowData
        this.setState({
            edit: true,
            ref_id: t[1],
            first: t[5],
            last: t[6],
            phone: t[7],
            email: t[8],
            barcode: t[9],
            credentials: t[4]

        })
    }

    save = () => {
        this.props.handleEditRef(this.state.ref_id, this.state.first, this.state.last, this.state.credentials, this.state.phone, this.state.email, this.state.barcode)
        this.setState({ edit: false })
    }

    cancel = () => {
        this.setState({
            edit: false,
            ref_id: '',
            first: '',
            last: '',
            phone: '',
            email: '',
            barcode: '',
            credentials: 'Not Rated'
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    render() {
        // DONT CHANGE ORDER - edit works using an array in that order
        const columns = [
            {
                name: "status",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let id = tableMeta.rowData[1]
                        return (

                            <FormControlLabel
                                label={value ? "Active" : "Inactive"}
                                value={value ? "Active" : "Inactive"}
                                control={
                                    <Switch
                                        color="primary"
                                        checked={value}
                                        value={value ? "Active" : "Inactive"}
                                    />
                                }

                                onClick={e => this.props.checkIn(id)}
                            />
                        );
                    }
                }
            },

            {
                name: "ref_id", label: "ref id", options: { filter: true, sort: true, display: false }
            },
            {
                name: "quick_break",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let id = tableMeta.rowData[1]
                        return (

                            <FormControlLabel
                                label={value ? "brb" : ""}
                                value={value ? "brb" : ""}
                                control={
                                    <Switch
                                        color="primary"
                                        checked={value}
                                        value={value ? "brb" : ""}
                                    />
                                }

                                onClick={e => this.props.quickBreak(id)}
                            />
                        );
                    }
                }
            },
            {
                name: "last_match",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let id = tableMeta.rowData[1]
                        return (

                            <FormControlLabel
                                label={value ? "last" : ""}
                                value={value ? "last" : ""}
                                control={
                                    <Switch
                                        color="primary"
                                        checked={value}
                                        value={value ? "last" : ""}
                                    />
                                }

                                onClick={e => this.props.lastMatch(id)}
                            />
                        );
                    }
                }
            },

            { name: "credentials_verified", label: "credentials", options: { filter: true, sort: true, } },
            { name: "first", label: "First Name", options: { filter: true, sort: true, } },
            { name: "last", label: "Last Name", options: { filter: true, sort: true, } },
            { name: "phone", label: "phone num", options: { filter: true, sort: true, } },
            { name: "email", label: "email", options: { filter: true, sort: true, } },
            { name: "barcode", label: "barcode", options: { filter: true, sort: true, } },
            {
                name: "barcode", label: "Schedule (coming soon)", options: {
                    filter: false, sort: false, customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <i className="far fa-calendar"></i>

                        )
                    }

                }
            },



            {
                name: "edit", label: "edit", options: {
                    filter: false, sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <i className="fas fa-user-edit" onClick={e => this.edit(value, tableMeta)}></i>

                        )
                    }

                }
            },


        ]




        return (
            <div className="">
                <div className='ref-status-container'>


                    <MUIDataTable
                        title={'Ref Status'}
                        data={this.props.refStatus}
                        columns={columns}
                        options={{ selectableRows: 'none', print: false, download: false, rowsPerPage: 100 }}

                    />

                    {this.state.edit ?
                        <Modal open={this.edit} center showCloseIcon={false} closeIconSize={2}>
                            <h6>Edit Referee Info</h6>
                            <h2>{this.state.first} {this.state.last}</h2>
                            <p>
                                <label> First
                                <input type="text"
                                        placeholder='first'
                                        name='first'
                                        value={this.state.first}
                                        onChange={this.handleChange} />
                                </label>
                            </p>
                            <p>
                                <label> last
                                <input type="text"
                                        placeholder='last'
                                        name='last'
                                        value={this.state.last}
                                        onChange={this.handleChange} />
                                </label>
                            </p>

                            <p><label> Phone
                                <input type="text"
                                    placeholder='Phone Num- no spaces'
                                    name='phone'
                                    value={this.state.phone}
                                    maxLength='10'
                                    onChange={this.handleChange} />
                            </label>
                            </p>
                            <p>
                                <label> Email
                                <input type="text"
                                        placeholder='email'
                                        name='email'
                                        value={this.state.email}
                                        onChange={this.handleChange} />
                                </label>
                            </p>
                            <p>
                                <label> Barcode
                                <input type="text"
                                        placeholder='Barcode'
                                        name='barcode'
                                        value={this.state.barcode}
                                        onChange={this.handleChange} />
                                </label>
                            </p>

                            <legend>Credentials: {this.state.credentials}</legend>
                            <label>
                                <input type='radio'
                                    checked={this.state.credentials === 'Not Rated'}
                                    value='Not Rated'
                                    onClick={this.handleChange}
                                    onBlur={this.save} /> Not Rated </label>
                            <label>
                                <input type='radio'
                                    checked={this.state.credentials === 'Level 1'}
                                    value='Level 1'
                                    onClick={this.handleChange}
                                    onBlur={this.save} /> Level 1 </label>
                            <label>
                                <input type='radio'
                                    value='Level 2'
                                    checked={this.state.credentials === 'Level 2'}
                                    onClick={this.handleChange}
                                    onBlur={this.save} /> Level 2 </label>
                            <label>
                                <input type='radio'
                                    checked={this.state.credentials === 'Certified'}
                                    value='Certified'

                                    onClick={this.handleChange}
                                    onBlur={this.save} /> Certified </label>
                            <div>
                                <button onClick={this.save}><i className="far fa-save"></i>Save</button>
                                <button onClick={this.cancel}><i className="fas fa-ban" ></i>Cancel</button>


                            </div>




                        </Modal>
                        : ''}



                </div>


            </div>


        )
    }
}


export default RefStatus;