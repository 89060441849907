import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { makeStyles } from '@material-ui/core/styles';
import MessageRow from './MessageRow';

const MessageList = props => {
    let {list} = props

    const classes = useStyles();


    return(
        <TableContainer component={Paper} className={classes.table}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.name}>Name</TableCell>
                        <TableCell align="left" >Post</TableCell>
                        <TableCell align="left"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((row, i)=> (
                        <MessageRow 
                        key={i}
                        row={row}
                        admin={props.admin}
                        get={props.get}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )

}
export default MessageList

const useStyles = makeStyles({
    name: {
        width: '200px'
    }
});
