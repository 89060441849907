import React, { Component } from 'react'
import axios from 'axios'
import { connect } from "react-redux"
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import './AddRefToSchedule.css'
import MappedShifts from './ScheduleModal/AddRefToScheduleDisplays/MappedShifts';
import RefereeScheduleRecap from './RefereeScheduleRecap';

class AddRefToSchedule extends Component {
    constructor() {
        super()
        this.state = {
            data: [],
            shiftsByName: [],
            shiftsByTime: [],
            referees: [],
            shift: {},
            referee: {},
            waitlist: false,
            firstSearch: '',
            lastSearch: '',
            searchName: '',
            searchFirst: '',
            searchLast: '',
            categoryFilter: '',
            showByName: true,
            addReferee: false,
            showAll: true
        }
    }

    componentDidMount() {
        this.getShiftSpots()
        this.getRegisteredRefs()
    }


    getShiftSpots = () => {
        axios.get(`/api/getShiftSpotsReferee/${this.props.tid}`).then(
            res => {
                if (this.state.showByName) {
                    this.setState({
                        data: res.data.name,
                        shiftsByName: res.data.name,
                        shiftsByTime: res.data.time
                    })
                } else {
                    this.setState({
                        data: res.data.time,
                        shiftsByName: res.data.name,
                        shiftsByTime: res.data.time
                    })
                }
            }
        )
    }

    getRegisteredRefs = async () => {
        await axios.get(`/api/getRegisteredRefs/${this.props.tid}`).then(
            res => {
                this.setState({ referees: res.data })
            }
        ).then(
            await this.getAvailableRefs
        )
    }

    assignRef = async () => {
        if (this.state.waitlist) { this.addWaitList() }
        else {
            axios.put(`/api/putTimeSpots/${this.state.shift.sid}`, { tid: this.props.tid, ref_id: this.state.referee.ref_id }).then(
                res => {
                    if(res.data.conflict){
                        toast.error(res.data.conflict)
                    }else {
                    this.getShiftSpots()
                    this.setState({ referee: {}, shift: {} })
                    }
                }
            ).catch(error => {
                if (error.response) {
                    toast.warning(error.response.data)
                    this.setState({ referee: {}, shift: {} })

                }
            })
        }

    }

    addWaitList = async () => {
        axios.put(`/api/putWaitlist/${this.state.shift.sid}`, { tid: this.props.tid, ref_id: this.state.referee.ref_id }).then(
            res => {
                if(res.data.conflict){toast.warning(res.data.conflict)}
                else{
                    this.getShiftSpots()
                }
                
                this.setState({ referee: {}, shift: {} })
            }
        ).catch(error => {
            if (error.response) {
                toast.warning(error.response.data)
                this.setState({ referee: {}, shift: {}, waitlist: false })

            }
        })

    }


    moveOffWaitlist = (shift_id) => {
        axios.put(`/api/moveOffWaitlist/${shift_id}`, { tid: this.props.tid }).then(
            res => {
                this.getShiftSpots()
                toast.success('email sent')
            }
        )
    }

    moveToWaitlist = (shift_id) => {
        axios.put(`/api/moveToWaitlist/${shift_id}`, { tid: this.props.tid }).then(
            res => {
                this.getShiftSpots()
                toast.success('email sent')
            }
        )
    }
    
    removeRef = (shift_id) => {
        axios.put(`/api/removeRefTime/${shift_id}`, { tid: this.props.tid }).then(
            res => {
                this.getShiftSpots()
            }
        )
    }

    addRefToTournament = async (ref_id) => {
        await axios.post(`/api/addRefToTournamentSetup/${this.props.tid}`, { ref_id: ref_id }).then(

            res => {
                this.setState({
                    refStatus: res.data
                })
            }
        ).then(this.getAllRefs)
    }

    setShift = async (shift, bol) => {
        await this.setState({ shift: shift, waitlist: bol })
        if (this.state.shift.sid && this.state.referee.ref_id && this.state.waitlist === false) {
            this.assignRef()
        }
        if (this.state.shift.sid && this.state.referee.ref_id && this.state.waitlist === true) {
            this.addWaitList()
        }
    }

    setReferee = async (referee) => {
        await this.setState({ referee: referee })
        if (this.state.shift.sid && this.state.referee.ref_id) { this.assignRef() }
    }
    clearSearch = () => this.setState({ firstSearch: '', lastSearch: '' })

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }
    handleSearchName = (e) => {
        if(this.state.searchName === e){e = ''}
        this.setState({
            searchName: e

        })
    }

    showByName = () => this.setState({
        showByName: true,
        data: this.state.shiftsByName
    })

    showByTime = () => this.setState({
        showByName: false,
        data: this.state.shiftsByTime
    })

    handleShowDetail = (value) => this.setState({ showAll: value})


    render() {
        const mappedShifts = this.state.data
            .filter(obj => obj.category.includes(this.state.categoryFilter))
            .filter(obj => obj.name.toUpperCase().includes(this.state.searchName.toUpperCase()))

            .map((shift, i) => {
                
                return (
                    <MappedShifts key={'y'+shift.sid}
                        shift={shift}
                        i={i}
                        removeRef={this.removeRef}
                        moveToWaitlist={this.moveToWaitlist}
                        moveOffWaitlist={this.moveOffWaitlist}
                        searchFirst={this.state.searchFirst}
                        searchLast={this.state.searchLast}
                        selectedShift={this.state.shift}
                        selectShift={this.setShift}
                        showAll={this.state.showAll}/>
                )
            })

        const mappedReferees = this.state.referees
            .filter(obj => obj.first.toUpperCase().includes(this.state.firstSearch.toUpperCase()))
            .filter(obj => obj.last.toUpperCase().includes(this.state.lastSearch.toUpperCase()))

            .map(referee => {
                return (

                    <div
                        key={'x'+referee.ref_id}
                        onClick={() => this.setReferee(referee)}
                        className={
                            referee.credentials === 'Certified' ? 'ref-list certified shift-checkin-list' :
                                referee.credentials === 'Level 2' ? 'ref-list level2 shift-checkin-list' :
                                    referee.credentials === 'Level 1' ? 'ref-list level1 shift-checkin-list' :
                                        'ref-list shift-checkin-list'}>
                        <div className={this.state.referee.ref_id === referee.ref_id ? 'selected-ref' : ''}>
                            {referee.last}, {referee.first} - {referee.credentials}
                        </div>
                    </div >

                )
            })
        return (
            <div className='AddRefToSchedule container' >
                 Total Referees: {this.state.referees.length}
                <RefereeScheduleRecap select={this.handleSearchName}/>         
                <Link to={`/desk/scheduleSetup`} >
                    <button className='register-button'>Schedule Setup</button>
                </Link>
                <Link to={`/desk/scheduleByRef`} >
                    <button className='register-button'>Schedule By Referee</button>
                </Link>
                <Link to={`/desk/schedulingLog`} >
                    <button className='register-button'>Schedule Log (signup/ removed times)</button>
                </Link>



                <div className='row'>
                    <div className='col-12 col-md-8 '>
                        <div className='search-menu'>
                            <form>

                                <legend> Search by date or title
                            <input
                                        type="text"
                                        placeholder='Search date/ Shift Name'
                                        name="searchName"
                                        value={this.state.searchName}
                                        onChange={this.handleChange} />

                                </legend>
                            </form>
                            <legend>
                                Search by name:

                                    <input
                                    type="text"
                                    placeholder='Search First Name'
                                    name="searchFirst"
                                    value={this.state.searchFirst}
                                    onChange={this.handleChange} />

                                <input
                                    type="text"
                                    placeholder='Search Last Name'
                                    name="searchLast"
                                    value={this.state.searchLast}
                                    onChange={this.handleChange} />
                                <i className="fas fa-ban" onClick={() => this.setState({ searchName: '', categoryFilter: '', searchFirst: '', searchLast: '' })}> clear</i>
                            </legend>

                            <button className='searchButtons'
                                onClick={this.showByName}>Order By Name</button>
                            <button className='searchButtons'
                                onClick={this.showByTime}>Order by Time</button>
                        </div>

                        <div>
                            <button className='small-button' onClick={()=>this.handleShowDetail(true)}> <i className="fas fa-chevron-down"></i>Show Shift Detail</button>
                            <button className='small-button' onClick={()=>this.handleShowDetail(false)}><i className="fas fa-chevron-up"></i>Hide Shift Detail</button>
                        </div>

                        <div className='mappedSection'>
                            {mappedShifts}
                        </div>
                        <i className="fas fa-arrow-right small-button"></i> = "move to waitlist"
                        <i className="fas fa-arrow-left small-button"></i> = "move to off waitlist"
                    </div>
                    <div className='col-12 col-md-4 '>

                        <div className=''>
                            <input
                                type="text"
                                placeholder='Search by Last Name'
                                name="lastSearch"
                                value={this.state.lastSearch}
                                onChange={this.handleChange} />
                            <input
                                type="text"
                                placeholder='Search by First Name'
                                name="firstSearch"
                                value={this.state.firstSearch}
                                onChange={this.handleChange} />
                            <i className="fas fa-ban" onClick={this.clearSearch}></i>
                        </div>
                        <h4>Referees Registered for Tournament</h4>
                        Total Referees: {this.state.referees.length}

                        <div className='mappedSection'>
                            {mappedReferees}
                        </div>
                    </div>
                </div>
                {/* <Modal open={this.state.addReferee} center showCloseIcon={true} onClose={()=> this.setState({addReferee:false})} closeIconSize={20} closeOnEsc={true} >
                <div>
                        <h4>Add</h4>
                        <h6>Click on name to add.  If Ref is not in the system add name at bottom</h6>
                        <LookupRef
                            allRefs={this.state.allRefs}
                            getAllRefs={this.getRegisteredRefs}
                            addRefToTournament={this.addRefToTournament}
                            removeRef={this.removeRef} />

                        <p>If ref is not in system add below</p>
                        <AddRef
                            tid={this.props.tid}
                            refereeAdded={this.getAllRefs}
                            getRegisteredRefs={this.getRegisteredRefs} />

                    </div>
                </Modal> */}
            </div>

        )
    }
}

const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name
    }
}
export default connect(
    mapStateToProps,
)(AddRefToSchedule)
