import React, { useState } from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { updateTournament } from '../../ducks/reducer'



const AddTournament = props => {
    const [name, setName] = useState('')
    const [location, setLocation] = useState('')
    const [courts, setCourts] = useState(0)
    const [organization, setOrganization] = useState('')
    const [tid, setTid] = useState(null)
    const [lat, setLat] = useState(0)
    const [long, setLong] = useState(0)
    const [country, setCountry] = useState('usa')
    const [facility, setFacility] = useState('')

    const dispatch = useDispatch()


    const addTournament = (s) => {
        axios.post(`/api/t/addTournament`, { name, location, courts, organization, lat, long, country, facility }).then(
            res => {
                
                setTid(res.data.tid)
                setName('')
                setLocation('')
                setCourts(0)
                setOrganization('')
                setLat(0)
                setLong(0)
                dispatch(updateTournament(res.data))
                props.history.push('/admin')

            }
        )
    }


    return (
        <div>
            <h2>Add Tournament</h2>
            <legend>
                Tournament name
                <input type="text"
                    value={name}
                    onChange={e => setName(e.target.value)} />
            </legend>
            <legend>
                Location
                <input type="text"
                    value={location}
                    onChange={e => setLocation(e.target.value)} />
            </legend>
            <legend>
                Number of Courts
                <input type="number"
                    value={courts}
                    onChange={e => setCourts(e.target.value)} />
            </legend>
            <legend>
                Organization
                <input type="text"
                    value={organization}
                    onChange={e => setOrganization(e.target.value)} />
                    <button onClick={()=>setOrganization('ppa')}>ppa</button>
                    <button onClick={()=>setOrganization('app')}>app</button>
                    <button onClick={()=>setOrganization('utr')}>UTR</button>
                    <button onClick={()=>setOrganization('np')}>NP</button>
            </legend>
            <legend>
                Lat
                <input type="number"
                    value={lat}
                    onChange={e => setLat(e.target.value)} />
            </legend>
            <legend>
                Long
                <input type="number"
                    value={long}
                    onChange={e => setLong(e.target.value)} />
            </legend>
            <legend>
                Country
                <input type="text"
                    value={country}
                    onChange={e => setCountry(e.target.value)} />
                    <button onClick={()=>setCountry('usa')}>usa</button>
                    <button onClick={()=>setCountry('canada')}>canada</button>
            </legend>
            <legend>
                Facility
                <input type="text"
                    value={facility}
                    onChange={e => setFacility(e.target.value)} />
                    <button onClick={()=>setFacility('legacy')}>legacy</button>
            </legend>
            <button onClick={addTournament}>Save</button>

            {tid}

        </div>
    )
}
export default AddTournament