import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import axios from 'axios'

import 'moment-timezone';
import '../RefDesk.css'
import AssignMagnet from './AssignMagnet'
import CircularProgress from '@material-ui/core/CircularProgress';



const Reassign = props => {
    const [paid, setPaid] = useState(false)
    const [amount, setAmount] = useState(0)
    const [sysid, setSysid] = useState(null)
    const [medal, setMedal] = useState(null)
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)
    const organization = useSelector(state => state.organization)
    const pr1 = useSelector(state => state.pr1_amount)
    const pr2 = useSelector(state => state.pr2_amount)
    const certified = useSelector(state => state.certified_amount)
    const level2 = useSelector(state => state.level_two_amount)
    const level1 = useSelector(state => state.level_one_amount)
    const volunteer = useSelector(state => state.volunteer_amount)
    const certified2 = useSelector(state => state.certified_amount2)
    const level22 = useSelector(state => state.level_two_amount2)
    const level12 = useSelector(state => state.level_one_amount2)
    const volunteer2 = useSelector(state => state.volunteer_amount2)
    const saveTag = useSelector(state => state.saveTag)

    useEffect(
        () => { handleAmount() }, [props.assignedRef.ref_id])

    const handleAmount = () => {
        if(saveTag && props.assignedRef.tag != null){
            if(sysid.includes(props.assignedRef.tag)){}
            else{
            setSysid(sysid + ' '+ props.assignedRef.tag)
            }
        }
        let credentials = props.assignedRef.credentials
        if(organization === 'ppa' && props.assignedRef.ppa_rating === 'PR1'){
            setAmount(pr1)
        }
        else if(organization === 'ppa' && props.assignedRef.ppa_rating === 'PR2'){
            setAmount(pr2)
        }
        else if (credentials === 'Certified') {
            setAmount(certified)
        }
        else if (credentials === 'Level 1') {
            setAmount(level1)
        }
        else if (credentials === 'Level 2') {
            setAmount(level2)
        } else {
            setAmount(volunteer)
        }
    }
    const handleAmountAlt = () => {
        let credentials = props.assignedRef.credentials
        if(organization === 'ppa' && props.assignedRef.ppa_rating === 'PR1'){
            setAmount(pr1)
        }
        else if(organization === 'ppa' && props.assignedRef.ppa_rating === 'PR2'){
            setAmount(pr2)
        }
        else if (credentials === 'Certified') {
            setAmount(certified2)
        }
        else if (credentials === 'Level 1') {
            setAmount(level12)
        }
        else if (credentials === 'Level 2') {
            setAmount(level22)
        } else {
            setAmount(volunteer2)
        }
    }
    const reassignRefereeOrCourt = () => {
        const pastCourt = props.pastCourt
        const courtNum = props.court

        axios.put(`/api/reassignReferee/${props.matchId}`, { ref_id: props.assignedRef.ref_id, tid, court_num: courtNum, past_court: pastCourt, medal, paid, amount, credentials: props.assignedRef.credentials, pastRef: props.pastRef }).then(
            res => {
                props.get()
            }
        )
    }

    const clear = () => {
        props.clearState()
        setPaid(false)
        setSysid('')
        setMedal(null)
    }

    const keyPressed = (event) => {
        // console.log('hit', event.keyCode, event.which)
        const code = event.which;
        if (code === 112) {
            setPaid(true)
        }
        if (code === 98) {
            setMedal('Bronze')
        }
        if (code === 103) {
            setMedal('Gold')
        }
    }
    return (
        <div className='finishMatch' align="center"
            onKeyPress={e => keyPressed(e)}
        >
            <div>
                <div>
                    <div className='assign-header'>Re-Assign</div>
                </div>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            placeholder='paid'
                            checked={paid}
                        // onChange={() => setPaid(paid => !paid)} 
                        />
                            PAID $
                            <input type="number"
                            style={{ width: '40px' }}
                            value={amount}
                            onChange={e => setAmount(e.target.value)} />
                    </label>
                    <button onClick={handleAmountAlt}>Alt Amount</button>

                </div>
            </div>
            <AssignMagnet
                referee={props.assignedRef}
                court={props.court}
                medal={medal}
                show={false} />


            <input
                type="text"
                placeholder='SYSID'
                name="sysid"
                ref={input => input && input.focus()}
                value={sysid}
                style={{ width: '100px' }}
                onChange={e => setSysid(e.target.value)} />
            <legend>Medal Match?</legend>
            <label>
                <input type='radio'
                    checked={medal === ''}
                    name='medal'
                    value={medal}
                    onChange={() => setMedal('')}
                /> No </label>
            <label>
                <input type='radio'
                    value='Bronze'
                    checked={medal === 'Bronze'}
                    name='medal'
                    onChange={() => setMedal('Bronze')}
                /> Bronze </label>
            <label>
                <input type='radio'
                    checked={medal === 'Gold'}
                    name='medal'
                    value='Gold'
                    onChange={() => setMedal('Gold')}
                /> Gold </label>
            {loader ? <CircularProgress /> : ''}

            <p><small>Text will be sent to ref when assigned</small></p>

            <button className='button-none' onClick={clear}><i className="fas fa-trash" ></i>Clear</button>
            <button onClick={reassignRefereeOrCourt}>Save Changes</button>




        </div >


    )
}

export default Reassign;