import React, {useState} from 'react'
import RefereeNamesForInvoice from './RefereeNamesForInvoice'
import RefereeMatchList from './RefereeMatchList'
import RefereeTotals from './RefereeTotals'
import AddCompletedMatch from '../../RefDesk/AddCompletedMatch/AddCompletedMatch'

const InvoiceMaster = () => {
    const [referee, setReferee] = useState({})
    const [update, setUpdate] = useState(false)

    const handleUpdate = () => setUpdate(update => !update)
    

    return(
        <div className='container-fluid'>
            
            <h3>Payment Reconciliation By Referee</h3>
            
            <RefereeNamesForInvoice 
                referee={referee}
                setReferee={setReferee}/>
                {referee.ref_id ?
                <div style={{marginTop: '15px'}}>
                
                <RefereeTotals 
                    ref_id={referee.ref_id}
                    referee={referee}
                    update={update}/>
            <p>To add a bonus click on a match amount and edit it to reflect the bonus.  Add a note stating bonus amount.  I usually add the bonus to the last match of the day/ tournament.</p>
                    
            <RefereeMatchList 
                ref_id={referee.ref_id}
                handleUpdate={handleUpdate}/>
                </div>:''}
                <AddCompletedMatch get={update}/>
        </div>
    )
}
export default InvoiceMaster