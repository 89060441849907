import React from 'react'

export default function MappedWaitlist(props){
    let {i, waitList, moveOffWaitlist, removeRef} = props

    return(
        <div key={waitList.spot_id} style={{ background: 'white', display: 'flex' }} >
                                <div>
                                            <i className="fas fa-arrow-left small-button" onClick={()=>moveOffWaitlist(waitList.shift_id)}>
                                            </i></div>
                                <div className=''>{++i}.</div>
                                <div className='signup-credential-box'>

                                    <div style={{ margin: '3px', width: '1em' }} className={waitList.credentials_verified === 'Certified' ? 'ref-list certified' : waitList.credentials_verified === 'Level 2' ? 'ref-list level2' : waitList.credentials_verified === 'Level 1' ? 'ref-list level1' :

                                        'ref-list Volunteer'}>
                                        {waitList.credentials === 'Certified' ? 'C' :
                                            waitList.credentials === 'Level 2' ? '2' : waitList.credentials === 'Level 1' ? '1' : '-'}
                                    </div></div>
                                <div className=''>
                                    {waitList.last}, {waitList.first}
                                </div>
                                <i className="fas fa-user-minus" onClick={() => removeRef(waitList.shift_id)}></i>

                            </div>
    )
}