import React, {useState, useEffect} from 'react'
import EventButtons from './EventButtons'
import './EventGuide.css'

const Venues = (props) => {
    const [id, setId] = useState(0)
    let {venue} = props
    
    const handleSelect = item => {
        if(id === item.id){
            setId(0)
        }
        else if(item.link) {
            window.open(item.link, "_blank")
        }
            else {
            setId(item.id)
        }
    }

    return(
        <div style={{border:`2px solid ${venue.color || 'black'}`, margin: '20px', background:`${venue.color || 'black'}`}} >            
            <h3 
            className='pointer'
            onClick={()=>props.select(venue.vid)}>{venue.name}</h3>
            {props.vid === venue.vid && venue.list.map((item, i)=> (
                <EventButtons key={'i'+i} item={item} select={handleSelect} id={id}/>
            ))}
        </div>
    )


}
export default Venues