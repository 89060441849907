import React, {useState, useEffect} from 'react'
import './EventGuide.css'

const EventButtons = (props) => {
    let {item} = props

    return( 
            <button className='button5' style={{border:`3px solid ${item.color} || #000000`}}
                onClick={()=>props.select(item)}>
                    <div style={{color: `${item.color || '#000000'}`, fontSize: '20px'}}>
                        {item.title}
                    </div>
                    
                
            {props.id === item.id && 
            <div >
                {item.image && 
                <img 
                style={{width: '90%', marginBottom: '5px'}}
                src={item.image} alt='image' />}
                {item.details}
            </div>
            }
            </button>

                
    )


}
export default EventButtons