import React, { Component } from 'react'
import { connect } from "react-redux"
import MUIDataTable from 'mui-datatables'


class GetRefSchedule extends Component {


    render() {
        const columns = [
            { name: "time_id", label: "time_id", options: { display: false } },
            { name: "date", label: "date", options: { filter: true, sort: true, } },
            { name: "start", label: "start", options: { filter: true, sort: true, } },
            { name: "stop", label: "end", options: { filter: true, sort: true, } },



        ]
        return (
            <div className='col-12'>
                <h2>{this.props.refSchedule[0].first} {this.props.refSchedule[0].last}</h2>

                <MUIDataTable
                    title={'Time Assignments'}
                    data={this.props.refSchedule}
                    columns={columns}
                    options={{ selectableRows: 'none', print: false, download: false, rowsPerPage: 100 }}

                />


            </div>
        )
    }
}

const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name
    }
}


export default connect(
    mapStateToProps,
)(GetRefSchedule)