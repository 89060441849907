import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import Setup from '../components/RefDesk/Setup'
import HowTo from '../components/Admin/HowTo'
import RefereeAdminRoutes from './RefereeAdminRoutes';
import VolunteerAdminRoute from './VolunteerAdminRoute';
import LineAdminRoute from './LineAdminRoute';
import VolunteerCaptainRoutes from './VolunteerCaptainRoutes';
import RefDeskDashboard from '../components/Dashboard/RefDeskDashboard'
import TournamentSetup from '../components/Schedule/TournamentSetup'
import TournamentSetupNotAuthorized from '../components/Schedule/TournamentSetupNotAuthorized'
import RegistrationCleanup from '../components/Admin/RegistrationCleanup';
import DeleteAccount from '../components/Admin/DeleteAccount';
import InviteCSV from '../components/Communication/InviteCSV'

const AdminRoutes = () => {
    const user_id = useSelector(state => state.user_id)
    const admin_type = useSelector(state => state.admin_type)
    const referee = useSelector(state => state.referee)
    const volunteer = useSelector(state => state.volunteer)
    const line = useSelector(state => state.line)
    const admin = useSelector(state => state.admin)
    const volunteer_captain = useSelector(state => state.volunteer_captain)

    return (
        <Switch>
            {user_id === 1 ? <Route path='/admin/deleteLogin' component={DeleteAccount} /> : ''}
                    {user_id === 1 ? <Route path='/admin/cleanup' component={RegistrationCleanup} /> : ''}
                    {user_id === 2 ? <Route path='/admin/cleanup' component={RegistrationCleanup} /> : ''}
                    {user_id === 45 ? <Route path='/admin/cleanup' component={RegistrationCleanup} /> : ''}
                    {user_id === 795 ? <Route path='/admin/cleanup' component={RegistrationCleanup} /> : ''}
                    {user_id === 55 ? <Route path='/admin/cleanup' component={RegistrationCleanup} /> : ''}
                    <Route path='/admin/sendInvitecsv' component={InviteCSV} />

            {admin_type === 'master' ||line || volunteer || referee ?
                    <Route path='/admin/tournamentSetup' component={TournamentSetup} /> :
                    <Route path='/admin/tournamentSetup' component={TournamentSetupNotAuthorized} /> }
            {admin && (admin_type === 'master' || referee )&& <Route path='/desk' component={RefereeAdminRoutes}/>}

                        {!referee && !line && !volunteer && volunteer_captain ?
                        <Route path='/admin' component={VolunteerCaptainRoutes}/>:
                        <Route path='/admin' component={Setup} />
                        }
{admin_type === 'master' || volunteer ? 
            <Route path='/volunteercaptain' component={VolunteerCaptainRoutes} />:null}
{admin_type === 'master' || volunteer ?
            <Route path='/volunteer' component={VolunteerAdminRoute} />:
            admin_type === 'basic' && !volunteer ?
            <Redirect from="/volunteer" to="/admin" component={AdminRoutes} />:
            <Redirect from="/volunteer" to="/" component={RefDeskDashboard} />}

{admin_type === 'master' || line ?
            <Route path='/line' component={LineAdminRoute} />:
            admin_type === 'basic' && !line ?
            <Redirect from="/line" to="/admin" component={AdminRoutes} />:
            <Redirect from="/line" to="/" component={RefDeskDashboard} />}
            <Route path='/admin/howto' component={HowTo} />
            <Route path='/admin' component={Setup} />
            



        </Switch>
    )
}
export default AdminRoutes 