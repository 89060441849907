import React from 'react'

export default function CategorySuggestions(props){
    const options = [
        {category: 'Announcer/DJ', description: 'Make announcements and play music between matches on center court.'},
        {category: 'Breakdown', description: 'Take down and pack up tents, tables, chairs, banners, etc.'},
        {category: 'Center Court Attendants', description: 'Keep water and ice replenished for players and production team. Keep balls from exiting the playing area during matches. Keep court area and banners tidy. Requirements: Good mobility.'},
        {category: 'Court Cleaning', description: 'Clean courts using power washer.'},
        
        {category:'Court Monitoring' , description:'Control entry and exit to courts so matches are not interrupted. Ensure players start and end play promptly. Requirement: Use of cell phone or electronic device during shift.', cm: true },
        {category: 'Court Taping', description: 'Set up (measure and tape) temporary courts. Help assemble temporary nets. Requirement: Bending, stooping, good mobility, light lifting.'},
        {category: 'Grounds Maintenance', description: 'Monitor and maintain upkeep of courts (including taping, blowing) and grounds (including restrooms, trash receptacles, recyclables). Daily inspection and cleanup at the end of play; lower canopies as necessary. Dry courts after rainy weather. Requirement: Light lifting.'},
        {category: 'Hospitality', description: 'Monitor and replenish snacks, beverages, and ice for players and referees. Requirement: Light lifting.'},
        {category: 'Information', description: 'Welcome players, spectators, and vendors; provide directions and general information about the tournament, venue, and locale. Demonstrate use QR of code.'},
        {category: 'Match Coordination', description:'Provide instructions score sheets and balls for players to start and manage their own matches.' },
        {category: 'Match Starter', description: 'Take scoresheet and balls to court and give basic instructions to players to start self-officiated matches. Review scoresheets returned by players for completeness.'},
        {category: 'Medal Management', description: 'Organize medals and affix labels. Assist with retrieval of medals for presentation.'},
        {category: 'National Anthem', description:'Singers and/or musicians to sing or play the National Anthem each morning.'},
        {category: 'Parking' , description: 'Direct vehicles to appropriate parking areas. Drive shuttle between parking areas and venue (must posses valid driver license).'},
        {category: 'Passes & Tickets', description: 'Sell grounds passes/tickets. Monitor access to VIP and restricted areas.'},
        {category: 'Player Check-In', description: 'Greet players and check in using computer/tablet. Verify ID and distribute player swag/tournament bags.'},
        {category: 'Swag/Tournament Bags', description: 'Stuff player bags.'},
        
        {category: 'Tournament Breakdown', description: 'Take down and pack up tents, tables, chairs, banners, etc. Requirements: Light lifting.'},
        {category: 'Tournament Setup' , description:'Set up equipment, tents, tables, chairs, banners, etc. Requirements: Light lifting.' },
        {category: 'Volunteer Check-In' , description:'Greet and check in volunteers using computer/tablet. Distribute volunteer shirts/hats. Direct volunteers to assigned area.' },
        
        
    ]

    const select = option => {
        let {category, description, cm} = options[option.target.value]
        props.setName(category)
        props.setDescription(description)
        props.setCourtMonitor(cm || false)
    }

    return(
            <select style={{overflow: 'auto', display: 'flex', flexWrap:'wrap'}}
                    // value={zone}
                    className='dropdown'
                    onChange={select}
                >
                    <option value="" key='1c'>
                        select from suggestions...
                                    </option>
                    {options.map((option, i)=>(
                <option key={i}  value={i} >
                   {option.category}
                </option>
            ))}
                </select>

    )
}