import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import AddPlayer from './AddPlayer'

const SearchForPlayer = props => {
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [showAdd, setShowAdd] = useState(false)

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { setSearch(props.name) }, [props.name])


    const searchForName = (name) => {
        setSearch(name)
        if (name.length > 2) {
            getName(name)
        }
        else {
            setSearchResults([])
        }
    }

    const getName = (name) => {
        axios.post(`/api/getPlayerName?search=${name}`, { tid }).then(res => {
            setSearchResults(res.data)
            if(res.data.length === 0){
                setShowAdd(true)
            }else {
                setShowAdd(false)
            }
        })

    }



    const handleSelect = r => {
        props.setName(r.name)
        props.setRefId(r.ref_id)
        setSearch(r.name)
        setSearchResults([])
        setShowAdd(false)
    }


    return (
        <div className='text-align-left'>
            <input type="text"
                value={search}
                onChange={e=>searchForName(e.target.value)}/>
             {searchResults.map((r, i)=> (
                        <div 
                            key={i}
                            className='dropdown' 
                            onClick={()=>handleSelect(r)}
                            value={r}>{r.name}  {r.email}</div>
                    ))}
                {showAdd ?
                    <AddPlayer 
                        handleSelect={handleSelect}/>:''}

        </div>
    )
}
export default SearchForPlayer