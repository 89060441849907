import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables'





class TournamentRefs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            edit: false,
            ref_id: '',
            first: '',
            last: '',
            phone: '',
            email: '',
            barcode: '',
            credentials: 'Not Rated'
        }

    }

    edit = (value, tableMeta) => {
        let t = tableMeta.rowData
        this.setState({
            edit: true,
            ref_id: t[1],
            first: t[3],
            last: t[4],
            phone: t[5],
            email: t[6],
            barcode: t[7],
            credentials: t[2]

        })
    }

    save = () => {
        this.props.handleEditRef(this.state.ref_id, this.state.first, this.state.last, this.state.credentials, this.state.phone, this.state.email, this.state.barcode)
        this.setState({ edit: false })
    }

    cancel = () => {
        this.setState({
            edit: false,
            ref_id: '',
            first: '',
            last: '',
            phone: '',
            email: '',
            barcode: '',
            credentials: 'Not Rated'
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    render() {
        // DONT CHANGE ORDER - edit works using an array in that order
        const columns = [

            {
                name: "ref_id", label: "ref id", options: { filter: true, sort: true, display: false }
            },

            { name: "credentials_verified", label: "credentials", options: { filter: true, sort: true, } },
            { name: "first", label: "First Name", options: { filter: true, sort: true, } },
            { name: "last", label: "Last Name", options: { filter: true, sort: true, } }

        ]




        return (
            <div className="">
                <div className='ref-status-container'>

                    <div>
                        <MUIDataTable
                            title={'Availabe Refs'}
                            data={this.props.referees}
                            columns={columns}

                            options={{
                                selectableRows: 'none', print: false, download: false, rowsPerPage: 100,
                                onRowClick: (rowData, rowState) => {

                                    this.props.assignRef(rowData[0])
                                }
                            }}

                        />
                        <button onClick={() => this.props.removeRef()}>Remove Current Referee</button>

                    </div>




                </div>


            </div >


        )
    }
}


export default TournamentRefs;