import React, { Component } from 'react'
import axios from 'axios'
import io from 'socket.io-client'
import { connect } from "react-redux"
import Moment from 'react-moment';
import 'moment-timezone';
import '../RefDesk/BoardView.css'

const Barcode = require('react-barcode');

class BoardBarcodes extends Component {
    constructor() {
        super()
        this.state = {
            courts: [],
            hideButton: true
        }
    }

    componentDidMount() {
        this.getCourtsWithBarcode()
        this.setSocketListeners()
        this.interval = setInterval(() => {
            this.getCourtsWithBarcode()
        }, 1000 * 10 * 60);
    }

    componentWillUnmount() {
        this.socket.disconnect()
    }

    setSocketListeners = () => {
        this.socket = io()

        this.socket.emit('joinRoom', `board${this.props.tid}`)

        this.socket.on('board', results => {
            this.getCourtsWithBarcode()

        })
    }

    componentWillUnmount() {
        this.stop();
    }

    stop() {
        clearInterval(this.interval);
    }



    getCourtsWithBarcode = async () => {

        await axios.get(`/api/getCourtsWithBarcode/${this.props.tid}`).then(

            res => {
                this.setState({ courts: res.data })
            }
        )
    }


    render() {
        const mappedCourts = this.state.courts.map(court => {

            return (
                <div key={court.court_num} className='col-3 court-box-barcodes'
                >
                    <div className='col-4 court-num'>
                        <p>{court.court_num}</p>
                        {court.medal === 'Bronze' ?
                            <div className='board-medal'>
                                <i className="fas fa-medal"></i>
                                B
                                </div> :
                            court.medal === 'Gold' ?
                                <div className='board-medal'>
                                    <i className="fas fa-medal"></i>
                                    G
                                </div> : ''}
                    </div>
                    <div className={
                        court.credentials_verified === 'Certified' ? 'certified-board col-8 ref-name' : court.credentials_verified === 'Level 2' ? 'level2-board col-8 ref-name' : court.credentials_verified === 'Level 1' ? 'level1-board col-8 ref-name' :
                            'col-8 ref-name'}>
                        <div >
                            {court.barcode ?
                                <Barcode
                                    format='CODE39'
                                    height={'30vh'}
                                    width={'1px'}
                                    value={court.barcode} /> : ''}
                            <span>{court.first} {court.last}</span>
                        </div>
                    </div>
                </div>
            )
        })


        return (
            <div className='container-fluid'>

                <div className='row'>
                    <div className='col-12 board' >


                        <div className='col-12 court-list'>
                            {mappedCourts}
                        </div>
                    </div>
                </div>

            </div >
        )
    }
}
const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name,
        zone: reduxState.zone
    }
}


export default connect(mapStateToProps)(BoardBarcodes)