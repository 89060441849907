import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import CourtMonitors from '../components/Volunteers/CourtMonitors'
import VolunteerSchedule from '../components/Volunteers/VolunteerSchedule'
import AddVolunteerToSchedule from '../components/Volunteers/AddVolunteerToSchedule'
import VolunteerCheckin from '../components/Volunteers/VolunteerCheckin'
import Setup from '../components/RefDesk/Setup'
import VolunteerDashboard from '../components/Dashboard/VolunteerDashboard'
import VolunteerCheckedInAndGroups from '../components/Volunteers/VolunteerCheckedInAndGroups'
import MonitorNotifyDesk from '../components/DayOfComponents/MonitorNotifyDesk';
import VolunteerCategories from '../components/Schedule/VolunteerSchedule/VolunteerCategories';
import VolunteerDashboardNew from '../components/Dashboard/VolunteerDashboardNew';
import VolunteerCheckinByDay from '../components/Volunteers/VolunteerCheckinByDay';
import VolunteerContactInfo from '../components/Reports/VolunteerContactInfo';
import VolunteerEmailLog from '../components/Email/VolunteerEmailLog';
import ScheduleOverview from '../components/Schedule/VolunteerSchedule/ScheduleOverview';
import VolunteerList from '../components/Volunteers/EditVolunteers/VolunteerList';
import VolunteerWaiverList from '../components/Volunteers/VolunteerWaiverList';
import VolunteerTimeAndShifts from '../components/Volunteers/VolunteerTimeAndShifts';
import VolunteerContactInfoByDay from '../components/Reports/VolunteerContactInfoByDay';
import VolunteerLog from '../components/Schedule/ScheduleLog/VolunteerLog';
import EmailToVolunteerShifts from '../components/Communication/EmailToShifts/EmailToVolunteerShifts';
import ShirtSizes from '../components/Volunteers/ShirtSizes';


const VolunteerAdminRoute = (props) => {
    const tid = useSelector(state => state.tid)
    const user_id = useSelector(state => state.user_id)
    const status = useSelector(state => state.status)
    const referee_approval_required = useSelector(state => state.referee_approval_required)
    const admin_type = useSelector(state => state.admin_type)


    return (
        <div>

            <Switch>
                <Route path='/volunteer/shirtsizes' component={ShirtSizes}/>
                <Route path='/volunteer/emailshifts' component={EmailToVolunteerShifts}/>
                <Route path='/volunteer/schedulelog' component={VolunteerLog}/>
                <Route path='/volunteer/volunteertimeandshifts' component={VolunteerTimeAndShifts}/>
                <Route path='/volunteer/volunteerwaiverlist' component={VolunteerWaiverList}/>
                <Route path='/volunteer/volunteerList' component={VolunteerList}/>
                <Route path='/volunteer/overview' component={ScheduleOverview}/>
                <Route path='/volunteer/emaillog' component={VolunteerEmailLog}/>
                <Route path='/volunteer/contactinfo' component={VolunteerContactInfo}/>
                <Route path='/volunteer/contactinfobyday' component={VolunteerContactInfoByDay}/>
                <Route path='/volunteer/categories' component={VolunteerCategories}/>
                <Route path='/volunteer/courtmonitor/:tid' component={MonitorNotifyDesk} />
                <Route path='/volunteer/groups' component={VolunteerCheckedInAndGroups} />
                <Route path='/admin' component={Setup} />
                <Route path='/volunteer/schedulemanager' component={AddVolunteerToSchedule} />
                <Route path='/volunteer/courtMonitors' component={CourtMonitors} />
                <Route path='/volunteer/volunteerSchedule' component={VolunteerSchedule} />
                <Route path='/volunteer/checkin' component={VolunteerCheckinByDay}/>
                {/* <Route path='/volunteer/checkin' component={VolunteerCheckin} /> */}
                <Route path='/volunteer' component={VolunteerDashboardNew}/>
                

            </Switch>

        </div>
    )
}
export default VolunteerAdminRoute