import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from 'react-responsive-modal';
import './Login.css';
import { updateUser } from '../../ducks/reducer';
import './LoginBar.css'
import Login from './Login'

class LoginBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            emailIsValid: true,
            password: '',
            errorMessage: '',
            passwordMessage: '',
        }
    }

    isValidEmailAddress = (address) => {
        return !!address.match(/.+@.+/)
    }

    login = async () => {
        this.setState({ errorMessage: '' })
        let user = {
            email: this.state.email,
            password: this.state.password
        }
        try {
            if (this.state.emailIsValid) {

                let res = await axios.post('/auth/login', user)
                if (res.data.message == 'Email not found, please signup') { this.setState({ email: '', password: '', errorMessage: 'Email not found, please signup', showLogin: true }) }
                else if (res.data.message == 'incorrect password, please try again') { this.setState({ email: '', password: '', errorMessage: 'incorrect password, please try again', showLogin: true }) }
                else if (res.data.message) {
                    this.setState({
                        email: '', password: '', errorMessage: 'Error logging in, please try again', showLogin: true
                    })
                }
                else if (res.data.user_id) {
                    await this.props.updateUser(res.data)
                    if (this.props.showLogin) { this.props.showLogin() }
                    if (this.props.updateUser) { this.props.updateUser() }
                }

            }

        } catch (err) {
            if (err.message) { this.setState({ errorMessage: 'Error logging in, please try again' }) }
            this.setState({
                email: '',
                password: '',
                loginFailed: true
            })

        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    enterPressed(event) {
        const code = event.keyCode || event.which;
        if (code === 13) {
            this.login()
        }
    }


    render() {
        const { email, password } = this.state;
        return (
            <div className='container'>
                <div className='d-lg-none loginBar'>
                    <button
                        className='login-button'
                        onClick={() => this.setState({ showLogin: true })} > Login</button>
                    <button className='register-button' onClick={() => this.props.history.push('/register')}>Register</button>

                </div>

                <div className='col-12 loginBar d-none d-lg-flex'>

                    <input type="text"
                        name='email'
                        placeholder='email'
                        autoComplete={this.state.showLogin ? 'off' : 'on'}
                        value={email}
                        onChange={this.handleChange}
                        onBlur={() => this.setState({
                            emailIsValid: this.isValidEmailAddress(this.state.email), errorMessage: ''
                        })} />

                    <input type="password"
                        placeholder='password'
                        autoComplete={this.state.showLogin ? 'off' : 'on'}
                        value={password}
                        onKeyPress={e => this.enterPressed(e)}
                        name='password'
                        onChange={this.handleChange} />



                    <p style={{ color: 'red' }}>{this.state.emailIsValid ? '' : 'invalid email'}</p>
                    <button className='login-button' onClick={this.login}>Login</button>
                    <button className='register-button' onClick={() => this.props.history.push('/register')}>Register</button>
                </div>
                <Modal open={this.state.showLogin} center showCloseIcon={true} onClose={() => this.setState({ showLogin: false })} closeIconSize={20} closeOnEsc={true} >
                    <Login
                        errorMessage={this.state.errorMessage}
                        clearErrorMessage={() => this.setState({ errorMessage: '' })} />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (reduxState) => {
    return {
        user_id: reduxState.user_id
    }
}
const mapDispatchToProps = {
    updateUser
}
export default (connect(mapStateToProps, mapDispatchToProps))(withRouter(LoginBar));