import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import VolunteerCaptainScheduling from '../components/Volunteers/VolunteerCaptains/VolunteerCaptainScheduling';
import CaptainManageSchedule from '../components/Volunteers/VolunteerCaptains/CaptainManageSchedule';
import VolunteerCheckinByDayForCaptain from '../components/Volunteers/VolunteerCheckinByDayForCaptain';
import CaptainVolunteerListWithSchedule from '../components/Volunteers/VolunteerCaptains/CaptainVolunteerListWithSchedule';
import VolunteerCaptainDashboard from '../components/Volunteers/VolunteerCaptains/VolunteerCaptainDashboard';
import VolunteerCaptainNav from '../components/Nav/VolunteerCaptainNav';
import EmailToVolunteerShiftsCaptains from '../components/Communication/EmailToShifts/EmailToVolunteerShiftsCaptains';



const VolunteerCaptainRoutes = (props) => {
    const volunteer_captain = useSelector(state => state.volunteer_captain)
    return (
        <div className='container-fluid'>
            {volunteer_captain ? <VolunteerCaptainNav/>:null}

            <Switch>
                <Route path='/volunteercaptain/emailshifts' component={EmailToVolunteerShiftsCaptains}/>
                <Route path='/volunteercaptain/schedulebyvolunteer' component={CaptainVolunteerListWithSchedule}/>
                <Route path='/volunteercaptain/shifsbyday' component={VolunteerCheckinByDayForCaptain}/>
                <Route path='/volunteercaptain/managevolunteers' component={CaptainManageSchedule}/>
                <Route path='/volunteercaptain/scheduling' component={VolunteerCaptainScheduling}/>
                <Route path='/volunteercaptain' component={VolunteerCaptainDashboard}/>
                <Route path='/admin' component={VolunteerCaptainDashboard}/>
            </Switch>

        </div>
    )
}
export default VolunteerCaptainRoutes