import React, { Component } from 'react';
import Moment from 'react-moment';
import { connect } from "react-redux"
import 'moment-timezone';
import MUIDataTable from 'mui-datatables'
import './RefDesk.css'





class RefCourtAssignments extends Component {


    assignCourt = (e) => {
        this.props.handlePickCourt(e[0])
    }

    completeMatch = (e) => {
        let court_num = e[0]
        let id = e[4]
        let ref_id = e[4]
        this.props.handleFinishMatch(court_num, id, ref_id)
    }


    render() {

        // const mappedCourts = this.props.courtStatus.map(court => {
        //     return (
        //         <div>
        //             <span style={{ "width": "5px" }}>{court.court_num}</span>
        //             <span style={{ width: "10%" }}>{court.first}</span>
        //             <span style={{ width: "100px" }}>{court.last}</span>
        //         </div>
        //     )
        // })

        const columns = [
            { name: "court_num", label: "Court Number", options: { filter: true, sort: true, } },
            { name: "first", label: "First", options: { filter: true, sort: true, } },
            { name: "last", label: "Last", options: { filter: true, sort: true, } },


            {
                name: "start_time", label: "Start Time", options: {
                    filter: true, sort: true,
                    customBodyRender: (value) => {
                        return (
                            <div>
                                {value > 0 ?
                                    <div>


                                        <Moment  format="h:mm a   MM/DD/YYYY" >
                                            {value}
                                        </Moment>
                                    </div>
                                    : ''}
                            </div>
                        )
                    }
                }
            },
            {
                name: "ref_id", label: "assign", options: {

                    filter: false, sort: false,
                    customBodyRender: (value, tableMeta, updateValueFn) => {
                        return (
                            <div>
                                {value ? <button
                                    className='finish-button'
                                    name='court'
                                    value={tableMeta.rowData}
                                    onClick={e => this.completeMatch(tableMeta.rowData)}
                                >
                                    Finish
                                </button> :

                                    <button
                                        className='assign-button'
                                        name='court'
                                        value={tableMeta.rowData}
                                        onClick={e => this.assignCourt(tableMeta.rowData)}
                                    >
                                        Assign
                                    </button>}
                            </div>



                        )
                    }

                }
            },
            { name: "current_match", label: "id", options: { display: false } },




        ]




        return (
            <div className="container">
                <div className='RefCourtAssignments'>



                    <MUIDataTable
                        title={'Court Assignments'}
                        data={this.props.courtStatus}
                        columns={columns}
                        options={{ print: false, download: false, rowsPerPage: 100, selectableRows: 'none' }}

                    />

                    {/* {mappedCourts} */}




                </div>
            </div>

        )
    }
}


const mapStateToProps = reduxState => {
    return {
        zone: reduxState.zone
    }
}


export default connect(
    mapStateToProps,
)(RefCourtAssignments);