import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import ApprovedList from './ApprovedList'
import SelectRefereeToApprove from './SelectRefereeToApprove'
import ApprovalRequests from './ApprovalRequests'

const ApprovalManager = props => {
    const [approved, setApproved] = useState([])

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getApprovedReferee() }, [])


    const getApprovedReferee = () => {
        axios.get(`/api/getApprovedReferees/${tid}`).then(
            res => {
                setApproved(res.data)
            }
        )
    }

    const addApproval = (ref_id) => {
        axios.post(`/api/addRefereeToApprovedList/${tid}`, { ref_id }).then(res => {
            getApprovedReferee()
        })
    }

    return (
        <div className='container-fluid'>
            <SelectRefereeToApprove
                addApproval={addApproval} />
            <ApprovalRequests
                getApprovedReferee={getApprovedReferee} />

        </div>
    )
}
export default ApprovalManager