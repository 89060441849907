import React from 'react'
import axios from 'axios'

const ReceivedW9 = props => {

    const receivedW9 = (received) => {
        axios.put(`/api/payment/receivedW9`, { id: props.id, received }).then(res => {
            props.setW9(received)
            props.close()
        })

    }

    return (
        <div>
            <button onClick={()=>receivedW9(true)}>Yes</button>
            <button onClick={()=>receivedW9(false)}>No</button>
        </div>
    )
}
export default ReceivedW9