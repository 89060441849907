import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './VolunteerSchedule.css'
import AddEditVolunteerCategories from './AddEditVolunteerCategory'
import AddVolunteerCategoryAdmin from './AddVolunteerCategoryAdmin'
import CaptainName from './CaptainName'


const VolunteerCategories = props => {
    const [categories, setCategories] = useState([])
    const [add, setAdd] = useState(false)
    const [category, setCategory] = useState({})
    const [showLoader, setShowLoader] = useState(false)
    const [addAdmin, setAddAdmin] = useState(undefined)

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getCategories() }, [tid])

    const getCategories = () => {
        setShowLoader(true)
        axios.get(`/api/volunteer/getVolunteerCategories/${tid}`).then(
            res => {
                setShowLoader(false)
                setCategories(res.data)
                if(props.get){props.get()}
                setAdd(false)
            }
        )
    }

    const handleEditCategory = (c) => {
        setAdd(true)
        setCategory(c)
    }

    const handleShowAdd = () => {
        // window.scrollTo(0, 200)
        setAdd(add => !add)
    }

    return(
        <div className='container-fluid'>
          <h3>Volunteer Categories</h3>
          <p>You can assign captains to each category.  Some example of categories include court monitors, setup, parking, food, etc.</p> 
          <p>Within the categories you will create specific shifts and job duties.  When you are ready for volunteers to signup for a category change make sure 'show' is set to yes.  If you are doing setup and do not want the category visible have 'show' set to no.</p>
            <button onClick={handleShowAdd}>Add Category</button>
            

            <AddEditVolunteerCategories 
                row={category}
                getCategories={getCategories}
                edit={category}
                show={add}
                close={handleShowAdd}/>

                {showLoader ? <LinearProgress />:''}
            <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>Edit</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">Show</TableCell>
            <TableCell align="left">Captain</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map((row, i) => (
            <TableRow key={i}>
            <TableCell component="th" scope="row">
             <button onClick={()=>handleEditCategory(row)}>edit</button>
            </TableCell>
            <TableCell> {row.name}</TableCell>
            <TableCell align="left">{row.description}</TableCell>
            <TableCell align="left">{row.show ? 'yes': 'no'}</TableCell>
            <TableCell align="left">
                <div>
                {row.admin.map((name, i)=> (
                    <CaptainName 
                    key={'c'+i}
                    name={name}
                    get={getCategories}/>
                ))}
                <AddVolunteerCategoryAdmin 
                get={getCategories}
                vcid={row.vcid}/>
                </div>

            </TableCell>
          </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  )
}
export default VolunteerCategories