import React, { Component } from 'react'
import io from 'socket.io-client'

class LineSocket extends Component {


    componentDidMount() {
        this.setSocketListeners()
    }

    componentWillUnmount() {
        this.socket.disconnect()
    }

    setSocketListeners = () => {
        console.log('started')
        this.socket = io()

        this.socket.emit('joinRoom', `board:${this.props.tid}`)

        this.socket.on('board', results => {
            // console.log('hit socket', results)

            if (results.msg === 'assign') {
                this.props.getCourts()
            }
            else if (results.msg === 'finish') {
                this.props.getCourts()
            }
            else if (results.msg === 'getCourtsOnly') {
                this.props.getCourts()
            }
            else if (results.msg === 'line') {
                this.props.getCourts()
            }
        })

        this.socket.on('line', results => {
            if (results.msg === 'line get all') {
                this.props.getCourts()
            }
            else if (results.msg === 'group') {
                this.props.getGroups()
                this.props.getCheckedInLineJudges()
            }
        })
    }

    joinRoom = () => {
        this.socket.emit('joinRoom', `board:${this.props.tid}`)
    }

    render() {
        return (
            <div>

            </div>
        )
    }
}

export default LineSocket