import React, { Component } from 'react'
import axios from 'axios'
import { connect } from "react-redux"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CSVReader from 'react-csv-reader'

const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header =>
        header
            .toLowerCase()
            .replace(/\W/g, '_')
}

class ImportPlayers extends Component {
    constructor() {
        super()
        this.state = {
            players: [],
            category: '',
            error: '',
            completed: false,
            loader: false
        }
    }

    addNames = () => {
        if (this.state.players.length === 0) { this.setState({ error: 'Please upload your CVS file' }) }

    }

    addPlayers = () => {
        if (this.state.players.length === 0) { this.setState({ error: 'Please upload your CVS file' }) } else {
            axios.post(`/api/score/addPlayersCSV/${this.props.tid}`, { list: this.state.players }).then(
                res => {
                    this.setState({ completed: true, referees: [] })
                }
            )
        }
    }

    handleUpload = data => this.setState({ players: data })

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }


    render() {

        return (
            <div className='container shadow mb-10 bg-white rounded' >

                <div className='row'>
                    <div className='col-12 email '>
                        <h4>Have a list of Players to upload?</h4>
                        <h6>Have a CSV file with name and  phone?</h6>
                        <h6>** Before uploading make sure to format your CSV file.  File needs to include only two columns titled: name, phone.  Phone numbers need to be 10 numbers. US numbers only, do not include the +1 </h6>

                    </div>
                    <div className='col-12'>
                        <h2>Import Players</h2>
                        <CSVReader
                            cssClass="react-csv-input"
                            label="Select CSV to upload"
                            onFileLoaded={this.handleUpload}
                            parserOptions={papaparseOptions}
                        />
                        <div className='emailRecipients'>
                            <TableContainer>
                                <Table c size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>name</TableCell>
                                            <TableCell align="left">phone</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.players.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="left">{row.phone}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                        <h4>Please make sure all columns are shown.  If one is missing then your file might have had an incorrect column title.  Please correct and re-upload.</h4>
                        <button onClick={this.addPlayers}>My data looks correct, Submit</button>
                        <h3 style={{ color: 'red' }}>{this.state.error}</h3>
                        <h3 style={{ color: 'red' }}>{this.state.completed ? 'Upload Successful' : ''}</h3>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name
    }
}
export default connect(
    mapStateToProps,
)(ImportPlayers)