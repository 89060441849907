import React, { Component } from 'react';
import axios from 'axios'
import { connect } from 'react-redux'
import Moment from 'react-moment';
// import 'moment-timezone';

class MatchCount extends Component {
    constructor() {
        super()
        this.state = {
            count: [],
            total: 0,
            stats: {},
            days: [],
            date: ''
        }
    }

    componentDidMount() {
        this.getMatchCount()
        this.getTournamentDays()
    }



    getMatchCount = () => {
        axios.get(`/api/matchstarter/getMatchCount/${this.props.tid}`).then(res => {
            this.setState({ count: res.data.count, total: res.data.total, date: 'All' })
        })
    }

    getTournamentDays = () => {
        
        axios.get(`/api/matchstarter/getTournamentDaysMatchStarter/${this.props.tid}`).then(res => {
            let days = res.data.sort((a, b) => a.start_time.localeCompare(b.start_time))
            this.setState({ days })
        })
    }
    getMatchCountByDay = (day) => {
        axios.post(`/api/matchstarter/getMatchCountMatchStarterByDay/${this.props.tid}`, { day }).then(res => {
            this.setState({ count: res.data.count, total: res.data.total, date: day })
        })
    }
    sortByCount = () => {
        let s = this.state.count.sort((a, b) => b.count.localeCompare(a.count))
        this.setState({ count: s })
    }
    sortByName = () => {
        let s = this.state.count.sort((a, b) => a.last.localeCompare(b.last))
        this.setState({ count: s })
    }

    render() {
        const mappedDays = this.state.days.map((day, i) => {
            // let d = day.start_time.slice(6, 5)
            return (
                <div key={i}>
                    <button
                        className='date-buttons'
                        onClick={() => this.getMatchCountByDay(day.start_time)} style={day.start_time === this.state.date ? { background: '#009CAE' } : {}}>
                            {day.day_display}
                    </button>
                </div>
            )
        })


        const mappedResults = this.state.count.map((ref, i) => {
            return (
                <div key={i} className='col-4'>
                    <div className='individual-match-count'>
                        <div className='match-box'>
                            {ref.count}
                        </div>
                        <div className='match-box'>
                            | <small>{ref.minutes}</small>
                        </div>
                       
                        <div className='signup-credential-box'>

<div style={{ margin: '3px', width: '1em' }} className={ref.credentials === 'Certified' ? 'ref-list certified' : ref.credentials === 'Level 2' ? 'ref-list level2' : ref.credentials === 'Level 1' ? 'ref-list level1' :

    'ref-list Volunteer'}>
    {ref.credentials === 'Certified' ? 'C' :
        ref.credentials === 'Level 2' ? '2' : ref.credentials === 'Level 1' ? '1' : '-'}
</div>
                        </div>
                        <span> {ref.last}, {ref.first}</span>
                    </div>
                </div>

            )
        })

        return (
            <div className="">
                <div className='row'>
                    <div className='col-12' style={{ background: 'white', padding: '15px' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {mappedDays}
                            <button
                                className='date-buttons'
                                onClick={this.getMatchCount}
                                style={this.state.date === 'All' ? { background: '#009CAE' } : {}}>All</button>
                        </div>
                        <h4>Match Count {this.state.total}</h4>
                        <div><small>First column is match starter count, second column is total minutes.</small></div>
                        <button className='button-none' onClick={this.sortByCount}>Sort By Count</button>
                        <button className='button-none' onClick={this.sortByName}>Sort By Name</button>



                        <div className='col-12 MatchCountByName'>

                            {mappedResults}
                        </div>
                        <p><small>Count includes matches currently in progress.</small></p>

                        <div>

                        </div>

                    </div>
                </div>

            </div>

        )
    }
}


function mapStateToProps({ tid, name, url,
    location,
    num_of_courts, zone }) {
    return {
        tid, name, url,
        location,
        num_of_courts, zone
    }
}

export default connect(mapStateToProps, {})(MatchCount);