import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Moment from 'react-moment';
import VolunteerCheckInList from './SchedulingUtilities/VolunteerCheckInList'
import LinearProgress from '@material-ui/core/LinearProgress'
import VolunteerCheckoutAllVolunteers from './VolunteerCheckoutAllVolunteers';

const VolunteerCheckinByDay = props => {
    const [dates, setDates] = useState([])
    const [day, setDay] = useState('')
    const [volunteers, setVolunteers] = useState([])
    const [loader, setLoader] = useState(false)
    const [noData, setNoData] = useState(false)

    const tid = useSelector(state => state.tid)
    const captain = useSelector(state => state.volunteer_captain)


    useEffect(
        () => { getVolunteerDates() }, [tid])
    useEffect(
        () => { getVolunteers() }, [day])


    const getVolunteerDates = async () => {
        await axios.get(`/api/getVolunteerDates/${tid}`).then(
            res => {
                setDates(res.data)
                let today = new Date().toISOString().slice(0, 10)
                setDay(today)
            }
        )
    }
    const getVolunteers = async () => {
        if(!day){return}
        setLoader(true)
        setVolunteers([])
        await axios.post(`/api/getVolunteerByDay/${tid}`, {day}).then(
            res => {
                setLoader(false)
                setVolunteers(res.data)
                if(res.data.length === 0){
                    setNoData(true)
                } else {
                    setNoData(false)
                }
                
            }
        )
    }

    return(
        <div className='container-fluid'>
            {day ?
                            <h3> 
                            <Moment format="ddd MM/DD/YYYY" >
                        {day}
                    </Moment> Volunteer List</h3>:''}
                    <VolunteerCheckoutAllVolunteers get={getVolunteers}/>
            <div className='tips' style={{textAlign: 'left'}}>
                                Select Date
                                
                            <select
                                name={day}
                                value={day}
                                className='dropdown'
                                onChange={e=>setDay(e.target.value)}
                            >
                                <option key={'s'} value={''}>Select...</option>
                                    {dates.map((day, i)=> (
                                        <option key={i+'o'} value={day.start_time} key={i}>
                                        {day.start_time}
                                        </option>
                                    ))}
                            </select>
                           
                            </div>
                            
                            
                            {volunteers.length> 0 ?

                            <VolunteerCheckInList 
                                volunteers={volunteers}/>:''}
                                {loader ?
            <LinearProgress color="secondary"/>:''}
            {noData ? 
            'No Volunteers found for this day':''}
                            

        </div>
    )
}
export default VolunteerCheckinByDay