import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';



const SendEmail = props => {
    const [subject, setSubject] = useState('')
    const [body, setBody] = useState('')
    const [saveEmail, setSaveEmail] = useState(false)
    const [templates, setTemplates] = useState([])
    const [showTemplates, setShowTemplates] = useState(false)
    const [saveTemplate, setSaveTemplate] = useState(false)
    const [templateName, setTemplateName] = useState('')

    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)
    const reply = useSelector(state => state.email)
    const author = useSelector(state => state.ref_id)
    const tournamentName = useSelector(state => state.name)

    let {category, selected, clearState} = props

    const classes = useStyles();

    const getTemplates = () => {
        axios.get(`/api/getTemplates/${author}`).then(
            res => {
                setTemplates(res.data)

            }
        )
    }

    const handleGetTemplates = () => {
        if(showTemplates){
            setShowTemplates(false)
        }else {
            setShowTemplates(true)
            getTemplates()
        }
    }



    const sendEmail = async () => {

        if (selected.length === 0) { return toast.warning('Select Email Recipients') } else {
            setLoader(true)
            if (saveTemplate) { axios.post(`/api/saveTemplate`, { ref_id: author, name: templateName, subject, body, type: 'email' }).then(res => {toast.success(`Template Saved`)
            setTemplateName('')
            }
            ) }

            if(saveEmail){
                axios.post(`/api/email/saveEmail`, { tid, body, reply, subject, tournamentName, category, author  }).then(
                    resp => toast.success('Email Saved')
                )
            }
            await selected.forEach(async x => {
                let s = selected.splice(0, 25)
                await handleSendEmail(s)
            })
            await clearState()
            await setLoader(false)
            await toast.success('email sent')
        }
    }

    const handleSendEmail = async (s) => {
        axios.post(`/api/email`, { email: s, tid, body, reply, subject, tournamentName, category: category === 'other' ? 'volunteer':category, author  }).then(
            res => {
                if (res.data === 'error') {
                    toast.error('Error Sending Email')
                } else {
                    props.clearState()
                    setSubject('')
                    setBody('')
                }
                
            }
        )
    }

    const handleBody = (value) => {
        setBody(value)
    }

    const handleSelectTemplate = (e) => {
        setBody(e.body)
        setSubject(e.subject)
        setShowTemplates(false)
    }



    return (
        <Paper className='Paper'>
            <h3>Email</h3>
            <button onClick={handleGetTemplates}>Create Message From Saved Template</button>
            <div style={{display: 'flex', flexDirection: 'column'}}>
            {showTemplates && templates.map((t, i)=> (
                <div key={i}
                    style={{cursor: 'pointer', textAlign: 'left', borderBottom: '1px dotted grey'}}
                    onClick={()=>handleSelectTemplate(t)}>{t.name}- {t.subject}</div>
            ))}
            </div>
            
                    <div className={classes.email}>
                        New Message:
                        <p style={{ color: 'red' }}>{selected.length < 1 && 'Select Email Recipients'}</p>
                        <div style={{display: 'flex', overflow: 'wrap', flexWrap: 'wrap', textAlign:'left', background: '#c2ff89', padding: '15px'}}>
                            {selected.map((name, i)=>(
                                <div key={i} style={{width: '200px'}}>{`${name.name}`}</div>
                            ))}
                        </div>
                        <div> Tournament name will be added to the end of the subject</div>
                        <div>
                            <input type="text"
                                style={{width:'300px'}}
                                className={classes.emailBody}
                                placeholder='Subject'
                                value={subject}
                                onChange={e => setSubject(e.target.value)} />
                                :{tournamentName}
                               
                        </div>
                                
                        <div>

                            <ReactQuill
                                style={{ background: 'white' }}
                                value={body}
                                modules={SendEmail.modules}
                                onChange={handleBody} />
                                <p>Reply-to email will be your email address.</p>
                        
                        <legend> 
                            
                            <input
                                type="checkbox"
                                name="saveTemplate"
                                checked={saveTemplate}
                                value={saveTemplate}
                                onChange={() => setSaveTemplate(!saveTemplate)} />
                                Save message as template
                                    
                            </legend>
                            {saveTemplate &&
                            <div>
                            <input type="text"
                                className={classes.emailBody}
                                placeholder='Template Name'
                                value={templateName}
                                onChange={e => setTemplateName(e.target.value)} />
                        </div>}
                        <legend> 
                            <input
                                type="checkbox"
                                placeholder='Save to message board'
                                name="saveEmail"
                                checked={saveEmail}
                                value={saveEmail}
                                onChange={() => setSaveEmail(!saveEmail)} />
                                Add Email To Message Board
                                    
                            </legend>
                                

                            {selected.length > 0 ?
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={loader}
                                    onClick={sendEmail}
                                >
                                    {loader ?
                                        <CircularProgress />
                                        : 'Send Email'}</Button> :
                                <button onClick={selected} className='inactive-button'>Send Email</button>}



                            {loader ? <h4>Email sending... please wait and do not refresh or leave page</h4> : ''}
                            <p>To send attachments (photos, videos) upload them to dropbox, google docs or your choice in file storage.  You can then include a link in the email.</p>

                            <div style={{border: '2px solid #019CAE', fontSize: '1.5em', background: '#d6fbff'}}>Email communication can only be used for this tournament.  It is against site policies to use communication to solicit for other tournaments or products. </div>
                        </div>
                    </div>
                </Paper>
    )
}
export default SendEmail

const useStyles = makeStyles({
   
    error: {
        color: 'red'
    },
    credentials: {
        maxWidth: '40px'
    },
    count: {
        maxWidth: '50px'
        },
    status: {
        maxWidth: '60px'
        },
    email: {
        width: '98%'
    },
    emailBody: {
        width: '100%',
        margin: '10px'
    }
});

SendEmail.modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      [{ 'color': [] }, { 'background': [] }]
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }
