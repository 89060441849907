import React, { useState} from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';



const SendText = props => {
    const [text, setText] = useState('')
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)
    const tournamentName = useSelector(state => state.name)

    let {selected, clearState} = props

    const classes = useStyles()

    const sendText = async () => {

        if (selected.length === 0) { return toast.warning('Select Text Recipients') } else {
            setLoader(true)
            await selected.forEach(async x => {
                let s = selected.splice(0, 25)
                await handleSendText(s)
            })
            await clearState()
            await setLoader(false)
            await toast.success('Text sent')
        }
    }

    const handleSendText = async (s) => {
        axios.post(`/api/customText`, { text: s, textBody: text, tid, type: props.category }).then(
            res => {
                if (res.data === 'error') {
                    toast.error('Error Sending Text')
                    
                }
                else {
                    props.clearState()
                    setText('')
                }
                
            }
        )
    }

    return (
        <Paper className='Paper'>
            <h3>Text</h3>
            
                    <div className={classes.email}>
                        New Message:
                        <p style={{ color: 'red' }}>{selected.length < 1 ? 'Select Text Recipients' : ''}</p>

                        <div>
                            <input type="text"
                                className={classes.text}
                                placeholder='text'
                                value={text}
                                maxLength='255'
                                onChange={e => setText(e.target.value)} />
                        </div>
                        <div>

                            {selected.length > 0 ?
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={loader}
                                    onClick={sendText}
                                >
                                    {loader ?
                                        <CircularProgress />
                                        : 'Send Text'}</Button> :
                                <button className='inactive-button'>Send Text</button>}

                            {loader ? <h4>Text sending... please wait and do not refresh or leave page</h4> : ''}
                        </div>
                    </div>
                </Paper>
    )
}
export default SendText

const useStyles = makeStyles({
    error: {
        color: 'red'
    },
    text: {
        width: '98%'
    },
    
});
