import React, {useState} from 'react'
import NotificationNameList from './NotificationNameList'
import Dialog from '@material-ui/core/Dialog';


export default function NotificationNameDialog(){
    const [show, setShow] = useState(false)

    return(
        <div style={{textAlign: 'left'}}>
            <button onClick={()=>setShow(!show)}>{show ? 'Close':'Notification Contact List'}</button>

                {show && <NotificationNameList />}

        </div>
    )
}
