import React, { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format';


const ItemDetail = props => {

    return (
        <div className='item'>
            <div className='item-title'>
                {props.item.budget_title}
                <i className="fas fa-info-circle"
                    style={{ padding: '0 5px' }}
                    onClick={() => props.handleEditBudgetItem(props.item)}></i>
            </div>

            <div className='item-rate'>
                <NumberFormat value={props.item.rate} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </div>
            <div className='item-quantity'>
                {props.item.quantity}
            </div>
            <div className='item-total'>
                <NumberFormat value={props.item.total} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>

        </div>
    )
}
export default ItemDetail