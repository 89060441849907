import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper'
import MatchDetail from './MatchDetail'
import AuxDetail from './AuxDetail';


const AuxTable = props => {
    

    return(
        <div>
            <h2>Aux Assignments</h2>
              <TableContainer component={Paper}>
      <Table  size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
          <TableCell></TableCell>
            <TableCell>Court Num</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Credentials</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Edit</TableCell>
            <TableCell>Paid</TableCell>
            <TableCell>Notes</TableCell>
            <TableCell>Delete</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
        
          {props.matches
            .map((row, i) => (
                <AuxDetail
           key={row.id}
            row={row}
            i={i}
            getLists={props.getLists}
            handleUpdate={props.handleUpdate}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>

        </div>
    )
}
export default AuxTable