import React from 'react'
import { Link } from 'react-router-dom';


const ResourcesHomepage = () => {

    return (
        <div>
            <h2>Helpful Resources</h2>
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <h4>Referee</h4>
                    <a target="_blank" rel="noopener noreferrer"
                        href="https://www.usapa.org/docs/ifp/USAPA-Rulebook.pdf">
                        <p>IFP/ USAPA Rule Book</p></a>
                    <a target="_blank" rel="noopener noreferrer"
                        href="https://host7.viethwebhosting.com/~pckl/docs/2020-USAPA-CaseBook-2020-F3.pdf">
                        <p>2020 USAPA Official Referee Handbook</p></a>
                    <a target="_blank" rel="noopener noreferrer"
                        href="https://www.memberleap.com/members/content.php?content_id=37131371&org_id=PCKL">
                        <p>USAPA Case Book</p></a>
                    <a target="_blank" rel="noopener noreferrer"
                        href="https://equipment.usapa.org/paddle-list/">
                        <p>USAPA Approved Paddle List</p></a>
                </div>
            </div>
        </div >
    )
}
export default ResourcesHomepage