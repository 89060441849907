import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from "react-redux"
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'
import ReactQuill from 'react-quill'
import { toast } from 'react-toastify';


const VolunteerWaiver = props => {
    const [waiver, setWaiver] = useState('')
    const [signature, setSignature] = useState('')
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState('')

    const ref_id = useSelector(state => state.ref_id)
    const last = useSelector(state => state.last)
    const first = useSelector(state => state.first)
    const tid = useSelector(state => state.tid)

    const classes = useStyles()

    useEffect(
        () => { getWaiver() }, [props.show])

    const getWaiver = () => {
        if(props.show){
            setLoader(false)
        axios.get(`/api/volunteer/getVolunteerWaiver/${tid}`).then(
            res => {
                setLoader(false)
                setWaiver(res.data.waiver)
            }
        )
    }
}
const submit = () => {
    axios.put(`/api/volunteer/signVolunteerWaiver/`, {tid, ref_id, signature}).then(
        res => {
            toast.success('Waiver Signed, Please register for shifts.')
            props.get()
            props.close()
        }
    )
    }

const handleSubmit = () => {
    if(signature.toUpperCase().includes(first.toUpperCase()) && signature.toUpperCase().includes(last.toUpperCase())){
        submit()
    }else {setError('Signature does not match your profile.')}
}

    return (
        <div>
            <h3>Thank you for volunteering</h3>
            <p>Before you can register for shifts we need to register you as a volunteer.</p>
            <p>Please read and sign below.</p>
            {loader && <LinearProgress color="secondary"/>}
            <div className={classes.waiver}>
            <ReactQuill
                value={waiver}
                readOnly={true}
                theme={"bubble"}
            />
            </div>
            <div className={classes.signature}>
                <div>
                    Type full name to sign
                </div>
                <input type="text" value={signature} onChange={e=>setSignature(e.target.value)} />
            </div>
            <div className={classes.signature}>
                <button onClick={handleSubmit}>Submit</button>
            </div>
            
            <h5>{error}</h5>

        </div>
    )
}
export default VolunteerWaiver

const useStyles = makeStyles({
    waiver: {
        maxHeight: '400px',
        overflow: 'scroll'
    },
    signature: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
})