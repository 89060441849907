import React from 'react'
import './ColorPicker.css'

//To use props.color() and color needs to be passed into component

const ColorPicker = props => {

    const handleColor = (c) => {
        props.setColor(c)
    }

    return (
        <div >
           

            <div style={{display: 'flex', flexWrap:'wrap'}}>
                {['FFDE15', 'F9A61C', 'F9A61C', 'EE3D3E', 'ED1C24', 'A3248F',
                '5C2D91', '20409B', '0066B4', '01AAAD', '00A560',
                '8FFFEF', '8DCEFF', 'EDD4BB','E38B8B', 'FD6E79', 'F78356', 'F9BA55', 'EBEC95', 'F6B3AC', 'F69B7F', 'E66947', 'D1906C', '4465C0', '8AA2C6', 'F5A713', '6E6676', '62ABEA', 'F16BB2', 'FFC523', 'A1D543'].map(color =>(
                    <ColorCircle handleColor={props.setColor} color={'#'+color} selectedColor={props.color}/>
                ))}
                <i className="fas fa-ban circle"
                    onClick={() => handleColor('')}></i>
            </div>
            <div>
                        {`Custom HEX  `} 
                        <input type="text" 
                        style={{background:props.color}}
                        value={props.color}
                        onChange={e=>props.setColor(e.target.value)} />
                    </div>
                    

        </div >

    )
}
export default ColorPicker

const ColorPickerDiv = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '15px'
}

const colorRow = {
    display: 'flex',
    alignItems: 'center'
}

const ColorCircle = ({color, selectedColor, handleColor}) => {
    return(
        <span style={{background: color}}className={selectedColor === color ? 'selected-circle' : 'circle'}
                    onClick={() => handleColor(color)}></span>
    )
}
