import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import Listing from './Listing'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 37.0902,
  lng: -95.7129
};



const MapOfTournaments = () => {
    const [tournaments, setTournaments] = useState([])
    const [locationSearch, setLocationSearch] = useState('')
    const [nameSearch, setNameSearch] = useState('')
    const [organization, setOrganization] = useState('')
    const [usapa, setUsapa] = useState(null)

    useEffect(
        () => { getTournaments() }, [])

    const getTournaments = () => {
        axios.get(`/api/t/getTournaments`).then(
            res => {
                setTournaments(res.data)
            }
        )
    }

    const clearSearch = () => {
        setNameSearch('')
        setLocationSearch('')
        setOrganization('')
        setUsapa(null)
    }

    const mappedTournaments = tournaments
        .filter(obj => obj.name.toUpperCase().includes(nameSearch.toUpperCase()))
        .filter(obj => obj.location.toUpperCase().includes(locationSearch.toUpperCase()))
        .filter(obj => obj.organization.includes(organization))
        .filter(obj => obj.usapa != usapa)


        .map(t => {
            return (
                <div key={t.tid} style={{ color: 'black' }}>
                    <Link to={`/signup/${t.tid}`}
                    >
                        <Listing
                            t={t} />
                    </Link>
                </div>
            )
        })

        const { isLoaded } = useJsApiLoader({
            id: 'google-map-script',
            googleMapsApiKey: 'AIzaSyCNHKHhKKCQACaboV2gICQLxHXPas0T2qk'
          })
        
          const [map, setMap] = React.useState(null)
        
          const onLoad = React.useCallback(function callback(map) {
            // const bounds = new window.google.maps.LatLngBounds();
            // map.fitBounds(bounds);
            setMap(map)
          }, [])
        
          const onUnmount = React.useCallback(function callback(map) {
            setMap(null)
          }, [])
return(
  <div className='container'>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={4}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {tournaments.length > 0 && tournaments
              .filter(obj => obj.lat.length > 0)
              .map(
                (t, i)=> (
                  <Marker
                    label={t.name}
                    onLoad={onLoad}
                    position={{
                        lat: 37.772,
                        lng: -122.214}
                      }
                />
                )
              )}

                {/* <Marker
                    label='test'
                    onLoad={onLoad}
                    position={{
                        lat: 37.772,
                        lng: -122.214}
                      }
                />
                <Marker
                    label='hamilton'
                    onLoad={onLoad}
                    position={{
                        lat: 46.2471,
                        lng: -114.1548}
                      }
                /> */}
              { /* Child components, such as markers, info windows, etc. */ }
              <></>
            </GoogleMap>
        ) : <></>
                    }
                    </div>
)}

export default MapOfTournaments