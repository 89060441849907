import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { updateTournament, clearTournament, setAdmin } from '../../ducks/reducer'
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ShondaDashboardRow from './ShondaDashboardRow';

class ShondaDashboard extends Component {
    constructor() {
        super()
        this.state = {
            tournaments: [],
            search: ''
        }

    }

    componentDidMount() {
        this.getTournaments()
    }

    getTournaments = async () => {
        await axios.get(`/api/getAllTournaments`).then(
            res => {
                this.setState({ tournaments: res.data })
            }
        )
    }


    selectTournament = (tourney) => {
        tourney.referee = true
        tourney.line = true
        tourney.volunteer = true

        this.props.updateTournament(tourney)
        this.props.setAdmin()
        this.props.history.push('/admin')

    }
    fieldView = (tourney) => {

        this.props.updateTournament(tourney)
        this.props.history.push('/field')

    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    render() {

        return (
            <div className='col-12'>
                <div className='row'>

                    <div className='col-12 box'>
                        <h2>Referee/ Operations Desk</h2>
                        <input
                            type="text"
                            placeholder='Search '
                            name="search"
                            value={this.state.search}
                            onChange={this.handleChange} />

<TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Go</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Invoiced</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Start</TableCell>
            <TableCell align="left">End</TableCell>
            <TableCell align="left">Organization</TableCell>
            <TableCell align="left">Courts</TableCell>
            <TableCell align="left">Lat/ Long</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.tournaments
          .filter(obj => obj.name.toUpperCase().includes(this.state.search.toUpperCase()))
          .map((row, i) => (
            <ShondaDashboardRow 
                key={i}
                row={row}
                selectTournament={this.selectTournament}
                get={this.getTournaments}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

                    </div>
                    <Link to={`/signup`} >
                        <span>Referee Shift Signup</span>
                    </Link>
                </div>
            </div>
        )
    }
}

function mapStateToProps({ tid, name, url,
    location,
    num_of_courts, zone, ref_id }) {
    return {
        tid, name, url,
        location,
        num_of_courts, zone, ref_id
    }
}

export default connect(mapStateToProps, { updateTournament, clearTournament, setAdmin })(ShondaDashboard)