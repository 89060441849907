import React, { useState, useEffect } from 'react';
import axios from 'axios'
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter'


const DeleteAccount = () => {
    const [names, setNames] = useState([])
    const [keep, setKeep] = useState({})
    const [merge, setMerge] = useState({})
    const [error, setError] = useState('')

    useEffect(
        () => { getAllUsers() }, [])

    const getAllUsers = () => {
        axios.get(`/api/getAllUsers`).then(
            res => {
                setNames(res.data)
            }
        )
    }

    const handleSelect = async (name) => {
        if (keep.ref_id > 0) {
            setMerge(name)
        }
        else {
            setKeep(name)
        }
    }

    const handleSwap = () => {
        let x = keep
        let y = merge
        setKeep(y)
        setMerge(x)
    }
    const handleClear = () => {
        setKeep({})
        setMerge({})
    }

    const submit = () => {
            axios.put(`/api/deleteAccount`, { ref_id: keep.ref_id }).then(
                res => {
                    getAllUsers()
                    handleClear()
                }
            )
    }

    const columns = [
        {
            Header: 'has account',
            accessor: 'id',
            Cell: ({ value, row }) =>
                <p>{value > 0 ? 'yes' : ''}</p>

        },
        {
            Header: 'First',
            accessor: 'first',
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["first"] }),
            filterAll: true

        },
        {
            Header: 'Last',
            accessor: 'last',
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["last"] }),
            filterAll: true


        },
        {
            Header: 'phone',
            accessor: 'phone',
        },
        {
            Header: 'email',
            accessor: 'email',
        },
        {
            Header: 'credentials',
            accessor: 'credentials',
        },





    ]
    return (
        <div className='container' style={{ textAlign: 'left' }}>
            <div className='row'>
                <div className='col-12'>
                    <h4>Duplicate Account Cleanup</h4>
                    <p>Is the same person in they system more then once?  This will let you remove duplicate accounts.  The account you want to keep should be on the left and the one you want to merge into that account on the right. </p>
                    <p>
                        browse the list of names.  Click first on the one you want on the left then the one on the right.  You can use the swap button if they end up on the wrong side.  If needed there is a clear button to restart.
                        </p>
                    <h5>Only click submit when you have verified everything is correct and on the side you want it on.</h5>

                </div>
                <div className='col-6' >
                    <div style={{ background: 'white' }}>

                        <h2>Delete</h2>
                        <p>Name: {keep.first} {keep.last}</p>
                        <p>Phone: {keep.phone}</p>
                        <p>Email: {keep.email}</p>
                        <p>Credentials: {keep.credentials}</p>
                    </div>

                </div>
               
            </div>
            <h2 style={{ color: 'red' }}>{error}</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button onClick={submit}>Submit changes</button>
                <button onClick={handleSwap}>Swap</button>
                <button onClick={handleClear}>clear</button>
            </div>

            <ReactTable
                data={names}
                filterable
                style={{ cursor: 'pointer' }}
                defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value}
                getTrProps={(state, rowInfo, column, instance) => ({
                    onClick: e => handleSelect(rowInfo.original)
                })}
                className='ReactTable'

                columns={columns}
            />

        </div>
    )
}

export default DeleteAccount