import React, { Component } from 'react';
import axios from 'axios'
import { connect } from "react-redux"
import io from 'socket.io-client'
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/TableRow';
import './RefDesk.css'
import Modal from 'react-responsive-modal';
import LookupRef from './LookupRef'
import AddRef from './AddRef'

import RefManagerRow from './RefManagerRow';
import RemoveAllTags from './MasterBoard/RemoveAllTags';

class RefManager extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allRefs: [],
            refStatus: [],
            verifyCheckOutAll: false,
            loader: false,
            searchFirst: '',
            searchLast: ''
        }

    }

    componentDidMount() {
        this.getRegisteredRefs()
        this.setSocketListeners()
    }

    componentWillUnmount() {
        this.socket.disconnect()
    }

    setSocketListeners = () => {
        this.socket = io()

        this.socket.emit('joinRoom', `board:${this.props.tid}`)

        this.socket.on('board', results => {
            this.getRegisteredRefs()

        })
    }

    getAllRefs = () => {
        axios.get(`/api/getAllRefList/${this.props.tid}`).then(
            res => {
                this.setState({ allRefs: res.data })
            }
        )
    }

    hideRefList = () => {
        this.setState({ showLookupButton: false })
    }

    getRegisteredRefs = async () => {
        await axios.get(`/api/getRegisteredRefs/${this.props.tid}`).then(
            res => {
                this.setState({ refStatus: res.data })
            }
        )
    }

    handleGetRegisteredReferees = async () => {
        await axios.get(`/api/getRegisteredRefs/${this.props.tid}`).then(
            res => {
                this.setState({ refStatus: res.data })
                this.props.getAvailableRefs()
            }
        ).then(
            this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'swapQueue' })
        )
    }

    handleGetRegisteredRefereesAfterReset = async () => {
        await axios.get(`/api/getRegisteredRefs/${this.props.tid}`).then(
            res => {
                this.setState({ refStatus: res.data })
                this.props.getAvailableRefs()
            }
        ).then(
            this.socket.emit('board', { room: `board:${this.props.tid}` })
        )
    }

    addRefToTournament = async (ref_id) => {
        this.setState({ loader: true, showLookupButton: false })
        await axios.post(`/api/addRefToTournament/${this.props.tid}`, { ref_id: ref_id }).then(

            res => {
                this.setState({
                    refStatus: res.data,
                    loader: false,
                    showLookupButton: false
                })
                this.props.getAvailableRefs()
                this.socket.emit('board', { room: `board:${this.props.tid}` })
            }
        )
    }

    hitSockets = () => this.socket.emit('board', { room: `board:${this.props.tid}` })

    verifyCheckOutAll = () => this.setState({ verifyCheckOutAll: true })

    cancelVerifyCheckoutAll = () => this.setState({ verifyCheckOutAll: false })

    checkOutAll = () => {
        this.setState({ loader: true })
        axios.put(`/api/checkOutAll/${this.props.tid}`).then(
            res => {
                this.setState({ refStatus: res.data, verifyCheckOutAll: false, loader: false })
                this.props.getAvailableRefs()
            }
        ).then(
            this.socket.emit('board', { room: `board:${this.props.tid}` })
        )
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    emitSocket = () => {
        this.props.getAvailableRefs()
        this.socket.emit('board', { room: `board:${this.props.tid}` })
    }

    render() {

        return (
            <div className="">
                {this.state.loader ?
                    <img id='loader' width="200" src={require('../../img/15.gif')} alt='players' />
                    : ''}
<label htmlFor="">
    Filter by name
        <input type="text"
            placeholder='First Name'
            maxLength='50'
            name='searchFirst'
            value={this.state.searchFirst}
            onChange={this.handleChange}/>
            
            <input type="text"
            placeholder='Last Name'
            maxLength='50'
            name='searchLast'
            value={this.state.searchLast}
            onChange={this.handleChange}/>
            <button onClick={()=>this.setState({searchFirst: '', searchLast:''})}>clear</button>
</label>
                <div className='ref-status-container'>
                <TableContainer component={Paper} style={{background: 'white'}}>
      <Table aria-label="Contact List">
        <TableHead>
          <TableRow>
          <TableCell>First</TableCell>
            <TableCell>Last</TableCell>
            <TableCell align="left">Credentials</TableCell>
            <TableCell align="left">Checked-In</TableCell>
            <TableCell align="left">Quick Break</TableCell>
            <TableCell align="left">Last Match</TableCell>
            <TableCell align="left">Lunch Break</TableCell>
            <TableCell align="left">Dress Code</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Schedule</TableCell>
            <TableCell align="left">Edit</TableCell>
            <TableCell align="left">Reset</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.refStatus
          .filter(obj => obj.first ? obj.first.toUpperCase().includes(this.state.searchFirst.toUpperCase()) : '')
          .filter(obj => obj.last ? obj.last.toUpperCase().includes(this.state.searchLast.toUpperCase()) : '')
          .map((row, i) => (
            <RefManagerRow key={row.ref_id} tid={this.props.tid} row={row} ref_id={row.ref_id} i={i} get={this.handleGetRegisteredReferees} 
            getAfterReset={this.handleGetRegisteredRefereesAfterReset}
            removed_ref={`${this.props.first} ${this.props.last}`}
            organization={this.props.organization}/>
            ))}
        </TableBody>
      </Table>
    </TableContainer>





    <button onClick={() => this.setState({ showLookupButton: true })}>Add Referee</button>


                    <button onClick={this.verifyCheckOutAll}>Check Out All</button>
                    <RemoveAllTags emit={this.emitSocket}/>



                    

                    <Modal open={this.state.showLookupButton} center showCloseIcon={true} onClose={() => this.setState({ showLookupButton: false })} closeOnEsc={true} >

                        <div>
                            <p>If you are setting up a tournament go to
            <Link to={`/desk/refereeSetup`} >
                                    <button>Referee Setup</button>
                                </Link>
                            </p>
                            <LookupRef
                                allRefs={this.state.allRefs}
                                getAllRefs={this.getAllRefs}
                                addRefToTournament={this.addRefToTournament}
                                removeRef={this.removeRef} />

                            <h4>After searching- if ref is not in system add below</h4>
                            <div style={{ border: '2px solid red', padding: '10px' }}>
                                <AddRef
                                    tid={this.props.tid}
                                    hitSockets={this.hitSockets}
                                    hideRefList={this.hideRefList}
                                    refereeAdded={this.refereeAdded}
                                />
                            </div>
                            <button onClick={() => this.setState({ showLookupButton: false })}>Close Add Referee</button>

                        </div>
                    </Modal>



                    


                    <Modal open={this.state.verifyCheckOutAll} center showCloseIcon={true} onClose={this.cancelVerifyCheckoutAll} closeOnEsc={true} >
                        Are you sure you want to check-out all referees?
                         <button onClick={this.checkOutAll}>Check-Out All</button>
                        <button onClick={this.cancelVerifyCheckoutAll}>Cancel</button>
                    </Modal>

                </div >


            </div >


        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        first: reduxState.first,
        last: reduxState.last,
        organization: reduxState.organization
    }
}


export default connect(
    mapStateToProps,
)(RefManager);