import React from 'react';
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import RefereePhoneEmailReport from '../components/RefDesk/RefereePhoneEmailReport'
import ImportVolunteer from '../components/Import/ImportVolunteer'
import CourtReset from '../components/RefDesk/CourtReset'
import RefereeScheduleTable from '../components/Schedule/RefereeScheduleTable'
import ViewInvoice from '../components/Invoice/ViewInvoice'
import InvoiceList from '../components/Invoice/InvoiceList'
import ApprovalManager from '../components/Schedule/ApproveReferee/ApprovalManager'
import InviteCSV from '../components/Communication/InviteCSV'
import SetupChecklist from '../components/Dashboard/SetupChecklist'
import BoardBarcodes from '../components/DayOfComponents/BoardBarcodes'
import ScheduleSetupBulk from '../components/Schedule/ScheduleSetupBulk'
import BarcodeListDeskView from '../components/RefDesk/BoardView/BarcodeListDeskView'
import BarcodesAllReferees from '../components/RefDesk/BoardView/BarcodesAllReferees'
import PastBarcodes from '../components/RefDesk/BoardView/PastBarcodes'
import EditProcedures from '../components/RefDesk/EditProcedures'
import RefereeSchedule from '../components/Schedule/RefereeSchedule'
import RefereeSetup from '../components/RefDesk/TournamentSetup/RefereeSetup'
import RefManager from '../components/RefDesk/RefManager'
import AddRefToSchedule from '../components/Schedule/AddRefToSchedule'
import Assignments from '../components/Schedule/Assignments'
import BoardView from '../components/RefDesk/BoardView'
import RefDeskManager from '../components/RefDeskManager/RefDeskManager'
import RefereeDashboard from '../components/Dashboard/RefereeDashboard'
import PastMatchesList from '../components/RefDesk/PastMatches'
import Setup from '../components/RefDesk/Setup'
import NameCourts from '../components/Admin/NameCourts'
import MasterBoard from '../components/RefDesk/MasterBoard/MasterBoard';
import MasterBoardNew from '../components/RefDesk/MasterBoard/MasterBoardNew';
import NotificationsList from '../components/RefDesk/MasterBoard/NotificationsList';
import EditPastMatch from '../components/RefDesk/EditPastMatch';
import PaymentMaster from '../components/PastMatches/PaymentReconciliation/PaymentMaster'
import RefereeContactInfo from '../components/Reports/RefereeContactInfo';
import RefereeTimeAndAmount from '../components/Reports/RefereeTimeAndAmount';
import InvoiceMaster from '../components/Invoice/InvoiceNew/InvoiceMaster';
import RefereeEmailLog from '../components/Email/RefereeEmailLog';
import ApprovedRefereeEmail from '../components/Communication/RefereeApprovalEmai/ApprovedRefereeEmail';
import RefereeTextLog from '../components/Communication/Text/RefereeTextLog';
import DailyRecapMaster from '../components/Reports/DailyRecapMaster/DailyRecapMaster';
import MessageListRefereeAdmin from '../components/MessageBoard/MessageListRefereeAdmin';
import NotificationNameList from '../components/Communication/CourtNotifications/NotificationNameList';
import RefereeTotalWithContact from '../components/Reports/RefereeTotalWithContact';
import PastMatchesMatchStarters from '../components/MatchStarters/PastMatchesMatchStarters';
import MergeToParentTournament from '../components/RefDesk/TournamentSetup/MergeToParentTournament';
import RefereeScheduleRecap from '../components/Schedule/RefereeScheduleRecap';
import EmailToRefereeShifts from '../components/Communication/EmailToShifts/EmailToRefereeShifts';
import MatchReportUSAPickleball from '../components/Reports/MatchReportUSAPickleball';
import RefereeLog from '../components/Schedule/ScheduleLog/RefereeLog';
import TemplateManager from '../components/Communication/TemplateManager';
import PrintSchedule from '../components/Schedule/PrintSchedule';
import TournamentSetup from '../components/Schedule/TournamentSetup';
import scheduleDashboard from '../components/Schedule/scheduleDashboard';
import Recap from '../components/RefDesk/Reacp/Recap';
import ScheduleSetup from '../components/Schedule/ScheduleSetup';
import MakeSchedule from '../components/Schedule/MakeSchedule';
import TextPlayers from '../components/Text/TextPlayers';
import TimeRecap from '../components/PastMatches/TimeRecap';
import TournamentSetupNotAuthorized from '../components/Schedule/TournamentSetupNotAuthorized';
import RefereeTimeAndShifts from '../components/Reports/RefereeTimeAndShifts';
import BoardFinishMatch from '../components/RefDesk/BoardFinishMatch/BoardFinishMatch';
import MasterBoardNewXX from '../components/RefDesk/MasterBoard/MasterBoardNewXX';
import DisplayNotificationsList from '../components/DayOfComponents/DisplayNotificationsList';
import EditPastAuxAssignment from '../components/RefDesk/EditPastAuxAssignment';
import MasterBoardOversized from '../components/RefDesk/MasterBoard/MasterBoardOversized';
import AppSignupReport from '../components/Reports/SignupReportByOrg/AppSignupReport';
import RefereeTotalDayAndMatchCount from '../components/Reports/RefereeTotalDaysAndMatchCount';

const RefereeAdminRoutes = (props) => {
    const user_id = useSelector(state => state.user_id)
    const admin_type = useSelector(state => state.admin_type)
    const referee = useSelector(state => state.referee)
    const line = useSelector(state => state.line)
    const volunteer = useSelector(state => state.volunteer)


    return (
        <div>

            <Switch>

                <Route path='/desk/notificationlist' component={DisplayNotificationsList}/>
                <Route path='/desk/refereefinishmatch' component={BoardFinishMatch}/>
                <Route path='/desk/refereetimereport' component={RefereeTimeAndShifts}/>
            <Route path='/admin/sendInvitecsv' component={InviteCSV} />
                    <Route path='/desk/scheduleSetupBulk' component={ScheduleSetupBulk} />
            
            <Route path='/report/refereereportusapickleball' component={MatchReportUSAPickleball} />

            <Route path='/desk/schedulingLog' component={RefereeLog} />

            <Route path='/desk/printSchedule' component={PrintSchedule} />
                    {admin_type === 'master' ||line || volunteer || referee ?
                    <Route path='/desk/tournamentSetup' component={TournamentSetup} /> :
                    <Route path='/desk/tournamentSetup' component={TournamentSetupNotAuthorized} /> }
                    <Route path='/desk/schedule' component={scheduleDashboard} />
                    <Route path='/desk/statusUpdates' component={Recap} />
                    <Route path='/desk/scheduleSetup' component={ScheduleSetup} />
                    <Route path='/admin' component={Setup} />
                    <Route path='//deskscheduler' component={Assignments} />
                    <Route path='/desk/makeschedule' component={MakeSchedule} />
                    <Route path='/desk/sendText' component={TextPlayers} />
                    


                    <Route path='/desk/TemplateManager' component={TemplateManager} />
                <Route path='/desk/emailtoshifts' component={EmailToRefereeShifts}/>
                <Route path='/desk/schedulerecap' component={RefereeScheduleRecap}/>
                <Route path='/desk/mergeToParentTournament' component={MergeToParentTournament}/>
                <Route path='/desk/matchstarters/pastmatches' component={PastMatchesMatchStarters}/>
                <Route path='/desk/totalcountanddays' component={RefereeTotalDayAndMatchCount}/>
                <Route path='/desk/refereetotalwithcontact' component={RefereeTotalWithContact}/>
                <Route path='/desk/notificationcontactlist' component={NotificationNameList}/>
                <Route path='/desk/messageboardadmin' component={MessageListRefereeAdmin}/>
                <Route path='/desk/dailyrecap' component={DailyRecapMaster}/>
                <Route path='/desk/refereetotals' component={RefereeTimeAndAmount}/>
                <Route path='/desk/emailapproved' component={ApprovedRefereeEmail}/>
                <Route path='/desk/emaillog' component={RefereeEmailLog}/>
                <Route path='/desk/textlog' component={RefereeTextLog}/>
                <Route path='/desk/refereecontactreport' component={RefereeContactInfo}/>
                <Route path='/desk/matchpaymentreconciliation' component={PaymentMaster} />
                <Route path='/desk/editpastmatch' component={EditPastMatch} />
                <Route path='/desk/editpastauxassignment' component={EditPastAuxAssignment}/>
                <Route path='/desk/pastNotifications' component={NotificationsList} />
                <Route path='/desk/dualboard' component={MasterBoard} />
                <Route path='/desk/namecourts' component={NameCourts} />
                <Route path='/admin' component={Setup} />
                <Route path='/desk/pastMatches' component={PastMatchesList} />

                <Route path='/desk/refereeContactReport' component={RefereePhoneEmailReport} />
                <Route path='/desk/importVolunteerList' component={ImportVolunteer} />
                <Route path='/desk/CourtReset' component={CourtReset} />
                <Route path='/desk/scheduleTable' component={RefereeScheduleTable} />
                <Route path='/desk/invoicemaster' component={InvoiceMaster}/>
                <Route path='/desk/viewInvoice/:id' component={ViewInvoice} />
                <Route path='/desk/invoiceList' component={InvoiceList} />
                <Route path='/desk/invoice' component={InvoiceMaster} />
                <Route path='/desk/ApprovalManager' component={ApprovalManager} />
                <Route path='/desk/setupChecklist' component={SetupChecklist} />
                <Route path='/desk/boardBarcodes' component={BoardBarcodes} />
                <Route path='/desk/scheduleSetupBulk' component={ScheduleSetupBulk} />
                <Route path='/desk/barcodes' component={BarcodeListDeskView} />
                <Route path='/desk/RefereeBarcodes' component={BarcodesAllReferees} />
                <Route path='/desk/PastBarcodes' component={PastBarcodes} />
                <Route path='/desk/editProcedures' component={EditProcedures} />
                <Route path='/desk/scheduleByRef' component={RefereeSchedule} />
                <Route path='/desk/refereeSetup' component={RefereeSetup} />
                <Route path='/desk/RefManager' component={RefManager} />
                <Route path='/desk/editSchedule' component={AddRefToSchedule} />
                <Route path='//deskscheduler' component={Assignments} />
                <Route path='/desk/boardlargevenue' component={MasterBoardOversized}/>
                <Route path='/desk/boardView' component={MasterBoardNew}/>
                <Route path='/desk/boardOld' component={BoardView} />
                <Route path='/desk/board1' component={MasterBoardNewXX} />
                <Route path='/desk/board' component={MasterBoard} />
                <Route path='/desk/timeRecap' component={TimeRecap} />


                <Route path='/desk' component={RefereeDashboard} />
                <Route path='/desk/:tournament_id' component={TimeRecap} />
            </Switch>

        </div>
    )
}
export default RefereeAdminRoutes