import React, { Component } from 'react';
import axios from 'axios'
import { connect } from "react-redux"
import io from 'socket.io-client'
import { toast } from 'react-toastify';
import { updateQueue } from '../../../ducks/reducer'
import Modal from 'react-responsive-modal';
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter'
import Switch from "@material-ui/core/Switch";
import RefInfoModal from '../RefInfoModal'
import './CheckinCheckout.css'
import BoardList from './BoardList'

class CheckinCheckout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            refStatus: [],
            firstSearch: '',
            lastSearch: '',
            showRefInfo: false,
            refInfo: {},
            showRefSchedule: false,
            refSchedule: {},
            showAddRef: false,
            queue: 0,
            queueWarning: false
        }

    }

    componentDidMount() {
        this.getRegisteredRefs()
        this.getQueueLength()
        this.setSocketListeners()

    }

    componentWillUnmount() {
        this.socket.disconnect()
    }

    setSocketListeners = () => {
        this.socket = io()

        this.socket.emit('joinRoom', `board:${this.props.tid}`)

        this.socket.on('board', results => {
            if (results.msg === 'queue') {
                this.props.updateQueue({ queueCount: results.data })
            }
            else {
                this.getRegisteredRefs()
                this.getQueueLength()
            }

        })

        this.socket.on('board', results => { this.getRegisteredRefs() })
    }

    notifyBoard = () => { this.socket.emit('board', { room: `board:${this.props.tid}` }) }


    shiftCheckIn = async (id, status) => {
        if (this.state.queue <= this.props.queue && status) { this.queueWarning() }
        else {
            await axios.put(`/api/shiftcheckin/${this.props.tid}`, { ref_id: id, status: true }).then(
                res => {
                    this.setState({ refStatus: res.data })
                    this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'shiftCheckin' })
                    // this.notifyBoard()
                    toast.success(`Thank you for checking in`)
                }
            )
        }
    }

    queueWarning = () => this.setState({
        queueWarning: !this.state.queueWarning,
        showRefInfo: false
    })

    getRegisteredRefs = async () => {
        await axios.get(`/api/getRegisteredRefs/${this.props.tid}`).then(
            res => {
                this.setState({ refStatus: res.data })
            }
        )
    }

    getQueueLength = () => {
        axios.get(`/api/getAvailableRefs/${this.props.tid}`).then(

            res => {
                let queueCount = res.data.length
                this.setState({ queue: queueCount })
            }
        )
    }

    clearSearch = () => this.setState({ firstSearch: '', lastSearch: '' })

    openRefInfo = (ref) => {
        this.setState({
            showRefInfo: true,
            refInfo: ref
        })
    }

    closeRefInfo = () => {
        this.setState({
            showRefInfo: false,
            assignedRef: { ref_id: '', first: '', last: '' }
        })
        this.notifyBoard()

    }

    refInfoModal = () => { }

    scheduleModal = () => { }

    showRefSchedule = (ref) => {
        this.setState({
            showRefSchedule: true,
            refSchedule: ref


        })
    }

    hideRefSchedule = () => { this.setState({ showRefSchedule: false, refSchedule: {} }) }

    addRef = () => this.setState({ showAddRef: true })



    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    render() {
        const columns = [

            {
                Header: 'Last',
                accessor: 'last',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["last"] }),
                filterAll: true


            },
            {
                Header: 'First',
                accessor: 'first',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["first"] }),
                filterAll: true

            },
            {
                Header: 'Checked-In ?',
                accessor: 'status',
                Cell: ({ value, row }) =>
                    < Switch
                        color="primary"
                        checked={value || row._original.shift_checkin}
                        value={value || row._original.shift_checkin ? "Active" : 'Inactive'}
                        onClick={() => { value || row._original.shift_checkin ? this.openRefInfo(row._original) : this.shiftCheckIn(row._original.ref_id, value) }}
                    />

            },
            {
                Header: 'Update Status',
                accessor: 'status',
                Cell: ({ value, row }) =>
                    < button
                        color="primary"
                        checked={value || row._original.shift_checkin}
                        value={value || row._original.shift_checkin ? "Active" : 'Inactive'}
                        onClick={() => this.openRefInfo(row._original)}
                    >Update Status  </button>

            },
            {
                Header: 'Status',
                accessor: 'on_court',
                Cell: ({ value, row }) =>
                    (
                        value ? `${row._original.last_match ? 'Last Match' : ''}Court ${row._original.court_num}` :
                            row._original.shift_checkin ? 'On Deck' :
                                row._original.quick_break ? 'Quick Break' :
                                    row._original.note === 'Lunch Break' ? 'Lunch Break' :
                                        row._original.status ? 'Queue' : ''
                    )


            },
        ]

        return (
            <div className="row" >
                <div className='col-12 col-md-9'>
                    <h4>Referee Shift Check-In</h4>
                    <div className='tips'>*To filter matches use the search boxes under column title.</div>
                    <div className='ref-status-container'>
                        <ReactTable
                            data={this.state.refStatus}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            className='ReactTable'

                            columns={columns}
                        />

                        <div className='row'>
                            <div className='col-12 checkin-list'>

                                {/* {mappedRef} */}
                                <p>Is your name not on the list?  Ask the ref desk to add you to the list</p>

                            </div>
                        </div>

                        <Modal open={this.state.showRefInfo} center showCloseIcon={true} onClose={this.closeRefInfo} closeIconSize={20} closeOnEsc={true} >
                            <div>

                                <RefInfoModal
                                    refInfo={this.state.refInfo}
                                    referee={this.state.refInfo}
                                    closeModal={this.closeRefInfo}
                                    queueNumber={this.props.queue}
                                    queueWarning={this.queueWarning}
                                    queue={this.state.queue}
                                    tid={this.props.tid} />
                            </div>
                        </Modal>


                        <Modal open={this.state.showRefSchedule} center showCloseIcon={true} onClose={this.hideRefSchedule} closeOnEsc={true} closeIconSize={2}>
                            <div>
                                <h4>{this.state.refSchedule.first} {this.state.refSchedule.last}</h4>
                                <div dangerouslySetInnerHTML={{ __html: this.state.refSchedule.schedule }}></div>
                            </div>
                            <button onClick={this.hideRefSchedule}> Close</button>
                        </Modal>
                        <Modal open={this.state.queueWarning} center showCloseIcon={true} onClose={this.queueWarning} closeOnEsc={true} closeIconSize={2}>
                            <div>
                                <h4>Sorry the referee queue is low so self-checkout is not available.  Please contact the desk to checkout.</h4>
                            </div>
                            <button onClick={this.queueWarning}> Close</button>
                        </Modal>


                    </div>
                </div>
                <div className='col-12 col-md-3'>
                    <BoardList />
                </div>


            </div>


        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        queue: reduxState.queue
    }
}


export default connect(
    mapStateToProps, { updateQueue }
)(CheckinCheckout)