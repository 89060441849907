import React from 'react'

export default function MappedSpots(props){
    let {spot, removeRef, moveToWaitlist, i} = props

    return(
        <div  style={{ background: 'white', display: 'flex' }} >
                                
                                <i className="fas fa-arrow-right small-button" onClick={()=>moveToWaitlist(spot.shift_id)}></i>
                                <div className=''>{++i}.</div>
                                <div className='signup-credential-box'>

                                    <div style={{ margin: '3px', width: '1em' }} className={spot.credentials_verified === 'Certified' ? 'ref-list certified' : spot.credentials_verified === 'Level 2' ? 'ref-list level2' : spot.credentials_verified === 'Level 1' ? 'ref-list level1' :

                                        'ref-list Volunteer'}>
                                        {spot.credentials === 'Certified' ? 'C' :
                                            spot.credentials === 'Level 2' ? '2' : spot.credentials === 'Level 1' ? '1' : '-'}
                                    </div></div>
                                <div className=''>
                                    {spot.last}, {spot.first}
                                </div>
                                
                                <div>
                               <i className="fas fa-user-minus" onClick={() => removeRef(spot.shift_id)}></i>
                                    </div>

                            </div>
    )
}