import React, { Component } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify';


export default class ResetPassword extends Component {
    constructor() {
        super()

        this.state = {
            email: '',
            password: '',
            confirmPassword: '',
            status: ''

        }
    }



    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    resetPassword = () => {
        if (this.state.password !== this.state.confirmPassword) {
            this.setState({ status: 'Passwords do not match' })
        } else {
            this.setState({ status: '' })

            axios.put(`/auth/reset/${this.props.match.params.token}`, { email: this.state.email, password: this.state.password }).then(res => {
                this.setState({ status: res.data })
                if (this.state.status === 'Password Updated Successfully') {
                    toast.success('Password Reset, Please Login')
                    this.props.history.push('/login')
                }
            }

            )
        }
    }




    render() {
        return (
            <div >
                <h4>Reset your password</h4>
                <div className='resetPassword'>
                    <form>
                        <label>
                            email
                <input
                                type="email"
                                name='email'
                                value={this.state.email}
                                placeholder='email'
                                onChange={this.handleChange}

                            />
                        </label>
                    </form>
                    <form>
                        <label >
                            new password
                <input
                                type="password"
                                name='password'
                                value={this.state.password}
                                placeholder='password'
                                onChange={this.handleChange}
                            />
                        </label>
                    </form>
                    <form>
                        <label >
                            re-type password
                    <input
                                type="password"
                                name='confirmPassword'
                                value={this.state.confirmPassword}
                                placeholder='password'
                                onChange={this.handleChange}
                            />
                        </label>
                    </form>
                    <form>
                        <button type='button' onClick={this.resetPassword}>Submit</button>
                    </form>
                    <div className='passwordDoNotMatch'>{this.state.status}</div>
                </div>
            </div>
        )
    }
}