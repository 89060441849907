const initialState = {
    user_id: null,
    first: '',
    last: '',
    phone: '',
    email: '',
    ref_id: null,
    profile_updated: true,
    credentials: '',
    tid: null,
    name: '',
    url: '',
    location: '',
    num_of_courts: 0,
    zone: '',
    text: false,
    queue: 0,
    status: '',
    referee_approval_required: false,
    referee_coordinator_name: '',
    line_coordinator_name: '',
    volunteer_coordinator_name: '',
    certified_amount: 0,
    level_two_amount: 0,
    level_one_amount: 0,
    volunteer_amount: 0,
    certified_amount2: 0,
    level_two_amount2: 0,
    level_one_amount2: 0,
    volunteer_amount2: 0,
    pr1_amount: 0, 
    pr2_amount: 0,
    lineShift: { name: 'test' },
    show: false,
    test: false,
    admin: false,
    admin_type: null,
    volunteerShiftFilter: '',
    description: '',
    line_description: '',
    volunteer_description: '',
    referee: false,
    line: false,
    volunteer: false,
    volunteer_captain: false,
    start: '',
    end_date: '',
    score_reporting: false,
    time_zone: '',
    rankTournament: {},
    referee_post_pay: true,
    payout: false,
    zero_payout_warning: false,
    country: 'usa',
    qr_guide: false,
    admin: false,
    organization:'',
    save_tag: false
}
const GET_TIMEZONE = 'GET_TIMEZONE'
const UPDATE_USER = 'UPDATE_USER'
const PROFILE_UPDATED = 'PROFILE_UPDATED'
const CLEAR_USER = 'CLEAR_USER'
const UPDATE_TOURNAMENT = 'UPDATE_TOURNAMENT'
const UPDATE_TOURNAMENT_STATUS = 'UPDATE_TOURNAMENT_STATUS'
const SET_ADMIN = 'SET_ADMIN'
const TOURNAMENT_ADMIN = 'TOURNAMENT_ADMIN'
const CLEAR_TOURNAMENT = 'CLEAR_TOURNAMENT'
const TOGGLE_TEXT = 'TOGGLE_TEXT'
const UPDATE_QUEUE = 'UPDATE_QUEUE'
const SET_LINE_SHIFT = 'SET_LINE_SHIFT'
const HIDE_MENU = 'HIDE_MENU'
const TOGGLE_MENU = 'TOGGLE_MENU'
const SET_VOLUNTEER_SHIFT_FILTER = 'SET_VOLUNTEER_SHIFT_FILTER'
const SET_RANK_TOURNAMENT = 'SET_RANK_TOURNAMENT'

export function getTimezone(zone) {
    return {
        type: GET_TIMEZONE,
        payload: zone
    }
}
export function updateUser(user) {
    return {
        type: UPDATE_USER,
        payload: user
    }
}

export function profileUpdated(){
    return{
        type: PROFILE_UPDATED
    }
}

export function clearUser() {
    return {
        type: CLEAR_USER
    }
}

export function updateTournament(tournament) {
    return {
        type: UPDATE_TOURNAMENT,
        payload: tournament
    }
}

export function updateTournamentStatus(status) {
    return {
        type: UPDATE_TOURNAMENT_STATUS,
        payload: status
    }
}
export function setAdmin() {
    return {
        type: SET_ADMIN
    }
}
export function tournamentAdmin(){
    return {
        type: TOURNAMENT_ADMIN
    }
}

export function clearTournament() {
    return {
        type: CLEAR_TOURNAMENT
    }
}

export function toggleText(text) {
    return {
        type: TOGGLE_TEXT,
        payload: text
    }
}
export function updateQueue(num) {
    return {
        type: UPDATE_QUEUE,
        payload: num
    }
}

export function setLineShift(s) {
    return {
        type: SET_LINE_SHIFT,
        payload: s
    }
}

export function hideMenu() {
    return {
        type: HIDE_MENU
    }
}

export function toggleMenu() {
    return {
        type: TOGGLE_MENU
    }
}

export function setVolunteerShiftFilter(s) {
    return {
        type: SET_LINE_SHIFT,
        payload: s
    }
}
export function setRankTournament(s) {
    return {
        type: SET_RANK_TOURNAMENT,
        payload: s
    }
}



export default function reducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_TIMEZONE:
            const { timeZone } = payload
            return { ...state, zone: timeZone }
        case UPDATE_TOURNAMENT:
            const { tid, name, url, location, num_of_courts, text, queue, status, test, referee_approval_required, certified_amount, level_two_amount, level_one_amount, volunteer_amount,
                certified_amount2, level_two_amount2, level_one_amount2, volunteer_amount2,
                admin_type, description,
                line_description,
                volunteer_description, referee, line, volunteer, volunteer_captain, start, end_date, referee_coordinator_name,
                line_coordinator_name,
                volunteer_coordinator_name, score_reporting, time_zone, referee_post_pay, payout, zero_payout_warning, country, organization, pr1_amount, pr2_amount, save_tag } = payload
            return {
                ...state, tid, name, url, location, num_of_courts, text, queue, status, test, referee_approval_required, certified_amount, level_two_amount, level_one_amount, volunteer_amount,
                certified_amount2, level_two_amount2, level_one_amount2, volunteer_amount2,
                 admin_type, description, line_description, volunteer_description, referee, line, volunteer, volunteer_captain, start, end_date, referee_coordinator_name,
                line_coordinator_name,
                volunteer_coordinator_name, score_reporting, time_zone, referee_post_pay, payout, zero_payout_warning, country, organization, pr1_amount, pr2_amount, save_tag
            }
        
        case UPDATE_TOURNAMENT_STATUS:
            const { tournamentStatus } = payload
            return { ...state, status: tournamentStatus }
        case SET_ADMIN:
            return { ...state, admin_type: 'master', payout: true, admin: true }
        case TOURNAMENT_ADMIN:
            return { ...state,  admin: true }
        case CLEAR_TOURNAMENT:
            return {
                ...state, tid: null, name: '', url: '', location: '', num_of_courts: 0, text: false, queue: 0, status: '', test: false, referee_approval_required: false,
                admin: false, admin_type: null,
                certified_amount: 0,
                level_two_amount: 0,
                level_one_amount: 0,
                volunteer_amount: 0,
                pr1_amount:0, pr2_amount:0,
                description: '',
                line_description: '',
                volunteer_description: '',
                referee: false,
                line: false,
                volunteer: false,
                volunteer_captain: false,
                start: '',
                end_date: '',
                referee_coordinator_name: '',
                line_coordinator_name: '',
                volunteer_coordinator_name: '',
                score_reporting: false,
                time_zone: '',
                referee_post_pay: true,
                payout: false,
                zero_payout_warning: false,
                country: 'usa',
                admin: false,
                organization: ''
            }
        case UPDATE_USER:
            const { user_id, first, last, phone, email, ref_id, credentials, profile_updated } = payload
            return { ...state, user_id, first, last, phone, email, ref_id, credentials, profile_updated }
        case PROFILE_UPDATED:
            return {...state, profile_updated: true}
        case CLEAR_USER:
            return { ...state, user_id: null, first: '', last: '', phone: '', email: '', ref_id: '', credentials: '', tid: null, name: '', url: '', location: '', num_of_courts: 0, text: false }
        case TOGGLE_TEXT:
            const { toggleText } = payload
            return { ...state, text: toggleText }
        case SET_LINE_SHIFT:
            const { lineShift } = payload
            return { ...state, lineShift: lineShift }
        case HIDE_MENU:
            return { ...state, show: false }
        case TOGGLE_MENU:
            return { ...state, show: !state.show }
        case SET_VOLUNTEER_SHIFT_FILTER:
            const { volunteerShiftFilter } = payload
            return { ...state, volunteerShiftFilter }
        case SET_RANK_TOURNAMENT:
            return { ...state, rankTournament: payload }

        default:
            return state
    }
}