import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import ScheduleListByName from './../Schedule/ScheduleListByName'
import LinearProgress from '@material-ui/core/LinearProgress'


const VolunteerSchedule = () => {
    const [names, setNames] = useState([])
    const [loader, setLoader] = useState(false)
    const [cancel, setCancel] = useState(false)


    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)

    useEffect(
        () => { getSchedule() 
            return () => {
                cancelRequests(); // trying to clear memory leak
              }}, [tid, ref_id])
    const cancelRequests = () => {
                setCancel(true)
            }

    const getSchedule = () => {
        setLoader(true)
        axios.post(`/api/volunteer/getVolunteerForCoordinator/${tid}`, {ref_id}).then(
            res => {
                if(cancel)return
                setLoader(false)
                setNames(res.data)
            }
        )
    }

    


        return (
            <div className='container-fluid' >
                <h3>Volunteer List with Their Schedule</h3>
                Volunteer Count: {names.length}
                {loader ?
                    <LinearProgress color="secondary"/>:''}
                <ScheduleListByName 
                    names={names}
                    category='volunteer'/>

            </div>
        )
    }

export default VolunteerSchedule

