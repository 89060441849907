import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from "react-redux"
import { updateTournament } from '../../../../ducks/reducer'
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ScheduleRecap from '../../ScheduleModal/ScheduleRecap'
import CategoryDetail from './Test'
import SignVolunteerWaiver from './SignVolunteerWaiver'

const VolunteerSignupByCategory = props => {
    const [categories, setCategories] = useState([])
    const [Schedule, setSchedule] = useState([])
    const [ShowMore, setShowMore] = useState(false)
    const [collapse, setCollapse] = useState(true)
    const [showAll, setShowAll] = useState(false)
    const [showWaiver, setShowWaiver] = useState(false)

    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)


    useEffect(
        () => { getCategories() }, [tid, props.view])
    useEffect(
        () => { checkForWaiver() }, [tid, ref_id])

    const getCategories = () => {
        if(props.view !== 'category'){return}
        axios.get(`/api/volunteer/getCategoriesForTournament/${tid}`).then(
            res => {
                setCategories(res.data)
            }
        )
    }

    const checkForWaiver = () => {
        if (ref_id > 0) {
            axios.post(`/api/volunteer/checkIfWaiverIsNeededAndSigned`, { ref_id, tid }).then(
                res => {
                    if(res.status === 200){
                    setShowWaiver(res.data)}
                }
            )
        }
    }


    const mappedCategories = categories.map((cat, i)=> {
        return(
            <CategoryDetail 
                key={'c'+i}
                category={cat}
                getSchedule={props.getSchedule}
                showAll={showAll}
                collapse={collapse}/>
            
        )
    })

        return( 
            <div>
            {mappedCategories}
            </div>
        )
    }

export default VolunteerSignupByCategory
