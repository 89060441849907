import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import './App.css';
import './reactDatetime.css'
import Nav from './components/Nav/Nav'
import TopNav from './components/Nav/TopNav'
import TextSwitch from './components/Nav/TextSwitch'
import ProfileNav from './components/Nav/ProfileNav'
import AdminNav from './components/Nav/AdminNav/AdminNav'
import RefereeNav from './components/Nav/RefereeNav/RefereeNav'
import LineNav from './components/Nav/LineNav'
import VolunteerNav from './components/Nav/VolunteerNav'
import VolunteerCaptainNav from './components/Nav/VolunteerCaptainNav'
import Footer from './components/Footer/Footer'
import Routes from './Routes/Routes'
import ScoreNav from './components/Nav/ScoreNav'
import PayoutNav from './components/Nav/PayoutNav'

const App = (props) => {
  const tid = useSelector(state => state.tid)
  const user_id = useSelector(state => state.user_id)
  const status = useSelector(state => state.status)
  const referee_approval_required = useSelector(state => state.referee_approval_required)
  const admin_type = useSelector(state => state.admin_type)
  const captain = useSelector(state => state.volunteer_captain)

  return (
    <div className="App">
      <div className='Routes'>
        <ToastContainer />
        {/* <h2>We are doing a few upgrades so the site will be down for a few minutes.  Will be back soon.  </h2> */}
        {window.location.href.includes('www.pickleball.tech/') ||
          window.location.href.includes('localhost') ||
          window.location.href.includes('sandbox.pickleballdesk.com')
          ?
          <div style={{ background: 'red' }} className='no-print'>
            <h3>You are on the sandbox site for pickleballdesk.com</h3>
            <p>This site is for learning features and testing</p>
            <h4>12/12/2022 Update: The sandbox was reset so you will need a new login as all data was deleted.  If you are receiving training please make a new account and contact your trainer to have them add you as an admin.  If you are not receiving training contact pickleballdesk@gmail.com</h4>
          </div> : ''}
        {status === 'draft' &&
          (props.location.pathname.includes('/desk/') || props.location.pathname.includes('/line/') || props.location.pathname.includes('/volunteer/')) ?
          <div className='status-draft'>
            <div>Your tournament is in draft status.  Update status when you want the tournament to be available to the public.  (update in admin section).
          </div>
            <div>
              No emails or text can be sent in draft mode.
            </div>
          </div> : ''}

        {props.location.pathname.includes('/print/') ||
          props.location.pathname.includes('/public/')
          ? '' : <TopNav />}

        {user_id > 0 && admin_type ?
          (props.location.pathname.includes('/desk/') ||
            props.location.pathname.includes('/line/') ||
            props.location.pathname.includes('/volunteer/') ||
            props.location.pathname.includes('/volunteercaptain/') ||
            props.location.pathname.includes('/print/') ||
            props.location.pathname.includes('/public/') ||
            props.location.pathname.includes('/plan/') ||
            props.location.pathname.includes('/admin') ||
            props.location.pathname.includes('/admin') ||
            props.location.pathname.includes('/score') ||
            props.location.pathname.includes('/report/'))
            ?
            '' : <ProfileNav /> : ''}

        {user_id > 0 && (props.location.pathname === '/' ||
          props.location.pathname.includes('/profile/') ||
          props.location.pathname.includes('/signup/') ||
          props.location.pathname.includes('/app/') ||
          props.location.pathname.includes('/ppa/') ||
          props.location.pathname.includes('/usapa/')) ?
          < ProfileNav /> : ''}

        {admin_type ?
          props.location.pathname.includes('/public/') ||
            props.location.pathname.includes('/print/') ||
            props.location.pathname.includes('/score/') ||
            props.location.pathname.includes('/report/') ? '' :
            <AdminNav /> : ''}

        {props.location.pathname.includes('/desk') && admin_type ? <RefereeNav /> : ''}
        {props.location.pathname.includes('/line/') && admin_type ? <LineNav /> : ''}
        {props.location.pathname.includes('/volunteer') && admin_type ? <VolunteerNav /> : ''}
        {props.location.pathname.includes('/volunteercaptain') && admin_type && captain ? <VolunteerCaptainNav /> : ''}
        {props.location.pathname.includes('/line/') && admin_type ? <TextSwitch /> : ''}
        {props.location.pathname.includes('/desk/') && admin_type ? <TextSwitch /> : ''}
        {props.location.pathname.includes('/volunteer/') && admin_type ? <TextSwitch /> : ''}
        {props.location.pathname.includes('/score/admin') && admin_type ? <ScoreNav /> : ''}
        {props.location.pathname.includes('/payout') && admin_type ? <PayoutNav /> : ''}

        <div style={{ margin: '10px' }}></div>

        <Routes />
      </div>

      <div className='Footer'>
        <Footer />
      </div>

    </div>
  );
}

export default withRouter((App))

