import React, {useState} from 'react'
import axios from 'axios'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import SendEmail from './SendEmail'


const MessageRow = props => {
    const [sendEmail, setSendEmail] = useState(false)
    const [edit, setEdit] = useState(false)
    const [message, setMessage] = useState('')
    const [verify, setVerify] = useState(false)

    const ref_id = useSelector(state => state.ref_id)
    let {row} = props

    const saveChanges = () => {
        if(!ref_id){return}
        axios.put(`/api/messageBoard/editMessage`, { id: row.id, message }).then(
            res => {
                setEdit(false)
                toast.success('saved')
            }
        ).catch(err => {
            toast.error('Issue Occurred- please try again')
        })
    }

    const deleteMessage = () => {
        if(!ref_id){return}
        axios.delete(`/api/messageBoard/deleteMessage/${row.id}`).then(
            res => {
                setEdit(false)
                setVerify(false)
                props.get()
                toast.success('message deleted')
                
            }
        ).catch(err => {
            toast.error('Issue Occurred- please try again')
        })
    }

    const handleSendEmail = () => setSendEmail(!sendEmail)
    const handleEdit = () => {
        if(edit){
            setEdit(false)
            setMessage('')
        }else{
        setEdit(true)
        if(!message){
        setMessage(row.message)
        }
        }
    }
     return(
        <TableRow>
        <TableCell>
            {row.name}
            <i className="far fa-envelope"
                onClick={handleSendEmail}></i>
            
        </TableCell>
        <TableCell align="left">
            {edit ?
            <textarea 
                style={{height: '100%', width: '100%'}}
                value={message} onChange={e=>setMessage(e.target.value)}></textarea>    :message || row.message}
        
        {(row.ref_id === ref_id || props.admin) && !edit && <button onClick={handleEdit}>edit</button>}
        {edit && <button onClick={saveChanges}>Save Changes</button>}
        {edit && <button className='button-none' onClick={handleEdit}>Cancel Edit</button>}
        {edit && <button onClick={()=>setVerify(!verify)}>delete</button>}
        {verify && <button className='button-none' onClick={deleteMessage}>yes, Delete</button>}
        </TableCell>
        <TableCell align="left">
        {row.post_time}
        </TableCell>
        <SendEmail close={handleSendEmail} row={row} show={sendEmail}/>
        
    </TableRow>

    )
}
export default MessageRow