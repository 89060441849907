import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchForPlayer from './SearchForPlayer'

const AddItem = props => {
    const [event, setEvent] = useState('')
    const [place, setPlace] = useState('')
    const [amount, setAmount] = useState('')
    const [pid, setPid] = useState(null)
    const [paid, setPaid] = useState(false)
    const [name, setName] = useState('')
    const [ref_id, setRef_id] = useState(null)
    const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [verify, setVerify] = useState(false)

  const classes = useStyles();

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { checkForProps() }, [props.row, props.i])
    useEffect(
      () => { handleAutoSave() }, [ref_id])

        const checkForProps = () => {
            if(props.row){
                let {pid, event, place, amount, paid, ref_id, name} = props.row
                setPid(pid)
                setEvent(event)
                setPlace(place)
                setAmount(amount)
                setPaid(paid)
                setRef_id(ref_id)
                setName(name)
            }
        }

    const handleSave = async () => {
        if(paid){
          checkForProps()
          return toast.warning('Item is paid so not changes can be made')}
        setSuccess(false);
        setLoading(true);
        if(pid && (
          event !== props.row.event || place !== props.row.place || amount !== props.row.amount
        ) ){
            await saveItem()
        }
        else if (!pid){
          await addItem()
        }
 
        
        
    }

    const handleAutoSave = () => {
      if(pid){saveItem()}
    }

    const addItem = async () => {
      setLoading(true)
        await axios.post(`/api/payment/addPaymentItem`, {tid, ref_id, event, place, amount}).then(
            res => {
              setLoading(false)
                setPid(res.data.pid)
                setSuccess(true)
                setLoading(false)
                toast.success('saved')
            }
        )
    }
    const duplicateItem = async () => {
      await axios.post(`/api/payment/addPaymentItem`, {tid, ref_id: null ,event, place, amount}).then(
          res => {
            toast.success('Duplicated and Sorted List by event/ place')
               if(props.get){
                props.get()
            }
            
          }
      )
  }
    const saveItem = async () => {
      setLoading(true)
        await axios.put(`/api/payment/editPaymentItem`, {pid, tid, ref_id, event, place, amount}).then(
            res => {
              setLoading(false)
              setSuccess(true)
              setLoading(false)
              toast.success('saved')
            }
        )
    }
    const deleteItem = async () => {
      await axios.put(`/api/payment/deleteItem`, {pid, tid, ref_id,}).then(
          res => {
            toast.success('deleted')
            setVerify(false)
            if(props.get){
              props.get()
          }
          }
      )
  }

  // const handleSaveAll = () => {
  //   if(!props.row.pid){return}
  //   console.log('hit save all')
  //   if(props.event !== event){saveItem()}
  //   else if(props.place !== place){saveItem()}
  //   else if(props.amount !== amount){saveItem()}
  //   else if(props.ref_id !== ref_id){saveItem()}
  //   else {
  //     console.log('no changes')
  //   }
  // }



  const enterPressed = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
        pid ? saveItem():addItem()
    }
}

    const clearState = () => {
        setPid('')
        setEvent('')
        setPlace('')
        setAmount('')
        setPaid('')
        setRef_id('')
        setName('')
    }

    return(
        <TableRow onKeyPress={e => enterPressed(e)}>
          <TableCell>
          <Button
          variant="contained"
          color="secondary"
          disabled={loading}
          onClick={duplicateItem}
        >
          Duplicate
          </Button>
              </TableCell>
              <TableCell>
              <input type="text"
                value={event}
                onChange={e=>setEvent(e.target.value)}
                onBlur={handleAutoSave}/>
              </TableCell>
              <TableCell>
              <input type="text"
                value={place}
                className={classes.place}
                onChange={e=>setPlace(e.target.value)}
                onBlur={handleAutoSave}/>
              </TableCell>
              <TableCell>
                <SearchForPlayer 
                  name={name}
                  setName={setName}
                  setRefId={setRef_id}/>
              </TableCell>
              <TableCell>
              <input type="number"
                value={amount}
                onChange={e=>setAmount(e.target.value)}
                onBlur={handleAutoSave}
                />
              </TableCell>
              <TableCell>
              {paid ? 'paid':''}
              </TableCell>
              <TableCell>
              <div className={classes.root}>
      
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
        //   className={buttonClassname}
          disabled={loading}
          onClick={handleSave}
        >
          save
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
              </TableCell>
              <TableCell>
              <button onClick={()=>setVerify(!verify)}>{verify ? 'Cancel Delete':'Delete'}</button>
              {verify ? 
              <button 
              style={{ background: '#EA607B', fontSize: '1.5em' }}
              onClick={deleteItem}>Yes Delete</button>:''}
              </TableCell>
            </TableRow>

    )
}
export default AddItem

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },place: {
        width: '50px'
    }
  }));