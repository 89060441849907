import React from 'react'


const BoardDisplayMagnet = (props) => {

    const ref = props.referee
    return (
        <div key={ref.ref_id} className='BoardDisplayMagnet'>
            <span style={{width: '40px', textAlign:'right', paddingRight: '4px'}}>
            {1 + props.i || ''}{ ' '}
            </span>
            
            <div className={
                ref.quick_break ? 'break ref-list' :
                    ref.credentials_verified === 'Certified' ? 'ref-list certified' :
                        ref.credentials_verified === 'Level 2' ? 'ref-list level2' :
                            ref.credentials_verified === 'Level 1' ? 'ref-list level1' : 'ref-list'}
            >
                
                {ref.note ? <span className='ref-note'>{ref.note}</span> : ''}
                {ref.ref_note ? <span className='ref-note'>{ref.ref_note}</span> : ''}
                {props.swapQueue ?
                    <i className="fas fa-exchange-alt swap-queue"
                        style={{ cursor: 'pointer' }}
                        onClick={() => props.swapQueue(ref.ref_id)}></i>
                    : ''}


                <div
                    style={{ cursor: 'pointer' }}
                    onClick={props.selectRef ? () => props.selectRef(ref) : null}>
                    {ref.quick_break ? <span className='wait-to-assign-display'>Wait</span> : ''}

                    {ref.last_match ? <span className='last_match_icon_display'>LM</span> : ''}
                    <div className='magnet-name-boardDisplay col-11'>{ref.first} {ref.last}</div>
                </div>
            </div>
        </div >
    )
}
export default BoardDisplayMagnet