import React from 'react'

export default function () {
    return (
        <div className=""
            style={{ background: 'white', display: 'flex', justifyContent: 'center', color: 'black', margin: '20px', flexWrap: 'wrap', textAlign: 'center' }}>
            <div>
                <img className=''
                    style={{ height: '80px', paddingRight: '20px' }}
                    src={'https://res.cloudinary.com/shonda/image/upload/v1583087563/PickleballDesk/how-to-girl_koauwv.png'} alt='Sign Up Video' />
            </div>
            <div>
                <div
                    style={{ fontSize: '1.5em' }}>
                    Want to learn about Pickleball Desk and how to register as a  volunteer?

            </div>

                <a target="_blank" rel="noopener noreferrer"
                    href="https://vimeo.com/394785699">
                    <button>
                        Watch this video to learn more
                        </button>
                </a>

            </div>
        </div>
    )
}