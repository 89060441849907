import React, { useState, useEffect } from 'react';
import axios from 'axios'
import LinearProgress from '@material-ui/core/LinearProgress'

export default function AppSignupReport(){
    const [list, setList] = useState([])
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        getList()
    }, [])


    const getList = () => {
        axios.post(`/api/getRefSignupByTournamentForOrg`, {org:'app'}).then(res => {
            setList(res.data)
            setLoader(false)
        })
    }

    return(
    <div className='container' style={{background: 'white'}}>
        <h2>Signup Report for future APP tournaments</h2>
        {loader ?
            <LinearProgress color="secondary"/>:''}
        {list.length} total
        <div style={{textAlign:'left'}}>
        {list.map((group, i)=>(
            <div>
                <h4>{`${group[0].last}, ${group[0].first}`}</h4>
                <ol>
                {group.map((row, i)=>(
                    <li>{row.name}</li>
                ))}
</ol>
            </div>
            )
        )}
        </div>
    </div>)
    
}