import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import PaymentDetail from './PaymentDetail'
import PaymentReconciliationInstructions from './PaymentReconciliationInstructions';

const PaymentMaster = () => {
    const [matches, setMatches] = useState([])
    const [total, setTotal] = useState(0)
    const [leadTotal, setLeadTotal] = useState(0)
    const [leadDue, setLeadDue] = useState(0)
    const [supportingTotal, setSupportingTotal] = useState(0)
    const [supportingDue, setSupportingDue] = useState(0)
    const [postedTotal, setPostedTotal] = useState(0)
    const [loader, setLoader] = useState(false)
    const [show, setShow] = useState(false)
    const [searchCourt, setSearchCourt] = useState('')
    const [searchReferee, setSearchReferee] = useState('')
    const [searchTotal, setSearchTotal] = useState(0)
    const [searchAmount, setSearchAmount] = useState('')

    const tid = useSelector(state => state.tid)
    const classes = useStyles()

    useEffect(
        () => { getMatches() }, [tid])
    useEffect(
        () => { getTotal() }, [tid])
    
    const getMatches = async () => {
        setLoader(true)
            axios.get(`/api/getMatchesForReconciliation/${tid}`).then(
                res => {
                    setMatches(res.data)
                    setLoader(false)
                }
            ).catch(error => {
                if (error.response) {
                    
                }
            })
        }
        const getTotal = async () => {
            axios.get(`/api/getTotalForReconciliation/${tid}`).then(
                res => {
                    setTotal(res.data.total || 0)
                    setLeadTotal(res.data.lead_sum || 0)
                    setSupportingTotal(res.data.supporting_sum || 0)
                    setPostedTotal(res.data.posted_total || 0)
                    setLeadDue(res.data.lead_due || 0)
                    setSupportingDue(res.data.supporting_due)
                }
            ).catch(error => {
                if (error.response) {
                    
                }
            })
        }
        const autoPost = async () => {
            setLoader(true)
            axios.put(`/api/reconciliation/autoPost/${tid}`).then(
                res => {
                    getMatches()
                    getTotal()
                    setLoader(false)
                }
            ).catch(error => {
                if (error.response) {
                    
                }
            })
        }

        // const sortByAmount = () => {
        //     let s = matches.sort((a, b) => b.amount.localeCompare(a.amount))
        //     setMatches(s)
        // }
        // const sortByReferee = () => {
        //     let s = matches.sort((a, b) => a.name.localeCompare(b.name))
        //     setMatches(s)
        // }
        // const sortByDate = () => {
        //     let s = matches.sort((a, b) => a.start_time.localeCompare(b.start_time))
        //     setMatches(s)
        // }

        const handleSearchNotPaid = () => {
            let x = matches.filter(obj => obj.paid !== true || ( obj.second_paid !== true && obj.second_amount > 0))
            setMatches(x)
        }

    return(
        <div>
            <Link to={`/desk/invoicemaster`}>
                    <button> Payment Reconciliation By Referee</button>
                    </Link>
            <h2>Payment Reconciliation</h2>
            <Grid container justify="space-around">
            <TableBody className={classes.totals}>
                <TableRow >
                    <TableCell>Total Paid</TableCell>
                    <TableCell className={classes.total}>${total}</TableCell>
                </TableRow>
                <TableRow className={classes.total}>
                    <TableCell>Total Posted</TableCell>
                    <TableCell className={classes.total}>${postedTotal}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Lead Referee</TableCell>
                    <TableCell>${leadTotal} paid</TableCell>
                    <TableCell>${leadDue} not paid</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Supporting Referee</TableCell>
                    <TableCell>${supportingTotal} paid</TableCell>
                    <TableCell>${supportingDue} not paid</TableCell>
                </TableRow>
            </TableBody>
            </Grid>
            
            <div>
            {loader ?
             '':
            <button onClick={autoPost}>Auto Post</button>}
                Auto post will automatically check matches.  If the estimated and actual payments are equal the match will be posted. </div>
                <h5>To edit an amount click on the number.</h5>

                <button onClick={()=>setShow(show => !show)}>{show ? 'Hide Explanation': 'Learn how to use this report'}</button>
                {show ? 
                <PaymentReconciliationInstructions />:''}
                <div>
                <div>
                    Court Num
                <input type="text"
                    value={searchCourt}
                    onChange={e=>setSearchCourt(e.target.value)}/>
            </div>
            <div>
                Referee
                <input type="text"
                    value={searchReferee}
                    onChange={e=>setSearchReferee(e.target.value)}/></div>
                </div>
                <div>
                    <button onClick={handleSearchNotPaid}>Not Paid</button>
                    <button onClick={getMatches}>Show All</button>
                </div>
                {/* <div>
                    Amount
                <input type="text"
                    value={searchAmount}
                    onChange={e=>setSearchAmount(e.target.value)}/> */}
                    {/* <button onClick={sortByAmount}>Sort By Amount</button>
                    <button onClick={sortByReferee}>Sort By Referee</button>
                    <button onClick={sortByDate}>Sort By Date</button> */}
            {/* </div> */}
                {loader ?
             <LinearProgress />:
            ''}
            <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
          <TableCell></TableCell>
            <TableCell>Court Num</TableCell>
            <TableCell>Referee</TableCell>
            <TableCell>Credentials</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Match Type</TableCell>
            <TableCell>SYSID</TableCell>
            <TableCell>Estimated Amount</TableCell>
            <TableCell >Amount</TableCell>
            <TableCell >Edit</TableCell>
            <TableCell>Paid</TableCell>
            <TableCell>Posted</TableCell>
            <TableCell>Supporting Referee</TableCell>
            <TableCell>Credentials</TableCell>
            <TableCell>Estimated Amount</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Edit</TableCell>
            <TableCell>Paid</TableCell>
            <TableCell>Posted</TableCell>
            <TableCell>Notes</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
        
          {matches
            .filter(obj => obj.court_num.toUpperCase().includes(searchCourt.toUpperCase()))
            .filter(obj => obj.name.toUpperCase().includes(searchReferee.toUpperCase()) || obj.supporting.toUpperCase().includes(searchReferee.toUpperCase()))
            // searchAmount > 0 ?
            // .filter(obj => obj.amount == searchAmount):''

            .map((row, i) => (
           <PaymentDetail 
           key={i}
            row={row}
            i={i}
            getTotal={getTotal}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

        </div>
    )
}
export default PaymentMaster

const useStyles = makeStyles({
    table: {
      minWidth: 650
    },
    totals: {
        background: 'white'
    },
    total: {
        fontSize: '20px'
    }
  });