import React, { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux'
import QRCodeStyling from "qr-code-styling";

const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  image:
    "https://res.cloudinary.com/shonda/image/upload/v1650459820/logos/rise_logo_plap4m.png",
  dotsOptions: {
    color: "black",
    type: "square"
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 0
  }
});

export default function QRRise() {
  const [url, setUrl] = useState("www.risepickleball.com");
  const [fileExt, setFileExt] = useState("png");
  const ref = useRef(null);

  const tid = useSelector(state => state.tid)

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url
    });
  }, [url]);

  const onUrlChange = (event) => {
    event.preventDefault();
    setUrl(event.target.value);
  };

  const onExtensionChange = (event) => {
    setFileExt(event.target.value);
  };

  const onDownloadClick = () => {
    qrCode.download({
      extension: fileExt
    });
  };

  return (
    <div className='container' style={{background: 'white'}}>
      
      <div ref={ref} />
      <input value={url} onChange={onUrlChange} style={styles.inputBox} />
      <div>
          Put any URL in 
      </div>
      <div>
          Scan now to make sure it works
      </div>
      <div style={styles.inputWrapper}>
        
        <select onChange={onExtensionChange} value={fileExt}>
          <option value="png">PNG</option>
          <option value="jpeg">JPEG</option>
          <option value="webp">WEBP</option>
        </select>
        <button onClick={onDownloadClick}>Download QR Image</button>
      </div>
    </div>
  );
}

const styles = {
  inputWrapper: {
    margin: "0",
    display: "flex",
    justifyContent: "space-between",
    width: "200px"
  },
  inputBox: {
    flexGrow: 1,
    marginRight: 20,
    width: '400px'
  }
};
