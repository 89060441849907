import React, { useState } from 'react'
import { useSelector } from 'react-redux'



const SignupsPublicDetail = props =>{
    const [verify, setVerify] = useState(false)
    const ref_id = useSelector(state => state.ref_id)

    let {spot, i} = props

    const handleDelete = () => {
        props.removeRef(spot.shift_id)
        setVerify(false)
    }

            return (
                <div style={{ display: 'flex' }}>
                    <div className=''>{++i}.</div>
                    <div className=''>
                        {spot.last}, {spot.first}
                    </div>
                    {spot.ref_id === ref_id ?
                        <button onClick={()=>setVerify(verify => !verify)}><i className="fas fa-user-minus" ></i></button> : ''}
                        {verify && spot.ref_id === ref_id ? 
                        <button 
                            className='register-button'
                            onClick={handleDelete}>Verify Remove From Shift</button> :''}
                </div>
            )
    }
export default SignupsPublicDetail