import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import VenueList from './VenueList'
import ExplainQrGuide from './ExplainQrGuide'

const EventCheck = () => {
    const [loader, setLoader] = useState(true)
    const [verified, setVerified] = useState(false)

    const tid = useSelector(state => state.tid)
    useEffect(
        () => { get() }, [tid])

    const get = () => {
        if(tid){
        axios.get(`/api/eventGuide/qrGuideCheck/${tid}`).then(res => {
            setLoader(false)
            setVerified(res.data)
        })
    }
    }

    return(
        <Grid container justify="center" >
            {loader && <h3>Gathering Event Guide</h3>}
            {!loader && verified && <VenueList />}
            {!loader && !verified && <ExplainQrGuide />}
        </Grid>
    )


}
export default EventCheck