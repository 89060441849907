import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import VolunteerShiftList from './VolunteerShiftList'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';





const EmailToVolunteerShiftsCaptains = () => {
    const [list, setList] = useState([])
    const [selected, setSelected] = useState([])
    const [subject, setSubject] = useState('')
    const [body, setBody] = useState('')
    const [saveEmail, setSaveEmail] = useState(false)
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)
    const reply = useSelector(state => state.email)
    const author = useSelector(state => state.ref_id)
    const tournamentName = useSelector(state => state.name)

    const classes = useStyles();

    useEffect(
        () => { getShifts() }, [tid, ref_id])

    const getShifts = () => {
        setLoader(true)
        axios.post(`/api/volunteer/getVolunteerShiftsForCaptain`, {tid, ref_id}).then(
            res => {
                setLoader(false)
                setList(res.data)
            }
        )
    }

    const sendEmail = () => {
        setLoader(true)
        axios.post(`/api/email/emailShifts`, {tid, list: selected, body, subject, tournamentName, category:'other', saveEmail, reply, author}).then(
            res => {
                setSelected([])
                setSubject('')
                setBody('')
                setLoader(false)
                toast.success('sent')
                
            }
        )
    }

    const select = (sid) => {
        if (selected.some(item => sid === item)) {
            let index = selected.map(function (e) { return e.sid; }).indexOf(sid);
            remove(index)
        } else {
            add(sid)
        }
    }

    // const handleSelectAll = () => {
    //     let all = list
    //     .filter(obj => obj.name ? obj.name.toUpperCase().includes(searchName.toUpperCase()) : '')
    //     .filter(obj => obj.credentials ? obj.credentials.toUpperCase().includes(searchCred.toUpperCase()) : '')
    //     .filter(obj => obj.count ? obj.count.toUpperCase().includes(searchCount.toUpperCase()) : '')
    //     .filter(obj => obj.status ? obj.status.toUpperCase().includes(searchStatus.toUpperCase()) : '')
    //     setSelected(all)
    // }
    const add = (sid) => {
        let list = selected.concat(sid)
        setSelected(list)
    }

    const remove = (i) => {
        let list = [...selected]
        list.splice(i, 1)
        setSelected(list)
    }
    const handleBody = (value) => {
        setBody(value)
    }
        return (
            <div className='container-fluid' >
                <h3>Email To Shifts</h3>
                {loader ?
                    <LinearProgress color="secondary"/>:''}
                    <div style={{textAlign:'left'}}>
                    <p style={{ color: 'red' }}>{list.length > 0 && selected.length < 1 && 'Select Shifts to Email'}</p>
                    {list.map((row, i)=>(
                        <VolunteerShiftList row={row} select={select} selected={selected.some(item => row.sid === item)}/>
                    ))}
                    {!loader && list.length === 0 ?
                    <h5>Sorry to shifts were found.</h5>:null}

<div className={classes.email}>
                      
                        
                        
                        <div> Tournament name will be added to the end of the subject</div>
                        <div>
                            <input type="text"
                                style={{width:'300px'}}
                                className={classes.emailBody}
                                placeholder='Subject'
                                value={subject}
                                onChange={e => setSubject(e.target.value)} />
                                :{tournamentName}
                               
                        </div>
                                
                        <div>

                            <ReactQuill
                                style={{ background: 'white' }}
                                value={body}
                                modules={EmailToVolunteerShiftsCaptains.modules}
                                onChange={handleBody} />
                                <p>Reply-to email will be your email address.</p>
                        
                        
                        <legend> 
                            <input
                                type="checkbox"
                                placeholder='Save to message board'
                                name="saveEmail"
                                checked={saveEmail}
                                value={saveEmail}
                                onChange={() => setSaveEmail(!saveEmail)} />
                                Add Email To Message Board
                                    
                            </legend>
                                

                            {selected.length > 0 ?
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={loader}
                                    onClick={sendEmail}
                                >
                                    {loader ?
                                        <CircularProgress />
                                        : 'Send Email'}</Button> :
                                <button onClick={selected} className='inactive-button'>Send Email</button>}



                            {loader ? <h4>Email sending... please wait and do not refresh or leave page</h4> : ''}
                            <p>To send attachments (photos, videos) upload them to dropbox, google docs or your choice in file storage.  You can then include a link in the email.</p>

                            <div style={{border: '2px solid #019CAE', fontSize: '1.5em', background: '#d6fbff'}}>Email communication can only be used for this tournament.  It is against site policies to use communication to solicit for other tournaments or products. </div>
                        </div>
                        </div>
                </div>

            </div>
        )
    }

export default EmailToVolunteerShiftsCaptains

const useStyles = makeStyles({
   
    error: {
        color: 'red'
    },
    credentials: {
        maxWidth: '40px'
    },
    count: {
        maxWidth: '50px'
        },
    status: {
        maxWidth: '60px'
        },
    email: {
        width: '98%'
    },
    emailBody: {
        width: '100%',
        margin: '10px'
    }
});

EmailToVolunteerShiftsCaptains.modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      [{ 'color': [] }, { 'background': [] }]
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }

