import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'

const CourtName = props => {
    const [name, setName] = useState([])
    const [color, setColor] = useState('')
    const [description, setDescription] = useState('')
    const [saved, setSaved] = useState(false)

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { setPropsToState() }, [props.court.court_num])

    const setPropsToState = () => {
        if (props.court.court_num) {
            setName(props.court.court_num)
            setColor(props.court.color)
            setDescription(props.court.description)
        }
    }

    const saveName = () => {
        setSaved(false)
        axios.put(`/api/nameCourts/`, { tid, court: props.court.court_order, name, old: props.court.court_num }).then(res => {
            setSaved(true)
        })
    }
    const saveColor = () => {
        setSaved(false)
        axios.put(`/api/courtColor/`, { tid, court: props.court.court_order, color }).then(res => {
            setSaved(true)
        })
    }
    const saveDescription = () => {
        setSaved(false)
        axios.put(`/api/courtDescription/`, { tid, court: props.court.court_order, description }).then(res => {
            setSaved(true)
        })
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            Order:  {props.court.court_order}  -  Name:
            <input type="text"
                value={name}
                maxLength={4}
                onChange={e => setName(e.target.value)}
                onBlur={saveName} />
    Description:
        <input type="text"
                value={description}
                maxLength={25}
                onChange={e => setDescription(e.target.value)}
                onBlur={saveDescription} />
            <label htmlFor="">
                    HEX Color, include #
                <input type="text"
                value={color}
                maxLength={25}
                onChange={e => setColor(e.target.value)}
                onBlur={saveColor} />
                </label>
            {saved ?
                <i className="fas fa-check-circle"
                    style={{ color: 'green' }}></i> : ''}
        </div>
    )

}
export default CourtName