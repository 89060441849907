import React, { Component } from 'react';
import axios from 'axios'
import { connect } from "react-redux"
import RefList from '../RefList/RefList'
import './RefDesk.css'
import RefStatus from './RefStatus'
import AddRef from './AddRef'
import RefCourtAssignments from './RefCourtAssignments'
import Assign from './Assign'
import LookupRef from './LookupRef'
import { toast } from 'react-toastify';



class RefDesk extends Component {
    constructor() {
        super()
        this.state = {
            allRefs: [],
            courtStatus: [],
            refStatus: [],
            refList: [],
            tournamentId: 5,
            court: [],
            assignedRef: {},
            medal: '',
            showLookupButton: false

        }
    }

    componentDidMount() {
        this.getRegisteredRefs()
        this.getCourtStatus()
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handlePickCourt = (e) => {
        this.setState({
            court: e

        })
    }

    handleAssignRef = (e, data) => {
        this.setState({ assignedRef: data })
    }

    handleFinishMatch = async (court_num, id, ref_id) => {
        axios.put(`/api/finishMatch/${this.props.tid}`, { court_num: court_num, id: id, ref_id: ref_id }).then(
            res => {
                this.setState({ courtStatus: res.data })
            }
        ).then(this.getAvailableRefs)
    }

    getAllRefs = () => {
        this.setState({ showLookupButton: true })
        axios.get(`/api/getAllRefList/${this.props.tid}`).then(
            res => {
                this.setState({ allRefs: res.data })
            }
        )
    }
    getAvailableRefs = () => {
        axios.get(`/api/getAvailableRefs/${this.props.tid}`).then(
            res => {
                this.setState({ refList: res.data })
            }
        )
    }

    checkIn = async (id) => {
        await axios.put(`/api/checkin/${this.props.tid}`, { ref_id: id }).then(
            res => {
                this.setState({ refStatus: res.data })
            }
        ).then(
            await this.getAvailableRefs
        )
    }

    quickBreak = async (id) => {
        await axios.put(`/api/quickBreak/${this.props.tid}`, { ref_id: id }).then(
            res => {
                this.setState({ refStatus: res.data })
            }
        ).then(
            await this.getAvailableRefs
        )
    }

    lastMatch = async (id) => {
        await axios.put(`/api/lastMatch/${this.props.tid}`, { ref_id: id }).then(
            res => {
                this.setState({ refStatus: res.data })
            }
        ).then(
            await this.getAvailableRefs
        )
    }

    handleEditRef = async (ref_id, first, last, credentials, phone, email, barcode) => {
        axios.put(`/api/editRef`, { tid: this.props.tid, ref_id, first, last, credentials, phone, email, barcode }).then(
            res => {
                this.setState({ refStatus: res.data })
            }
        )
    }

    getRegisteredRefs = async () => {
        await axios.get(`/api/getRegisteredRefs/${this.props.tid}`).then(
            res => {
                this.setState({ refStatus: res.data })
            }
        ).then(
            await this.getAvailableRefs
        )
    }

    getCourtStatus = async () => {
        await axios.get(`/api/getCourtStatus/${this.props.tid}`).then(

            res => {
                this.setState({ courtStatus: res.data })
            }
        ).then(
            await this.getAvailableRefs
        )
    }

    assign = async () => {
        await axios.post(`/api/assignCourt/${this.props.tid}`, { court_num: this.state.court, ref_id: this.state.assignedRef.ref_id, medal: this.state.medal }).then(

            res => {
                if(res.data.message){
                    toast.error(`CONFLICT- Referee is on court ${res.data.court_num}, Select new referee`)
                }else{
                this.setState({
                    courtStatus: res.data,
                    court: [],
                    assignedRef: {},
                    medal: ''
                })
            }
            }
        ).then(
            this.getAvailableRefs,
            axios.post(`/api/sendRefText`, { id: this.state.assignedRef.ref_id, courtNum: this.state.court })

        )
    }

    addRefToTournament = async (ref_id) => {
        await axios.post(`/api/addRefToTournament/${this.props.tid}`, { ref_id: ref_id }).then(

            res => {
                this.setState({
                    refStatus: res.data,
                    showLookupButton: false
                })
            }
        ).then(
            this.getAvailableRefs
        )
    }

    clearAssign = () => this.setState({
        court: [],
        assignedRef: {},
        medal: ''
    })




    render() {
        return (
            <div className="container-fluid">
                <div className='DeskView'>
                    <div className='col-12'>
                        <h1>Referee Desk</h1>

                    </div>
                    <div className='row'>

                        <div className='col-12 col-md-9'>

                            <RefCourtAssignments
                                courtStatus={this.state.courtStatus}
                                getCourtStatus={this.getCourtStatus}
                                handlePickCourt={this.handlePickCourt}
                                handleFinishMatch={this.handleFinishMatch} />

                        </div>
                        <div className='col-12 col-md-3'>

                            <Assign
                                court={this.state.court}
                                medal={this.state.medal}
                                assignedRef={this.state.assignedRef}
                                handleInputChange={this.handleInputChange}
                                assign={this.assign}
                                clearAssign={this.clearAssign}

                            />
                            <RefList
                                refList={this.state.refList}
                                assignedRef={this.state.assignedRef}
                                getAvailableRefs={this.getAvailableRefs}
                                handleAssignRef={this.handleAssignRef}
                            />
                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-12'>
                        <RefStatus
                            refStatus={this.state.refStatus}
                            getRegisteredRefs={this.state.getRegisteredRefs}
                            checkIn={e => this.checkIn(e)}
                            handleEditRef={this.handleEditRef}
                            quickBreak={this.quickBreak}
                            lastMatch={this.lastMatch}

                        />
                        {this.state.showLookupButton ?
                            <div>

                                <LookupRef
                                    allRefs={this.state.allRefs}
                                    getAllRefs={this.getAllRefs}
                                    addRefToTournament={this.addRefToTournament} />

                                <p>If ref is not in system add below</p>
                                <AddRef
                                    tid={this.props.tid}
                                    getRegisteredRefs={this.getRegisteredRefs} />

                            </div> : <button onClick={this.getAllRefs}>Add Referee</button>}
                    </div>
                </div>
            </div>

        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid
    }
}


export default connect(
    mapStateToProps,
)(RefDesk)