import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import axios from 'axios'
import Switch from "@material-ui/core/Switch";
import './CourtMonitors.css'
import CourtMonitorBoard from './CourtMonitorBoard'
import VolunteerGroups from './VolunteerGroups'

const VolunteerCheckedInAndGroups = props => {
    const [volunteers, setVolunteers] = useState([])
    const [selectedVolunteer, setSelectedVolunteer] = useState(0)
    const [searchName, setSearchName] = useState('')
    const [registeredVolunteers, setRegisteredVolunteers] = useState([])

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getVolunteers() }, [])
    useEffect(
        () => { getRegisteredVolunteers() }, [])


    const getVolunteers =  () => {
        axios.get(`/api/getCheckedInVolunteers/${tid}`).then(

            res => {
                setVolunteers(res.data)
            }
        )
    }

    const getRegisteredVolunteers = () => {
        axios.get(`/api/getVolunteers/${tid}`).then(res => {
            setRegisteredVolunteers(res.data)
        })
    }

    const checkinVolunteer = (ref_id) => {
        axios.put(`/api/checkinVolunteer/${tid}`, { ref_id }).then(res => {
            if (props.handleCheckInModal) {
                props.handleCheckInModal()
            }
            else {
                getVolunteers()
                getRegisteredVolunteers()

            }
        })
    }

    const mappedVolunteers = volunteers.map(v => {

        return (
            <div
                className={v.ref_id === selectedVolunteer ? 'selected-volunteer' : ''}
                style={{ display: 'flex' }}
                key={v.ref_id}
                onClick={() => setSelectedVolunteer(v.ref_id)}>
                < Switch
                    color="primary"
                    checked={v.status}
                    value={v.status ? "Active" : 'Inactive'}
                    onClick={() => checkinVolunteer(v.ref_id)}
                />
                <div>
                    {v.last}, {v.first}
                </div>

            </div>
        )
    })

    const mappedRegistered = registeredVolunteers
        .filter(obj => obj.last.toUpperCase().includes(searchName.toUpperCase()))
        .map(v => {
            return (
                <div key={'r' + v.ref_id}>
                    < Switch
                        color="primary"
                        checked={v.status}
                        value={v.status ? "Active" : 'Inactive'}
                        onClick={() => checkinVolunteer(v.ref_id)}
                    />
                    {v.last}, {v.first}
                </div>
            )
        })


    return (
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <h3>Volunteer Management</h3>
                    <p className='tips'>Create groups and assigned volunteers to the groups.  To assign a volunteer to a group select the volunteers name then click on the group title.</p>
                </div>
                <div className='col-12 col-md-3'>
                    <div
                        style={{ background: '#DBFAB6', marginBottom: '15px' }}>
                        <div className='tips'>
                            <h4>Checked In Volunteers</h4>
                            Not assigned to a group
                        </div>
                        <div style={{ paddingLeft: '15px' }}>
                            {mappedVolunteers}
                        </div>
                    </div>
                    <div
                        style={{ background: 'white', marginBottom: '15px' }}>

                        <h4 className='tips'>Registered Volunteers</h4>
                        <legend>
                            Search Last Name
                <input type="text"
                                value={searchName}
                                onChange={e => setSearchName(e.target.value)} />
                        </legend>
                        <div style={{ textAlign: 'left' }}>
                            {mappedRegistered}
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-9'>

                    <VolunteerGroups
                        selectedName={selectedVolunteer}
                        setSelectedName={setSelectedVolunteer}
                        getVolunteers={getVolunteers}
                        volunteers={volunteers}
                    />
                </div>

            </div>

        </div>
    )
}

export default VolunteerCheckedInAndGroups