import React, { Component } from 'react';
import axios from 'axios'
import { connect } from "react-redux"
import io from 'socket.io-client'
import "react-table/react-table.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import NotifyDesk from './NotifyDesk'
import RefreshIcon from '@material-ui/icons//Refresh';

class IndividualStatusUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            status: {},
            quickBreak: false,
            checkingQueue: false,
            queue: '',
            queueWarning: false,
            checkoutModal: false,
            statusCheckout: '',
            statusTime: '',
            statusOther: '',
            note: '',
            refMessage: '',
            loader: true
        }
    }

    componentDidMount() {
        this.getRefereeStatus()
        this.setSocketListeners()
    }

    componentDidUpdate(prevProps, prevState) {
        const { ref_id } = this.props;

        if (prevProps.ref_id !== ref_id) {
            this.getRefereeStatus()
        }
    }

    componentWillUnmount() {
        this.socket.disconnect()
    }

    setSocketListeners = () => {
        this.socket = io()

        this.socket.emit('joinRoom', `board:${this.props.tid}`)

        this.socket.on('board', results => {
            if(!this.props.ref_id === null){return}
            if(this.props.ref_id === null || this.props.ref_id === undefined){return}
            if(results.msg === 'assign'){
                if(results.court.ref_id === this.props.ref_id || results.court.ref_id === this.props.second){
                    this.getRefereeStatus()
                }
            }
            if(results.msg === 'finish'){
                if(results.referee.ref_id === this.props.ref_id){
                    this.getRefereeStatus()
                }
            }
            if(results.msg === 'swapQueue'){
                if(results.referee.ref_id === this.props.ref_id){
                    this.getRefereeStatus()
                }
            }
            if(results.msg === 'lastMatch'){
                if(results.referee.ref_id === this.props.ref_id){
                    this.getRefereeStatus()
                }
            }
            if(results.msg === 'updateReferee'){
                
                if(results.referee.ref_id === this.props.ref_id){
                    this.getRefereeStatus()
                }
            }
            return
            

        })
    }

    getRefereeStatus = () => {
        if (this.props.ref_id) {
            this.setState({ showLookupButton: true })
            axios.post(`/api/t/getRefereeStatusForTournament/${this.props.tid}`, { ref_id: this.props.ref_id }).then(
                res => {
                    this.setState({ status: res.data, quickBreak: res.data.quick_break,refMessage: res.data.ref_note, loader: false })
                }
            )
        }
    }
// same but adds toast
    refreshStatus = () => {
        if (this.props.ref_id) {
            this.setState({ showLookupButton: true })
            axios.post(`/api/t/getRefereeStatusForTournament/${this.props.tid}`, { ref_id: this.props.ref_id }).then(
                res => {
                    toast.success('refreshed')
                    this.setState({ status: res.data, quickBreak: res.data.quick_break,refMessage: res.data.ref_note, loader: false })
                }
            )
        }
    }

    getQueueLength = (category) => {
        
        this.setState({ checkingQueue: true })
        axios.get(`/api/getRefereeQueue/${this.props.tid}`).then(
            res => {
                this.setState({ checkingQueue: false })
                if (+res.data.in_queue < res.data.queue_min) {
                    this.setState({ queueWarning: true })
                }
                else if (category === 'checkout') {
                    this.setState({ checkoutModal: true })
                }
                else if (category === 'lunch') {
                    toast.success('Enjoy Your Lunch Break')
                    this.lunchBreakStart()
                }else if (category === 'Quick Break'){
                    toast.success('Quick Break Started')
                    this.quickBreak()
                }else if (category === 'Last Match'){
                    this.lastMatch()
                }
            }
        )
    }

    hitSockets = () => this.socket.emit('board', { room: `board:${this.props.tid}` })

    checkIn = async () => {
        this.setState({ checkoutModal: false, loader: true })
        if (this.state.queue === 0) { this.queueWarning() }

        let reason = `${this.state.statusCheckout} ${this.state.statusTime.length > 0 ? 'back @' : ''} ${this.state.statusTime}`

        await axios.put(`/api/checkin/${this.props.tid}`, { ref_id: this.props.ref_id, checkout_note: reason, status: false }).then(
            res => {
                this.getRefereeStatus()
                this.setState({ checkoutModal: false, loader: false })
                if (res.data.status) {
                    toast.success(`Thank you for checking in`)
                }
            }
        ).then(
            this.socket.emit('board', { room: `board:${this.props.tid}`,  msg: 'swapQueue', referee: {ref_id: this.props.ref_id} })
        )
    }

    shiftCheckIn = async () => {
        this.setState({ checkoutModal: false, loader: true })
        await axios.put(`/api/shiftcheckin/${this.props.tid}`, { ref_id: this.props.ref_id }).then(
            res => {
                this.getRefereeStatus()
                this.setState({ checkoutModal: false, loader: false })
                this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'shiftCheckin', referee: {ref_id: this.props.ref_id} })
                if (res.data.status) {
                    toast.success(`Thank you for checking in`)
                }
            }
        )
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleQuickBreak = () => {
        if(this.state.quickBreak){
            this.quickBreakTurnOff()
        }else {
            this.quickBreakTurnOn()
        }
    }

    quickBreak = async () => {
        this.setState({ loader: true })
        await axios.put(`/api/quickBreak/${this.props.tid}`, { ref_id: this.props.ref_id }).then(
            res => {
                this.getRefereeStatus()
                this.setState({ loader: false })
                this.socket.emit('board', { room: `board:${this.props.tid}`, msg:'updateReferee', referee: {ref_id: this.props.ref_id} })
            }
        )
    }
    quickBreakTurnOn =  () => {
        this.setState({ checkingQueue: true })
         axios.put(`/api/quickBreakTurnOn/${this.props.tid}`, { ref_id: this.props.ref_id }).then(
            res => {
                if(res.data === 'queue warning'){
                    this.setState({ checkingQueue: false, queueWarning: true})
                }else {
                this.setState({ checkingQueue: false, quickBreak: true })
                this.socket.emit('board', { room: `board:${this.props.tid}`,msg:'updateReferee', referee: {ref_id: this.props.ref_id} })
                }
            }
        )
    }
    quickBreakTurnOff =  () => {
        this.setState({ loader: true })
         axios.put(`/api/quickBreakTurnOff/${this.props.tid}`, { ref_id: this.props.ref_id }).then(
            res => {
                this.setState({ quickBreak: false, loader: false })
                this.socket.emit('board', { room: `board:${this.props.tid}`, msg:'updateReferee', referee: {ref_id: this.props.ref_id} })
            }
        )
    }

    lastMatch =  () => {
        this.setState({ loader: true })
         axios.put(`/api/lastMatch/${this.props.tid}`, { ref_id: this.props.ref_id }).then(
            res => {
                this.setState({ loader: false })
                this.getRefereeStatus()
                this.socket.emit('board', { room: `board:${this.props.tid}`, msg:'lastMatch', referee: {ref_id: this.props.ref_id} })
            }
        )
    }

    lunchBreakStart =  () => {
        this.setState({ loader: true })
         axios.put(`/api/lunchBreakStart/${this.props.tid}`, { ref_id: this.props.ref_id, note: 'Lunch Break' }).then(
            res => {
                this.setState({ loader: false })
                this.getRefereeStatus()
                this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'swapQueue', referee: {ref_id: this.props.ref_id} })
            }
        )
    }
    lunchBreakEnd = async () => {
        this.setState({ loader: true })
        await axios.put(`/api/lunchBreakEnd/${this.props.tid}`, { ref_id: this.props.ref_id }).then(
            res => {
                this.getRefereeStatus()
                this.setState({ loader: false })
                this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'swapQueue', referee: {ref_id: this.props.ref_id} })
            }
        )
    }
    saveRefMessage = async () => {
        this.setState({ loader: true })
        axios.put(`/api/saveRefMessage/${this.props.tid}`, { ref_id: this.props.ref_id, refMessage: this.state.refMessage }).then(
            res => {
                toast.success('Queue Message Saved')
                // this.getRefereeStatus()
                this.setState({ loader: false })
                this.socket.emit('board', { room: `board:${this.props.tid}` })
            }
        )
    }

    closeCheckoutModal = () => this.setState({ checkoutModal: false })

    queueWarning = () => this.setState({ queueWarning: !this.state.queueWarning })

    checkingQueue = () => this.setState({ checkingQueue: !this.state.checkingQueue })


    render() {

        return (
            <div className="">
                <button onClick={this.refreshStatus}><RefreshIcon />Refresh</button>
                {this.state.loader ?
                    <img id='loader' width="100" src={require('../../img/15.gif')} alt='players' /> : ''}
                <h5><small>Status: </small>{this.state.status.court_num ? ` Court ${this.state.status.court_num}` :
                    this.state.status.note === 'Lunch Break' ?
                        'Lunch Break' :
                        this.state.status.shift_checkin ?
                            'Checked In For Shift' :
                            this.state.status.status ?
                                'In Queue' : 'Checked Out'}
                </h5>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    {this.state.status.shift_checkin ?
                        '' :
                        <div>
                            {this.state.status.status ?
                                <FormControlLabel
                                    label={this.state.status.status ? 'Checked In' : 'Checked Out'}
                                    value={this.state.status.status ? 'Checked In' : 'Checked Out'}
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={this.state.status.status ? true : false}
                                            value={this.state.status.status ? "Active" : 'Inactive'}

                                        />
                                    }
                                    onClick={this.state.status.status ? () => this.getQueueLength('checkout') : this.shiftCheckIn}

                                /> :
                                <p>Check In at Referee Desk</p>}
                        </div>
                    }

                    {this.state.status.status ?
                        <div>

                            <FormControlLabel
                                label={this.state.quickBreak ? 'Quick Break' : 'Quick Break'}
                                value={this.state.quickBreak }
                                control={
                                    <Switch
                                        color="primary"
                                        checked={this.state.quickBreak ? true : false}
                                        value={this.state.quickBreak ? "Active" : 'Inactive'}

                                    />
                                }
                                onClick={this.handleQuickBreak}

                            />
                            <FormControlLabel
                                label={this.state.status.last_match ? 'Last Match' : 'Last Match'}
                                control={
                                    <Switch
                                        color="primary"
                                        checked={this.state.status.last_match ? true : false}
                                    />
                                }
                                onClick={()=>this.getQueueLength('Last Match')}
                            />
                            <FormControlLabel
                                label={this.state.status.note === 'Lunch Break' ? 'Lunch Break' : 'Lunch Break'}
                                value={this.state.status.note === 'Lunch Break' ? 'Lunch Break' : 'Lunch Break'}
                                control={
                                    <Switch
                                        color="primary"
                                        checked={this.state.status.note === 'Lunch Break' ? true : false}
                                        value={this.state.status.note === 'Lunch Break' ? "Active" : 'Inactive'}

                                    />
                                }
                                onClick={this.state.status.note === 'Lunch Break' ? () => this.lunchBreakEnd() : () => this.getQueueLength('lunch')}

                            />
                            <br/>
                            <input type="text" 
                                placeholder='Queue Note'
                                value={this.state.refMessage}
                                onChange={e=>this.setState({refMessage:e.target.value})} />
                                
                                <button 
                                    className='button-small'
                                    onClick={this.saveRefMessage}>save</button>
                                    <br/>
                                    <small>This adds a note to your name in the queue.</small>
                            <NotifyDesk />
                        </div> : ''}
                </div>

                {/* {this.state.status.checkout_note && !this.state.status.status ? `Checkout Note: Returning-  ${this.state.status.checkout_note} ` : ''} */}

                <Modal open={this.state.checkoutModal} center showCloseIcon={true} onClose={this.closeCheckoutModal} closeIconSize={20} closeOnEsc={true} >
                    <div className='ref-status-container'>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <legend>Will you be returning to ref?</legend>
                            <label>
                                <input type='radio'
                                    checked={this.state.statusCheckout === 'Lunch'}
                                    name='statusCheckout'
                                    value='Lunch'
                                    onChange={this.handleChange}
                                /> Lunch Break </label>
                            <label>
                                <input type='radio'
                                    value='Today'
                                    checked={this.state.statusCheckout === 'Today'}
                                    name='statusCheckout'
                                    onChange={this.handleChange}
                                /> Today </label>
                            <label>
                                <input type='radio'
                                    checked={this.state.statusCheckout === 'Today, When done playing'}
                                    name='statusCheckout'
                                    value='Today, When done playing'
                                    onChange={this.handleChange}
                                /> Today, when done playing </label>
                            <label>
                                <input type='radio'
                                    checked={this.state.statusCheckout === 'Tomorrow'}
                                    name='statusCheckout'
                                    value='Tomorrow'
                                    onChange={this.handleChange}
                                /> Tomorrow </label>


                            <label>
                                <input type='radio'
                                    checked={this.state.statusCheckout === 'Not Returning'}
                                    name='statusCheckout'
                                    value='Not Returning'

                                    onChange={this.handleChange}
                                /> Not Returning </label>
                            <label>
                                <input type='radio'
                                    checked={this.state.statusCheckout === 'Contact if needed'}
                                    name='statusCheckout'
                                    value='Contact if needed'
                                    onChange={this.handleChange}
                                /> Contact if needed </label>
                            <label>
                                <input type='radio'
                                    checked={this.state.statusOther === true}
                                    name='statusOther'
                                    value='true'
                                    onChange={this.handleChange}
                                /> Other </label>
                            {this.state.statusOther ?
                                <input type="text"
                                    placeholder=''
                                    maxLength='50'
                                    name='status'
                                    value={this.state.status}
                                    onChange={this.handleChange} />


                                : ''}
                        </div>


                        <legend>What time will you be back?</legend>
                        <input type="text"
                            placeholder='Time'
                            name='statusTime'
                            maxLength='10'
                            value={this.state.statusTime}
                            onChange={this.handleChange} />

                    </div>

                    <button onClick={this.checkIn}><i className="far fa-save"></i>Save</button>
                    <button onClick={this.showCheckoutModal}><i className="fas fa-ban" ></i>Cancel</button>

                </Modal>
                <Modal open={this.state.queueWarning} center showCloseIcon={true} onClose={this.queueWarning} closeOnEsc={true} closeIconSize={2}>
                    <div>
                        <h4>Sorry the referee queue is low so this feature is not available.  Please contact the desk to checkout or go on break.</h4>
                    </div>
                    <button onClick={this.queueWarning}> Close</button>
                </Modal>
                <Modal open={this.state.checkingQueue} center showCloseIcon={true} onClose={this.checkingQueue} closeOnEsc={true} closeIconSize={2}>
                    <div>
                        <h4>Checking to see if self-checkout is available...</h4>
                    </div>
                </Modal>
            </div >

        )
    }
}


const mapStateToProps = reduxState => {
    return {
        ref_id: reduxState.ref_id,
        tid: reduxState.tid
    }
}


export default connect(
    mapStateToProps,
)(IndividualStatusUpdate);