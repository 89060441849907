import React from 'react'
import Moment from 'react-moment';


const BoardMagnetDuel = props => {
    const { court, stateCourt } = props

    return (
        <div key={court.court_num} className='court-box-boardView'
            style={{ cursor: 'pointer' }}
            onClick={court.ref_id ? () => props.finish(court) : () => props.selectCourt(court.court_num)} >
            <div className='col-4 court-num' style={{background: court.color || '#333333'}}>
                <div className={stateCourt === court.court_num ? 'selectedCourt' : 'court-name'}>
                    <div className={court.court_num.length > 2 ? 'shrink-court-num' : ''}>{court.court_num}</div>
                </div>
                {court.medal === 'Bronze' ?
                    <div className='board-medal'>
                        <i className="fas fa-medal"></i>
                    B
                    </div> :
                    court.medal === 'Gold' ?
                        <div className='board-medal'>
                            <i className="fas fa-medal"></i>
                        G
                    </div> : ''}
                <div className='board-start-time'>
                    {court.ref_id ?

                        <Moment format="h:mm"
                            local>
                            {court.start_time}
                        </Moment>
                        : ''}
                </div>

            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <div className='dual'>
                    <div className={court.credentials_verified === 'Certified' ? 'certified-board' : court.credentials_verified === 'Level 2' ? 'level2-board' : court.credentials_verified === 'Level 1' ? 'level1-board' :
                        stateCourt === court.court_num ? 'selectedCourt' :
                            court.ref_id ? ' volunteer-board' :
                                ''}>{court.first} {court.last}</div>
                </div>

                <div className='dual'>
                    <div className={court.second_cred === 'Certified' ? 'certified-board' : court.second_cred === 'Level 2' ? 'level2-board' : court.second_cred === 'Level 1' ? 'level1-board' :
                        stateCourt === court.court_num ? 'selectedCourt' :
                            court.ref_id ? ' volunteer-board' :
                                ''}> {court.second}</div>
                </div>
            </div>
        </div>
    )
}
export default BoardMagnetDuel