import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';


const RefereeTimeAndShifts = () => {
    const [names, setNames] = useState([])
    const [loader, setLoader] = useState(false)
    const [searchName, setSearchName] = useState('')

    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)

    useEffect(
        () => { getNames() }, [tid, ref_id])

    const getNames = () => {
        setLoader(true)
        axios.get(`/api/getRefereeTimeAndShifts/${tid}`).then(
            res => {
                setLoader(false)
                setNames(res.data)
            }
        )
    }

        return (
            <div className='container-fluid' >
                <h3>Referee Time and Shift Detail</h3>
                <p>Referee Count: {names.length}</p>
                <div>
                    Search Name
                    <input value={searchName} onChange={e=>setSearchName(e.target.value)}/>
                   
                </div>
                {loader &&
                    <LinearProgress color="secondary"/>}


<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="left">time</TableCell>
            <TableCell align="left">detail</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {names
            .filter(obj => obj.name.toUpperCase().includes(searchName.toUpperCase()))

        .map((n, i)=>(
                <TableRow key={i}>
                <TableCell component="th" scope="row">
                    {n.name}
                </TableCell>
                <TableCell align="left">{n.total_time.hours ? n.total_time.hours: '00'}{n.total_time.minutes ? 
               
                    n.total_time.minutes < 10 ?
                    `:0${n.total_time.minutes}`:
                    `:${n.total_time.minutes}`:':00'}</TableCell>
                <TableCell align="left">
                    {n.actual
                    .map((s,i)=>(
                    <div key={'a'+i}>
                       {s.total_time === null ? 'on duty':s.total_time.substring(0, 5)}
                       {' - '}
                    
                    {s.start} - {s.end}
                    </div>
                ))}
                </TableCell>

                </TableRow>
                
                ))}
        </TableBody>
        </Table>
        </TableContainer>
                    
            </div>
        )
    }

export default RefereeTimeAndShifts

