import React from 'react'

const DisplayRefSchedule = (props) => {
    const mappedSchedule = props.referee.shifts.map((s, i) => {
        return (
            <div key={'s' + i}>
                <div>{s.name}: {s.category} </div>
            </div>
        )
    })
    return (
        <div>
            {props.referee.shifts ?
            mappedSchedule:''}
        </div>
    )
}

export default DisplayRefSchedule