import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import ScheduleListByName from './../Schedule/ScheduleListByName'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Link } from 'react-router-dom';



const RefereeSchedule = () => {
    const [names, setNames] = useState([])
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)
    const organization = useSelector(state => state.organization)

    useEffect(
        () => { getSchedule() }, [tid, ref_id])

    const getSchedule = () => {
        setLoader(true)
        axios.post(`/api/getScheduleByRef/${tid}`).then(
            res => {
                setLoader(false)
                setNames(res.data)
            }
        )
    }

    


        return (
            <div className='container-fluid' >
                <h3>Referee List with Their Schedule</h3>
                Count: {names.length}
                <br/>
                <Link to={`/desk/emailtoshifts`} >
                <button className='register-button' style={{fontSize:'20px'}}>Send Email to Shifts</button>
                </Link>
                {loader ?
                    <LinearProgress color="secondary"/>:''}
                <ScheduleListByName 
                    names={names}
                    category='referee'/>

            </div>
        )
    }

export default RefereeSchedule

