import React, { useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import io from 'socket.io-client'
import './NotifyDesk.css'
import { NotifyButtons } from './NotifyButtons';
import CircularProgress from '@material-ui/core/CircularProgress';



const NotifyDesk = props => {
    const [message, setMessage] = useState('')
    const [type, setType] = useState('')
    const [displayType, setDisplayType] = useState('')
    const [matchType, setMatchType] = useState('Amateur')
    const [loader, setLoader] = useState(false)

    const ref_id = useSelector(state => state.ref_id)
    const tid = useSelector(state => state.tid)

    const socket = io()


    const notify = () => {
        setLoader(true)
        axios.post(`/api/notify`, { tid, ref_id, message: `(${matchType}) ${message}`, type }).then(
            res => {
                setLoader(false)
                if (res.data === 'No Court') {
                    toast.error('You are not assigned to a court so notification was not sent.')
                }
                else if (res.data) {
                    toast.success('Message Sent, Your Phone Number has been provided')
                    socket.emit('notification', { room: `notification:${tid}` })
                    clear()
                }
                else {
                    toast.error('Issue Occurred- message not sent')
                }
            }
        ).catch(err => {
            toast.error('Issue Occurred- message not sent')
        })
    }
    const handleType = (type) => {
        setType(type)
        switch(type) {
            case '2nd':
                setDisplayType('Second Call')
                break
            case 'desk':
                setDisplayType('Message Referee Desk')
                break
            case 'td':
                setDisplayType('TD Requested')
                break
            case 'medical':
                setDisplayType('Medical')
                break
                
            case 'head ref':
                setDisplayType('Head Referee Requested')
                break
            default: 
            setDisplayType('')
            break
          }
    }

    const clear = () => {
        setMessage('')
        setType('')
        setDisplayType('')
    }


    return (
        <div>
            {type.length > 1 ?
            <div>
                <div className='login-button'>{displayType}</div>
            <label>
                {type === '2nd' ? 'Player Name(s)':'Message'}
            <textarea type="text"
                    value={message}
                    autoComplete="new-password"
                    onChange={e => setMessage(e.target.value)} />
            </label>
            <label>
                Match Type:
                <input type='radio'
                    checked={matchType === 'Amateur'}
                    name='matchType'
                    value={matchType}
                    onChange={() => setMatchType('Amateur')}/>
                    Amateur
                <input type='radio'
                    checked={matchType === 'Pro'}
                    name='matchType'
                    value={matchType}
                    onChange={() => setMatchType('Pro')}/>
                    Pro
            </label>
            <div>
                <button onClick={notify}>Send {loader && <CircularProgress />}</button>
                <button onClick={clear}>Cancel</button>
            </div>
            </div>:
            <NotifyButtons handleType={handleType}/>}
            
        </div>
    )
}
export default NotifyDesk