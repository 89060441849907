import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import SubmitQuestion from './SubmitQuestion'


const ListOfAnswers = props => {
    const [questions, setQuestions] = useState([])

    const ref_id = useSelector(state => state.ref_id);

    useEffect(
        () => { getQuestions() }, [])

    const getQuestions = () => {
        axios.get(`/api/getQuestions`).then(
            res => {
                setQuestions(res.data)
            }
        )
    }

    const mappedQuestions = questions.map(question => {
        return (
            <div key={question.qid}>
                <div>
                    Question: {question.question}
                </div>
                <div>
                    Answer: {question.answer}
                </div>
            </div>
        )
    })

    return (
        <div className='container'>
            <SubmitQuestion />
            <div style={{ textAlign: 'left', background: 'white' }}>
                {mappedQuestions}
            </div>
        </div>
    )
}
export default ListOfAnswers