import React from 'react'
import { Grid, Container, Paper, Typography } from '@material-ui/core';
import './QuizQuestions.css'

const QuizQuestion = () => {
    return (
        <Paper elevation={3} style={{ padding: '20px' }}>
            <div className='col-12 question'>
                Question
            </div>
            <div className='col-12'>
                <div className='col-12 answer'>
                    1.
                </div>
                <div className='col-12 answer'>
                    2.
                </div>
                <div className='col-12 answer'>
                    3.
                </div>
                <div className='col-12 answer'>
                    4.
                </div>
            </div>
        </Paper>
    )
}
export default QuizQuestion 