import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

const MatchDetail = props => {
    const [paid, setPaid] = useState(false)
    const [posted, setPosted] = useState(false)
    const [secondPaid, setSecondPaid] = useState(false)
    const [secondPosted, setSecondPosted] = useState(false)
    const [paymentNote, setPaymentNote] = useState('')
    const [amount, setAmount] = useState(0)
    const [secAmount,setSecAmount] = useState(0)
    const [note, setNote] = useState('')
    const [editAmount, setEditAmount] = useState(false)
    const [editSecAmount, setEditSecAmount] = useState(false)
    const [verifyDelete, setVerifyDelete] = useState(false)

    const first = useSelector(state => state.first)

    let {row, i} = props
    const classes = useStyles()

    useEffect(
        () => { setProps() }, [props.row])

    const setProps = () => {
        setPaid(row.paid)
        setPosted(row.posted)
        setSecondPaid(row.second_paid)
        setSecondPosted(row.posted_second)
        setPaymentNote(row.payment_note)
        setAmount(row.amount)
        setSecAmount(row.second_amount)
    }
    const handlePaid = () => {
        if(props.lead){
            leadPaid()
        }else{
            handleSecondPaid()
        }
    }
    const leadPaid = async () => {
        axios.put(`/api/reconciliation/paid`, {id:row.id, first, status: !paid}).then(
            res => {
                setPaid(paid => !paid)
                setPaymentNote(res.data)
                props.handleUpdate()
                
                
                
            }
        ).catch(error => {
            if (error.response) {
                
            }
        })
    }
    const handleSecondPaid = async () => {
        axios.put(`/api/reconciliation/supportingPaid`, {id:row.id, first, status: !paid}).then(
            res => {
                setPaid(paid => !paid)
                setPaymentNote(res.data)
                
                props.handleUpdate()
            }
        ).catch(error => {
            if (error.response) {
                
            }
        })
    }
    const handlePosted = () => {
        if(props.lead){
            leadPosted()
        }else {
            handleSecondPosted()
        }
    }
    
    const leadPosted = async () => {
            axios.put(`/api/reconciliation/posted`, {id:row.id, first, status: !posted}).then(
                res => {
                    setPosted(posted => !posted)
                    setPaymentNote(res.data)
                    
                    props.handleUpdate()
                }
            ).catch(error => {
                if (error.response) {
                    
                }
            })
        }
        const handleSecondPosted = async () => {
            axios.put(`/api/reconciliation/supportingPosted`, {id:row.id, first, status: !posted}).then(
                res => {
                    setPosted(posted => !posted)
                    setPaymentNote(res.data)
                    
                    props.handleUpdate()
                }
            ).catch(error => {
                if (error.response) {
                    
                }
            })
        }
        
        const recordPaymentChange = async () => {
            axios.put(`/api/reconciliation/updatePaymentAmount`, {id:row.id, first, amount, note}).then(
                res => {
                    
                    setPaymentNote(res.data)
                    setEditAmount(false)
                    props.handleUpdate()
                }
            ).catch(error => {
                if (error.response) {
                    
                }
            })
        }
        const recordSecondPaymentChange = async () => {
            axios.put(`/api/reconciliation/updateSecondPaymentAmount`, {id:row.id, first, amount: secAmount, note}).then(
                res => {
                    setPaymentNote(res.data)
                    setEditSecAmount(false)
                    
                    props.handleUpdate()
                }
            ).catch(error => {
                if (error.response) {
                    
                }
            })
        }

        const deleteMatch = () => {
            axios.put(`/api/deleteMatch/${props.row.id}`).then(
    
                res => {
                    setVerifyDelete(false)
                    props.getLists()
                }
            )
        }

        const handleEditAmount = () => {
            if(props.lead){
                setEditAmount (editAmount => !editAmount)
            }else if(props.lead === false){
                setEditSecAmount(editSecAmount => !editSecAmount)
            }else{
                setEditAmount (editAmount => !editAmount)
            }

            
        
        }
        const handleEditSecAmount = () => setEditSecAmount(editSecAmount => !editSecAmount)

    return(
            <TableRow>
                <TableCell component="th" scope="row">
                {++i}
              </TableCell>
              <TableCell >
                {row.court_num}
              </TableCell>
              <TableCell>{row.other_ref}</TableCell>
            <TableCell>{row.credentials}</TableCell>
            <TableCell>
                <div className={classes.time}>
                    <div >
                        {row.start_time}
                    </div>
                    
                    <div>
                        {row.end_time}
                    </div>
                    <div>
                        {`${row.match_time} min`}
                    </div>
                    
                </div>
            </TableCell>
            <TableCell>{row.match_type}</TableCell>
            <TableCell>{row.sysid}</TableCell>
            <TableCell>{row.estimated_amount}</TableCell>
            <TableCell 
                className={classes.amount} 
                onClick={handleEditAmount}>
                {amount}
            </TableCell>
            <TableCell 
                className={classes.edit} 
                onClick={handleEditAmount}>
                <i className="fas fa-pen"></i>
            </TableCell>
            <TableCell><input type="checkbox"
                    checked={paid}
                    onChange={handlePaid}/></TableCell>
            <TableCell>
            <input type="checkbox"
                    checked={posted}
                    onChange={handlePosted}/>
            </TableCell>
            
            <TableCell className={classes.notes}>{paymentNote}</TableCell>
            <TableCell className={classes.notes}>
            <button onClick={()=>setVerifyDelete(!verifyDelete)}>Delete</button>    
            {verifyDelete ? <button 
            style={{background: 'red'}}
            onClick={deleteMatch}>YES Delete</button>:null}
            </TableCell>
            <Dialog  open={editAmount} close={handleEditAmount} className={classes.dialog}>
      <DialogTitle >Edit Payment Amount For {row.name}</DialogTitle>
      <div>
          <label htmlFor="">
              Amount
              <input type="number"
                value={amount}
                onChange={e=>setAmount(e.target.value)}/>
          </label>
          <label htmlFor="">
              Note
              <input type="text"
                value={note}
                onChange={e=>setNote(e.target.value)}/>
          </label>
          <button onClick={recordPaymentChange}>Save</button>
          <button className='register-button'
            onClick={handleEditAmount}>Cancel</button>
      </div>
      </Dialog>
      <Dialog  open={editSecAmount} close={handleEditSecAmount} className={classes.dialog}>
      <DialogTitle >Edit Payment Amount For {row.name}</DialogTitle>
      <div>
    
          <label htmlFor="">
              Amount
              <input type="number"
                value={secAmount}
                onChange={e=>setSecAmount(e.target.value)}/>
          </label>
          <label htmlFor="">
              Note
              <input type="text"
                value={note}
                onChange={e=>setNote(e.target.value)}/>
          </label>
          <button onClick={recordSecondPaymentChange}>Save</button>
          <button className='register-button'
            onClick={handleEditSecAmount}>Cancel</button>
      </div>
      </Dialog>
                        </TableRow>
    )
}
export default MatchDetail

const useStyles = makeStyles({
    time: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '8px',
        lineHeight: '10px', 
        minWidth: '50px'
    },
    dialog: {
        padding: '15px'
    },

    notes: {
        fontSize: '8px',
        lineHeight: '10px',
        minWidth: '200px'
    },
    amount: {cursor:'pointer'
}, edit: {
    fontSize:'.6em',
    cursor:'pointer'
}
      
  });