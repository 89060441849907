import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'

const TournamentTimeZone = props => {
    const [zone, setZone] = useState('')

    const tid = useSelector(state => state.tid)
    const timeZone = useSelector(state => state.time_zone)

    useEffect(() => {
        setZone(timeZone)
    }, [timeZone])

    useEffect(() => {
        setZone(props.zone)
    }, [props.zone])

    const update = (e) => {
        let value = e.target.value
        if(value === 'Select...'){return}
        axios.put(`/api/t/setTimeZone`, { tid, value }).then(
            res => {
                setZone(value)
            }
        )
    }

    const mappedTimeZones = [
        'US/Alaska',
        'US/Aleutian',
        'US/Arizona',
        'Canada/Atlantic',
        'US/Central',
        'US/East-Indiana',
        'US/Eastern',
        'US/Hawaii',
        'US/Indiana-Starke',
        'US/Michigan',
        'US/Mountain',
        'US/Pacific',
        'US/Samoa',
        'UTC'
    ].map((z, i) => {
        return (
            <option key={i}>
                {z}
            </option>
        )
    })

    return (
        <div>
            <label htmlFor="">
                Time Zone:
                <select name="status"
                    value={zone}
                    className='dropdown'
                    onChange={update}
                >
                    <option key={'s'}>
                        Select...
            </option>
                    {mappedTimeZones}
                </select>
            </label>

        </div>


    )
}
export default TournamentTimeZone