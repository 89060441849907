import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import NotifyPlayers from '../components/ScoreReporting/NotifyPlayers'
import ReportScore from '../components/ScoreReporting/ReportScore'
import ViewScores from '../components/ScoreReporting/ViewScores'
import PlayerContact from '../components/ScoreReporting/PlayerContact'
import ActiveMatchList from '../components/ScoreReporting/ActiveMatchList'
import ImportPlayers from '../components/ScoreReporting/ImportPlayers'
import ScoreManager from '../components/ScoreReporting/ScoreManager'
import PastScores from '../components/ScoreReporting/PastScores'

const ScoreRoutes = () => {
    const user_id = useSelector(state => state.user_id)
    const admin_type = useSelector(state => state.admin_type)

    return (
        <Switch>
            {user_id && admin_type ? <Route path='/score/admin/pastscores' component={PastScores} /> : ''}
            {user_id && admin_type ? <Route path='/score/admin/importPlayers' component={ImportPlayers} /> : ''}
            {user_id && admin_type ? <Route path='/score/admin/setup' component={PlayerContact} /> : ''}
            {user_id && admin_type ? <Route path='/score/admin/getMatches' component={ActiveMatchList} /> : ''}
            {user_id && admin_type ? <Route path='/score/admin/notifyPlayers' component={NotifyPlayers} /> : ''}
            <Route path='/score/rpsr/:sid' component={ReportScore} />
            {user_id && admin_type ? <Route path='/score/admin/scores' component={ViewScores} /> : ''}
            {user_id && admin_type ? <Route path='/score/admin' component={ScoreManager} /> : ''}


        </Switch>
    )
}
export default ScoreRoutes 