import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Link } from 'react-router-dom';
import Listing from './Listing'

const OrganizationListingPage = props => {
    const [tournaments, setTournaments] = useState([])

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getTournaments() }, [])


    const getTournaments = () => {
        axios.get(`/api/t/getTournamentsByOrganization/${props.match.params.name}`).then(res => {
            setTournaments(res.data)
        })
    }
    const mappedTournaments = tournaments.map(t => {
        return (
            <div key={t.tid}
                className='col-12 col-md-6 col-lg-4'
                style={{ color: 'black' }}>
                <Link to={`/signup/${t.tid}`}
                >
                    <Listing
                        t={t} />
                </Link>
            </div>
        )
    })

    return (
        <div style={{ background: 'white', padding: '20px' }}>
            {props.match.params.name === 'ppa' ?
                <div>
                    <img className=''
                        style={{ width: '200px' }}
                        src={'https://www.propickleballassociation.com/wp-content/uploads/2019/07/ppa-dark-header-630.png'} alt={props.match.params.name} />
                    <h4>Volunteer Opportunities for the PPA- Pro Pickleball Association</h4>

                </div> : ''
            }
            {props.match.params.name === 'app' ?
                <div>
                    <img className=''
                        style={{ width: '200px' }}
                        src={'https://apptour.org/wp-content/uploads/APP-Tour-Logo-Final-302x150.png'} alt={props.match.params.name} />
                    <h4>Volunteer Opportunities for the APP Tour</h4>

                </div> : ''
            }
            <div className='row justify-content-center' >
                {mappedTournaments}
            </div>
        </div>
    )
}
export default OrganizationListingPage

