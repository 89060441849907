import React, { Component } from 'react'
import axios from 'axios'
import io from 'socket.io-client'
import { connect } from "react-redux"
import '../BoardView.css'
import ClipboardNeeded from '../ClipboardNeeded'
import BoardDisplayMagnet from '../BoardView/BoardDisplayMagnet'
import Dialog from '@material-ui/core/Dialog';
import { toast } from 'react-toastify'
import Moment from 'react-moment';
import 'moment-timezone';
import AuxAssignmentList from '../BoardView/AuxAssignmentList'
import RefreshIcon from '@material-ui/icons//Refresh';




class BoardFinishMatch extends Component {
    constructor() {
        super()
        this.state = {
            courts: [],
            refList: [],
            clipboardNeeded: [],
            sideQueue: [],
            court: {},
            show: false,
            tiebreaker: 1,
            auxAssignmentList: [],
        }
    }

    componentDidMount() {
        this.getCourts()
        this.getAvailableRefs()
        this.getClipboardNeeded()
        this.getSideQueue()
        this.setSocketListeners()
        this.getAuxAssignments()
    }

    componentWillUnmount() {
        this.socket.disconnect()
        clearInterval(this.interval)
    }

    setSocketListeners = () => {
        this.socket = io()

        this.socket.emit('joinRoom', `board:${this.props.tid}`)

        this.socket.on('board', results => {
            // console.log(51, results)
            if (results.msg === 'assign') {
                let c = [...this.state.courts]
                let index = c.map(e => e.court_num).indexOf(results.court.court_num)
                c[index] = results.court
                this.setState({courts: c})

                let q = [...this.state.refList]
                let qi = q.map(e => e.ref_id).indexOf(results.court.ref_id)
                if(qi >= 0){
                q.splice(qi, 1)
                this.setState({refList: q})
                }

                let s = [...this.state.sideQueue]
                let si = s.map(e => e.ref_id).indexOf(results.court.ref_id)
                if(si >= 0){
                s.splice(si, 1)
                this.setState({sideQueue: s})
                }
            }
            else if (results.msg === 'finish') {
                let c = [...this.state.courts]
                let index = c.map(e => e.court_num).indexOf(results.court.court_num)
                c[index] = results.court
                this.setState({courts: c})

                let q = [...this.state.refList]
                q = q.concat(results.referee)
                this.setState({refList: q})
            }
            else if (results.msg === 'match starter') {
                let q = [...this.state.refList]
                let qi = q.map(e => e.ref_id).indexOf(results.ref_id)
                if(qi >= 0){
                q[qi].note = results.note
                this.setState({refList: q})
                }else {
                    let aux = [...this.state.sideQueue]
                let auxI = aux.map(e => e.ref_id).indexOf(results.ref_id)
                if(auxI >= 0){
                aux[auxI].note = results.note
                this.setState({sideQueue: q})

                }

            }
        }
            else if (results.msg === 'swapQueue') {
                this.getAvailableRefs()
                this.getSideQueue()
            }
            else if (results.msg === 'getCourtsOnly') {
                this.getCourts()
            }
            else {
                this.getAll()
            }

        })
    }

    getAll = () => {
        this.getCourts()
        this.getAvailableRefs()
        this.getClipboardNeeded()
        this.getSideQueue()
        this.getAuxAssignments()
    }

    getCourts = () => {
        axios.get(`/api/getCourtsForPublicDisplay/${this.props.tid}`).then(
            res => {
                this.setState({ courts: res.data })
            }
        )
    }

    getAvailableRefs = () => {
        axios.get(`/api/getAvailableRefs/${this.props.tid}`).then(

            res => {
                this.setState({ refList: res.data })
            }
        )
    }

    getAuxAssignments = () => {
        axios.get(`/api/getAuxAssignments/${this.props.tid}`).then(

            res => {
                this.setState({ auxAssignmentList: res.data })
            }
        )
    }

    getClipboardNeeded =  () => {

        axios.get(`/api/getClipboardNeeded/${this.props.tid}`).then(

            res => {
                this.setState({ clipboardNeeded: res.data })
            }
        )
    }

    getSideQueue = () => {
        axios.get(`/api/getSideQueue/${this.props.tid}`).then(

            res => {
                this.setState({ sideQueue: res.data })
            }
        )
    }

    handleSelectCourt = (court) => {
        if(court.ref_id !== null){
            this.setState({court, show: true})
        }else {
            this.handleClose()
        }
    }
    handleClose = () => this.setState({court: {}, show: false, tiebreaker: 1})

    finishMatch = () => {
        axios.put(`/api/finishMatch/${this.props.tid}`, { court_num: this.state.court.court_num, id: this.state.court.id, ref_id: this.state.court.ref_id, medal: this.state.court.medal, sysid: this.state.court.sysid, amount: this.state.court.amount, paid: this.state.court.paid, aux_assignments:this.state.court.aux_assignments, secondAmount: this.state.court.second_amount
, secondPaid: this.state.court.second_paid }).then(
            res =>{
                toast.success('Thank you for refereeing')
            // this.socketFinish(this.state.court.court_num, this.state.court.ref_id)
if(this.state.court.second_ref_id !== null){
    this.socketFinishSecond()
}else {
            this.socketFinish({
                amount: null,
                cid: null,
                color: this.state.court.color,
                court_name: null,
                court_num: this.state.court.court_num,
                credentials: null,
                credentials_verified: null,
                first: null,
                gold: null,
                id: null,
                last: null,
                last_match: null,
                medal: null,
                paid: null,
                ref_id: null,
                second: null,
                second_amount: null,
                second_cred: null,
                second_lm: null,
                second_paid: null,
                second_ref_id: null,
                start_time: null,
                sysid: "",
            },
            {ref_id: this.state.court.ref_id,
                credentials: this.state.court.credentials,
                credentials_verified: this.state.court.credentials_verified,
                first: this.state.court.first,
                last: this.state.court.last,
                last_match: this.state.court.last_match,
                ref_note: this.state.court.ref_note,
                end_time: new Date()},
                )
            }
            this.setState({court: {}, show: false})
            }

        )
    }

    socketFinish = (court, referee) => {
        // console.log(178, this.props.tid)
        this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'finish', court, referee })
        this.getAvailableRefs()
        this.getAuxAssignments()
        this.getCourts()
        
    }
    socketFinishSecond = (court, referee) => {
        // console.log(178, this.props.tid)
        this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'second', court, referee })
        this.getAvailableRefs()
        this.getCourts()
    }

    handelTiebeaker = (num) => this.setState({tiebreaker: num})

    render() {
        const mappedCourts = this.state.courts.map((court,i) => {

            return (
                <div className='col-12 col-md-4 col-lg-3' key={'r' + court.court_num}>
                        <Magent court={court} onClick={()=>this.handleSelectCourt(court)} style={{marginBottom: '10px', cursor:'pointer'}}/>
                        </div>
                    
            )
        })

        const mappedRefs = this.state.refList.map((ref, i) => {

            return (
                <BoardDisplayMagnet
                    key={'r'+ref.ref_id}
                    referee={ref}
                    zone={this.props.zone} />
            )
        })

        const mappedSideQueue = this.state.sideQueue.map((ref, i) => {

            return (
                <BoardDisplayMagnet
                    key={'q'+ref.ref_id}
                    referee={ref}
                    zone={this.props.zone} />
            )
        })
        const mappedAuxList = this.state.auxAssignmentList.map((ref, i) => {

            return (
                <div>
                    <AuxAssignmentList 
                        key={'aux' + i}
                        referee={ref}
                        zone={this.props.zone}
                        get={this.getQueues}/>

                </div>
            )
        })
        return (
            <div className='container-fluid'>
                <h3 className='tips'>{this.props.name}</h3>
                <div className='col-12' style={{ lineHeight: '.5' }}>
                    <p>Queue order is an estimate for match assignments.</p>
                    <p>Actual assignment is at the discretion of the referee coordinator or based on match type.</p>

                </div>

                <div><button 
                    style={{fontSize: '2em'}}
                    onClick={this.getCourts}>
                        <RefreshIcon />
                        Refresh Board</button></div>

                <div className='row'>

                        <div className='col-12 col-md-8 court-list'>
                            {mappedCourts}
                        </div>
                        <div className='col-12 col-md-2 '>
                            <h5 className='queue-name'>Queue ({this.state.refList.length})</h5>
                            <div className=''>
                                {mappedRefs}

                            </div>
                        </div>
                        <div className='col-12 col-md-2'>
                            <h5 className='queue-name'>Aux Queue ({this.state.sideQueue.length})</h5>
                            <div className=''>
                                {mappedSideQueue}
                            </div>
                            {mappedAuxList}
                        </div>




                </div>
                {this.state.clipboardNeeded.length > 0 ?
                    <div className='row'>
                        <div className='col-6'>
                            {this.state.clipboardNeeded.length > 0 ?
                                <ClipboardNeeded
                                    clipboardNeeded={this.state.clipboardNeeded}
                                    clipboardReceived={this.clipboardReceived} />
                                : ''}
                        </div>
                    </div> : ''}
                    <div>
                                Guide 
                                <div><span className='last_match_icon'>LM</span> = Last Match For Referee</div>
                                <div>Wait = Wait to Assign (Quick Break)</div>
                                
                            </div>
                            <Dialog open={this.state.show} onClose={this.handleClose}>
                                <div style={{margin: '15px'}}>  
                                <div style={{fontSize:'20px'}}>
                                       
                    {this.state.court.start_time ?
                    <>
                     Match Started at: {` `}
                        <Moment format="h:mm"
                            tz={this.props.timeZone}>
                            {this.state.court.start_time}
                        </Moment>
                        {/* <br/>
                        Total Time: {` `}

                        <Moment toNow >{this.state.court.start_time}</Moment> */}
                        </>
                        
                        
                        : ''}


                        {this.state.court.medal === 'Bronze' && <h3>Bronze</h3>}
                        {this.state.court.medal === 'Gold' && <h3>Gold</h3>}
                        </div>
                                    <Magent court={this.state.court} />
                                    
                                  {this.state.court.medal === 'Gold' ?
                                    <>
                                    {this.state.tiebreaker === 1 && 
                                    <>
                                        <h3>Was there a tie-breaker match?</h3>
                                        <button style={{fontSize:'40px', margin: '30px', padding: '10px'}}
                                            onClick={()=>this.handelTiebeaker(3)}>Yes</button>
                                        <button style={{fontSize:'40px', margin: '30px', background: 'red', padding: '10px'}}
                                            onClick={()=>this.handelTiebeaker(2)}>No</button>
                                    </>}
                                    {this.state.tiebreaker === 3 && 
                                    <span style={{fontSize: '30px'}}>
                                    Since there was a tie-breaker the RC will need to finish this match.
                                    </span>
                                    }
                                    {this.state.tiebreaker === 2 && 
                                    <>
                                    <>To finish your match click the green finish match button</> 
                                    <button style={{fontSize: '3em'}} onClick={this.finishMatch}>
                                        Finish Match
                                    </button>
                                     </>}
                                      </>
                                  

                                   :
                                  <>
                                        <>To finish your match click the green finish match button</> 
                                        <><small>If there are two referees assigned, the finish button will put both back into the queue.</small></>
                                    <button style={{fontSize: '3em'}} onClick={this.finishMatch}>
                                        Finish Match
                                    </button>
                                    </>}
<br/>
                                    <button className='register-button' style={{margin: '40px'}} onClick={this.handleClose}>cancel</button>
                                    

                                </div>
                            </Dialog>

            </div>
        )
    }
}
const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name,
        timeZone: reduxState.time_zone
    }
}


export default connect(mapStateToProps)(BoardFinishMatch)

export function Magent(props){
    let {court} = props
    return(
        <div className='court-box-boardView'
            style={{ cursor: 'pointer' }}
            onClick={props.onClick} >
            <div className='col-4 court-num' style={{background: court.color || '#333333'}}>
                <div className='court-name'>
                    <div className={court.curt_num ? court.court_num.length > 2 ? 'shrink-court-num' : '':''}>{court.court_num}</div>
                </div>
                {court.medal === 'Bronze' ?
                    <div className='board-medal'>
                        <i className="fas fa-medal"></i>
                    B
                    </div> :
                    court.medal === 'Gold' ?
                        <div className='board-medal'>
                            <i className="fas fa-medal"></i>
                        G
                    </div> : ''}
                <div className='board-start-time'>
                    {court.ref_id ?

                        <Moment format="h:mm"
                            local>
                            {court.start_time}
                        </Moment>
                        : ''}
                </div>

            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: 'fit'}} >
                <div className={court.second? 'dual':''}>
                    <div 
                        className={court.credentials_verified === 'Certified' ? 'certified-board' : court.credentials_verified === 'Level 2' ? 'level2-board' : court.credentials_verified === 'Level 1' ? 'level1-board' :
                            court.ref_id ? ' volunteer-board' :
                                ''}>{court.first} {court.last}</div>
                </div>
                        {court.second  &&
                <div className='dual'>
                    <div className={court.second_cred === 'Certified' ? 'certified-board' : court.second_cred === 'Level 2' ? 'level2-board' : court.second_cred === 'Level 1' ? 'level1-board' :
                            court.ref_id ? ' volunteer-board' :
                                ''}> {court.second}</div>
                </div>
}
            </div>
        </div>
    
    )
}