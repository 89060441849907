import React from 'react'
import ViewScores from './ViewScores'
import NotifyPlayers from './NotifyPlayers'

const ScoreManager = () => {

    return (
        <div className='container-fluid'>
            <div className='row' style={{ marginBottom: '20px' }}>
                <ViewScores />
            </div>

            <div className='row' style={{ background: 'white' }}>
                <div className='col-12'>
                    <h3>Player Notification</h3>
                    <NotifyPlayers />
                </div>

            </div>
        </div>
    )
}
export default ScoreManager