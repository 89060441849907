import React from 'react'


export default function (props) {
    let { c, court } = props

    return (

        < div className='col-12 court-box-boardView'
            style={{ cursor: 'pointer' }
            }
            onClick={() => props.setCourt(c.court_num)} >
            <div className='col-12 court-num-score'>
                <div
                    className={c.court_num === court ? 'selected-court-monitor' : 'court-name'}
                >
                    <div className={c.court_num.length > 2 ? 'shrink-court-num' : ''}>{c.court_num}</div>
                </div>
            </div>

        </div >
    )
}