import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import PlayerContactRow from './PlayerContactRow'


const PlayerContact = props => {
    const [players, setPlayers] = useState([])
    const [nameSearch, setNameSearch] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getPlayers() }, [])

    const getPlayers = () => {
        axios.get(`/api/score/getPlayersPhone/${tid}`).then(
            res => {
                setPlayers(res.data)
            }
        )
    }

    const addPlayer = () => {
        axios.post(`/api/score/addPlayer`, { tid, name, phone }).then(
            res => {
                getPlayers()
                setName('')
                setPhone('')
            }
        )
    }

    const handlePhone = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setPhone(onlyNums)
        } else if (onlyNums.length === 10) {
            setPhone(onlyNums)
        }
    }


    const mappedPlayers = players
        .filter(obj => obj.name.toUpperCase().includes(nameSearch.toUpperCase()))
        .map(p => {

            return (
                <PlayerContactRow
                    key={p.pid}
                    player={p} />
            )
        })


    return (
        <Container maxWidth="sm">
            <h2>Player Contact Info</h2>
            <p>To change/ add a phone just type the number in the phone box.</p>
            <div>
                <input type="text"
                    value={nameSearch}
                    placeholder='Name ...'
                    onChange={e => setNameSearch(e.target.value)} />
                <button onClick={() => setNameSearch('')}>Clear</button>
            </div>
            <div className='col-12' >

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="center">Phone</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {mappedPlayers}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div>
                <input type="text"
                    value={name}
                    placeholder='name'
                    onChange={e => setName(e.target.value)} />
                <input type="text"
                    value={phone}
                    placeholder='phone'
                    onChange={handlePhone} />
                <button onClick={addPlayer}>Add Player</button>
            </div>

        </Container >
    )
}
export default PlayerContact