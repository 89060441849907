import React, {useState, useEffect} from 'react'
import MappedSpots from './MappedSpots'
import MappedWaitlist from './MappedWaitlist'

export default function MappedShifts(props){
    const [show, setShow] = useState(true)
    
    let {shift, i, removeRef, moveToWaitlist, moveOffWaitlist, searchFirst, searchLast, selectedShift, selectShift} = props

    useEffect(
        () => { handleProps() }, [props.showAll])

    const handleProps = () => setShow(props.showAll)

    const handleShow = () =>setShow(!show)

    const mappedSpot = shift.signup
                    .filter(obj => obj.first.toUpperCase().includes(searchFirst.toUpperCase()))
                    .filter(obj => obj.last.toUpperCase().includes(searchLast.toUpperCase()))
                    .map((spot, i) => {

                        return (
                            <MappedSpots 
                            key={props.i+'d'+i}
                            i={i} spot={spot} removeRef={removeRef} moveToWaitlist={moveToWaitlist}/>
                        )
                    })
                const mappedWaitlist = shift.waitlist
                    .filter(obj => obj.first.toUpperCase().includes(searchFirst.toUpperCase()))
                    .filter(obj => obj.last.toUpperCase().includes(searchLast.toUpperCase()))

                    .map((waitList, i) => {

                        return (
                            <MappedWaitlist key={props.i+'w'+i} i={i} waitList={waitList} moveOffWaitlist={moveOffWaitlist} removeRef={removeRef}/>
                        )
                    })

    return(
        <div  style={{ background: 'white', padding: '10px' }} className={selectedShift.sid === shift.sid ? '  selected-slot' : ''}>
                        <div className='col-12'>
                            <div className='shift-title'
                                style={{ background: shift.color || '#009CAE' }}>
                                    {show ?
                <i className="fas fa-chevron-up" onClick={handleShow}></i>:
                <i className="fas fa-chevron-down" onClick={handleShow}></i>    
                }
                                <h4>{shift.category}: {shift.name} {shift.time}</h4>
                                <div style={{display: 'flex', flexDirection:'column'}}>
                               { shift.pre_reg ?
                               <>Pre-Reg: {shift.count}</> :
                               <>
                                <div>Shift: {shift.count}/{shift.spots}</div>
                                <div>       Wait: {shift.wait_count}/{shift.wait_spots}</div>
                                </>}
    </div>

                            </div>
                            {show &&
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            {shift.pre_reg ?
                                                <h5>Pre-Reg: {shift.count}</h5> :
                                                <h5>Spots: {shift.count}/{shift.spots}</h5>}
                                        </div>

                                        <div className='col-12 col-lg-6'>
                                            Certified: {shift.certified_filled}/ {shift.certified}
                                        </div>
                                        <div className='col-12 col-lg-6'>
                                            Level Two: {shift.level_two_filled}/  {shift.level_two}
                                        </div>
                                        <div className='col-12 col-lg-6'>
                                            Level One: {shift.level_one_filled}/{shift.level_one}
                                        </div>
                                        <div className='col-12 col-lg-6'>
                                            Not Specified: {shift.none_filled}/{shift.none}
                                        </div>


                                    </div>
                                    <div >
                                        <div className='registered-spots'>
                                            {mappedSpot}

                                            {((shift.spots - shift.count) > 0) || shift.pre_reg ?
                                                <button onClick={() => selectShift(shift, false)}>add</button> : ''}
                                        </div>
                                    </div>
                                </div>
                                {shift.wait_spots ?
                                    <div className='col-12 col-md-6'>

                                        <div className='row'>
                                            <div className='col-12'>
                                                <h5>Waitlist: {shift.wait_count}/ {shift.wait_spots}</h5>
                                            </div>
                                            <div className='col-12 col-lg-6'>
                                                Certified: {shift.wait_certified_filled}/{shift.wait_certified}
                                            </div>
                                            <div className='col-12 col-lg-6'>
                                                Level Two: {shift.wait_level_two_filled}/{shift.wait_level_two}
                                            </div>
                                            <div className='col-12 col-lg-6'>
                                                Level One: {shift.wait_level_one_filled}/{shift.wait_level_one}
                                            </div>
                                            <div className='col-12 col-lg-6'>
                                                Not Specified: {shift.wait_none_filled}/{shift.wait_none}
                                            </div>


                                        </div>
                                        <div style={{ background: 'white' }}>
                                            <div className='registered-spots'>
                                                {mappedWaitlist}

                                                {(shift.wait_spots - shift.wait_count) > 0 ?
                                                    <button onClick={() => selectShift(shift, true)}>add</button> : ''}
                                            </div>
                                        </div>
                                    </div> : ''}

                            </div>}
                        </div>
                    </div>
    )
}