import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import ColorPicker from '../../utlities/ColorPicker'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';


const AddVenue = props => {
    const [name, setName] = useState('')
    const [color, setColor] = useState('')
    const [show, setShow] = useState(true)
    const [showDialog, setShowDialog] = useState(false)
    const [verifyDelete, setVerifyDelete] = useState(false)

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { setProps() }, [props.vid, showDialog])

    const save = () => {
        if(props.vid){
            saveEdit()
        }else {
            saveNew()
        }
    }

    const saveNew = () => {
        if(!tid)return
        axios.post(`/api/eventGuide/addVenue`, { tid, name, color, show }).then(res => {
            clearState()
            setShowDialog(false)
            if(props.get){props.get()}
        })
    }
    const saveEdit = () => {
        if(!tid)return
        axios.put(`/api/eventGuide/editVenue`, { vid: props.vid, name, color, show }).then(res => {
            clearState()
            setShowDialog(false)
            if(props.get){props.get()}
        })
    }
    const handleDelete = () => {
        if(!tid)return
        axios.post(`/api/eventGuide/deleteVenue`, { vid: props.vid}).then(res => {
            clearState()
            setShowDialog(false)
            if(props){props.get()}
        })
    }
    const setProps = () => {
        if(props.vid && showDialog){
            let {name, color, show} = props.venue
            setName(name)
            setColor(color)
            setShow(show)
        }
    }

    const clearState = () => {
        setName('')
        setColor('')
        setShow(true)
    }
    const handleClose = () => setShowDialog(!showDialog)

    return(
        <div>
            <button onClick={handleClose} style={{fontSize: '1.5em'}}className='button-none'>{props.vid ? 'Edit Venue':'+ Add Venue'}</button>
            <Dialog onClose={handleClose}  open={showDialog}>
                <div style={{margin: '20px'}}>
            <div>
                Venue Name 
                <input type="text"
                    value={name}
                    onChange={e=>setName(e.target.value)} />

            </div>
            <div>
                Venue Color
                <ColorPicker color={color} setColor={setColor}/>
            </div>
            <div>
            <input type="checkbox"
                    checked={show}
                    value={show}
                    onChange={e=>setShow(!show)} />
                    Venue Viewable to the Public (If unchecked it will not show)
            </div>
            <div>
                <button onClick={save}>Save</button>
            </div>
            <div style={{textAlign: 'right'}}>
            {props.vid && <button 
            className='button-none'
            onClick={()=>setVerifyDelete(!verifyDelete)}>{verifyDelete ? 'Cancel Delete': 'Delete'}</button>}
            {verifyDelete && <button 
            className='button-none'
            onClick={handleDelete}>Yes Delete Venue and All Buttons</button>}
            </div>
            </div>
            </Dialog>
        </div>
    )


}
export default AddVenue