import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import ReceivedW9 from './ReceivedW9'
import HandlePayment from './HandlePayment'
import Moment from 'react-moment';
import 'moment-timezone';
import EditVolunteer from '../../Volunteers/EditVolunteers/EditVolunteer';



const PayoutRow = props => {
    const [show, setShow] = useState(false)
    const [handleW9, setHandleW9] = useState(false)
    const [w9, setW9] = useState(false)
    const [showPayment, setShowPayment] = useState(false)
    const [edit, setEdit] = useState(false)

    let {row} = props

  const classes = useStyles()

  useEffect(
    () => { handleShowAll() }, [props.showAll])

    useEffect(
      () => { setProps() }, [props.row])

  const setProps = () => {
    setW9(row.w9_received)
  }    

const handleShowAll = () => setShow(props.showAll)

  const handleShow = () => setShow(!show)
  const handleEdit = () => {
    if(edit){props.get()}
    setEdit(!edit)
  }

    return(
        <>
            <TableRow className={classes.player}>
                <TableCell onClick={handleShow}>
            {show ?
            <i className="fas fa-chevron-up"></i>:
            <i className="fas fa-chevron-down"></i>
            }
            </TableCell>
            <TableCell>
            {row.name}
            </TableCell>
            <TableCell>
                {row.total}
            </TableCell>
            <TableCell>
              {row.w9_required ? 'yes': 'no'}
            </TableCell>
            <TableCell 
              className={classes.pointer}
              onClick={()=>setHandleW9(true)}>
            {w9 ? 'yes': 'no'}
            </TableCell>
            <TableCell
            className={classes.pointer}
            onClick={()=>setShowPayment(true)}>
            {row.paid ? 'paid':
            <button>pay</button>}
            </TableCell>
            <TableCell align="left">{row.cell_phone}</TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left">
                                <div className={classes.address}>
                                <div>
                                    {row.address}
                                </div>
                                <div>
                                    {row.city} {row.state} {row.zip}
                                </div>
                            
                            </div></TableCell>
                            <TableCell align="left">{row.paypal}</TableCell>
                            <TableCell align="left">{row.venmo}</TableCell>
                            <TableCell align="left">{row.zelle}</TableCell>
                            <TableCell component="th" scope="row">
              <button onClick={handleEdit}>Edit</button>
              </TableCell>
                           
                </TableRow>
                <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={show} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Event</TableCell>
                    <TableCell>Place</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Paid</TableCell>
                    <TableCell align="right">Paid Date</TableCell>
                    <TableCell align="right">Paid By</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.items.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.event}
                      </TableCell>
                      <TableCell>{historyRow.place}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">
                        {historyRow.payment_type}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.payment_date ?
                        <Moment format="MM/DD/YYYY" >
                        {historyRow.payment_date}
                    </Moment>:''}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.paid_by}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
        <EditVolunteer edit={edit} ref_id={row.ref_id} close={handleEdit}/>
        <Dialog  open={handleW9} onClose={()=>setHandleW9(false)}>
      <DialogTitle >Did you receive the W9?</DialogTitle>
      <div className={classes.dialog}>
                  <ReceivedW9 
                  id={props.row.id}
                  received={w9}
                  setW9={setW9}
                  close={()=>setHandleW9(false)}/>
      </div>
      </Dialog>

      <Dialog  open={showPayment} onClose={()=>setShowPayment(false)}>
      <DialogTitle >Payment</DialogTitle>
      <div className={classes.dialog}>
                  <HandlePayment 
                  row={props.row}
                  close={()=>setShowPayment(false)}
                  get={props.get}/>
      </div>
      </Dialog>
      </TableRow>
      </>
    )
}
export default PayoutRow

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    player:{
        background: '#cfeefa',
        borderTop: '2px solid #333333'
    },
    name: {
        minWidth: '200px'
    },
    phone: {
        minWidth: '150px'
    },
    address: {
        fontSize: '10px',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '200px'
    },
    email: {
        fontSize: '10px'
    },
    shirt: {
        fontSize: '10px',
        minWidth: '200px'
    },
    pointer: {
      cursor: 'pointer'
    },
    dialog: {
      padding: '20px'
    }
});