import React, {useState} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'


const MappedCaptains = (props)  => {
    const [verifyDelete, setVerifyDelete] = useState(false)

    let {c, i} = props

    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)

    const removeVolunteer = (shift_id) => {
        axios.put(`/api/removeRefTime/${shift_id}`, { tid }).then(
            res => {
                props.get()
            }
        )
    }

    const handleVerifyDelete = () => setVerifyDelete(verifyDelete => !verifyDelete)


    return(
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
                {c.volunteer_name}
            </div>

            {verifyDelete ? 
    <>
    <button onClick={() => removeVolunteer(c.shift_id)}><small>Verify Remove</small></button>
    <button onClick={handleVerifyDelete}><small>cancel</small></button>
    </>:
    <i className="fas fa-user-minus pointer" onClick={handleVerifyDelete}></i>}
        </div>
    )
}
export default MappedCaptains