import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import BingoItemDetail from './BingoItemDetail';
import '../Bingo.css'

const BingoItemList = () => {
    const [list, setList] = useState([])
    const [name, setName] = useState('')
    const [required, setRequired] = useState(false)
    const [code, setCode] = useState('')

    const tid = useSelector(state => state.tid)
    const classes = useStyles();

    useEffect(
        () => { getList() }, [])

    const getList = () => {
        axios.get(`/api/bingo/getAdminList/${tid}`).then(res => {
            setList(res.data)
        })
    }

    const saveNew = () => {
        axios.post(`/api/bingo/addItem/`, {tid, name, code, required}).then(res => {
            getList()
            setName('')
            setCode('')
            setRequired(false)
        })
    }

      
      const suggested = [
        'Thank A Volunteer',
        'Win A Match',
        '10 Jumping Jacks',
        'Complement A Stranger',
        'Leave a Stranger a Nice Note',
        'Check-in on Facebook',
        'Make Social Post',
        'Give a High Five',
        'Introduce Yourself to a Stranger',
        'Drink 16oz Water'
      ];

    return(
        <div className='container-fluid'>
            <h2>Bingo Setup</h2>
            <div>
                <input type="text"
                    placeholder='Name'
                    value={name}
                    onChange={e=>setName(e.target.value)} />
                <input type="text"
                    placeholder='code'
                    value={code}
                    onChange={e=>setCode(e.target.value)} />
                <input type="checkbox"
                    checked={required}
                    value={required}
                    onChange={e=>setRequired(!required)} />
                    Required On All Cards
            <button onClick={saveNew}>Save</button>
            </div>
            <div>
            <h3>Suggested Items</h3>
    <p>Click on Item then you can edit and save.</p>
    <div className={classes.suggestionList}>

    
    {suggested.map((name, i)=>(
        <div key={'s'+i} onClick={()=>setName(name)} className='bingo-square'>
            {name}
        </div>
    )
    )}
    </div>
            </div>
            <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="Bingo List">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="left">code</TableCell>
            <TableCell align="left">required</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row, i) => (
            <BingoItemDetail key={'l'+i} row={row} getList={getList} order={++i}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>




        </div>
    )

}
export default BingoItemList

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    suggestionList:{
        display: 'flex',
        flexWrap: 'wrap'
    }
  });