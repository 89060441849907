import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

const AddItemDetail = props => {
    const [title, setTitle] = useState('')
    const [note, setNote] = useState('')
    const [verifyDelete, setVerifyDelete] = useState(false)

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { setPropsToState() }, [props.editCategory])

    const setPropsToState = () => {
        if (props.editDetail.pdid) {
            setTitle(props.editDetail.title)
            setNote(props.editDetail.note)
        }
    }

    const addDetail = () => {
        axios.post(`/api/addDetail`, { title, note, pid: props.pid }).then(res => {
            props.close(false)
            props.getToDoList()
            props.setPid(null)
            setTitle('')
            setNote('')
        })
    }
    const editDetail = () => {

        axios.put(`/api/editDetail`, { pdid: props.editDetail.pdid, title, note, }).then(res => {
            props.close(false)
            props.getToDoList()
            setTitle('')
            setNote('')
        })
    }
    const deleteDetail = () => {
        axios.post(`/api/deleteDetail/${props.editDetail.pdid}`).then(res => {
            props.close(false)
            props.getToDoList()
            setTitle('')
            setNote('')
        })
    }
    const saveChanges = () => {
        if (props.editDetail.pdid) {
            editDetail()
        } else {
            addDetail()
        }
    }

    return (
        <div>
            <legend>
                Item Detail Title
            <input type="text"
                    style={{ width: '400px' }}
                    value={title}
                    onChange={e => setTitle(e.target.value)} />
            </legend>
            <legend>
                Item Detail Note
            <input type="text"
                    style={{ width: '400px' }}
                    value={note}
                    onChange={e => setNote(e.target.value)} />
            </legend>

            <div className='col-12'
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                <button onClick={saveChanges}>Save</button>
                {props.editDetail.pdid ?
                    verifyDelete ?
                        <button
                            style={{ background: 'red' }}
                            onClick={deleteDetail}>Verify Delete Item Detail</button> :
                        <i className="far fa-trash-alt" style={{ textAlign: 'right' }}
                            onClick={() => setVerifyDelete(true)}></i> : ''}
            </div>
        </div>
    )
}
export default AddItemDetail