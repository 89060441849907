import React, {useState} from 'react'

const EmailBodyDetail = props => {
    const [show, setShow] = useState()

    return(
        <div>
            {show ?
            props.body:
            props.body.substr(0, 100)}
            <button onClick={()=>setShow(show => !show)}>{show? 'hide':'show'}</button>

        </div>
    )
}
export default EmailBodyDetail