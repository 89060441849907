import React, { Component } from 'react';
import axios from 'axios'
import { connect } from "react-redux"
import Modal from 'react-responsive-modal';

import './DisplayNotification.css'




class DisplayNotifications extends Component {
    constructor() {
        super()
        this.state = {
            viewNotification: {},
            show: false,
            response: ''
        }
    }


    completeNotification = () => {
        axios.put(`/api/confirmNotification`, { nid: this.state.viewNotification.nid, response: this.state.response }).then(res => {
            this.setState({ viewNotification: {}, show: false, response:'' })
                        this.props.completeNotification()
            
        })
    }

    handleSetViewNotification = note => this.setState({ viewNotification: note, show: true })


    render() {
        const mappedNotifications = this.props.notifications.map(note => {
            return (
                <div 
                key={'nn' + note.nid}
                className={note.type === 'medical' ? 'notification medical'
                    : note.type === '2nd' ? 'notification second'
                        : note.type === 'td' ? 'tournament-director notification'
                            : 'notification'} 
                            
                    onClick={() => this.handleSetViewNotification(note)}>
                    <div className='col-4 notification-court'>
                        <div className='court-name'>
                            <div className='court-title'>
                            {note.time}
                            </div>
                            <div>{note.court_num}</div>
                        </div>

                    </div>
                    <div className='col-8'>
                        {note.type === '2nd' && 'Second Call Needed'}
                        {note.type === 'desk' && 'Message to Desk' }
                        {note.type === 'medical' && 'Medical Attention Needed'}
                        {note.type === 'td' && 'Tournament Director Needed'}
                        {note.type === 'head ref' && 'Head Referee Needed'}
                    </div>
                </div >

            )
        })

        return (
            <div >
                {mappedNotifications}

                <Modal open={this.state.show} center showCloseIcon={true} onClose={() => this.setState({ show: false, viewNotification: {} })} closeOnEsc={true} >
                    <div className='notification-modal'>
                    <div>
                                {this.state.viewNotification.time}
                            </div>
                        <div className='notification-court'>

                            <div className='court-name'>
                                <div>Court: {this.state.viewNotification.court_num}</div>
                            </div>
                            

                        </div>
                        <div>
                            <h4>
                                {this.state.viewNotification.type === '2nd' && 'Second Call Needed'}
                                {this.state.viewNotification.type === 'desk' && 'Message to Desk' }
                                {this.state.viewNotification.type === 'medical' && 'Medical Attention Needed'}
                                {this.state.viewNotification.type === 'td' && 'Tournament Director Needed'}
                                {this.state.viewNotification.type === 'head ref' && 'Head Referee Needed'}

                            </h4>
                            <h3 style={{padding: '10px', border: '1px solid black'}}> {this.state.viewNotification.type === '2nd' ? 'Players:' : ''}
                                {this.state.viewNotification.message}</h3>
                            <p>{this.state.viewNotification.department}: {this.state.viewNotification.first} {this.state.viewNotification.last}</p>

                        </div>
                        <div>
                            <h4>Want to include a message?</h4>
                            <textarea type="text" 
                                maxLength='250'
                                value={this.state.response} 
                                    onChange={e=>this.setState({response: e.target.value})}  />
                        </div>
                        <div>
                            <button onClick={this.completeNotification}>Confirm Receipt of Notification</button>
                        </div>
                        <p>By clicking 'Confirm Receipt of Notification' referee will be send a text confirmation that request was received and is being handled.  </p>
                        <button className='register-button'
                        onClick={() => this.setState({ show: false })}>
                          Close Window - this will not clear notification from list
                        </button>

                    </div >
                </Modal>
            </div>

        )
    }
}

const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name
    }
}
export default connect(
    mapStateToProps,
)(DisplayNotifications)
