import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

const AddPlayer = props => {
    const [first, setFirst] = useState('')
    const [last, setLast] = useState('')
    const [email, setEmail] = useState('')

    const tid = useSelector(state => state.tid)


    const addPlayer = () => {
        axios.post(`/api/payment/addPlayer`, { tid, first, last, email }).then(res => {
            clearState()
            props.handleSelect(res.data)
            
        })

    }

    const clearState = () => {
        setFirst('')
        setLast('')
        setEmail('')
    }


    return (
        <div>
            <h5>Want to add a player?</h5>
            <input type="text"
                placeholder='First Name'
                value={first}
                onChange={e=>setFirst(e.target.value)}/>
            <input type="text"
                placeholder='Last Name'
                value={last}
                onChange={e=>setLast(e.target.value)}/>
            <input type="text"
                placeholder='email'
                value={email}
                onChange={e=>setEmail(e.target.value)}/>
            <button onClick={addPlayer}>Add</button>
        </div>
    )
}
export default AddPlayer