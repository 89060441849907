import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Moment from 'react-moment';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import { toast } from 'react-toastify';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const AddTournament = props => {
    const [name, setName] = useState('')
    const [location, setLocation] = useState('')
    const [tier, setTier] = useState('')
    const [sanctioned, setSanctioned] = useState(false)
    const [start, setStart] = useState(null)
    const [end, setEnd] = useState(null)
    const [visible, setVisible] = useState(false)



    const addTournament = () => {
        axios.post(`/api/ranking/addTournament`, {
            name, location, tier, sanctioned, start, end, visible
        }).then(res => {
            clearState()
        })
    }
    const handleStart = (date) => {
        setStart(date)
    }
    const handleEnd = (date) => {
        setEnd(date)
    }

    const clearState = () => {
        setName('')
        setLocation('')
        setTier('')
        setSanctioned(false)
        setStart(null)
        setEnd(null)
        setVisible(false)

    }

    return (
        <div>
            <h3>Add Tournament</h3>
            <>
                <label>
                    Name
                <input type="text"
                        value={name}
                        onChange={e => setName(e.target.value)} />
                </label>
                <label>
                    Location
                <input type="text"
                        value={location}
                        onChange={e => setLocation(e.target.value)} />
                </label>
                <legend>
                    Sanctioned
                    <label>
                        <input type='radio'
                            checked={sanctioned}
                            name='sanctioned'
                            value='sanctioned'
                            onChange={() => setSanctioned(true)}
                        /> yes </label>
                    <label>
                        <input type='radio'
                            checked={!sanctioned}
                            name='sanctioned'
                            value='sanctioned'
                            onChange={() => setSanctioned(false)}
                        /> no </label>
                </legend>
                <label>
                    Tier
                    <select
                        value={tier}
                        className='dropdown'
                        onChange={e => setTier(e.target.value)}
                    >
                        <option key={'a6'}>
                            Select...
                         </option>
                        <option key={'a1'}>
                            1
                         </option>
                        <option key={'a2'}>
                            2
                         </option>
                        <option key={'a3'}>
                            3
                         </option>
                    </select>

                </label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        label="start"
                        format="MM/dd/yyyy"
                        value={start}
                        onChange={handleStart}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        label="end"
                        format="MM/dd/yyyy"
                        value={end}
                        onChange={handleEnd}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </>

            <button onClick={addTournament}>Save</button>

        </div>
    )
}
export default AddTournament