import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'


const AddVolunteerCategoryAdmin = props => {
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [add, setAdd] = useState(false)

    const { vcid } = props


    const searchForName = async (name) => {
        await setSearch(name)
        if (search.length > 2) {
            getName()
        }
        else {
            setSearchResults([])
        }
    }

    const getName = () => {
        axios.post(`/api/getAdminCaptainName?search=${search}`).then(res => {
            setSearchResults(res.data)
        })
    }

    const addAdmin = (ref_id) => {
        axios.post(`/api/volunteer/addCategoryAdmin`, { vcid, ref_id }).then(res => {
            setSearchResults([])
            setSearch('')
            setAdd(false)
            props.get()
        })

    }


    const mappedSearchResults = searchResults.map(name => {
        return (
            <div key={name.ref_id} className='list' onClick={() => addAdmin(name.ref_id)}>
                <div>
                    <button>Add</button>
                </div>
                <div>
                    {name.login && <i className="fas fa-user-check"></i>}
                    {name.first} {name.last}
                </div>

            </div>
        )
    })


    return (
        <div>
            {add ?
                <div>
                    <div>Search for person to add as category captain.</div>
                    <input
                        type="text"
                        placeholder='Start typing name...'
                        value={search}
                        onChange={e => searchForName(e.target.value)} />

                    {mappedSearchResults}
                </div> :
                <button
                    className='button-none'
                    onClick={() => setAdd(add => !add)}>+ Add</button>}
        </div>
    )
}
export default AddVolunteerCategoryAdmin