import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { useSelector } from 'react-redux'
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import MatchCountMatchStarters from './MatchCountMatchStarters';

const PastMatchesMatchStarters = props => {
    const [list, setList] = useState([])
    const [verifyDelete, setVerifyDelete] = useState(false)
    const [deleteMatch, setDeleteMatch] = useState({})

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getPastMatches() }, [])

    const getPastMatches = () => {
        axios.get(`/api/matchstarter/getPastMatchesMatchStarters/${tid}`).then(

            res => {
                setList(res.data)
            }
        )
    }
    const yesDelete = () => {
        axios.delete(`/api/matchstarter/deletePastMatch/${deleteMatch.id}`).then(

            res => {
                getPastMatches()
                setDeleteMatch({})
                setVerifyDelete(false)
            }
        )
    }
    const handleDelete = (match) => {
        if(verifyDelete){
            setDeleteMatch({})
            setVerifyDelete(false)
        }else {
            setDeleteMatch(match)
            setVerifyDelete(true)
        }
    }

        const columns = [

            {
                Header: 'Delete Match',
                accessor: 'id',
                Cell: ({ value, row }) =>
                    <button onClick={() => handleDelete(row._original)}>Delete</button>
            },{
                Header: 'Name',
                accessor: 'name',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true
            }
            ,{
                Header: 'Court',
                accessor: 'court_num',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["court_num"] }),
                filterAll: true
            },
            {
                Header: 'Start',
                accessor: 'start',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["start_time"] }),
                filterAll: true
            },
            
            {
                Header: 'End',
                accessor: 'end',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["end_time"] }),
                filterAll: true,
            },
            {
                Header: 'Minutes',
                accessor: 'minutes',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["minutes"] }),
                filterAll: true,
            }
        ]


        return (
            <div className="container">
                
                <MatchCountMatchStarters />
                <div className='RefCourtAssignments'>
                
                    <h2>Past Matches for Match Starters</h2>
                    <h4>Match Count {list.length}</h4>
                    <p>
                        Want to filter the data?  Use the white boxes under the column titles to filter.  Click the column title to sort.  
                    </p>
                    <h5>To view match starter and aux assignments click on Matches By Tournament and select the tournament.</h5>
                    <div  >
                        <ReactTable
                            data={list}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            className='ReactTable'

                            columns={columns}
                        />
                    </div>
                    <Dialog  aria-labelledby="simple-dialog-title" open={verifyDelete}>
                        <DialogTitle >Are you sure you want to delete this entry?</DialogTitle>
                        <div style={{padding: '20px'}}>
                            <h4>{deleteMatch.name}</h4>
                            <div>{deleteMatch.start} - {deleteMatch.end}</div>
                            <button onClick={yesDelete}>Yes Delete</button>
                            <button onClick={handleDelete}>Cancel Delete</button>
                        </div>
                        </Dialog>
                </div>
            </div>

        )
    }

export default PastMatchesMatchStarters