import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Venue from './Venue'
import AddVenue from './AddVenue'
import Grid from '@material-ui/core/Grid'
import QRCodeGuide from '../QRCodeGuide'
import SetupTips from '../SetupTips'
import ExplainQrGuide from './ExplainQrGuide'

const VenueList = () => {
    const [list, setList] = useState([])

    const tid = useSelector(state => state.tid)
    useEffect(
        () => { get() }, [tid])

    const get = () => {
        axios.get(`/api/eventGuide/getVenues/${tid}`).then(res => {
            setList(res.data)
        })
    }
    const select = () => {}

    return(
        <div className='container-fluid'>
            <div className='row'>
            <ExplainQrGuide show={true}/>
            </div>
            <div className='row' style={{background: 'white'}}>
                <div className='col-12 col-sm-6 col-md-4'>
                    <h3>Setup Guide Below</h3>
                    <AddVenue get={get}/>
                    <div style={{border:`2px solid ${list.color || 'black'}`, margin: '20px', background: 'white'}} >            
                    {list.map((item, i)=> (
                        <Venue key={'v'+i} venue={item} getVenues={get} select={select} />
                    ))}
                </div>
                </div>
                <div className='col-12 col-sm-6 col-md-4'>
                <SetupTips />
                </div>
                <div className='col-12 col-sm-6 col-md-4'>
                <QRCodeGuide/>
                </div>
            </div>
        </div>
    )


}
export default VenueList