import React, {useState} from 'react'
import Modal from 'react-responsive-modal'
import VolunteerWaiver from './VolunteerWaiver'



const SignVolunteerWaiver = props => {
    const [show, setShow] = useState(false)

    return(
        <div>
            <div style={{border: '1px solid #D92E42', margin: '10px', padding: '10px', background: 'white', textAlign: 'center'}}>
                <div style={{fontSize: '20px'}}>You have signed up to volunteer but need to sign the waiver to complete registration.</div>
                <button onClick={()=>setShow(true)}>Open</button>
            </div>

<Modal open={show} center showCloseIcon={true} onClose={()=>setShow(false)} closeIconSize={20} closeOnEsc={true} >
        <VolunteerWaiver show={show} get={props.get} close={()=>setShow(false)}/>
    </Modal>

        </div>
    )
}
export default SignVolunteerWaiver