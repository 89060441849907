import React, { useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import RefInfoModal from './RefInfoModal'
import Moment from 'react-moment';


const BoardMagnet = props => {
    const [showRefInfo, setShowRefInfo] = useState(false)
    const [refInfo, setRefInfo] = useState({})

    const tid = useSelector(state => state.tid)

    const { court, stateCourt } = props



    return (
        <div key={court.court_num} className='court-box-boardView'
            style={{ cursor: 'pointer' }}
            onClick={court.ref_id ? () => props.finish(court) : () => props.selectCourt(court.court_num, court.match_starter_id, court.match_starter_name)} >
            <div className='col-4 court-num' style={{background: court.color || '#333333'}}>
                <div className={stateCourt === court.court_num ? 'selectedCourt' : 'court-name'}>
                    <div className={court.court_num ?
                        court.court_num.length > 2 ? 'shrink-court-num' : '' : ''}>{court.court_num}</div>
                </div>
                {court.medal === 'Bronze' ?
                    <div className='board-medal'>
                        <i className="fas fa-medal"></i>
                    B
                    </div> :
                    court.medal === 'Gold' ?
                        <div className='board-medal'>
                            <i className="fas fa-medal"></i>
                        G
                    </div> : ''}
                <div className='board-start-time'>
                    {court.start_time ?
                        <Moment format="h:mm"
                            local>
                            {court.start_time}
                        </Moment>
                        : ''}
                </div>

            </div>
            <div className={
                court.match_starter_id ?'court-monitor-board col-8 ref-name':
                court.credentials_verified === 'Certified' ? 'certified-board col-8 ref-name' : court.credentials_verified === 'Level 2' ? 'level2-board col-8 ref-name' : court.credentials_verified === 'Level 1' ? 'level1-board col-8 ref-name' :
                    stateCourt === court.court_num ? 'selectedCourt' :
                        court.ref_id ? 'col-8 ref-name volunteer-board' :
                            'col-8 ref-name'}>

                {court.last_match ? <span className='last_match_icon'>LM</span> : ''}
                {court.first ?
                    <div>{court.first} {court.last}
                    </div> : ''}
                    {court.match_starter_name ?
                    <div>
                        <i className="fa-solid fa-circle-play"></i>
                        {court.match_starter_name}
                    </div> : ''}




                <div>
                    {court.first ?
                        <RefInfoModal
                            start_time={court.start_time}
                            ref_id={court.ref_id}
                            tid={tid}
                            board='board'
                            statusHasBeenUpdated={props.statusHasBeenUpdated} />
                        : ''}

                </div>


            </div>
        </div>
    )
}
export default BoardMagnet