import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

const AddBudgetItem = props => {
    const [category, setCategory] = useState(0)
    const [error, setError] = useState('')
    const [budget, setBudget] = useState(true)
    const [budgetTitle, setBudgetTitle] = useState('')
    const [quantity, setQuantity] = useState(0)
    const [rate, setRate] = useState(0)
    let [total, setTotal] = useState(0)
    const [contact, setContact] = useState('')
    const [contactPhone, setContactPhone] = useState('')
    const [contactEmail, setContactEmail] = useState('')
    const [itemNotes, setItemNotes] = useState('')
    const [categoryError, setCategoryError] = useState(true)
    const [titleError, setTitleError] = useState(true)
    const [verifyDelete, setVerifyDelete] = useState(false)
    const [todo, setTodo] = useState(false)
    const [todoTitle, setToDoTitle] = useState('')
    const [todoCategory, setTodoCategory] = useState(1)

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { setPropsToState() }, [props.editItem])

    useEffect(
        () => { totalItems() }, [rate])

    useEffect(
        () => { totalItems() }, [quantity])
    useEffect(
        () => { checkForCategory() }, [category])
    useEffect(
        () => { checkForTitle() }, [budgetTitle])
    useEffect(
        () => { duplicateTitle() }, [todo])
    useEffect(
        () => { editBudgetItem() }, [budget])

    const setPropsToState = () => {
        if (props.editItem.pid) {
            setCategory(props.editItem.category_id)
            setBudget(props.editItem.budget)
            setBudgetTitle(props.editItem.budget_title)
            setQuantity(props.editItem.quantity)
            setRate(props.editItem.rate)
            setTotal(props.editItem.total)
            setContact(props.editItem.contact)
            setContactPhone(props.editItem.contactPhone)
            setContactEmail(props.editItem.contactEmail)
            setItemNotes(props.editItem.itemNotes)
            setToDoTitle(props.editItem.todo_title)
            setTodo(props.editItem.todo)
            setTodoCategory(props.editItem.cid)
        }
    }

    const handleSave = () => {
        if (!categoryError && !titleError) {
            if (props.editItem.pid) {
                editBudgetItem()
            } else {
                addBudgetItem()
            }
        }
    }

    const addBudgetItem = () => {
        if (!categoryError && !titleError) {
            axios.post(`/api/addBudgetItem/${tid}`, { category, budgetTitle, todoTitle, quantity, rate, total, contact, contactPhone, contactEmail, itemNotes, budget, todo, todoCategory }).then(res => {
                if (props.getBudget) { props.getBudget() }
                if (props.close) { props.close() }
            })
        }
    }

    const editBudgetItem = () => {
        if (!categoryError && !titleError) {
            axios.put(`/api/editBudgetItem/${props.editItem.pid}`,
                { category, budgetTitle, todoTitle, quantity, rate, total, contact, contactPhone, contactEmail, itemNotes, budget, todo, todoCategory }).then(res => {
                    if (props.getBudget) { props.getBudget() }
                    if (props.close) { props.close() }
                })
        }
    }
    const deleteBudgetItem = () => {
        if (!categoryError && !titleError) {
            axios.put(`/api/deleteBudgetItem/${props.editItem.pid}`).then(res => {
                if (props.getBudget) { props.getBudget() }
                if (props.close) { props.close() }
            })
        }
    }

    const handleDeleteItem = () => {
        if (props.editItem.pid) {
            setVerifyDelete(true)
        } else {
            props.close()
        }
        
    }

    const checkForCategory = () => {
        if (category === 0) { setCategoryError(true) }
        else { setCategoryError(false) }
    }

    const checkForTitle = () => {
        if (budgetTitle.length === 0) { setTitleError(true) }
        else { setTitleError(false) }
    }

    const totalItems = () => {
        let lineTotal = Math.round((rate * quantity) * 100) / 100
        setTotal(lineTotal)
    }

    const handleQuantity = e => {
        const onlyNums = e.replace(/[^0-9]/g, '')
        setQuantity(onlyNums)
    }

    const duplicateTitle = () => {
        setToDoTitle(budgetTitle)
    }

    return (
        <div className='AddBudgetItem'>
            <div className='addForm'>
                <div className='formTitle'
                    style={categoryError ? { color: 'red' } : null}
                >
                    Expense Type
                    </div>
                <div >
                    <label>
                        <input type='radio'
                            checked={category === 1}
                            onChange={() => setCategory(1)}
                        /> Income </label>
                    <label>
                        <input type='radio'
                            checked={category === 4}
                            onChange={() => setCategory(4)}
                        /> Sponsorship </label>
                    <label>
                        <input type='radio'
                            checked={category === 2}
                            onChange={() => setCategory(2)}
                        /> Income Expense </label>

                    <label>
                        <input type='radio'
                            checked={category === 3}
                            onChange={() => setCategory(3)}
                        /> Expense </label>
                </div>
            </div>

            <div className='addForm'>
                <div className='formTitle'
                    style={titleError ? { color: 'red' } : null}
                >
                    Title
                    </div>
                <div >
                    <input type="text"
                        value={budgetTitle}
                        onChange={e => setBudgetTitle(e.target.value)} />
                </div>
            </div>
            <div className='addForm'>
                <div className='formTitle'>
                    Quantity
                    </div>
                <div >
                    <input type="number"
                        value={quantity}
                        onChange={e => handleQuantity(e.target.value)} />
                </div>
            </div>
            <div className='addForm'>
                <div className='formTitle'>
                    Rate
                    </div>
                <div >
                    <input type="number"
                        step='.01'
                        value={rate}
                        onChange={e => setRate(e.target.value)}
                    />
                </div>
            </div>

            <div className='addForm'>
                <div className='formTitle'>
                    Total
                    </div>
                <div className='itemTotalBox'>
                    {total}
                </div>
            </div>
            <div className='addForm'>
                <div className='formTitle'>
                    Contact
                    </div>
                <div >
                    <input type="text"
                        value={contact}
                        onChange={e => setContact(e.target.value)} />
                </div>
            </div>
            <div className='addForm'>
                <div className='formTitle'>
                    Contact Phone
                    </div>
                <div >
                    <input type="text"
                        value={contactPhone}
                        onChange={e => setContactPhone(e.target.value)} />
                </div>
            </div>
            <div className='addForm'>
                <div className='formTitle'>
                    Contact Email
                    </div>
                <div >
                    <input type="text"
                        value={contactEmail}
                        onChange={e => setContactEmail(e.target.value)} />
                </div>
            </div>
            <div className='addForm'>
                <div className='formTitle'>
                    Notes
                    </div>
                <div >
                    <textarea name="" id="" cols="30" rows="3"
                        onChange={e => setItemNotes(e.target.value)}>

                    </textarea>


                </div>

            </div>
            <div className='add-checkbox'>
                <input type="checkbox"
                    checked={todo}
                    value={todo}
                    onChange={e => setTodo(todo => !todo)} />
                Add Budget Item to To-Do list?

            </div>
            {todo ?
                <div className='addForm'>
                    <div className='formTitle'>
                        To-Do List Title
                    </div>
                    <div >
                        <input type="text"
                            value={todoTitle}
                            onChange={e => setToDoTitle(e.target.value)} />
                    </div>
                </div>
                : ''}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}
                className='tips'>

                
                {verifyDelete ?
                    todo ?
                        <div>
                            <h3>Budget Item has a to-do task assigned.  </h3>
                            <div>Do you want to remove both budget item and to-do item?</div>
                            <button
                                onClick={deleteBudgetItem}>Remove Both Budget and To-Do</button>
                            <button
                                onClick={() => setBudget(false)
                                }>Remove Just Budget Item</button>
                        </div>
                        :
                        <button
                            style={{ background: 'red' }}
                            onClick={deleteBudgetItem}>Verify Delete Item</button> : ''}
                <button onClick={props.close}>Close</button>
                <i className="far fa-trash-alt" style={{ textAlign: 'right' }}
                    onClick={handleDeleteItem}></i>
                    {!categoryError && !titleError && !verifyDelete ?
                    <button
                        onClick={handleSave}>Save</button> : ''}
            </div>
        </div>
    )
}
export default AddBudgetItem