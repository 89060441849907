import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from "react-redux"
import { updateTournament } from '../../../../ducks/reducer'
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ScheduleRecap from '../../ScheduleModal/ScheduleRecap'
import CategoryDetail from './Test'
import SignVolunteerWaiver from './SignVolunteerWaiver'
import VolunteerSignupByCategory from './VolunteerSignupByCategory';
import ShiftsByTime from './ShiftsByTime';

const VolunteerSignupNew = props => {
    const [Schedule, setSchedule] = useState([])
    const [ShowMore, setShowMore] = useState(false)
    const [showWaiver, setShowWaiver] = useState(false)
    const [view, setView] = useState('category')

    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)
    const name = useSelector(state => state.name)
    const start = useSelector(state => state.start)
    const end_date = useSelector(state => state.end_date)
    const location = useSelector(state => state.location)
    const volunteer_description = useSelector(state => state.volunteer_description)
    const volunteer_coordinator_name = useSelector(state => state.volunteer_coordinator_name)

    useEffect(
        () => { getMySchedule() }, [tid, ref_id])
        useEffect(
            () => { checkForWaiver() }, [tid, ref_id])

    const getMySchedule = () => {
        if (ref_id > 0) {
            axios.post(`/api/getRefScheduleForTournament/${ref_id}`, { tid: tid }).then(
                res => {
                    setSchedule(res.data)
                }
            )
        }
    }
    const checkForWaiver = () => {
        if (ref_id > 0) {
            axios.post(`/api/volunteer/checkIfWaiverIsNeededAndSigned`, { ref_id, tid }).then(
                res => {
                    if(res.status === 200){
                    setShowWaiver(res.data)}
                }
            )
        }
    }

    const mappedSchedule = Schedule
            .map((shift, i) => {
                return (
                    <div key={'a' + i}>
                        <ScheduleRecap
                            shift={shift} />
                    </div>
                )
            })

        return( 
            <div className='container-fluid'>
                <div className='AddRefToSchedule' >
                    <div className='col-12 col-md-4'>
                        <button onClick={() => props.history.goBack()}>Back to Tournament Listings</button>
                    </div>
                    <div className='row justify-content-center'>

                        <div className='col-4'>
                            <img className='volunteer-type-image'
                                src={'https://res.cloudinary.com/shonda/image/upload/v1582498963/PickleballDesk/Volunteer_he87xg.png'} alt='line judge' />
                        </div>
                        <div className='col-8'>
                            <h5>Volunteer Opportunities For:</h5>
                            <h2>{name}</h2>
                            <div>
                                <Moment format="MM/DD/YYYY" >
                                    {start}
                                </Moment> -
                        <Moment format="MM/DD/YYYY" >
                                    {end_date}
                                </Moment>
                            </div>
                            <p>Location: {location}</p>

                            {showWaiver && <SignVolunteerWaiver get={checkForWaiver}/>}
                            

                        </div>
                        
                    </div>

                    <div className='row'>

                        <div className='col-12 col-md-4 '
                            style={{ background: 'white' }}>

                            {ref_id ?
                                <div>
                                    <div className='my-schedule-title'>
                                        My Schedule
                                            </div>
                                    <div className='my-schedule'>
                                        {mappedSchedule}
                                    </div>
                                </div> : 'Login to see your schedule'}

                        </div>

                        <div className='col-12 col-md-8' >
                            <div className='dashboard-title'>Volunteer Signup</div>
                            {volunteer_coordinator_name ?
                                <div className='coordinator'>Volunteer Coordinator: {volunteer_coordinator_name}
                                    {ref_id ?
                                        <Link to={{
                                            pathname: `/signup/contactcoordinator`,
                                            type: 'volunteer'
                                        }}>
                                            <div className='contact-button'>
                                                <i className="fas fa-envelope"></i>Contact</div>
                                        </Link> :
                                        <p style={{ marginLeft: '20px' }}>Login to Contact</p>}
                                </div> : ''}
                            <div style={{
                                background: 'white', padding: '10px'
                            }}>
                               
                                <div className={ShowMore ? 'tournament-description-long' : 'tournament-description-short'
                                } >
                                    <ReactQuill
                                        className='description'
                                        value={volunteer_description}
                                        readOnly={true}
                                        theme={"bubble"}
                                    />
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <button
                                        className='login-button'
                                        onClick={() => setShowMore(ShowMore => !ShowMore)}>{ShowMore ? 'Show Less' : 'Show More'}</button>
                                </div>
                            </div>

                            <div style={{ margin: '10px 0 0 0' }}>
                                <div className='search-menu'>
                                <h4>How would you like to help?</h4>
                        <div className='row'>
                            <div className='col-12'>

                            
                            <button onClick={()=>setView('category')}>
                                List By Category
                            </button>
                            <button onClick={()=>setView('time')}>
                                List By Time
                            </button>
                            
                        {view === 'category' && <VolunteerSignupByCategory getSchedule={getMySchedule} view={view}/>}
                        {view === 'time' && <ShiftsByTime getSchedule={getMySchedule} view={view}/>}

                        </div>
                        </div>
                                    
                                    
                                </div>
                                
                                
                               </div>
                        </div>


                    </div>
                    
                </div >

            </div >
        )
    }

export default VolunteerSignupNew
