import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import BingoBox from './BingoBox';

const MakeBingoCard = props => {
    const [bingoCard, setBingoCard] = useState([]) 
    let {tid, ref_id} = props
    let {cid, one, two,three, four, five, six, seven, eight, nine, ten, eleven, twelve, thirteen, fourteen, fifteen, sixteen, seventeen, eighteen, nineteen, twenty, twentyone, twentytwo, twentythree, twentyfour,
        one_done, two_done,three_done, four_done, five_done, six_done, seven_done, eight_done, nine_done, ten_done, eleven_done, twelve_done, thirteen_done, fourteen_done, fifteen_done, sixteen_done, seventeen_done, eighteen_done, nineteen_done, twenty_done, twentyone_done, twentytwo_done, twentythree_done, twentyfour_done
    } = bingoCard

        const classes = useStyles();

    useEffect(
        () => { getBingoCard() }, [props.ref_id, props.tid])

const getBingoCard = () => {
    if(!props.ref_id || !props.tid)return
    axios.post(`/api/bingo/getBingoCard/`, {tid, ref_id}).then(
        res => {
            setBingoCard(res.data[0])
        }
    )
}

  return (
    <div className='bingo-card'>
        <div className='bingo'>BINGO</div>

        <div className='bingo-row'>
              <BingoBox cid={cid} get={getBingoCard} item={1} name={one} completed={one_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={2} name={two} completed={two_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={3} name={three} completed={three_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={4} name={four} completed={four_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={5} name={five} completed={five_done}/>
        </div>
        <div className='bingo-row'>
              <BingoBox cid={cid} get={getBingoCard} item={6} name={six} completed={six_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={7} name={seven} completed={seven_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={8} name={eight} completed={eight_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={9} name={nine} completed={nine_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={10} name={ten} completed={ten_done}/>
        </div>
        <div className='bingo-row'>
              <BingoBox cid={cid} get={getBingoCard} item={11} name={eleven} completed={eleven_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={12} name={twelve} completed={twelve_done}/>
              <BingoBox cid={cid} get={getBingoCard} name={'free'} completed={true}/>
              <BingoBox cid={cid} get={getBingoCard} item={13} name={thirteen} completed={thirteen_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={14} name={fourteen} completed={fourteen_done}/>
        </div>
        <div className='bingo-row'>
              <BingoBox cid={cid} get={getBingoCard} item={15} name={fifteen} completed={fifteen_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={16} name={sixteen} completed={sixteen_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={17} name={seventeen} completed={seventeen_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={18} name={eighteen} completed={eighteen_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={19} name={nineteen} completed={nineteen_done}/>
        </div>
        <div className='bingo-row'>
              <BingoBox cid={cid} get={getBingoCard} item={20} name={twenty} completed={twenty_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={21} name={twentyone} completed={twentyone_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={22} name={twentytwo} completed={twentytwo_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={23} name={twentythree} completed={twentythree_done}/>
              <BingoBox cid={cid} get={getBingoCard} item={24} name={twentyfour} completed={twentyfour_done}/>
        </div>
      </div>
    )

}
export default MakeBingoCard

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    square: {
        height: '100px',
        width: '100px',
        textAlign: 'center'
    }
  });