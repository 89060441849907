import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import RankingMaster from '../components/Ranking/RankingMaster'
import EventSetup from '../components/Ranking/TournamentResults/EventSetup'
import Partnerships from '../components/Ranking/TournamentResults/Partnerships'

const RankingRoutes = () => {
    const user_id = useSelector(state => state.user_id)

    return (
        <Switch>
            <Route path='/ranking/partners' component={Partnerships} />
            <Route path='/ranking/eventsetup' component={EventSetup} />
            <Route path='/ranking' component={RankingMaster} />

        </Switch>
    )
}
export default RankingRoutes 