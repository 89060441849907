import React, {useState} from 'react'
import axios from 'axios'
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Switch from "@material-ui/core/Switch";
import { CircularProgress } from '@material-ui/core';
import RefManagerEditReferee from './RefManagerEditReferee';
import RefManagerRefSchedule from './RefManagerRefSchedule';
import { toast } from 'react-toastify';



export default function(props){
    const [checkOutLoader, setCheckoutLoader] = useState(false)
    const [quickBreakLoader, setQuickBreakLoader] = useState(false)
    const [dressCodeLoader, setDressCodeLoader] = useState(false)
    const [lunchLoader, setLunchLoader] = useState(false)
    const [lastMatchLoader, setLastMatchLoader] = useState(false)
    const [resetLoader, setResetLoader] = useState(false)



    let {tid, row, ref_id, removed_by, organization} = props




    const checkIn = () => {
        setCheckoutLoader(true)
        axios.put(`/api/checkin/${tid}`, { ref_id, checkout_note: '', status: !row.status }).then(
            res => {
                setCheckoutLoader(false)
                props.get()
            }
        )
    }


    const quickBreak = () => {
        setQuickBreakLoader(true)
        axios.put(`/api/quickBreak/${tid}`, { ref_id }).then(
            res => {
                setQuickBreakLoader(false)
                props.get()
            }
        )
    }
    const dressCode = () => {
        setDressCodeLoader(true)
        axios.put(`/api/dressCode/${tid}`, { ref_id }).then(
            res => {
                setDressCodeLoader(false)
                props.get()
            }
        )
    }

    const lastMatch = () => {
        setLastMatchLoader(true)
        axios.put(`/api/lastMatch/${tid}`, { ref_id }).then(
            res => {
                setLastMatchLoader(false)
                props.get()
            }
        )
    }
    const lunchBreak = () => {
        if(row.note === 'Lunch Break'){
            lunchBreakEnd()
        }else{
            lunchBreakStart()
        }
    }
    const lunchBreakStart = () => {
        setLunchLoader(true)
        axios.put(`/api/lunchBreakStart/${tid}`, { ref_id, note: 'Lunch Break' }).then(
            res => {
                setLunchLoader(false)
                props.get()

            }
        )
    }
    const lunchBreakEnd = () => {
        setLunchLoader(true)
        axios.put(`/api/lunchBreakEnd/${tid}`, { ref_id }).then(
            res => {
                setLunchLoader(false)
                props.get()
            }
        )
    }
    const resetReferee = () => {
        setResetLoader(true)
        axios.put(`/api/resetReferee/${tid}`, { ref_id }).then(
            res => {
                toast.success(`${row.first} was reset and will now be in the queue`)
                setResetLoader(false)
                props.getAfterReset()
            }
        )
    }
    return(
        <TableRow>
              <TableCell>{row.first}</TableCell>
              <TableCell>{row.last}</TableCell>
              <TableCell align="left">{organization === 'ppa' ? row.ppa_rating + ' / ' + row.credentials:row.credentials}</TableCell>
              <TableCell align="left">
                  {checkOutLoader ?
                  <CircularProgress />:
              < Switch
                        color="primary"
                        checked={row.status}
                        value={row.status ? "Active" : 'Inactive'}
                        onClick={checkIn}
                    />}
              </TableCell>
              <TableCell align="left">
              {quickBreakLoader ?
                  <CircularProgress />:
              < Switch
                        color="primary"
                        checked={row.quick_break}
                        value={row.quick_break ? "Active" : 'Inactive'}
                        onClick={quickBreak}
                    />}
              </TableCell>
              <TableCell align="left">
              {lastMatchLoader ?
                  <CircularProgress />:
              < Switch
                        color="primary"
                        checked={row.last_match}
                        value={row.last_match ? "Active" : 'Inactive'}
                        onClick={lastMatch}
                    />}
              </TableCell>
              <TableCell align="left"> 
              {lunchLoader ?
                  <CircularProgress />:
              < Switch
                        color="primary"
                        checked={row.note === 'Lunch Break'}
                        value={row.note === 'Lunch Break' ? "Active" : 'Inactive'}
                        onClick={lunchBreak}
                    />}
                    </TableCell>
              <TableCell align="left" >
                  {dressCodeLoader ?
                  <CircularProgress />:
              < Switch
                        color="primary"
                        checked={row.dress_code}
                        value={row.dress_code ? "Active" : 'Inactive'}
                        onClick={dressCode}
                    />}</TableCell>
              <TableCell align="left">{row.on_court ? 
                 `${row.last_match ? 'Last Match' : ''}Court ${row.court_num}` :
            row.note === 'Lunch Break' ?
                'Lunch Break' :
                row.shift_checkin ? 'On Deck' :
                    row.quick_break ? 'Quick Break' : row.status ? 'On Duty' :
                        row.checkout_note  
            }</TableCell>
              <TableCell align="left" >
                  <RefManagerRefSchedule tid={tid} ref_id={row.ref_id} row={row}/>
              </TableCell>
              <TableCell align="left">
                  <RefManagerEditReferee tid={tid} ref_id={row.ref_id} row={row} get={props.get} removed_by={removed_by}/>
              </TableCell>
              <TableCell align="left">
                  {resetLoader ? 
                  <CircularProgress/>:
                  <button className='register-button' onClick={resetReferee}>Reset</button>}
                  </TableCell>
              </TableRow>
    )
}