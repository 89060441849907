import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import Budget from '../components/Planning/Budget/Budget'
import ToDoList from '../components/Planning/ToDo/ToDoList'


const PlanRoutes = () => {
    const user_id = useSelector(state => state.user_id)
    const admin_type = useSelector(state => state.admin_type)

    return (
        <Switch>
            <Route path='/plan/budget' component={Budget} />
            <Route path='/plan/todolist' component={ToDoList} />


        </Switch>
    )
}
export default PlanRoutes 