import React from 'react'
import Moment from 'react-moment'
import { useSelector } from "react-redux"


const ScheduleRecap = (props) => {
    const timeZone = useSelector(state => state.time_zone)

    let {shift} = props
    return (
        <div style={{ borderBottom: '1px dotted grey', fontSize: '14px' }}>
            <div>
            {shift.start_time ?
                <Moment format="ddd MM/DD h:mm a" tz={timeZone}>
                    {shift.start_time}
                </Moment>:''}
                {shift.end_time ?
                <>
                {` to `}
                <Moment format="h:mm a" tz={timeZone}>
                    {shift.end_time}
                </Moment>
                </>:''}
                <div style={{paddingLeft:'10px'}}>
                {props.shift.category}: <small>{props.shift.waitlist ? 'Waitlist' : ''}</small> {props.shift.name} 
                </div>
                </div>
        </div>

    )
}
export default ScheduleRecap