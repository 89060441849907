import React from 'react'
import Moment from 'react-moment';
import 'moment-timezone';
import { useSelector } from 'react-redux'
import RefInfoModal from '../MasterBoard/RefInfoModal'

const RefereeMagnet = (props) => {
    const organization = useSelector(state => state.organization)

    const ref = props.referee
    return (
        <div>
            <div className={
                ref.quick_break ? 'break ref-list' :
                    ref.credentials_verified === 'Certified' ? 'ref-list certified' :
                        ref.credentials_verified === 'Level 2' ? 'ref-list level2' :
                            ref.credentials_verified === 'Level 1' ? 'ref-list level1' : 'ref-list'}
            >
                {ref.note ? <span className='ref-note'>{ref.note}</span> : ''}
                {ref.tag ? <span className='ref-tag'>{ref.tag}</span> : ''}
                {ref.ref_note ? <span className='ref-note'>{ref.ref_note}</span> : ''}

                {props.swapQueue ?
                    <i className="fas fa-exchange-alt swap-queue"
                        style={{ cursor: 'pointer' }}
                        onClick={() => props.swapQueue(ref.ref_id)}></i>
                    : ''}


                <div
                    style={{ cursor: 'pointer' }}
                    onClick={props.selectRef ? () => props.selectRef(ref) : ''}>
                    {ref.quick_break ? <span className='wait-to-assign'>Wait to Assign</span> : ''}

                    {ref.last_match ? <span className='last_match_icon'>LM</span> : ''}
                    {ref.dress_code ? <i className="fas fa-user-check dress_code"></i>:''}

                    <div className='magnet-name col-11'>{ref.first} {ref.last}</div>
                </div>
                <div className='row'>
                    <div className='col-12 magnet-ref-info'>
                        <div className='magnet-credentials'>
                        {organization === 'ppa' && <span style={{fontSize:'1.2em'}}>{ref.ppa_rating !== null && ref.ppa_rating !== undefined &&  ref.ppa_rating + ' '}</span>}

                            {ref.credentials_verified === 'Certified' ? 'C' :
                                ref.credentials_verified === 'Level 1' ? 'L1' :
                                    ref.credentials_verified === 'Level 2' ? 'L2' :
                                        'V'

                            }
                        </div>

                        {ref.end_time ?
                            <Moment format="h:mm a">
                                {ref.end_time}
                            </Moment>
                            : ''}
                        <RefInfoModal
                            ref_id={ref.ref_id}
                            statusHasBeenUpdated={props.statusHasBeenUpdated} />

                    </div>

                </div>
            </div>
        </div >
    )
}
export default RefereeMagnet