import React from 'react'
import { connect } from "react-redux"
import { clearUser } from '../../ducks/reducer';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import './Nav.css'
const Nav = (props) => {

    if (props.location.pathname.includes('/desk/')) {
        return (

            <div className='Nav row'>
                {/* <div className='col-12' style={{ color: 'red', 'fontSize': '2em', 'background': 'white' }}>
                {window.location.href == 'pickleball.tech' || 'localhost' ? 'DEMO site' : ''}

            </div> */}


                <div className={props.location.pathname === '/desk/boardView' ? 'active-page menu' : 'nav-item menu'}>

                    <div className='nav-rectangle'></div>
                    <Link to={`/desk/boardView`} >
                        <span>Board View</span>
                    </Link>
                </div>
                <div className={props.location.pathname === '/desk/refDesk/checkIn' ? 'active-page menu' : 'nav-item menu'}>
                    <div className='nav-rectangle'></div>
                    <Link to={'/public/checkIn'} target='_blank' rel="noopener noreferrer">
                        Ref Status/ Check-In
            </Link>
                </div>
                <div className={props.location.pathname === '/desk/pastMatches' ? 'active-page menu' : 'nav-item menu'}>
                    <div className='nav-rectangle'></div>
                    <Link to={`/desk/pastMatches`} >
                        <span>Past Matches</span>
                    </Link>
                </div>
                <div className={props.location.pathname === '/desk/statusUpdates' ? 'active-page menu' : 'nav-item menu'}>
                    <div className='nav-rectangle'></div>
                    <Link to={`/desk/statusUpdates`} >
                        <span>Status Report</span>
                    </Link>
                </div>
                <div className={props.location.pathname === '/line/linejudge' ? 'active-page menu' : 'nav-item menu'}>
                    <div className='nav-rectangle'></div>
                    <Link to={`/line/linejudge`} >
                        <span>Line Judge</span>
                    </Link>
                </div>
                <div className={props.location.pathname === '/admin/tournamentSetup' ? 'active-page menu' : 'nav-item menu'}>
                    <div className='nav-rectangle'></div>
                    <Link to={`/admin`} >
                        <span>Admin</span>
                    </Link>
                </div>
                {/* <div className={props.location.pathname === '/desk/schedule' ? 'active-page' : 'nav-item'} >
                    <div className='nav-rectangle'></div>
                    <Link to={`/desk/schedule`} >
                        <span>Schedule</span>
                    </Link>
                </div> */}

                <div className={props.location.pathname === '/deskDashboard' ? 'active-page menu' : 'nav-item menu'} >
                    <div className='nav-rectangle'></div>
                    <Link to={`/deskDashboard`} >
                        <span>Dashboard</span>
                    </Link>
                </div>






                {/* <Link to={'/refDesk/checkin'}>
                Ref Check-In
            </Link> */}

                {/* <Link to={'/scheduler'}>
                <span>Referee Schedule</span>
            </Link> */}



                {/* <Link to={`/sendText`} >
                <span>Send Text to Players</span>
            </Link>
            <Link to={`/deskScorecards`} >
                <span>Scorecards</span>
            </Link> */}




            </div>
        )
    }
    else if (props.location.pathname.includes('/line/')) {
        return (
            <div className='Nav row'>
                <div className={props.location.pathname === '/line/linejudge' ? 'active-page menu' : 'nav-item menu'}>
                    <div className='nav-rectangle'></div>
                    <Link to={'/line/linejudge'} >
                        Line Judge Manager
                    </Link>
                </div>
                <div className={props.location.pathname === '/public/linecheckin' ? 'active-page menu' : 'nav-item menu'}>
                    <div className='nav-rectangle'></div>
                    <Link to={'/public/linecheckin'} target='_blank' rel="noopener noreferrer">
                        Line Judge Status/ Check-In
                    </Link>
                </div>

                <div className={props.location.pathname === '/line/linejudgeschedule' ? 'active-page menu' : 'nav-item menu'}>
                    <div className='nav-rectangle'></div>
                    <Link to={'/line/linejudgeschedule'} >
                        Communication/ Schedule by Line Judge
                    </Link>
                </div>
                <div className={props.location.pathname === '/line/editSchedule' ? 'active-page menu' : 'nav-item menu'}>
                    <div className='nav-rectangle'></div>
                    <Link to={'/line/editSchedule'} >
                        Manage Schedule
                    </Link>
                </div>
                <div className={props.location.pathname === '/desk/boardView' ? 'active-page menu' : 'nav-item menu'}>

                    <div className='nav-rectangle'></div>
                    <Link to={`/desk/boardView`} >
                        <span>Referee</span>
                    </Link>
                </div>

                <div className={props.location.pathname === '/line/tournamentSetup' ? 'active-page menu' : 'nav-item menu'}>
                    <div className='nav-rectangle'></div>
                    <Link to={`/line/setup`} >
                        <span>Admin</span>
                    </Link>
                </div>

                <div className={props.location.pathname === '/deskDashboard' ? 'active-page menu' : 'nav-item menu'} >
                    <div className='nav-rectangle'></div>
                    <Link to={`/deskDashboard`} >
                        <span>Dashboard</span>
                    </Link>
                </div>
            </div>
        )

    }

    else return (null)
}

const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        user_id: reduxState.user_id,
        name: reduxState.name,
        first: reduxState.first,
        last: reduxState.last,
        user_name: reduxState.user_name
    }
}


export default withRouter(connect(
    mapStateToProps, { clearUser }
)(Nav))