import React, {useState} from 'react'
import axios from 'axios'
import Modal from 'react-responsive-modal';



export default function RefManagerEditReferee(props){
    const [edit, setEdit] = useState(false)
    const [first, setFirst] = useState('')
    const [last, setLast] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [credentials, setCredentials] = useState('')
    const [barcode, setBarcode] = useState('')
    const [verify, setVerify] = useState(false)

    let {tid, ref_id, removed_by} = props

    const handleProps = () => {
        let {first, last, phone, email, credentials, barcode} = props.row
        setFirst(first)
        setLast(last)
        setCredentials(credentials)
        setPhone(phone)
        setEmail(email)
        setBarcode(barcode)
        setEdit(!edit)
    }
    const saveEdit = () => {
        axios.put(`/api/editRef`, { tid, ref_id, first, last, credentials, phone, email, barcode }).then(
            res => {
                setEdit(false)
                props.get()
            }
        )
    }

    const removeRef =  () => {
        axios.put(`/api/removeRefFromTournament/${tid}`, { ref_id, removed_by}).then(
            res => {
                setEdit(false)
                setVerify(false)
                props.get()
            }
        )
    }

    return(
        <>{edit ? 
        <Modal open={edit} center showCloseIcon={true} onClose={()=>setEdit(false)} closeOnEsc={true} closeIconSize={2}>
                        <h6>Edit Referee Info</h6>
                        <h2>{first} {last}</h2>
                        <p>
                            <label> First
                                <input type="text"
                                    placeholder='first'
                                    name='first'
                                    value={first}
                                    onChange={e=>setFirst(e.target.value)} />
                            </label>
                        </p>
                        <p>
                            <label> last
                                <input type="text"
                                    placeholder='last'
                                    name='last'
                                    value={last}
                                    onChange={e=>setLast(e.target.value)} />
                            </label>
                        </p>

                        <p><label> Phone
                                <input type="text"
                                placeholder='Phone Num- no spaces'
                                name='phone'
                                value={phone}
                                maxLength='10'
                                onChange={e=>setPhone(e.target.value)} />
                        </label>
                        </p>
                        <p>
                            <label> Email
                                <input type="text"
                                    placeholder='email'
                                    name='email'
                                    value={email}
                                    onChange={e=>setEmail(e.target.value)} />
                            </label>
                        </p>
                        <p>
                            <label> Barcode
                                <input type="text"
                                    placeholder='Barcode'
                                    name='barcode'
                                    value={barcode}
                                    onChange={e=>setBarcode(e.target.value)} />
                            </label>
                        </p>

                        <legend>Credentials: {credentials}</legend>
                        <label>
                            <input type='radio'
                                checked={credentials === 'Not Rated'}
                                name='credentials'
                                value='Not Rated'
                                onClick={()=>setCredentials('Not Rated')}
                            /> Not Rated </label>
                        <label>
                            <input type='radio'
                                checked={credentials === 'Level 1'}
                                name='credentials'
                                value='Level 1'
                                onClick={()=>setCredentials('Level 1')}
                            /> Level 1 </label>
                        <label>
                            <input type='radio'
                                value='Level 2'
                                name='credentials'
                                checked={credentials === 'Level 2'}
                                onClick={()=>setCredentials('Level 2')}
                            /> Level 2 </label>
                        <label>
                            <input type='radio'
                                checked={credentials === 'Certified'}
                                name='credentials'
                                value='Certified'
                                onClick={()=>setCredentials('Certified')}
                            /> Certified </label>
                        <div>
                            <button onClick={saveEdit}><i className="far fa-save"></i>Save</button>
                            <button onClick={()=>setEdit(false)}><i className="fas fa-ban" ></i>Cancel</button>
                            <button onClick={()=>setVerify(true)}>{verify ? 'cancel remove':'Remove Ref From Tournament'}</button>
                            {verify ? 
                            <div>
                            <h4>Are you sure you want to remove {first} {last} from the tournament?</h4>
                            <p>Referee will be removed from all scheduled shifts.  This cannot be reversed.</p>
                            <p>Please make sure to first notify the referee before removing.</p>

                            <p>To reset referee status use the reset button  </p>
                            <button onClick={removeRef}>Verify Remove From Shifts and Tournament</button>
                        </div>
                            
                            
                            
                            :null}
                        </div>

                    </Modal>: <button onClick={handleProps}>edit</button>}
                    </>
    )
}