import React from 'react'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const BingoItemDetail = props => {
    let {row, order} = props

    return(
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {order}. {row.name}
              </TableCell>
              <TableCell align="left">{row.code}</TableCell>
              <TableCell align="left">{row.required}</TableCell>
            </TableRow>
    )
}
export default BingoItemDetail