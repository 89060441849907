import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify';
import LookupReferee from './LookupReferee'
import Modal from 'react-responsive-modal';





const AddCompletedAuxAssignment = (props) => {
    const [show, setShow] = useState(false)
    const [court, setCourt] = useState(null)
    const [referee, setReferee] = useState({ref_id: null, credentials: null})
    const [paid, setPaid] = useState(false)
    const [amount, setAmount] = useState(0)
    const [type, setType] = useState('')


    const tid = useSelector(state => state.tid)


    const addCompletedMatch = async () => {
        if(!referee.ref_id) return toast.error('select referee')
        axios.post(`/api/addCompletedAuxAssignment/${tid}`, { court, ref_id: referee.ref_id, credentials : referee.credentials, paid, amount, type }).then(
            res => {
                if (res.data === 'Try Again') {
                    toast.warning(`Issue with finish match, please make sure referee is now in the queue.  Reset referee if missing.`)
                }
                else {
                    clearState()
                    setShow(false)
                    if(props.get){
                        props.get()
                    }
                }
            }
        )
    }


    const addLeadReferee = (e) => {
        setReferee(e)
    }
    
    const clearState = () => {
        setCourt(null)
        setReferee({ref_id: null, credentials: null})
        setPaid(false)
        setAmount(0)
    }


    return (
        <div>
            <button 
                className='button-none'
            onClick={()=>setShow(true)}>+ Add Completed Aux Assignment</button>
            
            <Modal open={show} center showCloseIcon={true}
                onClose={() => setShow(false)} closeOnEsc={true} closeIconSize={2}>
        <div className='finishMatch col-12 ' align="center">
            <div>
                <div>
                    <div className='assign-header'>Add Completed Match</div>
                </div>
                <div>
                    Court: 
                    <input type="text"
                        onChange={e=>setCourt(e.target.value)}
                        value={court}/>
                </div>
                <div>
                    <div>
                        Referee: 
                        <LookupReferee 
                            show={show}
                            addReferee={addLeadReferee}/>
                    </div>
                    <div>
                        Type:
                        <small> Select from options or type custom</small>
                        <SmallButton value='Bonus' set={setType}/>
                        <SmallButton value='Consulting' set={setType}/>
                        <SmallButton value='Line Judge' set={setType}/>
                        <SmallButton value='Line Judge Manager' set={setType}/>
                        <SmallButton value='Tracking Ref' set={setType}/>
                        <SmallButton value='Video Ref' set={setType}/>

                        
                        <input type="text" 
                            placeholder='custom'
                            value={type} onChange={e=>setType(e.target.value)} />
                            </div>
                    <label>
                        
                        <input
                            type="checkbox"
                            placeholder='paid'
                            checked={paid}
                            onChange={() => setPaid(paid => !paid)} />
                            PAID $
                            <input type="number"
                            style={{ width: '50px' }}
                            value={amount}
                            onChange={e => setAmount(e.target.value)} />
                    </label>
                </div>
            </div>
            
          

       

                <button onClick={addCompletedMatch}>Save Match</button>
                
            </div>

            <div>
           

            </div>

        </Modal>
        </div>

    )
}
export default AddCompletedAuxAssignment

export function SmallButton(props){
    return(
        <ul style={{fontSize:'16px', background: 'white', cursor: 'pointer'}}
            onClick={()=>props.set(props.value)}>  
        {props.value}

        </ul>
    )
}