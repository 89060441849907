import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';


const VolunteerWaiverList = () => {
    const [names, setNames] = useState([])
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)

    useEffect(
        () => { getVolunteers() }, [tid, ref_id])

    const getVolunteers = () => {
        setLoader(true)
        axios.post(`/api/volunteer/getVolunteerWaiverList`, {tid}).then(
            res => {
                setLoader(false)
                setNames(res.data)
            }
        )
    }

        return (
            <div className='container-fluid' >
                <h3>Volunteer Waivers</h3>
                {loader &&
                    <LinearProgress color="secondary"/>}


<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="left">Signature</TableCell>
            <TableCell align="left">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {names.map((name, i)=>(
                <TableRow key={i}>
                <TableCell component="th" scope="row">
                    {name.name}
                </TableCell>
                <TableCell align="left">{name.signature}</TableCell>
                <TableCell align="left">{name.signature_date}</TableCell>
                </TableRow>
                
                ))}
        </TableBody>
        </Table>
        </TableContainer>
                    
            </div>
        )
    }

export default VolunteerWaiverList

