import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const TotalDisplay = (props) => {
 let {totals, headTotals, secondTotals, auxTotal, auxTotalByCat } = props

 const classes = useStyles();
    return(
        

            <div className='DailyTotals'>
                
              <h4>
                  Matches: {totals.match_count ||0}  ${+totals.lead_total + +totals.second_total + +auxTotal
 }
                  </h4>
                  <div className='row'>
              
              <div className='daily-detail col-12 col-md-6'>
              {headTotals.length > 0 && <h5>Lead Referee Amount</h5>}
                  

                  <Table className={classes.table} size="small" aria-label="a dense table">

                    <TableBody>
                    {headTotals.map((row, i) => (
                        <TableRow key={'l'+i}>
                        <TableCell component="th" scope="row">
                            {row.ref_cred}
                        </TableCell>
                        <TableCell align="right">{row.count}</TableCell>
                        <TableCell align="left">@ ${row.amount || 0} </TableCell>
                        <TableCell align="left">= ${row.sum}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
              </div>
              <div className='daily-detail col-12 col-md-6'>
                  {secondTotals.length > 0 && <h5>Supporting Referee Amount</h5>}
                  <Table className={classes.table} size="small" aria-label="a dense table">

                    <TableBody>
                    {secondTotals.map((row, i) => (
                        <TableRow key={'l'+i}>
                        <TableCell component="th" scope="row">
                            {row.second_cred}
                        </TableCell>
                        <TableCell align="right">{row.count}</TableCell>
                        <TableCell align="left">@ ${row.second_amount}</TableCell>
                        <TableCell align="left">= ${row.sum}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>

                {auxTotalByCat.length > 0 && <h5>Aux Assignment Amount</h5>}
                  <Table className={classes.table} size="small" aria-label="a dense table">

                    <TableBody>
                    {auxTotalByCat.map((row, i) => (
                        <TableRow key={'l'+i}>
                        <TableCell component="th" scope="row">
                            {row.type}
                        </TableCell>
                        <TableCell align="right">{row.count}</TableCell>
                        <TableCell align="left">@ ${row.amount}</TableCell>
                        <TableCell align="left">= ${row.sum}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                  
              </div>
              {/* <div className='daily-detail col-12 col-md-6'>
                  <h5>Lead Referee Credentials</h5>
                  
                  {credentials.map((row, i)=>(
                      <div row={'a'+i}>
                          {row.ref_cred}: {row.count}
                      </div>
                  ))}

              </div>
              <div className='daily-detail col-12 col-md-6'>
                  <h5>Supporting Referee Credentials</h5>
                  {secondCredentials.map((row, i)=>(
                      <div row={'a'+i}>
                          
                          {row.second_cred}: {row.count}
                      </div>
                  ))}
              </div> */}
              </div>
              </div>

    )
}
export default TotalDisplay

const useStyles = makeStyles({
    table: {
    //   minWidth: 650,
    },
  });