import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
// import { updateTournament } from '../../ducks/reducer'

import { Switch, Route } from 'react-router-dom'

import RefDeskDashboard from '../components/Dashboard/RefDeskDashboard'
import HomePage from '../components/Dashboard/HomePage'
import ResetPassword from '../components/Login/ResetPassword'
import BoardDisplay from '../components/RefDesk/CheckinCheckout/BoardDisplay'
import Procedures from '../components/RefDesk/Procedures'
import Login from '../components/Login/Login'
import Register from '../components/Login/Register'
import BoardListFromParams from '../components/RefDesk/CheckinCheckout/BoardListFromParams'
import OrganizationListingPage from '../components/TournamentListing/OrganizationListingPage'
import VolunteerSignupDashboard from '../components/Dashboard/VolunteerSignupDashboard';
import RefereeSignup from '../components/Schedule/Signup/RefereeSignup';
import RefereeSignupApproval from '../components/Schedule/Signup/RefereeSignupApproval'
import LineSignup from '../components/Schedule/Signup/LineSignup';
import VolunteerSignupNew from '../components/Schedule/Signup/SignupNew/VolunteerSignupNew';
import PrivacyPolicy from '../components/Footer/PrivacyPolicy';
import TermsOfService from '../components/Footer/TermsOfService';
import VolunteerOpportunities from '../components/TournamentListing/VolunteerOpportunities';
import UserRoutes from './UserRoutes';
import ResourcesHomepage from '../components/Resources/ResourcesHomepage';
import LinePublicDisplay from '../components/LineJudge/LinePublicDisplay';
import AppListings from '../components/TournamentListing/OrganizationPages/APPListings';
import PPAListings from '../components/TournamentListing/OrganizationPages/PPAListings';
import QuizRoutes from './QuizRoutes';
import ScoreRoutes from './ScoreRoutes'
import RefereeMessageBoard from '../components/Email/RefereeMessageBoard';
import MessageListReferee from '../components/MessageBoard/MessageListReferee';
import EventGuideRoutes from './EventGuideRoutes';
import PublicDisplayMaster from '../components/EventGuide/PublicDisplay/PublicDisplayMaster';
import MapOfTournaments from '../components/TournamentListing/MapOfTournaments';
import CalendarOfTournaments from '../components/TournamentListing/CalendarOfTournaments';
import ShiftsByTime from '../components/Schedule/Signup/SignupNew/ShiftsByTime';
import FacilityListings from '../components/TournamentListing/OrganizationPages/FacilityListings';
import RoutesLoggedIn from './RoutesLoggedIn';
import ViewCustomHomepage from '../components/CustomHomepage/ViewCustomHomepage';
import ContactCoordinator from '../components/Schedule/Signup/ContactCoordinator';
import AppSignupReport from '../components/Reports/SignupReportByOrg/AppSignupReport';

export default function Routes(props){
    const tid = useSelector(state => state.tid)
    const user_id = useSelector(state => state.user_id)
    const status = useSelector(state => state.status)
    const referee_approval_required = useSelector(state => state.referee_approval_required)

    const dispatch = useDispatch();

    // useEffect(
    //     () => { checkParams() }, [])

    //     const checkParams = () => {
    //         if(window.location.href.includes('/signup/')){
    //             if(props.match.params.tid !== tid && props.match.params.tid !== null){
    //                 getTournament()
    //             }
    //         }
    //     }

    //     const getTournament = () => {
    //         if (props.match.params.tid && props.match.params.tid > 0) {
    //             axios.post(`/api/t/getTournament/${props.match.params.tid}`, { ref_id }).then(
    //                 res => {

    //                     dispatch(updateTournament(res.data))
    //                 }
    //             )
    //         }
    //         else { setLoadingTournament(false) }
    //     }

    

    return (
        <div className="Routes">

            <Switch>
            {user_id === 55 || user_id < 3 ?<Route path='/print/marsha' component={AppSignupReport}/>:''}

            <Route path='/tournamentcalendar' component={CalendarOfTournaments}/>
            <Route path='/tournamentmap' component={MapOfTournaments}/>
            <Route path='/tournament/:tid' component={PublicDisplayMaster}/>
            <Route path='/app' component={AppListings}/>
            <Route path='/ppa' component={PPAListings}/>
            <Route path='/quiz' component={QuizRoutes} />
            <Route path='/legacy' component={FacilityListings}/>

            <Route path='/public/lineboard/:tid' component={LinePublicDisplay} />

            <Route path='/resources' component={ResourcesHomepage} />
            <Route path='/question' component={UserRoutes} />
            <Route path='/tournaments' component={VolunteerOpportunities} />
            <Route path='/signup/contactcoordinator' component={ContactCoordinator} />
            {status === 'active' && tid &&
                <Route path='/signup/volunteer/bytime' component={ShiftsByTime} />}
            {status === 'active' && tid > 0 ?
                <Route path='/signup/volunteer' component={VolunteerSignupNew} /> : ''}
                
            {status === 'active' && tid > 0 ?
                <Route path='/signup/linejudge' component={LineSignup} /> : ''}
            <Route path='/signup/refereemessageboard/:tid' component={RefereeMessageBoard}/>
            {status === 'active' && tid && 
                    <Route path='/signup/referee/messageboard' component={MessageListReferee} />}

            {status === 'active' && tid > 0 ?
                referee_approval_required ?
                    <Route path='/signup/referee' component={RefereeSignupApproval} /> :
                    <Route path='/signup/referee' component={RefereeSignup} /> : ''}

            <Route path='/signup/:tid' component={VolunteerSignupDashboard} />
            <Route path='/tools/privacy' component={PrivacyPolicy} />
            <Route path='/tools/termsofservice' component={TermsOfService} />
            <Route path='/register/:name' component={OrganizationListingPage} />
            <Route path='/public/board/:tid' component={BoardListFromParams} />
            <Route path='/procedures/:tid' component={Procedures} />
            <Route path='/public/board/:tid' component={BoardListFromParams} />
            <Route path='/public/boardDisplay/:tid' component={BoardDisplay} />
            <Route path='/reset/:token' component={ResetPassword} />
            <Route path='/register' component={Register} />
            <Route path='/login' component={Login} />
            <Route path='/score' component={ScoreRoutes} />
            <Route path='/eventGuide' component={EventGuideRoutes} />
            

                
            

                {/* <Route path = '/:url' component={ViewCustomHomepage}/> */}
            {user_id > 0 ? 
            <Route path='/' component={RoutesLoggedIn} />:
            <Route path='/' component={HomePage} />}

            </Switch>
        </div>
    );
}

