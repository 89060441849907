import React from 'react';
import 'moment-timezone';
import '../RefDesk.css'
import AssignMagnet from './AssignMagnet'

const FinishMatchStarter = props => {
    
    return (
        <div className='finishMatch' align="center"
        >
            <div className='match-starter-header'>Match Starter</div>
                
            <AssignMagnet
                referee={props.assignedRef}
                matchStarter={props.matchStarter}
                court={props.court}
                show={false} />
                <button id='finish-button'
                    // type="submit"
                    onClick={props.finishMatchStarter}>Finish- Maintain Queue Position</button>
                <button onClick={props.finishMatchStarterBottomQueue}>Finish- End of Queue</button>



            <p className='pointer' onClick={props.clear}><i className="fas fa-trash" ></i>Cancel</p>




        </div >


    )
}



export default FinishMatchStarter;