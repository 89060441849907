import React, { Component } from 'react'
import axios from 'axios'
import io from 'socket.io-client'
import { connect } from "react-redux"
import { updateTournament, updateQueue, toggleText } from '../../ducks/reducer'
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper'


import 'moment-timezone';
import './BoardView.css'
import Assign from '../RefDesk/Assign'
import ClipboardNeeded from './ClipboardNeeded'
import RefManager from './RefManager'
import RefInfoModal from './RefInfoModal'
import ShiftCheckedIn from './BoardView/ShiftCheckedIn'
import FinishMatch from './BoardView/FinishMatch'
import Reassign from './BoardView/Reassign'
import RefereeMagnet from './BoardView/RefereeMagnet'
import SmallMagnet from './BoardView/SmallMagnet'
import BoardCommunication from '../Communication/BoardCommunication'
import CheckinShowingShifts from './CheckinCheckout/CheckinShowingShifts'
import LineJudgeManager from '../LineJudge/LineJudgeManager'
import DisplayNotifications from '../DayOfComponents/DisplayNotifications'

class BoardView extends Component {
    constructor() {
        super()
        this.state = {
            leftSide: [],
            rightSide: [],
            refList: [],
            sideQueue: [],
            court: '',
            assignedRef: { ref_id: '', first: '', last: '' },
            medal: '',
            forfeit: false,
            finishMatch: { ref_id: '', first: '', last: '', court_num: '', id: '', sysid: '', tiebreaker: false, medal: '' },
            clipboardNeeded: [],
            showReassign: false,
            showRefInfo: false,
            refInfo: {},
            resetStartTime: false,
            queueCount: 0,
            sysid: '',
            tiebreaker: false,
            tieSysid: '',
            matchId: null,
            pastCourt: null,
            loader: true,
            showCommunicationModal: false,
            showSchedule: false,
            showRefManager: false,
            showLineJudge: false
        }
    }

    componentDidMount() {
        this.getRightSide()
        this.getLeftSide()
        this.getAvailableRefs()
        this.getSideQueue()
        this.getClipboardNeeded()
        this.setSocketListeners()
        this.updateState()
    }



    componentWillUnmount() {
        this.socket.disconnect()
    }

    setSocketListeners = () => {
        this.socket = io()

        this.socket.emit('joinRoom', `board:${this.props.tid}`)

        this.socket.on(`board`, results => {
            if (results.msg === 'text') {
                this.props.toggleText({ toggleText: results.data })
            }
            else if (results.msg === 'assign') {
                this.getAssign()
            }
            else if (results.msg === 'finish') {
                this.getFinish()
            }
            else if (results.msg === 'swapQueue') {
                this.getAvailableRefs()
                this.getSideQueue()
            }
            else if (results.msg === 'getCourtsOnly') {
                this.getRightSide()
                this.getLeftSide()
            }
            else {
                this.getAll()
            }
        })
    }


    updateState = () => this.setState({ queueCount: this.props.queue })

    updateBoard = () => {
        this.getAll()
        this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'all' })
    }

    getAll = () => {
        this.getRightSide()
        this.getLeftSide()
        this.getSideQueue()
        this.getAvailableRefs()
        this.getClipboardNeeded()
    }

    getAssign = () => {
        this.getRightSide()
        this.getLeftSide()
        this.getSideQueue()
        this.getAvailableRefs()
    }

    getFinish = () => {
        this.getRightSide()
        this.getLeftSide()
        this.getAvailableRefs()
        this.getClipboardNeeded()
    }

    getLeftSide = async () => {
        this.setState({ loader: true })

        await axios.get(`/api/getLeftSide/${this.props.tid}`).then(

            res => {
                this.setState({ leftSide: res.data, loader: false })
            }
        )
    }

    getRightSide = async () => {

        await axios.get(`/api/getRightSide/${this.props.tid}`).then(

            res => {
                this.setState({ rightSide: res.data })
            }
        )
    }
    getAvailableRefs = () => {
        axios.get(`/api/getAvailableRefs/${this.props.tid}`).then(

            res => {
                this.setState({ refList: res.data })
            }
        )
    }

    getSideQueue = () => {
        axios.get(`/api/getSideQueue/${this.props.tid}`).then(

            res => {
                this.setState({ sideQueue: res.data })
            }
        )
    }



    getClipboardNeeded = async () => {

        await axios.get(`/api/getClipboardNeeded/${this.props.tid}`).then(

            res => {
                this.setState({ clipboardNeeded: res.data })
            }
        )
    }

    selectCourt = async (court) => {
        await this.setState({ court: court, finishMatch: '' })
        if (this.state.assignedRef && this.state.court) { this.assign() }
    }

    selectRef = async (ref) => {
        await this.setState({ assignedRef: ref })
        if (this.state.assignedRef && this.state.court) { this.assign() }

    }

    assign = async () => {

        if (this.state.assignedRef.ref_id > 0 && this.state.court && !this.state.showReassign) {
            const court = this.state.court
            const ref_id = this.state.assignedRef.ref_id
            const medal = this.state.medal
            const sysid = this.state.sysid

            this.setState({ loader: true, court: '', assignedRef: {}, medal: '', sysid: '' })
            await axios.post(`/api/assignCourt/${this.props.tid}`, {
                court_num: court,
                ref_id: ref_id,
                medal: medal,
                sysid: sysid,
                loader: false
            }).then(res => {
                if(res.data.message){
                    toast.error(`CONFLICT- Referee is on court ${res.data.court_num}, Select new referee`)
                }else{
                this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'assign' })
                this.getAssign()
                }
            })
        }
    }

    queueCount = () => {
        axios.put(`/api/queueCount/${this.props.tid}`, { queue: this.state.queueCount }).then(
            res => {
                this.props.updateQueue({ queueCount: res.data.queue })
                this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'queue', data: res.data.queue })
            }
        )
    }

    reAssign = () => {
        this.clearAssign()
        this.setState({
            showReassign: true,
            matchId: this.state.finishMatch.current_match,
            court: this.state.finishMatch.court_num,
            assignedRef: this.state.finishMatch,
            pastCourt: this.state.finishMatch.court_num
        })
    }

    reassignRefereeOrCourt = () => {
        this.setState({ loader: true })
        const pastRef = this.state.finishMatch.ref_id
        const pastCourt = this.state.pastCourt
        const courtNum = this.state.court

        axios.put(`/api/reassignReferee/${this.state.matchId}`, { ref_id: this.state.assignedRef.ref_id, past: pastRef, tid: this.props.tid, court_num: courtNum, past_court: pastCourt, medal: this.state.medal }).then(
            res => {
                this.clearState()
                this.getAssign()
                this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'assign' })

            }
        )
    }


    clearAssign = () => this.setState({
        court: '',
        assignedRef: {},
        sysid: '',
        tiebreaker: false,
        tieSysid: '',
        matchId: null,
        pastCourt: null,
        loader: false
    })

    handleFinishMatch = async () => {
        if (this.state.tiebreaker) {
            this.tiebreaker(this.state.finishMatch)
        }
        this.setState({ loader: true })
        axios.put(`/api/finishMatch/${this.props.tid}`, { court_num: this.state.finishMatch.court_num, id: this.state.finishMatch.current_match, ref_id: this.state.finishMatch.ref_id, medal: this.state.medal, forfeit: this.state.forfeit, sysid: this.state.sysid }).then(
            res => {
                if (res.data === 'Try Again') {
                    toast.warning(`Issue with finish match, please make sure referee is now in the queue.  Reset referee if missing.`)
                }
                else {
                    this.getFinish()
                    this.clearState()
                    this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'finish' })
                }
            }
        )
    }

    tiebreaker = (match) => {
        let { ref_id, start_time, court_num } = match
        axios.post(`/api/tiebreakermatch/${this.props.tid}`, {
            ref_id, start_time, court_num, sysid: this.state.tieSysid
        }).then(
            res => {

            }
        )
    }

    clipboardNeeded = async () => {
        axios.put(`/api/clipboardNeeded/${this.props.tid}`, { id: this.state.finishMatch.current_match }).then(
            res => {
                this.setState({
                    finishMatch: {}
                })
            }
        ).then(this.updateBoard)
    }

    clipboardReceived = async (id, ref_id) => {
        axios.put(`/api/clipboardReceived/${id}`, { ref_id: ref_id, tid: this.props.tid }).then(
            res => {
                this.updateBoard()
            }
        )
    }

    swapQueue = (ref_id) => {
        this.setState({ loader: true })
        axios.put(`/api/swapQueue/${ref_id}`, { tid: this.props.tid }).then(
            res => {
                this.getAvailableRefs()
                this.getSideQueue()
                this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'swapQueue' })
                this.setState({ loader: false })
            }
        )
    }

    handleSysid = async () => {
        this.setState({ loader: true })
        axios.put(`/api/addSysid/${this.state.finishMatch.current_match}`, { sysid: this.state.sysid }).then(
            res => {
                this.getLeftSide()
                this.getRightSide()
                this.setState({ sysid: '', loader: false })
            }
        )
    }

    saveChanges = async () => {
        this.setState({ loader: true })
        axios.put(`/api/saveCourtChanges/${this.state.finishMatch.current_match}`, { sysid: this.state.sysid, medal: this.state.medal, tid: this.props.tid }).then(
            res => {
                this.getLeftSide()
                this.getRightSide()
                this.setState({ sysid: '', medal: '', finishMatch: {}, loader: false })
                this.socket.emit('board', { room: `board:${this.props.tid}`, msg: 'getCourtsOnly' })

            }
        )
    }

    openRefInfo = (ref) => {
        this.setState({
            showRefInfo: true,
            refInfo: ref,
            assignedRef: { ref_id: '', first: '', last: '' }
        })
    }

    closeRefInfo = () => {
        this.setState({
            showRefInfo: false,
            assignedRef: { ref_id: '', first: '', last: '' }
        })
    }

    closeSchedule = () => {
        this.getAll()
        this.setState({ showSchedule: false })
    }

    showRefManager = async () => {
        await this.setState({ showRefManager: !this.state.showRefManager })
        window.scrollTo(0, 1000)
    }

    refInfoModal = () => { }

    verifyResetStartTime = () => {
        this.setState({ resetStartTime: !this.state.resetStartTime })
    }

    resetStartTime = () => {
        this.setState({ loader: true })

        axios.put(`/api/resetStartTime/${this.props.tid}`).then(

            res => {
                this.setState({ resetStartTime: false, loader: false })
            }
        )
    }

    showCommunicationModal = () => this.setState({ showCommunicationModal: !this.state.showCommunicationModal })

    clearState = () => this.setState({
        court: '',
        assignedRef: { ref_id: '', first: '', last: '' },
        medal: '',
        finishMatch: {},
        showReassign: false,
        sysid: '',
        tiebreaker: false,
        tieSysid: '',
        matchId: null,
        pastCourt: null,
        medal: '',
        loader: false
    })

    statusHasBeenUpdated = (ref_id) => {
        this.getRightSide()
        this.getLeftSide()
        this.getSideQueue()
        this.getAvailableRefs()
        this.socket.emit('board', { room: `board:${this.props.tid}`, msg:'updateReferee', referee: {ref_id} })
    }


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    render() {
        console.log(this.state.finishMatch)

        const mappedLeftSide = this.state.leftSide.map(court => {

            return (
                <div key={court.court_num} className='col-6 court-box-boardView'
                    style={{ cursor: 'pointer' }}
                    onClick={court.ref_id ? e => this.setState({ finishMatch: court, court: '', medal: court.medal, sysid: court.sysid }) : () => this.selectCourt(court.court_num)} >
                    <div className='col-4 court-num'>
                        <div className={this.state.court === court.court_num ? 'selectedCourt' : 'court-name'}>
                            <div className={court.court_num.length > 2 ? 'shrink-court-num' : ''}>{court.court_num}</div>
                        </div>
                        {court.medal === 'Bronze' ?
                            <div className='board-medal'>
                                <i className="fas fa-medal"></i>
                                B
                                </div> :
                            court.medal === 'Gold' ?
                                <div className='board-medal'>
                                    <i className="fas fa-medal"></i>
                                    G
                                </div> : ''}
                        <div className='board-start-time'>
                            {/* {court.ref_id ?
                                    
                                    <Moment  format="h:mm" 
                                        local>
                                        {court.start_time}
                                    </Moment>
                                    : ''} */}
                        </div>

                    </div>
                    <div className={
                        court.credentials_verified === 'Certified' ? 'certified-board col-8 ref-name' : court.credentials_verified === 'Level 2' ? 'level2-board col-8 ref-name' : court.credentials_verified === 'Level 1' ? 'level1-board col-8 ref-name' :
                            this.state.court === court.court_num ? 'selectedCourt' :
                                court.ref_id ? 'col-8 ref-name volunteer-board' :
                                    'col-8 ref-name'}>

                        {court.last_match ? <span className='last_match_icon'>LM</span> : ''}
                        {court.first ?
                            <div><p >{court.first} {court.last}</p> <i className="fas fa-info-circle boardView-refInfo" onClick={() => this.openRefInfo(court)}></i>
                            </div> : ''}

                    </div>
                </div>
            )
        })

        const mappedRightSide = this.state.rightSide.map(court => {
            return (
                <div key={court.court_num} className='col-6 court-box-boardView'
                    style={{ cursor: 'pointer' }}
                    onClick={court.ref_id ? e => this.setState({ finishMatch: court, medal: court.medal, court: '', sysid: court.sysid }) : () => this.selectCourt(court.court_num)} >
                    <div className='col-4 court-num'>
                        <div className={this.state.court === court.court_num ? 'selectedCourt' : 'court-name'}>
                            <div className={court.court_num.length > 2 ? 'shrink-court-num' : ''}>{court.court_num}</div>
                        </div>

                        {court.medal === 'Bronze' ?
                            <div className='board-medal'>
                                <i className="fas fa-medal"></i>
                                B
                                </div> :
                            court.medal === 'Gold' ?
                                <div className='board-medal'>
                                    <i className="fas fa-medal"></i>
                                    G
                                </div> : ''}
                        <div className='board-start-time'>
                            {/* {court.ref_id ?
                                    
                                    <Moment  format="h:mm" 
                                        local>
                                        {court.start_time}
                                    </Moment>
                                    : ''} */}
                        </div>
                    </div>
                    <div className={
                        court.credentials_verified === 'Certified' ? 'certified-board col-8 ref-name' : court.credentials_verified === 'Level 2' ? 'level2-board col-8 ref-name' : court.credentials_verified === 'Level 1' ? 'level1-board col-8 ref-name' :
                            court.ref_id ? 'col-8 ref-name volunteer-board' :
                                'col-8 ref-name'}>

                        {court.last_match ? <span className='last_match_icon'>LM</span> : ''}
                        {court.first ?
                            <div><p >{court.first} {court.last}</p> <i className="fas fa-info-circle boardView-refInfo" onClick={() => this.openRefInfo(court)}></i>
                            </div> : ''}

                    </div>
                </div >
            )
        })

        const mappedRefs = this.state.refList.map((referee, i) => {

            return (
                <div key={referee.ref_id} className={this.state.assignedRef.ref_id === referee.ref_id ? 'selectedMagnet' : ''}>

                    <RefereeMagnet
                        key={referee.ref_id}
                        referee={referee}
                        selectRef={this.selectRef}
                        swapQueue={this.swapQueue}
                        zone={this.props.zone}
                        openRefInfo={this.openRefInfo} />
                </div>

            )
        })

        const mappedSideQueue = this.state.sideQueue.map((ref, i) => {

            return (
                <div className={this.state.assignedRef.ref_id === ref.ref_id ? 'selectedMagnet' : ''}>
                    <SmallMagnet
                        referee={ref}
                        selectRef={this.selectRef}
                        swapQueue={this.swapQueue}
                        zone={this.props.zone}
                        openRefInfo={this.openRefInfo} />
                </div>
            )
        })

        return (
            <div className='container-fluid' >

                <div className='row'>
                    <div className='col-12 tips' style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div>
                            <legend>
                                Self Checkout available if queue is greater than
                            <input
                                    type="integer"
                                    name="queueCount"
                                    value={this.state.queueCount}
                                    onChange={this.handleChange}
                                    style={{ width: '3em' }}
                                />
                                <button onClick={this.queueCount}>Save</button>
                            </legend>
                        </div>
                        <div>
                            <button onClick={this.verifyResetStartTime}>Reset all court start times</button>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-2'>
                        <button onClick={() => this.setState({ showLineJudge: true })}>Assign Line Judge Group</button>
                    </div>
                    <div className='col-8'>
                        <p>Assign court - click select referee then court number. </p>
                        <p>Assign New Referee to match or Finish Match - click on court and Click Finish</p>
                    </div>
                    <div className='col-2'>
                        {/* <button onClick={this.showCommunicationModal}>Send Message</button> */}
                    </div>

                    <div className='col-12 board' >


                        <div className='col-3 court-list'>
                            {mappedLeftSide}
                        </div>
                        <div className='col-3 '>
                            {this.state.loader ?
                                <img id='loader' width="100" src={require('../../img/15.gif')} alt='players' /> : ''}
                            <div style={{ paddingBottom: '10px' }}>
                                <Paper elevation={3}>

                                    <DisplayNotifications />

                                </Paper>
                            </div>
                            <div className='assign-box'>

                                {(this.state.court || this.state.assignedRef.ref_id > 0) && !this.state.showReassign ?
                                    <Assign
                                        assign={this.assign}
                                        assignedRef={this.state.assignedRef}
                                        clearAssign={this.clearAssign}
                                        court={this.state.court}
                                        handleChange={this.handleChange}
                                        medal={this.state.medal}
                                        sysid={this.state.sysid}
                                    /> : ''}
                                {this.state.finishMatch.first && !this.state.showReassign ?
                                    < FinishMatch
                                        clearState={this.clearState}
                                        clipboardNeeded={this.clipboardNeeded}
                                        court={this.state.finishMatch}
                                        handleChange={this.handleChange}
                                        handleFinishMatch={this.handleFinishMatch}
                                        handleSysid={this.handleSysid}
                                        forfeit={this.state.forfeit}
                                        medal={this.state.medal}
                                        reAssign={this.reAssign}
                                        saveChanges={this.saveChanges}
                                        sysid={this.state.sysid}
                                        tiebreaker={this.state.tiebreaker}
                                        tieSysid={this.state.tieSysid}
                                    />
                                    : ''}
                                {this.state.showReassign ?
                                    <Reassign
                                        assignedRef={this.state.assignedRef}
                                        clearState={this.clearState}
                                        court={this.state.court}
                                        handleChange={this.handleSysid}
                                        reassignRefereeOrCourt={this.reassignRefereeOrCourt} />
                                    : ''}

                            </div>
                            <Paper elevation={3}>
                                <div className='board-available-refs'>
                                    <h3 className='queue-name'>Queue ({this.state.refList.length})</h3>
                                    <div className='board-ref-list' style={{ padding: '10px ' }}>
                                        {mappedRefs}

                                    </div>

                                </div>
                            </Paper>
                        </div>

                        <div className='col-3 court-list'>
                            {mappedRightSide}

                        </div>
                        <div className='col-3' >

                            <Paper elevation={3}>
                                <div>
                                    <div className='queue-name' style={{ marginBottom: '10px' }}>
                                        <h3 >Aux Queue ({this.state.sideQueue.length})</h3>
                                        <span ><small>Example: waiting for a match or available if needed.</small></span>
                                    </div>
                                    <div className='' style={{ paddingBottom: '10px' }}>

                                        {mappedSideQueue}

                                    </div>
                                </div>
                            </Paper>
                            <Paper elevation={3}>
                                <div className='' style={{ paddingBottom: '10px', marginTop: '20px' }}>
                                    <div className='queue-name'>
                                        <h4 >OnDeck </h4>
                                        <div>Referees who have checked-In</div>
                                    </div>
                                    <ShiftCheckedIn
                                        updateBoard={this.updateBoard} />
                                </div>
                            </Paper>
                        </div>

                    </div>
                    <Modal open={this.state.resetStartTime} center showCloseIcon={true} onClose={this.verifyResetStartTime} closeIconSize={20} closeOnEsc={true} >
                        <div>
                            <p>Are you sure you want to re-set all match start times to now?

                            This will update all matches that are currently on court.  The queue will not be affected.
                                        </p>
                            <button onClick={this.resetStartTime}>Confirm Re-set Start Times</button>
                        </div>
                    </Modal>

                    <Modal open={this.state.showRefInfo} center showCloseIcon={true} onClose={this.closeRefInfo} closeIconSize={20} closeOnEsc={true} >
                        <div>

                            <RefInfoModal
                                refInfo={this.state.refInfo}
                                closeModal={this.closeRefInfo}
                                tid={this.props.tid}
                                statusHasBeenUpdated={this.statusHasBeenUpdated} />
                        </div>
                    </Modal>
                    <Modal open={this.state.showLineJudge} center showCloseIcon={true} onClose={() => this.setState({ showLineJudge: false })} closeOnEsc={true} >
                        <div>

                            <LineJudgeManager />
                        </div>
                    </Modal>


                </div>
                <div className='row'>
                    <div className='col-6'>
                        {this.state.clipboardNeeded.length > 0 ?
                            <ClipboardNeeded
                                clipboardNeeded={this.state.clipboardNeeded}
                                clipboardReceived={this.clipboardReceived} /> : ''}
                    </div>


                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='lineBreak'>
                            <h4>Referee Manager</h4>
                        </div>
                        <button className='refManager-button' onClick={this.showRefManager}>

                            {this.state.showRefManager ? 'Close Referee Manager' : 'Referee Manager'}</button>
                        <button className='refManager-button' onClick={() => this.setState({ showSchedule: true })}>Show Shifts- List</button>
                        <Link to={`/desk/scheduleTable`} >
                            <button className='refManager-button'>Show Shifts- Table </button>
                        </Link>
                        {this.state.showRefManager ?
                            <RefManager
                                getAvailableRefs={this.getAvailableRefs} /> : ''}
                    </div>

                </div>

                <Modal open={this.state.showCommunicationModal} center showCloseIcon={true} onClose={this.showCommunicationModal} closeIconSize={20} closeOnEsc={true} >
                    <div>

                        <BoardCommunication />
                    </div>
                </Modal>
                <Modal open={this.state.showSchedule} center showCloseIcon={true} onClose={this.closeSchedule} closeIconSize={20} closeOnEsc={true} >
                    <div>
                        <CheckinShowingShifts
                            closeSchedule={this.closeSchedule} />
                    </div>
                </Modal>


            </div >
        )
    }
}
const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name,
        zone: reduxState.zone,
        text: reduxState.text,
        queue: reduxState.queue
    }
}


export default connect(mapStateToProps, { updateTournament, updateQueue, toggleText })(BoardView)