import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import './Score.css'


const PlayerList = props => {
    const [players, setPlayers] = useState([])
    const [nameSearch, setNameSearch] = useState('')

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getPlayers() }, [])

    const getPlayers = () => {
        axios.get(`/api/getPlayers/${tid}`).then(
            res => {
                setPlayers(res.data)
            }
        )
    }


    const mappedPlayers = players
        .filter(obj => obj.name.toUpperCase().includes(nameSearch.toUpperCase()))
        .map(p => {

            return (
                <div className='' key={'p' + p.pid} style={{ textAlign: 'left' }}>
                    <button onClick={() => props.select(p)}>Select</button>
                    {p.name}
                </div>
            )
        })


    return (
        <div>
            <div>
                <input type="text"
                    value={nameSearch}
                    placeholder='Name ...'
                    onChange={e => setNameSearch(e.target.value)} />
                <button onClick={() => setNameSearch('')}>Clear</button>
            </div>
            <div style={{ textAlign: 'left' }}>
                <button style={{ background: 'red' }} onClick={() => props.select({})}>Leave Blank/ Skip Player</button>

            </div>
            <div className='PlayerList'>
                {mappedPlayers}
            </div>

        </div>
    )
}
export default PlayerList