import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import QuizHome from '../components/Resources/RulesQuiz/QuizHome'

const QuizRoutes = () => {
    const user_id = useSelector(state => state.user_id)

    return (
        <Switch>
            <Route path='/quiz' component={QuizHome} />
        </Switch>
    )
}
export default QuizRoutes 