import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import { toast } from 'react-toastify';



const PlayerContactRow = props => {
    const [players, setPlayers] = useState([])
    const [nameSearch, setNameSearch] = useState('')
    const [newPhone, setNewPhone] = useState('')
    const [saved, setSaved] = useState(false)

    const tid = useSelector(state => state.tid)

    let { pid, name, phone } = props.player
    useEffect(
        () => { handlePhoneProps() }, [phone])

    const savePlayerPhone = (num) => {
        if (phone != newPhone) {
            axios.put(`/api/score/savePlayersPhone/`, { pid, phone: num }).then(
                res => {
                    toast.success('saved')
                }
            )
        }
    }

    const handlePhoneProps = () => {
        setNewPhone(phone)
    }

    const handlePhone = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setNewPhone(onlyNums)
            setSaved(false)
        } else if (onlyNums.length === 10) {
            savePlayerPhone(onlyNums)
            setNewPhone(onlyNums)
        }
    }


    return (
        <TableRow style={{ background: 'white' }}>
            <TableCell component="th" scope="row">
                {name}
            </TableCell>
            <TableCell align="right">
                1+ <input type="text"
                    value={newPhone}
                    onChange={handlePhone} />
            </TableCell>
        </TableRow>
    )
}
export default PlayerContactRow