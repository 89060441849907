import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import axios from 'axios'
import GetDailyTotals from './GetDailyTotals';
import './DailyTotals.css'
import GetTournamentTotals from './GetTournamentTotals';

const DailyRecapMaster = (props) => {
    const [days, setDays] = useState([])
     const tid = useSelector(state => state.tid)
     const name = useSelector(state => state.name)


    useEffect(() => {
        getDays()
    }, [])


    const getDays = () => {
        axios.get(`/api/getTournamentDaysReferee/${tid}`).then(res => {
            setDays(res.data)
        })
    }
    
    return(
        <div className='container'>
            <h2>Daily Referee Recap</h2>
            <p>{name}</p>
            <div className='row'>
                <div className='col-12'>
                <h3>Tournament Totals</h3>
                <GetTournamentTotals />
                </div>

            </div>
            <div className='row'>
            <div className='col-12'>
            <h3>Daily Totals</h3>
            {days
            .sort((a, b) => a.start_time.localeCompare(b.start_time))
            .map((day, i)=>
            <GetDailyTotals
                key={i}
                day={day.start_time}
                display={day.day} />)}
</div>
</div>
        </div>
    )
}
export default DailyRecapMaster