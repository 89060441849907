import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import Paper from '@material-ui/core/Paper';
import Login from '../../../Login/Login'
import Modal from 'react-responsive-modal';
import VolunteerWaiver from './VolunteerWaiver'


const MappedShiftPublic = props => {
    const [show, setShow] = useState(false)
    const [showLogin, setShowLogin] = useState(false)
    const [showWaiver, setShowWaiver] = useState(false)

    let {shift, searchFirst, searchLast} = props
    const timeZone = useSelector(state => state.time_zone)

    const ref_id = useSelector(state => state.ref_id)
    const tid = useSelector(state => state.tid)

    const handleShow = () => setShow(show => !show)

    useEffect(
        () => { handleHideAll() }, [props.collapse])

    useEffect(
        () => { handleShowAll() }, [props.showAll])

    const handleShowAll = () => setShow(props.showAll)
    const handleHideAll = () => setShow(props.collapse)

    const assignRef = async () => {
        if (!ref_id) { setShowLogin(true)}
        else if (shift.signature_required && !shift.signed){
            setShowWaiver(true)
        } else {
            setShowLogin(false)
            axios.put(`/api/putVolunteerInShift/${shift.sid}`, { tid, ref_id, waitlist:false}).then(
                res => {
                    if(res.data.conflict){
                        toast.error(`CONFLICT - ${res.data.conflict.name} ${res.data.conflict.start} - ${res.data.conflict.end}`)
                    }
                    else if (res.data.length > 0){
                        toast.error(res.data )
                    }else {
                    props.get()
                    }
                }
            ).catch(error => {
                if (error.response) {
                    toast.warning('System Error')
                    
                }
            })
        }
    }

    const addWaitList = async () => {
        if (!ref_id) { setShowLogin(true)}
        else if (shift.signature_required && !shift.signed){
            setShowWaiver(true)
        } else {
            setShowLogin(false)
            axios.put(`/api/putVolunteerInShift/${shift.sid}`, { tid, ref_id, waitlist: true }).then(
                res => {
                    if(res.data.conflict){
                        toast.error(`CONFLICT - ${res.data.conflict.name} ${res.data.conflict.start} - ${res.data.conflict.end}`)
                    }else {
                    props.get()
                    }
                }
            ).catch(error => {
                if (error.response) {
                    toast.warning('System Error')
                }
            })
        }
    }

    const removeRef = (shift_id) => {
        axios.put(`/api/removeRefTime/${shift_id}`, { tid }).then(
            res => {
                props.get()
            }
        )
    }

    const handleShowLogin = () => setShowLogin(false)
    const handleCloseWaiver = () => {
        
    }

    const mappedCaptains = shift.captains
                    .map((c, i) => {
                        return (
                            <div 
                            key={'c'+i}
                            className='captain-name'
                            >
                                {c.first} {c.last}
                         </div>
                        )
                    })
                const mappedSpot = shift.signup.map((spot, i) => {

                    return (
                        <div style={{ display: 'flex' }}
                    key={'pp:' + i}>
                    <div className=''>{++i}.</div>

                    <div className=''>
                        {spot.last}, {spot.first}
                    </div>
                    {spot.ref_id === ref_id ?
                        <button><i className="fas fa-user-minus" onClick={() => removeRef(spot.shift_id)}></i></button> : ''}
                </div>
                    )
                })
                const mappedWaitlist = shift.waitlist
                    // .filter(obj => obj.first.toUpperCase().includes(searchFirst.toUpperCase()))
                    // .filter(obj => obj.last.toUpperCase().includes(searchLast.toUpperCase()))
                    .map((spot, i) => {

                        return (
                            <div style={{ display: 'flex' }}
                    key={'pp:' + i}>
                    <div className=''>{++i}.</div>

                    <div className=''>
                        {spot.last}, {spot.first}
                    </div>
                    {spot.ref_id === ref_id ?
                        <button><i className="fas fa-user-minus" onClick={() => removeRef(spot.shift_id)}></i></button> : ''}
                </div>
                        )
                    })

return(
    <Paper>
    <div style={{ background: 'white', margin: '10px 0 20px 0', padding: '0 0 10px 0', borderTop: `2px solid ${shift.color} || #009CAE`  }}>
    
        <div className='shift-title-public'>
                <div>
                {show ?
                <i className="fas fa-chevron-up" onClick={handleShow}></i>:
                <i className="fas fa-chevron-down" onClick={handleShow}></i>    
            }
                </div>
                <div>
            <h5>{shift.category}: {shift.name}</h5>
            <div>
            {shift.start_time ?
                <Moment format="ddd MM/DD h:mm a" tz={timeZone}>
                    {shift.start_time}
                </Moment>:''}
                {shift.end_time ?
                <>
                {` to `}
                <Moment format="h:mm a" tz={timeZone}>
                    {shift.end_time}
                </Moment>
                </>:''}
            </div>
            
            </div>
            <div className='shift-status'>
                { shift.registered > 0 ?
                <>
                    Registered <i className="far fa-edit pointer" onClick={handleShow}></i></>:
                    shift.registered_waitlist > 0 ?
                    <>
                    Waitlist <i className="far fa-edit pointer" onClick={handleShow}></i></> :
                (shift.count - shift.spots) >= 0 ?
                    (shift.wait_count - shift.wait_spots) >= 0 ?
                    'Full':
                    'Waitlist Only':

                    <button onClick={assignRef}>Register - {shift.spots - shift.count} needed</button>}
            </div>


        </div>
        {show ?
        <div style={{padding: '0 10px'}}>
            <div className='category-description'>{shift.category_description}</div>
            <div className='shift-description'>{shift.shift_description}</div>
        <div >
            {shift.captains.length > 0 ?
                < div className='captains-display'>
                    <div className='captains'>
                        {shift.captains.length > 1 ?
                            `Shift Captains:` : 'Shift Captain:'}
                            
                    </div>
                    {mappedCaptains}
                </div>
                : ''}

        </div>
        <div className='row'>
            <div className='col-12 col-md-6'>
                <div className='row'>
                    <div className='col-12'>
                        <h5>Spots: {shift.count}/{shift.spots}</h5>
                    </div>


                </div>
                <div >

                    <div className='registered-spots'>
                        {mappedSpot}

 
                        {(shift.spots - shift.count) > 0 ?
                            <button onClick={assignRef}>add</button> : ''}
                    </div>
                </div>
            </div>
            {shift.wait_spots ?
                <div className='col-12 col-md-6'>

                    <div className='row'>
                        <div className='col-12'>
                            <h5>Waitlist: {shift.wait_count}/ {shift.wait_spots}</h5>
                        </div>

                    </div>
                    <div style={{ background: 'white' }}>
                        <div className='registered-spots'>
                            {mappedWaitlist}

                            {(shift.wait_spots - shift.wait_count) > 0 ?
                                <button onClick={addWaitList}>add</button> : ''}
                        </div>
                    </div>
                    
                </div> : ''}
                
        </div>
    </div>:''}

    <Modal open={showLogin} center showCloseIcon={true} onClose={handleShowLogin} closeIconSize={20} closeOnEsc={true} >
                            <Login
                                showLogin={handleShowLogin} />

                        </Modal>
    <Modal open={showWaiver} center showCloseIcon={true} onClose={()=>setShowWaiver(false)} closeIconSize={20} closeOnEsc={true} >
        <VolunteerWaiver show={showWaiver} get={props.get} close={()=>setShowWaiver(false)}/>

    </Modal>
    </div>
    </Paper>

)
}
export default MappedShiftPublic