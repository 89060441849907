import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SendEmail from '../Communication/SendEmail'
import SendText from '../Communication/SendText'
import { useSelector } from 'react-redux'

const VolunteerCheckInList = props => {
    const [name, setName] = useState('')
    const [category, setCategory] = useState('')
    const [shift, setShift] = useState('')
    const [timeFilter, setTimeFilter] = useState('')
    const [selected, setSelected] = useState([])
    const [email, setEmail] = useState(false)
    const [text, setText] = useState(false)
    const [showAll, setShowAll] = useState(true)
    const [filter, setFilter] = useState('all')
    const [credFilter, setCredFilter] = useState('')

    const classes = useStyles()
    const organization = useSelector(state => state.organization)


    const select = (row) => {
      if (selected.some(item => row.ref_id === item.ref_id)) {
          let index = selected.map(function (e) { return e.ref_id; }).indexOf(row.ref_id);
          remove(index)
      } else {
          add(row)
      }
  }

  const handleSelectAll = () => {
    if(selected.length === 0){
      let all = props.names
      .filter(obj => obj.credentials.toUpperCase().includes(credFilter.toUpperCase()))

      .filter(obj => obj ? obj.name.toUpperCase().includes(name.toUpperCase()):'')
      .filter(filter === 'none' ? obj => obj.shift.length === 0:
              filter === 'more' ? obj => obj.shift.length > 0:obj => obj.shift.length >= 0)
      setSelected(all)
    }else {
      setSelected([])
    }
  }
  const add = (row) => {
      let list = selected.concat(row)
      setSelected(list)
  }

  const remove = (i) => {
      let list = [...selected]
      list.splice(i, 1)
      setSelected(list)
  }

  const clearState = () => {
    setName('')
    setCategory('')
    setShift('')
    setTimeFilter('')
    setSelected([])
    setEmail(false)
    setText(false)
    setFilter('all')
    setCredFilter('')
  }

  const clearFilters = () => {
    setName('')
    setCategory('')
    setShift('')
    setTimeFilter('')
    setFilter('all')
    setCredFilter('')
  }

  

  function Row(props) {
    const { row } = props;
    let {credentials} = props.row
    const [open, setOpen] = React.useState(true);
    const classes = useRowStyles();

    React.useEffect(
        () => { handleShowAll() }, [showAll])

        const handleShowAll = () => {
            setOpen(showAll)
        }
    
  
    return (
      <React.Fragment>
        
        <TableRow className={selected.some(item => row.ref_id === item.ref_id)? classes.selected : 
        row.shift.length === 0 ? 'background-orange':
        classes.row}
        style={{}}
        >
        <TableCell 
          align="left" 
          className={classes.checkbox}
          onClick={()=>select(row)}
          >
                      <input type="checkbox"
                        readOnly
                        checked={selected.some(item => row.ref_id === item.ref_id)}
                        
                        />

          </TableCell>
          <TableCell
       
               style={{width: '15px'}}
               className={credentials === 'Certified' ? 'ref-list certified' : credentials === 'Level 2' ? 'ref-list level2' : credentials === 'Level 1' ? 'ref-list level1' :

                'ref-list Volunteer'}>
                  {organization === 'ppa' && ``}
                {credentials === 'Certified' ? 'C' :
                    credentials === 'Level 2' ? '2' : credentials === 'Level 1' ? '1' : 'N'}


          </TableCell>
          <TableCell 
          align="left"
          className={classes.name} 
          onClick={()=>select(row)}
          >
           
               {props.order}. {row.name}
          </TableCell>
          <TableCell 
          align="left"
          className={classes.contact} >
          {row.phone.length > 3 ? row.phone:''}
          </TableCell>
          <TableCell 
          align="left"
          className={classes.contact} >
          {row.alt_phone.length > 3 ? row.alt_phone :''}
          </TableCell>
          <TableCell 
          align="left"
          className={classes.contact} >
          {row.email}
          </TableCell>
          <TableCell>
            {row.shift.length}
            <IconButton  size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          

        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>

                <Table size="small" aria-label="names">

                  <TableBody>
                    {row.shift
                    .filter(obj => obj.category.toUpperCase().includes(category.toUpperCase()))
                    .filter(obj => obj.shift_name.toUpperCase().includes(shift.toUpperCase()))
                    .filter(obj => obj.start_time.toUpperCase().includes(timeFilter.toUpperCase()))
                    .map((s, i) => (
                      <TableRow key={i} 
                      
                      >
    
                    <TableCell align="left" className={classes.category}>{s.category}</TableCell>
                    <TableCell align="left" className={classes.shiftName}>{s.waitlist && 'WAITLIST '}{s.shift_name}</TableCell>
                    <TableCell align="left">
                        {s.start_time ?
                        `${s.start_time} to ${s.end_time}` :''}
                      </TableCell>
                  </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );

  }

    return(
      <div>
        <button
                            className='select-button'
                            onClick={handleSelectAll}>Select All</button>
                        <button
                            className='select-button'
                            onClick={() => setSelected([])}>Clear Selection</button>
                            <button onClick={()=>setEmail(email => !email)}><i className="far fa-envelope"></i> Email Selected</button>

                            <button onClick={()=>setText(text => !text)}>
                              <i className="fas fa-sms"></i> Text Selected</button>
          {email ?
          <SendEmail 
            selected={selected}
            category={props.category}
            clearState={clearState}/>:''}
            {text ?
          <SendText 
            selected={selected}
            category={props.category}
            clearState={clearState}/>:''}
            <div>
Search By Name
    <input type="text"
        value={name}
        onChange={e=>setName(e.target.value)}/>
        
Search By Credential
    <input type="text"
        value={credFilter}
        onChange={e=>setCredFilter(e.target.value)}/>
        <br/>
        <br/>

Search By Category
<input type="text"
        value={category}
        onChange={e=>setCategory(e.target.value)}/>
        <br/>
Search By Shift
<input type="text"
        value={shift}
        onChange={e=>setShift(e.target.value)}/>
        Search By Start Time
<input type="text"
        value={timeFilter}
        onChange={e=>setTimeFilter(e.target.value)}/>

            </div>
            <div>
              <button onClick={()=>setFilter('none')}>No Shifts</button>
              <button onClick={()=>setFilter('more')}>1+ shifts</button>
              <button onClick={clearFilters}>Clear Filters</button>
            </div>
            <button className='button-none' style={{float: 'right'}} onClick={()=>setShowAll(showAll => !showAll)}>{showAll ? 'Collapse All':'Show All Schedules'}</button>
      
        <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>

          {props.names
            .filter(obj => obj.name.toUpperCase().includes(name.toUpperCase()))
            .filter(obj => obj.credentials.toUpperCase().includes(credFilter.toUpperCase()))
            .filter(filter === 'none' ? obj => obj.shift.length === 0:
              filter === 'more' ? obj => obj.shift.length > 0:obj => obj.shift.length >= 0)
            

            .map((row, i) => (
                <Row key={'r'+i} row={row} order={i+1}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>

    )
}
export default VolunteerCheckInList

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    selected: {
        background: "#D2D4F0"
    },
    
    
  });

  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset'
        
      },
      
    },
    row: {
      background: '#009CAE',
    },
    checkbox:{
      cursor:'pointer'
    },
    category: {
      maxWidth: '200px',
      width: '200px',
      minWidth: '200px',
      overflow: 'none'
    },
    shiftName: {
      maxWidth: '200px',
      width: '200px',
      minWidth: '200px',
      overflow: 'none'
    },
    name: {
        textAlign: 'left',
        fontSize: '1.5em',
        cursor:'pointer'
    },
    contact: {
      textAlign: 'left',
  },
    selected: {
        background: "#D2D4F0",
        borderBottom: '2px solid #333'
    },
  });