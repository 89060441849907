import React from 'react'

const ClipboardNeeded = (props) => {
    const mappedCourts = props.clipboardNeeded.map(court => {
        return (
            <div key={court.court_num} className='col-3 court-box-boardView'
                onClick={() => props.clipboardReceived(court.id, court.ref_id)} >
                <div className='col-4 court-num-clipboard'>
                    <h2>{court.court_num}</h2>
                </div>
                <div className='col-8 ref-name'>
                    <p>{court.first} {court.last}</p>
                </div>
            </div>
        )
    })

    return (
        <div className='row'>
            <div className='ClipboardsNeeded col-12'>
                <div className='col-12'>
                    <h3>Clipboards Needed</h3>
                    <p>When clipboard is received click on court number to remove from list</p>
                </div>
                <div className='col-12 clipboard-list'>
                    {mappedCourts}
                </div>
            </div>


        </div>
    )
}
export default ClipboardNeeded