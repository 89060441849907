import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Venues from './Venues'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'


const PublicDisplayMaster = props => {
    const [tournament, setTournament] = useState('')
    const [list, setList] = useState([])
    const [vid, setVid] = useState(0)

    useEffect(
        () => { get() }, [props.match.params.tid])
        useEffect(
            () => { getTournamentName() }, [props.match.params.tid])

    const get = () => {
        if(!props.match.params.tid)return
        axios.get(`/api/eventGuide/getGuide/${props.match.params.tid}`).then(res => {
            setList(res.data)
            if(res.data.length === 1){
                setVid(res.data[0].vid)
            }
        })
    }
    const getTournamentName = () => {
        axios.get(`/api/getTournamentName/${props.match.params.tid}`).then(
            res => {
                setTournament(res.data.name)
            }
        )
    }
    const handleSelectVenue = (e) => {
        if(e === vid){
        setVid(0)
        }else {
            setVid(e) 
        }
    }

    return(
        <Container maxWidth="xs">
        <Paper style={{padding: '20px'}}>
                <h3 className='pointer'>{tournament}</h3>
                <div>Below you will find all the tournament information in one easy place.</div>
            {list.map((venue, i)=>(
                <Venues key={'v'+i} venue={venue} select={handleSelectVenue} vid={vid}/>
            ))}

        </Paper>
        </Container>
    )


}
export default PublicDisplayMaster