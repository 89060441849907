import React from 'react'

const ExplainQrGuide = (props) => {

    return(
        <div className='container'>
            <div className='row'>
            <div className='col-12 col-sm-6 col-md-3'>
                <div style={{width: 'inherit'}}>
                <img style={{width: '100%'}}
                    src={'https://res.cloudinary.com/shonda/image/upload/v1629407478/tournament_guide_lhlzst.png'} alt='Tournament Guide' />
                    </div>
            </div>
            <div className='col-12 col-sm-6 col-md-9'>

           
            
            <h3>Tournament QR Code Guide</h3>
            <p>The best way to keep your players an spectators informed.</p>
            <p>The event guide provides you with an easy and efficient way of sharing tournament information.  Once setup you post one QR code around the venue.  Patrons scan the code which brings them to your tournament information.  In this area you can include venue information, parking, food, court assignments, player lookup, vendors, volunteer signup and much more.</p>
            <div>
                <h4>How it works</h4>
                <ul style={{textAlign: 'left'}}>
                    <li>
                    You setup venues and add custom buttons. Each button can be a link or include a photo and/ or text.
                    </li>
                    <li>
                        You will be provided with a custom QR code to post around the venue.
                    </li>
                    <li>
                        Participants scan the code and go directly to your tournament information.  
                    </li>
                    <li>
                        Information can be quickly updated.  Experiencing a weather delay?  Quickly update the guide to keep players informed.
                    </li>
                </ul>
                {props.show ?
                <div>
                </div>:
                <div>
                    <h4>Want to add this service to your tournament?</h4>
                    <p>Contact pickleballdesk@gmail.com</p>
                </div>}
                </div>

</div>
            </div>
        </div>
    )


}
export default ExplainQrGuide