import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import RefereeMatchListByTournament from './RefereeMatchListByTournament';

const MatchesByTournament = (props) => {
   const [list, setList] = useState([])
   const [tournament, setTournament] = useState({})
   const [showMore, setShowMore] = useState(false)

    const ref_id = useSelector(state => state.ref_id)

    useEffect(() => {
        getTournamentsForReferee()
    }, [ref_id])

    const getTournamentsForReferee = () => {
        axios.get(`/api/getTournamentsForReferee/${ref_id}`).then( 
            res => {
                setList(res.data)
            }
        )
    }
    const handleSelectTournament = (e) => {
        setTournament(list[e.target.value])
    }


    return (
        <div className='container'>
            <h2>Matches By Tournament</h2>
            <select onChange={handleSelectTournament} value={tournament.name}>
            <option key={'x'} value={null}>Select Tournament</option>
                {list.map((row, i) =>(
                    <option key={row.tid+i} value={i}>{row.start} - {row.name}</option>
                ))}

        </select>

        <h3>{tournament.name}</h3>
        {tournament.tid ?
        <>
         <div style={{background: 'white', overflow:'scroll', maxHeight: '250px'}} >
                                    <ReactQuill
                                        className='description'
                                        value={tournament.description}
                                        readOnly={true}
                                        theme={"bubble"}
                                    />
                                </div>
       
        <RefereeMatchListByTournament tid={tournament.tid} ref_id={ref_id}/>
        </>
        :null}
        
        </div>
    )
}

export default MatchesByTournament