import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTournament } from '../../ducks/reducer'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';


import Moment from 'react-moment';
import axios from 'axios'
import SignupVideo from '../utlities/SignUpVideo'
import ScheduleRecap from '../Schedule/ScheduleModal/ScheduleRecap'
import IndividualStatusUpdate from '../DayOfComponents/IndividualStatusUpdate'
import CourtMonitorDayOf from '../DayOfComponents/CourtMonitorDayOf'


const VolunteerSignupDashboard = props => {
    const [loadingTournament, setLoadingTournament] = useState(true)
    const [emailSchedule, setEmailSchedule] = useState(false)
    const [schedule, setSchedule] = useState([])
    const [tournament, setTournament] = useState([])
    const [started, setStarted] = useState(false)
    const [emailLoader, setEmailLoader] = useState(false)


    const name = useSelector(state => state.name)
    const ref_id = useSelector(state => state.ref_id)
    const tid = useSelector(state => state.tid)
    const status = useSelector(state => state.status)
    const start = useSelector(state => state.start)
    const end_date = useSelector(state => state.end_date)
    const location = useSelector(state => state.location)
    const referee = useSelector(state => state.referee)
    const line = useSelector(state => state.line)
    const volunteer = useSelector(state => state.volunteer)


    const dispatch = useDispatch();

    useEffect(
        () => { checkForProps() }, [])
    useEffect(
        () => { getMySchedule() }, [tournament])
    useEffect(
        () => { getMySchedule() }, [tid])
    useEffect(
        () => { getTournament() }, [ref_id])
    useEffect(
        () => { hasTournamentStarted() }, [ref_id])

    const checkForProps = () => {
        if (tid) {
            hasTournamentStarted()
            getMySchedule()
            setLoadingTournament(false)

        } else {
            hasTournamentStarted()
            getTournament()
        }
    }

    const getTournament = () => {
        if (props.match.params.tid && props.match.params.tid > 0) {
            axios.post(`/api/t/getTournament/${props.match.params.tid}`, { ref_id }).then(
                res => {
                    setTournament(res.data)
                    dispatch(updateTournament(res.data))
                    setLoadingTournament(false)
                }
            )
        }
        else { setLoadingTournament(false) }
    }
    const hasTournamentStarted = () => {
        if (props.match.params.tid && props.match.params.tid > 0) {
            axios.get(`/api/t/hasTournamentStarted/${props.match.params.tid}`, { ref_id }).then(
                res => {
                    setStarted(res.data)
                }
            )
        }
    }

    const getMySchedule = () => {
        if (ref_id > 0 && tid) {
            axios.post(`/api/getRefScheduleForTournament/${ref_id}`, { tid }).then(
                res => {
                    setSchedule(res.data)
                }
            )
        }
    }

    const handleEmailSchedule = () => {
        if (ref_id > 0 && tid) {
            axios.post(`/api/emailSchedule`, { tid, ref_id, author: ref_id }).then(
                res => {
                    setEmailSchedule(true)
                    toast.success(`Schedule Has Been Emailed`)
                }
            )
        }
    }

    const mappedSchedule = schedule
        .map((shift, i) => {
            return (
                <div key={'a' + i} style={{ textAlign: 'left', padding: '0 10px' }}>

                    <ScheduleRecap
                        shift={shift} />
                </div>
            )
        })
    return (
        <div className='container-fluid'>
            {tournament && status === 'active' ?

                <div className='row justify-content-center'>
                    <div className='col-12'>
                        <h5>Volunteer Opportunities For:</h5>
                        <h2>{name}</h2>
                        <div>
                            <Moment format="MM/DD/YYYY" >
                                {start}
                            </Moment> -
                        <Moment format="MM/DD/YYYY" >
                                {end_date}
                            </Moment>
                        </div>
                        <p>Location: {location}</p>

                    </div>
                    <div className='col-12 tips'>
                        <h5>Click on volunteer type to register for time spots.</h5>
                    </div>
                    <div className='col-12 volunteer-types'>
                        <div className='col-12 col-md-4 col-lg-3'>
                            <div style={{ background: 'white' }}>
                                <div className='my-schedule-title'>
                                    My Schedule
                                    
                                </div>
                                {!ref_id ? 'Login to see schedule':''}
                                {mappedSchedule}
                                {tid && ref_id ?
                                    !emailSchedule ?
                                        <button onClick={handleEmailSchedule}>Email Schedule</button> :
                                        <div style={{ color: 'red' }}>Email Sent</div> : ''
                                }
                            </div>
                        </div>
                        <div className='col-12 col-md-4 col-lg-3'>
                            <div className='admin-image-box'>
                                <Link to={`/signup/referee`} >
                                    <img className='admin-images'
                                        src={'https://res.cloudinary.com/shonda/image/upload/v1582498963/PickleballDesk/Referee_o31pb8.png'} alt='referee' />
                                </Link>
                            </div>
                            {started ?
                                <div>
                                    <Link to={`/public/board/${tid}`} target='_blank' rel="noopener noreferrer" >
                                        <div className='admin-quick-links'>
                                            <i className="fas fa-th-list" style={{ paddingLeft: '10px' }}></i>
                                    Referee Court Assignments
                                    </div>
                                    </Link>

                                </div>
                                :
                                <div className='admin-quick-links-inactive'>
                                    <i className="fas fa-th-list" style={{ paddingLeft: '10px' }}></i>
                                    Referee Court Assignments
                                    </div>}
                            {started && referee ?
                                <div>
                                    <div className='admin-quick-links'>
                                        Status Manager
                                         </div>
                                    <div className='individual-status'>
                                        <IndividualStatusUpdate />
                                    </div>
                                </div>
                                :
                                <div className='admin-quick-links-inactive'>
                                    {started ?
                                        !ref_id ? 'Login to see details':'Check In':'Check In   '}
                                </div>}

                        </div>
                        <div className='col-6 col-md-4 col-lg-3'>
                            <div className='admin-image-box'>
                                <Link to={`/signup/linejudge`} >
                                    <img className='admin-images'
                                        src={'https://res.cloudinary.com/shonda/image/upload/v1582498963/PickleballDesk/Line_Judge_vhfptx.png'} alt='line judge' />
                                </Link>
                            </div>
                            {started ?
                                <div>
                                    <Link to={`/public/lineboard/${tid}`} target='_blank' rel="noopener noreferrer" >
                                        <div className='admin-quick-links'>
                                            <i className="fas fa-th-list" style={{ paddingLeft: '10px' }}></i>
                                    Line Judge Assignments
                                    </div>
                                    </Link>

                                </div>
                                :
                                <div className='admin-quick-links-inactive'>
                                    <i className="fas fa-th-list" style={{ paddingLeft: '10px' }}></i>
                                    Line Judge Assignments
                                    </div>}
                        </div>
                        <div className='col-6 col-md-4 col-lg-3'>
                            <div className='admin-image-box'>
                                <Link to={`/signup/volunteer`} >
                                    <img className='admin-images'
                                        src={'https://res.cloudinary.com/shonda/image/upload/v1582498963/PickleballDesk/Volunteer_he87xg.png'} alt='line judge' />
                                </Link>
                            </div>
                            {started && <CourtMonitorDayOf started={started}/>}

                        </div>

                    </div>
                    <SignupVideo />
                </div>
                :

                loadingTournament ?
                    <div>
                        <h4>Gathering tournament information... </h4>
                    </div> :
                    <div>
                        <h4>Sorry this tournament is not open for registration.  </h4>
                    </div>
            }
        </div>

    )
}
export default VolunteerSignupDashboard