import React from 'react'
import { useSelector, useDispatch, } from 'react-redux'
import { clearUser, clearTournament } from '../../ducks/reducer';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import './Nav.css'
import LoginBar from '../Login/LoginBar'

const TopNav = (props) => {
    const user_id = useSelector(state => state.user_id)
    const name = useSelector(state => state.name)
    const first = useSelector(state => state.first)
    const user_name = useSelector(state => state.user_name)

    const dispatch = useDispatch();


    const logout = async () => {
        await axios.post('/auth/logout')
        dispatch(clearUser())
        dispatch(clearTournament())
        props.history.push('/')
    }


    return (
        <div className='col-12 Nav nav-name'
            style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}>

            <Link to={`/`}>
                <img className='desk-logo'
                    onClick={() => dispatch(clearTournament())}
                    style={{ width: '100%' }}
                    src={'https://res.cloudinary.com/shonda/image/upload/v1582412572/PickleballDesk/pickleball-desk-logo1_h2ufb0.png'} alt='logo' />
            </Link>

            {user_id ?
                <div className='nav-tournament-name'>
                    {name}
                </div>
                : ''}

            <div style={{ display: 'flex', 'padding': '0 20px', alignItems: 'center' }}>
                <div >
                    {user_id && !props.location.pathname.includes('/public') ?
                        <div>
                            Welcome {first}
                            {props.location.pathname.includes('/public') ? '' :
                                <Link to={`/profile/myProfile`} style={{ color: 'white' }}>

                                    <i id='profile' className="fas fa-user-circle"></i>
                                </Link>}



                        </div> : <LoginBar />}
                </div>
                <div>
                    {user_id && !props.location.pathname.includes('/public') ?
                        <div style={{ display: 'flex', 'padding': '0 20px', alignContent: 'center' }}>

                            <button className='' onClick={logout}>Logout</button>
                        </div> : ''}
                </div>
            </div>
        </div>

    )
}

export default withRouter((TopNav))