import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import QRPdf from '../components/EventGuide/PDFSigns/QRPdf'
import BingoItemList from '../components/Bingo/BingoAdmin/BingoItemList'
import MakeBingoCard from '../components/Bingo/BingoPlayer/MakeBingoCard'
import EventCheck from '../components/EventGuide/Venues/EventCheck'


const EventGuideRoutes = () => {
    const user_id = useSelector(state => state.user_id)
    const admin_type = useSelector(state => state.admin_type)

    return (
        <Switch>
            <Route path='/eventGuide/bingo/start/:tid' component={MakeBingoCard}/>
            <Route path='/eventGuide/bingoSetup' component={BingoItemList}/>
            <Route path='/eventGuide/poster' component={QRPdf}/>
            <Route path='/eventGuide' component={EventCheck}/>


        </Switch>
    )
}
export default EventGuideRoutes 