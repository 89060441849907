import React, { Component } from 'react'
import axios from 'axios'
import { connect } from "react-redux"
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import '../RefList/RefList.css'
import '../Schedule/RefereeSchedule.css'


class LineSchedule extends Component {
    constructor() {
        super()
        this.state = {
            schedule: [],
            templates: [],
            showTemplateList: false,
            firstSearch: '',
            lastSearch: '',
            nameSearch: '',
            categoryFilter: '',
            email: [],
            emailSubject: '',
            replyEmail: '',
            emailBody: '',
            text: [],
            textBody: '',
            saveTemplate: false,
            templateName: '',
            showEmail: false,
            showText: false
        }
    }

    componentDidMount() {
        this.getSchedule()
        this.setEmail()
        this.getTemplates()
    }

    getSchedule = () => {
        axios.get(`/api/getScheduleByLineJudge/${this.props.tid}`).then(
            res => {
                this.setState({ schedule: res.data })
            }
        )
    }

    setEmail = () => this.setState({ replyEmail: this.props.email })

    getTemplates = () => {
        axios.get(`/api/getTemplates/${this.props.ref_id}`).then(
            res => {
                this.setState({ templates: res.data })

            }
        )
    }

    clearSearch = () => this.setState({ firstSearch: '', lastSearch: '', nameSearch: '', categoryFilter: '' })

    emailAll = () => {
        let email = [...this.state.schedule]
        this.setState({ email: email })

    }

    textAll = () => {
        let text = [...this.state.schedule]
        this.setState({ text: text })

    }


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    handleEmail = (value) => {
        this.setState({ emailBody: value })
    }

    toggleShowTemplate = () => this.setState({ showTemplateList: !this.state.showTemplateList })


    selectTemplate = (t) => {
        if (t.type === 'text') {
            this.setState({
                showTemplateList: false,
                textBody: t.body

            })
        } else {
            this.setState({
                showTemplateList: false,
                emailSubject: t.subject,
                emailBody: t.body

            })
        }
    }

    addEmail = (ref) => {
        let email = this.state.email.concat(ref)
        this.setState({ email: email })
    }

    addText = (ref) => {
        let text = this.state.text.concat(ref)
        this.setState({ text: text })
    }

    removeEmail = (i) => {
        let email = [...this.state.email]
        email.splice(i, 1)
        this.setState({ email: email })
    }
    removeText = (i) => {
        let text = [...this.state.text]
        text.splice(i, 1)
        this.setState({ text: text })
    }


    sendEmail = () => {
        if (this.state.saveTemplate) { axios.post(`/api/saveTemplate`, { ref_id: this.props.ref_id, name: this.state.templateName, subject: this.state.emailSubject, body: this.state.emailBody, type: 'email' }).then(res => toast.success(`Template Saved`)) }
        axios.post(`/api/email`, { category: 'line', tournamentName: this.props.name, tid: this.props.tid, email: this.state.email, subject: this.state.emailSubject, reply: this.state.replyEmail, body: this.state.emailBody, author: this.props.ref_id }).then(
            res => {
                toast.success(`Email Queued`)
                this.setState({ email: [], emailSubject: '', replyEmail: '', emailBody: '' })
            }
        )
    }

    sendText = () => {
        if (this.state.saveTemplate) { axios.post(`/api/saveTemplate`, { ref_id: this.props.ref_id, name: this.state.templateName, subject: '', body: this.state.textBody, type: 'text' }).then(res => toast.success(`Template Saved`)) }
        axios.post(`/api/customText`, { text: this.state.text, textBody: this.state.textBody, tid: this.props.tid, type: 'line' }).then(
            res => {
                this.setState({ text: [], textBody: '' })
            }
        )
    }

    render() {

        const mappedSchedule = this.state.schedule
            .filter(obj => obj.first.toUpperCase().includes(this.state.firstSearch.toUpperCase()))
            .filter(obj => obj.last.toUpperCase().includes(this.state.lastSearch.toUpperCase()))


            .map(schedule => {
                const mappedShift = schedule.shift
                    .filter(obj => obj.name.toUpperCase().includes(this.state.nameSearch.toUpperCase()))
                    .filter(obj => obj.category.includes(this.state.categoryFilter))
                    .map(shift => {
                        return (
                            <div className='mappedShift col-12' key={shift.sid}>
                                <div className='col-4'>{shift.category} {shift.referee ? '' : shift.waitlist ? 'waitlist' : shift.line_judge ? 'line judge' : ''}</div>
                                <div className='col-8'>
                                    {shift.name}
                                </div>
                            </div>
                        )
                    })
                return (
                    <div className='mappedSchedule'>
                        <div className='scheduleHeader'>
                            <div style={{ display: 'flex' }}>
                                <div className='signup-credential-box'>

                                    <div style={{ margin: '3px', width: '1em' }} className={schedule.credentials === 'Certified' ? 'ref-list certified' : schedule.credentials === 'Level 2' ? 'ref-list level2' : schedule.credentials === 'Level 1' ? 'ref-list level1' :

                                        ''}>
                                        {schedule.credentials === 'Certified' ? 'C' :
                                            schedule.credentials === 'Level 2' ? '2' : schedule.credentials === 'Level 1' ? '1' : ''}
                                    </div>
                                </div>
                                <h5>{schedule.last}, {schedule.first}</h5>
                            </div>

                            <div>
                                {schedule.email ?
                                    <i className="far fa-envelope icon"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.addEmail(schedule)}></i> : <i className="far fa-envelope icon inactive" ></i>}
                                {schedule.phone && schedule.phone !== '0000000000' ?
                                    <i className="fas fa-sms icon"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.addText(schedule)}></i> :
                                    <i className="fas fa-sms icon inactive" ></i>}
                            </div>
                        </div>
                        {mappedShift}
                    </div>
                )
            })

        const mappedEmail = this.state.email.map((ref, i) => {
            return (
                <div key={i} >
                    {ref.last}, {ref.first} <i className="fas fa-minus-circle" onClick={() => this.removeEmail(i)}></i>
                </div>
            )
        })
        const mappedText = this.state.text.map((ref, i) => {
            return (
                <div key={i} >
                    {ref.last}, {ref.first} <i className="fas fa-minus-circle" onClick={() => this.removeText(i)}></i>
                </div>
            )
        })

        const mappedTemplates = this.state.templates.map(t => {
            return (
                <div key={t.etid}
                    onClick={() => this.selectTemplate(t)}
                    className='mappedTemplates'>

                    {t.type}: {t.name}

                </div >
            )
        })
        return (
            <div className='container' >
                <div className='RefereeSchedule col-12'>

                    <div className='row'>
                        <div className='col-12'>
                            <Link to={`/desk/scheduleSetup`} >
                                <button className='btn btn-primary'>Schedule Setup</button>
                            </Link>
                            <Link to={`/desk/editSchedule`} >
                                <button className='btn btn-primary'>Edit Schedule</button>
                            </Link>
                        </div>
                        <div className='col-12 col-md-6'>

                            <h4>Schedule By Line Judge</h4>
                            <div className='tips'>
                                <input
                                    type="text"
                                    placeholder='Search by Last Name'
                                    name="lastSearch"
                                    value={this.state.lastSearch}
                                    onChange={this.handleChange} />
                                <input
                                    type="text"
                                    placeholder='Search by First Name'
                                    name="firstSearch"
                                    value={this.state.firstSearch}
                                    onChange={this.handleChange} />

                                <input
                                    type="text"
                                    placeholder='Search by Shift Name'
                                    name="nameSearch"
                                    value={this.state.nameSearch}
                                    onChange={this.handleChange} />
                                <i className="fas fa-ban" onClick={this.clearSearch}></i>
                            </div>

                            <div className='RefSchedule-schedule'>{mappedSchedule}</div>
                        </div>

                        <div className='col-12 col-md-6 email'>
                            <h4>Send <button onClick={() => this.setState({ showEmail: !this.state.showEmail })}>Email</button>
                                <button onClick={() => this.setState({ showText: !this.state.showText })}>Text</button></h4>
                            <button onClick={this.toggleShowTemplate}>{this.state.showTemplateList ? 'Close Template List' : 'Create from Saved Template'}</button>
                            {this.state.showTemplateList ?
                                <div className='mappedTemplatesDiv'>{mappedTemplates}</div>
                                : ''}

                            <div className='row'>
                                {this.state.showText || this.state.text.length > 0 ?
                                    <div className='col-12 paper'>
                                        <h4>Text Line Judge</h4>
                                        <button onClick={this.textAll}>Add All Registered Line Judges to text</button>
                                        <div className='emailRecipients'>
                                            {mappedText}
                                        </div>


                                        <p>Text Message</p>

                                        <textarea
                                            type="text"
                                            placeholder='Text Message, limit 160 cha'
                                            name="textBody"
                                            maxLength='159'
                                            value={this.state.textBody}
                                            onChange={this.handleChange} />
                                        <p><small>*Include your name or tournament name. Limit 160 characters</small></p>
                                        <legend> Save text as template
                                    <input
                                                type="checkbox"
                                                placeholder='Save as template'
                                                name="saveTemplate"
                                                value={this.state.saveTemplate}
                                                onChange={() => this.setState({ saveTemplate: !this.state.saveTemplate })} />
                                            {this.state.saveTemplate ?
                                                <input
                                                    type="text"
                                                    placeholder='Template Name'
                                                    name="templateName"
                                                    value={this.state.templateName}
                                                    onChange={this.handleChange} /> : ''}
                                        </legend>
                                        <button onClick={this.sendText}>Send Text</button>
                                    </div> : ''}
                                {this.state.showEmail || this.state.email.length > 0 ?
                                    <div className='col-12 paper'>
                                        <h4>Email Line Judge</h4>
                                        <button onClick={this.emailAll}>Add All Registered Line Judges to email</button>
                                        <legend>To:</legend>
                                        <div className='emailRecipients'>
                                            {mappedEmail}
                                        </div>
                                        <legend>
                                            Email Subject
                                <input
                                                type="text"
                                                placeholder='Email Subject'
                                                name="emailSubject"
                                                value={this.state.emailSubject}
                                                onChange={this.handleChange} />
                                            <small>*Tournament Name will be added to the end of the subject</small></legend>
                                        <legend>
                                            reply to:
                                    <input
                                                type="text"
                                                placeholder='Reply Email'
                                                name="replyEmail"
                                                value={this.state.replyEmail}
                                                onChange={this.handleChange} />
                                        </legend>
                                        <ReactQuill
                                            style={{ background: 'white' }}
                                            name="emailBody"
                                            value={this.state.emailBody}
                                            onChange={this.handleEmail} />

                                        <legend> Save email as template
                                    <input
                                                type="checkbox"
                                                placeholder='Save as template'
                                                name="saveTemplate"
                                                value={this.state.saveTemplate}
                                                onChange={() => this.setState({ saveTemplate: !this.state.saveTemplate })} />
                                            {this.state.saveTemplate ?
                                                <input
                                                    type="text"
                                                    placeholder='Template Name'
                                                    name="templateName"
                                                    value={this.state.templateName}
                                                    onChange={this.handleChange} /> : ''}
                                        </legend>
                                        {/* <legend>Schedule will be added to the bottom of the email</legend> */}

                                        <button onClick={this.sendEmail}>Send Email</button>
                                        <div>Email communication can be used for this tournament.  It is against site policies to use communication to solicit for other tournaments or products. </div>
                                    </div> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name,
        email: reduxState.email,
        ref_id: reduxState.ref_id
    }
}
export default connect(
    mapStateToProps,
)(LineSchedule)