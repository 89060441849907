import React from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import TournamentStatus from '../RefDesk/modals/TournamentStatus'




const TournamentSettings = (props) => {

    const status = useSelector(state => state.status);
    const tid = useSelector(state => state.tid);

    return (
        <div className='container'>
            <div className='col-12'>


                <div className='row'>
                    <div className='col-12'>
                        <TournamentStatus />
                    </div>
                </div>
                <div className='line'></div>
                
                <div className='line'></div>

            </div>
        </div>


    )
}

export default withRouter(TournamentSettings);
