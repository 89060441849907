import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import MessageList  from './MessageList';
import AddMessage from './AddMessage'
import LinearProgress from '@material-ui/core/LinearProgress'


const MessageListRefereeAdmin = () => {
    const [list, setList] = useState([])
    const [loader, setLoader] = useState(true)
    const [notAuthorized, setNotAuthorized] = useState(false)
    const [showAdd, setShowAdd] = useState(false)

    const ref_id = useSelector(state => state.ref_id)
    const tid = useSelector(state => state.tid)

    useEffect(
        () => { get() }, [ref_id])


    const get = () => {
        if(!ref_id){return}
        axios.post(`/api/messageboard/getMessages`, { tid, ref_id, category:'referee' }).then(
            res => {
                setLoader(false)
                if(res.message === 'not registered'){
                    setNotAuthorized(true)
                }else {
                setList(res.data)}
            }
        ).catch(err => {
            toast.error('Issue Occurred- please try again')
        })
    }

    return(
        <div className='container-fluid'>
            <h3>Referee Message Board</h3>
            <p>The message board is a place to communicate with other referees.  An example of it's use would be to coordinate ride shares or housing.</p>
            <p>When posting your phone and email will be displayed for others to contact you.  The referee board is only viewable to registered volunteers for that specific tournament.</p>
            {loader ?
                    <LinearProgress color="secondary"/>:''}
            <MessageList list={list} admin={true} get={get}/>
            
            {notAuthorized && 
            <h3>You must be registered to see the message board.</h3>}
            
            {!ref_id && 
            <h3>Please log in to see the message board.</h3>}
            <button 
            style={{margin: '15px'}}
            onClick={()=>setShowAdd(!showAdd)}>Post to Message Board</button>
            {showAdd && <AddMessage get={get} tid={tid}/>}
        </div>
    )

}
export default MessageListRefereeAdmin