import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import AddShiftToCategory from './AddShiftToCategory'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import MassEditShifts from '../SchedulingUtilities/MassEditShifts'

const VolunteerShiftsByCaptain = props => {
    const [showEdit, setShowEdit] = useState(false)
    const [edit, setEdit] = useState(false)
    const [shift, setShift] = useState(undefined)
    const [selected, setSelected] = useState([])
    const [searchCategory, setSearchCategory] = useState('')
    const [searchTitle, setSearchTitle] = useState('')
    const [searchStart, setSearchStart] = useState('')
    const [searchEnd, setSearchEnd] = useState('')
    const [waitFilter, setWaitFilter] = useState(0)
    const [openSpotsFilter, setOpenSpotsFilter] = useState(0)


    let {shifts} = props
    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)


    useEffect(
        () => { props.get() }, [tid])

        const handleEditShift = (shift, edit) => {
          setEdit(edit)
            setShift(shift)
            setShowEdit(true)
            if(props.closeAddShift){
                props.closeAddShift()
            }
        }

        const select = (row) => {
          if (selected.some(item => row.sid === item.sid)) {
              let index = selected.map(function (e) { return e.sid; }).indexOf(row.sid);
              remove(index)
          } else {
              add(row)
          }
      }

      const add = (row) => {
        let list = selected.concat(row)
        setSelected(list)
    }

    const remove = (i) => {
        let list = [...selected]
        list.splice(i, 1)
        setSelected(list)
    }

    
    return(
        <div>
          {selected.length > 0 ?
          <MassEditShifts selected={selected} get={props.get}/>:''}
            {showEdit ?
            <AddShiftToCategory 
            get={props.get}
            getCategories={props.getCategories}
            categories={props.categories}
            close={()=>setShowEdit(false)}
            shift={shift}
            showEdit={showEdit}
            edit={edit}/>:''}
           
            <Paper>
            <TableContainer>
            <Table aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>Select</TableCell>
          <TableCell>Edit</TableCell>
          <TableCell align="left" onClick={props.sortReset} style={{cursor:'pointer'}}>
            <i className="fas fa-sort"></i>
              Category</TableCell>
            <TableCell>Title</TableCell>
            <TableCell align="left" onClick={props.sortByTime} style={{cursor:'pointer'}}>
            <i className="fas fa-sort"></i>
              Start</TableCell>
            <TableCell align="left">End</TableCell>
            <TableCell align="left">Spots</TableCell>
            <TableCell align="left">Waitlist Spots</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <input type="text" 
            value={searchCategory} 
            onChange={e=>setSearchCategory(e.target.value)}/>
          </TableCell>
            <TableCell>
              <input type="text" 
            value={searchTitle} 
            onChange={e=>setSearchTitle(e.target.value)}/>
            </TableCell>
            <TableCell>
            <input type="text" 
            value={searchStart} 
            onChange={e=>setSearchStart(e.target.value)}/>
          </TableCell>
            <TableCell align="left">
            <input type="text" 
            value={searchEnd} 
            onChange={e=>setSearchEnd(e.target.value)}/>
            </TableCell>
            <TableCell align="left">
              open spots >=
            <input type="text"
            placeholder='Greater or Equal to' 
            value={openSpotsFilter} 
            onChange={e=>setOpenSpotsFilter(e.target.value)}/>
            </TableCell>
            <TableCell align="left">
            waitlist >=
            <input type="text" 
            placeholder='Greater or Equal to' 
            value={waitFilter} 
            onChange={e=>setWaitFilter(e.target.value)}/>
            </TableCell>
          </TableRow>
          {shifts
          .filter(obj => obj.category ? obj.category.toUpperCase().includes(searchCategory.toUpperCase()) : '')
          .filter(obj => obj.name.toUpperCase().includes(searchTitle.toUpperCase()))
          .filter(obj => obj.start.toUpperCase().includes(searchStart.toUpperCase()))
          .filter(obj => obj.end.toUpperCase().includes(searchEnd.toUpperCase()))
          .filter(obj =>  Math.max(0, (obj.spots - +obj.spot_count))  >= openSpotsFilter)
          .filter(obj => Math.max(0, +obj.wait_count) >= waitFilter)
          .map((row, i) => (
            <TableRow key={i} style={{background: row.color}}>
              <TableCell onClick={() => select(row)}
                className={selected.some(item => row.sid === item.sid) ? 'selected-shift' : ''}>
                    {selected.some(item => row.sid === item.sid) ? <i className="far fa-check-square"></i> : <i className="far fa-square"></i>}
              </TableCell>
            <TableCell component="th" scope="row">
             <button onClick={()=>handleEditShift(row, true)}>edit</button>
             <button onClick={()=>handleEditShift(row, false)}>duplicate</button>
            </TableCell>
            <TableCell> {row.category}</TableCell>
            <TableCell> {row.name}</TableCell>
            <TableCell align="left">
            {row.start}
                </TableCell>
            <TableCell align="left">
            {row.end}
            </TableCell>
            <TableCell align="left">{`${row.spot_count} / ${row.spots}`}</TableCell>
            <TableCell align="left">{`${row.wait_count} / ${row.wait_spots}`}</TableCell>
            <TableCell align="left">
                

            </TableCell>
          </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Paper>

        </div>
    )
}
export default VolunteerShiftsByCaptain