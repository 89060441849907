import React, {useState, useEffect} from 'react'
import axios from 'axios'
import AddVenueItem from '../VenueItem/AddVenueItem'
import VenueItem from '../VenueItem/VenueItem'
import AddVenue from './AddVenue'

const Venue = (props) => {
    const [list, setList] = useState([])
    let {venue} = props

    useEffect(
        () => { get() }, [venue.vid])

    const get = () => {
        axios.get(`/api/eventGuide/getVenueDetails/${venue.vid}`).then(res => {
            setList(res.data)
        })
    }

    return(
        <div style={{border:`2px solid ${venue.color || 'black'}`, margin: '20px', }}>            
            <h3>{venue.name}</h3>
            <AddVenue vid={venue.vid} get={props.getVenues} venue={venue}/>
            <AddVenueItem vid={venue.vid} get={get}/>
            {list.map((row, i)=> (
                <VenueItem item={row} vid={row.vid} get={get} select={props.select}/>
            ))}
        </div>
    )


}
export default Venue