import React, { Component } from 'react';
import axios from 'axios'
import { connect } from "react-redux"
import Moment from 'react-moment';
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter'
import Modal from 'react-responsive-modal';
import 'moment-timezone';
import MatchCountLine from './MatchCountLine'

class PastMatchesLineJudge extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pastMatches: [],
            searchFirst: '',
            searchLast: '',
            searchCourt: '',
            searchDate: '',
            searchSysid: '',
            verifyDelete: false,
            match: {}
        }
    }

    componentDidMount() {
        this.getPastMatches()
    }

    getPastMatches = () => {
        axios.get(`/api/getPastMatchesLineJudge/${this.props.tid}`).then(

            res => {
                this.setState({ pastMatches: res.data })
            }
        )
    }

    verifyDelete = (match) => {
        if (match) {
            this.setState({ verifyDelete: !this.state.verifyDelete, match: match })
        } else {
            this.setState({ verifyDelete: !this.state.verifyDelete, match: {} })
        }
    }

    deleteMatch = () => {
        axios.put(`/api/deleteMatch/${this.state.match.id}`, { tid: this.props.tid }).then(

            res => {
                this.setState({ pastMatches: res.data, match: {}, verifyDelete: false })
            }
        )
    }


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    render() {

        const columns = [
            {
                Header: 'Court',
                accessor: 'court_num',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["court_num"] }),
                filterAll: true
            },
            {
                Header: 'SYSID',
                accessor: 'sysid',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["sysid"] }),
                filterAll: true,
                Cell: ({ value, row }) =>
                    (row._original.medal === 'Bronze' ? `${value}  Bronze` :
                        row._original.medal === 'Gold' ?
                            row._original.tiebreaker ? `${value} G-TB` : `${value}  Gold`
                            : `${value}`
                    )
            },
            {
                Header: 'Last',
                accessor: 'last',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["last"] }),
                filterAll: true,
            },
            {
                Header: 'First',
                accessor: 'first',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["first"] }),
                filterAll: true,
            },
            {
                Header: 'credentials',
                accessor: 'credentials',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["credentials"] }),
                filterAll: true

            },
            {
                Header: 'Date',
                accessor: 'start_time',
                Cell: ({ value, row }) =>
                    <Moment format="MM/DD" >
                        {value}
                    </Moment>
            },
            {
                Header: 'Start',
                accessor: 'start_time',
                Cell: ({ value, row }) =>
                    <Moment format="h:mm a" >
                        {value}
                    </Moment>
            },
            {
                Header: 'End',
                accessor: 'end_time',
                Cell: ({ value, row }) =>
                    <Moment format="h:mm a" >
                        {value}
                    </Moment>
            },
            {
                Header: 'Match Time',
                accessor: 'end_time',
                Cell: ({ value, row }) =>
                    <Moment duration={row._original.start_time} date={value} >
                    </Moment>
            },
            {
                Header: 'Delete Match',
                accessor: 'id',
                Cell: ({ value, row }) =>
                    <button onClick={() => this.verifyDelete(row._original)}>Delete</button>
            }
        ]




        return (
            <div className="container">
                <div className='col-12'>
                    <MatchCountLine />
                </div>
                <div className='RefCourtAssignments'>
                    <h2>Past Matches for {this.props.name}</h2>
                    <div className='tips'>*To filter matches use the search boxes under column title.</div>



                    <ReactTable
                        data={this.state.pastMatches}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                        className='ReactTable'
                        columns={columns}
                    />
                </div>
                <Modal open={this.state.verifyDelete} center showCloseIcon={true} onClose={this.verifyDelete} closeIconSize={20} closeOnEsc={true} >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <h4>Are you sure you want to delete this match?
                            You will not be able to recover this match.</h4>
                        <div >
                            Court: {this.state.match.court_num}
                            {this.state.match.medal === 'Bronze' ?
                                <div>
                                    <i className="fas fa-medal"></i>
                                    B
                                </div> :
                                this.state.match.medal === 'Gold' ?
                                    <div>
                                        <i className="fas fa-medal"></i>
                                        G
                                </div> : ''}
                        </div>
                        <div >
                            Name: {this.state.match.last}, {this.state.match.first}
                        </div>
                        <div >
                            <div>


                                <Moment format="MM/DD" >
                                    {this.state.match.start_time}
                                </Moment>
                            </div>



                        </div>
                        <div >
                            Start:
                            <Moment format="h:mm a" >
                                {this.state.match.start_time}
                            </Moment>

                        </div>
                        <div >
                            End:
                            <Moment format="h:mm a" >
                                {this.state.match.end_time}
                            </Moment>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                            <div>
                                Match Time:
                                <Moment duration={this.state.match.start_time} date={this.state.match.end_time} >

                                </Moment>
                            </div>


                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <button onClick={this.deleteMatch}>Confirm Delete</button>
                            <button onClick={this.verifyDelete}>Cancel</button>
                        </div>
                    </div>
                </Modal>
            </div >

        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name,
        zone: reduxState.zone
    }
}


export default connect(
    mapStateToProps,
)(PastMatchesLineJudge)