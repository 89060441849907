import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import AddShiftToCategory from './AddShiftToCategory'
import VolunteerShiftsByCaptain from './VolunteerShiftsByCaptain'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress'



const VolunteerCaptainScheduling = props => {
    const [shifts, setShifts] = useState([])
    const [shiftsOriginal, setShiftsOriginal ] = useState([])
    const [categories, setCategories] = useState([])
    const [addShift, setAddShift] = useState(false)
    const [addCategories, setAddCategories] = useState(false)
    const [loader, setLoader] = useState(false)
    const [cancel, setCancel] = useState(false)


    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)
    const volunteer = useSelector(state => state.admin_type)

    useEffect(
        () => { scrollToTop() 
            return () => {
                cancelRequests(); // trying to clear memory leak
              }
            }, [addShift])
    const cancelRequests = () => {
        setCancel(true)
    }

    const getShifts = () => {
        if(volunteer){
            getAllShifts()

        }
        else {
            getCaptainShifts()
        }
    }
    const getCaptainShifts = () => {
        setLoader(true)
        axios.post(`/api/volunteer/getVolunteerShiftsForCaptain`, { tid, ref_id }).then(
        res => {
            if(cancel){return}
            setLoader(false)
            setShifts(res.data)
            setShiftsOriginal(res.data)
        }
    )
    }
    const getAllShifts = () => {
        if(cancel){return}
        setLoader(true)
        axios.post(`/api/volunteer/getAllVolunteerShifts`, { tid, ref_id }).then(
        res => {
            setLoader(false)
            setShifts(res.data)
            setShiftsOriginal(res.data)
        }
    )
    }
    const getCategories = () => {
        axios.post(`/api/volunteer/getVolunteerCategoriesForCaptain`, { tid, ref_id }).then(
        res => {
            if(cancel){return}
            setCategories(res.data)
            if(res.data.length === 0){
                setAddCategories(true)
                setAddShift(false)
            }
        }
    )
    }

    const sortByTime = async () => {
        const copy = [...shiftsOriginal]
        await copy.sort((a,b) => {
            return new Date(a.start_time).getTime() - 
                new Date(b.start_time).getTime()
        });
        setShifts(copy)
      }

      const sortReset = () => {
        setShifts(shiftsOriginal)
      }

    const scrollToTop = () => window.scrollTo(0, 300)

    return(
        <div className='container-fluid'>
            
            {addShift ?
            <AddShiftToCategory 
            get={getShifts}
            getCategories={getCategories}
            categories={categories}
            sortByTime={sortByTime}
            sortReset={sortReset}
            close={()=>setAddShift(false)}/>:''}
            <h3>Volunteer Shifts</h3>
            {volunteer ?
            <p>You are a volunteer coordinator all shifts are shown</p>:
            <p>Showing all shifts you are a volunteer captain for</p>}
            <button onClick={()=>setAddShift(addShift => !addShift)}>Add Shift</button>
            {loader && <LinearProgress/>}
            <VolunteerShiftsByCaptain 
            get={getShifts}
            shifts={shifts}
            categories={categories}
            getCategories={getCategories}
            sortByTime={sortByTime}
            sortReset={sortReset}
            closeAddShift={()=>setAddShift(false)}/>

<Dialog onClose={()=>setAddCategories(false)} aria-labelledby="simple-dialog-title" open={addCategories}>
      <DialogTitle id="simple-dialog-title">Before you can make a shift you need to add at least one volunteer category</DialogTitle>
      <div>
      {volunteer ?
                <Link to={`/volunteer/categories`} >
                <button>Open Category Setup</button>
                </Link>:
                <p>Please have the volunteer coordinator make categories before you can make a shift</p>}
      </div>
      </Dialog>
        </div>
    )
}
export default VolunteerCaptainScheduling