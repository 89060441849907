import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddItem from './AddItem/AddItem'

const ItemList = () => {
    const [list, setList] = useState([])

  const classes = useStyles();


    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getItems() }, [tid])

    const getItems = () => {
        axios.get(`/api/payment/getPaymentItems/${tid}`).then(
            res => {
                setList(res.data)
            }
        )
    }

    return(
        <div className='container-fluid'>
          <h3>Payout Setup</h3> 
          <p>This page you can add all payouts.  Fill in what information you have as you can add information later.  For example you can set up the payouts for each event and add the player name later.  </p>
          <p>Remember to click 'save' or hit 'enter' to save.</p>

          <button onClick={getItems}>Sort by Event/ Place</button>

<TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell></TableCell>
            <TableCell>Event</TableCell>
            <TableCell>Place</TableCell>
            <TableCell>Payee</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Paid</TableCell>
            <TableCell>Save</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list
          .map((row, i) => (
            <AddItem 
                key={i}
                i={i}
                row={row}
                get={getItems}
                />
          ))}
          <AddItem 
        get={getItems}
        />   
        </TableBody>
      </Table>
    </TableContainer>                  

        </div>
    )
}
export default ItemList

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

