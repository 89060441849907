import React, { useState } from 'react'
import axios from 'axios'
import { Button, TextField, Typography } from '@material-ui/core';

const ForgotPassword = props => {
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const isValidEmailAddress = async () => {
        let valid = await !!email.match(/.+@.+/)
        setEmailError(!valid)
    }

    const sendEmail = () => {
        if (emailError || email.length < 3) {
            return
        } else {
            axios.post(`/auth/forgotPassword`, {
                email
            }).then(res => {
                if (res.data === 'email not in db') {
                    setErrorMessage('Sorry this email is not registered, please register for an account.')
                } else {
                    setErrorMessage('Recovery Email Sent')
                    setEmail('')
                }
            }).catch(err => {
            })
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', margin: '20px' }}>
            <Typography variant="h5" color="secondary">
                Forgot Password?
            </Typography>
            <Typography variant="body2" color="initial">
                We will send an email with a link to reset your password.
            </Typography>
            <TextField
                required
                error={emailError}
                label="email"
                helperText="email"
                onChange={e => setEmail(e.target.value)}
                onBlur={isValidEmailAddress}
            />
            <button
                onClick={sendEmail}>
                Reset Password
                </button>

            <Typography variant="body2" color="error">
                {errorMessage}
            </Typography>
        </div>

    )
}
export default ForgotPassword