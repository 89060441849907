import React, { Component } from 'react';
import 'moment-timezone';
import './RefDesk.css'

class Assign extends Component {
    

    render() {
        return (
            <div className='finishMatch col-12 ' align="center">
                <h4>Court Assigner</h4>
                <span><small>Assign court and click on refs name.</small></span>
                <div className='court-box-boardView col-10' align="center">
                    <div className='court-num col-4'>
                        {this.props.court}
                    </div>
                    <div className={
                        this.props.assignedRef.credentials === 'Certified' ? 'certified-board col-8 ref-name' : this.props.assignedRef.credentials === 'Level 2' ? 'level2-board col-8 ref-name' : this.props.assignedRef.credentials === 'Level 1' ? 'level1-board col-8 ref-name' :
                            'col-8 ref-name'}>

                        {this.props.assignedRef.last_match ? <span className='last_match_icon'>LM</span> : ''}
                        {this.props.assignedRef.first ?
                            <div>{this.props.assignedRef.first} {this.props.assignedRef.last}
                            </div> : ''}
                    </div>
                </div>



                <input
                    type="text"
                    placeholder='SYSID'
                    name="sysid"
                    ref={input => input && input.focus()}
                    value={this.props.sysid}
                    style={{ width: '100px' }}
                    onChange={this.props.handleChange} />
                <legend>Medal Match?</legend>
                <label>
                    <input type='radio'
                        checked={this.props.medal === ''}
                        name='medal'
                        value=''
                        onChange={this.props.handleChange}
                    /> No </label>
                <label>
                    <input type='radio'
                        value='Bronze'
                        checked={this.props.medal === 'Bronze'}
                        name='medal'
                        onChange={this.props.handleChange}
                    /> Bronze </label>
                <label>
                    <input type='radio'
                        checked={this.props.medal === 'Gold'}
                        name='medal'
                        value='Gold'
                        onChange={this.props.handleChange}
                    /> Gold </label>

                {this.props.court ?
                    this.props.assignedRef.ref_id > 0 ?
                        <p><button onClick={this.props.assign}>
                            Submit

                </button></p>
                        : '' : ''}
                <p><small>Text will be sent to ref when assigned</small></p>

                <p onClick={this.props.clearAssign}><i className="fas fa-trash" ></i>Clear</p>




            </div >


        )
    }
}


export default Assign;