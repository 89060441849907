import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Moment from 'react-moment'
import { makeStyles } from '@material-ui/core/styles'
import EmailBodyDetail from './EmailBodyDetail'


const EmailTable = props => {
    const [searchName, setSearchName] = useState('')
    const [searchAuthor, setSearchAuthor] = useState('')
    const [searchSubject, setSearchSubject] = useState('')

    const timeZone = useSelector(state => state.time_zone)

    let { list } = props

    const classes = useStyles()


    return (
        <>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Sent Time</TableCell>
                            <TableCell>{props.category}</TableCell>
                            <TableCell align="left">Author</TableCell>
                            <TableCell align="left">Subject</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Delivered</TableCell>
                            <TableCell align="left">Viewed</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                            </TableCell>
                            <TableCell>
                                <input type="text"
                                    onChange={e => setSearchName(e.target.value)}
                                    value={searchName} /></TableCell>
                            <TableCell align="left">
                                <input type="text"
                                    onChange={e => setSearchAuthor(e.target.value)}
                                    value={searchAuthor} />
                            </TableCell>
                            <TableCell align="left">
                            <input type="text"
                                    onChange={e => setSearchSubject(e.target.value)}
                                    value={searchSubject} />
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list
                        .filter(obj => obj.name ? obj.name.toUpperCase().includes(searchName.toUpperCase()) : '')
                            .filter(obj => obj.subject ? obj.subject.toUpperCase().includes(searchSubject.toUpperCase()) : '')
                            .filter(obj => obj.author ? obj.author.toUpperCase().includes(searchAuthor.toUpperCase()) : '')
                            .map((row, i) => (
                                <TableRow key={'m=e' + i}>
                                    <TableCell component="th" scope="row">
                                        <Moment format="MM/DD/YYYY h:mm a" tz={timeZone}>
                                            {row.time}
                                        </Moment>
                                    </TableCell>
                                    <TableCell >
                                        {row.name}
                                    </TableCell>
                                    <TableCell >
                                        {row.author}
                                    </TableCell>
                                    <TableCell >
                                        {row.subject}
                                    </TableCell>
                                    
                                    <TableCell align="left" className={classes.emailBody}>
                                        <EmailBodyDetail
                                        body={row.body}/>
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.delivered ?
                                            <Moment format="MM/DD/YYYY h:mm a" tz={timeZone}>
                                                {row.delivered}
                                            </Moment> : ''}</TableCell>
                                    <TableCell align="left">
                                        {row.viewed ?
                                            <Moment format="MM/DD/YYYY h:mm a" tz={timeZone}>
                                                {row.viewed}
                                            </Moment> : ''}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {list.length === 0 ? 'No emails found' : ''}
        </>
    )
}
export default EmailTable

const useStyles = makeStyles({
    emailBody: {
        minWidth: '300px',
    }
  });