import React, {useState} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';



export default function WithdrawFromAllShifts(props){
    const [message, setMessage] = useState('')
    const [show, setShow] = useState(false)

    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)
    const first = useSelector(state => state.first)
    const last = useSelector(state => state.last)
    const email = useSelector(state => state.email)


    const withdraw = () => {
        if (ref_id > 0) {
            axios.post(`/api/withdrawFromShifts/`, { tid, ref_id, first, last, email, message }).then(
                res => {
                    setMessage('')
                    setShow(false)
                    props.get()
                }
            )
        }
    }

    return(
        <div>
            <button onClick={()=>setShow(!show)}>Withdraw From All Shifts</button>
            <Dialog  aria-labelledby="simple-dialog-title" open={show} onClose={()=>setShow(!show)}>
            <div style={{padding: '20px'}}>
            <i className="far fa-window-close" style={{float:'right', cursor:'pointer', fontSize: '30px'}} onClick={()=>setShow(false)}></i>
            
            <h3>Withdraw from all shifts</h3>
            <p>We are sorry to hear you need to withdraw.  Would you like to include a message to the tournament so they know why you are withdrawing? </p>
            <textarea name="" id="" cols="30" rows="10"
                value={message}
                onChange={e=>setMessage(e.target.value)}
            ></textarea>
            <button onClick={withdraw}>Withdraw From All Shifts</button>

            <p>If you want to remove yourself from one shift, find the shift and click the remove icon.</p>
            </div>
            
            </Dialog>
        </div>
    )

}