import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import Modal from 'react-responsive-modal';
import TournamentSetup from '../Schedule/TournamentSetup'
import ApprovalRequests from '../Schedule/ApproveReferee/ApprovalRequests'
import TournamentSettings from './TournamentSettings'

import { makeStyles } from '@material-ui/core/styles';
import { ListSubheader, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    subheader: {
        background: '#6ECD62',
        fontSize: '1.5em',
        color: '#333333',
        lineHeight: '1',
        padding: '5px'
    }
}));


const RefereeDashboard = props => {
    const [showTournamentSetup, setShowTournamentSetup] = useState(false)
    const tid = useSelector(state => state.tid);
    const approve = useSelector(state => state.referee_approval_required)

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <div className='container'>
            <div className='col-12' >
                <div style={{ background: '#EA607B', padding: '10px' }}>
                    <h4>Attention USAP Sanctioned Tournaments</h4>
                    <p>If your tournament is USAP Sanctioned a match report must be provided to USA Pickleball.  Under the current guidelines the report comes from pt.com so it is important that the PT operator inputs referee names on the matches which require referees.</p>
                </div>

                <div className='category-name'>
                    <img style={{ height: '50px', marginRight: '20px' }}
                        src={'https://res.cloudinary.com/shonda/image/upload/v1589410272/PickleballDesk/Referee-coordinator_naj2a7.png'} alt='referee' />
                    Referee Coordinator Dashboard- <small>Links to all features.</small></div>

                <div className='col-12'>
                    <Link to={`/desk/setupChecklist`} >
                        <button className='register-button'
                            style={{ fontSize: '1.5em', margin: '15px' }}
                        >Setup Guide</button>
                    </Link>
                </div>
                <div className='row'>


                    {approve ?
                        <div className='col-12 col-md-6 col-lg-3'>
                            <Link to={`/desk/approvalManager`} >
                                <div className='category-header'>
                                    Referee Approval
                                </div>
                                <button><small>Click to Open Full Screen</small></button>
                            </Link>
                            <h5>Requests</h5>
                            <ApprovalRequests />
                        </div>
                        : ''}

                    <div className='col-12 col-md-6 col-lg-3'>
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <Link to={`/desk/editSchedule`} >
                                    <ListSubheader component="div" className={classes.subheader}>
                                        Scheduling
        </ListSubheader>
                                </Link>
                            }
                            className={classes.root}
                        >
                            <Link to={`/desk/scheduleSetup`} >
                                <ListItem button>
                                    <ListItemText primary="Shift Setup" secondary='When you are ready for volunteers to sign up, make sure to change tournament status to active' />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/editSchedule`} >
                                <ListItem button>
                                    <ListItemText primary="Assign and Remove Referees to Shifts" />
                                </ListItem>
                            </Link>
  
                            <Link to={`/desk/scheduleByRef`} >
                                <ListItem button>
                                    <ListItemText primary="Schedule by Referee" />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/printSchedule`} >
                                <ListItem button>
                                    <ListItemText primary="Print Schedule"
                                        secondary='showing shift name and volunteer signup' />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/schedulingLog`} >
                                <ListItem button>
                                    <ListItemText primary="Schedule Recap"
                                        secondary='List of shift signup or removal times for all shifts' />
                                </ListItem>
                            </Link>
                        </List>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3'>

                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <Link to={`/desk/scheduleByRef`} >
                                    <ListSubheader component="div" className={classes.subheader}>
                                        Communication
                                    </ListSubheader>
                                </Link>
                            }
                            className={classes.root}
                        >
                            <Link to={`/desk/scheduleByRef`} >
                                <ListItem button>
                                    <ListItemText primary="Email & Text Message" />
                                </ListItem>
                            </Link>
                            
                            <Link to={`/desk/emailtoshifts`} >
                                <ListItem button>
                                    <ListItemText primary="Email To Shifts" 
                                        secondary='select shift(s) to email'/>
                                </ListItem>
                            </Link>

                            <Link to={`/desk/emailapproved`} >
                                <ListItem button>
                                    <ListItemText primary="Email Approved Referees" />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/emaillog`} >
                                <ListItem button>
                                    <ListItemText primary="Email Log" />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/textlog`} >
                                <ListItem button>
                                    <ListItemText primary="Text Log" />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/messageboardadmin`} >
                                <ListItem button>
                                    <ListItemText primary="Message Board - admin" />
                                </ListItem>
                            </Link>

                            <Link to={`/desk/templateManager`} >
                                <ListItem button>
                                    <ListItemText primary="Manage Templates" />
                                </ListItem>
                            </Link>
                            <Link to={`/admin/sendInvitecsv`} >
                                <ListItem button>
                                    <ListItemText primary="Send email invitation to sign up using a csv file." />
                                </ListItem>
                            </Link>
                        </List>
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader component="div" className={classes.subheader}>
                                    Reports/ Misc
                                    </ListSubheader>
                            }
                            className={classes.root}
                        >
                            <Link to={`/desk/refereecontactreport`} >
                                <ListItem button>
                                    <ListItemText primary="Referee Contact Info Report"
                                        secondary="Name, phone, email, address, shirt size, venmo, paypal, zelle" />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/dailyrecap`} >
                                <ListItem button>
                                    <ListItemText primary="Daily Totals"
                                        secondary="Daily totals by amount and credentials." />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/matchpaymentreconciliation`} >
                                <ListItem button>
                                    <ListItemText primary="Payment Reconciliation"
                                        />
                                </ListItem>
                            </Link>
                            {/* <Link to={`/report/matchreport`} >
                                <ListItem button>
                                    <ListItemText primary="Match Count Report" />
                                </ListItem>
                            </Link> */}
                            <Link to={`/desk/refereetimereport`} >
                                <ListItem button>
                                    <ListItemText primary="Referee Match Time Worked" 
                                    secondary={`Tracks check in and check out times`}/>
                                </ListItem>
                            </Link>
                            <Link to={`/desk/refereetotals`} >
                                <ListItem button>
                                    <ListItemText primary="Referee Match Time and Payment Totals" />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/refereetotalwithcontact`} >
                                <ListItem button>
                                    <ListItemText primary="Referee Total with Payment Options and Email" />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/totalcountanddays`} >
                                <ListItem button>
                                    <ListItemText primary="Referee Total Days and Match Counts" />
                                </ListItem>
                            </Link>


                            
                            

                            <Link to={`/desk/invoice`} >
                                <ListItem button>
                                    <ListItemText primary="Referee Payouts" />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/matchstarters/pastmatches`} >
                                <ListItem button>
                                    <ListItemText primary="Match Starters - Past Matches"
                                        secondary={`List of match starter for tournament`} />
                                </ListItem>
                            </Link>
                            
                            <Link to={`/desk/namecourts`} >
                                <ListItem button>
                                    <ListItemText primary="Name Courts"
                                        secondary={`If you don't use standard numeric numbers, use this tool to name courts`} />
                                </ListItem>
                            </Link>
                            <ListItem button>
                                <a target="_blank" rel="noopener noreferrer"
                                    href="https://www.dropbox.com/s/v9wpi1dw3vt99cv/pickleball%20desk%20how%20to.pdf?dl=0">
                                    <ListItemText primary="Day Of Guide for Referee- PDF"
                                        secondary='Print for Referee Lounge' />
                                </a>
                            </ListItem>
                        </List>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3'>

                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <Link to={`/desk/boardView`} >
                                    <ListSubheader component="div" className={classes.subheader}>
                                        Day of Management
                                    </ListSubheader>
                                </Link>
                            }
                            className={classes.root}
                        >
                            <Typography variant="body2" gutterBottom>
                                **Make sure to turn on text messaging (switch is top left corner of the board view)
                            </Typography>
                            <Link to={`/desk/boardView`} >
                                <ListItem button>
                                    <ListItemText primary="Day Of Management/ Board" />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/boardlargevenue`} >
                                <ListItem button>
                                    <ListItemText primary="Large Venue Board"
                                        secondary='Courts are listed in 6 columns so you can see more courts without scrolling.' />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/refereefinishmatch`} >
                                <ListItem button>
                                    <ListItemText primary="Finish Match Board" 
                                        secondary='This allows referees to finish their match but has no other admin features.  To use setup a computer, iPad, or tablet at the referee desk for the referees to use when they come off a match.'/>
                                </ListItem>
                            </Link>
                            <Link to={'/public/checkIn'} target='_blank' rel="noopener noreferrer">
                                <ListItem button>
                                    <ListItemText primary="Ref Status/ Check-In"
                                        secondary='Ref Status/ Check-In- Opens in new tab' />
                                </ListItem>
                            </Link>
                            <Link to={`/public/boardDisplay/${tid}`} target='_blank' rel="noopener noreferrer">
                                <ListItem button>
                                    <ListItemText primary="Board Display"
                                        secondary='Use in Ref Lounge - Display Only' />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/pastMatches`} >
                                <ListItem button>
                                    <ListItemText primary="Past Matches" />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/notificationlist`} >
                                <ListItem button>
                                    <ListItemText primary="Notifications List" />
                                </ListItem>
                            </Link>
                            

                            <Link to={`/desk/pastNotifications`} target='_blank' rel="noopener noreferrer" >
                                <i className="fas fa-exclamation-circle"></i>Past Notifications
                            </Link>

                            <ListItem button onClick={handleClick}>
                                <ListItemText primary="Barcodes" />
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <Link to={'/desk/barcodes'}>
                                        <ListItem button className={classes.nested}>
                                            <ListItemText primary="Past Matches and Referee List"
                                                secondary='Past matches on left with all referees on right' />
                                        </ListItem>
                                    </Link>
                                    <Link to={'/desk/PastBarcodes'}>
                                        <ListItem button className={classes.nested}>
                                            <ListItemText primary="Past Matches"
                                                secondary='10 most recent matches, shows referee barcodes' />
                                        </ListItem>
                                    </Link>
                                    <Link to={'/desk/RefereeBarcodes'}>
                                        <ListItem button className={classes.nested}>
                                            <ListItemText primary="All Referees"
                                                secondary='10 most recent matches, shows referee barcodes' />
                                        </ListItem>
                                    </Link>
                                </List>
                            </Collapse>
                            <Link to={`/desk/invoice`} >
                                <ListItem button>
                                    <ListItemText primary="Invoicing" />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/mergeToParentTournament`} >
                                <ListItem button>
                                    <ListItemText primary="Merge Dual Venues" />
                                </ListItem>
                            </Link>
                        </List>
                    </div>

                    <div className='col-12 col-md-6 col-lg-3'>

                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <Link to={`/desk/refereeSetup`} >
                                    <ListSubheader component="div" className={classes.subheader}>
                                        Referee Management
                                    </ListSubheader>
                                </Link>
                            }
                            className={classes.root}
                        >
                            <Link to={`/desk/refereeSetup`} >
                                <ListItem button>
                                    <ListItemText primary="Referee Setup"
                                        secondary='Add Referees, Edit Referees, Add Barcodes' />
                                </ListItem>
                            </Link>

                            <Link to={`/desk/editProcedures`} >
                                <ListItem button>
                                    <ListItemText primary="Referee Procedures" />
                                </ListItem>
                            </Link>
                            <Link to={`/desk/approvalManager`} >
                                <ListItem button>
                                    <ListItemText primary="Approved Referees" />
                                </ListItem>
                            </Link>
                            <ListItem button>
                                <ListItemText primary="Import Referees"
                                    secondary='Email CSV file to shonda- pickleballdesk@gmail.com to have your names imported.  Include first, last, email and phone' />
                            </ListItem>
                        </List>

                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader component="div" className={classes.subheader}>
                                    SOS/ Help
                                    </ListSubheader>
                            }
                            className={classes.root}
                        >
                            <Link to={`/desk/courtReset`} >
                                <ListItem button>
                                    <ListItemText primary="Court not letting you assign a match?"
                                        secondary='This tool lets you re-set a court' />
                                </ListItem>
                            </Link>

                            <Typography variant="body1" gutterBottom>
                                In case of internet failure:
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Use your phone to operate the board. If that also does not work, have this spreadsheet on your computer as a temporary backup.
                            <a target="_blank" rel="noopener noreferrer"
                                    href="https://www.dropbox.com/s/sl03aokori85cxn/PickleballDesk%20Backup%20Spreadsheet.xlsx?dl=0">
                                    <i className="fas fa-download"></i>
                                        Backup spreadsheet</a>
                            </Typography>
                        </List>

                    </div>
                </div>
            </div>
            <div className='line'></div>
            <div className='row'>
                    <div className='col-12' >
                        <div className='setup-list'>
                            <h4>Tournament URL:</h4>
                            <span>Use this URL in your tournament listing so volunteers know where to sign up</span>
                            <p onClick={() => { navigator.clipboard.writeText(`www.pickleballdesk.com/signup/${tid}`) }}>
                                www.pickleballdesk.com/signup/{tid}
                                <button onClick={() => { navigator.clipboard.writeText(`www.pickleballdesk.com/signup/${tid}`) }}>
                                    Copy URL
                        </button>
                            </p>


                        </div>


                    </div>
                </div>
            
        </div >
    )
}
export default RefereeDashboard