import React from 'react'

const CourtMonitorBoard = props => {

    return (
        <div className='col-6 court-box-boardView'
            style={{ cursor: 'pointer' }}
            onClick={() => props.setSelectedCourt(props.c)}>
            <div className='col-4 court-num'>
                <div
                    className={props.c.court_num === props.selectedCourt.court_num ? 'selected-court-monitor' : 'court-name'}
                >
                    <div className={props.c.court_num.length > 2 ? 'shrink-court-num' : ''}>{props.c.court_num}</div>
                </div>
            </div>
            <div className='ref-name'
                style={{ paddingLeft: '5px' }}>
                {props.c.first ?
                    <div>{props.c.first} {props.c.last}
                    </div> : ''}

            </div>
        </div>

    )
}
export default CourtMonitorBoard