import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify';


const AddCategory = props => {
    const [category, setCategory] = useState('')
    const [verifyDelete, setVerifyDelete] = useState(false)

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { setPropsToState() }, [props.editCategory])

    const setPropsToState = () => {
        if (props.editCategory.cid) {
            setCategory(props.editCategory.category)
        }
    }

    const addCategory = () => {
        if(props.categories.findIndex(x => x.category.toUpperCase() === category.toUpperCase())){
            return toast.error('Duplicate Category Name Found')
        }
        axios.post(`/api/addCategory/${tid}`, { category }).then(res => {
            props.setShowAddCategory(false)
            props.getToDoList()
            props.getCategories()
            setCategory('')
        })
    }
    const editCategory = () => {
        axios.put(`/api/editCategory/${props.editCategory.cid}`, { category }).then(res => {
            props.setShowAddCategory(false)
            props.getToDoList()
            props.getCategories()
            setCategory('')
        })
    }
    const deleteCategory = () => {
        if (props.editCategory.items.length === 0) {
            axios.put(`/api/deleteCategory/${props.editCategory.cid}`, { category }).then(res => {
                props.setShowAddCategory(false)
                props.getToDoList()
                props.getCategories()
                setCategory('')
            })
        }
    }
    const saveChanges = () => {
        if (props.editCategory.category) {
            editCategory()
        } else {
            addCategory()
        }
    }

    return (
        <div>
            <legend>
                Category
            <input type="text"
                    style={{ width: '400px' }}
                    value={category}
                    onChange={e => setCategory(e.target.value)} />
            </legend>

            <div className='col-12'
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}
            >

                <button onClick={saveChanges}>Save</button>
                {props.editCategory.cid && props.editCategory.items.length == 0 ?
                    verifyDelete ?
                        <button
                            style={{ background: 'red' }}
                            onClick={deleteCategory}>Verify Delete Category</button> :
                        <i className="far fa-trash-alt" style={{ textAlign: 'right' }}
                            onClick={() => setVerifyDelete(true)}></i> : ''}


            </div>

        </div>
    )
}
export default AddCategory