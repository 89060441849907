import React, { useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { updateTournamentStatus } from '../../../ducks/reducer'
import Modal from 'react-responsive-modal';
import TournamentSetup from '../../Schedule/TournamentSetup'
import { toast } from 'react-toastify';

const TournamentStatus = props => {
    const [showTournamentOverview, setShowTournamentOverview] = useState(false)

    const status = useSelector(state => state.status);
    const admin_type = useSelector(state => state.admin_type);
    const tid = useSelector(state => state.tid);
    const dispatch = useDispatch();


    const changeStatus = (s) => {
        axios.put(`/api/t/updateTournamentStatus/${tid}`, { status: s }).then(
            res => {
                dispatch(updateTournamentStatus({ tournamentStatus: res.data.status }))
                setShowTournamentOverview(false)
                toast.success(`Status Updated to ${s}`)
            }
        )
    }

    return (
        <div>
            <p>Tournament Status: {status}</p>
            {admin_type === 'master' &&
            <>
            <p>Change Tournament Status to:
                        {status !== 'draft' ?
                    <button onClick={() => changeStatus('draft')}>Draft</button> : ''}
                {status !== 'active' ?
                    <button onClick={() => setShowTournamentOverview(true)} >Active/ Viewable to Public</button> : ''}
                {status !== 'archive' ?
                    <button onClick={() => changeStatus('archive')}>Archive</button> : ''}

            </p>
            </>}

            <Modal open={showTournamentOverview} center showCloseIcon={true} onClose={() => setShowTournamentOverview(false)} closeOnEsc={true} closeIconSize={2}>
                <div>
                    <h4>Please verify all information is correct before submitting.</h4>
                    <TournamentSetup />
                    <div className='tips flex'
                    >
                        <h4>If you have made any changes first click the blue 'save' button.  Once saved click activate</h4>
                        <button onClick={() => changeStatus('active')}>Activate</button>
                        <button onClick={() => setShowTournamentOverview(false)} > Cancel</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default TournamentStatus