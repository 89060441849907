import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress'



const EditVolunteer = (props) => {
    const [loader, setLoader] = useState(false)
    const [first, setFirst] = useState('')
    const [last, setLast] = useState('')
    const [email, setEmail] = useState('')
    const [textPhone, setTextPhone] = useState('')
    const [altPhone, setAltPhone] = useState('')
    const [credentials, setCredentials] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zip, setZip] = useState('')
    const [shirt, setShirt] = useState('')
    const [shirt2, setShirt2] = useState('')
    const [ambassador, setAmbassador] = useState(false)
    const [emergencyName, setEmergencyName] = useState('')
    const [emergencyNumber, setEmergencyNumber] = useState('')
    const [contactForReferee, setContactForReferee] = useState(false)
    const [contactForLine, setContactForLine] = useState(false)
    const [contactForVolunteer, setContactForVolunteer] = useState(false)
    const [paypal, setPaypal] = useState('')
    const [venmo, setVenmo] = useState('')
    const [zelle, setZelle] = useState('')
    const [errorPhone, setErrorPhone] = useState('')
    const [errorAltPhone, setErrorAltPhone] = useState('')
    const [errorFirst, setErrorFirst] = useState('')
    const [errorLast, setErrorLast] = useState('')

    const editor = useSelector(state => state.ref_id)

    useEffect(() => {
        getProfile()
    }, [props.ref_id, props.edit])

    const handleAltPhone = (e) => {
        const onlyNums = e.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setAltPhone(onlyNums)
            verify();
        } else if (onlyNums.length === 10) {
            setAltPhone(onlyNums)
            verify()
        }
    }

    const handleTextPhone = (e) => {
        const onlyNums = e.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setTextPhone(onlyNums)
            verify();
        } else if (onlyNums.length === 10) {
            setTextPhone(onlyNums)
            verify()
        }
    }
    const handleEmergencyNumber = (e) => {
        const onlyNums = e.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setEmergencyNumber(onlyNums)
        } else if (onlyNums.length === 10) {
            setEmergencyNumber(onlyNums)
        }
    }
    const verify = () => {

        if (altPhone.length !== 10) {
            setErrorPhone('US Number 10 characters')
        }
        if (altPhone.length === 10) {
            setErrorPhone('')
        }
        if (textPhone && textPhone.length !== 10) {
            setErrorAltPhone('US Number 10 characters')
        }
        if (textPhone && textPhone.length === 10) {
            setErrorAltPhone('')
        }
        if (first.length <= 0) {
            setErrorFirst('First Name Required')
        }
        if (first.length > 0) {
            setErrorFirst('')
        }
        if (last.length <= 0) {
            setErrorLast('Last Name Required')
        }
        if (last.length > 0) {
            setErrorLast('')
        }
    }

    const getProfile = () => {
        if(!props.edit){return}
        axios.get(`/auth/getProfile/${props.ref_id}`).then( 
            res => {
                let {address, city, state, zip, shirt, shirt2, usap_ambassador, emergency_name, emergency_number, referee, line_judge, volunteer, paypal, venmo, zelle} = res.data
                setFirst(res.data.first)
                setLast(res.data.last)
                setEmail(res.data.email)
                setTextPhone(res.data.phone)
                setAltPhone(res.data.alt_phone)
                setCredentials(res.data.credentials)
                setAddress(address)
                setCity(city)
                setState(state)
                setZip(zip)
                setShirt(shirt)
                setShirt2(shirt2)
                setAmbassador(usap_ambassador)
                setEmergencyName(emergency_name)
                setEmergencyNumber(emergency_number)
                setContactForReferee(referee)
                setContactForLine(line_judge)
                setContactForVolunteer(volunteer)
                setPaypal(paypal)
                setVenmo(venmo)
                setZelle(zelle)
            }
        )
    }

    const save = async () => {
        verify()
        if (errorAltPhone.length > 0 || errorPhone.length > 0 || errorLast.length > 0 || errorFirst.length > 0) {
            return
        }
        else {
            axios.put(`/auth/editProfile/${props.ref_id}`, { first, last, phone: textPhone, alt_phone: altPhone, credentials, address, city, state, zip, shirt, shirt2, 
                ambassador, emergencyName, emergencyNumber, contactForReferee, contactForLine, contactForVolunteer, paypal, venmo, zelle }).then(
                res => {
                    toast.success(`Profile Updated`)
                    props.close()
                }
            )
        }
    }

    const sendPasswordEmail = () => {
        axios.post(`/auth/forgotPassword`, {
            email: email
        }).then(res => {

            toast.success(`Email Sent to Change Password`)
        }).catch(err => {
        })

    }

    return (
        <Dialog  aria-labelledby="simple-dialog-title" open={props.edit} onClose={props.close}>
      <DialogTitle >Update Profile</DialogTitle>
      {loader &&
                    <LinearProgress color="secondary"/>}
            <div className=' edit-profile' align="center" style={{ background: 'white' }}>
                        <legend>First:
                            <input
                                type="text"
                                value={first}
                                onChange={(e) => setFirst(e.target.value)}
                                onBlur={verify} />
                        </legend>
                        <legend>
                            Last:
                            <input
                                type="text"
                                value={last}
                                onChange={(e) => setLast(e.target.value)}
                                onBlur={verify} />
                        </legend>
                        Email: {email} <small>Contact admin to change</small>

                        <legend>
                            Phone:
                            <input
                                type="text"
                                value={altPhone}
                                onChange={(e) => handleAltPhone(e.target.value)}
                                onBlur={verify} />
                        </legend>
                        <legend>
                            Want to receive text reminders?

                            <input
                                type="text"
                                value={textPhone}
                                onChange={(e) => handleTextPhone(e.target.value)}
                                onBlur={verify} />
                            *By adding your phone number you consent to receiving text messages.  This includes court assignment notification, check in reminders, and admins can text general information.
                    </legend>


                        <div className='credentials'>
                            <legend >Referee Credentials:
                    <label>
                                    <input type='radio'
                                        name='credentials'
                                        value={credentials}
                                        checked={credentials === 'Not Rated'}
                                        onClick={() => setCredentials('Not Rated')}
                                    /> Not Rated </label>
                                <label>
                                    <input type='radio'
                                        value={credentials}
                                        name='credentials'
                                        checked={credentials === 'Level 1'}
                                        onClick={() => setCredentials('Level 1')}
                                    /> Level 1 </label>
                                <label>
                                    <input type='radio'
                                        name='credentials'
                                        checked={credentials === 'Level 2'}
                                        value={credentials}
                                        onClick={() => setCredentials('Level 2')}
                                    /> Level 2 </label>
                                <label>
                                    <input type='radio'
                                        name='credentials'
                                        checked={credentials === 'Certified'}
                                        value={credentials}
                                        onClick={() => setCredentials('Certified')}
                                    /> Certified </label>
                            </legend>

                        </div>
                        <div>
                        <legend>
                            Address:
                            <input
                                type="text"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                 />
                        </legend>
                        <legend>
                            City:
                            <input
                                type="text"
                                value={city} 
                                onChange={(e) => setCity(e.target.value)}
                                 />
                        </legend>
                        <legend>
                            State:
                            <input
                                type="text"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                 />
                        </legend>
                        <legend>
                            Zip:
                            <input
                                type="text"
                                value={zip}
                                onChange={(e) => setZip(e.target.value)}
                                 />
                        </legend>
                        </div>
                        <div className='form-label1'>
                            Shirt Size: 
                            <select
                                className='dropdown'
                                style={{marginRight: '15px'}}
                                value={shirt}
                                onChange={e=>setShirt(e.target.value)}>
                                    <option value="" key='1'>
                                        select...
                                    </option>
                                    {['M- X-Small', 'M- Small', 'M- Medium', 'M- Large', 'M- X-Large', 'M- XX-Large', 'M- XXX-Large',
                                    'W- X-Small', 'W- Small', 'W- Medium', 'W- Large', 'W- X-Large', 'W- XX-Large', 'W- XXX-Large'].map((size, i)=>(
                                    <option value="X-Small" value={size} key={i}>
                                        {size}
                                    </option>
                                    )
                                    )}
                                    
                                   
                            </select>
                          Unisex
                            <select
                                className='dropdown'
                                value={shirt2}
                                onChange={e=>setShirt2(e.target.value)}>
                                    <option value="" key='1'>
                                        select...
                                    </option>
                                    {['X-Small', 'Small', 'Medium', 'Large', 'X-Large', 'XX-Large', 'XXX-Large'].map((size, i)=>(
                                    <option value="X-Small" value={size} key={i}>
                                        {size}
                                    </option>
                                    )
                                    )}
                                    
                                   
                            </select>
                        </div>
                        
                        <div className='form-label1'>
                        <legend>
                        Emergency Contact Name
                            <input
                                type="text"
                                value={emergencyName}
                                onChange={(e) => setEmergencyName(e.target.value)}
                                 />
                        </legend>
                        <legend>
                        Emergency Contact Phone Number
                            <input
                                type="number"
                                value={emergencyNumber}
                                onChange={(e) => setEmergencyNumber(e.target.value)}
                                 />
                        </legend>
                        </div>
                        
                        
                        <div className='form-label1'>
                        <legend>
                            <input
                                type="checkbox"
                                checked={ambassador}
                                onChange={(e) => setAmbassador(ambassador => !ambassador)}
                                 />
                                 USA Pickleball Ambassador
                        </legend>
                        </div>
                        <div>
                            <h5>Payment Information</h5>
                            <p>Some tournaments pay their volunteers for refereeing, line judging or other tasks.  This information is for those tournaments.</p>
                            <legend>
                            Paypal
                            <input
                                type="text"
                                value={paypal}
                                onChange={(e) => setPaypal(e.target.value)}
                                 />
                        </legend>
                        <legend>
                            venmo
                            <input
                                type="text"
                                value={venmo}
                                onChange={(e) => setVenmo(e.target.value)}
                                 />
                        </legend>
                        <legend>
                            zelle
                            <input
                                type="text"
                                value={zelle}
                                onChange={(e) => setZelle(e.target.value)}
                                 />
                        </legend>
                        </div>

                        <div style={{ color: 'red', display: 'flex', flexDirection: 'column' }}>
                            <div>{errorPhone} </div>
                            <div>{errorAltPhone}</div>
                            <div> {errorFirst}</div>
                            <div> {errorLast}</div>
                        </div>
                        <h4 className='tips'>Any changes will update this persons profile for ALL tournaments.  Only make updates you are 100% sure are correct.</h4>
                        {props.show ?
                            <button onClick={save}>Save Changes</button>:
                            <>
                            <button onClick={save}>Update Profile for All Tournaments</button>
                            <button  className='button-verify' onClick={props.close}>Cancel Edit</button>
                        </>}
                    </div>
                   


</Dialog>
    )
}

export default EditVolunteer