import React, {useState} from 'react'
import axios from 'axios'
import { toast } from 'react-toastify';
import LinearProgress from '@material-ui/core/LinearProgress';

const MassEditShifts = props => {
    const [action, setAction] = useState('')
    const [newValue, setNewValue] = useState('')
    const [loader, setLoader] = useState(false)


    let {selected} = props

    const options = ['title', 'spots', 'waitlist spots', 'delete']

    const save = () => {
        if(!action){return toast.error('Select Option to Change')}
        if(!newValue && action !== 'delete'){return toast.error('New Value needed')}
        setLoader(true)
            axios.put(`/api/massEditShifts`, {selected, action, newValue}).then(res => {
                props.get()
                setAction('')
                setNewValue('')
                toast.success('saved')
                setLoader(false)
            })
    }

    return(
        <div className='edit-shifts'>
                        {loader ? <LinearProgress /> : ''}
            <div>

            <label htmlFor="">Mass Edit Selected Shifts
            <select name="action"
                    value={action}
                    className='dropdown'
                    onChange={e=>setAction(e.target.value)}
                >
                    <option key={'s'}>
                        Select...
            </option>
                    {options.map((o, i)=> (
                        <option key={'o'+i} value={o}>{o}</option>
                    ))}
                </select>
                </label>

                {action !== 'delete' ?

                <input type="text"
                    value={newValue}
                    onChange={e=>setNewValue(e.target.value)}/>:''}
                
                <button className='button-none'
                    onClick={save}>
                    save
                </button>
                </div>
                <div style={{color: 'white'}}>
                    {action === 'delete' ? 'Before deleting please move volunteers to a new shift or let them know you are deleting the shift':''}
                </div>

            

        </div>
    )
}
export default MassEditShifts