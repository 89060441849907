import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { updateTournament } from "../../../ducks/reducer";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Moment from "react-moment";
import Modal from "react-responsive-modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../AddRefToSchedule.css";
import ScheduleRecap from "../ScheduleModal/ScheduleRecap";
import SignupsPublic from "../ScheduleModal/SignupsPublic";
import Login from "../../Login/Login";
import IndividualStatusUpdate from "../../DayOfComponents/IndividualStatusUpdate";
import "./Signup.css";
import WithdrawFromAllShifts from "./WithdrawFromAllShifts";
import ShiftDescriptions from "../ShiftDescriptions";

class RefereeSignup extends Component {
  constructor() {
    super();
    this.state = {
      tournament: {},
      shifts: [],
      schedule: [],
      searchName: "",
      showLogin: false,
      categoryFilter: "",
      showMore: false,
      loadingTournament: false,
    };
  }

  componentDidMount() {
    this.getShiftSpots();
    this.getMySchedule();
  }

  componentDidUpdate(prevProps, prevState) {
    const { ref_id } = this.props;

    if (prevProps.ref_id !== ref_id) {
      this.getMySchedule();
      this.getShiftSpots();
    }
  }

  setProps = () => this.props.updateTournament(this.state.tournament);

  getShiftSpots = () => {
    axios.get(`/api/getRefereeSignup/${this.props.tid}`).then((res) => {
      this.setState({ shifts: res.data });
    });
  };

  getMySchedule = () => {
    if (this.props.ref_id > 0) {
      axios
        .post(`/api/getRefScheduleForTournament/${this.props.ref_id}`, {
          tid: this.props.tid,
        })
        .then((res) => {
          this.setState({ schedule: res.data });
        });
    }
  };

  assignRef = async (shift_id) => {
    if (!this.props.ref_id) {
      this.setState({ showLogin: true });
    } else {
      axios
        .put(`/api/putTimeSpots/${shift_id}`, {
          tid: this.props.tid,
          ref_id: this.props.ref_id,
        })
        .then((res) => {
          if (res.data.conflict) {
            toast.warning(res.data.conflict);
          } else {
            this.getShiftSpots();
            this.getMySchedule();
            toast.success("Added To Shift");
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.warning(error.response.data);
            this.setState({ referee: {}, shift: {}, waitlist: false });
          }
        });
    }
  };

  addWaitList = async (shift_id) => {
    if (!this.props.ref_id) {
      this.setState({ showLogin: true });
    } else {
      axios
        .put(`/api/putWaitlist/${shift_id}`, {
          tid: this.props.tid,
          ref_id: this.props.ref_id,
        })
        .then((res) => {
          if (res.data.conflict) {
            toast.warning(res.data.conflict);
          } else {
            this.getShiftSpots();
            this.getMySchedule();
            toast.success("Added To Waitlist");
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.warning(error.response.data);
            this.setState({ referee: {}, shift: {}, waitlist: false });
          }
        });
    }
  };

  removeRef = (shift_id) => {
    axios
      .put(`/api/removeRefTime/${shift_id}`, { tid: this.props.tid })
      .then((res) => {
        this.getMySchedule();
        this.getShiftSpots();
        toast.success("Removed From Shift");
      });
  };

  updateUser = () => {
    this.getMySchedule();
  };

  showLogin = () => this.setState({ showLogin: !this.state.showLogin });

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleWithdraw = () => {
    this.getShiftSpots();
    this.getMySchedule();
  };

  render() {
    const mappedSchedule = this.state.schedule.map((shift, i) => {
      return (
        <div key={"a" + i}>
          <ScheduleRecap shift={shift} />
        </div>
      );
    });

    const mappedShifts = this.state.shifts
      .filter((obj) => obj.category.includes(this.state.categoryFilter))
      .filter((obj) =>
        obj.name.toUpperCase().includes(this.state.searchName.toUpperCase())
      )
      .map((shift, i) => {
        return (
          <div key={"b" + i}>
            <div style={{ background: "white", margin: "10px 0" }}>
              <div
                className="shift-title"
                style={{ background: shift.color || "#009CAE" }}
              >
                <h4>
                  {shift.category}: {shift.time} {shift.name}
                </h4>
              </div>
              {shift.description ? (
                <div style={{ padding: "0 15px 15px 15px" }}>
                  {shift.description}
                </div>
              ) : (
                ""
              )}
              <ShiftDescriptions shift={shift} />

              <div className="row">
                <div className="col-12 col-md-6 shift-box">
                  {shift.pre_reg ? (
                    ""
                  ) : (
                    <div className="col-12 criteria">
                      <h5>
                        Spots: {shift.count}/{shift.spots}
                      </h5>
                      {shift.certified > 0 ||
                      shift.level_two > 0 ||
                      shift.level_one > 0 ? (
                        <div style={{ fontSize: ".7em" }}>criteria:</div>
                      ) : (
                        ""
                      )}
                      <div className="row">
                        {shift.certified > 0 ? (
                          <div className="col-12 col-lg-6">
                            Certified: {shift.certified}
                          </div>
                        ) : (
                          ""
                        )}
                        {shift.level_two > 0 ? (
                          <div className="col-12 col-lg-6">
                            Level Two: {shift.level_two}
                          </div>
                        ) : (
                          ""
                        )}
                        {shift.level_one > 0 ? (
                          <div className="col-12 col-lg-6">
                            Level One: {shift.level_one}
                          </div>
                        ) : (
                          ""
                        )}
                        {shift.certified > 0 &&
                        shift.level_two > 0 &&
                        shift.level_one > 0 ? (
                          <div className="col-12 col-lg-6">
                            Not Specified: {shift.none}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                  <div className="registered-spots">
                    <SignupsPublic
                      key={`aa:${i}`}
                      spots={shift.signup}
                      ref_id={this.props.ref_id}
                      removeRef={this.removeRef}
                    />

                    {shift.spots - shift.count > 0 || shift.pre_reg ? (
                      <button onClick={() => this.assignRef(shift.sid)}>
                        Sign Up
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6 shift-box">
                  <div className="col-12 criteria">
                    {shift.wait_spots > 0 ? (
                      <div>
                        <h5>
                          Waitlist: {shift.wait_count}/{shift.wait_spots}
                        </h5>
                        {shift.wait_certified > 0 ||
                        shift.wait_level_two > 0 ||
                        shift.wait_level_one > 0 ? (
                          <div style={{ fontSize: ".7em" }}>criteria:</div>
                        ) : (
                          ""
                        )}
                        <div className="row">
                          {shift.wait_certified > 0 ? (
                            <div className="col-12 col-lg-6">
                              Certified: {shift.wait_certified}
                            </div>
                          ) : (
                            ""
                          )}
                          {shift.wait_level_two > 0 ? (
                            <div className="col-12 col-lg-6">
                              Level Two: {shift.wait_level_two}
                            </div>
                          ) : (
                            ""
                          )}
                          {shift.wait_level_one > 0 ? (
                            <div className="col-12 col-lg-6">
                              Level One: {shift.wait_level_one}
                            </div>
                          ) : (
                            ""
                          )}
                          {shift.wait_certified > 0 &&
                          shift.wait_level_two > 0 &&
                          shift.wait_level_one > 0 ? (
                            <div className="col-12 col-lg-6">
                              Not Specified: {shift.wait_none}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="registered-spots">
                          <SignupsPublic
                            key={`z:${shift.shift_id}`}
                            spots={shift.waitlist}
                            ref_id={this.props.ref_id}
                            removeRef={this.removeRef}
                          />
                          {shift.wait_spots - shift.wait_count > 0 ? (
                            <button onClick={() => this.addWaitList(shift.sid)}>
                              Sign Up
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    return (
      <div>
        <div className="AddRefToSchedule container">
          <div className="col-12 col-md-4">
            <button onClick={() => this.props.history.goBack()}>
              Back to Tournament Listings
            </button>
          </div>
          <div className="row justify-content-center">
            <div className="col-4">
              <img
                className="volunteer-type-image"
                src={
                  "https://res.cloudinary.com/shonda/image/upload/v1582498963/PickleballDesk/Referee_o31pb8.png"
                }
                alt="referee"
              />
            </div>
            <div className="col-8">
              <h5>Volunteer Opportunities For:</h5>
              <h2>{this.props.name}</h2>
              <div>
                {/* <Moment format="MM/DD/YYYY" > */}
                {this.props.start}
                {/* </Moment>  */}-{/* <Moment format="MM/DD/YYYY" > */}
                {this.props.end_date}
                {/* </Moment> */}
              </div>
              <p>Location: {this.props.location}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-4 " style={{ background: "white" }}>
              {this.state.tournament.tournament_started ? (
                <Link
                  to={`/public/board/${this.props.tid}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="register-button">
                    Referee Court Assignments/ Queue
                  </button>
                </Link>
              ) : (
                ""
              )}
              {this.state.schedule.length > 0 &&
              this.state.tournament.tournament_started ? (
                <IndividualStatusUpdate tid={this.props.match.params.tid} />
              ) : (
                ""
              )}
              {this.props.ref_id ? (
                <div>
                  <div className="my-schedule-title">My Schedule</div>
                  <div className="my-schedule">{mappedSchedule}</div>
                  {this.state.schedule.length > 0 && (
                    <WithdrawFromAllShifts get={this.handleWithdraw} />
                  )}
                </div>
              ) : (
                "Login to see your schedule"
              )}
            </div>

            <div className="col-12 col-md-8">
              <div className="dashboard-title">Referee Signup</div>
              {this.props.referee_coordinator_name ? (
                <div className="coordinator">
                  Referee Coordinator: {this.props.referee_coordinator_name}
                  {this.props.ref_id ? (
                    <Link
                      to={{
                        pathname: `/signup/contactcoordinator`,
                        type: "referee",
                      }}
                    >
                      <div className="contact-button">
                        <i class="fas fa-envelope"></i>Contact
                      </div>
                    </Link>
                  ) : (
                    <p style={{ marginLeft: "20px" }}>Login to Contact</p>
                  )}
                </div>
              ) : (
                ""
              )}
              <div
                style={{
                  background: "white",
                  padding: "10px",
                }}
              >
                <div
                  className={
                    this.state.showMore
                      ? "tournament-description-long"
                      : "tournament-description-short"
                  }
                >
                  <ReactQuill
                    className="description"
                    value={this.props.description}
                    readOnly={true}
                    theme={"bubble"}
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <button
                    className="login-button"
                    onClick={() =>
                      this.setState({ showMore: !this.state.showMore })
                    }
                  >
                    {this.state.showMore ? "Show Less" : "Show More"}
                  </button>
                </div>
              </div>

              <div>
                <Link to={`/signup/refereemessageboard/${this.props.tid}`}>
                  <button
                    className="login-button"
                    style={{
                      margin: "10px 0 0 0",
                      fontSize: "2em",
                      background: "#EF746C",
                    }}
                  >
                    Past Emails Sent to Referees
                  </button>
                </Link>
              </div>
              <div>
                <Link to={`/signup/referee/messageboard/${this.props.tid}`}>
                  <button
                    className="login-button"
                    style={{
                      margin: "10px 0 0 0",
                      fontSize: "2em",
                      background: "#4974a5",
                    }}
                  >
                    Referee Message Board
                  </button>
                </Link>
              </div>
              <div>
                <Link to={`/procedures/${this.props.tid}`}>
                  <button
                    className="register-button"
                    style={{ margin: "10px 0 0 0" }}
                  >
                    View Referee Procedures
                  </button>
                </Link>
              </div>

              <div style={{ margin: "10px 0 0 0" }}>
                <div className="search-menu">
                  <legend>
                    {" "}
                    Search by date or title
                    <input
                      type="text"
                      placeholder="Search date/ Shift Name"
                      name="searchName"
                      value={this.state.searchName}
                      onChange={this.handleChange}
                    />
                    <i
                      className="fas fa-ban"
                      onClick={() => this.setState({ searchName: "" })}
                    ></i>
                  </legend>
                </div>

                {mappedShifts}
              </div>
            </div>
          </div>
          <Modal
            open={this.state.showLogin}
            center
            showCloseIcon={true}
            onClose={() => this.setState({ showLogin: false })}
            closeIconSize={20}
            closeOnEsc={true}
          >
            <Login showLogin={this.showLogin} />
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => {
  return {
    tid: reduxState.tid,
    ref_id: reduxState.ref_id,
    name: reduxState.name,
    start: reduxState.start,
    end_date: reduxState.end_date,
    location: reduxState.location,
    description: reduxState.description,
    referee_coordinator_name: reduxState.referee_coordinator_name,
  };
};
export default connect(mapStateToProps, { updateTournament })(RefereeSignup);
