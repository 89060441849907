import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import Datetime from 'react-datetime'
import moment from 'moment'
import 'moment-timezone'
import ColorPicker from '../../utlities/ColorPicker'


const AddShiftToCategory = props => {
    const [name, setName] = useState('')
    const [preReg, setPreReg] = useState(false)
    const [description, setDescription] = useState('')
    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const [spots, setSpots] = useState(0)
    const [waitSpots, setWaitSpots] = useState(0) 
    const [message, setMessage] = useState('')
    const [color, setColor] = useState('')
    const [verifyDelete, setVerifyDelete] = useState(false)
    const [deleteSid, setDeleteSid] = useState(undefined)
    const [category, setCategory] = useState({})
    const [categoryName, setCategoryName] = useState('')
    const [repeat, setRepeat] = useState(0)
    const [oldName, setOldName] = useState('')

    let {categories} = props

    const tid = useSelector(state => state.tid)
    const zone = useSelector(state => state.time_zone)

    useEffect(
        () => { props.getCategories() }, [])
    useEffect(
        () => { handleEndTime() }, [start])
    useEffect(
        () => { handleSetPropsForEdit() }, [props.shift])

const handleSetPropsForEdit = () => {
    if(props.showEdit){
    let {name, pre_reg, description, color, start_time, end_time, category, vcid, spots, wait_spots} = props.shift
    setCategory({vcid, name: category})
    setName(name)
    setOldName(name)
    setPreReg(pre_reg)
    setStart(moment(start_time).tz(zone))
    setEnd(moment(end_time).tz(zone))
    setDescription(description)
    setColor(color)
    setSpots(spots)
    setWaitSpots(wait_spots)
    }
}

    const handleEndTime = () => {
        if(props.shift){return}
        if(end === null){
            setEnd(start)}
        else if(end < start){
            setEnd(start)}
    }

    const handlePreReg = () => {
        if (preReg) {
            setPreReg(false)
            setDescription('')
            
        } else {
            setPreReg(true)
            setDescription('This is pre-registration signup to identify who plans to volunteer. By registering you will be on the list to receive an email invitation once time slots and specific volunteer needs are available.')
        }
    }

    const addShift = () => {
        if (name.length === 0 && start.length === 0 && end.length === 0) { setMessage('Title, start time, end time Required') }
        else if (start.length === 0) { setMessage('Start Time Required') }
        else if (end.length === 0) { setMessage('End Time Required') }
        else if (spots === 0 && waitSpots === 0 && !preReg) {
            setMessage('Total spot count is 0, please add spots.')
        }
        else {
            axios.post(`/api/volunteer/addVolunteerShift`, { tid, name, preReg, start, end, description, spots, waitSpots, category, color, vcid: category.vcid, repeat }).then(
                res => {
                    toast.success(`Saved`)
                    if(props.get){
                        props.get()
                    }
                    if(props.close){
                        props.close()
                    }
                    clearState()
                }
            )
        }
    }

    const editShift = () => {
        if (name.length === 0) { setMessage('Title Required') }
        
        else {
            if (spots === 0 && waitSpots === 0 && !preReg) {
                setMessage('Adding shift, shift spot count is 0.')
            }
            axios.put(`/api/volunteer/editVolunteerShift`, { tid, sid: props.shift.sid, name, preReg, start, end, description, spots, waitSpots, category, color, vcid: category.vcid, repeat }).then(
                res => {
                    toast.success(`Saved`)
                    if(props.get){
                        props.get()
                    }
                    if(props.close){
                        props.close()
                    }
                    clearState()
                }
            )
        }
    }

    const handleCategory = e => {
        let x = categories.find(x => x.name === e.target.value)
        setCategoryName(x.name)
        setCategory(x)
    }

    const handleVerifyDelete = (sid) => {
        setVerifyDelete(true)
        deleteSid(sid)
    }

    const cancelDelete = () => {
        setVerifyDelete(false)
        deleteSid(undefined)
    }

    const deleteShift = () => {
        axios.delete(`/api/deleteShift/${props.shift.sid}`, {shift: props.shift, tid}).then(
            res => {
                if(props.get){props.get()}
                setVerifyDelete(false)
        deleteSid(undefined)
            }
        )
    }

    const clearState = () => {
        setName('')
        setPreReg(false)
        setDescription('')
        setColor('')
        setStart(null)
        setEnd(null)
    }

        return (
            <div className=''>
                <div className='ScheduleSetup'>
                    <div className='row justify-content-center'>

                        <div className='col-12 '>
                            <h4 className='tips'>{props.edit ? 'Edit Volunteer Shift':'Volunteer Shift Setup '}</h4>
                            <div className='form-label' style={{justifyContent: 'flex-start'}}>
                                Select a category
                                <label htmlFor="">
                            <select 
                                name={categoryName}

                                value={categoryName}
                                className='dropdown'
                                onChange={handleCategory}
                            >
                                <option key={0} value={{}}>Select...</option>
                                    {categories.map((cat, i)=> (
                                        <option key={i+'o'} value={cat.name}>
                                        {cat.name}
                                        </option>
                                    ))}
                            </select>
                            </label>
                            </div>

                            <div className='form-label'>
                                Title
                                <input type="text"
                                    name='name'
                                    style={{ width: '200px' }}
                                    value={name}
                                    onChange={e=>setName(e.target.value)} />*Recommended title format:Date, Letter, start time - end time.
                                </div>
                            <div className='form-label'>
                                <input type='checkbox'
                                    checked={preReg}
                                    name='preReg'
                                    onChange={handlePreReg}
                                /> Pre-Registration Shift?  Select if time slots and volunteer needs have not been decided.
                                </div>
                            <div className='form-label'>
                                Start Date/ Time
                                <small>Time Zone: {zone}</small>
                                <Datetime 
                                displayTimeZone={zone}
                                timeConstraints={
                                    { minutes: { step: 15 }}
                                }
                                value={start}
                                onChange={e=>setStart(e)} />
                                
                            </div>
                            <div className='form-label'>
                                End Date/ Time
                                <small>Time Zone: {zone}</small>
                                <Datetime 
                                displayTimeZone={zone}
                                timeConstraints={
                                    { minutes: { step: 15 }}
                                }
                                value={end}
                                onChange={e=>setEnd(e)} />
                                
                            </div>
                            <div>
                                ** make sure your shifts do not overlap times.  When volunteers register, the system checks to make sure they are not registered for a conflicting shift.  It will not let them select a shift if they are registered for another shift during that time.  
                            </div>
                            <div className='form-label'>
                                Description
                                <textarea cols="20" rows="2"
                                    style={{ margin: '0 20px', width: '90%' }}
                                    name='description'
                                    placeholder='Shift Description'
                                    value={description}
                                    onChange={e=>setDescription(e.target.value)}></textarea>
                            </div>
                        </div>
                    </div>
                               <div className='form-label'>
                                <h5>Shift Requirements</h5>
                                <legend>
                                    Spots
                                <input type="number"
                                        value={spots}
                                        onChange={e=>setSpots(e.target.value)} />
                                </legend>
                                <legend>Waitlist
                                <input type="number"
                                        value={waitSpots}
                                        onChange={e=>setWaitSpots(e.target.value)} />
                                </legend>       
                        </div>
                    <div className='col-12' >
                    <h5>Want to color code the shifts?</h5>
                    <ColorPicker
                    setColor={setColor}
                    color={color} />
                        

                    </div>
                    {!props.edit &&
                    <div className='form-label'>
                        How many days do you want this shift to be duplicated for?
                        <input type="number"
                            value={repeat}
                            onChange={e=>setRepeat(e.target.value)}/>
                    </div>}
                    <p style={{ color: 'red' }}>{message}</p>
                    {props.edit ?
                    <button onClick={editShift}>Save Changes</button>:
                    <button onClick={addShift}>Add</button>}
                    <button onClick={clearState}>Clear</button>
                    <div style={{textAlign:'right'}}>
                        <button 
                        className='button-none'
                        onClick={()=>setVerifyDelete(true)}>Delete</button>
                        {verifyDelete ?
                        <div>
                        <button 
                        style={{ background: 'red' }}
                        onClick={deleteShift}>Yes, Delete and remove any signups for this shift</button>
                        <button onClick={()=>setVerifyDelete(false)}>Cancel Delete</button>
                        </div>:''}
                        
                    </div>

                </div>

            </div >
        )
    }
export default AddShiftToCategory
