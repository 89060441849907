import React, { useState, useEffect } from 'react'
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import 'moment-timezone';
import Modal from 'react-responsive-modal';
import InvoiceLineItem from './InvoiceLineItem'
import axios from 'axios'
import './Invoice.css'

const PrintInvoice = props => {
    const [invoice, setInvoice] = useState({ detail: [] })
    const [auth, setAuth] = useState(false)
    const [checkingAuth, setCheckingAuth] = useState(true)

    const tid = useSelector(state => state.tid)
    const admin_type = useSelector(state => state.admin_type)
    const ref_id = useSelector(state => state.ref_id)



    useEffect(
        () => { getInvoice() }, [])

    useEffect(
        () => { verifiedAuth() }, [invoice])

    const getInvoice = () => {
        axios.get(`/api/getInvoice/${props.match.params.id}`).then(res => {
            setInvoice(res.data)

        })
    }

    const verifiedAuth = async () => {
        if ((admin_type.length > 0 && tid === invoice.tid) || invoice.ref_id === ref_id) {
            await setAuth(true)
            setCheckingAuth(false)
            window.print()
        } else {
            setCheckingAuth(false)
        }
    }

    const mappedPastMatches = invoice.detail.map(p =>

        <InvoiceLineItem
            key={p.id}
            past={p}
            amount={p.amount} />
    )

    if (auth) {
        return (

            <div className='container'>
                <div className='row'>
                    <div className='col-12' style={{ background: 'white' }}>
                        <div className='invoiceHeader'>

                            <div style={{ color: 'red', fontSize: '1.5em' }}>Paid</div>
                            <div className='flex'>
                                <div style={{ paddingRight: '6px' }}>
                                    Referee Name:
                            </div>
                                <div className='refereeName'>
                                    {invoice.first} {invoice.last}
                                </div>
                            </div>
                            <div className='flex'>
                                <div style={{ paddingRight: '6px' }}>
                                    Tournament:
                            </div>
                                <div className='tournamentName'>
                                    {invoice.name} </div>
                            </div>

                            <div className='flex'>
                                <div style={{ paddingRight: '6px' }}>
                                    Payment Date:
                            </div>
                                <Moment format="MM/DD/YYYY" >
                                    {invoice.payment_date}
                                </Moment>
                            </div>
                            <div className='flex'>
                                <div style={{ paddingRight: '6px' }}>
                                    Payment Type:
                            </div>
                                <div className=''>
                                    {invoice.payment_type} {invoice.payment_memo}
                                </div>
                            </div>
                        </div>




                        <div className='invoiceItem'>
                            <div style={{ width: '50px' }}>
                                Court</div>
                            <div style={{ width: '50px' }}>
                                Date
            </div>
                            <div style={{ width: '100px' }}>
                                Start Time
            </div>
                            <div style={{ width: '100px' }}> End Time</div>
                            <div style={{ width: '50px' }}>
                                Amount
            </div>

                        </div>
                        {mappedPastMatches}
                        <div className='row'>
                            <div className='col-9'>
                                {invoice.bonusDesc}
                            </div>
                            <div className='col-3'>
                                Bonus: ${invoice.bonus}
                            </div>
                        </div>
                        <div className='invoiceTotal'>Total: ${invoice.total}</div>
                        <div>Invoice was created by pickleballdesk.com</div>
                    </div>

                </div >
            </div >
        )
    }
    else {
        return (
            <div>
                {checkingAuth ?
                    <h5>Checking Authorization</h5> : ''}
            </div>
        )
    }
}
export default PrintInvoice