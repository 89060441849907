import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';


const EditPastAuxAssignment = props => {
    const [paid, setPaid] = useState(false)
    const [amount, setAmount] = useState(0)
    const [loader, setLoader] = useState(false)


    let { id, court_num, first, last } = props.location.state.row
    useEffect(
        () => { setProps() }, [props.location.state.row])



    const setProps = () => {
        let { amount, paid } = props.location.state.row
        setAmount(amount)
        if(paid === 'yes'){
            setPaid(true)
        }
        
    }

    const save = () => {
        axios.put(`/api/updatePastAuxAssignment`, { id, amount, paid }).then(res => {
            props.history.goBack()

        })
    }



    return (
        <div className='container'>
            <div style={{ textAlign: 'left' }}>
                <h4>Edit Match</h4>
                <div>Court: {court_num}</div>
                <div>Referee: {first} {last}</div>
                <label>
                    <input
                        type="checkbox"
                        placeholder='paid'
                        checked={paid}
                        onChange={() => setPaid(paid => !paid)} />
                            PAID $
                            <input type="number"
                        style={{ width: '50px' }}
                        value={amount}
                        onChange={e => setAmount(e.target.value)} />
                </label>

                <button onClick={save}>Save</button>

            </div>
        </div >
    )
}
export default EditPastAuxAssignment