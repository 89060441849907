import React, {useState} from 'react'
import { useSelector } from 'react-redux'

import axios from 'axios'

export default function ShirtSizes(){
    const [list, setList] = useState([])
    const [type, setType] = useState('')

    const tid = useSelector(state => state.tid)

    const getShirtSizesUnisex = () => {
        axios.get(`/api/volunteer/getShirtSizesUnisex/${tid}`).then(

            res => {
                setList(res.data)
                setType('Unisex Sizes')
            }
        )
    }

    const getShirtSizesGender = () => {
        axios.get(`/api/volunteer/getShirtSizesGender/${tid}`).then(

            res => {
                setList(res.data)
                setType('Gender Sizes')
            }
        )
    }

    return(
        <div className='container' style={{textAlign:'left'}}>
            <h2>Shirt Size Totals</h2>
            <button onClick={getShirtSizesGender}>Gender Sizes</button>
            <button onClick={getShirtSizesUnisex}>Unisex Size</button>
            <h5>{type}</h5>
            {list.map((row, i)=>(
                <div key={i} style={{display: 'flex', borderBottom: '1px dotted grey', padding: '5px'}}>
                    <div style={{minWidth: '40px', width: '40px'}}>{row.count}</div>
                    <div >{row.shirt}</div>
                    
                </div>
            ))}
        </div>
    )
}