import React from 'react'
import AddVenueItem from './AddVenueItem'
import { makeStyles } from '@material-ui/core/styles';


const VenueItem = (props) => {
    let {item} = props

    const classes = useStyles();


    return(            
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', overflowWrap: 'anywhere', border:`0.1em solid ${item.color}|| '#000000' `,}}>
            <button className='button5' 
            style={{ background: `${item.color || '#FFFFFF'}`}}
                onClick={()=>props.select(item)}>
                    {item.title}
                </button>

            <div >
                {item.link && <>Link to: {item.link}</>}
                {item.image && 
                <img 
                style={{width: '90%', marginBottom: '5px'}}
                src={item.image} alt='image' />}
                {item.details}
            </div>
            <AddVenueItem item={item} id={item.id} get={props.get}/>
        </div>
    )


}
export default VenueItem

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });