import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify';
import LookupReferee from './LookupReferee'
import Modal from 'react-responsive-modal';





const AddCompletedMatch = (props) => {
    const [show, setShow] = useState(false)
    const [court, setCourt] = useState(null)
    const [referee, setReferee] = useState({ref_id: null, credentials: null})
    const [secondRef, setSecondRef] = useState({ref_id: null, credentials: null})
    const [paid, setPaid] = useState(false)
    const [amount, setAmount] = useState(0)
    const [secondPaid, setSecondPaid] = useState(false)
    const [secondAmount, setSecondAmount] = useState(0)
    const [sysid, setSysid] = useState(null)
    const [medal, setMedal] = useState(null)


    const tid = useSelector(state => state.tid)


    const addCompletedMatch = async () => {
        if(!referee.ref_id) return toast.error('select referee')
        axios.post(`/api/addCompletedMatch/${tid}`, { court, ref_id: referee.ref_id, credentials : referee.credentials, paid, amount, secondPaid, secondAmount, secondRef: secondRef.ref_id, secondCred: secondRef.credentials, sysid, medal }).then(
            res => {
                if (res.data === 'Try Again') {
                    toast.warning(`Issue with finish match, please make sure referee is now in the queue.  Reset referee if missing.`)
                }
                else {
                    clearState()
                    setShow(false)
                    if(props.get){
                        props.get()
                    }
                }
            }
        )
    }


    const addLeadReferee = (e) => {
        setReferee(e)
    }
    const addSecondReferee = (e) => {
        setSecondRef(e)
    }
    const clearState = () => {
        setCourt(null)
        setReferee({ref_id: null, credentials: null})
        setSecondRef({ref_id: null, credentials: null})
        setPaid(false)
        setAmount(0)
        setSecondAmount(0)
        setSecondPaid(false)
        setSysid('')
        setMedal('')
    }


    return (
        <div>
            <button 
                className='button-none'
            onClick={()=>setShow(true)}>+ Add Completed Match</button>
            
            <Modal open={show} center showCloseIcon={true}
                onClose={() => setShow(false)} closeOnEsc={true} closeIconSize={2}>
        <div className='finishMatch col-12 ' align="center">
            <div>
                <div>
                    <div className='assign-header'>Add Completed Match</div>
                </div>
                <div>
                    Court: 
                    <input type="text"
                        onChange={e=>setCourt(e.target.value)}
                        value={court}/>
                </div>
                <div>
                    <div>
                        Referee: 
                        <LookupReferee 
                            show={show}
                            addReferee={addLeadReferee}/>
                    </div>
                    <label>
                        <input
                            type="checkbox"
                            placeholder='paid'
                            checked={paid}
                            onChange={() => setPaid(paid => !paid)} />
                            PAID $
                            <input type="number"
                            style={{ width: '50px' }}
                            value={amount}
                            onChange={e => setAmount(e.target.value)} />
                    </label>
                </div>
            </div>
            <input
                type="text"
                placeholder='SYSID or Note'
                name="sysid"
                value={sysid}
                onChange={e => setSysid(e.target.value)}
            />

            <legend>Medal Match?</legend>
            <label>
                <input type='radio'
                    checked={medal === ''}
                    name='medal'
                    value=''
                    onChange={() => setMedal('')}
                /> No
            </label>
            <label>
                <input type='radio'
                    value='Bronze'
                    checked={medal === 'Bronze'}
                    name='medal'
                    onChange={() => setMedal('Bronze')}
                /> Bronze
            </label>
            <label>
                <input type='radio'
                    checked={medal === 'Gold'}
                    name='medal'
                    value='Gold'
                    onChange={() => setMedal('Gold')}
                /> Gold
            </label>
            <label>
                <input type='radio'
                    checked={medal === 'tie-breaker'}
                    name='medal'
                    value='tie-breaker'
                    onChange={() => setMedal('tie-breaker')}
                /> Gold-TB
            </label>
            <div>
                    <div>
                        Supporting Referee: 
                        <LookupReferee 
                            show={show}
                            addReferee={addSecondReferee}/>
                    </div>
                    <label>
                        <input
                            type="checkbox"
                            placeholder='paid'
                            checked={secondPaid}
                            onChange={() => setSecondPaid(secondPaid => !secondPaid)} />
                            PAID $
                            <input type="number"
                            style={{ width: '50px' }}
                            value={secondAmount}
                            onChange={e => setSecondAmount(e.target.value)} />
                    </label>
                </div>
            </div>

            <div>

                <button onClick={addCompletedMatch}>Save Match</button>
                
            </div>

            <div>
           

            </div>

        </Modal>
        </div>

    )
}
export default AddCompletedMatch