import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import axios from 'axios'
import 'moment-timezone';
import '../RefDesk.css'
import AssignMagnet from './AssignMagnet'
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import { LinearProgress } from '@material-ui/core';

const Assign = props => {
    const [paid, setPaid] = useState(true)
    const [amount, setAmount] = useState(0)
    const [sysid, setSysid] = useState('')
    const [medal, setMedal] = useState('')
    const [loader, setLoader] = useState(false)
    const [wait, setWait] = useState(false)
    const [alt, setAlt] = useState(0)
    const [altAmountShown, setAltAmountShown] = useState(false)

    const tid = useSelector(state => state.tid)
    const organization = useSelector(state => state.organization)
    const pr1 = useSelector(state => state.pr1_amount)
    const pr2 = useSelector(state => state.pr2_amount)
    const certified = useSelector(state => state.certified_amount)
    const level2 = useSelector(state => state.level_two_amount)
    const level1 = useSelector(state => state.level_one_amount)
    const volunteer = useSelector(state => state.volunteer_amount)
    const certified2 = useSelector(state => state.certified_amount2)
    const level22 = useSelector(state => state.level_two_amount2)
    const level12 = useSelector(state => state.level_one_amount2)
    const volunteer2 = useSelector(state => state.volunteer_amount2)
    const postPaid = useSelector(state => state.referee_post_pay)
    const saveTag = useSelector(state => state.save_tag)

    
    // useEffect(
    //     () => { assignMatch() }, [props.assignedRef])
    useEffect(
        () => { assignMatch() }, [props.court])
    useEffect(
        () => { handleAmount() }, [props.assignedRef])
    useEffect(
        () => { handleProps() }, [postPaid])

        //if postpaid then postpaid = true so has to be !postPaid to show paid

    const handleProps = () => setPaid(!postPaid)

    const handleSwapAlt = () => {
        if(altAmountShown){
            handleAmount()
        }else {
            handleAmountAlt()
        }
    }

    const handleAmount = () => {
        setAltAmountShown(false)
        if(saveTag && props.assignedRef.tag != null){
            if(sysid.includes(props.assignedRef.tag)){}
            else{
            setSysid(sysid + ' '+ props.assignedRef.tag)
            }
        }
        let a = 0
        let credentials = props.assignedRef.credentials
        if(organization === 'ppa' && props.assignedRef.ppa_rating === 'PR1'){
            setAmount(pr1)
            a = pr1
        }
        else if(organization === 'ppa' && props.assignedRef.ppa_rating === 'PR2'){
            setAmount(pr1)
            a = pr1
        }
        else if(organization === 'ppa' && props.assignedRef.ppa_rating === 'PR'){
            setAmount(pr1)
            a = pr1
        }
        else if (credentials === 'Certified') {
            setAmount(certified)
            setAlt(certified2)
            a = certified
        }
        else if (credentials === 'Level 1') {
            setAmount(level1)
            setAlt(level12)
            a = level1
        }
        else if (credentials === 'Level 2') {
            setAmount(level2)
            setAlt(level22)
            a = level2 
        } 
        else {
            setAmount(volunteer)
            setAlt(volunteer2)
            a = volunteer
        }
        if(props.court){
            assignMatchRefSecond(a)
        }else {
        assignMatch()
        }
    }
    const handleAmountAlt = () => {
        setAltAmountShown(true)

        let credentials = props.assignedRef.credentials
        if(organization === 'ppa' && props.assignedRef.ppa_rating === 'PR1'){
            setAmount(pr1)
        }
        else if(organization === 'ppa' && props.assignedRef.ppa_rating === 'PR2'){
            setAmount(pr2)
        }
        else if (credentials === 'Certified') {
            setAmount(certified2)
            setAlt(certified)
        }
        else if (credentials === 'Level 1') {
            setAmount(level12)
            setAlt(level1)
        }
        else if (credentials === 'Level 2') {
            setAmount(level22)
            setAlt(level2)
        } else {
            setAmount(volunteer2)
            setAlt(volunteer)
        }
    }
    const assignMatch = () => {
        if(wait){return} 
        if (props.assignedRef.ref_id > 0 && props.court && !props.reassign) {  
            setWait(true)      
            const court = props.court
            const ref_id = props.assignedRef.ref_id
            axios.post(`/api/assignCourt/${tid}`, {
                court_num: court, ref_id, medal, sysid, paid, amount, credentials: props.assignedRef.credentials
            }).then(res => {
                setWait(false)
                if(res.status === 201){
                    toast.error(`CONFLICT- Referee is on court ${res.data.court_num}, Select new referee`)
                }else {
                    setPaid(!postPaid)
                    setSysid('')
                    setMedal('')
                    setAlt(false)
                props.get(res.data[0])
                
                }
            })
        }
    }

    const assignMatchRefSecond = (amount) => {
        if(wait){return} 
        if (props.assignedRef.ref_id > 0 && props.court && !props.reassign) {  
            setWait(true)      
            const court = props.court
            const ref_id = props.assignedRef.ref_id
            axios.post(`/api/assignCourt/${tid}`, {
                court_num: court, ref_id, medal, sysid, paid, amount, credentials: props.assignedRef.credentials
            }).then(res => {
                setWait(false)
                if(res.status === 201){
                    toast.error(`CONFLICT- Referee is on court ${res.data.court_num}, Select new referee`)
                }else {
                    setPaid(!postPaid)
                    setSysid('')
                    setMedal('')
                props.get(res.data[0])
                
                }
            })
        }
    }

    const clear = () => {
        props.clearAssign()
        setPaid(!postPaid)
        setSysid('')
        setMedal('')
    }

    const keyPressed = (event) => {
        const code = event.which;
        // if (code === 112) {
        //     setPaid(true)
        // }
        if (code === 98) {
            setMedal('Bronze')
        }
        if (code === 103) {
            setMedal('Gold')
        }
    }

    return (
        <div className='finishMatch' align="center"
            onKeyPress={e => keyPressed(e)}
        >
            <div>
                <div>
                    {wait ? <LinearProgress color="secondary"/>:null}
                    <div className='assign-header'>Match Assigner</div>
                </div>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            placeholder='paid'
                            checked={paid}
                            onChange={() => setPaid(paid => !paid)} />
                            PAID $
                            <input type="number"
                            style={{ width: '40px' }}
                            value={amount}
                            onChange={e => setAmount(e.target.value)} />
                    </label>
                    {alt > 0 &&
                    <button onClick={handleSwapAlt}>$ {alt}</button> }
                    
                </div>
            </div>
            <AssignMagnet
                referee={props.assignedRef}
                court={props.court}
                medal={medal}
                show={false} />


            <input
                type="text"
                placeholder='note'
                name="sysid"
                // ref={input => input && input.focus()}
                value={sysid || ''}
                style={{ width: '100px' }}
                onChange={e => setSysid(e.target.value)} />
            <legend>Medal Match?</legend>
            <label>
                <input type='radio'
                    checked={medal === ''}
                    name='medal'
                    value={medal}
                    onChange={() => setMedal('')}
                /> No </label>
            <label>
                <input type='radio'
                    value='Bronze'
                    checked={medal === 'Bronze'}
                    name='medal'
                    onChange={() => setMedal('Bronze')}
                /> Bronze </label>
            <label>
                <input type='radio'
                    checked={medal === 'Gold'}
                    name='medal'
                    value='Gold'
                    onChange={() => setMedal('Gold')}
                /> Gold </label>
            {loader ? <CircularProgress /> : ''}
            {/* {props.court ?
                props.assignedRef.ref_id > 0 ?
                    <p><button onClick={props.assign}>
                        Submit

                </button></p>
                    : '' : ''} */}
            <p><small>Text will be sent to ref when assigned</small></p>

            <p className='pointer' onClick={clear}><i className="fas fa-trash" ></i>Clear</p>




        </div >


    )
}



export default Assign;