import React from 'react'

export default function VolunteerShiftList(props){
    return(
        <div style={{background: props.row.color, display: 'flex'}} >
            <input type='checkbox' checked={props.selected} onClick={()=>props.select(props.row.sid)}/>
            <span>
            {props.row.category}- {props.row.name} {props.row.start} - {props.row.end}
            </span>
            
        </div>
    )
}