import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './Login.css';
import { updateUser } from '../../ducks/reducer';
import ForgotPassword from './ForgotPassword'

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            first: '',
            last: '',
            email: '',
            emailIsValid: true,
            password: '',
            confirmPassword: '',
            view: 'login',
            showForgotPassword: false,
            loginFailed: false,
            errorMessage: '',
            passwordMessage: '',
            phone: '',
            altPhone: '',
            phoneErrorText: '',
            altPhoneErrorText: '',
            credentials: 'Not Rated',
            yesText: false
        }
    }

    register = async () => {
        this.setState({ errorMessage: '' })
        if (this.state.password !== this.state.confirmPassword) {
            this.setState({ errorMessage: `Passwords Do Not Match` })
            return this.setState({ password: '', confirmPassword: '' })

        }

        if (this.state.emailIsValid !== true) {
            return this.setState({ password: '', confirmPassword: '' })

        }
        if (this.state.altPhone.length > 1 && this.state.altPhone.length !== 10) {
            return
        }
        if (this.state.phone && this.state.phone.length !== 10) {
            return
        }
        let user = {
            first: this.state.first,
            last: this.state.last,
            email: this.state.email,
            password: this.state.password,
            phone: this.state.phone,
            alt_phone: this.state.altPhone,
            credentials: this.state.credentials
        }
        try {
            let res = await axios.post('/auth/register', user)
            if (res.data.message) { this.setState({ errorMessage: res.data.message }) }
            else {
                this.props.updateUser(res.data)

                // this.props.history.push('/')
            }
        }
        catch (err) {
            if (err.message) { this.setState({ errorMessage: err.message }) }
            this.setState({
                password: '',
                confirmPassword: ''
            })


        }
    }

    isValidEmailAddress = (address) => {
        return !!address.match(/.+@.+/)
    }

    doPasswordsMatch = () => {
        if (this.state.password !== this.state.confirmPassword)
            this.setState({ passwordMessage: 'passwords do not match' })


    }

    login = async () => {
        if (this.props.clearErrorMessage) { this.props.clearErrorMessage() }
        this.setState({ errorMessage: '' })
        let user = {
            email: this.state.email,
            password: this.state.password
        }
        try {
            if (this.state.emailIsValid) {

                let res = await axios.post('/auth/login', user)
                if (res.data.message === 'Email not found, please signup') { this.setState({ errorMessage: 'Email not found, please signup' }) }
                else if (res.data.message === 'incorrect password, please try again') { this.setState({ errorMessage: 'incorrect password, please try again' }) }
                else if (res.data.message) { this.setState({ errorMessage: 'Error logging in, please try again' }) }
                else if (res.data.user_id) {
                    this.props.updateUser(res.data)
                    if (this.props.showLogin) { this.props.showLogin() }
                }

            }

        } catch (err) {
            if (err.message) { this.setState({ errorMessage: 'Error logging in, please try again' }) }
            this.setState({
                userName: '',
                email: '',
                password: '',
                loginFailed: true
            })

        }
    }

    logout = async () => {
        await axios.post('/auth/logout')
        this.props.clearUser()
        this.props.history.push('/')
    }

    toggleView = () => {
        if (this.props.clearErrorMessage) { this.props.clearErrorMessage() }
        this.clearState()
        this.setState({
            view: this.state.view === 'login' ? 'register' : 'login'
        })
    }

    toggleForgotPassword = () => {
        this.setState({
            showForgotPassword: this.state.showForgotPassword === false ? true : false
        })
    }


    handleChange = (e) => {
        if (this.props.errorMessage) {
            if (this.props.errorMessage.length > 0) { this.props.clearErrorMessage() }
        }
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handlePhone = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            this.setState({ phone: onlyNums, phoneErrorText: 'US Phone - 10 numbers' });
        } else if (onlyNums.length === 10) { this.setState({ phone: onlyNums, phoneErrorText: '' }) }
    }

    handleAltPhone = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            this.setState({ altPhone: onlyNums, altPhoneErrorText: 'US Phone - 10 numbers' });
        } else if (onlyNums.length === 10) { this.setState({ altPhone: onlyNums, altPhoneErrorText: '' }) }
    }
    enterPressed(event) {
        const code = event.keyCode || event.which;
        if (code === 13) {
            this.login()
        }
    }

    clearState = () => this.setState({
        first: '',
        last: '',
        email: '',
        emailIsValid: true,
        password: '',
        confirmPassword: '',
        loginFailed: false,
        errorMessage: '',
        passwordMessage: '',
        phone: '',
        altPhone: '',
        phoneErrorText: '',
        altPhoneErrorText: '',
        credentials: 'Not Rated'
    })

    render() {
        const { email, password } = this.state;
        return (
            <div className='container'>
                <div className="Login col-12 col-7" align="center">

                    {
                        this.props.user_id ?
                            <div>

                            </div> : this.state.view === 'login' ?
                                <div className='row'>
                                    <div className='col-12 login'>
                                        <h3> Login</h3>

                                        <input type="text"
                                            name='email'
                                            placeholder='email'
                                            value={email}
                                            onChange={this.handleChange}
                                            onBlur={() => this.setState({
                                                emailIsValid: this.isValidEmailAddress(this.state.email), errorMessage: ''
                                            })} />

                                        <input type="password"
                                            placeholder='password'
                                            value={password}
                                            name='password'
                                            onKeyPress={e => this.enterPressed(e)}
                                            onChange={this.handleChange} />



                                        <p style={{ color: 'red' }}>{this.props.errorMessage}</p>
                                        <p style={{ color: 'red' }}>{!this.state.emailIsValid && this.state.email.length > 0 ? 'invalid email' : ''}</p>
                                        <p style={{ color: 'red' }}>{this.state.errorMessage}</p>
                                        <button className='login-button' onClick={this.login}>Login</button>
                                        <h4 onClick={this.toggleView}>Don't have an account?  Register Here</h4>


                                        <p>Disclaimer: by using this site you consent to the use of cookies.</p>
                                        <div>{this.state.showForgotPassword === true ? <ForgotPassword /> : <button className='forgot-password-button' onClick={this.toggleForgotPassword}>Forgot Password?</button>}</div>
                                    </div>
                                </div> :
                                <div className='row'>
                                    <div className='col-12 login'>
                                        <h3> Register</h3>
                                        <input type="text"
                                            placeholder='First Name'
                                            value={this.state.first}
                                            name='first'
                                            onChange={this.handleChange} />
                                        <input
                                            type="text"
                                            placeholder='Last Name'
                                            name='last'
                                            value={this.state.last}
                                            onChange={this.handleChange} />
                                        <input type="email"
                                            placeholder='email'
                                            value={email}
                                            name='email'
                                            onChange={this.handleChange}
                                            onBlur={() => this.setState({
                                                emailIsValid: this.isValidEmailAddress(this.state.email)
                                            })
                                            }
                                        />
                                        <legend>Phone Number
                                        <input type="integer"
                                                name='altPhone'
                                                maxLength='10'
                                                placeholder='Phone Number'
                                                value={this.state.altPhone}
                                                onChange={this.handleAltPhone}
                                            />
                                        </legend>
                                        <span style={{ color: 'red' }}>{this.state.altPhoneErrorText}</span>

                                        <div className='paper'>
                                            <legend >
                                                Want to receive text message reminders?

                                                    <button
                                                    style={this.state.yesText ? { border: '3px solid black' } : {}}
                                                    onClick={() => this.setState({ phone: this.state.altPhone, yesText: true })}>Yes</button>

                                                <button
                                                    style={this.state.yesText ? {} : { border: '3px solid black' }}
                                                    onClick={() => this.setState({ phone: this.state.altPhone, yesText: false })}>No</button>

                                            </legend>
                                            {this.state.yesText ?
                                                <legend>
                                                    Text Phone Number:
                                                <input type="integer"
                                                        placeholder='Text Phone'
                                                        name='phone'
                                                        maxLength='10'
                                                        value={this.state.phone}
                                                        onChange={this.handlePhone}
                                                    />
                                                </legend> : ''}
                                        </div>
                                        <span style={{ color: 'red' }}>{this.state.phoneErrorText}</span>
                                        <div className='paper'>
                                            <p>Referee Credentials</p>
                                            <legend >
                                                <label>
                                                    <input type='radio'
                                                        name='credentials'
                                                        value='Not Rated'
                                                        checked={this.state.credentials === 'Not Rated'}
                                                        onClick={this.handleChange}
                                                    /> Not Rated </label>
                                                <label>
                                                    <input type='radio'
                                                        name='credentials'
                                                        value='Level 1'
                                                        checked={this.state.credentials === 'Level 1'}
                                                        onClick={this.handleChange}
                                                    /> Level 1 </label>
                                                <label>
                                                    <input type='radio'
                                                        name='credentials'
                                                        checked={this.state.credentials === 'Level 2'}
                                                        value='Level 2'
                                                        onClick={this.handleChange}
                                                    /> Level 2 </label>
                                                <label>
                                                    <input type='radio'
                                                        name='credentials'
                                                        checked={this.state.credentials === 'Certified'}
                                                        value='Certified'
                                                        onClick={this.handleChange}
                                                    /> Certified </label>
                                            </legend>

                                        </div>


                                        <input
                                            type="password"
                                            placeholder='password'
                                            value={password}
                                            name='password'
                                            onChange={this.handleChange} />
                                        <input type="password"
                                            placeholder='confirm password' value={this.state.confirmPassword}
                                            name='confirmPassword'
                                            onChange={this.handleChange}
                                            onBlur={this.doPasswordsMatch} />
                                        <p style={{ color: 'red' }}>{this.state.errorMessage}</p>
                                        <p style={{ color: 'red' }}>{this.state.emailIsValid ? '' : 'invalid email'}</p>
                                        <p style={{ color: 'red' }}>{this.state.passwordMessage}</p>

                                        <button className='register-button' onClick={this.register}>Register</button>
                                        <h4> <a onClick={this.toggleView}>Already have an account?</a></h4>
                                        <p>Disclaimer: by using this site you consent to the use of cookies.</p>
                                    </div>
                                </div>
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = (reduxState) => {
    return {
        user_id: reduxState.user_id
    }
}
const mapDispatchToProps = {
    updateUser
}
export default (connect(mapStateToProps, mapDispatchToProps))(withRouter(Login));