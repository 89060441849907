import React, { Component } from 'react'
import axios from 'axios'
import { connect } from "react-redux"
import MUIDataTable from 'mui-datatables'
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Modal from 'react-responsive-modal';
import TournamentRefs from './TournamentRefs'
import GetRefSchedule from './GetRefSchedule'
import './Schedule.css'

class Assignments extends Component {
    constructor() {
        super()
        this.state = {
            referees: [],
            timeSpots: [],
            spot: '',
            pickRef: false,
            refSchedule: [],
            refId: [],
            showSchedule: false


        }
    }

    componentDidMount() {
        this.getTimeSpots()
        this.getRegisteredRefs()
    }

    getRegisteredRefs = async () => {
        await axios.get(`/api/getRegisteredRefs/${this.props.tid}`).then(
            res => {
                this.setState({ referees: res.data })
            }
        ).then(
            await this.getAvailableRefs
        )
    }

    getTimeSpots = async () => {
        await axios.get(`/api/getTimeSpots/${this.props.tid}`).then(
            res => {
                this.setState({ timeSpots: res.data })
            }
        )
    }

    assignRef = async (id) => {
        axios.put(`/api/putTimeSpots/${this.state.spot}`, { tid: this.props.tid, ref_id: id }).then(
            res => {
                this.getTimeSpots()
                this.setState({ pickRef: false, spot: null })
            }
        )
    }

    removeRef = () => {
        axios.put(`/api/removeRefTime/${this.state.spot}`).then(
            res => {
                this.getTimeSpots()
                this.setState({ pickRef: false, spot: null })
            }
        )
    }

    onOpenModal = () => {
        this.setState({ pickRef: true });
    };

    onCloseModal = () => {
        this.setState({ pickRef: false });
    };

    checkOut = async (id) => {

        await axios.put(`/api/checkin/${this.props.tid}`, { ref_id: id[9] }).then(
            res => {
                this.getTimeSpots()
            }
        )
    }

    checkIn = (row) => {
        axios.put(`/api/checkinForSpot/${row[0]}`, { tid: this.props.tid }).then(
            res => {
                this.getTimeSpots()
            }
        )
    }

    getSchedule = async (id) => {
        await axios.post(`/api/getRefSchedule/${id}`, { tid: 3 }).then(
            res => {
                this.setState({ refSchedule: res.data, showSchedule: true })
            }
        )
    }

    onCloseSchedule = () => {
        this.setState({ showSchedule: false });
    };





    render() {
        const columns = [
            { name: "time_id", label: "time_id", options: { display: false } },
            {
                name: "checkin",
                label: 'Shift Check-in',
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let row = tableMeta.rowData

                        return (
                            <div>
                                {value ? <i className="fas fa-check-circle"></i> :
                                    <FormControlLabel
                                        label=''
                                        value={value ? true : false}
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={value}
                                                value={value ? true : false}
                                            />
                                        }

                                        onClick={() => this.checkIn(row)}
                                    />
                                }
                            </div>

                        );

                    }
                }
            },
            {
                name: "status",
                label: 'Currently Reffing',
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let id = tableMeta.rowData
                        return (

                            <FormControlLabel
                                label={value ? "Active" : "Inactive"}
                                value={value ? "Active" : "Inactive"}
                                control={
                                    <Switch
                                        color="primary"
                                        checked={value}
                                        value={value ? "Active" : "Inactive"}
                                    />
                                }

                                onClick={e => this.checkOut(id)}
                            />
                        );
                    }
                }
            },
            { name: "date", label: "date", options: { filter: true, sort: true, } },
            { name: "start", label: "start", options: { filter: true, sort: true, } },
            { name: "stop", label: "end", options: { filter: true, sort: true, } },
            { name: "first", label: "first", options: { filter: true, sort: true, display: true } },
            { name: "last", label: "last", options: { filter: true, sort: true, display: true } },
            { name: "credentials_verified", label: "credentials", options: { filter: true, sort: true, } },
            {
                name: "ref_id", label: "Schedule", options: {
                    filter: false, sort: false, customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <i className="far fa-calendar" onClick={() => this.getSchedule(value)}></i>

                        )
                    }

                }
            },
            {
                name: "time_id", label: "Add", options: {
                    filter: false, sort: false, customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <i className="fas fa-user-plus" onClick={() => this.setState({ spot: value, pickRef: true })}></i>

                        )
                    }

                }
            },


        ]
        return (
            <div className='col-12 Assignments'>
                <h2>Scheduler</h2>
                <MUIDataTable
                    title={'Time Assignments'}
                    data={this.state.timeSpots}
                    columns={columns}
                    options={{ selectableRows: 'none', print: true, download: true, rowsPerPage: 100 }}

                />
                {this.state.pickRef ?
                    <Modal open={true} center onClose={this.onCloseModal} closeIconSize={2}>
                        <TournamentRefs
                            referees={this.state.referees}
                            assignRef={this.assignRef}
                            removeRef={this.removeRef} />
                    </Modal> : ''}

                {this.state.showSchedule ?
                    <Modal open={true} center onClose={this.onCloseSchedule} >
                        <GetRefSchedule
                            refId={this.state.refId}
                            refSchedule={this.state.refSchedule}
                        />
                    </Modal> : ''}

            </div>
        )
    }
}

const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name
    }
}


export default connect(
    mapStateToProps,
)(Assignments)