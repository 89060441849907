import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const RefereeTotals = (props) => {
    const [total, setTotal] = useState(0)
    const [leadTotal, setLeadTotal] = useState(0)
    const [supportingTotal, setSupportingTotal] = useState(0)
    const [postedTotal, setPostedTotal] = useState(0)
    const [notPaid, setNotPaid] = useState(0)
    const [leadCount, setLeadCount] = useState(0)
    const [supportCount, setSupportCount] =  useState(0)
    const [notPaidCount, setNotPaidCount] = useState(0)
    const [auxCount, setAuxCount] = useState(0)
    const [auxTotal, setAuxTotal] = useState(0)
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)
    const classes = useStyles()
    let {ref_id, referee} = props

    useEffect(
        () => { getTotal() }, [ref_id])
    useEffect(
            () => { getTotal() }, [props.update])
    
        const getTotal = async () => {
            if(!ref_id){return}
            axios.post(`/api/getRefereeTotalsForInvoice/${tid}`, {ref_id}).then(
                res => {
                    setTotal(res.data.total || 0)
                    setLeadTotal(res.data.lead_sum || 0)
                    setSupportingTotal(res.data.supporting_sum || 0)
                    setPostedTotal(res.data.posted_total || 0)
                    setNotPaid(res.data.total_not_paid || 0)
                    setLeadCount(res.data.lead_count || 0)
                    setSupportCount(res.data.supporting_count || 0)
                    setNotPaidCount(res.data.not_paid_count || 0)
                    setAuxTotal(res.data.aux_sum || 0)
                    setAuxCount(res.data.aux_count || 0)
                }
            ).catch(error => {
                if (error.response) {
                    
                }
            })
        }

    return(
        <div>
            <Grid container justify="center">
            <Grid item xs={12} sm={6}>

                <div style={{textAlign: 'left', background: 'white', padding: '10px', marginRight: '20px'}}>
                <h3>{referee.first} {referee.last}</h3>
                <TableBody className={classes.totals}>
                <TableRow >
                    <TableCell>Address</TableCell>
                    <TableCell>
                        <div>
                            {referee.address}
                            <br/>
                            {referee.city} {referee.state} {referee.zip}
                        </div>
                    </TableCell>
                </TableRow>
                <TableRow >
                    <TableCell>Paypal</TableCell>
                    <TableCell>
                        {referee.paypal}
                    </TableCell>
                </TableRow>
                <TableRow >
                    <TableCell>Venmo</TableCell>
                    <TableCell>
                        {referee.venmo}
                    </TableCell>
                </TableRow>
                <TableRow >
                    <TableCell>Zelle</TableCell>
                    <TableCell>
                        {referee.zelle}
                    </TableCell>
                </TableRow>
                
            </TableBody>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
            <TableBody className={classes.totals}>
                <TableRow >
                    <TableCell>Total Paid</TableCell>
                    <TableCell className={classes.total}>${total}</TableCell>
                    <TableCell>Total Not Paid</TableCell>
                    <TableCell className={classes.total}>{notPaidCount} / ${notPaid}</TableCell>
                </TableRow>
                
                <TableRow className={classes.total}>
                    <TableCell>Total Posted</TableCell>
                    <TableCell className={classes.total}>${postedTotal}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>

                </TableRow>
                
                <TableRow>
                    <TableCell>{`${leadCount} Lead Referee`}</TableCell>
                    <TableCell>${leadTotal}</TableCell>
                    <TableCell>{`${supportCount} Supporting Referee`}</TableCell>
                    <TableCell>${supportingTotal}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{`${auxCount} Aux Assignments`}</TableCell>
                    <TableCell>${auxTotal}</TableCell>
                </TableRow>

            </TableBody>
            </Grid>
            

            </Grid>

   

        </div>
    )
}
export default RefereeTotals

const useStyles = makeStyles({
    table: {
      minWidth: 650,
      background: 'white'
    },
    totals: {
        background: 'white'
    },
    total: {
        fontSize: '20px'
    }
  });