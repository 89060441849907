import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'


const RefereeNamesForInvoice = props => {
    const [referees, setReferees] = useState([])

    const tid = useSelector(state => state.tid)


    useEffect(
        () => { getReferees() }, [tid])

    const getReferees = () => {
        if (tid) {
            axios.get(`/api/getRefereesUnpaidMatches/${tid}`).then(res => {
                setReferees(res.data)
            })
        }
    }


    const handleSelectReferee = e => {
        props.setReferee(referees[e.target.value])
    }

    return(
        <div className='text-align-left'>
             <select name="status"
                    value={props.referee}
                    className='dropdown'
                    onChange={handleSelectReferee}
                >
                    <option key={'s'}>
                        Select Referee
            </option>
                    {referees.map((r, i)=> (
                        <option key={'r'+i} value={i}>{r.last}, {r.first}</option>
                    ))}
                </select>

        </div>
    )
}
export default RefereeNamesForInvoice