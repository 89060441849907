import React, { useState } from 'react'
import axios from 'axios'


const AddCourts = props => {
    const [courts, setCourts] = useState(0)
    const [status, setStatus] = useState('')
    const [num, setNum] = useState('')


    const addOneCourt = () => {
        axios.post(`/api/t/addOneCourt`, { tid: props.match.params.tid, num }).then(
            res => {
                setStatus('Court Added')
                setNum('')
            }
        )
    }
    
    const addCourts = () => {
        axios.post(`/api/t/addCourts`, { tid: props.match.params.tid, courts }).then(
            res => {
                setStatus('Courts Added')
            }
        )
    }

    

    const deleteCourts = () => {
        axios.post(`/api/t/deleteCourts`, { tid: props.match.params.tid, courts }).then(
            res => {
                setStatus('Deleted Courts')
            }
        )
    }


    return (
        <div>
            <h2>Add Courts</h2>
            <h5>TID: {props.match.params.tid} -  {props.match.params.name}</h5>
            {/* <p>If there are existing courts it will add to them.</p> */}

           
            <legend>
                Number of Courts
                <input type="number"
                    value={courts}
                    onChange={e => setCourts(e.target.value)} />
            </legend>

            <button onClick={addCourts}>Add Courts</button>

            {/* <button
                style={{ margin: '30px' }}
                onClick={deleteCourts}>Delete Courts</button> */}

                <label htmlFor="">
                    Court Number
                    <input type="text"
                        value={num}
                        onChange={e=>setNum(e.target.value)}/>
                </label>
                <button onClick={addOneCourt}>Save</button>

            <h4>{status}</h4>

        </div>
    )
}
export default AddCourts