import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";

class EditProcedures extends Component {
  constructor(props) {
    super(props);

    this.state = {
      body: "",
    };
  }
  componentDidMount() {
    this.getBody();
  }

  getBody = () => {
    axios
      .post(`/api/getProcedures/${this.props.tid}`, {
        ref_id: this.props.ref_id,
      })
      .then((res) => {
        if (res) {
          this.setState({ body: res.data.procedures });
        }
      });
  };

  handleBody = (value) => {
    this.setState({ body: value });
  };

  updateBody = () => {
    axios
      .put(`/api/updateProcedures/${this.props.tid}`, {
        procedures: this.state.body,
      })
      .then((res) => {
        this.setState({ body: res.data.procedures });
        toast.success(`Saved`);
      });
  };

  render() {
    return (
      <div className="col-12">
        <h4>Procedures</h4>
        <h4
          onClick={() => {
            navigator.clipboard.writeText(
              `https://www.pickleballdesk.com/procedures/${this.props.tid}`
            );
          }}
        >
          Public URL: https://www.pickleballdesk.com/procedures/{this.props.tid}
        </h4>
        <button
          onClick={() => {
            navigator.clipboard.writeText(
              `www.pickleballdesk.com/procedures/${this.props.tid}`
            );
          }}
        >
          Copy URL
        </button>
        <div style={{ background: "white" }}>
          <ReactQuill
            className="description"
            value={this.state.body}
            onChange={this.handleBody}
          />
        </div>
        <button onClick={this.updateBody}>Save</button>
      </div>
    );
  }
}
const mapStateToProps = (reduxState) => {
  return {
    tid: reduxState.tid,
    ref_id: reduxState.ref_id,
  };
};

export default connect(mapStateToProps)(EditProcedures);
