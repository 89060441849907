import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import '../Schedule/AddRefToSchedule.css'
import AddVolunteerFromSystem from './AddVolunteerFromSystem'
import AddVolunteer from './AddVolunteer'
import VolunteerList from './SchedulingUtilities/VolunteerList'
import MappedShift from './SchedulingUtilities/MappedShift'

const AddVolunteerToSchedule = props => {
    const [data, setData] = useState([])
    const [shiftsByName, setShiftsByName] = useState([])
    const [shiftsByTime, setShiftsByTime] = useState([])
    const [volunteers, setVolunteers] = useState([])
    const [shift, setShift] = useState([])
    const [volunteer, setVolunteer] = useState({})
    const [waitlist, setWaitlist] = useState(false)
    const [captain, setCaptain] = useState(false)
    const [searchFirst, setSearchFirst] = useState('')
    const [searchLast, setSearchLast] = useState('')
    const [searchName, setSearchName] = useState('')
    const [categoryFilter, setCategoryFilter] = useState('')
    const [showAddVolunteer, setShowAddVolunteer] = useState(false)
    const [showByName, setShowByName] = useState(false)

    const tid = useSelector(state => state.tid)
   
    useEffect(
        () => { getShiftSpots() }, [])
        useEffect(
            () => { getRegisteredVolunteers() }, [])

    const getShiftSpots = () => {
        axios.post(`/api/volunteer/getShiftSpotsForCategory${props.vcid}`, {tid}).then(
            res => {
                if (showByName) {
                    
                    setShiftsByName(res.data.name)
                    setShiftsByTime(res.data.time)
                } else {
                    setData(res.data.time)
                    setShiftsByName(res.data.name)
                    setShiftsByTime(res.data.time)
                }
            }
        )
    }

    const getRegisteredVolunteers = async () => {
        await axios.get(`/api/getRegisteredVolunteers/${tid}`).then(
            res => {
                setVolunteers(res.data)
            }
        )
    }
    const assignCaptain = async () => {
        axios.post(`/api/putCaptainInShift/${shift.sid}`, { tid: tid, ref_id: volunteer.ref_id }).then(
            res => {
                getShiftSpots()
                setVolunteer({})
                setShift({})
                setCaptain(false)
            }
        ).catch(error => {
            if (error.response) {
                toast.warning(error.response.data)
                setVolunteer({})
                setShift({})
                setCaptain(false)

            }
        })
    }

    const assignVolunteer = async () => {
        axios.put(`/api/putVolunteerInShift/${shift.sid}`, { tid: tid, ref_id: volunteer.ref_id, waitlist: waitlist }).then(
            res => {
                if(res.data.conflict){
                    toast.error(`CONFLICT - ${res.data.conflict.name} ${res.data.conflict.start} - ${res.data.conflict.end}`)
                }else if (res.data.length > 0){
                    toast.error(res.data )
                }
                else {
                getShiftSpots()
                setVolunteer({})
                setShift({})
                }
            }
        ).catch(error => {
            if (error.response) {
                toast.warning('System Error')
                setVolunteer({})
                setShift({})

            }
        })
    }

    const addWaitList = async () => {
        axios.put(`/api/putWaitlist/${shift.sid}`, { tid, ref_id: volunteer.ref_id }).then(
            res => {
                getShiftSpots()
                setVolunteer({})
                setShift({})
            }
        ).catch(error => {
            if (error.response) {
                toast.warning(error.response.data)
                setVolunteer({})
                setShift({})
                setWaitlist(false)
            }
        });

    }

    const addVolunteerToDBandTournament = (refInfo) => {
        axios.post(`/api/addVolunteerToDBandTournament/${tid}`, { refInfo }).then(
            res => {
                getRegisteredVolunteers()
                this.handleShowAddVolunteer()
            }
        )
    }

    const addVolunteerToTournament = (ref_id) => {
        axios.post(`/api/addVolunteerToTournament/${tid}`, { ref_id }).then(
            res => {
                getRegisteredVolunteers()
                setShowAddVolunteer(false)
            }
        )
    }

    setShift = async (shift, bol) => {
        await setShift(shift)
        await setWaitlist(bol)
        if (shift.sid && volunteer.ref_id && waitlist === false && !captain) {
            assignVolunteer()
        }
        else if (shift.sid && volunteer.ref_id && waitlist === true && !captain) {
            addWaitList()
        }
        else if (shift.sid && volunteer.ref_id && waitlist === true && captain) {
            assignCaptain()
        }
    }


    const handleSelectVolunteer = async (volunteer) => {
        await setVolunteer(volunteer)
        if (shift.sid && volunteer.ref_id && !captain) { this.assignVolunteer() }
        else if (shift.sid && volunteer.ref_id && captain) { this.assignCaptain() }
    }

    const clearSearch = () => {
        setSearchFirst('')
        setSearchLast('')
    }

    const handleShowAddVolunteer = () => setShowAddVolunteer(showAddVolunteer => !showAddVolunteer )

    const handleShowByName = () => {
        setShowByName(true)
        setData(shiftsByName)
    }

    const handleShowByTime = () => {
        setShowByName(false)
        setData(shiftsByTime)
    }

    const handleAddCaptain = (shift) => {
        setShift(shift)
        setCaptain(true)
        }

        const mappedShifts = data
            .filter(obj => obj.category.includes(categoryFilter))
            .filter(obj => obj.name.toUpperCase().includes(searchName.toUpperCase()))

            .map((shift, i) => 
            <MappedShift 
                key={'s'+i}
                shift={shift}
                selected={shift}
                searchFirst={searchFirst}
                searchLast={searchLast}
                get={this.getShiftSpots}
                handleAddCaptain={this.handleAddCaptain}
                setShift={this.setShift}/>
            )

        const mappedVolunteers = volunteers
            .filter(obj => obj.first.toUpperCase().includes(searchFirst.toUpperCase()))
            .filter(obj => obj.last.toUpperCase().includes(searchLast.toUpperCase()))

            .map((volunteer, i) => 
            <VolunteerList 
                key={'v'+i}
                volunteer={volunteer}
                selected={volunteer}
                setVolunteer={handleSelectVolunteer}/>)
        return (
            <div className='AddRefToSchedule container' >

                <Link to={`/volunteercaptain/scheduling`} >
                    <button className='login-button'>Schedule Setup</button>
                </Link>
                <Link to={`/volunteer/volunteerSchedule`} >
                    <button className='register-button'>Schedule By Name</button>
                </Link>
                <Link to={`/desk/schedulingLog`} >
                    <button className='register-button'>Schedule Log (signup/ removed times)</button>
                </Link>



                <div className='row'>
                    <div className='col-12 col-md-8 '>
                        <div className='search-menu'>
                            <form>

                                <legend> Search by date or title
                            <input
                                        type="text"
                                        placeholder='Search date/ Shift Name'
                                        name="searchName"
                                        value={searchName}
                                        onChange={this.handleChange} />
                                </legend>
                                <legend>
                                    Search by name:

                                    <input
                                        type="text"
                                        placeholder='Search First Name'
                                        name="searchFirst"
                                        value={searchFirst}
                                        onChange={this.handleChange} />

                                    <input
                                        type="text"
                                        placeholder='Search Last Name'
                                        name="searchLast"
                                        value={searchLast}
                                        onChange={this.handleChange} />
                                </legend>
                                <i className="fas fa-ban" onClick={() => this.setState({ searchName: '', categoryFilter: '', searchFirst: '', searchLast: '' })}> clear</i>
                            </form>
                            <button className='searchButtons'
                                onClick={handleShowByName}>Order By Name</button>
                            <button className='searchButtons'
                                onClick={handleShowByTime}>Order by Time</button>
                        </div>

                        <div className='mappedSection'>
                            {mappedShifts}
                        </div>
                    </div>
                    <div className='col-12 col-md-4 '>

                        <div className=''>
                            <input
                                type="text"
                                placeholder='Search by Last Name'
                                name="searchLast"
                                value={searchLast}
                                onChange={this.handleChange} />
                            <input
                                type="text"
                                placeholder='Search by First Name'
                                name="searchFirst"
                                value={searchFirst}
                                onChange={this.handleChange} />
                            <i className="fas fa-ban" onClick={this.clearSearch}></i>
                            <legend>
                                Search by name:

                                    <input
                                    type="text"
                                    placeholder='Search First Name'
                                    name="searchFirst"
                                    value={searchFirst}
                                    onChange={this.handleChange} />

                                <input
                                    type="text"
                                    placeholder='Search Last Name'
                                    name="searchLast"
                                    value={searchLast}
                                    onChange={this.handleChange} />
                            </legend>
                        </div>
                        <h4>Volunteers Registered for Tournament</h4>

                        <div className='mappedSection'>
                            {mappedVolunteers}
                            <button onClick={this.handleShowAddVolunteer}>Add Volunteers</button>
                        </div>
                    </div>
                </div>
                <Modal open={showAddVolunteer} center showCloseIcon={true}
                    onClose={this.handleShowAddVolunteer} closeOnEsc={true} closeIconSize={2}>
                    <div>
                        <h4>Click on name to add volunteer.  If volunteer is not in the system add them at the bottom.</h4>
                        <AddVolunteerFromSystem
                            addRefToTournament={this.addVolunteerToTournament}
                        />
                        <div className='addLineJudge'>
                            <AddVolunteer
                                tid={this.props.tid}
                                addVolunteer={this.addVolunteerToDBandTournament} />
                        </div>
                        <button onClick={this.handleShowVolunteer}>Cancel</button>
                    </div>
                </Modal>
            </div>

        )
    }

export default AddVolunteerToSchedule
