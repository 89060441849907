import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Listing from "./Listing";

const ListOfTournaments = () => {
  const [tournaments, setTournaments] = useState([]);
  const [locationSearch, setLocationSearch] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const [organization, setOrganization] = useState("");
  const [usapa, setUsapa] = useState(null);
  const [canada, setCanada] = useState(null);

  useEffect(() => {
    getTournaments();
  }, []);

  const getTournaments = () => {
    axios.get(`/api/t/getTournaments`).then((res) => {
      setTournaments(res.data);
    });
  };

  const clearSearch = () => {
    setNameSearch("");
    setLocationSearch("");
    setOrganization("");
    setUsapa(null);
    setCanada(null);
  };

  const mappedTournaments = tournaments
    .filter((obj) => obj.name.toUpperCase().includes(nameSearch.toUpperCase()))
    .filter((obj) =>
      obj.location.toUpperCase().includes(locationSearch.toUpperCase())
    )
    .filter((obj) => organization === "" || obj.organization === organization)
    .filter((obj) => obj.usapa != usapa)
    .filter((obj) => obj.pickleball_canada != canada)

    .map((t) => {
      return (
        <div key={t.tid} style={{ color: "black" }}>
          <Link to={`/signup/${t.tid}`}>
            <Listing t={t} />
          </Link>
        </div>
      );
    });

  return (
    <div className="col-12">
      <div className="col-12">
        <legend>
          Search by name:
          <input
            type="text"
            value={nameSearch}
            onChange={(e) => setNameSearch(e.target.value)}
          />
        </legend>
        <legend>
          Search by location:
          <input
            type="text"
            value={locationSearch}
            onChange={(e) => setLocationSearch(e.target.value)}
          />
        </legend>
        <legend>Search by organization:</legend>
        <div>
          <img
            className="search-by-organization pointer"
            onClick={() => setUsapa(false)}
            src={require("../../img/usapickleball-logo.png")}
            alt="USA Pickleball"
          />

          <img
            className="search-by-organization pointer"
            onClick={() => setCanada(false)}
            src={
              "https://res.cloudinary.com/shonda/image/upload/v1650340361/logos/logoPickleballCanada_1_u7hwr8.png"
            }
            alt="APP"
          />
          <img
            className="search-by-organization pointer"
            onClick={() => setOrganization("app")}
            src={
              "https://res.cloudinary.com/shonda/image/upload/v1585598171/app_fgbs8a.png"
            }
            alt="APP"
          />
          <img
            className="search-by-organization pointer"
            onClick={() => setOrganization("np")}
            src={
              "https://res.cloudinary.com/shonda/image/upload/v1612829816/NationalPickleball-stacked_n08msp.png"
            }
            alt="National Pickleball"
          />
          <img
            className="search-by-organization pointer"
            onClick={() => setOrganization("npl")}
            src={
              "https://res.cloudinary.com/shonda/image/upload/v1713838655/LivePickleball/image001_wxplgl.png"
            }
            alt="NPL"
          />
          {/* <img className='search-by-organization pointer'
                        onClick={() => setOrganization('legacy')}

                        src={'https://res.cloudinary.com/shonda/image/upload/v1641486085/PickleballDesk/Legacy-Sports_Logo-01_fqrke9.png'} alt='Legacy' /> */}
          <img
            className="search-by-organization pointer"
            onClick={() => setOrganization("pig")}
            src={
              "https://res.cloudinary.com/shonda/image/upload/v1613689855/pig_v3k4d8.png"
            }
            alt="PIG"
          />
          <img
            className="search-by-organization pointer"
            onClick={() => setOrganization("ppa")}
            src={
              "https://res.cloudinary.com/shonda/image/upload/v1585598171/ppa_dpolxq.png"
            }
            alt="PPA"
          />
          <img
            className="search-by-organization pointer"
            onClick={() => setOrganization("utr")}
            src={
              "https://res.cloudinary.com/shonda/image/upload/v1708965546/logos/_UTRSports_Primary_Horizontal_Color_Black_2_ylhahk.png"
            }
            alt="UTR"
          />
        </div>

        <button className="pointer" onClick={clearSearch}>
          <i className="fas fa-minus-circle"></i>
          Clear Search
        </button>
      </div>

      {mappedTournaments}
    </div>
  );
};

export default ListOfTournaments;
