import React, {useState, useEffect} from 'react'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';



export default function NotificationNameRow(props){
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [loader, setLoader] = useState(false)

    let {row} = props
    
    const handlePhone = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 11) {
            setPhone(onlyNums)
        } else if (onlyNums.length === 11) { setPhone(onlyNums)}
    }

    const update = (id, type, value) => {
        if(phone.length !== 11){return toast.error('Phone number required.  Include country code.')}
        setLoader(true)
        props.update(id, type, value)
    }

    useEffect(
        () => { handleProps() }, [props])
    
        const handleProps = () => {
            setName(row.name)
            setPhone(row.phone)
            setLoader(false)
        }

    return(
            <TableRow key={row.name}>
                 <TableCell align="left">
                <input type="checkbox"
                    checked={row.active}
                    onClick={()=>update(row.id, 'active')} />
            </TableCell>
              <TableCell component="th" scope="row">
               <input type="text"
                value={name}
                onChange={e=>setName(e.target.value)} />
                <i className="far fa-save" onClick={()=>update(row.id, 'name', name)}></i>
              </TableCell>
              <TableCell align="left">
              <input type="text"
                value={phone}
                onChange={handlePhone} />
                <i className="far fa-save" onClick={()=>update(row.id, 'phone', phone)}></i>
              </TableCell>
            <TableCell align="left">
                <input type="checkbox"
                    checked={row.second_call}
                    onClick={()=>update(row.id, 'second')} />
            </TableCell>
            <TableCell align="left">
                <input type="checkbox"
                    checked={row.medical}
                    onClick={()=>update(row.id, 'medical')} />
            </TableCell>
            <TableCell align="left">
                <input type="checkbox"
                    checked={row.td}
                    onClick={()=>update(row.id, 'td')} />
                </TableCell>
            <TableCell align="left">
                <input type="checkbox"
                    checked={row.desk}
                    onClick={()=>update(row.id, 'desk')} />
                </TableCell>
            <TableCell align="left">
                <input type="checkbox"
                    checked={row.head_referee}
                    onClick={()=>update(row.id, 'head ref')} />
                </TableCell>
                <TableCell>
                    {loader && <CircularProgress />}
                </TableCell>
                
            </TableRow>
    )
}
