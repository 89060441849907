import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';


const EditPastMatch = props => {
    const [paid, setPaid] = useState(false)
    const [amount, setAmount] = useState(0)
    const [sysid, setSysid] = useState(null)
    const [medal, setMedal] = useState(null)
    const [secondPaid, setSecondPaid] = useState(false)
    const [secondAmount, setSecondAmount] = useState(undefined)
    const [loader, setLoader] = useState(false)


    let { id, court_num, first, last, second } = props.location.state.row
    useEffect(
        () => { setProps() }, [props.location.state.row])



    const setProps = () => {
        let { amount, sysid, paid, second_paid, second_amount, medal } = props.location.state.row
        setAmount(amount)
        setSysid(sysid)
        setPaid(paid)
        setSecondPaid(second_paid)
        setSecondAmount(second_amount)
        setMedal(medal)
    }

    const save = () => {
        axios.put(`/api/updatePastMatch`, { id, amount, paid, secondAmount, secondPaid, sysid, medal }).then(res => {
            props.history.goBack()

        })
    }



    return (
        <div className='container'>
            <div style={{ textAlign: 'left' }}>
                <h4>Edit Match</h4>
                <div>Court: {court_num}</div>
                <div>Referee: {first} {last}</div>
                <label>
                    <input
                        type="checkbox"
                        placeholder='paid'
                        checked={paid}
                        onChange={() => setPaid(paid => !paid)} />
                            PAID $
                            <input type="number"
                        style={{ width: '50px' }}
                        value={amount}
                        onChange={e => setAmount(e.target.value)} />
                </label>
                <div>Second Referee: {second}</div>
                <label>
                    <input
                        type="checkbox"
                        placeholder='paid'
                        checked={secondPaid}
                        onChange={() => setSecondPaid(paid => !paid)} />
                            PAID $
                            <input type="number"
                        style={{ width: '50px' }}
                        value={secondAmount}
                        onChange={e => setSecondAmount(e.target.value)} />
                </label>

                <legend>Medal Match?</legend>
                <label>
                    <input type='radio'
                        checked={medal === ''}
                        name='medal'
                        value=''
                        onChange={() => setMedal('')}
                    /> No
            </label>
                <label>
                    <input type='radio'
                        value='Bronze'
                        checked={medal === 'Bronze'}
                        name='medal'
                        onChange={() => setMedal('Bronze')}
                    /> Bronze
            </label>
                <label>
                    <input type='radio'
                        checked={medal === 'Gold'}
                        name='medal'
                        value='Gold'
                        onChange={() => setMedal('Gold')}
                    /> Gold

            </label>
                <div>
                    <input
                        type="text"
                        placeholder='SYSID'
                        name="sysid"
                        value={sysid}
                        onChange={e => setSysid(e.target.value)}
                    />

                </div>
                {/* {medal === 'Gold' ?
                <div className='tie-breaker'>
                    <label>
                        <p >Was there a tie-breaker match?</p>
                        <input type='radio'
                            checked={tieBreaker}
                            name='tieBreaker'
                            value={tieBreaker}
                            onClick={e => setTieBreaker(tieBreaker => !tieBreaker)}
                        /> Yes, Tie Breaker
                    </label> */}

                <button onClick={save}>Save</button>

            </div>
        </div >
    )
}
export default EditPastMatch