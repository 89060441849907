import React, { useState} from 'react'
import axios from 'axios'
import { useSelector } from "react-redux"
import MappedShiftsPublic from './MappedShiftsPublic'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LinearProgress from '@material-ui/core/LinearProgress';

const CategoryDetail = (props) => {
    const [shifts, setShifts] = useState([])
    const [show, setShow] = useState(false)
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)

    let {name, description} = props.category

    const handleShow = () => {
        if(show){
            setShow(false)
        }else {
            setShow(true)
            if(shifts.length === 0){
                getShiftSpots()
            }
        }
    }
 
     const getShiftSpots = () => {
         setLoader(true)
         axios.post(`/api/getVolunteerSignupByCategory/${tid}`, {ref_id, vcid: props.category.vcid}).then(
             res => {
                 setShifts(res.data)
                 setLoader(false)
             }
         )
     }

     const getAll = () => {
        getShiftSpots()
        if(props.getMySchedule){
        props.getMySchedule()
        }
        if(props.getSchedule){
            props.getSchedule()
            }
    }

     const mappedShifts = shifts
        .map((shift, i) => (
            <MappedShiftsPublic 
            key={i}
            shift={shift}
            get={getAll}
            showAll={props.showAll}
            collapse={props.collapse}
            />
        ))

    return(
        <div className='container'>
            <div style={{border: '1px solid #51BB85'}}>
            <ListItem onClick={handleShow}>
                <ListItemAvatar>
                    {show ?
                    <KeyboardArrowUpIcon onClick={handleShow}/>:
                    <KeyboardArrowDownIcon onClick={handleShow}/>   
            }
                    </ListItemAvatar>
        <ListItemText primary={name} secondary={description} />
      </ListItem>
      {loader ? <LinearProgress />:''}
      {show ?
      <div style={{marginLeft: '20px'}}>
             {mappedShifts}
             </div> :''}
        </div>
        </div>
    )
}
export default CategoryDetail