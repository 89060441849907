import React from 'react'

const Reassign = (props) => {
    return (
        <div className='finishMatch col-12 ' align="center">
            <h5>Change Referee or Court</h5>
            <div className='court-box-boardView col-10' align="center">
                <div className='court-num col-4'>
                    {props.court}
                </div>
                <div className={
                    props.assignedRef.credentials_verified === 'Certified' ? 'certified-board col-8 ref-name' : props.assignedRef.credentials_verified === 'Level 2' ? 'level2-board col-8 ref-name' : props.assignedRef.credentials_verified === 'Level 1' ? 'level1-board col-8 ref-name' :
                        'col-8 ref-name'}>

                    {props.assignedRef.last_match ? <span className='last_match_icon'>LM</span> : ''}
                    {props.assignedRef.first ?
                        <div>{props.assignedRef.first} {props.assignedRef.last}
                        </div> : ''}
                </div>
            </div>

            <button onClick={props.reassignRefereeOrCourt}>Save Changes</button>
            <button onClick={props.clearState}>Cancel</button>


        </div>


    )
}
export default Reassign