import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const RefereeScheduleRecap = props => {
    const [list, setList] = useState([])
    const [totalSpots, setTotalSpots] = useState(0)
    const [totalTaken, setTotalTaken] = useState(0)
    const [totalWaitSpots, setTotalWaitSpots] = useState(0)
    const [totalWaitTaken, setTotalWaitTaken] = useState(0)
    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getRecap() }, [tid])


    const getRecap = () => {
        axios.post(`/api/getRefereeScheduleRecap/`, { tid}).then(res => {
            setList(res.data)
            let s = 0 
            let t = 0 
            let w = 0 
            let wt = 0
            for(let i = 0; i < res.data.length; i++){
              s = s + +res.data[i].spots
              t = t + +res.data[i].taken
              w = w + +res.data[i].wait_spots
              wt = wt + +res.data[i].waitlist
            }
            setTotalTaken(t)
            setTotalSpots(s)
            setTotalWaitSpots(w)
            setTotalWaitTaken(wt)
        })
    }

    return(
      <div style={{marginBottom: '15px'}}>
            <TableContainer component={Paper} >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Shift</TableCell>
            <TableCell align="left">Spots</TableCell>
            <TableCell align="left">Waitlist</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row, i) => (
             <TableRow key={'t'+i} className='pointer' onClick={()=>props.select(row.name)}>
             <TableCell  style={{background:row.color || '#009CAD'}}>{row.name} {row.start_time} {row.end_time ?` - ${row.end_time}` : ''}</TableCell>
             <TableCell align="left" className={row.taken >= row.spots ? 'background-green':'background-orange'}>{row.taken} / {row.spots}</TableCell>
             <TableCell align="left" className={row.waitlist >= row.wait_spots ? 'background-green':'background-orange'}>{row.waitlist} / {row.wait_spots}</TableCell>
           </TableRow>
          ))}
          <TableRow key={'total'}>
             <TableCell  style={{fontWeight: 700}}>Total: {list.length}</TableCell>
             <TableCell  style={{fontWeight: 700}} align="left" className={totalTaken >= totalSpots ? 'background-green':'background-orange'}>{totalTaken} / {totalSpots}</TableCell>
             <TableCell  style={{fontWeight: 700}} align="left" className={totalWaitTaken >= totalWaitSpots ? 'background-green':'background-orange'}>{totalWaitTaken} / {totalWaitSpots}</TableCell>
           </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
<small>Click on the shift name to filter by that shift.  Once a shift is full it will have a green background.</small>
        </div>
    )
}
export default RefereeScheduleRecap