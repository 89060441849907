import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Modal from 'react-responsive-modal';
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Moment from 'react-moment';


const ApprovalRequests = props => {
    const [requests, setRequests] = useState([])
    const [referee, setReferee] = useState({})
    const [view, setView] = useState(false)
    const [note, setNote] = useState('')
    const [all, setAll] = useState(false)

    const tid = useSelector(state => state.tid)
    const author = useSelector(state => state.ref_id)

    useEffect(
        () => { getRequests() }, [])

    const getRequests = () => {
        axios.get(`/api/getApprovalRequests/${tid}`).then(res => {
            setRequests(res.data)
            setAll(false)
        })
    }
    const getAll = () => {
        axios.get(`/api/getApprovedReferees/${tid}`).then(
            res => {
                setRequests(res.data)
                setAll(true)
            }
        )
    }

    const approve = () => {
        axios.put(`/api/approveReferee/${tid}`, { ref_id: referee.ref_id, note, author }).then(res => {
            getRequests()
            setReferee({})
            setView(false)
            setNote('')
            if (props.getApprovedReferee) {
                props.getApprovedReferee()
            }
        })
    }

    const deny = () => {
        axios.put(`/api/removeRefereeFromApprovedList/${tid}`, { ref_id: referee.ref_id, note, author }).then(res => {
            getRequests()
            setReferee({})
            setView(false)
            setNote('')
            if (all) {
                getAll()
            } else {
                getRequests()
            }
        })
    }

    const handleApprove = (ref) => {
        setReferee(ref)
        setView(true)
    }
    const closeView = () => {
        setReferee({})
        setView(false)
    }

    const columns = [
        {
            Header: 'View',
            accessor: 'ref_id',
            Cell: ({ value, row }) =>
                <button onClick={() => handleApprove(row._original)}>View</button>
        },
        {
            Header: 'Status',
            accessor: 'referee_approved',
            Cell: ({ value }) =>
                value === true ? 'approved' :
                    value === false ? 'denied' :
                        'awaiting response'
        },
        {
            Header: 'First',
            accessor: 'first',
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["first"] }),
            filterAll: true
        },
        {
            Header: 'Last',
            accessor: 'last',
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["last"] }),
            filterAll: true
        },
        {
            Header: 'credentials',
            accessor: 'credentials',
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["credentials"] }),
            filterAll: true

        },
        {
            Header: 'Request Time',
            accessor: 'request_time',
            Cell: ({ value }) =>
            value ? 
            <Moment format="MM/DD/YYYY h:mm a " >
                {value}
            </Moment>:''
        },
        {
            Header: 'Approved Time',
            accessor: 'approved_time',
            Cell: ({ value }) =>
            value ? 
            <Moment format="MM/DD/YYYY h:mm a " >
                {value}
            </Moment>:''
        },
        {
            Header: 'Note',
            accessor: 'referee_request_note'

        },
        {
            Header: 'phone',
            accessor: 'phone'

        },
        {
            Header: 'email',
            accessor: 'email'

        }
    ]

    return (
        <div>
            <div>
                <div className='tournament-listing-header' style={{ marginTop: '15px' }}>
                    <div style={{ fontSize: '1.5em' }}>
                        {all ? 'All Requests' : 'Showing All Referee Requests Awaiting Response'}
                    </div>
                    {all ? <button
                        onClick={getRequests}> <i className="fas fa-exchange-alt"></i>View Requests Awaiting Response</button> :
                        <button
                            onClick={getAll}><i className="fas fa-exchange-alt"></i>Show All Requests</button>}</div>

            </div>
            <ReactTable
                data={requests}
                filterable
                defaultPageSize={10}
                defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value}
                columns={columns}
            />
            <p>* approval requested times will show if the request/ approval was after 1/28/2021</p>

            <Dialog onClose={closeView} open={view} >
                <DialogTitle id="simple-dialog-title">Referee Request</DialogTitle>
                <div className='dialog'>
                    <TableContainer component={Paper}>
                        <Table>

                            <TableBody>
                                <TableRow key={1}>
                                    <TableCell component="th" scope="row">
                                        Name
              </TableCell>
                                    <TableCell align="left">{referee.first} {referee.last}</TableCell>
                                </TableRow>
                                <TableRow key={2}>
                                    <TableCell component="th" scope="row">
                                        Credentials
              </TableCell>
                                    <TableCell align="left">{referee.credentials}</TableCell>
                                </TableRow>
                                <TableRow key={3}>
                                    <TableCell component="th" scope="row">
                                        Contact
              </TableCell>
                                    <TableCell align="left">{referee.phone} {referee.email}</TableCell>
                                </TableRow>
                                <TableRow key={4}>
                                    <TableCell component="th" scope="row">
                                        Note from Referee
              </TableCell>
                                    <TableCell align="left">{referee.referee_request_note} </TableCell>
                                </TableRow>
                                <TableRow key={5}>
                                    <TableCell component="th" scope="row">
                                        RC Response
              </TableCell>
                                    <TableCell align="left">{referee.response} </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ background: '#D0D3E7', padding: '20px' }}>
                        <label>Would you like to include a message to the referee with your response?</label>
                        <textarea
                            placeholder='Response to Referee'
                            value={note}
                            onChange={e => setNote(e.target.value)}
                            cols="15" rows="5"></textarea>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <button
                            className='login-button'
                            onClick={approve}>Approve</button>
                        <button
                            className='register-button'
                            onClick={deny}>Deny</button>
                        <button onClick={closeView}>Close</button>
                    </div>

                </div>
            </Dialog>
        </div >
    )
}
export default ApprovalRequests