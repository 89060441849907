import React, {useState} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button';


export default function VolunteerCheckoutAllVolunteers(props){
    const [verify, setVerify] = useState(false)

    const tid = useSelector(state => state.tid)


    const checkoutAllVolunteers = async () => {
        await axios.post(`/api/volunteer/checkoutAllVolunteers/`, {tid}).then(
            res => {
                if(props.get){
                    props.get()
                }
            }
        )
    }

    return(
        <div>
            <Button variant="contained" color="primary" onClick={()=>setVerify(!verify)}>
                {verify ? 'Cancel':'Check-out ALL Volunteers'}
            </Button>
            {verify && 
            <Button variant="contained" color="secondary" onClick={checkoutAllVolunteers}>
              Yes, Check-out ALL
            </Button>}
        </div>
    )
}