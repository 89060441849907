import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import LinearProgress from '@material-ui/core/LinearProgress'
import Moment from 'react-moment'

const RefereeMessageBoard = props => {
    const [list, setList] = useState([])
    const [loader, setLoader] = useState(true)
    const [error, setError] = useState('')

    let {tid} = props.match.params
    const ref_id = useSelector(state => state.ref_id) 
    const name = useSelector(state => state.name)
    const timeZone = useSelector(state => state.time_zone)

    let {category} = props


    useEffect(
        () => { getEmails() }, [tid, ref_id])


    const getEmails = () => {
        if(!ref_id || !tid || tid === null || ref_id === null){setError(true)}
        setLoader(true)
        setError('')
        axios.post(`/api/email/getMessageBoardEmails/${tid || 0}`, {category: 'referee', ref_id}).then(
            res => {
                if(res.data.length === 0){
                    setError('No Emails Found')
                } else {
                setList(res.data)
                }
                setLoader(false)
            }
        ).catch(err => {
            setLoader(false)
            setError('Sorry you are not registered for the tournament')
        })
    }

    const mappedEmails = list.map((row, i)=> {
        return(
            <div key={i} style={{textAlign:'left'}}>
                <div>
                    
                <Moment format="MM/DD/YYYY h:mm a" tz={timeZone}>
                    {row.time}
                </Moment>
                {`  -  ${row.subject}  From ${row.author}`}
                </div>
                <div>
                <ReactQuill
                    // className='description'
                    style={{background: 'white'}}
                    value={row.body}
                    readOnly={true}
                    theme={"bubble"}
                />
                </div>
            </div>
        )
    })

    return (
        <div className='container-fluid'>
            <div>{name}</div>
            <h3>Past Emails Sent to Referees</h3>
            
            {loader ?
            <LinearProgress color="secondary"/>:''}
                {mappedEmails}

                {error ? 
                <h4>{error}</h4>:''}
            
        </div>
    )
}
export default RefereeMessageBoard