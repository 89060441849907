import React, { Component } from 'react'
import axios from 'axios'
import { connect } from "react-redux"
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './TemplateManager.css'


class TemplateManager extends Component {
    constructor(props) {
        super(props)

        this.state = {
            templates: [],
            edit: false,
            name: '',
            subject: '',
            body: '',
            type: 'email',
            etid: '',
            nameError: false,
            subjectError: false,
            bodyError: false,
            verifyDeleteTemplate: false
        }
    }
    componentDidMount() {
        this.getTemplates()
    }

    getTemplates = () => {
        axios.get(`/api/getTemplates/${this.props.ref_id}`).then(
            res => {
                this.setState({ templates: res.data })

            }
        )
    }

    verifyFields = () => {
        if (this.state.name.length === 0) {
            this.setState({ nameError: true })
        }
        if (this.state.body.length === 0) {
            this.setState({ bodyError: true })
        }
        if (this.state.subject.length === 0 && this.state.type === 'email') {
            this.setState({ subjectError: true })
        }
    }

    addTemplate = async () => {
        await this.verifyFields()
        if (!this.state.nameError && !this.state.subjectError && !this.state.bodyError) {
            axios.post(`/api/saveTemplate`, { ref_id: this.props.ref_id, name: this.state.name, subject: this.state.subject, body: this.state.body, type: this.state.type })
                .then(res => {
                    this.setState({ templates: res.data })
                    this.clearState()
                    toast.success(`Template Added`)

                })
        }
    }

    updateTemplate = async () => {
        await this.verifyFields()
        if (!this.state.nameError && !this.state.subjectError && !this.state.bodyError) {
            axios.put(`/api/updateTemplate/${this.state.etid}`, { ref_id: this.props.ref_id, name: this.state.name, subject: this.state.subject, body: this.state.body, type: this.state.type })
                .then(res => {
                    this.setState({ templates: res.data })
                    this.clearState()
                    toast.success(`Template Updated`)

                })
        }
    }

    verifyDeleteTemplate = () => this.setState({ verifyDeleteTemplate: !this.state.verifyDeleteTemplate })

    deleteTemplate = () => {
        axios.post(`/api/deleteTemplate/${this.state.etid}`, { ref_id: this.props.ref_id }).then(
            res => {
                this.setState({ templates: res.data })
                this.clearState()

            }
        )
    }

    handleUpdateTemplate = (t) => {
        this.setState({
            edit: true,
            name: t.name,
            subject: t.subject,
            body: t.body,
            type: t.type,
            etid: t.etid,
        })
    }

    clearState = () => this.setState({
        edit: false,
        name: '',
        subject: '',
        body: '',
        type: 'email',
        etid: ''
    })

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }
    handleEmail = (value) => {
        this.setState({ body: value })
    }


    render() {

        const mappedTemplates = this.state.templates.map(t => {
            return (
                <div key={t.etid}
                    className='mappedTemplates'
                    onClick={() => this.handleUpdateTemplate(t)}>
                    {t.name}
                </div>
            )
        })
        return (
            <div className='container shadow mb-10 bg-white rounded' style={{ padding: '20px' }}>
                <h4>Welcome to Template Manager</h4>
                <div> This is where you can create and edit email and text templates.</div>
                <div>  These templates are stored in your account so they can be accessed for any tournament for which you have admin access.</div>
                <div className='row'>

                    <div className='col-4'>
                        Template Name
                    <div style={{ background: '#ECFCE6', textAlign: 'left', padding: '10px' }}>
                            {mappedTemplates}
                        </div>

                    </div>
                    <div className='col-8 TemplateManager-Message'>
                        <label>Message Type
                        <label>
                                <input type='radio'
                                    value='email'
                                    checked={this.state.type === 'email'}
                                    name='type'
                                    onChange={this.handleChange}
                                /> email </label>
                            <label>
                                <input type='radio'
                                    value='text'
                                    checked={this.state.type === 'text'}
                                    name='type'
                                    onChange={this.handleChange}
                                /> text </label>
                        </label>
                        <label>Template Name
                        <input
                                type="text"
                                placeholder='name'
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange} />
                        </label>
                        {this.state.type === 'email' ?
                            <label> Email Subject
                            <input
                                    type="text"
                                    placeholder='subject'
                                    name="subject"
                                    value={this.state.subject}
                                    onChange={this.handleChange} />
                            </label> : ''}
                        {this.state.type === 'email' ?
                            <ReactQuill
                                style={{ background: 'white' }}
                                name="body"
                                value={this.state.body}
                                onChange={this.handleEmail} /> :
                            <textarea
                                type="text"
                                placeholder=''
                                name="body"
                                style={{ minHeight: '100px' }}
                                maxLength={this.state.type === 'text' ? 159 : ''}
                                value={this.state.body}
                                onChange={this.handleChange} />}
                        {this.state.type === 'text' ?
                            <p>Text are limited to 159 characters</p> : ''}
                        <div>
                            <div className='error-warnings'>
                                {this.state.nameError ? <p>Template Name Required</p> : ''}
                                {this.state.subjectError ? <p>Subject Required</p> : ''}
                                {this.state.bodyError ? <p>{this.state.type} body required</p> : ''}
                            </div>
                           
                        </div>
                        <div style={{margin: '20px', zIndex: 10}}>
                            {this.state.edit ?
                                <button onClick={this.updateTemplate}>Save</button> :
                                <button onClick={this.addTemplate}>Save</button>}
                            <button onClick={this.clearState}>Cancel</button>
                            {this.state.etid ?
                                <button onClick={this.verifyDeleteTemplate}>Delete</button> : ''}
                                </div>
                    </div>
                </div>
                <Modal open={this.state.verifyDeleteTemplate} center showCloseIcon={true} onClose={this.verifyDeleteTemplate} closeIconSize={20} closeOnEsc={true} >
                    <div>
                        Are you sure you want to delete this template?  You will no tbe able to recover this template.
                                        <button onClick={this.deleteTemplate}>Confirm Delete</button>
                        <button onClick={this.verifyDeleteTemplate}>Cancel</button>
                    </div>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        ref_id: reduxState.ref_id
    }
}


export default connect(
    mapStateToProps,
)(TemplateManager);

