import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Switch from "@material-ui/core/Switch"
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';




const VolunteerCheckInListDetail = props => {
    const [checkin, setCheckin] = useState(false)
    const [shirt, setShirt] = useState(false)
    const [show, setShow] = useState(false)
    const [wait, setWait] = useState(false)
    const classes = useStyles()

    let {row, selected, select} = props
    const tid = useSelector(state => state.tid)

    useEffect(
        () => { handleProps() }, [props.row.checkin])

    const checkinVolunteer = () => {
        if(wait){return}
        setWait(true)
        axios.put(`/api/checkinVolunteer/${tid}`, { ref_id: row.ref_id, status: !checkin}).then(res => {
            setCheckin(res.data)
            setWait(false)
        })
    }

    const shirtPickup = () => {
        axios.put(`/api/shirtPickup/${tid}`, { ref_id: row.ref_id}).then(res => {
            setShirt(true)
            setShow(false)
        })
    }

    const handleProps = () => {
        setCheckin(props.row.checkin)
        setShirt(props.row.shirt_pickup)
    }

    return(
            <TableRow key={row.name}
                className={selected.some(item => row.ref_id === item.ref_id)? classes.selected : ''}
                >
              <TableCell component="th" scope="row">
            {wait ?
            <CircularProgress />:
              < Switch
                        color="primary"
                        checked={checkin}
                        value={checkin}
                        onClick={() => checkinVolunteer()}
                    />}
                    
              </TableCell>
              <TableCell>
                  {shirt ?
                  'received':
                <button onClick={()=>setShow(true)}>Shirt</button>}
              </TableCell>
              <TableCell component="th" scope="row" onClick={()=>select(row)}>
              <div>
                      <input type="checkbox"
                        checked={selected.some(item => row.ref_id === item.ref_id)}
                        readOnly
                        />
                {row.name}
                </div>
              </TableCell>
              <TableCell align="left">{row.category}</TableCell>
              <TableCell align="left">{row.shift}</TableCell>
              <TableCell align="left">
                  {`${row.start_time} to ${row.end_time}`}
                </TableCell>
                <Dialog  aria-labelledby="simple-dialog-title" open={show}>
      <DialogTitle >Shirt Pickup</DialogTitle>
      <div style={{padding: '20px'}}>
                <h4>Shirt Size: {row.shirt || 'not provided'}</h4>
                <div>
                <button 
                    className='login-button'
                    style={{fontSize: '1.5em'}}
                    onClick={shirtPickup}> Received</button>
                <button className='register-button'
                    onClick={()=>setShow(false)}>Cancel</button>
                </div>
      </div>
      </Dialog>
            </TableRow>

    )
}
export default VolunteerCheckInListDetail
const useStyles = makeStyles({
    selected: {
        background: "#D2D4F0"
    },
  });