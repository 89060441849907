import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import LinearProgress from '@material-ui/core/LinearProgress'
import TextTable from './TextTable'

const SentTexts = props => {
    const [list, setList] = useState([])
    const [loader, setLoader] = useState(true)

    const tid = useSelector(state => state.tid)
    const name = useSelector(state => state.name)

    let {category} = props

    useEffect(
        () => { getTexts() }, [tid])


    const getTexts = () => {
        axios.post(`/api/text/getTextsByCategory/${tid}`, {category}).then(
            res => {
                setList(res.data)
                setLoader(false)
            }
        )
    }

    return (
        <div className='container-fluid'>
            <h3>Text Log</h3>
            <div>{name}</div>
            {loader ?
            <LinearProgress color="secondary"/>:''}

            <TextTable
                list={list}
                category={props.category} />
        </div>
    )
}
export default SentTexts