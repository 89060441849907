import React from 'react'
import { Link } from 'react-router-dom';
import './VolunteerDashboard.css'

const VolunteerDashboardNew = props => {
    return (
        <div className='container'>
            <div className='col-12'>
                <img className='volunteer-dashboard-volunteer'
                    src={'https://res.cloudinary.com/shonda/image/upload/v1582498963/PickleballDesk/Volunteer_he87xg.png'} alt='volunteer' />
                <h2>Volunteer Management</h2>
                <h5>Create volunteer shifts, manage volunteers during event.</h5>
                <p>When setting up volunteer shifts you can add specific jobs and have volunteers register for those spots.  Another option is to have volunteers signup for generic shifts then assigned them to duties when they arrive.</p>


            </div>
            <div className='row'>


                <div className='col-12'>
                <div className='checklist'>
                        <div className='checklist-number'>1A
                        </div>
                        <Link to={`/volunteer/categories`} >
                            <div>
                            Categories Set up / Captain Assignment
                        </div>
                        </Link>
                    </div >
                    <div className='checklist'>
                        <div className='checklist-number'>1B
                        </div>
                        <Link to={`/volunteercaptain/scheduling`} >
                            <div>
                                Create Shifts/ View Shift List
                                <p><small>You can create shifts within categories or captains can make shifts for their categories.</small></p>
                        </div>
                        </Link>
                    </div >


                    <div className='checklist'>
                        <div className='checklist-number'>2
                        </div>
                        <Link to={`/volunteercaptain/managevolunteers`} >
                            <div>
                                Manage Schedule
                                <p><small>Assign volunteers to shifts.  See who is registered to volunteer</small></p>
                            </div>
                        </Link>
                    </div >
                    <div className='checklist'>
                        <div className='checklist-number'>3
                        </div>
                        <Link to={`/volunteer/volunteerSchedule`} >
                            <div>
                                Volunteer Schedule/ Communicate with Volunteers
                                <p><small>Sent emails and text messages.  View scheduled by volunteer.</small></p>
                            </div>
                        </Link>
                    </div >
                    <div className='checklist'>
                        <div className='checklist-number'>3A
                        </div>
                        <Link to={`/volunteer/emailshifts`} >
                            <div>
                                Email Shifts
                                <p><small>Select shifts to email</small></p>
                            </div>
                        </Link>
                    </div >

                    <div className='checklist'>
                        <div className='checklist-number'>4
                        </div>
                        <Link to={`/volunteer/checkin`} >
                            <div>
                                Day-of Check-in
                        </div>
                        </Link>
                    </div >

                    <div className='checklist'>
                        <div className='checklist-number'>5
                        </div>
                        <Link to={`/volunteer/courtMonitors`} >
                            <div>
                                Court Monitors and Volunteer Groups
                    </div>
                        </Link>
                    </div >
                    <div className='checklist'>
                        <div className='checklist-number'>6
                        </div>
                        <Link to={`/volunteer/groups`} >
                            <div>
                                Volunteer Groups
                    </div>
                        </Link>
                    </div >
                    <div className='checklist'>
                        <div className='checklist-number'>7
                        </div>
                        <Link to={`/volunteer/contactinfo`} >
                            <div>
                            Volunteer Contact Info
                    </div>
                        </Link>
                    </div >
                    <div className='checklist'>
                        <div className='checklist-number'>7a
                        </div>
                        <Link to={`/volunteer/contactinfobyday`} >
                            <div>
                            Volunteer Contact Info by Day
                    </div>
                        </Link>
                    </div >
                    <div className='checklist'>
                        <div className='checklist-number'>8
                        </div>
                        <Link to={`/volunteer/emaillog`} >
                            <div>
                            Email Log
                    </div>
                        </Link>
                    </div >
                    <div className='checklist'>
                        <div className='checklist-number'>8a
                        </div>
                        <Link to={`/volunteer/schedulelog`} >
                            <div>
                                Registration Log
                            <p><small>List time volunteer registers for shifts or removes registration.</small></p>
                    </div>
                        </Link>
                    </div >
                    <div className='checklist'>
                        <div className='checklist-number'>9
                        </div>
                        <Link to={`/volunteer/volunteerList`} >
                            <div>
                            Edit/ Remove Volunteer
                    </div>
                        </Link>
                    </div >
                    <div className='checklist'>
                        <div className='checklist-number'>10
                        </div>
                        <Link to={`/volunteer/volunteerwaiverlist`} >
                            <div>
                                Waiver List
                                <p><small>See all volunteers and if their waiver is signed.</small></p>
                        </div>
                        </Link>
                    </div >
                    <div className='checklist'>
                        <div className='checklist-number'>11
                        </div>
                        <Link to={`/volunteer/volunteertimeandshifts`} >
                            <div>
                                Total Time and Shifts
                                <p><small>See estimated total time and all registered shifts for volunteer</small></p>
                        </div>
                        </Link>
                    </div >
                    <div className='checklist'>
                        <div className='checklist-number'>12
                        </div>
                        <Link to={`/volunteer/shirtsizes`} >
                            <div>
                                Total Shirt Sizes
                                
                        </div>
                        </Link>
                    </div >
                    
                </div >
            </div>
        </div >
    )
}
export default VolunteerDashboardNew