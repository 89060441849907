import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Moment from 'react-moment';
import 'moment-timezone';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';

import axios from 'axios'
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter'
import ViewInvoice from './ViewInvoice'

import './Invoice.css'

const InvoiceList = props => {
    const [invoice, setInvoice] = useState({})
    const [showInvoice, setShowInvoice] = useState(false)
    const [invoices, setInvoices] = useState([])
    const [invoiceTotal, setInvoiceTotal] = useState(0)

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getInvoices() }, [tid])

    useEffect(
        () => { invoiceTotalPaid() }, [tid])


    const getInvoices = () => {
        axios.get(`/api/getInvoices/${tid}`).then(res => {
            setInvoices(res.data)
        })
    }

    const invoiceTotalPaid = () => {
        axios.get(`/api/invoiceTotalPaid/${tid}`).then(res => {
            setInvoiceTotal(res.data[0].sum)
        })
    }



    const columns = [
        {
            Header: 'View',
            accessor: 'invoice_id',
            Cell: ({ value, row }) =>
                <button onClick={() => props.history.push(`/desk/viewInvoice/${value}`)}>View</button>
        },
        {
            Header: 'Date',
            accessor: 'payment_date',
            Cell: ({ value, row }) =>
                <Moment format="MM/DD" >
                    {value}
                </Moment>
        },
        {
            Header: 'Last',
            accessor: 'last',
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["last"] }),
            filterAll: true


        },
        {
            Header: 'First',
            accessor: 'first',
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["first"] }),
            filterAll: true

        },
        {
            Header: 'Amount',
            accessor: 'total',
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["total"] }),
            filterAll: true,
            Cell: ({ value, row }) => {
                return (
                    <div>$ {value}</div>
                )
            }


        },
    ]


    return (
        <div className='container'>
            <div>
                <Link to={`/desk/invoice`} >
                    <button>Make Invoice</button>
                </Link>
                <h4>Invoice List</h4>
                <div style={{ fontSize: '2em' }}>Total Paid: ${invoiceTotal}</div>
                <ReactTable
                    data={invoices}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}

                    className='ReactTable'

                    columns={columns}
                />
            </div>
            <Modal open={showInvoice} center showCloseIcon={true}
                onClose={() => setShowInvoice(false)} closeOnEsc={true} >
                <ViewInvoice
                    invoice={invoice} />
            </Modal>



        </div >
    )
}
export default InvoiceList