import React, { Component } from 'react';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';
import 'moment-timezone';
import '../RefDesk/RefDesk.css'

class AddVolunteer extends Component {
    constructor() {
        super()
        this.state = {
            first: '',
            last: '',
            credentials: 'Not Rated',
            credentials_verified: 'Not Rated',
            phone: '',
            email: '',
            phoneErrorText: ''

        }
    }

    handlePhone = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            this.setState({ phone: onlyNums, phoneErrorText: 'US Phone - 10 numbers' });
        } else if (onlyNums.length === 10) { this.setState({ phone: onlyNums, phoneErrorText: '' }) }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    isValidEmailAddress = (address) => {
        return !!address.match(/.+@.+/)
    }

    render() {


        return (
            <div className="">
                <div className=''>


                    <h5>Add New Person</h5>
                    <TextField
                        // error={this.state.last.length === 1 ? false : true}
                        type='text'
                        id="outlined-error-helper-text"
                        label="First Name"
                        name='first'
                        defaultValue="first Name"
                        value={this.state.first}
                        onChange={this.handleChange}
                        errorText='first Name Required'
                        className='textField'
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        // error={this.state.last.length === 1 ? false : true}
                        type='text'
                        id="outlined-error-helper-text"
                        label="Last Name"
                        name='last'
                        defaultValue="Last Name"
                        value={this.state.last}
                        onChange={this.handleChange}
                        errorText='Last Name Required'
                        className='textField'
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        error={this.state.phoneErrorText.length === 0 ? false : true}
                        type='integer'
                        id="outlined-error-helper-text"
                        label="Phone Num"
                        name='phone'
                        defaultValue="Phone"
                        helperText='For Text Messages - US Phone Only'
                        value={this.state.phone}
                        onChange={this.handlePhone}
                        errorText={this.state.phoneErrorText}
                        className='textField'
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        // error={this.state.last.length === 1 ? false : true}
                        type='email'
                        id="outlined-error-helper-text"
                        label="email"
                        name='email'
                        defaultValue="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        errorText='Last Name Required'
                        className='textField'
                        margin="normal"
                        variant="outlined"
                    />

                    {/* <legend>Credentials</legend>
                    <label>
                        <input type='radio'
                            name='credentials'
                            checked={this.state.credentials === 'Not Rated'}
                            value='Not Rated'
                            onClick={this.handleChange}
                            onBlur={this.save} /> Not Rated </label>
                    <label>
                        <input type='radio'
                            name='credentials'
                            checked={this.state.credentials === 'Level 1'}
                            value='Level 1'
                            onClick={this.handleChange}
                            onBlur={this.save} /> Level 1 </label>
                    <label>
                        <input type='radio'
                            name='credentials'
                            value='Level 2'
                            checked={this.state.credentials === 'Level 2'}
                            onClick={this.handleChange}
                            onBlur={this.save} /> Level 2 </label>
                    <label>
                        <input type='radio'
                            name='credentials'
                            checked={this.state.credentials === 'Certified'}
                            value='Certified'

                            onClick={this.handleChange}
                            onBlur={this.save} /> Certified </label> */}

                    <button onClick={() => this.props.addVolunteer({ first: this.state.first, last: this.state.last, phone: this.state.phone, email: this.state.email, credentials: this.state.credentials, credentials_verified: this.state.credentials })}>Save</button>




                </div>


            </div>


        )
    }
}


export default AddVolunteer;