import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'

const EventSetup = props => {
    const [events, setEvents] = useState([])
    const [eventOptions, setEventOptions] = useState([
        { type: 'Mens Doubles Pro' },
        { type: 'Womens Doubles Pro' },
        { type: 'Mixed Doubles Pro' },
        { type: 'Mens Doubles Senior Pro' },
        { type: 'Womens Doubles Senior Pro' },
        { type: 'Mixed Doubles Senior Pro' }])

    const tournament = useSelector(state => state.rankTournament.name)
    const rtid = useSelector(state => state.rankTournament.rtid)

    useEffect(
        () => { getTournamentEvents() }, [rtid])

    const getTournamentEvents = () => {
        axios.get(`/api/ranking/getTournamentEvents/${rtid}`).then(
            res => {
                setEvents(res.data)
            }
        )
    }

    const addEventToTournament = (type) => {
        axios.post(`/api/ranking/addEventToTournament/`, { rtid, type }).then(
            res => {
                getTournamentEvents()
            }
        )
    }

    const mappedEventOptions = eventOptions.map((e, i) => <button
        className={events.some(item => e.type === item.type) ? 'event-selected-button ' : 'imageButtons '}
        onClick={() => addEventToTournament(e.type)}>{e.type}</button>)

    return (
        <div>
            {tournament}
            <h4>What events does this tournament have?</h4>
            {mappedEventOptions}


        </div>
    )
}
export default EventSetup


