import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import LinePhoneEmailReport from '../components/LineJudge/LinePhoneEmailReport'
import PastMatchLineJudge from '../components/LineJudge/PastMatchLineJudge'
import LineJudgeManager from '../components/LineJudge/LineJudgeManager'
import AddLineJudgeToSchedule from '../components/LineJudge/AddLineJudgeToSchedule'
import LineSchedule from '../components/LineJudge/LineSchedule'
import Setup from '../components/RefDesk/Setup'
import ScheduleSetupLine from '../components/Schedule/ScheduleSetupLine';
import EditLineJudgeContact from '../components/LineJudge/EditLineJudgeContact';
import LineJudgeLog from '../components/Schedule/ScheduleLog/LineJudgeLog';


const LineAdminRoute = (props) => {
    const tid = useSelector(state => state.tid)
    const user_id = useSelector(state => state.user_id)
    const admin_type = useSelector(state => state.admin_type)


    return (
        <div>

            <Switch>
                <Route path='/line/schedulelog' component={LineJudgeLog}/>
                <Route path='/line/contactinfo' component={EditLineJudgeContact}/>
                <Route path='/admin' component={Setup} />
                <Route path='/line/linecontactreport' component={LinePhoneEmailReport} />
                <Route path='/line/pastMatches' component={PastMatchLineJudge} />
                {tid ? <Route path='/line/lineJudge' component={LineJudgeManager} /> : ''}
                <Route path='/line/addShifts' component={ScheduleSetupLine}/>
                <Route path='/line/editSchedule' component={AddLineJudgeToSchedule} />
                <Route path='/line/linejudgeschedule' component={LineSchedule} />
                <Route path='/line/setup' component={Setup} />
            </Switch>

        </div>
    )
}
export default LineAdminRoute