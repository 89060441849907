import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'


const ScheduleSetupBulk = (props) => {
    const [shifts, setShifts] = useState([])
    const [category, setCategory] = useState('other')
    const [shiftName, setShiftName] = useState('')
    const [listOfShifts, setListOfShifts] = useState([])
    const [certified, setCertified] = useState(0)
    const [levelTwo, setLevelTwo] = useState(0)
    const [levelOne, setLevelOne] = useState(0)
    const [volunteer, setVolunteer] = useState(0)
    const [waitCertified, setWaitCertified] = useState(0)
    const [waitLevelTwo, setWaitLevelTwo] = useState(0)
    const [waitLevelOne, setWaitLevelOne] = useState(0)
    const [waitVolunteer, setWaitVolunteer] = useState(0)

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getShifts() }, [])

    let spotTotal = +certified + +levelTwo + +levelOne + +volunteer
    let waitTotal = +waitCertified + +waitLevelOne + +waitLevelTwo + +waitVolunteer

    const getShifts = () => {
        axios.get(`/api/getShifts/${tid}`).then(
            res => {
                setShifts(res.data)
            }
        )
    }

    const addShiftName = () => {
        setListOfShifts(listOfShifts => [...listOfShifts, shiftName])
    }

    const removeShiftTitle = (r) => {
        setListOfShifts(listOfShifts.filter(shift => shift != r))
    }

    const generateShifts = () => {
        axios.post(`/api/addShiftsFromList/${tid}`, { listOfShifts, spotTotal, certified, levelTwo, levelOne, volunteer, waitTotal, waitCertified, waitLevelOne, waitLevelTwo, waitVolunteer, category }).then(
            res => {
                getShifts()
                setListOfShifts([])
            }
        )
    }

    const clearAll = () => {
        setCategory('other')
        setShiftName('')
        setListOfShifts([])
        setCertified(0)
        setLevelTwo(0)
        setLevelOne(0)
        setVolunteer(0)
        setWaitCertified(0)
        setWaitLevelTwo(0)
        setWaitLevelOne(0)
        setWaitVolunteer(0)
    }


    const mappedShiftNames = listOfShifts.map((shift, i) => {
        return (
            <div>
                <span>{shift}</span>
                <button onClick={() => removeShiftTitle(shift)}>Remove</button>
            </div>
        )

    })

    const mappedShifts = shifts
        // .filter(obj => obj.name.toUpperCase().includes(this.state.searchName.toUpperCase()))
        // .filter(obj => obj.category.includes(this.state.searchCategory))
        .map(shift => {
            return (
                <div key={shift.sid} className='col-12'>
                    <div style={{ background: 'white', margin: '10px' }}>
                        <div className='shift-title'
                            style={{ background: shift.color || '#009CAE' }}>
                            <h4 >{shift.category}: {shift.name}</h4>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-md-6 shift-box' >
                                <div className='col-12 criteria'>

                                    <h4>Spots: {shift.spots}</h4>
                                    <div className='row'>
                                        <div className='col-12 col-lg-6'>
                                            <div>
                                                Certified: {shift.certified}
                                            </div>
                                            <div>
                                                Level Two: {shift.level_two}
                                            </div>
                                            <div>
                                                Level One: {shift.level_one}
                                            </div>
                                            <div>
                                                Not Specified: {shift.none}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='shift-box' >
                                    {shift.wait_spots > 0 ?
                                        <div className='col-12 criteria'>
                                            <h4>Waitlist: {shift.wait_spots}</h4>
                                            <div className='row'>
                                                <div className='col-12 col-lg-6'>
                                                    <div>
                                                        Certified: {shift.wait_certified}
                                                    </div>
                                                    <div>
                                                        Level Two: {shift.wait_level_two}
                                                    </div>
                                                    <div>
                                                        Level One: {shift.wait_level_one}
                                                    </div>
                                                    <div>
                                                        Not Specified: {shift.wait_none}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <button onClick={() => this.handleEdit(shift)}>Edit</button>
                    <button onClick={() => this.handleDuplicate(shift)}>Duplicate</button>
                    <button onClick={() => this.verifyDelete(shift)}>Delete</button> */}
                </div >
            )
        }
        )

    return (
        <div className='container'>
            <div style={{ background: 'white' }}>


                <h4 style={{ color: 'red' }}>*** DO NOT REFRESH BEFORE SAVING AS DATA WILL BE LOST***</h4>
                <div>
                    <form>
                        <label>
                            Volunteer Category:
                                    <input type='radio'
                                checked={category === 'Referee'}
                                onChange={() => setCategory('Referee')}
                            /> Referee
                                </label>
                        <label>
                            <input type='radio'
                                checked={category === 'Line Judge'}
                                value='Line Judge'
                                onChange={() => setCategory('Line Judge')}
                            /> Line Judge
                                </label>
                        <label>
                            <input type='radio'
                                checked={category === 'other'}
                                onChange={() => setCategory('other')}
                            /> Other
                                </label>
                    </form>
                </div>
                <div>

                    <p>Shift Name(s)</p>
                    <input type="text"
                        value={shiftName}
                        onChange={e => { setShiftName(e.target.value) }} />
                    <button onClick={addShiftName}>Add</button>
                    {mappedShiftNames}
                    <button onClick={() => setListOfShifts([])}>Clear Shift Names</button>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-6 shift-requirements'>
                        <h5>Shift Requirements</h5>
                        <p>{`shift total: ${spotTotal}`}</p>
                        <legend>Certified
                                <input type="number"
                                value={certified}
                                onChange={e => { setCertified(e.target.value) }} />
                        </legend>
                        <legend>Level 2
                                <input type="number"
                                value={levelTwo}
                                onChange={e => { setLevelTwo(e.target.value) }} />
                        </legend>
                        <legend>Level 1
                                <input type="number"
                                value={levelOne}
                                onChange={e => { setLevelOne(e.target.value) }} />
                        </legend>
                        <legend> Not Specified
                                <input type="number"
                                value={volunteer}
                                onChange={e => { setVolunteer(e.target.value) }} />
                        </legend>
                        Place total number as Not Specified if there is no certification requirements.
                        </div>
                    <div className='col-12 col-md-6'>
                        <h5>Waitlist Requirements</h5>
                        <p>{`Waitlist total: ${waitTotal}`}</p>
                        <legend>Certified
                                <input type="number"
                                value={waitCertified}
                                onChange={e => { setWaitCertified(e.target.value) }} />
                        </legend>
                        <legend>Level 2
                                <input type="number"
                                value={waitLevelTwo}
                                onChange={e => { setWaitLevelTwo(e.target.value) }} />
                        </legend>
                        <legend>Level 1
                                <input type="number"
                                value={waitLevelOne}
                                onChange={e => { setWaitLevelOne(e.target.value) }} />
                        </legend>
                        <legend>Not Specified
                                <input type="number"
                                value={waitVolunteer}
                                onChange={e => { setWaitVolunteer(e.target.value) }} />
                        </legend>
                    </div>


                </div>
                <button onClick={generateShifts}>Generate Shifts</button>

                <div>
                    <button
                        style={{ marginTop: '30px' }}
                        onClick={clearAll}>Clear All</button>
                </div>
            </div>
            <div>
                {mappedShifts}
            </div>
        </div>
    )
}

export default ScheduleSetupBulk;