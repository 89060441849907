import React, { useState, useEffect } from 'react'
import axios from 'axios'
import TournamentInfo from './TournamentInfo'


const AppTournamentList = props => {
    const [tournaments, setTournaments] = useState([])


    useEffect(
        () => { getTournaments() }, [])

    const getTournaments = () => {
        axios.get(`/api/getAPPTournaments/app`).then(
            res => {
                setTournaments(res.data)
            }
        )
    }

    const mappedTournaments = tournaments.map((t, i) => {
        return (
            <div key={'t' + i}
                style={{ margin: '40px' }}>
                < TournamentInfo
                    t={t} />
            </div >
        )
    })

    return (
        <div className='container'>
            <h3>APP Tournament List</h3>
            {mappedTournaments}
        </div>
    )
}
export default AppTournamentList