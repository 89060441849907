import React, { Component } from 'react';
import axios from 'axios'
import { connect } from "react-redux"
import io from 'socket.io-client'
import Moment from 'react-moment';
import './ShiftCheckedIn.css'
import SmallMagnet from './SmallMagnet'

class ShiftCheckedIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checkedIn: [],
            firstSearch: '',
            lastSearch: '',
            showRefInfo: false,
            refInfo: {},
            showRefSchedule: false,
            refSchedule: {},
            showAddRef: false
        }

    }

    componentDidMount() {
        this.getShiftCheckin()
        this.setSocketListeners()

    }

    componentWillUnmount() {
        this.socket.disconnect()
    }

    setSocketListeners = () => {
        this.socket = io()

        this.socket.emit('joinRoom', `board:${this.props.tid}`)

        this.socket.on('board', results => {
            if (results.msg === 'shiftCheckin') {
                this.getShiftCheckin()
            }
        })
    }


    getShiftCheckin = async () => {
        if(!this.props.tid){return}
        await axios.get(`/api/getShiftCheckin/${this.props.tid}`).then(
            res => {
                this.setState({ checkedIn: res.data })
            }
        )
    }

    checkIn = async (ref) => {
        if (this.props.turnOffOnClick) { return }

        await axios.put(`/api/checkin/${this.props.tid}`, { ref_id: ref.ref_id, checkout_note: '' }).then(
            res => {
                this.getShiftCheckin()
                this.props.updateBoard()
            }
        )
    }

    checkInAll = () => {
        if (this.props.turnOffOnClick) { return }
        axios.put(`/api/checkinAll/${this.props.tid}`).then(
            res => {
                this.getShiftCheckin()
                this.props.updateBoard()
            }
        )
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    render() {

        const mappedRef = this.state.checkedIn
            .map(ref => {
                return (
                    <SmallMagnet
                        key={ref.ref_id}
                        referee={ref}
                        selectRef={this.checkIn}
                        zone={this.props.zone}
                    />
                )
            })

        return (
            <div className="col-12">
                <div>
                    {this.props.hideButton ? '' :
                        <div className='shiftCheckedIn'>
                            Click on name to add Referee to On Duty
                    <button
                                className='register-button'
                                onClick={this.checkInAll}>Check-In All</button>
                        </div>}

                    {mappedRef}
                </div>
            </div>


        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        zone: reduxState.zone
    }
}


export default connect(
    mapStateToProps,
)(ShiftCheckedIn)