import React, { useState, useEffect } from 'react'
import axios from 'axios'
import CategoryVolunteerScheduling from '../SchedulingUtilities/CategoryVolunteerScheduling'
import LinearProgress from '@material-ui/core/LinearProgress';



const GetShifts = props => {
    const [data, setData] = useState([])
    const [volunteers, setVolunteers] = useState([])
    const [loader, setLoader] = useState(false)
    const [noShifts, setNoShifts] = useState(false)
    const [cancel, setCancel] = useState(false)


    useEffect(
        () => { getShiftSpots() 
            return () => {
                cancelRequests(); // trying to clear memory leak
              }
        }, [props.vcid])
    useEffect(
        () => { getRegisteredVolunteers() }, [props.vcid])
    
    const cancelRequests = () => {
        setCancel(true)
    }

    const getShiftSpots = () => {
        setCancel(false)
        if(props.vcid > 0 || props.vcid === 'all'){
            setLoader(true)
        axios.post(`/api/volunteer/getShiftSpotsForCategory/${props.vcid}`, {tid: props.tid}).then(
            res => {
                if(cancel)return
                setLoader(false)
                if(res.data.length === 0){
                    setData([])
                    setNoShifts(true)
                }else {
                    setNoShifts(false)
                    setData(res.data)
                }
            }
        )
    }
}

    const getRegisteredVolunteers = async () => {
        if(props.vcid > 0){
        await axios.get(`/api/volunteer/getRegisteredVolunteersForCategory/${props.vcid}`).then(
            res => {
                if(cancel){return}
                setVolunteers(res.data)
            }
        )
    }
}
        return (
           <div>
                {loader ? <LinearProgress /> : ''}

               {noShifts ? 
               <h4>No shifts found for this category</h4>:''}
               {data.length > 0 ?
               <CategoryVolunteerScheduling 
                    vcid={props.vcid}
                    data={data}
                    volunteers={volunteers}
                    getShiftSpots={getShiftSpots}
                    getRegisteredVolunteers={getRegisteredVolunteers}/>:''}

            </div>

        )
    }

export default GetShifts
