import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { FormLabel, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import Courts from './Courts'
import PlayerList from './PlayerList'
import './Score.css'
import { toast } from 'react-toastify';

const NotifyPlayers = props => {
    const [t1p1, setT1p1] = useState({})
    const [t1p2, setT1p2] = useState({})
    const [t2p1, setT2p1] = useState({})
    const [t2p2, setT2p2] = useState({})
    const [matchType, setMatchType] = useState('')
    const [gold, setGold] = useState(false)
    const [left, setLeft] = useState([])
    const [right, setRight] = useState([])
    const [court, setCourt] = useState('')
    const [focus, setFocus] = useState('t1p1')
    const [sysid, setSysid] = useState('')
    const [matchId, setMatchId] = useState('')

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getCourts() }, [])

    const getCourts = () => {
        axios.get(`/api/getCourtNumbers/${tid}`).then(
            res => {
                setLeft(res.data.left)
                setRight(res.data.right)
            }
        )
    }
    const send = () => {
        axios.post(`/api/sendPlayerNotification`, { tid, t1p1, t1p2, t2p1, t2p2, matchType, gold, court, sysid, matchId }).then(
            res => {
                clear()
                toast.success('sent')
            }
        )
    }
    const handleFocus = e => {
        setFocus(e)
    }

    const handleSelect = (p) => {
        if (focus === 't1p1') {
            setT1p1(p)
            setFocus('t1p2')
        } else if (focus === 't1p2') {
            setT1p2(p)
            setFocus('t2p1')
        }
        else if (focus === 't2p1') {
            setT2p1(p)
            setFocus('t2p2')
        } else if (focus === 't2p2') {
            setT2p2(p)
            setFocus('')

        }
    }

    const clear = () => {
        setFocus('t1p1')
        setT1p1({})
        setT1p2({})
        setT2p1({})
        setT2p2({})
        setGold(false)
        setCourt('')
        setMatchType('')
        setSysid('')
        setMatchId(0)
    }

    const mappedRight = right.map(c => {

        return (
            <div className='col-6 two-per-column' key={'a' + c.court_num}>
                <Courts
                    c={c}
                    setCourt={setCourt}
                    court={court} />
            </div>
        )
    })
    const mappedLeft = left.map(c => {

        return (
            <div className='col-6 two-per-column' key={'c' + c.court_num}>
                <Courts
                    c={c}
                    setCourt={setCourt}
                    court={court} />
            </div>
        )
    })


    return (
        <div className='container'>

            <div className='row' style={{ margin: '20px' }}>
                <div className='col-5'>
                    <h3>Select Players</h3>
                    <PlayerList
                        select={handleSelect} />
                </div>
                <div className='col-7'>
                    <div className='row'>
                        <div>
                            <div className='col-12'>

                                <div className={focus === 't1p1' ? 'selected input-box' : 'input-box'}
                                    onFocus={() => handleFocus('t1p1')}
                                    placeholder='Team 1 Player 1'>{t1p1.name || 'Team 1 Player 1'}
                                </div>
                                <div className={focus === 't1p2' ? 'selected input-box' : 'input-box'}
                                    onFocus={() => handleFocus('t1p2')}
                                    placeholder='Team 1 Player 2'>
                                    {t1p2.name || 'Team 1 Player 2'}
                                </div>

                                <h3>VS</h3>

                                <div className={focus === 't2p1' ? 'selected input-box' : 'input-box'}
                                    onFocus={() => handleFocus('t2p1')}
                                    placeholder='Team 2 Player 1'>
                                    {t2p1.name || 'Team 2 Player 1'}
                                </div>
                                <div className={focus === 't2p2' ? 'selected input-box' : 'input-box'}
                                    onFocus={() => handleFocus('t2p2')}
                                    name='t2p2'
                                    placeholder='Team 2 Player 2'>{t2p2.name || 'Team 2 Player 2'}</div>

                            </div>
                            <div className='col-12'>
                                <h3>Select Court</h3>
                                <div className='row'>
                                    <div className='col-6 court-list'>
                                        {mappedLeft}

                                    </div>
                                    <div className='col-6 court-list'>
                                        {mappedRight}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <FormLabel component="legend" style={{ paddingTop: '20px' }}>Match Type</FormLabel>
                        <RadioGroup name="type" value={matchType} onChange={e => setMatchType(e.target.value)} row style={{ justifyContent: 'center' }}>
                            <FormControlLabel value="2/3 to 11" control={<Radio />} label="2/3 to 11" />
                            <FormControlLabel value="1 to 15" control={<Radio />} label="1 to 15" />
                            <FormControlLabel value="1 to 21" control={<Radio />} label="1 to 21" />
                        </RadioGroup>
                    </div>
                    <div>
                        <label>Gold Medal Match?
            <input type="checkbox"
                                value={gold}
                                onChange={() => setGold(gold => !gold)} />
                        </label>
                    </div>
                    <div>
                        <input type="text"
                            value={sysid}
                            placeholder='sysid'
                            onChange={e => setSysid(e.target.value)} />
                        <input type="text"
                            value={matchId}
                            placeholder='match id'
                            onChange={e => setMatchId(e.target.value)} />
                    </div>

                    <button onClick={send}>Send</button>
                </div>
            </div>
        </div>
    )
}
export default NotifyPlayers