import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PaymentRow from './PayoutRow'

const PayoutReport = props => {
    const [list, setList] = useState([])
    const [showAll, setShowAll] = useState(false)

  const classes = useStyles();

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getPlayers() }, [tid])


    const getPlayers = async () => {
        await axios.get(`/api/payment/getPlayers/${tid}`).then(
            res => {
              setList(res.data)
            }
        )
    }

    return(
        <div className='container-fluid'>
            <h3>Payout Report</h3>
            <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell onClick={()=>setShowAll(!showAll)}>{showAll ?
            <i className="fas fa-chevron-up"></i>:
            <i className="fas fa-chevron-down"></i>
            }
            </TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>W9 Required</TableCell>
            <TableCell>W9 Received</TableCell>
            <TableCell>Paid</TableCell>
            <TableCell align="left"
                                className={classes.phone}>Cell Phone</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Address</TableCell>
                            <TableCell align="left">Paypal</TableCell>
                            <TableCell align="left">Venmo</TableCell>
                            <TableCell align="left">Zelle</TableCell>
                            <TableCell align="left">Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row, i) => (
            <PaymentRow 
                key={i}
                row={row}
                showAll={showAll}
                get={getPlayers}/>
          ))}
 
        </TableBody>
      </Table>
    </TableContainer>   

        </div>

    )
}
export default PayoutReport

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    name: {
        minWidth: '200px'
    },
    phone: {
        minWidth: '150px'
    },
    address: {
        fontSize: '10px',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '200px'
    },
    email: {
        fontSize: '10px'
    },
    shirt: {
        fontSize: '10px',
        minWidth: '200px'
    }
});