import React, { Component } from 'react'
import axios from 'axios'
import { connect } from "react-redux"
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import '../Schedule/AddRefToSchedule.css'
import AddLineJudge from './AddLineJudge'
import LookupVolunteer from './LookupVolunteer'



class AddLineJudgeToSchedule extends Component {
    constructor() {
        super()
        this.state = {
            data: [],
            shiftsByName: [],
            shiftsByTime: [],
            lineJudges: [],
            shift: {},
            lineJudge: {},
            waitlist: false,
            firstSearch: '',
            lastSearch: '',
            searchName: '',
            searchFirst: '',
            searchLast: '',
            categoryFilter: '',
            showAddLineJudge: false,
            showByName: true
        }
    }

    componentDidMount() {
        this.getShiftSpots()
        this.getRegisteredLineJudges()
    }


    getShiftSpots = () => {
        axios.get(`/api/getShiftSpotsLinejudge/${this.props.tid}`).then(
            res => {
                if (this.state.showByName) {
                    this.setState({
                        data: res.data.name,
                        shiftsByName: res.data.name,
                        shiftsByTime: res.data.time
                    })
                } else {
                    this.setState({
                        data: res.data.time,
                        shiftsByName: res.data.name,
                        shiftsByTime: res.data.time
                    })
                }
            }
        )
    }

    getRegisteredLineJudges = async () => {
        await axios.get(`/api/getRegisteredLineJudges/${this.props.tid}`).then(
            res => {
                this.setState({ lineJudges: res.data })
            }
        )
    }



    assignRef = async () => {
        if (this.state.waitlist) { this.addWaitList() }
        else {
            axios.put(`/api/putTimeSpots/${this.state.shift.sid}`, { tid: this.props.tid, ref_id: this.state.lineJudge.ref_id }).then(
                res => {
                    this.getShiftSpots()
                    this.setState({ lineJudge: {}, shift: {} })
                }
            ).catch(error => {
                if (error.response) {
                    toast.warning(error.response.data)
                    this.setState({ lineJudge: {}, shift: {} })

                }
            })
        }

    }

    addWaitList = async () => {
        axios.put(`/api/putWaitlist/${this.state.shift.sid}`, { tid: this.props.tid, ref_id: this.state.lineJudge.ref_id }).then(
            res => {
                this.getShiftSpots()
                this.setState({ lineJudge: {}, shift: {} })
            }
        ).catch(error => {
            if (error.response) {
                toast.warning(error.response.data)
                this.setState({ lineJudge: {}, shift: {}, waitlist: false })

            }
        });

    }


    removeRef = (shift_id) => {
        axios.put(`/api/removeRefTime/${shift_id}`, { tid: this.props.tid }).then(
            res => {
                this.getShiftSpots()
            }
        )
    }

    addLineJudgeToDBandTournament = (refInfo) => {
        axios.post(`/api/addLineJudgeToDBandTournament/${this.props.tid}`, { refInfo }).then(
            res => {
                this.getRegisteredLineJudges()
                this.setState({ showAddLineJudge: false })
            }
        )
    }

    addLineJudgeToTournament = (ref_id) => {
        axios.post(`/api/addLineJudgeToTournament/${this.props.tid}`, { ref_id }).then(
            res => {
                this.getRegisteredLineJudges()
                this.setState({ showAddLineJudge: false })
            }
        )
    }

    setShift = async (shift, bol) => {
        await this.setState({ shift: shift, waitlist: bol })
        if (this.state.shift.sid && this.state.lineJudge.ref_id && this.state.waitlist === false) {
            this.assignRef()
        }
        if (this.state.shift.sid && this.state.lineJudge.ref_id && this.state.waitlist === true) {
            this.addWaitList()
        }
    }

    setLineJudge = async (lineJudge) => {
        await this.setState({ lineJudge: lineJudge })
        if (this.state.shift.sid && this.state.lineJudge.ref_id) { this.assignRef() }
    }
    clearSearch = () => this.setState({ firstSearch: '', lastSearch: '' })

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    handleShowLineJudge = () => this.setState({ showAddLineJudge: !this.state.showAddLineJudge })

    showByName = () => this.setState({
        showByName: true,
        data: this.state.shiftsByName
    })

    showByTime = () => this.setState({
        showByName: false,
        data: this.state.shiftsByTime
    })

    render() {
        const mappedShifts = this.state.data
            .filter(obj => obj.category.includes(this.state.categoryFilter))
            .filter(obj => obj.name.toUpperCase().includes(this.state.searchName.toUpperCase()))

            .map((shift, i) => {
                const mappedSpot = shift.signup
                    .filter(obj => obj.first.toUpperCase().includes(this.state.searchFirst.toUpperCase()))
                    .filter(obj => obj.last.toUpperCase().includes(this.state.searchLast.toUpperCase()))
                    .map((spot, i) => {

                        return (
                            <div key={spot.spot_id} style={{ background: 'white', display: 'flex', alignItems: 'center' }} >

                                <div className=''>{++i}.</div>
                                <div className='signup-credential-box'>

                                    <div style={{ margin: '3px', width: '1em' }} className={spot.credentials_verified === 'Certified' ? 'ref-list certified' : spot.credentials_verified === 'Level 2' ? 'ref-list level2' : spot.credentials_verified === 'Level 1' ? 'ref-list level1' :

                                        'ref-list Volunteer'}>
                                        {spot.credentials === 'Certified' ? 'C' :
                                            spot.credentials === 'Level 2' ? '2' : spot.credentials === 'Level 1' ? '1' : '-'}
                                    </div></div>
                                <div className=''>
                                    {spot.last}, {spot.first}
                                </div>
                                <i className="fas fa-user-minus" onClick={() => this.removeRef(spot.shift_id)}></i>

                            </div>
                        )
                    })
                const mappedWaitlist = shift.waitlist
                    .filter(obj => obj.first.toUpperCase().includes(this.state.searchFirst.toUpperCase()))
                    .filter(obj => obj.last.toUpperCase().includes(this.state.searchLast.toUpperCase()))
                    .map((waitList, i) => {

                        return (
                            <div key={waitList.spot_id} style={{ background: 'white', display: 'flex', alignItems: 'center' }} >
                                <div className=''>{++i}.</div>
                                <div className='signup-credential-box'>

                                    <div style={{ margin: '3px', width: '1em' }} className={waitList.credentials_verified === 'Certified' ? 'ref-list certified' : waitList.credentials_verified === 'Level 2' ? 'ref-list level2' : waitList.credentials_verified === 'Level 1' ? 'ref-list level1' :

                                        'ref-list Volunteer'}>
                                        {waitList.credentials === 'Certified' ? 'C' :
                                            waitList.credentials === 'Level 2' ? '2' : waitList.credentials === 'Level 1' ? '1' : '-'}
                                    </div></div>
                                <div className=''>
                                    {waitList.last}, {waitList.first}
                                </div>
                                <i className="fas fa-user-minus" onClick={() => this.removeRef(waitList.shift_id)}></i>

                            </div>
                        )
                    })
                return (
                    <div key={shift.sid} style={{ background: 'white', margin: '10px' }} className={this.state.shift.sid === shift.sid ? '  selected-slot' : ''}>
                        <div className='col-12'>
                            <div className='shift-title'
                                style={{ background: shift.color || '#009CAE' }}>
                                <h4>{shift.category}: {shift.name}</h4>

                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'>

                                    <div className='row'>
                                        <div className='col-12'>
                                            <h5>Spots: {shift.count}/{shift.spots}</h5>
                                        </div>
                                        <div className='col-12 col-lg-6'>
                                            Certified: {shift.certified_filled}/ {shift.certified}
                                        </div>
                                        <div className='col-12 col-lg-6'>
                                            Level Two: {shift.level_two_filled}/  {shift.level_two}
                                        </div>
                                        <div className='col-12 col-lg-6'>
                                            Level One: {shift.level_one_filled}/{shift.level_one}
                                        </div>
                                        <div className='col-12 col-lg-6'>
                                            Not Rated: {shift.none_filled}/{shift.none}
                                        </div>


                                    </div>
                                    <div >
                                        <div className='registered-spots'>
                                            {mappedSpot}

                                            {(shift.spots - shift.count) > 0 ?
                                                <button onClick={() => this.setShift(shift, false)}>add</button> : ''}
                                        </div>
                                    </div>
                                </div>
                                {shift.wait_spots ?
                                    <div className='col-12 col-md-6'>

                                        <div className='row'>
                                            <div className='col-12'>
                                                <h5>Waitlist: {shift.wait_count}/ {shift.wait_spots}</h5>
                                            </div>
                                            <div className='col-12 col-lg-6'>
                                                Certified: {shift.wait_certified_filled}/{shift.wait_certified}
                                            </div>
                                            <div className='col-12 col-lg-6'>
                                                Level Two: {shift.wait_level_two_filled}/{shift.wait_level_two}
                                            </div>
                                            <div className='col-12 col-lg-6'>
                                                Level One: {shift.wait_level_one_filled}/{shift.wait_level_one}
                                            </div>
                                            <div className='col-12 col-lg-6'>
                                                Not Rated: {shift.wait_none_filled}/{shift.wait_none}
                                            </div>


                                        </div>
                                        <div style={{ background: 'white' }}>
                                            <div className='registered-spots'>
                                                {mappedWaitlist}

                                                {(shift.wait_spots - shift.wait_count) > 0 ?
                                                    <button onClick={() => this.setShift(shift, true)}>add</button> : ''}
                                            </div>
                                        </div>
                                    </div> : ''}

                            </div>
                        </div>
                    </div>
                )
            })

        const mappedLineJudges = this.state.lineJudges
            .filter(obj => obj.first.toUpperCase().includes(this.state.firstSearch.toUpperCase()))
            .filter(obj => obj.last.toUpperCase().includes(this.state.lastSearch.toUpperCase()))

            .map(lineJudge => {
                return (

                    <div onClick={() => this.setLineJudge(lineJudge)}
                        className={
                            lineJudge.credentials === 'Certified' ? 'ref-list certified shift-checkin-list' :
                                lineJudge.credentials === 'Level 2' ? 'ref-list level2 shift-checkin-list' :
                                    lineJudge.credentials === 'Level 1' ? 'ref-list level1 shift-checkin-list' :
                                        'ref-list shift-checkin-list'}>
                        <div className={this.state.lineJudge.ref_id === lineJudge.ref_id ? 'selected-ref' : ''}>
                            {lineJudge.last}, {lineJudge.first} - {lineJudge.credentials}
                        </div>
                    </div >

                )
            })
        return (
            <div className='AddRefToSchedule container' >

                <Link to={`/line/addShifts`} >
                    <button className='login-button'>Schedule Setup</button>
                </Link>
                <Link to={`/line/linejudgeschedule`} >
                    <button className='register-button'>Schedule By lineJudge</button>
                </Link>
                <Link to={`/line/schedulelog`} >
                    <button className='register-button'>Schedule Log (signup/ removed times)</button>
                </Link>



                <div className='row'>
                    <div className='col-12 col-md-8 '>
                        <div className='search-menu'>
                            <form>

                                <legend> Search by date or title
                            <input
                                        type="text"
                                        placeholder='Search date/ Shift Name'
                                        name="searchName"
                                        value={this.state.searchName}
                                        onChange={this.handleChange} />
                                    <i className="fas fa-ban" onClick={() => this.setState({ searchName: '', categoryFilter: '', searchFirst: '', searchLast: '' })}> clear</i>
                                </legend>
                                <legend>
                                    Search by name:

                                    <input
                                        type="text"
                                        placeholder='Search First Name'
                                        name="searchFirst"
                                        value={this.state.searchFirst}
                                        onChange={this.handleChange} />

                                    <input
                                        type="text"
                                        placeholder='Search Last Name'
                                        name="searchLast"
                                        value={this.state.searchLast}
                                        onChange={this.handleChange} />
                                </legend>
                            </form>
                            <button className='searchButtons'
                                onClick={this.showByName}>Order By Name</button>
                            <button className='searchButtons'
                                onClick={this.showByTime}>Order by Time</button>
                        </div>

                        <div className='mappedSection'>
                            {mappedShifts}
                        </div>
                    </div>
                    <div className='col-12 col-md-4 '>

                        <div className=''>
                            <input
                                type="text"
                                placeholder='Search by Last Name'
                                name="lastSearch"
                                value={this.state.lastSearch}
                                onChange={this.handleChange} />
                            <input
                                type="text"
                                placeholder='Search by First Name'
                                name="firstSearch"
                                value={this.state.firstSearch}
                                onChange={this.handleChange} />
                            <i className="fas fa-ban" onClick={this.clearSearch}></i>
                        </div>
                        <h4>lineJudges Registered for Tournament</h4>

                        <div className='mappedSection'>
                            {mappedLineJudges}
                            <button onClick={this.handleShowLineJudge}>Add Line Judge</button>
                        </div>
                    </div>
                </div>
                <Modal open={this.state.showAddLineJudge} center showCloseIcon={true}
                    onClose={this.handleShowLineJudge} closeOnEsc={true} closeIconSize={2}>
                    <div>
                        <h4>Click on name to add volunteer.  If volunteer is not in the system add them at the bottom.</h4>
                        <LookupVolunteer
                            tid={this.props.tid}
                            addVolunteerToShift={this.addLineJudgeToTournament}
                        />
                        <div className='addLineJudge'>
                            <AddLineJudge
                                tid={this.props.tid}
                                addLineJudge={this.addLineJudgeToDBandTournament} />
                        </div>
                        <button onClick={this.handleShowLineJudge}>Cancel</button>
                    </div>
                </Modal>
            </div>

        )
    }
}

const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name
    }
}
export default connect(
    mapStateToProps,
)(AddLineJudgeToSchedule)
