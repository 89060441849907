import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';



const EmailPlayers = props => {
    const [people, setPeople] = useState([])
    const [selected, setSelected] = useState([])
    const [searchName, setSearchName] = useState('')
    const [searchW9, setSearchW9] = useState(null)
    const [searchW9Received, setSearchW9Received] = useState(null)
    const [searchPaid, setSearchPaid] = useState(null)
    const [subject, setSubject] = useState('')
    const [body, setBody] = useState('')

    const tid = useSelector(state => state.tid)
    const reply = useSelector(state => state.email)
    const author = useSelector(state => state.ref_id)
    const tournamentName = useSelector(state => state.name)

    const classes = useStyles();

    useEffect(
        () => { getPlayers() }, [tid])

    const getPlayers = () => {
        axios.get(`/api/payment/getPlayers/${tid}`).then(
            res => {
                setPeople(res.data)
            }
        )
    }

    const sendEmail = async () => {
        // console.log('hit send', selected)
        if (selected.length === 0) { return toast.warning('Select Email Recipients') }
        await selected.forEach(async x => {
            let s = selected.splice(0, 10)
            await handleSendEmail(s)
        })
        toast.success('Email Sent')
        clearState()
    }

    const handleSendEmail = async (s) => {
        axios.post(`/api/email`, { tid, email: s, body, reply, subject, tournamentName, category: 'payout', author, saveMessage: false }).then(
            res => {
            }
        )
    }
    const selectRecipients = () => toast.warning('Select Email Recipients')

    console.log('selected', selected)


    const select = (row) => {
        if (selected.some(item => row.id === item.id)) {
            let index = selected.map(function (e) { return e.id; }).indexOf(row.id);
            remove(index)
        } else {
            add(row)
        }
    }

    const handleSelectAll = () => {
        let all = people
            .filter(obj => obj.name ? obj.name.toUpperCase().includes(searchName.toUpperCase()) : '')
            .filter(obj => obj.w9_required !== searchW9)
            .filter(obj => obj.w9_received !== searchW9Received)
            .filter(obj => obj.paid !== searchPaid)
            
        setSelected(all)
    }
    const add = (row) => {
        let list = selected.concat(row)
        setSelected(list)
    }

    const remove = (i) => {
        let list = [...selected]
        list.splice(i, 1)
        setSelected(list)
    }

    const clearState = () => {
        setSelected([])
        setSearchName('')
        setSearchW9(null)
        setSearchW9Received(null)
        setSearchPaid(null)
        setSubject('')
        setBody('')
    }

    const handleBody = (value) => {
        setBody(value)
    }

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <h3>Players</h3>
                    <TableContainer component={Paper} className={classes.table}>
                        <button
                            className='select-button'
                            onClick={handleSelectAll}>Select All</button>
                        <button
                            className='select-button'
                            onClick={clearState}>Clear Selection & Filters</button>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="left">W9 Required</TableCell>
                                    <TableCell align="left">W9 Received</TableCell>
                                    <TableCell align="left">Paid</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <input type="text"
                                            onChange={e => setSearchName(e.target.value)}
                                            value={searchName} /></TableCell>
                                    <TableCell align="left">
                                    <div className={classes.gender}>
                                        <button className='small-button'
                                            onClick={e => setSearchW9(false)}>
                                            yes
                                            </button>
                                        <button className='small-button'
                                            onClick={e => setSearchW9(true)}>
                                            no
                                            </button>
                                        <button className='small-button'
                                            onClick={e => setSearchW9(null)}>
                                            all
                                            </button>
                                    </div>
                                    </TableCell>
                                    <TableCell align="left">
                                    <div className={classes.gender}>
                                        <button className='small-button'
                                            onClick={e => setSearchW9Received(false)}>
                                            yes
                                            </button>
                                        <button className='small-button'
                                            onClick={e => setSearchW9Received(true)}>
                                            no
                                            </button>
                                        <button className='small-button'
                                            onClick={e => setSearchW9Received(null)}>
                                            all
                                            </button>
                                    </div>
                                    </TableCell>
                                    <TableCell align="left">
                                    <div className={classes.gender}>
                                        <button className='small-button'
                                            onClick={e => setSearchPaid(false)}>
                                            yes
                                            </button>
                                        <button className='small-button'
                                            onClick={e => setSearchPaid(true)}>
                                            no
                                            </button>
                                        <button className='small-button'
                                            onClick={e => setSearchPaid(null)}>
                                            all
                                            </button>
                                    </div>
                                    </TableCell>
                                    

                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                {people
                                    .filter(obj => obj.name ? obj.name.toUpperCase().includes(searchName.toUpperCase()) : '')
                                    .filter(obj => obj.w9_required !== searchW9)
                                    .filter(obj => obj.w9_received !== searchW9Received)
                                    .filter(obj => obj.paid !== searchPaid)


                                    .map((row) => (
                                        <TableRow key={'m' + row.id}
                                            onClick={() => select(row)}
                                            className={selected.some(item => row.id === item.id) ? classes.selected : classes.row}>
                                            <TableCell component="th" scope="row">
                                                {selected.some(item => row.id === item.id) ? <i className="fas fa-check"></i> : ''}
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left">{row.w9_required ? 'yes': 'no'}</TableCell>
                                            <TableCell align="left">{row.w9_received ? 'yes': 'no'}</TableCell>
                                            <TableCell align="left">{row.paid ? 'yes': 'no'}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <div className='col-12 col-md-6'>
                    <div className={classes.email}>
                        New Message:
                        <p style={{ color: 'red' }}>{selected.length < 1 ? 'Select Email Recipients By Clicking on Their Name' : ''}</p>

                        <div>
                            <input type="text"
                                className={classes.emailBody}
                                placeholder='Subject'
                                value={subject}
                                onChange={e => setSubject(e.target.value)} />
                        </div>
                        <div>

                            <ReactQuill
                                style={{ background: 'white' }}
                                value={body}
                                onChange={handleBody} />
                            <p>Reply-to email: {reply}</p>

                            {selected.length > 0 ?
                                <button onClick={sendEmail}>Send Email</button> :
                                <button className='inactive-button'>Select Names to Sent Email</button>}
                                <p>To send attachments (photos, videos) upload them to dropbox, google docs or your choice in file storage.  You can then include a link in the email.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EmailPlayers

const useStyles = makeStyles({
    table: {
        textAlign: 'left',
        maxHeight: '600px',
        overflow: 'scroll'
    },
    tableBody: {
        textAlign: 'left',
        height: '500px',
        overflow: 'scroll',
    },
    row:{
        '&:hover': {
        background: "#E0E0E0",
        cursor: 'pointer'
        }
    },
    selected: {
        background: "#D2D4F0",
        '&:hover': {
            background: "#E0E0E0",
            cursor: 'pointer'
            }
    },
    gender: {
        display: 'flex'
    },
    pointer: {
        cursor: 'pointer'
    },
    error: {
        color: 'red'
    },
    email: {
        width: '98%'
    },
    emailBody: {
        width: '100%',
        margin: '10px'
    }
});
