import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Modal from 'react-responsive-modal';
import Moment from 'react-moment';
import 'moment-timezone';
import EditItem from './EditItem'
import AddCategory from './AddCategory'
import './ToDoList.css'
import AddToDo from './AddToDo'
import AddItemDetail from './AddItemDetail'

const ToDoList = props => {
    const [committee, setCommittee] = useState([])
    const [pid, setPid] = useState(null)
    const [list, setList] = useState([])
    const [showAddCategory, setShowAddCategory] = useState(false)
    const [showAddItem, setShowAddItem] = useState(false)
    const [category, setCategory] = useState({})
    const [todoCategory, setTodoCategory] = useState(0)
    const [categories, setCategories] = useState([])
    const [todoList, setTodoList] = useState([])
    const [item, setItem] = useState('')
    const [editItem, setEditItem] = useState({})
    const [error, setError] = useState('')
    const [editCategory, setEditCategory] = useState({})
    const [editDetail, setEditDetail] = useState({})
    const [showAddDetail, setShowAddDetail] = useState(false)
    const [selectedPerson, setSelectedPerson] = useState(0)

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getToDoList() }, [])
    useEffect(
        () => { getCategories() }, [])
    useEffect(
        () => { getCommitteeNames() }, [])
    useEffect(
        () => { scrollToTop() }, [showAddCategory])
    useEffect(
        () => { scrollToTop() }, [showAddItem])
    useEffect(
        () => { scrollToTop() }, [showAddDetail])

    const getToDoList = () => {
        axios.get(`/api/getToDoList/${tid}`).then(res => {
            setTodoList(res.data)
        })
    }

    const getCategories = () => {
        axios.get(`/api/getToDoCategories/${tid}`).then(res => {
            setCategories(res.data)
        })
    }

    const getCommitteeNames = () => {
        axios.get(`/api/getCommitteeNames/${tid}`).then(res => {
            setCommittee(res.data)
        })
    }

    const markItemFinished = (pid) => {
        console.log('hit', pid)
        axios.put(`/api/markItemFinished/${pid}`).then(res => {
            getToDoList()
        })
    }

    const markDetailFinished = (pdid) => { 
        axios.put(`/api/markItemDetailFinished/`, {pdid}).then(res => {
            getToDoList()
        })
    }

    const handleEditDetail = (d) => {
        setEditDetail(d)
        setShowAddDetail(true)
    }

    const closeEditItem = () => {
        setPid(null)
        getToDoList()
    }

    const handleEditItem = item => {
        setEditItem(item)
        setShowAddItem(true)
    }

    const handleEditCategory = category => {
        setEditCategory(category)
        setShowAddCategory(true)
    }

    const handleCloseAddItem = () => {
        setEditItem({})
        setShowAddItem(false)
    }

    const handleAddDetail = pid => {
        setShowAddDetail(true)
        setPid(pid)
    }

    const handleShowAddCategory = () => {
        setShowAddCategory(showAddCategory => !showAddCategory)
        setShowAddItem(false)
        setShowAddDetail(false)
    }

    const handleShowAddItem = (c) => {
        console.log(108, c)
        setShowAddCategory(false)
        setShowAddItem(showAddItem => !showAddItem)
        setShowAddDetail(false)
        setCategory(c)
    }

    const handleShowAddDetail = () => {
        setShowAddCategory(false)
        setShowAddItem(false)
        setShowAddDetail(showAddDetail => !showAddDetail)
    }

    const scrollToTop = () => {
        window.scrollTo(0, 100)
    }

    const handleSelectPerson = (id) =>{
        if(selectedPerson === id){setSelectedPerson(0)}
        else {setSelectedPerson(id)}
    }

    const mappedTodoList = todoList.map(c => {
        const mappedItems = c.items.map(item => {
            const mappedDetails = item.detail.map(d => {
                return (
                    <div key={d.pdid}
                        style={{ display: 'flex' }}>
                        <div className='col-7'>
                            {d.completed ?
                                <i className="far fa-check-square pointer"
                                    style={{ paddingRight: '5px' }}
                                    onClick={() => markDetailFinished(d.pdid)}></i> :
                                <i className="far fa-square pointer"
                                    style={{ paddingRight: '5px' }}
                                    onClick={() => markDetailFinished(d.pdid)}></i>}
                            {d.title}
                        </div>
                        <div className='col-4'>
                            {d.note}
                        </div>
                        <div className='col-1'><i className="far fa-edit pointer"
                            onClick={() => handleEditDetail(d)}></i></div>
                    </div>
                )
            }
            )
            const mappedNames = item.names.map(n => {
                return (
                    <div key={n.ref_id}>
                        {n.first} {n.last.charAt(0)}
                    </div>
                )
            })
            return (
                <div
                    key={item.pid}
                    className={item.completed ? 'toDo-Items completed' : 'toDo-Items'}
                    style={{ display: 'flex', flexWrap: 'wrap' }} >
                    <div className='col-7 flex' >
                        <div style={{ paddingRight: '5px' }}>
                            {item.completed ?
                                <i className="far fa-check-square pointer"
                                    onClick={() => markItemFinished(item.pid)}></i> :
                                <i className="far fa-square pointer"
                                    onClick={() => markItemFinished(item.pid)}></i>}
                        </div>
                        <div className='todo-title'>
                            {item.todo_title}
                        </div></div>
                    <div className='col-1'>
                        {item.due_date ?
                            <Moment format="MM/DD" >
                                {item.due_date}
                            </Moment> : ''}
                    </div>
                    <div className='col-3'> {mappedNames}</div>
                    <div className='col-1'><i className="far fa-edit pointer"
                        onClick={() => handleEditItem(item)}></i></div>
                    <div className='col-12'
                        style={{ paddingLeft: '60px' }}> {item.item_notes}</div>
                    <div className='col-12'>
                        {mappedDetails}
                        <div
                            className='col-12 pointer'
                            onClick={() => handleAddDetail(item.pid)}>
                            <i className="fas fa-plus"></i>
                            Add Detail
                        </div>
                    </div>
                </div >
            )
        })
        return (
            <div key={c.cid}>
                <div className='todo-category col-12'>
                    <div className='todo-category-title'>
                        {c.category}
                    </div>
                    <div>
                        <button
                            className='register-button'
                            onClick={()=>handleShowAddItem(c)}>{showAddItem ? 'Cancel Add Item' : 'Add Item'}</button>
                        {c.cid > 1 ?
                            <i className="far fa-edit pointer"
                                onClick={() => handleEditCategory(c)}></i>
                            : ''}
                    </div>
                </div>

                {mappedItems}

            </div>
        )
    })
    return (
        <div className='container budget'>
            <div className='col-12'>
                <h3>To-Do List</h3>
                <div style={{margin: '10px', border: '1px solid grey', padding: '10px'}}>
                Committee Members
                <p><small>To assign task to members, click on a members name then click on the item(s)</small></p>
                {committee.map(c => {
        return (
            <div key={'c'+c.ref_id}
                
                onClick={() => handleSelectPerson(c.ref_id)}>
                    <input type='checkbox'
                        checked={selectedPerson === c.ref_id}/>
                {c.first}  {c.last.charAt(0)}
            </div>
        )
    })
}
</div>

                <div>
                    <button
                        className='register-button'
                        onClick={()=>handleShowAddItem({})}>{showAddItem ? 'Cancel Add Item' : 'Add Item'}</button>
                    <button
                        className='register-button'
                        onClick={handleShowAddCategory}>{showAddCategory ? 'Cancel Add Category' : 'Add Category'}
                    </button>
                </div>
            </div>
            <div className='col-12'>

                <Modal open={showAddCategory} center showCloseIcon={true} onClose={() => setShowAddCategory(false)} closeOnEsc={true} closeIconSize={2}>
                    <AddCategory
                        setShowAddCategory={handleShowAddCategory}
                        getToDoList={getToDoList}
                        editCategory={editCategory}
                        committee={committee}
                        getCategories={getCategories}
                        categories={categories} />
                </Modal>


                <Modal open={showAddItem} center showCloseIcon={true} onClose={() => setShowAddItem(false)} closeOnEsc={true} closeIconSize={2}>
                    <AddToDo
                        getToDoList={getToDoList}
                        close={handleShowAddItem}
                        editItem={editItem}
                        categories={categories}
                        committee={committee}
                        category={category} />
                </Modal>
                <Modal open={showAddDetail} center showCloseIcon={true} onClose={() => setShowAddDetail(false)} closeOnEsc={true} closeIconSize={2}>
                    <AddItemDetail
                        getToDoList={getToDoList}
                        close={handleShowAddDetail}
                        editDetail={editDetail}
                        pid={pid}
                        setPid={setPid}
                        category={category} />
                </Modal>
            </div>

            <div className='ToDoList'>


                {mappedTodoList}



            </div>
        </div>
    )
}
export default ToDoList