import React, { useState, useEffect } from 'react'
import BoardMagnet from './BoardMagnet'
import BoardMagnetDuel from './BoardMagnetDuel'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import AuxAssignment from './AuxAssignment'

const FinishMatch = (props) => {
    const [paid, setPaid] = useState(false)
    const [amount, setAmount] = useState(0)
    const [secondPaid, setSecondPaid] = useState(false)
    const [secondAmount, setSecondAmount] = useState(0)
    const [sysid, setSysid] = useState(null)
    const [medal, setMedal] = useState(null)
    const [tieBreaker, setTieBreaker] = useState(false)
    const [loader, setLoader] = useState(false)
    const [tiePaid, setTiePaid] = useState(true)
    const [tieAmount, setTieAmount] = useState(0)
    const [tieSecondPaid, setTieSecondPaid] = useState(true)
    const [tieSecondAmount, setTieSecondAmount] = useState(0)
    const [verifyReset, setVerifyReset] = useState(false)
    const [verifyDelete, setVerifyDelete] = useState(false)


    const warning = useSelector(state => state.zero_payout_warning )
    const postPaid = useSelector(state => state.referee_post_pay)

    let { court, tid } = props

    useEffect(
        () => { setProps() }, [props.court])

    const setProps = () => {
        let { paid, amount, sysid, medal, second_paid, second_amount } = props.court
        setPaid(paid)
        setAmount(amount)
        setSysid(sysid)
        setMedal(medal)
        setSecondPaid(second_paid)
        setSecondAmount(second_amount)
    }
    const handleFinishMatch = async (override) => {
        if(amount == 0 && warning && !override){return toast.warning('Amount Needed')}
        if (tieBreaker === true) {
            handleTiebreaker(props.court)
        }
        setLoader(true)
        axios.put(`/api/finishMatch/${props.tid}`, { court_num: court.court_num, id: court.id, ref_id: court.ref_id, medal, sysid, amount, paid, secondPaid, secondAmount}).then(
            res => {
                setLoader(false)
                if (res.data === 'Try Again') {
                    toast.warning(`Issue with finish match, please make sure referee is now in the queue.  Reset referee if missing.`)
                }
                else {
                    props.clearState()
                    if(props.court.second_ref_id !== null){
                        props.socketFinishSecond()
                    }else {
                    props.socketFinish({
                        amount: null,
                        cid: null,
                        color: court.color,
                        court_name: null,
                        court_num: court.court_num,
                        credentials: null,
                        credentials_verified: null,
                        first: null,
                        gold: null,
                        id: null,
                        last: null,
                        last_match: null,
                        medal: null,
                        paid: null,
                        ref_id: null,
                        second: null,
                        second_amount: null,
                        second_cred: null,
                        second_lm: null,
                        second_paid: null,
                        second_ref_id: null,
                        start_time: null,
                        sysid: "",
                    },
                    {ref_id: court.ref_id,
                        credentials: court.credentials,
                        credentials_verified: court.credentials_verified,
                        first: court.first,
                        last: court.last,
                        last_match: court.last_match,
                        ref_note: court.ref_note,
                        end_time: new Date(),
                        ppa_rating: court.ppa_rating}
                        
                    )
                    }
                }
            }
        )
    }

    const handleTiebreaker = (match) => {
        let { ref_id, start_time, court_num, } = match
        axios.post(`/api/tiebreakermatch/${props.tid}`, {
            ref_id, start_time, court_num, sysid: props.court.sysid || '' + 'tb', second: props.court.second_ref_id, second_cred: props.court.second_cred, tiePaid, tieAmount, tieSecondPaid, tieSecondAmount
        }).then(
            res => {

            }
        )
    }

    const saveChanges = async () => {
        setLoader(true)
        axios.put(`/api/saveCourtChanges/${props.court.id}`, { sysid, medal, tid, paid, amount, secondAmount, secondPaid }).then(
            res => {
                props.saveMatchChange()
                clearState()
                setLoader(false)

            }
        )
    }
    const resetMatchStartTime = async () => {
        setLoader(true)
        axios.put(`/api/resetMatchStartTime/${props.court.id}`).then(
            res => {
                props.saveMatchChange()
                clearState()
                setLoader(false)

            }
        )
    }
    const deleteMatch = async () => {
        setLoader(true)
        axios.put(`/api/deleteActiveMatch/${props.court.id}`).then(
            res => {
                setVerifyDelete(false)
                props.socketFinish()
                props.clearState()
                clearState()
                setLoader(false)
            }
        )
    }

    const clearState = () => {
        setSysid('')
        setTieBreaker(false)
        setTieAmount(0)
        setTiePaid(true)
        setTieSecondAmount(0)
        setTieSecondPaid(true)

    }

    const toggleTieBreaker = () => {
        if(tieBreaker){
            setTieBreaker(false)
        }
        else {
            setTieBreaker(true)
            setTieAmount(props.court.amount)
            setTiePaid(!postPaid)
            setTieSecondAmount(props.court.second_amount)
            setTieSecondPaid(!postPaid)
        }
    }


    return (
        <div className='finishMatch col-12 ' align="center">
            <div>
                <div>
                    <div className='assign-header'>Finish Match</div>
                </div>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            placeholder='paid'
                            checked={paid}
                            onChange={() => setPaid(paid => !paid)} />
                            PAID $
                            
                            <input type="number"
                            style={{ width: '50px' }}
                            className={warning && amount == 0 ? 'amount-warning':''}
                            value={amount}

                            onChange={e => setAmount(e.target.value)} />
                    </label>
                </div>
                {props.court.second && 
                <div>
                <label>
                    <input
                        type="checkbox"
                        placeholder='paid'
                        checked={secondPaid}
                        onChange={() => setSecondPaid(secondPaid => !secondPaid)} />
                        Second PAID $
                        
                        <input type="number"
                        style={{ width: '50px' }}
                        className={warning && secondAmount == 0 ? 'amount-warning':''}
                        value={secondAmount}

                        onChange={e => setSecondAmount(e.target.value)} />
                </label>
            </div>}
            </div>
            {props.court.second ?
                <BoardMagnetDuel
                    court={props.court} /> :
                <BoardMagnet
                    court={props.court} />}
            <input
                type="text"
                placeholder='SYSID or Note'
                name="sysid"
                value={sysid}
                onChange={e => setSysid(e.target.value)}
            />

            <legend>Medal Match?</legend>
            <label>
                <input type='radio'
                    checked={medal === ''}
                    name='medal'
                    value=''
                    onChange={() => setMedal('')}
                /> No
            </label>
            <label>
                <input type='radio'
                    value='Bronze'
                    checked={medal === 'Bronze'}
                    name='medal'
                    onChange={() => setMedal('Bronze')}
                /> Bronze
            </label>
            <label>
                <input type='radio'
                    checked={medal === 'Gold'}
                    name='medal'
                    value='Gold'
                    onChange={() => setMedal('Gold')}
                /> Gold
            </label>
            {medal === 'Gold' ?
                <div className='tie-breaker'>
                    <label>
                        <p >Was there a tie-breaker match?</p>
                        <input type='radio'
                            checked={tieBreaker}
                            name='tieBreaker'
                            value={tieBreaker}
                            onClick={toggleTieBreaker}
                        /> Yes, Tie Breaker
                    </label>
                    {tieBreaker ?
                        <div>

                            <label>
                                Lead Ref
                        <input
                                    type="checkbox"
                                    placeholder='tiePaid'
                                    checked={tiePaid}
                                    onChange={() => setTiePaid(tiePaid => !tiePaid)} />
                            PAID $
                            <input type="number"
                                    style={{ width: '50px' }}
                                    value={tieAmount}
                                    onChange={e => setTieAmount(e.target.value)} />
                            </label>
                            {props.court.second_ref_id > 0 ?
                                <label>
                                    2nd Ref
                        <input
                                        type="checkbox"
                                        placeholder='tieSecondPaid'
                                        checked={tieSecondPaid}
                                        onChange={() => setTieSecondPaid(tieSecondPaid => !tieSecondPaid)} />
                            PAID $
                            <input type="number"
                                        style={{ width: '50px' }}
                                        value={tieSecondAmount}
                                        onChange={e => setTieSecondAmount(e.target.value)} />
                                </label> : ''}
                        </div> : ''}

                </div>
                : ''}
            {props.court.last_match ?
                <p className='last_match_finish'>Last Match *Verify and remember to checkout referee</p>
                : ''}

            <div>
                {loader ? <CircularProgress /> : ''}

                <button id='finish-button'
                    // type="submit"
                    onClick={() => {handleFinishMatch(false) }}>Finish</button>
                    {warning && amount == 0 ?
                    <button id='finish-zero-button'
                    // type="submit"
                    onClick={() => {handleFinishMatch(true) }}>Finish with no payment</button>:''}
                <button onClick={() => props.showSecondReferee()}>
                 +/- Second Referee<i className="fas fa-user-friends"></i>
                </button>
                
                <button onClick={props.reAssign}>Assign New Referee/ Change Courts</button>
                <button onClick={saveChanges}>Save Changes</button>
                
            </div>

            <button className='button-none' onClick={props.clearState} > Cancel</button>
            <div>
                <AuxAssignment 
                    id={props.court.id}
                    court_num={props.court.court_num} paid={props.court.paid || postPaid}
                    queue={props.queue}
                    sideQueue={props.sideQueue}
                    tid={props.tid}
                    getQueues={props.getQueues} />

            <button className='button-none' onClick={() => props.clipboardNeeded()}>
                    Clipboard Needed
                </button>
            <button className='button-none' onClick={()=>setVerifyDelete(verifyDelete => !verifyDelete)}><small>Delete Match</small></button>
                {verifyDelete ?
                <button 
                className='button-verify' onClick={deleteMatch}>Yes, Delete <small>Cancellation Text Will Be Sent</small></button>:''}

<button className='button-none' onClick={()=>setVerifyReset(verifyReset => !verifyReset)}><small>Reset Start Time</small></button>
                {verifyReset ?
                <button 
                className='button-verify' onClick={resetMatchStartTime}>Yes, Reset</button>:''}
            </div>
        </div>

    )
}
export default FinishMatch