import React from 'react';
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import RefDeskDashboard from '../components/Dashboard/RefDeskDashboard'
import MatchOverview from '../components/Profile/MatchOverview'
import SignupReport from '../components/Profile/SignupReport'
import MyProfile from '../components/Profile/MyProfile'
import ViewInvoice from '../components/Invoice/ViewInvoice'
import PrintInvoice from '../components/Invoice/PrintInvoice'
import APPTournamentList from '../components/Admin/APPTournamentList'
import PPATournamentList from '../components/Admin/PPATournamentList'
import UserRoutes from './UserRoutes';
import QuizRoutes from './QuizRoutes';
import ContactCoordinator from '../components/Schedule/Signup/ContactCoordinator'
import ScoreRoutes from './ScoreRoutes'
import MonitorNotifyDesk from '../components/DayOfComponents/MonitorNotifyDesk';
import RankingRoutes from './RankingRoutes';
import VolunteerCaptainRoutes from './VolunteerCaptainRoutes';
import PayoutAdminRoutes from './PayoutAdminRoutes';
import RefereeMessageBoard from '../components/Email/RefereeMessageBoard';
import MessageListReferee from '../components/MessageBoard/MessageListReferee';
import EventGuideRoutes from './EventGuideRoutes';
import MatchesByTournament from '../components/Profile/MatchesByTournament';
import QRRise from '../components/EventGuide/QRRise'
import AdminRoutes from './AdminRoutes';
import ShondaDashboard from '../components/Dashboard/ShondaDashboard';
import AddTournament from '../components/Admin/AddTournament';
import AddCourts from '../components/Admin/AddCourts';
import QuillDisplay from '../components/Communication/QuillDisplay';
import RefereeAdminRoutes from './RefereeAdminRoutes';
import VolunteerAdminRoute from './VolunteerAdminRoute';
import HowTo from '../components/Admin/HowTo'
import LineAdminRoute from './LineAdminRoute';
import LineCheckin from '../components/LineJudge/LineCheckin';
import LineCheckinShifts from '../components/LineJudge/LineCheckinShifts';
import PlanRoutes from './PlanRoutes';
import CheckinCheckout from '../components/RefDesk/CheckinCheckout/CheckinCheckout'



export default function RoutesLoggedIn(props){
    const tid = useSelector(state => state.tid)
    const user_id = useSelector(state => state.user_id)
    const status = useSelector(state => state.status)
    const admin = useSelector(state => state.admin)
    const admin_type = useSelector(state => state.admin_type)
    const score_reporting = useSelector(state => state.score_reporting)
    const referee = useSelector(state => state.referee)
    const line = useSelector(state => state.line)
    const volunteer = useSelector(state => state.volunteer)
    const volunteer_captain = useSelector(state => state.volunteer_captain)
    const payout = useSelector(state => state.payout)

    return (
<>
            <Switch>
            <Route path='/public/checkin' component={CheckinCheckout} />

            <Route path='/public/linecheckin' component={LineCheckin} />
            <Route path='/public/linecheckinshifts' component={LineCheckinShifts} />
            
                {payout ? <Route path='/payout' component={PayoutAdminRoutes}/>:''}
                {score_reporting ?
                        <Route path='/score' component={ScoreRoutes} /> : ''}

           {user_id === 339 || user_id === 1 ?
                        <Route path='/rise' component={QRRise}/>  :null  
                    }
                    <Route path='/admin/howto' component={HowTo} />
                        <Route path='/signup/refereemessageboard/:tid' component={RefereeMessageBoard}/>
                        <Route path='/ranking' component={RankingRoutes} />

                        <Route path='/volunteer/courtmonitor/:tid' component={MonitorNotifyDesk} />
                        <Route path='/signup/contactcoordinator' component={ContactCoordinator} />
                        <Route path='/quiz' component={QuizRoutes} />
                        <Route path='/question' component={UserRoutes} />                        

                        {status === 'active' && tid && 
                                <Route path='/signup/referee/messageboard' component={MessageListReferee} />}

                        <Route path='/admin/howto' component={HowTo} />

                        {user_id === 414 || user_id === 1 ?
                            < Route path='/admin/ppa' component={PPATournamentList} /> : ''}
                        {user_id === 54 || user_id === 55 || user_id === 1 ?
                            < Route path='/admin/app' component={APPTournamentList} /> : ''}
                        <Route path='/profile/matchesByTournament' component={MatchesByTournament}/>
                        <Route path='/profile/matchCount' component={MatchOverview} />
                        <Route path='/profile/signupReport' component={SignupReport} />       
                        
                        <Route path='/profile/myProfile' component={MyProfile} />
                        <Route path='/print/printInvoice/:id' component={PrintInvoice} />
                        
                        <Route path='/score' component={ScoreRoutes} />
                        
                        {volunteer_captain ?
                        <Route path='/volunteercaptain' component={VolunteerCaptainRoutes}/>:null}
            
                        {user_id === 1 ? <Route path='/admin/shonda' component={ShondaDashboard} /> : ''}
            {user_id === 1 ? <Route path='/admin/addTournament' component={AddTournament} /> : ''}
            {user_id === 1 ? <Route path='/admin/addCourts/:tid/:name' component={AddCourts} /> : ''}
            {user_id === 1 ?
            <Route path='/quill' component={QuillDisplay}/>:''}
            


                        <Route path='/eventGuide' component={EventGuideRoutes} />
                        {user_id === 1 && <Route path='/admin' component={AdminRoutes}/>}
                        {admin && <Route path='/admin' component={AdminRoutes}/>}
                        {admin && <Route path='/desk' component={AdminRoutes}/>}
                        {admin && <Route path='/plan' component={PlanRoutes}/>}
                        {admin && <Route path='/line' component={AdminRoutes}/>}
                        {admin && <Route path='/volunteer' component={AdminRoutes}/>}
                        {admin && <Route path='/volunteercaptain' component={AdminRoutes}/>}
                        {admin && <Route path='/report' component={RefereeAdminRoutes}/>}

                    <Route path='/' component={RefDeskDashboard} />

                        <Route exact path='/' component={RefDeskDashboard} />
                        <Redirect from="/" to="/" component={RefDeskDashboard} />

            </Switch>
            </>
    );
}

