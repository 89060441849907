import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import useSocket from 'use-socket.io-client';
import Switch from "@material-ui/core/Switch";

const CheckinShowingShifts = (props) => {
    const [shifts, setShifts] = useState([])
    const [showLoader, setShowLoader] = useState(false)

    const tid = useSelector(state => state.tid)

    const [socket] = useSocket();
    socket.connect();

    useEffect(
        () => { getShifts() }, [])


    const getShifts = () => {
        setShowLoader(true)
        axios.get(`/api/getRefereesForCheckin/${tid}`).then(res => {
            setShifts(res.data)
            setShowLoader(false)
        })
    }
    const checkInReferee = async (ref_id) => {
        setShowLoader(true)
        await axios.put(`/api/checkin/${tid}`, { ref_id, checkout_note: '' }).then(
            res => {
                getShifts()
            }
        )
    }

    const lastMatchForShift = (sid) => {
        setShowLoader(true)
        axios.put(`/api/lastMatchForShift/${sid}`).then(
            res => {
                props.closeSchedule()
            }
        )
    }

    const checkoutShift = (sid) => {
        setShowLoader(true)
        axios.put(`/api/checkoutShift/${sid}`).then(
            res => {
                props.closeSchedule()
            }
        )
    }

    const lastMatch = (id) => {
        axios.put(`/api/lastMatch/${tid}`, { ref_id: id }).then(
            res => {
                getShifts()
            }
        )
    }

    const mappedShifts = shifts.map((s, i) => {
        const mappedNames = s.signup.map(name => {
            return (
                <div key={'gg' + i +s.sid} className='line-checkin-list '>

                    <div>
                        < Switch
                            color="primary"
                            checked={name.status}
                            value={name.status ? "Active" : 'Inactive'}
                            onClick={() => checkInReferee(name.ref_id)}
                        />

                        {name.last}, {name.first}

                    </div>
                    <div>
                        {name.last_match ? <span className='last_match_icon pointer'
                            onClick={() => lastMatch(name.ref_id)}>LM</span> : <span
                                className='last_match_icon_inactive pointer'
                                onClick={() => lastMatch(name.ref_id)}>LM</span>}
                    </div>


                </div>
            )
        })
        return (
            <div key={'n' + i} className='checkin-shift-box'>
                <div className='line-checkin-shift-box'>
                    <div className='line-checkin-shift-title'>{s.name} {s.time}</div>

                    <button onClick={() => lastMatchForShift(s.sid)}>Last Match</button>
                    <button onClick={() => checkoutShift(s.sid)}>Checkout Shift</button>
                </div>
                <div className='row'>
                    <div className='mapped-checkin-names col-12'>
                        {mappedNames}
                    </div>
                </div>
            </div>
        )
    })



    return (
        <div className='container'>
            <h4>Referee Shifts</h4>
            {showLoader ?
                <div>
                    <img id='loader' width="100" src={require('../../../img/15.gif')} alt='players' />
                </div> : ''}
            {mappedShifts}

        </div>
    )
}
export default CheckinShowingShifts