import React, {useState} from 'react'
import axios from 'axios'
import Dialog from '@material-ui/core/Dialog';
import SmallMagnet from '../BoardView/SmallMagnet';
import { toast } from 'react-toastify';


export default function AuxAssignment(props){
    const [show, setShow] = useState(false)
    const [type, setType] = useState('')
    const [amount, setAmount] = useState(0)
    const [paid, setPaid] = useState(props.paid)
    const [referee, setReferee] = useState({})
    const [message, setMessage] = useState('')

    const save = () => {
        if(!referee.ref_id && type === '')return setMessage('Select Referee and type')
        else if(!referee.ref_id)return setMessage('Select Referee')
        else if(type === ''){return setMessage('Type required')}
        else (setMessage(''))
            axios.post(`/api/board/addAuxAssignment/`, { id: props.id, tid: props.tid, ref_id: referee.ref_id, court_num: props.court_num, type, amount, paid, credentials: referee.credentials_verified}).then(
                res => {
                    toast.success(`${referee.first} added as ${type}`)
                    clearState()
                }
            )
        
    }

    const clearState = () => {
        setType('')
        setReferee({})
        setMessage('')
        setAmount(0)
        setPaid(props.paid)
    }


    const handleClose = () => {
        if(show){
            setShow(false)
            props.getQueues()
        }else {
            setShow(true)
        }
        
    }
    return(
        <div>
            <button onClick={handleClose}>{show ? 'Cancel Aux Assignment':'Add Aux Assignment'}</button>

            {show && 
            <Dialog onClose={handleClose} open={show} >
            <div style={{border: '4px solid black', padding: '10px'}}>
                <div className='row'>
                    <div className='col-6 col-md-3'>
                        {props.queue.map(ref=>(
                            <SmallMagnet key={ref.ref_id} referee={ref} selectRef={setReferee}
                            hide={true}/>
                        ))}

                    </div>
                    <div className='col-6 col-md-3'>
                        {props.sideQueue.map(ref=>(
                            <SmallMagnet key={ref.ref_id} referee={ref} selectRef={setReferee} hide={true}/>
                        ))}

                    </div>
                    <div className='col-12 col-md-6'>
                        <div>
                            <div style={{border: '1px solid black', fontSize:'30px', width: '80px', color: 'white', background: 'black', padding: '10px'}}>
                                {props.court_num}
                            </div>
                        Referee: 
                        <SmallMagnet referee={referee} hide={true}/>
                        </div>
                        <div>
                        Type:
                        <small> Select from options or type custom</small>
                        <SmallButton value='Bonus' set={setType}/>
                        <SmallButton value='Consulting' set={setType}/>
                        <SmallButton value='Line Judge' set={setType}/>
                        <SmallButton value='Line Judge Manager' set={setType}/>
                        <SmallButton value='Tracking Ref' set={setType}/>
                        <SmallButton value='Video Ref' set={setType}/>

                        
                        <input type="text" 
                            placeholder='custom'
                            value={type} onChange={e=>setType(e.target.value)} />
                            <br/>
                            Amount:
                        <input type="integer" value={amount}  onChange={e=>setAmount(e.target.value)}/>
                        <input
                            type="checkbox"
                            placeholder='paid'
                            checked={paid}
                            onChange={() => setPaid(paid => !paid)} />
                            Paid

                        </div>
                        <button onClick={save}>Save</button>
                        <div style={{color: 'red'}}>{message}</div>
                    </div>
                    <div style={{display:'flex', justifyContent:'right', width: '100%'}}>
                    <button 
                        
                        onClick={handleClose}>Close</button>
                    </div>

                </div>
                

            </div></Dialog>}
        </div>
    )
}

export function SmallButton(props){
    return(
        <ul style={{fontSize:'16px', background: 'white', cursor: 'pointer'}}
            onClick={()=>props.set(props.value)}>  
        {props.value}

        </ul>
    )
}