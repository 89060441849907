import React from 'react'
import './Listing.css'

const Listing = (props) => {
    return (
        <div className='tournament-listing shadow mb-1 bg-white rounded'>
            <div className='tournament-listing-header'>
                {props.t.start ?
                    <span>
                            {props.t.start} -
                            {props.t.end_date}
                    </span> : ''}
            </div>
            
           <div>
            <h4>{props.t.name}</h4>
            <p>{props.t.location}</p>
            </div>
            <div>
                 {props.t.usapa ?
                <img className='usapa-logo' width="100" src={require('../../img/usapickleball-logo.png')} alt='USA Pickleball' /> : ''
            }
            {props.t.pickleball_canada ?
                <img className='usapa-logo' width="100" src={'https://res.cloudinary.com/shonda/image/upload/v1650340361/logos/logoPickleballCanada_1_u7hwr8.png'} alt='Pickleball Canada' /> : ''
            }

            {props.t.organization === 'app' ?
                <img className='organization-logo'
                    src={'https://res.cloudinary.com/shonda/image/upload/v1585598171/app_fgbs8a.png'} alt='APP' /> : ''

            }
            {props.t.organization === 'ppa' ?
                <img className='organization-logo'
                    src={'https://res.cloudinary.com/shonda/image/upload/v1585598171/ppa_dpolxq.png'} alt='PPA' /> : ''}
            {props.t.organization === 'np' ?
                <img className='organization-logo'
                    src={'https://res.cloudinary.com/shonda/image/upload/v1612829816/NationalPickleball-stacked_n08msp.png'} alt='National Pickleball' /> : ''}
             {props.t.organization === 'pig' && 
              <img className='organization-logo'
              src={'https://res.cloudinary.com/shonda/image/upload/v1641486085/PickleballDesk/Legacy-Sports_Logo-01_fqrke9.png'} alt='Legacy' />}
             
            {props.t.organization === 'pig' ?
                <img className='organization-logo'
                    src={'https://res.cloudinary.com/shonda/image/upload/v1613689855/pig_v3k4d8.png'} alt='PIG' /> : ''}
            </div>
            

            {/* <div className='about'>{props.t.about}</div> */}
        </div>
    )
}
export default Listing