import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import MUIDataTable from 'mui-datatables'

const RefereePhoneEmailReport = props => {
    const [names, setNames] = useState([])

    const tid = useSelector(state => state.tid)

    useEffect(
        () => { getNames() }, [])


    const getNames = () => {
        axios.get(`/api/getRefereeEmailPhone/${tid}`).then(res => {
            setNames(res.data)
        })
    }
    const columns = [
        {
            name: "first",
            label: "first"
        },
        {
            name: "last",
            label: "last"
        },
        {
            name: "phone",
            label: "phone"
        },
        {
            name: "email",
            label: "email"
        }

    ]
    return (
        <div>
            <MUIDataTable
                title={"Referees"}
                data={names}
                columns={columns}
                options={{ filterType: 'checkbox', selectableRows: 'none' }}
            />
        </div>
    )
}
export default RefereePhoneEmailReport