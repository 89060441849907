import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'



export default function Procedures(props){
    const [body, setBody] = useState([])
    const [tournament, setTournament] = useState('')
    const [message, setMessage] = useState('')
    const [show, setShow] = useState(false)

    const ref_id = useSelector(state => state.ref_id)


    useEffect(
        () => { getBody() }, [])

        const getBody = () => {
            if(!ref_id){return setMessage('Please Login')}
            axios.post(`/api/getProcedures/${props.match.params.tid}`, {ref_id}).then(
                res => {
                    if(res.data === 'not registered'){
                        setMessage("You must be registered for this tournament to view this page.")
                    }
                    else if(res.data.procedures.length === 0 || res.data.procedures === 'none'){
                        setMessage('No procedures found for this tournament.')
                    }else {
                        setShow(true)
                        setBody(res.data.procedures)
                        setTournament(res.data.name)
                    }
                }
            )
        }

        return(
            <div style={{ background: 'white', textAlign: 'left' }} className='container'>
                {message}
            
            {show && <>
               
            <h3>Procedures For {tournament}</h3>
            <Link to={`/signup/${props.match.params.tid}`} >
                <button>Back to Tournament Listing</button>
            </Link>
            <ReactQuill
                className='description'
                value={body}
                readOnly={true}
                theme={"bubble"}
            />
            <Link to={`/signup/${props.match.params.tid}`} >
                <button>Back to Tournament Listing</button>
            </Link>
            </>}
        </div>

        )
}



