import React, {useState} from 'react'
import axios from 'axios'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { toast } from 'react-toastify';

export default function AddNotificationContact(props){
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [medical, setMedical] = useState(false)
    const [second, setSecond] = useState(false)
    const [td, setTd] = useState(false)
    const [desk, setDesk] = useState(false)
    const [headRef, setHeadRef] = useState(false)
    const [active, setActive] = useState(false)


    const addContact = () => {
        if(phone.length !== 11){
            return toast.error('Phone number required.  Include country code.')
        }
        axios.post(`/api/addNotificationContact/`,{tid: props.tid, name, phone, second, medical, td, desk, headRef, active}).then(
            res => {
                props.getList()
                clearState()
            }
        )
    }
    
    const handlePhone = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 11) {
            setPhone(onlyNums)
        } else if (onlyNums.length === 11) { setPhone(onlyNums)}
    }
    const clearState = ()=> {
        setName('')
        setPhone('')
        setMedical(false)
        setSecond(false)
        setTd(false)
        setDesk(false)
        setHeadRef(false)
        setActive(false)
    }

    return(
            <TableRow key='new'>
                <TableCell align="left">
                <input type="checkbox"
                    checked={active}
                    onClick={e=>setActive(!active)} />
                On Duty</TableCell>
              <TableCell component="th" scope="row">
               <input type="text"
                    placeholder='name/ desc'
                    value={name}
                    onChange={e=>setName(e.target.value)} />
              </TableCell>
              <TableCell align="left">
                  <input type="text"
                    placeholder='Phone Include Country Code'
                    value={phone}
                    onChange={handlePhone} />
              </TableCell>
            <TableCell align="left">
                <input type="checkbox"
                    checked={second}
                    onClick={e=>setSecond(!second)} />
                    2nd
            </TableCell>
            <TableCell align="left">
                <input type="checkbox"
                    checked={medical}
                    onClick={e=>setMedical(!medical)} />
                Medical</TableCell>
            <TableCell align="left">
                <input type="checkbox"
                    checked={td}
                    onClick={e=>setTd(!td)} />
                TD</TableCell>
            <TableCell align="left">
                <input type="checkbox"
                    checked={desk}
                    onClick={e=>setDesk(!desk)} />
                Desk</TableCell>
            <TableCell align="left">
                <input type="checkbox"
                    checked={headRef}
                    onClick={e=>setHeadRef(!headRef)} />
                Head Ref</TableCell>
                <TableCell align="left">
                <button onClick={addContact}>Save</button></TableCell>
            </TableRow>
    )
}
