import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import GetShifts from '../SchedulingUtilities/GetShifts'


const CaptainManageSchedule = props => {
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState('')
    const [vcid, setVcid] = useState(0)

    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)
    const volunteer = useSelector(state => state.volunteer)
    const master = useSelector(state => state.admin_type)

    useEffect(
        () => { getCategories() }, [ref_id])

const getCategories = () => {
    if(volunteer){
        getAllCategories()
    }else {
        getCategoriesForCaptain()
    }
}
    const getCategoriesForCaptain = () => {
        axios.post(`/api/volunteer/getVolunteerCategoriesForCaptain`, { tid, ref_id }).then(
        res => {
            setCategories(res.data)
        }
    )
    }
    const getAllCategories = () => {
        axios.post(`/api/volunteer/getAllCategories`, { tid, ref_id }).then(
        res => {
            setCategories(res.data)
        }
    )
    }
    const handleCategory = e => {
        if(e.target.value === 'all'){
            setCategory(e.target.value)
            setVcid('all')
        }else {
        let x = categories.find(x => x.name === e.target.value)
        setCategory(e.target.value)
        setVcid(x.vcid)
        }
        }
    return(
        <div>
            <h3>Volunteer Schedule Management</h3>
             <div className='tips'>
                                Select a category
                                
                            <select
                                name={category}
                                value={category}
                                className='dropdown'
                                onChange={handleCategory}
                            >
                                <option key={'s'} value={''}>Select...</option>
                                {(volunteer || master === 'master')&& <option key='all' value='all' >
                                        All Shifts
                                        </option>}
                                    {categories.map((cat, i)=> (
                                        <option key={i+'o'} value={cat.name}>
                                        {cat.name}
                                        </option>
                                    ))}
                            </select>
                           
                            </div>
                            <GetShifts 
                                vcid={vcid}
                                tid={tid}
                                />
                               

        </div>
    )
}
export default CaptainManageSchedule