import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import AddItem from '../components/PlayerPayouts/AddItem/AddItem';
import ItemList from '../components/PlayerPayouts/ItemList';
import PayoutReport from '../components/PlayerPayouts/PlayerReports/PayoutReport';
import EmailPlayers from '../components/PlayerPayouts/PlayerReports/EmailPlayers';


const PayoutAdminRoutes = (props) => {



    return (
        <div>

            <Switch>
                <Route path='/payout/email' component={EmailPlayers}/>
                <Route path='/payout/report' component={PayoutReport}/>
                <Route path='/payout/addItems' component={AddItem}/>
                <Route path='/payout' component={ItemList}/>
            </Switch>

        </div>
    )
}
export default PayoutAdminRoutes