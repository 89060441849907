import React from 'react'


export default function (props) {
    let { c, selectedCourt } = props

    return (

        < div className='col-12 court-box-boardView'
            style={{ cursor: 'pointer' }
            }
            onClick={c.gid ? () => props.handleFinishMatch(c) : () => props.setSelectedCourt(c)} >
            <div className='col-4 court-num'>
                <div
                    className={c.court_num === selectedCourt.court_num ? 'selected-court-monitor' : 'court-name'}
                >
                    <div className={c.court_num.length > 2 ? 'shrink-court-num' : ''}>{c.court_num}</div>
                    {c.medal === 'Bronze' ?
                        <div className='board-medal'>
                            <i className="fas fa-medal"></i>
                                B
                                </div> :
                        c.medal === 'Gold' ?
                            <div className='board-medal'>
                                <i className="fas fa-medal"></i>
                                    G
                                </div> : ''}
                </div>
            </div>
            <div className='ref-name'
                style={c.gid ? { background: c.color || '#ABB8C3', paddingLeft: '5px', width: '100%' } : { background: 'white' }}>

                {c.group_name ?
                    <div>{c.group_name}
                    </div> : ''}

            </div>
        </div >
    )
}