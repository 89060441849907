import React, { Component } from 'react'
import { connect } from "react-redux"


class MakeSchedule extends Component {
    constructor() {
        super()
        this.state = {
            date: '',
            startTime: '',
            endTime: '',
            spots: 0,
            startDate: new Date()

        }
    }

    handleChange = date => {
        this.setState({
            startDate: date
        });
    };


    render() {
        return (
            <div>
                Make schedule for {this.props.name}


                <p>Start Time</p>

            </div>
        )
    }
}

const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name
    }
}


export default connect(
    mapStateToProps,
)(MakeSchedule)