import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'

const Partnerships = props => {
    const [events, setEvents] = useState([])
    const [selectedEvent, setSelectedEvent] = useState({})
    const [playerOne, setPlayerOne] = useState({})
    const [playerTwo, setPlayerTwo] = useState({})
    const [searchOne, setSearchOne] = useState('')
    const [searchResultsOne, setSearchResultsOne] = useState([])
    const [searchTwo, setSearchTwo] = useState('')
    const [searchResultsTwo, setSearchResultsTwo] = useState([])
    const [roster, setRoster] = useState([])

    const tournament = useSelector(state => state.rankTournament.name)
    const rtid = useSelector(state => state.rankTournament.rtid)

    useEffect(
        () => { getTournamentEvents() }, [rtid])

    useEffect(
        () => { getPlayers() }, [selectedEvent])

    const getTournamentEvents = () => {
        axios.get(`/api/ranking/getTournamentEvents/${rtid}`).then(
            res => {
                setEvents(res.data)
            }
        )
    }

    const getPlayers = () => {
        if (selectedEvent.eid > 0) {
            axios.get(`/api/ranking/getEventPlayers/${selectedEvent.eid}`).then(
                res => {
                    setRoster(res.data)
                }
            )
        }
    }

    const searchForOne = async (name) => {
        // console.log('hit', name)

        await setSearchOne(name)
        if (name.length > 2) {
            getNameOne(name)
        }
        else {
            setSearchResultsOne([])
        }
    }

    const getNameOne = (name) => {
        // console.log('hit', name)
        axios.post(`/api/ranking/searchPlayer?search=${name}`, { eid: selectedEvent.eid }).then(res => {
            setSearchResultsOne(res.data)
        })
    }

    const searchForTwo = async (name) => {
        await setSearchTwo(name)
        if (name.length > 2) {
            getNameTwo(name)
        }
        else {
            setSearchResultsTwo([])
        }
    }

    const getNameTwo = (name) => {
        axios.post(`/api/ranking/searchPlayer?search=${name}`, { eid: selectedEvent.eid }).then(res => {
            setSearchResultsTwo(res.data)
        })
    }

    const selectPlayerOne = e => {
        setPlayerOne(e)
        setSearchResultsOne([])
    }

    const selectPlayerTwo = e => {
        setPlayerTwo(e)
        setSearchResultsTwo([])
    }

    const savePartners = () => {
        axios.post(`/api/ranking/savePartners`, { eid: selectedEvent.eid, one: playerOne.pid, two: playerTwo.pid }).then(res => {
            setPlayerOne({})
            setPlayerTwo({})
        })
    }





    const mappedEvents = events.map((e, i) => (
        <button key={'e' + i} className='imageButtons' onClick={() => setSelectedEvent(e)}>{e.type}</button>
    ))

    const mappedSearchOne = searchResultsOne.map((n, i) => (
        <div key={'p' + i}
            onClick={() => selectPlayerOne(n)}>{n.name}</div>
    ))
    const mappedSearchTwo = searchResultsTwo.map((n, i) => (
        <div key={'p' + i}
            onClick={() => selectPlayerTwo(n)}
        >{n.name}</div>
    ))

    return (
        <div className='container'>

            <div className='row'>
                {tournament}
                <>
                    {mappedEvents}
                </>
                <h3>{selectedEvent.type}</h3>
            </div>
            <div className='row'>
                <div className='col-12 col-md-5'>


                    <label>
                        Player One
                    <input type="text"
                            value={playerOne.name}
                            onChange={e => searchForOne(e.target.value)} />
                    </label>
                    {mappedSearchOne}
                </div>

                <div className='col-12 col-md-5'>
                    <label>
                        Player One
                    <input type="text"
                            value={playerTwo.name}
                            onChange={e => searchForTwo(e.target.value)} />
                    </label>
                    {mappedSearchTwo}
                </div>
                <div className='col-12 col-md-5'>
                <button onClick={savePartners}>save</button>
</div>
            </div>


        </div>
    )
}
export default Partnerships


