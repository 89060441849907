import React, { useState } from 'react'
import ListOfTournaments from './ListOfTournaments'

const VolunteerOpportunities = props => {

    return (
        <div className='container'>
            <h2>Volunteer Opportunities</h2>
            <p>Check back often as tournaments are added daily.</p>
            <p style={{ fontStyle: 'italic' }}>All tournaments are independently operated and used pickleballdesk.com as their volunteer management platform.</p>
            <ListOfTournaments />
        </div>
    )
}
export default VolunteerOpportunities
