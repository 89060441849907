import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
// import useSocket from 'use-socket.io-client';
import Switch from "@material-ui/core/Switch";



const LineCheckinShifts = (props) => {
    const [shifts, setShifts] = useState([])
    const [showLoader, setShowLoader] = useState(false)

    const tid = useSelector(state => state.tid)

    // const [socket] = useSocket();
    // socket.connect('http://localhost:3000:4005');

    useEffect(
        () => { getShifts() }, [])

    // useEffect(
    //     () => {
    //         socket.on('board', results => {
    //             console.log('hit board')

    //         })
    //     }, 0);

    // const emitSocket = () => {
    //     socket.emit('board', { room: `board:${tid}` })
    // }

    const getShifts = () => {
        setShowLoader(true)

        axios.get(`/api/getLineShiftsForCheckin/${tid}`).then(res => {
            setShifts(res.data)
            setShowLoader(false)
        })
    }
    const checkinLineJudge = (ref_id) => {
        axios.put(`/api/checkinLineJudge/${tid}`, { ref_id }).then(res => {
            if (props.handleCheckInModal) {
                props.handleCheckInModal()
            }
            else {
                getShifts()
            }
        })
    }

    const mappedShifts = shifts.map((s, i) => {
        const mappedNames = s.signup.map(name => {
            return (
                <div key={'g' + name.ref_id} className='line-checkin-list '>
                    <div>
                        < Switch
                            color="primary"
                            checked={name.status}
                            value={name.status ? "Active" : 'Inactive'}
                            onClick={() => checkinLineJudge(name.ref_id)}
                        />
                        {name.last}, {name.first} </div>

                </div>
            )
        })
        return (
            <div key={'n' + i} className='checkin-shift-box'>
                {/* <button onClick={emitSocket}>Hit</button> */}
                <div className='line-checkin-shift-title'>
                    {s.name}
                </div>
                <div className='row'>
                    <div className='mapped-checkin-names col-12'>
                        {mappedNames}
                    </div>
                </div>




            </div>
        )
    })



    return (
        <div className='container'>
            <h4>Line Judge Check-In</h4>
            {showLoader ?
                <div>
                    <p>generating list...</p>
                    <img id='loader' width="100" src={require('../../img/15.gif')} alt='players' />
                </div> : ''}
            {mappedShifts}

        </div>
    )
}
export default LineCheckinShifts