import React, { Component } from 'react';
import axios from 'axios'
import { connect } from "react-redux"
import io from 'socket.io-client'
import Moment from 'react-moment';
import Modal from 'react-responsive-modal';



const Barcode = require('react-barcode');


class PastBarcodes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pastMatches: [],
            showModal: false,
            referee: {}
        }
    }

    componentDidMount() {
        this.getPastMatches()
        this.setSocketListeners()
        this.interval = setInterval(() => {
            this.getPastMatches()
        }, 1000 * 60 * 10);
    }

    componentWillUnmount() {
        this.socket.disconnect()
        clearInterval(this.interval)
    }

    setSocketListeners = () => {
        this.socket = io()

        this.socket.emit('joinRoom', `board:${this.props.tid}`)

        this.socket.on('board', results => {
            if (results.msg === 'finish') {
                this.getPastMatches()
            }
        })
    }

    getPastMatches = () => {
        axios.get(`/api/getPastMatchesLimit10/${this.props.tid}`).then(

            res => {
                this.setState({ pastMatches: res.data })
            }
        )
    }

    showModal = (referee) => {
        if (this.state.showModal) {
            this.setState({ showModal: false, referee: {} })
        }
        else { this.setState({ showModal: true, referee: referee }) }
    }



    render() {
        const mappedMatches = this.state.pastMatches.map((court, i) => {

            return (
                <div key={'p' + i} className='col-12' >
                    <div className='court-box-Pastbarcode'
                        onClick={() => this.showModal(court)}>
                        <div className='col-4 court-num'>
                            {court.court_num}
                            {court.medal === 'Bronze' ?
                                <div className='board-medal'>
                                    <i className="fas fa-medal"></i>
                                    B
                                </div> :
                                court.medal === 'Gold' ?
                                    <div className='board-medal'>
                                        <i className="fas fa-medal"></i>
                                        G
                                </div> : ''}
                            <div className='past-end-time'>
                                {court.ref_id ?

                                    <Moment format="h:mm"
                                        local>
                                        {court.end_time}
                                    </Moment>
                                    : ''}
                            </div>

                        </div>
                        <div className='col-8 past-barcode-name'>
                            <div className='barcode-pastMatches'>
                                {court.barcode ?
                                    <Barcode
                                        format='CODE39'
                                        height={30}
                                        width={1}
                                        value={court.barcode} />
                                    : ''}
                            </div>
                            <div>{court.first} {court.last}</div>

                        </div>
                    </div>
                </div>
            )
        })


        return (
            <div className="container">
                <div className='col-12' >
                    <h3>Completed Matches</h3>
                    <div className='tips'>*Click on barcode to enlarge</div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', margin: '10px' }}>
                        {mappedMatches}
                    </div>
                </div>
                <Modal open={this.state.showModal} center showCloseIcon={true} onClose={this.showModal} closeIconSize={20} closeOnEsc={true} >
                    <div className='modal-full-screen'>
                        <div>
                            {this.state.referee.barcode ?
                                <Barcode
                                    format='CODE39'
                                    height={'30vh'}
                                    width={'1.5px'}
                                    value={this.state.referee.barcode} /> : ''}
                        </div>
                        <div>{this.state.referee.first} {this.state.referee.last}</div>
                    </div>
                </Modal>
            </div>

        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name,
        zone: reduxState.zone
    }
}


export default connect(
    mapStateToProps,
)(PastBarcodes)