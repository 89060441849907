import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    title: {
        fontSize: '1.2em',
        textAlign: 'center',
        color: '#009CAE'
    }
});

export default function SwipeableTemporaryDrawer() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        left: false

    });

    const tid = useSelector(state => state.tid)


    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <Link to={`/desk`} >
                    <ListItem button key='11'>
                        <ListItemIcon>
                            <img className='admin-images'
                                src={'https://res.cloudinary.com/shonda/image/upload/v1589687563/PickleballDesk/Referee-coordinator-50_r6k4mi.jpg'} alt='referee' /></ListItemIcon>


                        <ListItemText primary='RC Dashboard' secondary='Links to All Features' />
                    </ListItem>
                </Link>
            </List>
            <Divider />
            <List>
                <Link to={`/desk/setupChecklist`} >
                    <ListItem button key='1'>
                        <ListItemIcon><i className="fas fa-users-cog"></i></ListItemIcon>
                        <ListItemText primary='Setup Guide' />
                    </ListItem>
                </Link>
            </List>
            <List>
                <Link to={`/admin/tournamentSetup`} >
                    <ListItem button key='1b'>
                        <ListItemIcon><i className="fas fa-users-cog"></i></ListItemIcon>
                        <ListItemText primary='Edit Tournament Setup' />
                    </ListItem>
                </Link>
            </List>
            <Divider />
            <List>
                <Typography className={classes.title} component="h2" gutterBottom>
                    Scheduling
      </Typography>
                <Link to={`/desk/scheduleSetup`} >
                    <ListItem button key='2'>
                        <ListItemText primary='Schedule Setup' />
                    </ListItem>
                </Link>
                <Link to={`/desk/editSchedule`} >
                    <ListItem button key='3'>
                        <ListItemText primary='Schedule/ Shifts' />
                    </ListItem>
                </Link>
                <Link to={`/desk/scheduleByRef`} >
                    <ListItem button key='5'>
                        <ListItemText primary='Schedule By Referee' />
                    </ListItem>
                </Link>
                <Link to={`/desk/schedulingLog`} >
                    <ListItem button key='4'>
                        <ListItemText primary='Schedule Log' />
                    </ListItem>
                </Link>

            </List>
            <Divider />

            <List>
                <Link to={`/desk/scheduleByRef`} >
                    <ListItem button key='6'>
                        <ListItemText primary='Communication' />
                    </ListItem>
                </Link>
                <Link to={`/desk/emailtoshifts`} >
                    <ListItem button key='6x'>
                        <ListItemText primary='Email or Text Shifts' />
                    </ListItem>
                </Link>
            </List>
            <Divider />
            <Typography className={classes.title} component="h2" gutterBottom>
                Game Day
      </Typography>
            <List>
                <Link to={`/desk/boardView`} >
                    <ListItem button key='7'>
                        <ListItemText primary='Board/ Referee Manager' />
                    </ListItem>
                </Link>
                <Link to={`/public/checkIn`} >
                    <ListItem button key='8'>
                        <ListItemText primary='Check In' secondary='Use For Self Check In' />
                    </ListItem>
                </Link>
                <Link to={`/public/boardDisplay/${tid}`} target='_blank' rel="noopener noreferrer">
                    <ListItem button key='9'>
                        <ListItemText primary='Board Display' secondary='Use In Ref Lounge- Display Only' />
                    </ListItem>
                </Link>
            </List>
            <Link to={`/desk/pastMatches`} >
                <ListItem button key='10'>
                    <ListItemText primary='Past Matches' />
                </ListItem>
            </Link>
            <Divider />
        </div>
    );

    return (

        <React.Fragment>
            <button
                className='rc-menu' onClick={toggleDrawer('left', true)}><MenuIcon /></button>
            <SwipeableDrawer
                anchor={'left'}
                open={state['left']}
                onClose={toggleDrawer('left', false)}
                onOpen={toggleDrawer('left', true)}
            >
                {list('left')}
            </SwipeableDrawer>
        </React.Fragment>

    );
}