import React from 'react'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import './AdminLandingPage.css'
import InviteOne from '../Communication/InviteOne'
import { toast } from 'react-toastify';


const AdminLandingPage = () => {
    const name = useSelector(state => state.name)
    const status = useSelector(state => state.status)
    const score_reporting = useSelector(state => state.score_reporting)
    const payout = useSelector(state => state.payout)
    const referee = useSelector(state => state.referee)
    const line = useSelector(state => state.line)
    const volunteer = useSelector(state => state.volunteer)
    const admin_type = useSelector(state => state.admin_type)

    const featureNotActive = () => toast.warning('To add this feature contact pickleballdesk@gmail.com')

    return (
        <div className='container'>
            What section are you looking for?
            <div className='row'>

                <div className='col-6 col-md-4 col-lg-3'>
                    {admin_type === 'master' || referee ?
                    <div className='admin-image-box'>
                        <Link to={`/desk/`} >
                            <img className='admin-images'
                                src={'https://res.cloudinary.com/shonda/image/upload/v1589410272/PickleballDesk/Referee-coordinator_naj2a7.png'} alt='referee' />
                        </Link>
                    </div>:
                     <div className='admin-image-box-grey'>
                         <img className='admin-images'
                             src={'https://res.cloudinary.com/shonda/image/upload/v1589410272/PickleballDesk/Referee-coordinator_naj2a7.png'} alt='referee' />
                 </div>}
                    
                </div>
                <div className='col-6 col-md-4 col-lg-3'>
                    {admin_type === 'master' || line ?
                    <div className='admin-image-box'>
                        <Link to={`/line/linejudge`} >
                            <img className='admin-images'
                                src={'https://res.cloudinary.com/shonda/image/upload/v1589410272/PickleballDesk/Line_Judge-coordinator_hjgyl0.png'} alt='line judge' />
                        </Link>
                    </div>:
                    <div className='admin-image-box-grey'>
                            <img className='admin-images'
                                src={'https://res.cloudinary.com/shonda/image/upload/v1589410272/PickleballDesk/Line_Judge-coordinator_hjgyl0.png'} alt='line judge' />
                    </div>}
                    
                </div>
                <div className='col-6 col-md-4 col-lg-3'>
                {admin_type === 'master' || volunteer ?
                    <div className='admin-image-box'>
                        <Link to={`/volunteer/`} >
                            <img className='admin-images'
                                src={'https://res.cloudinary.com/shonda/image/upload/v1589410272/PickleballDesk/Volunteer-coordinator_knojal.png'} alt='line judge' />
                        </Link>
                    </div>:
                    <div className='admin-image-box-grey'>
                        <img className='admin-images'
                            src={'https://res.cloudinary.com/shonda/image/upload/v1589410272/PickleballDesk/Volunteer-coordinator_knojal.png'} alt='line judge' />
                </div>}
                    
                </div>
                <div className='col-6 col-md-4 col-lg-3'>
                    <div className='admin-image-box'>
                        <Link to={`/eventGuide`} >
                            <img className='admin-images'
                                src={'https://res.cloudinary.com/shonda/image/upload/v1629751278/PickleballDesk/tournament_guide_riir7i.png'} alt='QR Event Guide' />
                        </Link>
                        {/* <div className='admin-quick-links'>
                        
                                </div> */}
                    </div>
                    
                </div>
                <div className='col-6 col-md-4 col-lg-3'>
                    
                    <div className='admin-image-box'>
                        <Link to={`/admin/tournamentSetup`} >
                            <img className='admin-images'
                                src={'https://res.cloudinary.com/shonda/image/upload/v1660599394/PickleballDesk/setup_ul0oyr.jpg'} alt='setup' />
                        </Link>
                    </div>
                    
                </div>
                <div className='col-6 col-md-4 col-lg-3'>
                    <div className='admin-image-box'>
                        {score_reporting ?
                            <Link to={`/score/admin`} >
                                <img className='admin-images'
                                    src={'https://res.cloudinary.com/shonda/image/upload/v1593351885/PickleballDesk/score-reporting_iondc3.jpg'} alt='score reporting' />
                            </Link> :
                            <img className='admin-images'
                                onClick={featureNotActive}
                                src={'https://res.cloudinary.com/shonda/image/upload/v1593351875/PickleballDesk/score-reporting-inactive_q5fkwk.jpg'} alt='score reporting not activated' />}
                    </div>
                    <div className='admin-quick-links'>
                        Includes Match Notifications to Players via Text Message
                                </div>
                </div>
                <div className='col-6 col-md-4 col-lg-3'>
                    <div className='admin-image-box'>
                        {payout ?
                            <Link to={`/payout`} >
                                <img className='admin-images'
                                    src={'https://res.cloudinary.com/shonda/image/upload/v1617144356/payouts_ixpyrv.png'} alt='payout' />
                            </Link> :
                            <img className='admin-images'
                                onClick={featureNotActive}
                                src={'https://res.cloudinary.com/shonda/image/upload/v1617144356/payouts-inactive_hvuaw2.png'} alt='payout not activated' />}
                    </div>
                    <div className='admin-quick-links'>
                        Track payments to pay by Paypal, Venmo, or Zelle
                                </div>
                </div>
                <div className='col-6 col-md-4 col-lg-3'>
                    <div className='admin-image-box'>

                        <Link to={`admin/howto`} >
                            <img className='admin-images'
                                src={'https://res.cloudinary.com/shonda/image/upload/v1582498963/PickleballDesk/how-to_k1zl8t.png'} alt='how-to' />
                        </Link>
                    </div>
                    {/* <div>
                        Quick Links:
                    </div>

                    <Link to={`/desk/setupChecklist`} >
                        <div className='admin-quick-links'>
                            Setup Guide
                                </div>
                    </Link>
                    <Link to={`admin/howto`} >
                        <div className='admin-quick-links'>
                            Videos/ Downloads
                                </div>
                    </Link> */}

                </div>

            </div>
            <div className='col-12'>
                <div style={{ display: 'flex', marginTop: '20px', background: 'white' }}>
                    <div>
                        <img className='small-admin-images'
                            src={'https://res.cloudinary.com/shonda/image/upload/v1586532686/PickleballDesk/Invite200_wnj8ro.png'} alt='invite' />
                    </div>
                    {status === 'active' ?
                        <InviteOne /> :

                        <div style={{ color: 'red' }}>Tournament is in {status} status.  Invites can only be sent when tournament is active.</div>}
                </div>
            </div>

        </div >
    )
}
export default AdminLandingPage