import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import ScheduleListByName from '../../Schedule/ScheduleListByName'
import LinearProgress from '@material-ui/core/LinearProgress'

const CaptainVolunteerListWithSchedule = () => {
    const [names, setNames] = useState([])
    const [loader, setLoader] = useState(false)

    const tid = useSelector(state => state.tid)
    const ref_id = useSelector(state => state.ref_id)

    useEffect(
        () => { getSchedule() }, [tid, ref_id])

    const getSchedule = () => {
        setLoader(true)
        axios.post(`/api/volunteer/getVolunteerForCaptain/${tid}`, {ref_id}).then(
            res => {
                setLoader(false)
                setNames(res.data)
            }
        )
    }

        return (
            <div className='container-fluid' >
                <h3>Volunteer List with Their Schedule</h3>
                Volunteer Count: {names.length}
                {loader ? <LinearProgress color="secondary"/>:''}
                <ScheduleListByName 
                    names={names}/>

            </div>
        )
    }

export default CaptainVolunteerListWithSchedule

