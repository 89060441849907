import React, { Component } from 'react'
import axios from 'axios'
import { connect } from "react-redux"
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CSVReader from 'react-csv-reader'

const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header =>
        header
            .toLowerCase()
            .replace(/\W/g, '_')
}

class ImportVolunteer extends Component {
    constructor() {
        super()
        this.state = {
            referees: [],
            category: '',
            error: '',
            completed: false,
            loader: false
        }
    }

    addNames = () => {
        if (this.state.category === '') { this.setState({ error: 'Category must be selected' }) }
        else if (this.state.referees.length === 0) { this.setState({ error: 'Please upload your CVS file' }) }
        else if (this.state.category === 'Referee') {
            this.addReferee()
        }
        else if (this.state.category === 'Line Judge') {
            this.addLineJudge()
        }
        else if (this.state.category === 'Other') {
            this.addVolunteer()
        }
    }

    addReferee = () => {
        axios.post(`/api/addRefereeFromCSV/${this.props.tid}`, { list: this.state.referees }).then(
            res => {
                this.setState({ completed: true, referees: [] })
            }
        )
    }

    addLineJudge = () => {
        axios.post(`/api/addLineJudgeFromCSV/${this.props.tid}`, { list: this.state.referees }).then(
            res => {
                this.setState({ completed: true, referees: [] })
            }
        )
    }

    addVolunteer = () => {
        axios.post(`/api/addVolunteerFromCSV/${this.props.tid}`, { list: this.state.referees }).then(
            res => {
                this.setState({ completed: true, referees: [] })
            }
        )
    }


    handleUpload = data => this.setState({ referees: data })

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }


    render() {
        const mappedNames = this.state.referees.map((ref, i) => {
            return (
                <div key={i} >
                    {ref.first} {ref.last} {ref.email}, {ref.phone}
                </div>
            )
        })

        return (
            <div className='container shadow mb-10 bg-white rounded' >

                <div className='row'>
                    <div className='col-12 email '>
                        <h4>Have a list of Referee's to upload?</h4>
                        <h6>Have a CSV file with emails and phone?</h6>
                        <h6>** Before uploading make sure to format your CSV file.  File needs to include only four columns titled: first, last, email, phone </h6>

                    </div>
                    <div className='col-12'>
                        <legend> Volunteer Type
                            <label>
                                <input type='radio'
                                    checked={this.state.category === 'Referee'}
                                    name='category'
                                    value='Referee'
                                    onChange={this.handleChange}
                                /> Referee </label>
                            <label>
                                <input type='radio'
                                    checked={this.state.category === 'Line Judge'}
                                    name='category'
                                    value='Line Judge'
                                    onChange={this.handleChange}
                                /> Line Judge </label>
                            <label>
                                <input type='radio'
                                    checked={this.state.category === 'Other'}
                                    name='category'
                                    value='Other'
                                    onChange={this.handleChange}
                                /> Other </label>
                        </legend>
                        <CSVReader
                            cssClass="react-csv-input"
                            label="Select CSV to upload"
                            onFileLoaded={this.handleUpload}
                            parserOptions={papaparseOptions}
                        />
                        <div className='emailRecipients'>
                            {mappedNames}
                        </div>

                        <h4>Please make sure all columns are shown.  If one is missing then your file might have had an incorrect column title.  Please correct and re-upload.</h4>
                        <button onClick={this.addNames}>My data looks correct, Submit</button>
                        <h3 style={{ color: 'red' }}>{this.state.error}</h3>
                        <h3 style={{ color: 'red' }}>{this.state.completed ? 'Upload Successful' : ''}</h3>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = reduxState => {
    return {
        tid: reduxState.tid,
        name: reduxState.name
    }
}
export default connect(
    mapStateToProps,
)(ImportVolunteer)