import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleText } from '../../ducks/reducer'

import axios from 'axios';
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CredentialGuide from '../utlities/CredentialGuide'



const TextSwitch = (props) => {

    const tid = useSelector(state => state.tid)
    const text = useSelector(state => state.text)

    const dispatch = useDispatch()


    const changeTextStatus = () => {
        axios.put(`/api/toggleText/${tid}`).then(
            res => {
                dispatch(toggleText({ toggleText: res.data.text }))
                // this.socket.emit('board', { room: `board:${tid}`, msg: 'text', data: res.data.text })
            }
        )
    }

    return (
        <div className='col-12 TextSwitchNav'>
            <div>

                <FormControlLabel
                    label={text ? "Send Text On" : "Send Text Off"}
                    value={text ? "Send Text On" : "Send Text Off"}
                    control={
                        <Switch
                            color="primary"
                            checked={text}
                            value={text ? "Send Text On" : "Send Text Off"}
                        />
                    }

                    onClick={changeTextStatus}
                />
            </div>

            <div>
                <CredentialGuide />
            </div>


        </div >
    )
}

export default TextSwitch