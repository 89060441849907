import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import BoardMagnetDuel from './BoardMagnetDuel'

const SecondReferee = props => {
    const [amount, setAmount] = useState(0)
    const [paid, setPaid] = useState(true)

    const pr1 = useSelector(state => state.pr1_amount)
    const pr2 = useSelector(state => state.pr2_amount)
    const certified = useSelector(state => state.certified_amount)
    const level2 = useSelector(state => state.level_two_amount)
    const level1 = useSelector(state => state.level_one_amount)
    const volunteer = useSelector(state => state.volunteer_amount)
    const postPaid = useSelector(state => state.referee_post_pay)
    const organization = useSelector(state => state.organization)

    useEffect(
        () => { handleAmount() }, [props.referee])
    useEffect(
        () => { handleProps() }, [])
    
    const handleProps = () => setPaid(!postPaid)
    
    const assign = () => {
        axios.put(`/api/board/assignSecondReferee`, { ref_id: props.referee.ref_id || null, credentials: props.referee.credentials, id: props.court.id, amount, paid, medal: props.court.medal }).then(
            res => {
                setPaid(!postPaid)
                props.clear()
                props.getAll()
                props.socket()
                // props.hide()
            }
        )
    }

    const handleAmount = () => {
        let credentials = props.referee.credentials
        if(organization === 'ppa' && props.referee.ppa_rating === 'PR1'){
            setAmount(pr1)
        }
        else if(organization === 'ppa' && props.referee.ppa_rating === 'PR2'){
            setAmount(pr2)
        }
        else if (credentials === 'Certified') {
            setAmount(certified)
        }
        else if (credentials === 'Level 1') {
            setAmount(level1)
        }
        else if (credentials === 'Level 2') {
            setAmount(level2)
        } else {
            setAmount(volunteer)
        }
    }

    const keyPressed = (event) => {
        // console.log('hit', event.keyCode, event.which)
        const code = event.which;
        if (code === 112) {
            setPaid(true)
        }
    }
    return (
        <div onKeyPress={e => keyPressed(e)}>
            <h5>Assign Second Referee</h5>
            <div>
                <label>
                    <input
                        type="checkbox"
                        placeholder='paid'
                        checked={paid}
                        onChange={() => setPaid(paid => !paid)} />
                            PAID $
                            <input type="number"
                        style={{ width: '40px' }}
                        value={amount}
                        onChange={e => setAmount(e.target.value)} />
                </label>
            </div>

            <BoardMagnetDuel
                court={props.court}
                referee={props.referee} />
            <h5>Second Referee:</h5>
            <h2>{props.referee.first} {props.referee.last}</h2>

            <button onClick={assign}>Save</button>
            <button onClick={assign}>Remove 2nd Referee</button>
            <button onClick={props.clear}>cancel</button>

        </div>
    )
}
export default SecondReferee