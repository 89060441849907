import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Modal from 'react-responsive-modal';
import { useSelector } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from "@material-ui/core/Switch";
import Moment from 'react-moment';

import FormControlLabel from "@material-ui/core/FormControlLabel";
import RefScheduleModal from '../RefScheduleModal'
import DisplayRefSchedule from '../DisplayRefSchedule'


const RefInfoModal = props => {
    const [refInfo, setRefInfo] = useState({ shifts: [] })
    const [checkOutStatus, setCheckOutStatus] = useState(false)
    const [status, setStatus] = useState('')
    const [statusTime, setStatusTime] = useState('')
    const [statusOther, setStatusOther] = useState('')
    const [referee, setReferee] = useState({})
    const [note, setNote] = useState('')
    const [tag, setTag] = useState('')
    const [refMessage, setRefMessage] = useState('')
    const [loader, setLoader] = useState(false)
    const [showRefStatus, setShowRefStatus] = useState(false)
    const [showRefInfo, setShowRefInfo] = useState(false)

    const tid = useSelector(state => state.tid)

    const getRefInfo = () => {
        setLoader(true)
        setShowRefInfo(true)
        axios.post(`/api/board/getRefDayOfInfo`, { ref_id: props.ref_id, tid }).then(
            res => {
                setRefInfo(res.data)
                setNote(res.data.note)
                setTag(res.data.tag)
                setRefMessage(res.data.ref_note)
                setLoader(false)
            }
        )
    }

    const close = () => {
        setShowRefStatus(false)
        setShowRefInfo(false)
        setRefInfo({ shifts: [] })
    }

    const checkInStatus = () => {
        if (props.queue <= props.queueNumber) { props.queueWarning() }
        else {
            setCheckOutStatus(true)
        }
    }



    const checkOut = () => {
            setLoader(true)

             axios.put(`/api/checkin/${tid}`, { ref_id: props.ref_id, checkout_note: '' }).then(
                res => {
                    setLoader(false)
                    if (props.statusHasBeenUpdated) { props.statusHasBeenUpdated(props.ref_id) }
                    close()
                }
            )
        }

    const quickBreak = () => {
        setLoader(true)
         axios.put(`/api/quickBreak/${tid}`, { ref_id: props.ref_id }).then(
            res => {
                setLoader(false)
                if (props.statusHasBeenUpdated) { props.statusHasBeenUpdated() }
                close()

            }
        )
    }

    const lastMatch = () => {
        setLoader(true)
         axios.put(`/api/lastMatch/${tid}`, { ref_id: props.ref_id }).then(
            res => {
                setLoader(false)
                if (props.statusHasBeenUpdated) { props.statusHasBeenUpdated() }
                close()
            }
        )
    }

    const updateNote = () => {
        setLoader(true)
        axios.put(`/api/updateNote/${tid}`, { ref_id: props.ref_id, note, tag, refMessage }).then(
            res => {
                setLoader(false)
                setShowRefInfo(false)
                if (props.statusHasBeenUpdated) { props.statusHasBeenUpdated() }
                close()
            }
        )
    }

    const lunchBreakStart = () => {
        setLoader(true)
         axios.put(`/api/lunchBreakStart/${tid}`, { ref_id: props.ref_id, note: 'Lunch Break' }).then(
            res => {
                setLoader(false)
                if (props.statusHasBeenUpdated) { props.statusHasBeenUpdated() }
                close()
            }
        )
    }
    const lunchBreakEnd = () => {
        setLoader(true)
         axios.put(`/api/lunchBreakEnd/${tid}`, { ref_id: props.ref_id }).then(
            res => {
                setLoader(false)
                if (props.statusHasBeenUpdated) { props.statusHasBeenUpdated() }
                close()
            }
        )
    }

    return (
        <div>
            <i className={props.board ? 'fas fa-info-circle boardView-refInfo1' : 'fas fa-info-circle boardView-refInfo'} onClick={() => getRefInfo(props.ref_id)}></i>
            {showRefInfo ?
                <Modal open={showRefInfo} center showCloseIcon={true} onClose={() => setShowRefInfo(false)} closeIconSize={20} closeOnEsc={true} >
                    <div className='col-12 ref_info_modal'>
                        {loader ? <LinearProgress /> : ''}
                        <div className='row'>
                            <div className='col-6 RefInfoModal-status'>

                                {props.start_time ?
                                    <div style={{ background: '#DBFAB6' }}>
                                        Match Start Time:
                                
                                    <Moment format="h:mm a" >
                                        {props.start_time}
                                    </Moment></div> : ''}

                                < h4 > {refInfo.first} {refInfo.last}</h4>
                                {!checkOutStatus ?
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {referee.status ?
                                            <FormControlLabel
                                                label='Checked In'
                                                value={refInfo.status ? "Active" : 'Inactive'}
                                                control={
                                                    <Switch
                                                        color="primary"
                                                        checked={refInfo.status}
                                                        value={refInfo.status ? "Active" : 'Inactive'}
                                                    />
                                                }

                                                onClick={checkInStatus}

                                            /> : 'On Deck'}
                                            <button onClick={checkOut}>Check Out</button>
                                        <FormControlLabel
                                            label='Quick Break'
                                            value={refInfo.quick_break ? "Active" : 'Inactive'}
                                            control={
                                                <Switch
                                                    color="primary"
                                                    checked={refInfo.quick_break}
                                                    value={refInfo.quick_break ? "Active" : 'Inactive'}
                                                />
                                            }

                                            onClick={quickBreak}

                                        />
                                        <FormControlLabel
                                            label='Last Match '
                                            value={refInfo.last_match ? "Active" : 'Inactive'}
                                            control={
                                                <Switch
                                                    color="primary"
                                                    checked={refInfo.last_match}
                                                    value={refInfo.last_match ? "Active" : 'Inactive'}
                                                />
                                            }

                                            onClick={lastMatch}

                                        />
                                        <FormControlLabel
                                            label='Lunch Break '
                                            value={refInfo.last_match ? "Active" : 'Inactive'}
                                            control={
                                                <Switch
                                                    color="primary"
                                                    checked={refInfo.note === 'Lunch Break'}
                                                    value={refInfo.note === 'Lunch Break' ? "Active" : 'Inactive'}
                                                />
                                            }

                                            onClick={() => {
                                                refInfo.note === 'Lunch Break' ? lunchBreakEnd() :
                                                    lunchBreakStart()
                                            }}

                                        />
                                note: (will be removed once assigned)
                        <input type="text"
                                            placeholder=''
                                            maxLength='100'
                                            name='note'
                                            value={note}
                                            onChange={e => setNote(e.target.value)}
                                        />
                            
                                        tag: (stays until removed)
                        <input type="text"
                                            placeholder=''
                                            maxLength='100'
                                            name='tag'
                                            value={tag}
                                            onChange={e => setTag(e.target.value)}
                                        />
                                        Message from Referee:
                        <input type="text"
                                            placeholder=''
                                            maxLength='100'
                                            name='refMessage'
                                            value={refMessage}
                                            onChange={e => setRefMessage(e.target.value)}
                                        />
                                        <button onClick={updateNote}><i className="far fa-save" ></i>Save Note & Tag</button>
                                    </div>
                                    : ''}

                                {checkOutStatus ?
                                    <div className='check-out-modal checkout-style'>
                                        <div className='col-12 check-out-questions '>


                                            <legend>Will you be returning to ref?</legend>
                                            <label>
                                                <input type='radio'
                                                    checked={status === 'Lunch'}
                                                    name='status'
                                                    value='Lunch'
                                                    onChange={() => setStatus('Lunch')}
                                                /> Lunch Break </label>
                                            <label>
                                                <input type='radio'
                                                    value='Today'
                                                    checked={status === 'Today'}
                                                    name='status'
                                                    onChange={() => setStatus('Today')}
                                                /> Today </label>
                                            <label>
                                                <input type='radio'
                                                    checked={status === 'Today, When done playing'}
                                                    name='status'
                                                    value='Today, When done playing'
                                                    onChange={() => setStatus('Today, When done playing')}
                                                /> Today, when done playing </label>
                                            <label>
                                                <input type='radio'
                                                    checked={status === 'Tomorrow'}
                                                    name='status'
                                                    value='Tomorrow'
                                                    onChange={() => setStatus('Tomorrow')}
                                                /> Tomorrow </label>


                                            <label>
                                                <input type='radio'
                                                    checked={status === 'Not Returning'}
                                                    name='status'
                                                    value='Not Returning'

                                                    onChange={() => setStatus('Not Returning')}
                                                /> Not Returning </label>
                                            <label>
                                                <input type='radio'
                                                    checked={status === 'Contact if needed'}
                                                    name='status'
                                                    value='Contact if needed'
                                                    onChange={() => setStatus('Contact if needed')}
                                                /> Contact if needed </label>
                                            <label>
                                                <input type='radio'
                                                    checked={statusOther}
                                                    name='statusOther'
                                                    value='true'
                                                    onChange={() => setStatusOther(true)}
                                                /> Other </label>
                                            {statusOther ?
                                                <input type="text"
                                                    placeholder=''
                                                    maxLength='50'
                                                    name='status'
                                                    value={status}
                                                    onChange={e => setStatus(e.target.value)} />

                                                : ''}
                                        </div>


                                        <legend>What time will you be back?</legend>
                                        <input type="text"
                                            placeholder='Time'
                                            name='statusTime'
                                            maxLength='10'
                                            value={statusTime}
                                            onChange={e => setStatusTime(e.target.value)} />
                                        <div>

                                            <button onClick={checkOut}><i className="far fa-save"></i>Save Checkout</button>



                                        </div>
                                    </div> : ''}
                            </div>
                            <div className='col-6'>
                                <h4>Schedule:</h4>
                                <div className='registered-spots'>
                                    <DisplayRefSchedule
                                        referee={refInfo} />
                                </div>
                                <h6>Notes:</h6>
                                <RefScheduleModal
                                    referee={refInfo}
                                />
                            </div>

                            <button onClick={() => setShowRefInfo(false)}> Close</button>
                        </div>
                    </div>
                </Modal > : ''
            }
        </div >
    )
}
export default RefInfoModal
