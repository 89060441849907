import React, { useState } from 'react'
import axios from 'axios'


const CaptainName = props => {
    const [verifyDelete, setVerifyDelete] = useState(false)

    let { name } = props

    const removeAdmin = (id) => {
        axios.post(`/api/volunteer/removeCaptain`, {id }).then(
            res => {
                props.get()
                setVerifyDelete(false)
            }
        )
    }
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <i className="fas fa-minus-circle icon-margin-right"
                onClick={() => setVerifyDelete(verifyDelete => !verifyDelete)}></i>
            {verifyDelete ?
                <button onClick={() => removeAdmin(name.id)}>Verify Remove</button> : ''}

            {name.name}
        </div>

    )
}
export default CaptainName