import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { connect } from "react-redux"
import Moment from 'react-moment';
import './SignupReport.css'






class SignupReport extends Component {
    constructor() {
        super()
        this.state = {
            schedule: []
        }
    }

    componentDidMount() {
        this.getSchedule()
    }

    getSchedule = () => {
        axios.get(`/api/getIndividualSchedule/${this.props.ref_id}`).then(
            res => {
                this.setState({ schedule: res.data })
            }
        )
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }


    render() {

        // const mappedSchedule = this.state.schedule
        //     .map(schedule => {
        //         const mappedShift = schedule.shift.map(shift => {
        //             return (
        //                 <div className='mappedShift col-12'>
        //                     <div className='col-4'>
        //                         {shift.category === 'other' ? 'Volunteer' : shift.category}
        //                         {shift.waitlist ? 'waitlist' : ''}
        //                     </div>
        //                     <div className='col-8'>
        //                         {shift.shift_name} {shift.start_time} {shift.end_time ? ` - ${shift.end_time}`:''}
        //                     </div>
        //                 </div>
        //             )
        //         })
        //         return (
        //             <div className='mappedSchedule'>
        //                 <div className=''>
        //                     <div className='signupReport-tournament-header'>
        //                         <div>
        //                             <h5>{schedule.name}</h5>
        //                             <p>{schedule.location}</p>
        //                         </div>
        //                         <div>
        //                             {schedule.start ?
        //                                 <p>
        //                                     <Moment format="MM/DD/YYYY" >
        //                                         {schedule.start}
        //                                     </Moment> to
        //                                 <Moment format="MM/DD/YYYY" >
        //                                         {schedule.end_date}
        //                                     </Moment>
        //                                 </p> : ''}
        //                         </div>


        //                     </div>

        //                 </div>
        //                 {mappedShift}
        //                 <Link to={`/signup/${schedule.tid}`} >
        //                     <button>View Signup Page</button>
        //                 </Link>

        //             </div>
        //         )
        //     })

        const mappedSchedule = this.state.schedule.map((shift, i) =>(
            <div>
                {i === 0 ? 
                <div className='signupReport-tournament-header'>
                <Link to={`/signup/${shift.tid}`} >
                                 <button>Open</button>
                           </Link>
                {shift.tournament} {shift.start} - {shift.end} 
                </div>
                : shift.tid !== this.state.schedule[i-1].tid ?
                <div className='signupReport-tournament-header'>
                    <Link to={`/signup/${shift.tid}`} >
                                     <button>Open</button>
                               </Link>
                    {shift.tournament} {shift.start} - {shift.end} 
                    </div>:null}
                <div className='mappedShift col-12'>
                                 {shift.category}
                                 {shift.waitlist ? ' waitlist' : ''}{`  `}
                                 {shift.name} {shift.start_time} {shift.end_time ? ` - ${shift.end_time}`:''}
                        
                         </div>

            </div>
        ))

        return (
            <div className='container'>
                <div className='RefereeSchedule '>
                    <div className='col-12'>


                        <div className='RefSchedule-schedule'>
                            <h4>Future Schedule for  {this.props.first} {this.props.last}</h4>
                            {mappedSchedule}</div>
                    </div>

                </div>
            </div>
        )
    }
}


const mapStateToProps = reduxState => {
    return {
        ref_id: reduxState.ref_id,
        first: reduxState.first,
        last: reduxState.last
    }
}
export default connect(
    mapStateToProps,
)(SignupReport)